import React, { useContext, useState } from "react";
import { commonType } from "../../../../pages/main/lead";
import { ViewLeadContext } from "../../../../pages/main/lead/view lead";
import { ProfileContext } from "../../../../pages/main/lead/view lead/sub pages/profile";
import Form from "./Form";

const CompanyDetails = () => {
  const { lead } = useContext(ViewLeadContext);
  const { step } = useContext(ProfileContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });

  const initialValues = {
    name: lead?.client?.name ?? "--",
    email: lead?.client?.email ?? "--",
    contactNumber: lead?.client?.contactNumber ?? "--",
    phoneCode:
      lead?.client?.phoneCode || lead?.client?.phoneCode?.length === 0
        ? "+91"
        : "",
    gstType: lead?.client?.gstType ?? "--",
    gstin: lead?.client?.gstin ?? "--",
    pan: lead?.client?.pan ?? "--",
    website: lead?.client?.website ?? "--",
    currency: lead?.client?.currency ?? "--",
    clientId: lead?.client?._id ?? "",
    lastUpdate: lead?.client?.updatedAt ?? "",
    updatedBy: lead?.client?.updatedBy ? lead?.client?.updatedBy : undefined,
  };

  const fn = {
    openForm: () => {
      setCommonData({
        data: initialValues,
        modal: true,
        check: "company_menu",
      });
      document.body.style.overflow = "hidden";
    },
  };

  return (
    <>
      <div className="profile_menu_content_section">
        <div className="d-flex justify-content-between align-items-center">
          <p className="mb-0 font12x500 textColor3">Company</p>
          <div
            className="d-flex justify-content-between align-items-center cursor_pointer"
            onClick={fn.openForm}
          >
            <img src="/Assets/Svg/editIcon.svg" alt="edit" />
            <p className="mb-0 font12x400 textColor2 ms-1">Edit</p>
          </div>
        </div>
        <div className="container-fluid p-0 mt-3">
          <div className="row">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">Company Name</p>
            </div>
            <div className="col-8">
              <p className="mb-0 font12x400 textColor3">{initialValues.name}</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">
                Company Contact Number
              </p>
            </div>
            <div className="col-8">
              <p className="mb-0 font12x400 textColor3">
                {initialValues?.contactNumber?.length > 0
                  ? `${initialValues?.phoneCode} ${initialValues?.contactNumber}`
                  : "--"}
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">Email</p>
            </div>
            <div className="col-8 d-flex">
              <a
                href={`mailto:${initialValues.email ?? "#"}`}
                className="font12x400 textColor2 text-decoration-none"
                target="_blank"
              >
                {initialValues.email?.length > 0 ? initialValues.email : "--"}
              </a>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">Company website</p>
            </div>
            <div className="col-8 d-flex">
              <a
                href={`${initialValues.website ?? "#"}`}
                className="font12x400 textColor2 text-decoration-none"
                target="_blank"
              >
                {initialValues.website?.length > 0
                  ? initialValues.website
                  : "--"}
              </a>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">GST Type</p>
            </div>
            <div className="col-8 d-flex">
              <p className="mb-0 font12x400 textColor3">
                {initialValues.gstType?.length > 0
                  ? initialValues.gstType
                  : "--"}
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">GSTIN</p>
            </div>
            <div className="col-8 d-flex">
              <p className="mb-0 font12x400 textColor3">
                {initialValues.gstin?.length > 0 ? initialValues.gstin : "--"}
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">PAN</p>
            </div>
            <div className="col-8 d-flex">
              <p className="mb-0 font12x400 textColor3">
                {initialValues.pan?.length > 0 ? initialValues.pan : "--"}
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">Currency</p>
            </div>
            <div className="col-8 d-flex">
              <p className="mb-0 font12x400 textColor3">
                {initialValues.currency?.length > 0
                  ? initialValues.currency
                  : "--"}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* form modal */}
      {commonData?.modal && commonData?.check === "company_menu" && (
        <Form step={step} state={commonData} setState={setCommonData} />
      )}
    </>
  );
};

export default CompanyDetails;
