import { createContext, useContext, useEffect } from "react";
import MenuBar from "../../../../components/User Components/User Profile Components/Menubar";
import MenuData from "../../../../components/User Components/User Profile Components/MenuData";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";

type propType = {
  routeName?: string;
};

export const UserProfileContext = createContext<any>(null);

const UserProfile: React.FC<propType> = ({ routeName }) => {
  const { setPageTitle } = useContext(MainContext);
  const userObj = JSON.parse(window.localStorage.getItem("user") || "");

  useEffect(() => {
    setPageTitle("Your Profile");
  }, []);

  return (
    <>
      <UserProfileContext.Provider
        value={{
          routeName,
          userObj,
        }}
      >
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3 pe-lg-2">
              <MenuBar />
            </div>
            <div className="col-12 col-md-8 col-lg-9 px-lg-2">
              <MenuData />
            </div>
          </div>
        </div>
      </UserProfileContext.Provider>
    </>
  );
};

export default UserProfile;
