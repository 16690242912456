import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { ViewLeadContext } from "../..";
import { commonType } from "../../..";
import DataNotFound from "../../../../../../components/Data Not Found";
import Form from "../../../../../../components/View Lead Components/MenuData/PO Components/Form";
import useLoading from "../../../../../../hooks/useLoading";
import { getPO } from "../../../../../../lib/api functions/lead/po";
import { poStatusMap } from "../../../../../../utils/constants";
import { MainContext } from "../../../../../../utils/private_routes/PrivateRoutes";
import { ParamType } from "./type";

const ViewPo = () => {
  const { setting } = useContext(MainContext);
  const { id } = useContext(ViewLeadContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });

  const [loading, showLoader, hideLoader] = useLoading();
  const [pos, setPos] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [params, setParams] = useState<ParamType>({
    // page: 1,
    // per_page: 10,
    lead: id,
  });

  const fn = {
    openForm: (data: any) => {
      setCommonData({
        data: data ? data : undefined,
        modal: true,
        check: "po_menu",
      });
      document.body.style.overflow = "hidden";
    },
    getPOs: async (params?: ParamType) => {
      showLoader();
      const res = await getPO(params);
      if (res) {
        setPos(res.data.purchaseOrders);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
  };

  useEffect(() => {
    fn.getPOs(params);
  }, []);

  return (
    <>
      <div>
        <div className="viewlead_content_header d-flex justify-content-between">
          <p className="m-0 font14x500 textColor3">Attach P. O.</p>
          <button
            className="btn font12x500 buttonOne shadow-none"
            disabled={pos[0]?.status === 0 ? true : false}
            onClick={() => fn.openForm(null)}
          >
            <img src="/Assets/Svg/plusIcon.svg" alt="icon" className="me-2" />
            Attach New PO
          </button>
        </div>

        {loading ? (
          <Skeleton height="190px" borderRadius="8px" />
        ) : (
          <div className="menu_content_wrapper">
            {/* table */}
            {pos?.length !== 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head_small">
                      <tr className="font12x500 textColor3">
                        <th scope="col">Entry Date</th>
                        <th scope="col">PO No.</th>
                        <th scope="col">Title</th>
                        <th scope="col">Attachments</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pos?.map((po: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <td>
                              {moment(po?.createdAt).format(setting.dateFormat)}
                            </td>
                            <td>{po?.number}</td>
                            <td>{po?.title}</td>
                            <td>
                              {po?.attachments.length
                                ? po?.attachments?.map(
                                    (item: any, i: number) => {
                                      return (
                                        <a
                                          className="text-decoration-none"
                                          href={`${process.env.REACT_APP_IMAGE_URL}${item?.url}`}
                                          target="_blank"
                                        >
                                          <p
                                            key={i}
                                            className="mb-1 textColor2 font10x600 cursor_pointer"
                                          >
                                            {item?.filename}
                                          </p>
                                        </a>
                                      );
                                    }
                                  )
                                : "--"}
                            </td>
                            <td>{poStatusMap.get(po?.status)}</td>
                            <td>
                              {po?.status === 1 || po?.status === 2 ? (
                                <p
                                  className="mb-0 font10x400 textColor1"
                                  style={{
                                    cursor: "no-drop",
                                  }}
                                >
                                  Edit
                                </p>
                              ) : (
                                <p
                                  className="mb-0 font10x400 textColor2 cursor_pointer"
                                  onClick={() => fn.openForm(po)}
                                >
                                  Edit
                                </p>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <DataNotFound />
            )}
          </div>
        )}
      </div>

      {/* form modal */}
      {commonData?.modal && commonData?.check === "po_menu" && (
        <Form
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getPOs}
          params={params}
        />
      )}
    </>
  );
};

export default ViewPo;
