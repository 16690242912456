import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import EmailLogin from "../pages/common/EmailLogin";
import ErrorPage from "../pages/common/ErrorPage";
import OtpLogin from "../pages/common/OtpLogin";
import UserProfile from "../pages/common/UserProfile";
import VerifyOtp from "../pages/common/VerifyOtp";
import Attendance from "../pages/main/attendance";
import AttendanceLeave from "../pages/main/attendance/sub pages/Leave";
import ViewCalendar from "../pages/main/calendar";
import Client from "../pages/main/client";
import AddUpdateClient from "../pages/main/client/add update client";
import TrashedClient from "../pages/main/client/trashed client";
import ViewClient from "../pages/main/client/view client";
import CreditNotes from "../pages/main/credit note";
import AddUpdateCreditNote from "../pages/main/credit note/add update note";
import TrashedCreditNote from "../pages/main/credit note/trashed credit note";
import ViewCreditNotePage from "../pages/main/credit note/view credit note";
import Dashboard from "../pages/main/dashboard";
import DemoRequest from "../pages/main/demo request";
import Expenses from "../pages/main/expense";
import AddUpdateExpense from "../pages/main/expense/add update expense";
import TrashedExpense from "../pages/main/expense/trashed expense";
import ViewExpensePage from "../pages/main/expense/view expense";
import Invoice from "../pages/main/invoice";
import AddUpdateInvoice from "../pages/main/invoice/add update invoice";
import RequestedInvoice from "../pages/main/invoice/sub pages/requested invoice";
import ViewCancelledInvoice from "../pages/main/invoice/view cancelled invoice";
import ViewInvoicePage from "../pages/main/invoice/view invoice";
import Item from "../pages/main/item";
import TrashedItem from "../pages/main/item/trashed item";
import Lead from "../pages/main/lead";
import DeletedLead from "../pages/main/lead/Deleted Lead";
import NewLead from "../pages/main/lead/new lead";
import ViewLead from "../pages/main/lead/view lead";
import Payments from "../pages/main/payment";
import ViewPaymentPage from "../pages/main/payment/view payment";
import Reminder from "../pages/main/reminder";
import Setting from "../pages/main/setting";
import Task from "../pages/main/task";
import ViewTaskPage from "../pages/main/task/view";
import Todo from "../pages/main/todo";
import User from "../pages/main/user";
import AddUpdateUser from "../pages/main/user/add update user";
import UserProfileView from "../pages/main/user/User Profile";
import Vendors from "../pages/main/vendor";
import AddUpdateVendor from "../pages/main/vendor/add update vendor";
import TrashedVendor from "../pages/main/vendor/trashed vendor";
import ViewVendor from "../pages/main/vendor/view vendor";
import {
  ADD_EDIT_PROPOSAL,
  SETTING_CREDITNOTE,
  SETTING_DEPARTMENT,
  SETTING_EMPLOYEE,
  SETTING_EXPENSE,
  SETTING_GENERAL,
  SETTING_GST,
  SETTING_INVOICE,
  SETTING_LOCATION,
  SETTING_PAYMENT,
  SETTING_PROFILE,
  SETTING_PROPOSAL,
  SETTING_SALARY_SLIP,
  SETTING_USER_ROLE,
  USER_ATTENDANCE,
  USER_BANK_DETAILS,
  USER_DETAILS,
  USER_DOCUMENTS,
  USER_OFFICE_DETAILS,
  USER_ROLES,
  USER_SALARY_SLIP,
  VIEWP_QUOTATION,
  VIEW_CREDENTIAL,
  VIEW_INVOICE,
  VIEW_PAYMENT,
  VIEW_PO,
  VIEW_PROFILE,
  VIEW_PROPOSAL,
  VIEW_PROPOSAL_PAGE,
  VIEW_REQUEST_DEMO,
  VIEW_REQUEST_INVOICE,
  VIEW_UPDATE,
  YOUR_ATTENDANCE,
  YOUR_BANK_DETAILS,
  YOUR_DETAILS,
  YOUR_DOCUMENTS,
  YOUR_OFFICE_DETAILS,
  YOUR_SALARY_SLIP,
} from "../utils/constants";
import PrivateRoutes from "../utils/private_routes/PrivateRoutes";
import ProtectedRoutes from "../utils/private_routes/ProtectedRoutes";

const AllRoutes = () => {
  const router = createBrowserRouter(
    createRoutesFromElements([
      <Route path="/" element={<EmailLogin />} errorElement={<ErrorPage />} />,
      <Route path="/otp" element={<OtpLogin />} errorElement={<ErrorPage />} />,
      <Route
        path="/verify-otp"
        element={<VerifyOtp />}
        errorElement={<ErrorPage />}
      />,

      <Route element={<PrivateRoutes />}>
        <Route
          path="/dashboard"
          element={
            <ProtectedRoutes pageName="dashboard">
              <Dashboard />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />

        {/* client routes */}
        <Route
          path="/client"
          element={
            <ProtectedRoutes pageName="clients">
              <Client />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/client/form"
          element={
            <ProtectedRoutes pageName="clients">
              <AddUpdateClient />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/client/form/:clientId"
          element={
            <ProtectedRoutes pageName="clients">
              <AddUpdateClient />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/client/view/:clientId"
          element={
            <ProtectedRoutes pageName="clients">
              <ViewClient />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/client/trashed"
          element={
            <ProtectedRoutes pageName="clients">
              <TrashedClient />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />

        {/* vendor routes */}
        <Route
          path="/vendor"
          element={
            <ProtectedRoutes pageName="vendors">
              <Vendors />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/vendor/form"
          element={
            <ProtectedRoutes pageName="vendors">
              <AddUpdateVendor />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/vendor/form/:vendorId"
          element={
            <ProtectedRoutes pageName="vendors">
              <AddUpdateVendor />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/vendor/view/:vendorId"
          element={
            <ProtectedRoutes pageName="vendors">
              <ViewVendor />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/vendor/trashed"
          element={
            <ProtectedRoutes pageName="vendors">
              <TrashedVendor />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />

        {/* lead routes */}
        <Route
          path="/lead"
          element={
            <ProtectedRoutes pageName="leads">
              <Lead />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/lead/new"
          element={
            <ProtectedRoutes pageName="leads">
              <NewLead />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/lead/view/:id/profile"
          element={
            <ProtectedRoutes pageName="leads">
              <ViewLead routeName={VIEW_PROFILE} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/lead/view/:id/update"
          element={
            <ProtectedRoutes pageName="leads">
              <ViewLead routeName={VIEW_UPDATE} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/lead/view/:id/proposal"
          element={
            <ProtectedRoutes pageName="leads">
              <ViewLead routeName={VIEW_PROPOSAL} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/lead/view/:id/proposal/form"
          element={
            <ProtectedRoutes pageName="leads">
              <ViewLead routeName={ADD_EDIT_PROPOSAL} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/lead/view/:id/proposal/form/:proposalId"
          element={
            <ProtectedRoutes pageName="leads">
              <ViewLead routeName={ADD_EDIT_PROPOSAL} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/lead/view/:id/proposal/view/:proposalId"
          element={
            <ProtectedRoutes pageName="leads">
              <ViewLead routeName={VIEW_PROPOSAL_PAGE} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/lead/view/:id/quotation"
          element={
            <ProtectedRoutes pageName="leads">
              <ViewLead routeName={VIEWP_QUOTATION} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/lead/view/:id/invoice"
          element={
            <ProtectedRoutes pageName="leads">
              <ViewLead routeName={VIEW_INVOICE} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/lead/view/:id/request-invoice"
          element={
            <ProtectedRoutes pageName="leads">
              <ViewLead routeName={VIEW_REQUEST_INVOICE} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/lead/view/:id/po"
          element={
            <ProtectedRoutes pageName="leads">
              <ViewLead routeName={VIEW_PO} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/lead/view/:id/payment"
          element={
            <ProtectedRoutes pageName="leads">
              <ViewLead routeName={VIEW_PAYMENT} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/lead/view/:id/credential"
          element={
            <ProtectedRoutes pageName="leads">
              <ViewLead routeName={VIEW_CREDENTIAL} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/lead/view/:id/request-demo"
          element={
            <ProtectedRoutes pageName="leads">
              <ViewLead routeName={VIEW_REQUEST_DEMO} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/lead/trashed"
          element={
            <ProtectedRoutes pageName="leads">
              <DeletedLead />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />

        {/* item routes */}
        <Route
          path="/item"
          element={
            <ProtectedRoutes pageName="items">
              <Item />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/item/trashed"
          element={
            <ProtectedRoutes pageName="items">
              <TrashedItem />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />

        {/* payment routes */}
        <Route
          path="/payment"
          element={
            <ProtectedRoutes pageName="payments">
              <Payments />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/payment/view/:paymentId"
          element={
            <ProtectedRoutes pageName="payments">
              <ViewPaymentPage />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />

        {/* expense routes */}
        <Route
          path="/expense"
          element={
            <ProtectedRoutes pageName="expenses">
              <Expenses />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/expense/form"
          element={
            <ProtectedRoutes pageName="expenses">
              <AddUpdateExpense />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/expense/form/:expenseId"
          element={
            <ProtectedRoutes pageName="expenses">
              <AddUpdateExpense />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/expense/view/:expenseId"
          element={
            <ProtectedRoutes pageName="expenses">
              <ViewExpensePage />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/expense/trashed"
          element={
            <ProtectedRoutes pageName="expenses">
              <TrashedExpense />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />

        {/* credit-note routes */}
        <Route
          path="/credit-note"
          element={
            <ProtectedRoutes pageName="creditNotes">
              <CreditNotes />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/credit-note/form"
          element={
            <ProtectedRoutes pageName="creditNotes">
              <AddUpdateCreditNote />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/credit-note/form/:creditNoteId"
          element={
            <ProtectedRoutes pageName="creditNotes">
              <AddUpdateCreditNote />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/credit-note/view/:creditNoteId"
          element={
            <ProtectedRoutes pageName="creditNotes">
              <ViewCreditNotePage />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/credit-note/trashed"
          element={
            <ProtectedRoutes pageName="creditNotes">
              <TrashedCreditNote />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />

        {/* demo-request routes */}
        <Route
          path="/demo-request"
          element={
            <ProtectedRoutes pageName="demoRequests">
              <DemoRequest />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />

        {/* task routes */}
        <Route
          path="/task"
          element={
            <ProtectedRoutes pageName="tasks">
              <Task />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/task/view/:id"
          element={
            <ProtectedRoutes pageName="tasks">
              <ViewTaskPage />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />

        {/* calendar routes */}
        <Route
          path="/calendar"
          element={
            <ProtectedRoutes pageName="calendar">
              <ViewCalendar />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />

        {/* todo routes */}
        <Route path="/todo" element={<Todo />} errorElement={<ErrorPage />} />

        {/* reminder routes */}
        <Route
          path="/reminder"
          element={<Reminder />}
          errorElement={<ErrorPage />}
        />

        {/* invoice routes */}
        <Route
          path="/invoice"
          element={
            <ProtectedRoutes pageName="invoices">
              <Invoice />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/invoice/form"
          element={
            <ProtectedRoutes pageName="invoices">
              <AddUpdateInvoice />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/invoice/form/:invoiceId"
          element={
            <ProtectedRoutes pageName="invoices">
              <AddUpdateInvoice />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/invoice/view/:invoiceId"
          element={
            <ProtectedRoutes pageName="invoices">
              <ViewInvoicePage />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/invoice/cancelled"
          element={
            <ProtectedRoutes pageName="invoices">
              <ViewCancelledInvoice />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/invoice/request-invoice"
          element={
            <ProtectedRoutes pageName="invoices">
              <RequestedInvoice />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />

        {/* user routes */}
        <Route
          path="/user"
          element={
            <ProtectedRoutes pageName="userManagement">
              <User />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/user/user-role/form"
          element={
            <ProtectedRoutes pageName="userManagement">
              <AddUpdateUser routeName={USER_ROLES} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/user/user-role/form/:userId"
          element={
            <ProtectedRoutes pageName="userManagement">
              <AddUpdateUser routeName={USER_ROLES} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/user/user-details/form/:userId"
          element={
            <ProtectedRoutes pageName="userManagement">
              <AddUpdateUser routeName={USER_DETAILS} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/user/bank-details/form/:userId"
          element={
            <ProtectedRoutes pageName="userManagement">
              <AddUpdateUser routeName={USER_BANK_DETAILS} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/user/office-details/form/:userId"
          element={
            <ProtectedRoutes pageName="userManagement">
              <AddUpdateUser routeName={USER_OFFICE_DETAILS} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/user/documents/form/:userId"
          element={
            <ProtectedRoutes pageName="userManagement">
              <AddUpdateUser routeName={USER_DOCUMENTS} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/user/attendance/form/:userId"
          element={
            <ProtectedRoutes pageName="userManagement">
              <AddUpdateUser routeName={USER_ATTENDANCE} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/user/salary-slip/form/:userId"
          element={
            <ProtectedRoutes pageName="userManagement">
              <AddUpdateUser routeName={USER_SALARY_SLIP} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/user/your-details"
          element={
            <ProtectedRoutes pageName="userManagement">
              <UserProfileView routeName={YOUR_DETAILS} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/user/your-bank-details"
          element={
            <ProtectedRoutes pageName="userManagement">
              <UserProfileView routeName={YOUR_BANK_DETAILS} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/user/your-office-details"
          element={
            <ProtectedRoutes pageName="userManagement">
              <UserProfileView routeName={YOUR_OFFICE_DETAILS} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/user/your-documents"
          element={
            <ProtectedRoutes pageName="userManagement">
              <UserProfileView routeName={YOUR_DOCUMENTS} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/user/your-attendance"
          element={
            <ProtectedRoutes pageName="userManagement">
              <UserProfileView routeName={YOUR_ATTENDANCE} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/user/your-salary-slip"
          element={
            <ProtectedRoutes pageName="userManagement">
              <UserProfileView routeName={YOUR_SALARY_SLIP} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />

        {/* attendance routes */}
        <Route
          path="/attendance"
          element={
            <ProtectedRoutes pageName="attendance">
              <Attendance />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/attendance/leave"
          element={
            <ProtectedRoutes pageName="attendance">
              <AttendanceLeave />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />

        {/* setting routes */}
        <Route
          path="/setting/general"
          element={
            <ProtectedRoutes pageName="settings">
              <Setting routeName={SETTING_GENERAL} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/setting/profile"
          element={
            <ProtectedRoutes pageName="settings">
              <Setting routeName={SETTING_PROFILE} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/setting/location"
          element={
            <ProtectedRoutes pageName="settings">
              <Setting routeName={SETTING_LOCATION} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/setting/department"
          element={
            <ProtectedRoutes pageName="settings">
              <Setting routeName={SETTING_DEPARTMENT} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        {/* <Route
          path="/setting/account"
          element={
            <ProtectedRoutes pageName="settings">
              <Setting routeName={SETTING_ACCOUNT} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        /> */}
        <Route
          path="/setting/user-role"
          element={
            <ProtectedRoutes pageName="settings">
              <Setting routeName={SETTING_USER_ROLE} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/setting/gst"
          element={
            <ProtectedRoutes pageName="settings">
              <Setting routeName={SETTING_GST} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        {/* <Route
          path="/setting/taxes"
          element={
            <ProtectedRoutes pageName="settings">
              <Setting routeName={SETTING_TAXES} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        /> */}
        <Route
          path="/setting/employee"
          element={
            <ProtectedRoutes pageName="settings">
              <Setting routeName={SETTING_EMPLOYEE} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/setting/salary-slip"
          element={
            <ProtectedRoutes pageName="settings">
              <Setting routeName={SETTING_SALARY_SLIP} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/setting/invoice"
          element={
            <ProtectedRoutes pageName="settings">
              <Setting routeName={SETTING_INVOICE} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/setting/expense"
          element={
            <ProtectedRoutes pageName="settings">
              <Setting routeName={SETTING_EXPENSE} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/setting/proposal"
          element={
            <ProtectedRoutes pageName="settings">
              <Setting routeName={SETTING_PROPOSAL} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/setting/payment"
          element={
            <ProtectedRoutes pageName="settings">
              <Setting routeName={SETTING_PAYMENT} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/setting/credit-note"
          element={
            <ProtectedRoutes pageName="settings">
              <Setting routeName={SETTING_CREDITNOTE} />
            </ProtectedRoutes>
          }
          errorElement={<ErrorPage />}
        />

        {/* user profile */}
        <Route
          path="/user-profile"
          element={<UserProfile />}
          errorElement={<ErrorPage />}
        />
      </Route>,
    ])
  );

  return <RouterProvider router={router} />;
};

export default AllRoutes;
