import Api from "../../../config/api";
import {
  CREATE_PROPOSAL,
  SEARCH_PROPOSAL,
  SEARCH_PROPOSAL_BY_ID,
  UPDATE_PROPOSAL,
} from "../../../utils/constants";
import { showToast } from "../../toast/ShowToast";

export const getProposal = async (params: any) => {
  try {
    const res = await Api(false).get(SEARCH_PROPOSAL, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const getProposalById = async (id?: string) => {
  try {
    const res = await Api(false).get(`${SEARCH_PROPOSAL_BY_ID}/${id}`);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const createProposal = async (body: any, hideLoader: () => void) => {
  try {
    const res = await Api(false).post(CREATE_PROPOSAL, body);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      let msg = error?.response?.data?.message.split(":");
      if (msg[2] === " proposalNumber_1_company_1 dup key") {
        showToast(`Proposal number already taken`, "error");
        hideLoader();
        return error;
      } else {
        showToast(`${error?.response?.data?.message}`, "error");
        hideLoader();
      }
    }
  }
};

export const updateProposal = async (
  body: any,
  id: string,
  hideLoader: () => void
) => {
  try {
    const res = await Api(false).put(`${UPDATE_PROPOSAL}/${id}`, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};
