import React, { useContext } from "react";
import InputField from "../../../../shared components/TextInput";
import { CgClose } from "react-icons/cg";
import { commonType } from "../../../../pages/main/lead";
import SingleFileInput from "../../../../shared components/SingleFileInput";
import UpdationDetail from "../../../Data Update Detail";
import { ViewLeadContext } from "../../../../pages/main/lead/view lead";
import useLoading from "../../../../hooks/useLoading";
import { POObject } from "./Type";
import { useFormik } from "formik";
import { POSchema } from "../../../../utils/validationSchema";
import { createPO, updatePO } from "../../../../lib/api functions/lead/po";
import { showToast } from "../../../../lib/toast/ShowToast";
import InputError from "../../../../shared components/InputError/InputError";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall: (val: any) => void;
  params: any;
};

const Form: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params } = props;
  const { id, getActivity } = useContext(ViewLeadContext);
  const [loading, showLoader, hideLoader] = useLoading();

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "";
    },
  };

  const initialValues: POObject = {
    title: state?.data?.title ?? "",
    attachments: [],
    deleteAttachments: [],
    allAttachments: state?.data?.attachments ?? [],
    viewAttachments: state?.data?.attachments ?? [],
  };

  const formik = useFormik<POObject>({
    initialValues,
    validationSchema: POSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      for (let i = 0; i < values.attachments.length; i++) {
        formData.append("attachments", values.attachments[i]);
      }

      state.data &&
        values.deleteAttachments.length !== 0 &&
        formData.append(
          "deleteAttachments",
          JSON.stringify(values.deleteAttachments)
        );
      state.data &&
        formData.append(
          "allAttachments",
          JSON.stringify(values.allAttachments)
        );
      !state.data && formData.append("title", values.title);
      !state.data && formData.append("lead", id);

      showLoader();
      if (state?.data) {
        const res = await updatePO(formData, state?.data?._id, hideLoader);
        if (res) {
          showToast(`Purchase order updated`, "success");
          fn.setPositionToBody();
          apiCall(params);
          getActivity({
            lead: id,
          });
          hideLoader();
        }
      } else {
        const res = await createPO(formData, hideLoader);
        if (res) {
          showToast(`Purchase order created`, "success");
          fn.setPositionToBody();
          apiCall(params);
          getActivity({
            lead: id,
          });
          hideLoader();
        }
      }
    },
  });

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">
              {state.data ? "Edit P. O." : "Attach New P. O."}
            </h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <InputField
                    id="title"
                    label="Title*"
                    type="text"
                    state={formik.values.title}
                    setState={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isDisabled={state.data ? true : false}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <InputError error={formik.errors.title} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <SingleFileInput
                    id="attachments"
                    label="Attach File"
                    state={formik.values.attachments}
                    formik={formik}
                  />
                  {formik.errors.attachments ? (
                    <InputError error={formik.errors.attachments} />
                  ) : null}
                </div>
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>
                <button className="btn font12x500 shadow-none custom_btn ms-3">
                  {state.data ? (
                    loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )
                  ) : loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>

            {state.data && (
              <UpdationDetail
                lastUpdate={state?.data?.updatedAt}
                updatedBy={state?.data?.updatedBy}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
