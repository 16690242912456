import React, { createContext, useContext, useState } from "react";
import { ViewLeadContext } from "../..";
import ProfileProcess from "../../../../../../components/View Lead Components/MenuData/Profile Components";
import Skeleton from "react-loading-skeleton";

export const ProfileContext = createContext<any>(null);

const ViewProfile = () => {
  const { loading } = useContext(ViewLeadContext);
  const [step, setStep] = useState<number>(1);

  return (
    <>
      <div>
        <div className="viewlead_content_header">
          <p className="m-0 font14x500 textColor3">Profile</p>
        </div>

        {loading ? (
          <Skeleton height="190px" borderRadius="8px" />
        ) : (
          <div className="menu_content_wrapper">
            <div className="profile_menu_section d-flex">
              <p
                className={`font12x500 mb-0 ${
                  step === 1
                    ? "profile_menus_active"
                    : "profile_menus textColor5"
                }`}
                onClick={() => setStep(1)}
              >
                Person Details
              </p>
              <p
                className={`font12x500 mb-0 ${
                  step === 2
                    ? "profile_menus_active"
                    : "profile_menus textColor5"
                }`}
                onClick={() => setStep(2)}
              >
                Company Details
              </p>
              <p
                className={`font12x500 mb-0 ${
                  step === 3
                    ? "profile_menus_active"
                    : "profile_menus textColor5"
                }`}
                onClick={() => setStep(3)}
              >
                Billing Address
              </p>
            </div>

            <ProfileContext.Provider value={{ step }}>
              <ProfileProcess />
            </ProfileContext.Provider>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewProfile;
