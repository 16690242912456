import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import useLoading from "../../../../hooks/useLoading";
import { getDocumentNumberById } from "../../../../lib/api functions/common";
import { getRole } from "../../../../lib/api functions/setting/role";
import { createUser, updateUser } from "../../../../lib/api functions/user";
import { showToast } from "../../../../lib/toast/ShowToast";
import { UserContext } from "../../../../pages/main/user/add update user";
import { UserObject } from "../../../../pages/main/user/add update user/type";
import InputError from "../../../../shared components/InputError/InputError";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import RadioButton from "../../../../shared components/RadioButton/RadioButton";
import InputField from "../../../../shared components/TextInput";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import { userRolesDetailsSchema } from "../../../../utils/validationSchema";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import ChangePassword from "./ChangePassword";
import PhoneCode from "../../../../shared components/Dropdown/PhoneCode";

const UserRoles = () => {
  const { companyId } = useContext(MainContext);
  const [commonData, setCommonData] = useState({
    modal: false,
  });
  const { userId, user, userLoading, getUser } = useContext(UserContext);
  const [roles, setRoles] = useState<any>([]);
  const [loading, showLoader, hideLoader] = useLoading();
  const [roleLoading, showLoader2, hideLoader2] = useLoading();
  const navigate = useNavigate();
  const [isPasswordShow, setIsPasswordShow] = useState<boolean>(false);
  const passRef = useRef<any>(null);
  const companyObj = JSON.parse(window.localStorage.getItem("company") || "");

  const fn = {
    openModal: () => {
      setCommonData({
        modal: true,
      });
      document.body.style.overflow = "hidden";
    },
    getRoles: async (params: any) => {
      showLoader2();
      const res = await getRole(params);
      if (res) {
        setRoles(res.data.roles);
        hideLoader2();
      }
    },
    passwordToggle: () => {
      if (passRef?.current?.type === "password") {
        passRef.current.type = "text";
        setIsPasswordShow(true);
      } else {
        passRef.current.type = "password";
        setIsPasswordShow(false);
      }
    },
    getDocNumber: async (params?: any) => {
      const res = await getDocumentNumberById(companyId, params);
      if (res) {
        formik.setFieldValue(
          "customUserId",
          res.data.documentNumber.user.number.toString()
        );
      }
    },
    putUserValueInState: () => {
      formik.setFieldValue("customUserId", user.customUserId);
      formik.setFieldValue("name", user.name);
      formik.setFieldValue("email", user.email);
      formik.setFieldValue("mobile", user.mobile);
      formik.setFieldValue("phoneCode", user.phoneCode);
      formik.setFieldValue("password", user.password);
      formik.setFieldValue("role", user.role);
      formik.setFieldValue("status", user.status);
      // formik.setFieldValue("isEdit", user.password?.length ? true : false);
      formik.setFieldValue("isEdit", userId ? true : false);
    },
  };
  const initialValues: UserObject = {
    customUserId: "",
    name: "",
    employeeId: "",
    email: "",
    mobile: "",
    phoneCode: companyObj?.phoneCode,
    password: "",
    role: "",
    status: 1,
    isEdit: false,
  };

  const formik = useFormik<UserObject>({
    initialValues,
    validationSchema: userRolesDetailsSchema,
    onSubmit: async (values) => {
      let finalObj: UserObject = {};

      if (!userId) {
        finalObj["customUserId"] = values.customUserId;
        finalObj["name"] = values.name;
        finalObj["employeeId"] = values.employeeId;
        finalObj["email"] = values.email?.toLowerCase();
        finalObj["mobile"] = values.mobile;
        finalObj["phoneCode"] = values.phoneCode;
        finalObj["status"] = 1;
      } else {
        finalObj["status"] = values.status;
      }
      if (!user.password?.length) {
        finalObj["password"] = values.password;
      }
      finalObj = {
        ...finalObj,
        role: values.role,
      };

      showLoader();
      if (userId) {
        const res = await updateUser(finalObj, userId, hideLoader);
        if (res) {
          showToast(`User updated`, "success");
          getUser();
          hideLoader();
        }
      } else {
        const res = await createUser(finalObj, hideLoader);
        if (res) {
          showToast(`User created`, "success");
          navigate("/user", { replace: true });
        }
      }
    },
  });

  useEffect(() => {
    fn.getRoles({
      f: "name",
      company: companyId,
    });
  }, []);
  useEffect(() => {
    if (!userId) {
      fn.getDocNumber({ f: "user" });
    }
  }, []);
  useEffect(() => {
    !userLoading && userId && fn.putUserValueInState();
  }, [userLoading]);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font16x600 textColor3">User Roles</p>
        </div>

        {userLoading ? (
          <p>loading...</p>
        ) : (
          <div className="details_form_wrapper">
            {/* form */}
            <form onSubmit={formik.handleSubmit}>
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-12 col-sm-6 order-2 order-sm-1">
                    <div className="mb-3">
                      <InputField
                        id="customUserId"
                        label="User Id"
                        type="text"
                        state={formik.values.customUserId}
                        setState={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isDisabled={true}
                      />
                      {formik.touched.customUserId &&
                      formik.errors.customUserId ? (
                        <InputError error={formik.errors.customUserId} />
                      ) : null}
                    </div>

                    {!userId && (
                      <>
                        <div className="mb-3">
                          <InputField
                            id="name"
                            label="Name*"
                            type="text"
                            state={formik.values.name}
                            setState={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <InputError error={formik.errors.name} />
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <InputField
                            id="employeeId"
                            label="Employee Id*"
                            type="text"
                            state={formik.values.employeeId}
                            setState={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.employeeId &&
                          formik.errors.employeeId ? (
                            <InputError error={formik.errors.employeeId} />
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <InputField
                            id="email"
                            label="Email*"
                            type="text"
                            state={formik.values.email}
                            setState={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <InputError error={formik.errors.email} />
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="contactNumber"
                            className="form-label font12x400 textColor3 mb-1"
                          >
                            Phone No*
                          </label>
                          <div className="input-group">
                            <PhoneCode
                              formik={formik}
                              fieldName="phoneCode"
                              fieldName2="mobile"
                            />
                            <input
                              type="text"
                              className="form-control shadow-none font12x600 border-0"
                              id="mobile"
                              value={formik.values.mobile}
                              onChange={(e) => {
                                formik.setFieldValue("mobile", e.target.value);
                              }}
                              onBlur={formik.handleBlur}
                              autoComplete="new-mobile"
                            />
                          </div>
                          {formik.touched.mobile && formik.errors.mobile ? (
                            <InputError error={formik.errors.mobile} />
                          ) : null}
                          {formik.touched.phoneCode &&
                          formik.errors.phoneCode ? (
                            <InputError error={formik.errors.phoneCode} />
                          ) : null}
                        </div>
                      </>
                    )}

                    {!user.password?.length && (
                      <div className="mb-3">
                        <label
                          htmlFor="password"
                          className="form-label font12x400 textColor3 mb-1"
                        >
                          Password
                        </label>
                        <div className="input-group">
                          <input
                            ref={passRef}
                            type="password"
                            className="form-control shadow-none font12x600 border-0"
                            id="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="new-password"
                          />
                          <div
                            className="input-group-text font12x600 border-0 cursor_pointer"
                            onClick={fn.passwordToggle}
                          >
                            {isPasswordShow ? (
                              <EyeOutlined className="textColor1" />
                            ) : (
                              <EyeInvisibleOutlined className="textColor1" />
                            )}
                          </div>
                        </div>
                        {formik.touched.password && formik.errors.password ? (
                          <InputError error={formik.errors.password} />
                        ) : null}
                      </div>
                    )}

                    {/* change password */}
                    {userId && user.password?.length && (
                      <div className="mt-4">
                        <div className="mb-2">
                          <h3 className="font12x600 textColor3 mb-0">
                            Change Password
                          </h3>
                          <p className="mt-2 mb-0 font12x400 textColor3">
                            You can change password from{" "}
                            <span
                              className="font12x500 textColor2 cursor_pointer"
                              onClick={fn.openModal}
                            >
                              here
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="mt-4">
                    <div className="mb-2">
                      <h3 className="font12x600 textColor3 mb-0">Role</h3>
                    </div>

                    {roles?.length !== 0 ? (
                      <div className="d-flex flex-column flex-wrap gap-2 flex-sm-row gap-sm-3">
                        {roles?.map((role: any, i: number) => {
                          return (
                            <RadioButton
                              key={i}
                              label={role?.name}
                              htmlFor={role?.name}
                              name="role"
                              value={role?.name}
                              state={formik.values.role}
                              setState={(x?: string | number) => {
                                formik.setFieldValue("role", x);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched("role", true);
                              }}
                              // isDisabled={userId ? true : false}
                            />
                          );
                        })}
                      </div>
                    ) : roleLoading ? (
                      <div className="d-flex flex-column gap-2 flex-sm-row gap-sm-4">
                        <div className="d-flex gap-2">
                          <Skeleton width={16} height={16} borderRadius={80} />
                          <Skeleton width={80} borderRadius={4} />
                        </div>
                        <div className="d-flex gap-2">
                          <Skeleton width={16} height={16} borderRadius={80} />
                          <Skeleton width={80} borderRadius={4} />
                        </div>
                        <div className="d-flex gap-2">
                          <Skeleton width={16} height={16} borderRadius={80} />
                          <Skeleton width={80} borderRadius={4} />
                        </div>
                      </div>
                    ) : (
                      <p className="mb-0 font12x400 textColor3">
                        No roles found. Please add a{" "}
                        <span
                          className="font12x500 textColor2 cursor_pointer"
                          onClick={() => navigate("/setting/user-role")}
                        >
                          role
                        </span>
                      </p>
                    )}
                    {formik.touched.role && formik.errors.role ? (
                      <InputError error={formik.errors.role} />
                    ) : null}
                  </div>
                </div>

                {userId && (
                  <div className="mb-4">
                    <label className="form-label font12x600 textColor3 mb-1">
                      Status
                    </label>
                    <div className="d-flex gap-3">
                      <RadioButton
                        label="Active"
                        htmlFor="active"
                        name="status"
                        value={1}
                        state={formik.values.status}
                        setState={(x?: string | number) => {
                          formik.setFieldValue("status", Number(x));
                        }}
                      />
                      <RadioButton
                        label="Inactive"
                        htmlFor="inactive"
                        name="status"
                        value={2}
                        state={formik.values.status}
                        setState={(x?: string | number) => {
                          formik.setFieldValue("status", Number(x));
                        }}
                      />
                    </div>
                    {formik.touched.status && formik.errors.status ? (
                      <InputError error={formik.errors.status} />
                    ) : null}
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-end my-4">
                <button
                  type="submit"
                  className="btn font12x500 shadow-none ms-3 custom_btn"
                >
                  {userId ? (
                    loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )
                  ) : loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>

      {/* change password modal */}
      {commonData?.modal && (
        <ChangePassword userId={userId} setState={setCommonData} />
      )}
    </>
  );
};

export default UserRoles;
