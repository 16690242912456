import React, { useContext, useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { commonType } from "../../../../pages/main/lead";
import useLoading from "../../../../hooks/useLoading";
import {
  getInvoice,
  updateInvoice,
} from "../../../../lib/api functions/invoice/invoice";
import { ViewLeadContext } from "../../../../pages/main/lead/view lead";
import { leadInvoiceSchema } from "../../../../utils/validationSchema";
import { InvoiceObject } from "./Type";
import { useFormik } from "formik";
import InputError from "../../../../shared components/InputError/InputError";
import DropdownTwo from "../../../../shared components/Dropdown/DropdownTwo";
import { showToast } from "../../../../lib/toast/ShowToast";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall: (val: any) => void;
  params: any;
};

type ParamType = {
  f: string;
  client: string;
  company: string;
  isCancelled: boolean;
};

const Form: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params } = props;
  const { companyId } = useContext(MainContext);
  const { id, lead, getActivity } = useContext(ViewLeadContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [invoices, setInvoices] = useState<any>([]);

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "";
    },
    getInvoices: async (params: ParamType) => {
      const res = await getInvoice(params);
      if (res) {
        setInvoices(res.data.invoices);
      }
    },
  };

  useEffect(() => {
    fn.getInvoices({
      f: "invoiceNumber",
      client: `${lead?.client?._id}`,
      company: companyId,
      isCancelled: false,
    });
  }, []);

  const initialValues: InvoiceObject = {
    invoiceNumber: "",
    invoiceId: "",
  };

  const formik = useFormik<InvoiceObject>({
    initialValues,
    validationSchema: leadInvoiceSchema,
    onSubmit: async (values) => {
      const formObj = {
        lead: id,
      };
      showLoader();
      const res = await updateInvoice(formObj, values.invoiceId, hideLoader);
      if (res) {
        showToast(`Invoice Added`, "success");
        fn.setPositionToBody();
        apiCall(params);
        getActivity({ lead: id });
        hideLoader();
      }
    },
  });

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">Add Invoice</h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <DropdownTwo
                    label="Invoice Number*"
                    placeholder="Select invoice number"
                    name="invoiceNumber"
                    data={invoices}
                    state={formik.values.invoiceNumber}
                    setState={(e?: string, x?: string | number) => {
                      formik.setFieldValue("invoiceNumber", e);
                      formik.setFieldValue("invoiceId", x);
                    }}
                    labelField="invoiceNumber"
                    onBlur={() => formik.setFieldTouched("invoiceNumber", true)}
                  />
                  {formik.touched.invoiceNumber &&
                  formik.errors.invoiceNumber ? (
                    <InputError error={formik.errors.invoiceNumber} />
                  ) : null}
                </div>
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>
                <button className="btn font12x500 shadow-none custom_btn ms-3">
                  {loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
