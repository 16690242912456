import { useFormik } from "formik";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UpdationDetail from "../../../../components/Data Update Detail";
import AddProposalList from "../../../../components/Expense Component/Add Update Components/AddProposalList";
import Calculation from "../../../../components/Expense Component/Add Update Components/Calculation";
import Form from "../../../../components/Expense Component/Add Update Components/Form";
import { generateCompleteAddress } from "../../../../function/other";
import useLoading from "../../../../hooks/useLoading";
import { getClient, getClientById } from "../../../../lib/api functions/client";
import { getDocumentNumberById } from "../../../../lib/api functions/common";
import {
  createExpense,
  getExpenseById,
  updateExpense,
} from "../../../../lib/api functions/expense";
import { getItem, getItemById } from "../../../../lib/api functions/item";
import { getTax } from "../../../../lib/api functions/setting/tax";
import { showToast } from "../../../../lib/toast/ShowToast";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import { expenseSchema } from "../../../../utils/validationSchema";
import { ExpenseType, ItemsType } from "./type";

export const ExpenseContext = createContext<any>(null);

const AddUpdateExpense = () => {
  const { setPageTitle, companyId } = useContext(MainContext);
  const { expenseId } = useParams();
  useEffect(() => {
    setPageTitle(expenseId ? "Update Expense" : "Add Expense");
  }, []);
  const companyObj = JSON.parse(window.localStorage.getItem("company") || "");
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const [vendorloading, showLoader2, hideLoader2] = useLoading();
  const [itemsList, setItemsList] = useState<any>([]);
  const [vendorList, setVendorList] = useState<any>([]);
  const [clientList, setClientList] = useState<any>([]);
  const [taxes, setTaxes] = useState<any>([]);
  const [calculation, setCalculation] = useState<any>({
    subTotal: 0,
    cgst: 0,
    sgst: 0,
    igst: 0,
    discount: 0,
    total: 0,
  });
  const [expense, setExpense] = useState<ExpenseType>({
    vendor: "",
    vendorName: "",
    clientReference: null,
    clientName: "",
    vendorAddress: "",
    placeOfSupply: "",
    companyState: companyObj?.state,
    gstNo: "",
    number: "",
    invoiceNumber: "",
    date: "",
    dueDate: "",
    items: [],
  });
  const [singleItem, setSingleItem] = useState<ItemsType>({
    name: "",
    description: "",
    unit: "",
    hsnOrsac: "",
    qty: 0,
    unitQtyPrice: 0,
    discount: 0,
    cgst: 0,
    sgst: 0,
    igst: 0,
    tax: 0,
    total: 0,
    isEditable: false,
  });
  const [updationDetails, setUpdationDetails] = useState({
    lastUpdate: "",
    updatedBy: undefined,
  });

  const fn = {
    getDocNumber: async (params?: any) => {
      const res = await getDocumentNumberById(companyId, params);
      if (res) {
        formik.setFieldValue(
          "number",
          res.data.documentNumber.expense.number.toString()
        );
      }
    },
    getGstTaxes: async (params?: any) => {
      const res = await getTax(params);
      if (res) {
        setTaxes(res.data.taxes);
      }
    },
    getAllVendors: async (params?: any) => {
      const res = await getClient(params);
      if (res) {
        setVendorList(res.data.clients);
      }
    },
    getAllClients: async (params?: any) => {
      const res = await getClient(params);
      if (res) {
        setClientList(res.data.clients);
      }
    },
    getVendorById: async () => {
      showLoader2();
      const res = await getClientById(formik.values.vendor);
      if (res) {
        formik.setFieldValue(
          "vendorAddress",
          generateCompleteAddress(
            res.data?.client?.completeAddress?.address,
            res.data?.client?.completeAddress?.city,
            res.data?.client?.completeAddress?.state,
            res.data?.client?.completeAddress?.country,
            res.data?.client?.completeAddress?.pincode
          )
            .filter((item) => item?.length)
            .join(", ")
        );
        formik.setFieldValue("gstNo", res.data.client.gstin);
        if (!expenseId) {
          formik.setFieldValue(
            "placeOfSupply",
            res.data.client.completeAddress?.state
          );
        }
        if (expenseId) {
          formik.setFieldValue("vendorName", res.data.client.name);
        }
        hideLoader2();
      }
    },
    getExpenseById: async () => {
      showLoader();
      const res = await getExpenseById(expenseId);
      if (res) {
        formik.setFieldValue("vendor", res.data.expense.vendor?._id);
        formik.setFieldValue(
          "clientName",
          res.data.expense.clientReference?.name
        );
        formik.setFieldValue(
          "clientReference",
          res.data.expense.clientReference?._id
        );
        formik.setFieldValue("number", res.data.expense.number);
        formik.setFieldValue("placeOfSupply", res.data.expense.placeOfSupply);
        formik.setFieldValue("invoiceNumber", res.data.expense.invoiceNumber);
        formik.setFieldValue(
          "date",
          moment(res.data.expense.date).format("YYYY-MM-DD")
        );
        formik.setFieldValue(
          "dueDate",
          moment(res.data.expense.dueDate).format("YYYY-MM-DD")
        );
        formik.setFieldValue("items", res.data.expense.items);
        setCalculation({
          subTotal: res.data.expense?.calculation.subTotal,
          cgst: res.data.expense?.calculation.cgst,
          sgst: res.data.expense?.calculation.sgst,
          igst: res.data.expense?.calculation.igst,
          discount: res.data.expense?.calculation.discount,
          total: res.data.expense?.calculation.total,
        });
        setUpdationDetails({
          lastUpdate: res.data.expense.updatedAt,
          updatedBy: res.data.expense.updatedBy,
        });
        hideLoader();
      }
    },
  };

  const formik = useFormik<ExpenseType>({
    initialValues: expense,
    validationSchema: expenseSchema,
    onSubmit: async (values) => {
      let finalItem: any = [];
      values?.items?.forEach((item) => {
        finalItem.push({
          name: item.name,
          hsnOrsac: item.hsnOrsac,
          description: item.description,
          unit: item.unit ?? "",
          qty: item.qty,
          unitQtyPrice: item.unitQtyPrice,
          discount: item.discount,
          tax: item.tax,
          total: item.total,
        });
      });
      showLoader();
      let finalObj = {};
      if (expenseId) {
        finalObj = {
          date: values.date,
          dueDate: values.dueDate,
          items: finalItem,
          placeOfSupply: values.placeOfSupply,
          calculation: calculation,
        };
        const res = await updateExpense(finalObj, expenseId, hideLoader);
        if (res) {
          showToast(`Expense updated`, "success");
          hideLoader();
        }
      } else {
        finalObj = {
          vendor: values.vendor,
          clientReference: values.clientReference,
          number: values.number,
          invoiceNumber: values.invoiceNumber,
          date: values.date,
          dueDate: values.dueDate,
          placeOfSupply: values.placeOfSupply,
          items: finalItem,
          calculation: calculation,
        };
        const res = await createExpense(finalObj, hideLoader);
        if (res.response && res.response.status === 502) {
          formik.setFieldError("number", "Expense number already taken");
        } else {
          showToast(`Expense created`, "success");
          navigate(`/expense`);
          hideLoader();
        }
      }
    },
  });

  useEffect(() => {
    if (!expenseId) {
      fn.getDocNumber({ f: "expense" });
    }
    fn.getAllVendors({
      company: companyId,
      f: "name",
      type: JSON.stringify([2, 3]),
    });
    fn.getAllClients({
      company: companyId,
      f: "name",
      type: JSON.stringify([1, 3]),
    });
    fn.getGstTaxes({
      f: "percent",
      type: 1,
      company: companyId,
    });
  }, []);
  useEffect(() => {
    if (formik.values.vendor) {
      fn.getVendorById();
    }
  }, [formik.values.vendor]);
  useEffect(() => {
    if (expenseId) {
      fn.getExpenseById();
    }
  }, [expenseId]);

  return (
    <>
      <div
        className="single_page"
        style={{
          overflow: "unset",
        }}
      >
        <div className="back_section px-3">
          <div
            className="cursor_pointer d-flex align-items-center"
            onClick={() => navigate(`/expense`)}
          >
            <img src="/Assets/Svg/backBtn.svg" alt="close" />
            <h3 className="mb-0 font16x600 textColor3 d-inline-block ms-2">
              Back to list
            </h3>
          </div>
        </div>
        <div className="form_section">
          <form onSubmit={formik.handleSubmit}>
            <ExpenseContext.Provider
              value={{
                expenseId,
                formik,
                itemsList,
                vendorList,
                singleItem,
                setSingleItem,
                loading,
                setCalculation,
                calculation,
                vendorloading,
                taxes,
                clientList,
                getDocNumber: fn.getDocNumber,
              }}
            >
              <Form />

              <AddProposalList />

              <Calculation />
            </ExpenseContext.Provider>

            <div className="d-flex justify-content-end mt-5 px-3">
              {/* <button className="btn font12x500 shadow-none modalBtnOutline">
                Cancel
              </button> */}
              <button
                type="submit"
                className="btn font12x500 shadow-none custom_btn ms-3"
              >
                {expenseId ? (
                  loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Update"
                  )
                ) : loading ? (
                  <SpinLoader height="20" width="20" color="#fff" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>

          {expenseId && (
            <UpdationDetail
              lastUpdate={updationDetails.lastUpdate}
              updatedBy={updationDetails.updatedBy}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AddUpdateExpense;
