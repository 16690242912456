import React from "react";
import { MutatingDots } from "react-loader-spinner";
import { PRIMARY_COLOR } from "../../utils/constants";

const MainLoader = () => {
  return (
    <>
      <div className="main_loader_wrapper">
        <MutatingDots
          height="100"
          width="100"
          color={PRIMARY_COLOR}
          secondaryColor={PRIMARY_COLOR}
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    </>
  );
};

export default MainLoader;
