import moment from "moment";
import React from "react";
import { showToast } from "../../../lib/toast/ShowToast";
import InputDateForm from "../../../shared components/DateInput/InputDateForm";
import InputError from "../../../shared components/InputError/InputError";

type propType = {
  formik: any;
  state: any;
};

const MultipleDateSelection: React.FC<propType> = (props) => {
  const { formik, state } = props;

  const fn = {
    putSingleDateIntoArray: () => {
      if (formik.values.dates.includes(formik.values.tempDate)) {
        showToast(`This date is already exist`, "error");
      } else {
        formik.setFieldValue("dates", [
          ...formik.values.dates,
          formik.values.tempDate,
        ]);
        formik.setFieldValue("tempDate", "");
      }
    },
    deleteSingleDateIntoArray: (date: string) => {
      const filterDates = formik.values.dates?.filter(
        (ele: any) => ele !== date
      );
      formik.setFieldValue("dates", filterDates);
    },
  };

  return (
    <>
      <div className="d-flex flex-column gap-2">
        {formik.values.dates?.map((item: any, i: number) => {
          return (
            <div key={i} className="d-flex gap-2">
              <InputDateForm
                id={`date_${item}`}
                type="date"
                state={item}
                setState={formik.handleChange}
                onBlur={formik.handleBlur}
                width="100%"
                isDisabled={true}
              />
              {state?.data ? (
                <div
                  className="btn h-auto d-flex justify-content-center align-items-center"
                  style={{
                    width: 40,
                    height: 40,
                    backgroundColor: "var(--error)",
                    cursor: "no-drop",
                    opacity: 0.5,
                  }}
                >
                  <img
                    src="/Assets/Svg/delete.svg"
                    alt="icon"
                    width={20}
                    height={20}
                  />
                </div>
              ) : (
                <div
                  className="btn h-auto d-flex justify-content-center align-items-center"
                  style={{
                    width: 40,
                    height: 40,
                    backgroundColor: "var(--error)",
                  }}
                  onClick={() => fn.deleteSingleDateIntoArray(item)}
                >
                  <img
                    src="/Assets/Svg/delete.svg"
                    alt="icon"
                    width={20}
                    height={20}
                  />
                </div>
              )}
            </div>
          );
        })}

        {!state?.data && (
          <div className="d-flex gap-2">
            <InputDateForm
              id="tempDate"
              type="date"
              state={moment(formik.values.tempDate).format("YYYY-MM-DD")}
              setState={formik.handleChange}
              onBlur={formik.handleBlur}
              width="100%"
              isDisabled={state?.data ? true : false}
            />
            {formik.values.tempDate !== "" ? (
              <div
                className="btn h-auto d-flex justify-content-center align-items-center"
                style={{
                  width: 40,
                  height: 40,
                  backgroundColor: "var(--bg_color2)",
                }}
                onClick={fn.putSingleDateIntoArray}
              >
                <img
                  src="/Assets/Svg/plusIcon.svg"
                  alt="icon"
                  width={15}
                  height={15}
                />
              </div>
            ) : (
              <div
                className="btn h-auto d-flex justify-content-center align-items-center"
                style={{
                  width: 40,
                  height: 40,
                  backgroundColor: "var(--bg_color2)",
                  cursor: "no-drop",
                  opacity: 0.5,
                }}
              >
                <img
                  src="/Assets/Svg/plusIcon.svg"
                  alt="icon"
                  width={15}
                  height={15}
                />
              </div>
            )}
          </div>
        )}
        {formik.touched.dates && formik.errors.dates ? (
          <InputError error={formik.errors.dates} />
        ) : null}
      </div>
    </>
  );
};

export default MultipleDateSelection;
