import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { ViewLeadContext } from "../..";
import { commonType } from "../../..";
import DataNotFound from "../../../../../../components/Data Not Found";
import Form from "../../../../../../components/View Lead Components/MenuData/Credential Component/Form";
import useLoading from "../../../../../../hooks/useLoading";
import { getCredential } from "../../../../../../lib/api functions/lead/credential";
import { credentialTypesMap } from "../../../../../../utils/constants";
import { ParamType } from "./type";

const ViewCredential = () => {
  const { id } = useContext(ViewLeadContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const [loading, showLoader, hideLoader] = useLoading();
  const [credentials, setCredentials] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [params, setParams] = useState<ParamType>({
    // page: 1,
    // per_page: 10,
    lead: id,
  });

  const fn = {
    openForm: (data: any) => {
      setCommonData({
        data: data ? data : undefined,
        modal: true,
        check: "credential_menu",
      });
      document.body.style.overflow = "hidden";
    },
    getCredentials: async (params?: ParamType) => {
      showLoader();
      const res = await getCredential(params);
      if (res) {
        setCredentials(res.data.credentials);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
  };

  useEffect(() => {
    fn.getCredentials(params);
  }, []);

  return (
    <>
      <div>
        <div className="viewlead_content_header d-flex justify-content-between">
          <p className="m-0 font14x500 textColor3">Attach Credentials</p>
          <button
            className="btn font12x500 buttonOne shadow-none"
            onClick={() => fn.openForm(null)}
          >
            <img src="/Assets/Svg/plusIcon.svg" alt="icon" className="me-2" />
            Attach New
          </button>
        </div>

        {loading ? (
          <Skeleton height="190px" borderRadius="8px" />
        ) : (
          <div className="menu_content_wrapper">
            {/* table */}
            {credentials?.length !== 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head_small">
                      <tr className="font12x500 textColor3">
                        <th scope="col">Title</th>
                        <th scope="col">User ID</th>
                        <th scope="col">Password</th>
                        <th scope="col">Type</th>
                        <th scope="col">Login URL</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {credentials?.map((credential: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <td>{credential?.title}</td>
                            <td>{credential?.userId}</td>
                            <td>{credential?.password}</td>
                            <td>{credentialTypesMap.get(credential?.type)}</td>
                            <td
                              style={{
                                maxWidth: 100,
                                wordBreak: "break-all",
                              }}
                            >
                              <p className="mb-0 font10x400 textColor2">
                                {credential?.loginLink}
                              </p>
                            </td>
                            <td>
                              <p
                                className="mb-0 font10x400 textColor2 cursor_pointer"
                                onClick={() => fn.openForm(credential)}
                              >
                                Edit
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <DataNotFound />
            )}
          </div>
        )}
      </div>

      {/* form modal */}
      {commonData?.modal && commonData?.check === "credential_menu" && (
        <Form
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getCredentials}
          params={params}
        />
      )}
    </>
  );
};

export default ViewCredential;
