import React, { useRef, useState } from "react";
import InputError from "../../../../shared components/InputError/InputError";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import { ChangePasswordForUser } from "../../../../pages/main/user/add update user/type";
import { passwordChangeForUserSchema } from "../../../../utils/validationSchema";
import useLoading from "../../../../hooks/useLoading";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import { showToast } from "../../../../lib/toast/ShowToast";
import { updateUser } from "../../../../lib/api functions/user";

type propType = {
  setState: (val: any) => void;
  userId: string;
};

const ChangePassword: React.FC<propType> = (props) => {
  const { setState, userId } = props;
  const [isNewPasswordShow, setIsNewPasswordShow] = useState<boolean>(false);
  const [isConfirmPasswordShow, setIsConfirmPasswordShow] =
    useState<boolean>(false);
  const newPassRef = useRef<any>(null);
  const confirmPassRef = useRef<any>(null);
  const [loading, showLoader, hideLoader] = useLoading();

  const fn = {
    setCommonState: () => {
      setState({
        modal: false,
      });
      document.body.style.overflow = "unset";
    },
    passwordToggle: (check: string) => {
      if (check === "new") {
        if (newPassRef?.current?.type === "password") {
          newPassRef.current.type = "text";
          setIsNewPasswordShow(true);
        } else {
          newPassRef.current.type = "password";
          setIsNewPasswordShow(false);
        }
      } else {
        if (confirmPassRef?.current?.type === "password") {
          confirmPassRef.current.type = "text";
          setIsConfirmPasswordShow(true);
        } else {
          confirmPassRef.current.type = "password";
          setIsConfirmPasswordShow(false);
        }
      }
    },
  };

  const initialValues: ChangePasswordForUser = {
    password: "",
    confirmPassword: "",
  };

  const formik = useFormik<ChangePasswordForUser>({
    initialValues,
    validationSchema: passwordChangeForUserSchema,
    onSubmit: async (values) => {
      let finalObj = {
        password: values.password,
      };

      showLoader();
      const res = await updateUser(finalObj, userId, hideLoader);
      if (res) {
        showToast(`User password changed`, "success");
        fn.setCommonState();
        hideLoader();
      }
    },
  });
  return (
    <>
      <div className="delete_modal_wrapper">
        <div
          className="delete_modal_section"
          style={{
            width: "500px",
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="font14x500 textColor3 mb-0">Change Password</h3>
            <img
              src="/Assets/Svg/closeIcon.svg"
              alt="close"
              style={{
                cursor: "pointer",
              }}
              onClick={fn.setCommonState}
            />
          </div>
          <div className="mt-2">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="form-label font12x400 textColor3 mb-1"
                >
                  New Password*
                </label>
                <div className="input-group">
                  <input
                    ref={newPassRef}
                    type="password"
                    className="form-control shadow-none font12x600 border-0"
                    id="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="new-password"
                  />
                  <div
                    className="input-group-text font12x600 border-0 cursor_pointer"
                    onClick={() => {
                      fn.passwordToggle("new");
                    }}
                  >
                    {isNewPasswordShow ? (
                      <EyeOutlined className="textColor1" />
                    ) : (
                      <EyeInvisibleOutlined className="textColor1" />
                    )}
                  </div>
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <InputError error={formik.errors.password} />
                ) : null}
              </div>

              <div className="mb-3">
                <label
                  htmlFor="confirmPassword"
                  className="form-label font12x400 textColor3 mb-1"
                >
                  Confirm Password*
                </label>
                <div className="input-group">
                  <input
                    ref={confirmPassRef}
                    type="password"
                    className="form-control shadow-none font12x600 border-0"
                    id="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="new-password"
                  />
                  <div
                    className="input-group-text font12x600 border-0 cursor_pointer"
                    onClick={() => {
                      fn.passwordToggle("confirm");
                    }}
                  >
                    {isConfirmPasswordShow ? (
                      <EyeOutlined className="textColor1" />
                    ) : (
                      <EyeInvisibleOutlined className="textColor1" />
                    )}
                  </div>
                </div>
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <InputError error={formik.errors.confirmPassword} />
                ) : null}
              </div>

              <div className="mt-4 d-flex gap-2 justify-content-end">
                <button
                  type="submit"
                  className="btn font12x500 shadow-none custom_btn"
                >
                  {loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Change"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
