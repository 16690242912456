import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DropdownOne from "../../../../shared components/Dropdown/DropdownOne";
import InputField from "../../../../shared components/TextInput";
import {
  BOTH_CLIENT_VENDOR,
  countryMap,
  currenciesMap,
  gstTypesMap,
  ONLY_CLIENT,
} from "../../../../utils/constants";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import { gettingCities, gettingStates } from "../../../../function/other";
import { ClientObject } from "./type";
import { useFormik } from "formik";
import { clientSchema } from "../../../../utils/validationSchema";
import InputError from "../../../../shared components/InputError/InputError";
import useLoading from "../../../../hooks/useLoading";
import {
  createClient,
  updateClient,
} from "../../../../lib/api functions/client";
import { showToast } from "../../../../lib/toast/ShowToast";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import PhoneCode from "../../../../shared components/Dropdown/PhoneCode";

const AddUpdateClient = () => {
  const { setPageTitle } = useContext(MainContext);
  useEffect(() => {
    setPageTitle(clientId ? "Update Client" : "Add New Client");
  }, []);
  const { clientId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, showLoader, hideLoader] = useLoading();
  const [cities, setCities] = useState<string[]>([]);
  const [states, setStates] = useState<string[]>([]);
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });

  const initialValues: ClientObject = {
    name: state?.client?.name ?? "",
    contactNumber: state?.client?.contactNumber ?? "",
    phoneCode:
      !state?.client?.phoneCode || state?.client?.phoneCode?.length === 0
        ? "+91"
        : "",
    email: state?.client?.email ?? "",
    website: state?.client?.website ?? "",
    gstType: state?.client?.gstType ?? "",
    gstin: state?.client?.gstin ?? "",
    pan: state?.client?.pan ?? "",
    currency: state?.client?.currency ?? "INR",
    type: state?.client?.type ?? ONLY_CLIENT,
    completeAddress: {
      address: state?.client?.completeAddress?.address ?? "",
      state: state?.client?.completeAddress?.state ?? "",
      city: state?.client?.completeAddress?.city ?? "",
      pincode: state?.client?.completeAddress?.pincode ?? "",
      country: state?.client?.completeAddress?.country ?? "India",
    },
    extra: {
      countryCode: state?.client?.completeAddress?.cCode ?? "IN",
      stateCode: state?.client?.completeAddress?.sCode ?? "",
    },
  };

  const formik = useFormik({
    initialValues,
    validationSchema: clientSchema,
    onSubmit: async (values) => {
      let finalObj = {};

      delete updatedValues.isObjChange;
      if (clientId) {
        finalObj = {
          ...updatedValues,
          completeAddress: {
            address: values.completeAddress.address,
            state: values.completeAddress.state,
            city: values.completeAddress.city,
            pincode: values.completeAddress.pincode,
            country: values.completeAddress.country,
            cCode: values.extra.countryCode,
            sCode: values.extra.stateCode,
          },
        };
      } else {
        finalObj = {
          // name: values.name,
          // contactNumber: values.contactNumber,
          // phoneCode: values.phoneCode,
          // email: values.email,
          // website: values.website,
          // gstType: values.gstType,
          // gstin: clientId
          //   ? values.gstin
          //   : values.gstType === "Unregistered Business" ||
          //     values.gstType === "Consumer (B2C)" ||
          //     values.gstType === "Overseas (Outside India)"
          //   ? ""
          //   : values.gstin,
          // pan: values.pan,
          // currency: values.currency,
          type: values.type,
          ...updatedValues,
          completeAddress: {
            address: values.completeAddress.address,
            state: values.completeAddress.state,
            city: values.completeAddress.city,
            pincode: values.completeAddress.pincode,
            country: values.completeAddress.country,
            cCode: values.extra.countryCode,
            sCode: values.extra.stateCode,
          },
        };
      }

      showLoader();
      if (clientId) {
        const res = await updateClient(finalObj, clientId, hideLoader);
        if (res) {
          showToast(`Client updated`, "success");
          navigate(`/client/view/${clientId}`, { replace: true });
        }
      } else {
        const res = await createClient(finalObj, hideLoader);
        if (res) {
          showToast(`Client created`, "success");
          navigate("/client", { replace: true });
        }
      }
    },
  });

  useEffect(() => {
    setStates(gettingStates(formik.values.extra.countryCode));
  }, [formik.values.extra.countryCode]);

  useEffect(() => {
    setCities(
      gettingCities(
        formik.values.extra.countryCode,
        formik.values.extra.stateCode
      )
    );
  }, [formik.values.extra.stateCode]);

  return (
    <>
      <div
        className="single_page"
        style={{
          overflow: "unset",
        }}
      >
        <div className="details_form_wrapper p-3">
          <div>
            <h3 className="font16x600 textColor3 mb-0">Company Details</h3>
          </div>

          {/* form */}
          <div className="mt-4">
            <form onSubmit={formik.handleSubmit}>
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="mb-3">
                      <InputField
                        id="name"
                        label="Company Name*"
                        type="text"
                        state={formik.values.name}
                        setState={(e) => {
                          formik.setFieldValue("name", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            name: e.target.value,
                            isObjChange: true,
                          });
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <InputError error={formik.errors.name} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <InputField
                        id="email"
                        label="Email"
                        type="text"
                        state={formik.values.email}
                        setState={(e) => {
                          formik.setFieldValue("email", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            email: e.target.value,
                            isObjChange: true,
                          });
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <InputError error={formik.errors.email} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <DropdownOne
                        label="GST Type*"
                        placeholder="Select GST type"
                        name="gstType"
                        data={gstTypesMap}
                        state={formik.values.gstType}
                        setState={(e?: string) => {
                          formik.setFieldValue("gstType", e);
                          setUpdatedValues({
                            ...updatedValues,
                            gstType: e,
                            isObjChange: true,
                          });
                        }}
                        onBlur={() => formik.setFieldTouched("gstType", true)}
                      />
                      {formik.touched.gstType && formik.errors.gstType ? (
                        <InputError error={formik.errors.gstType} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <InputField
                        id="pan"
                        label="PAN"
                        type="text"
                        state={formik.values.pan}
                        setState={(e) => {
                          formik.setFieldValue("pan", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            pan: e.target.value,
                            isObjChange: true,
                          });
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.pan && formik.errors.pan ? (
                        <InputError error={formik.errors.pan} />
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="contactNumber"
                        className="form-label font12x400 textColor3 mb-1"
                      >
                        Company Contact Number
                      </label>
                      <div className="input-group">
                        <PhoneCode
                          formik={formik}
                          updatedValues={updatedValues}
                          setUpdatedValues={setUpdatedValues}
                          fieldName="phoneCode"
                          fieldName2="contactNumber"
                        />
                        <input
                          type="text"
                          className="form-control shadow-none font12x600 border-0"
                          id="contactNumber"
                          value={formik.values.contactNumber}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "contactNumber",
                              e.target.value
                            );
                            setUpdatedValues({
                              ...updatedValues,
                              contactNumber: e.target.value,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                          autoComplete="new-contactNumber"
                        />
                      </div>
                      {formik.touched.contactNumber &&
                      formik.errors.contactNumber ? (
                        <InputError error={formik.errors.contactNumber} />
                      ) : null}
                      {formik.touched.phoneCode && formik.errors.phoneCode ? (
                        <InputError error={formik.errors.phoneCode} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <InputField
                        placeholder="eg: https://www.example.com"
                        id="website"
                        label="Website"
                        type="text"
                        state={formik.values.website}
                        setState={(e) => {
                          formik.setFieldValue("website", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            website: e.target.value,
                            isObjChange: true,
                          });
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.website && formik.errors.website ? (
                        <InputError error={formik.errors.website} />
                      ) : null}
                    </div>

                    {!(
                      formik.values.gstType === "Unregistered Business" ||
                      formik.values.gstType === "Registered - Comp. Scheme" ||
                      formik.values.gstType === "Consumer (B2C)" ||
                      formik.values.gstType === "Overseas (Outside India)"
                    ) && (
                      <div className="mb-3">
                        <InputField
                          id="gstin"
                          label={`${
                            formik.values.gstType === ""
                              ? "GSTIN"
                              : !(
                                  formik.values.gstType ===
                                    "Unregistered Business" ||
                                  formik.values.gstType ===
                                    "Registered - Comp. Scheme" ||
                                  formik.values.gstType === "Consumer (B2C)" ||
                                  formik.values.gstType ===
                                    "Overseas (Outside India)"
                                )
                              ? "GSTIN*"
                              : "GSTIN"
                          }`}
                          type="text"
                          state={formik.values.gstin}
                          setState={(e) => {
                            formik.setFieldValue("gstin", e.target.value);
                            setUpdatedValues({
                              ...updatedValues,
                              gstin: e.target.value,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.gstin && formik.errors.gstin ? (
                          <InputError error={formik.errors.gstin} />
                        ) : null}
                      </div>
                    )}

                    <div className="mb-3">
                      <DropdownOne
                        label="Currency"
                        placeholder="Select currency"
                        name="currency"
                        data={currenciesMap}
                        state={formik.values.currency}
                        setState={(e?: string) => {
                          formik.setFieldValue("currency", e);
                          setUpdatedValues({
                            ...updatedValues,
                            currency: e,
                            isObjChange: true,
                          });
                        }}
                        onBlur={() => formik.setFieldTouched("currency", true)}
                      />
                      {formik.touched.currency && formik.errors.currency ? (
                        <InputError error={formik.errors.currency} />
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <div className="customCheck form-check d-flex align-items-center p-0">
                      <input
                        type="checkbox"
                        className="me-2"
                        id="type"
                        name="type"
                        checked={
                          formik.values.type === BOTH_CLIENT_VENDOR
                            ? true
                            : false
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.checked) {
                            formik.setFieldValue("type", BOTH_CLIENT_VENDOR);
                            setUpdatedValues({
                              ...updatedValues,
                              type: BOTH_CLIENT_VENDOR,
                              isObjChange: true,
                            });
                          } else {
                            formik.setFieldValue("type", ONLY_CLIENT);
                            setUpdatedValues({
                              ...updatedValues,
                              type: ONLY_CLIENT,
                              isObjChange: true,
                            });
                          }
                        }}
                      />
                      <label
                        className="font12x500 textColor3 form-check-label cursor_pointer"
                        htmlFor="type"
                      >
                        Use this also as vendor
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* billing form */}
              <div className="mt-5">
                <div>
                  <h3 className="font16x600 textColor3 mb-0">
                    Billing Address
                  </h3>
                </div>

                <div className="container-fluid p-0 mt-4">
                  <div className="row">
                    <div className="row pe-0">
                      <div className="mb-3 col-12 col-lg-6 pe-0">
                        <InputField
                          id="completeAddress.address"
                          label="Address"
                          type="text"
                          state={formik.values.completeAddress.address}
                          setState={(e) => {
                            formik.setFieldValue(
                              "completeAddress.address",
                              e.target.value
                            );
                            setUpdatedValues({
                              ...updatedValues,
                              // "completeAddress.address": e.target.value,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.completeAddress?.address &&
                        formik.errors.completeAddress?.address ? (
                          <InputError
                            error={formik.errors.completeAddress?.address}
                          />
                        ) : null}
                      </div>

                      <div className="mb-3 col-12 col-lg-6 pe-0">
                        <DropdownOne
                          label="Country"
                          placeholder="Select country"
                          name="completeAddress.country"
                          data={countryMap}
                          state={formik.values.completeAddress.country}
                          setState={(e?: string, x?: string | number) => {
                            formik.setFieldValue("completeAddress.country", e);
                            formik.setFieldValue("extra.countryCode", x);
                            formik.setFieldValue("completeAddress.state", "");
                            formik.setFieldValue("extra.stateCode", "");
                            formik.setFieldValue("completeAddress.city", "");
                            setUpdatedValues({
                              ...updatedValues,
                              // "completeAddress.country": e,
                              isObjChange: true,
                            });
                          }}
                          onBlur={() =>
                            formik.setFieldTouched(
                              "completeAddress.country",
                              true
                            )
                          }
                        />
                        {formik.touched.completeAddress?.country &&
                        formik.errors.completeAddress?.country ? (
                          <InputError
                            error={formik.errors.completeAddress?.country}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="row pe-0">
                      <div className="mb-3 col-12 col-lg-6 pe-0">
                        <DropdownOne
                          label="State*"
                          placeholder="Select State"
                          name="completeAddress.state"
                          data={states}
                          state={formik.values.completeAddress.state}
                          setState={(e?: string, x?: string | number) => {
                            formik.setFieldValue("completeAddress.state", e);
                            formik.setFieldValue("extra.stateCode", x);
                            formik.setFieldValue("completeAddress.city", "");
                            setUpdatedValues({
                              ...updatedValues,
                              // "completeAddress.state": e,
                              isObjChange: true,
                            });
                          }}
                          onBlur={() =>
                            formik.setFieldTouched(
                              "completeAddress.state",
                              true
                            )
                          }
                        />
                        {formik.touched.completeAddress?.state &&
                        formik.errors.completeAddress?.state ? (
                          <InputError
                            error={formik.errors.completeAddress?.state}
                          />
                        ) : null}
                      </div>

                      <div className="mb-3 col-12 col-lg-6 pe-0">
                        {formik.values.extra.stateCode !== "" ? (
                          <>
                            <DropdownOne
                              label="City"
                              placeholder="Select City"
                              name="completeAddress.city"
                              data={cities}
                              state={formik.values.completeAddress.city}
                              setState={(e?: string) => {
                                formik.setFieldValue("completeAddress.city", e);
                                setUpdatedValues({
                                  ...updatedValues,
                                  // "completeAddress.city": e,
                                  isObjChange: true,
                                });
                              }}
                              onBlur={() =>
                                formik.setFieldTouched(
                                  "completeAddress.city",
                                  true
                                )
                              }
                            />
                          </>
                        ) : (
                          <InputField
                            id="completeAddress.city"
                            label="City"
                            type="text"
                            state={formik.values.completeAddress.city}
                            isReadOnly={true}
                          />
                        )}
                        {formik.touched.completeAddress?.city &&
                        formik.errors.completeAddress?.city ? (
                          <InputError
                            error={formik.errors.completeAddress?.city}
                          />
                        ) : null}
                      </div>
                    </div>

                    <div className="row pe-0">
                      <div className="mb-3 col-12 col-lg-6 pe-0">
                        <InputField
                          id="completeAddress.pincode"
                          label="Pin Code"
                          type="tel"
                          state={formik.values.completeAddress.pincode}
                          setState={(e) => {
                            formik.setFieldValue(
                              "completeAddress.pincode",
                              e.target.value
                            );
                            setUpdatedValues({
                              ...updatedValues,
                              // "completeAddress.pincode": e.target.value,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.completeAddress?.pincode &&
                        formik.errors.completeAddress?.pincode ? (
                          <InputError
                            error={formik.errors.completeAddress?.pincode}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end mt-4">
                    {/* <button
                      className="btn font12x500 shadow-none custom_btn_outline"
                      //   onClick={fn.prevStep}
                    >
                      Back
                    </button> */}

                    {clientId ? (
                      <button
                        type="submit"
                        className="btn font12x500 shadow-none custom_btn ms-3"
                        disabled={updatedValues?.isObjChange ? false : true}
                      >
                        {loading ? (
                          <SpinLoader height="20" width="20" color="#fff" />
                        ) : (
                          "Update"
                        )}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn font12x500 shadow-none custom_btn ms-3"
                      >
                        {loading ? (
                          <SpinLoader height="20" width="20" color="#fff" />
                        ) : (
                          "Save"
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUpdateClient;
