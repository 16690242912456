import React from "react";
import SettingAccount from "../../../pages/main/setting/sub pages/account";
import SettingCreditNote from "../../../pages/main/setting/sub pages/creditNote";
import SettingDepartment from "../../../pages/main/setting/sub pages/department";
import SettingEmployee from "../../../pages/main/setting/sub pages/employee";
import SettingExpense from "../../../pages/main/setting/sub pages/expense";
import SettingGeneral from "../../../pages/main/setting/sub pages/general";
import SettingGst from "../../../pages/main/setting/sub pages/gst";
import SettingInvoice from "../../../pages/main/setting/sub pages/invoice";
import SettingLocation from "../../../pages/main/setting/sub pages/location";
import SettingPayment from "../../../pages/main/setting/sub pages/payment";
import SettingProfile from "../../../pages/main/setting/sub pages/profile";
import SettingProposal from "../../../pages/main/setting/sub pages/proposal";
import SalarySlipLabel from "../../../pages/main/setting/sub pages/salary slip label";
import SettingTaxes from "../../../pages/main/setting/sub pages/taxes";
import SettingUserRole from "../../../pages/main/setting/sub pages/user_and_role";
import {
  SETTING_CREDITNOTE,
  SETTING_DEPARTMENT,
  SETTING_EMPLOYEE,
  SETTING_EXPENSE,
  SETTING_GENERAL,
  SETTING_GST,
  SETTING_INVOICE,
  SETTING_LOCATION,
  SETTING_PAYMENT,
  SETTING_PROFILE,
  SETTING_PROPOSAL,
  SETTING_SALARY_SLIP,
  SETTING_USER_ROLE,
} from "../../../utils/constants";

type propType = {
  routeName: string;
};

const MenuData: React.FC<propType> = ({ routeName }) => {
  switch (routeName) {
    case SETTING_GENERAL:
      return (
        <>
          <SettingGeneral />
        </>
      );
    case SETTING_PROFILE:
      return (
        <>
          <SettingProfile />
        </>
      );
    case SETTING_LOCATION:
      return (
        <>
          <SettingLocation />
        </>
      );
    case SETTING_DEPARTMENT:
      return (
        <>
          <SettingDepartment />
        </>
      );
    // case SETTING_ACCOUNT:
    //   return (
    //     <>
    //       <SettingAccount />
    //     </>
    //   );
    case SETTING_USER_ROLE:
      return (
        <>
          <SettingUserRole />
        </>
      );
    case SETTING_GST:
      return (
        <>
          <SettingGst />
        </>
      );
    // case SETTING_TAXES:
    //   return (
    //     <>
    //       <SettingTaxes />
    //     </>
    //   );
    case SETTING_EMPLOYEE:
      return (
        <>
          <SettingEmployee />
        </>
      );
    case SETTING_SALARY_SLIP:
      return (
        <>
          <SalarySlipLabel />
        </>
      );
    case SETTING_INVOICE:
      return (
        <>
          <SettingInvoice />
        </>
      );
    case SETTING_PROPOSAL:
      return (
        <>
          <SettingProposal />
        </>
      );
    case SETTING_PAYMENT:
      return (
        <>
          <SettingPayment />
        </>
      );
    case SETTING_EXPENSE:
      return (
        <>
          <SettingExpense />
        </>
      );
    case SETTING_CREDITNOTE:
      return (
        <>
          <SettingCreditNote />
        </>
      );
    default:
      return null;
  }
};

export default MenuData;
