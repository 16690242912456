import { useContext, useState } from "react";
import { commonType } from "../../../../pages/main/lead";
import { ViewLeadContext } from "../../../../pages/main/lead/view lead";
import { ProfileContext } from "../../../../pages/main/lead/view lead/sub pages/profile";
import Form from "./Form";

const PersonDetails = () => {
  const { lead } = useContext(ViewLeadContext);
  const { step } = useContext(ProfileContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });

  const initialValues = {
    name: lead?.name ?? "--",
    email: lead?.email ?? "--",
    mobile: lead?.mobile ?? "--",
    phoneCode: lead?.phoneCode ?? "--",
    leadId: lead?._id ?? "",
    lastUpdate: lead?.updatedAt ?? "",
    updatedBy: lead?.updatedBy ? lead?.updatedBy : undefined,
  };

  const fn = {
    openForm: () => {
      setCommonData({
        data: initialValues,
        modal: true,
        check: "profile_menu",
      });
      document.body.style.overflow = "hidden";
    },
  };

  return (
    <>
      <div className="profile_menu_content_section">
        <div className="d-flex justify-content-between align-items-center">
          <p className="mb-0 font12x500 textColor3">Person</p>
          <div
            className="d-flex justify-content-between align-items-center cursor_pointer"
            onClick={fn.openForm}
          >
            <img src="/Assets/Svg/editIcon.svg" alt="edit" />
            <p className="mb-0 font12x400 textColor2 ms-1">Edit</p>
          </div>
        </div>
        <div className="container-fluid p-0 mt-3">
          <div className="row">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">Name</p>
            </div>
            <div className="col-8">
              <p className="mb-0 font12x400 textColor3">{initialValues.name}</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">Phone</p>
            </div>
            <div className="col-8">
              <p className="mb-0 font12x400 textColor3">
                {`${initialValues?.phoneCode} ${initialValues?.mobile}`}
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">Email</p>
            </div>
            <div className="col-8 d-flex">
              <a
                href={`mailto:${
                  initialValues?.email?.length > 0 ? initialValues?.email : "#"
                }`}
                className="font12x400 textColor2 text-decoration-none"
                target="_blank"
              >
                {initialValues?.email?.length > 0 ? initialValues?.email : "--"}
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* form modal */}
      {commonData?.modal && commonData?.check === "profile_menu" && (
        <Form step={step} state={commonData} setState={setCommonData} />
      )}
    </>
  );
};

export default PersonDetails;
