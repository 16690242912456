import React, { useContext, useEffect, useState } from "react";
import DataNotFound from "../../../components/Data Not Found";
import Form from "../../../components/Item Components/Form";
import UpdateStock from "../../../components/Item Components/UpdateStock";
import Pagination from "../../../components/Pagination";
import MoveModal from "../../../components/Trashed Modal/MoveModal";
import DeleteFilter from "../../../Filters/DeleteFilter";
import useLoading from "../../../hooks/useLoading";
import { getItem } from "../../../lib/api functions/item";
import SkeletonLoader from "../../../shared components/Loader/SkeletonLoader";
import Search from "../../../shared components/Search";
import { DELETE_ITEM_SOFT, itemTypesMap } from "../../../utils/constants";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import { commonType } from "../lead";
import { ParamType } from "./type";

export const getItemTypeLabel = (type: number): any => {
  switch (type) {
    case 1:
      return "Product";
    case 2:
      return "Service";
    default:
      return null;
  }
};

const Item = () => {
  const { userId, companyId, roles } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });

  const [loading, showLoader, hideLoader] = useLoading();
  const [items, setItems] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    company: companyId,
    isDeleted: false,
  });

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getItems: async (params?: ParamType) => {
      showLoader();
      const res = await getItem(params);
      if (res) {
        setItems(res.data.items);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
    addParam: (value: string) => {
      if (value === "resetFilter") {
        delete params?.type;
        fn.getItems?.({ ...params, page: 1 });
      } else {
        fn.getItems?.({ ...params, page: 1, type: value });
      }
    },
    gettingSelectedData: (
      isChecked: boolean,
      check: string,
      value?: string
    ) => {
      if (check === "all") {
        if (isChecked) {
          setSelectedRecords([]);
          items?.map((item: any) => {
            setSelectedRecords((prev: any) => [...prev, item?._id]);
          });
        } else {
          setSelectedRecords([]);
        }
      } else {
        if (isChecked) {
          setSelectedRecords([...selectedRecords, value ?? ""]);
        } else {
          let filterSelectedValue = selectedRecords.filter(
            (item: any) => item !== value
          );
          setSelectedRecords(filterSelectedValue);
        }
      }
    },
  };

  useEffect(() => {
    if (roles?.length !== 0) {
      if (!roles[0]?.items.complete) {
        params.user = userId;
        fn.getItems(params);
      } else {
        fn.getItems(params);
      }
    }
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="data_render_wrapper">
          {/* filters */}
          <div className="filter_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-12 col-md-4">
                  <Search
                    placeholder="Search by item name"
                    state={params}
                    setState={setParams}
                    apiCall={fn.getItems}
                    paramValue="name"
                  />
                </div>
                <div className="col-12 col-md-8 pt-3 pt-md-0">
                  <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                    <div className="d-flex align-items-center gap-2">
                      <DeleteFilter
                        url="/item/trashed"
                        title="View trashed item"
                      />
                      <button
                        className="btn font12x500 buttonOne shadow-none"
                        onClick={() => fn.openModal(undefined, "add_item")}
                      >
                        <img
                          src="/Assets/Svg/plusIcon.svg"
                          alt="icon"
                          className="me-2"
                        />
                        Add New Item
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="list_view_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center pb-3">
                <div className="col-12 col-lg-3">
                  <span className="font16x600 textColor3">List of Items</span>
                </div>
                <div className="col-12 col-lg-9 pt-3 pt-lg-0">
                  <div className="d-flex justify-content-start justify-content-lg-end">
                    {selectedRecords?.length !== 0 ? (
                      <div>
                        <button
                          className="btn font12x500 shadow-none modalBtnColor"
                          onClick={() =>
                            fn.openModal(
                              {
                                itemIds: selectedRecords,
                                isDeleted: true,
                              },
                              "move"
                            )
                          }
                          style={{
                            backgroundColor: "var(--error)",
                          }}
                        >
                          {`Move (${selectedRecords?.length})`}
                        </button>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <span className="font12x500 textColor3">Filter:</span>
                        <div className="ms-2">
                          <select
                            className="form-select font12x500 textColor3 shadow-none dropdown_filter"
                            onChange={(e) => {
                              fn.addParam(e.target.value);
                            }}
                          >
                            <option value="resetFilter">---Type---</option>
                            <option value="1">Product</option>
                            <option value="2">Service</option>
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* table */}
            {items?.length !== 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="font12x500 textColor3">
                        <th scope="col">
                          <div className="customCheck form-check d-flex align-items-center p-0">
                            <input
                              type="checkbox"
                              className="me-2"
                              id="checkbox"
                              name="rememberMe"
                              checked={
                                selectedRecords.length === items.length
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                fn.gettingSelectedData(e.target.checked, "all");
                              }}
                            />
                          </div>
                        </th>
                        <th scope="col">#</th>
                        <th scope="col">Item Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Type</th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Unit</th>
                        <th scope="col">HSN/SAC</th>
                        <th scope="col">Tax</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items?.map((item: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <th>
                              <div className="customCheck form-check d-flex align-items-center p-0">
                                <input
                                  type="checkbox"
                                  className="me-2"
                                  id="checkbox"
                                  name="rememberMe"
                                  value={item?._id}
                                  checked={
                                    selectedRecords.includes(item?._id)
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    fn.gettingSelectedData(
                                      e.target.checked,
                                      "single",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </th>
                            <th>
                              {(params.page - 1) * params.per_page + (i + 1)}
                            </th>
                            <td>{item?.name}</td>
                            <td
                              title={item?.description}
                              style={{ maxWidth: 200 }}
                            >
                              {`${
                                item?.description?.length
                                  ? item?.description?.length >= 90
                                    ? item?.description?.substring(0, 90) +
                                      "..."
                                    : item?.description
                                  : "--"
                              }`}
                            </td>
                            <td>{itemTypesMap.get(item?.type)}</td>
                            <td>{`₹ ${item?.unitAmount?.toLocaleString()}`}</td>
                            <td>{item.unit}</td>
                            <td>
                              {item?.type === 1
                                ? item?.hsn
                                : item?.type === 2
                                ? item?.sac
                                : "--"}
                            </td>
                            <td>{`${item?.tax}%`}</td>
                            <td>{item?.stock}</td>
                            <td>
                              <div className="d-flex gap-2">
                                <div>
                                  <button
                                    className="btn font12x500 buttonTwo shadow-none"
                                    onClick={() =>
                                      fn.openModal({ item }, "view/edit_item")
                                    }
                                  >
                                    View Details
                                  </button>
                                </div>
                                <div>
                                  <button
                                    className="btn font12x500 buttonTwo shadow-none"
                                    onClick={() =>
                                      fn.openModal(
                                        { _id: item?._id },
                                        "add_stock"
                                      )
                                    }
                                  >
                                    Add Stock
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : loading ? (
              <SkeletonLoader />
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>

        {/* pagination */}
        {items?.length !== 0 && (
          <div className="pagination_wrapper pt-3 px-3">
            <Pagination
              currentPage={params.page}
              onPageChange={(e) => {
                fn.getItems({ ...params, page: e });
                setParams({
                  ...params,
                  page: e,
                });
              }}
              pageSize={params.per_page}
              totalCount={Math.ceil(totalResult / params.per_page)}
              onChangePerPage={(e) => {
                fn.getItems({ ...params, per_page: e });
                setParams({
                  ...params,
                  per_page: e,
                });
              }}
            />
          </div>
        )}
      </div>

      {/* form modal */}
      {commonData?.modal &&
        (commonData?.check === "view/edit_item" ||
          commonData?.check === "add_item") && (
          <Form
            state={commonData}
            setState={setCommonData}
            apiCall={fn.getItems}
            params={params}
          />
        )}

      {/* add stock modal */}
      {commonData?.modal && commonData?.check === "add_stock" && (
        <UpdateStock
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getItems}
          params={params}
        />
      )}

      {/* move modal */}
      {commonData?.modal && commonData?.check === "move" && (
        <MoveModal
          label="Item"
          dataIds={commonData?.data}
          setState={setCommonData}
          url={DELETE_ITEM_SOFT}
          variableName="itemIds"
          apiCall={fn.getItems}
          params={params}
          setSelectedRecords={setSelectedRecords}
        />
      )}
    </>
  );
};

export default Item;
