import { useContext } from "react";
import { UserContext } from "../../../pages/main/user/add update user";
import Menu from "./Menu";

const MenuBar = () => {
  const { userId } = useContext(UserContext);
  return (
    <>
      <div>
        <div
          className="details_menu_wrapper"
          style={{
            minHeight: "calc(100vh - 102px)",
            height: "calc(100vh - 102px)",
          }}
        >
          {userId ? (
            <>
              <Menu
                path={`/user/user-details/form/${userId}`}
                label="User Details"
                isUserCreated={true}
              />
              <Menu
                path={`/user/bank-details/form/${userId}`}
                label="Bank Details"
                isUserCreated={true}
              />
              <Menu
                path={`/user/office-details/form/${userId}`}
                label="Office Details"
                isUserCreated={true}
              />
              <Menu
                path={`/user/documents/form/${userId}`}
                label="Documents"
                isUserCreated={true}
              />
              <Menu
                path={`/user/attendance/form/${userId}`}
                label="Attendance"
                isUserCreated={true}
              />
              <Menu
                path={`/user/salary-slip/form/${userId}`}
                label="Salary Slip"
                isUserCreated={true}
              />
              <Menu
                path={`/user/user-role/form/${userId}`}
                label="User Roles"
                isUserCreated={true}
              />
            </>
          ) : (
            <>
              <Menu
                path={`/user/user-role/form`}
                label="User Roles"
                isUserCreated={true}
              />
              <Menu path={`#`} label="User Details" isUserCreated={false} />
              <Menu path={`#`} label="Bank Details" isUserCreated={false} />
              <Menu path={`#`} label="Office Details" isUserCreated={false} />
              <Menu path={`#`} label="Documents" isUserCreated={false} />
              <Menu path={`#`} label="Attendance" isUserCreated={false} />
              <Menu path={`#`} label="Salary Slip" isUserCreated={false} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MenuBar;
