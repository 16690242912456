import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { showToast } from "./lib/toast/ShowToast";

const firebaseConfig = {
  apiKey: "AIzaSyAHkd_tuiJTcVlGTN7gpKT5ekU2c7kk9qA",
  authDomain: "crm-erp-3c152.firebaseapp.com",
  projectId: "crm-erp-3c152",
  storageBucket: "crm-erp-3c152.appspot.com",
  messagingSenderId: "1008288547482",
  appId: "1:1008288547482:web:e3848e3d0e7f26444ed523",
  measurementId: "G-5LJ7NZ1BF6",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const PushNotificationToken = (setTokenFound: any) => {
  return getToken(messaging, {
    vapidKey:
      "BCpFPoanBXYSg7UEqHv380MIT44-_ngZ5-92nykpQoZF6S951p5bOWs_tcYOx99xoxOyNIdysXBkR5oVHaRIVig",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound("");
        // shows on the UI that false permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      showToast("Please allow notification for getting notifications", "error");
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
