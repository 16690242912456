import React, { useContext } from "react";
import moment from "moment";
import { generateCompleteAddress } from "../../../function/other";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import { leaveTypesMap } from "../../../utils/constants";

type propType = {
  slipData: any;
};

const PaySlipComponent: React.FC<propType> = (props) => {
  const { slipData } = props;
  const { setting } = useContext(MainContext);

  return (
    <>
      <div className="paySlip_section p-4">
        <div className="d-flex justify-content-between">
          <img
            src={`${process.env.REACT_APP_IMAGE_URL}${slipData?.company?.logo}`}
            width={200}
            alt="logo"
          />
          <div
            style={{
              width: "210px",
            }}
          >
            <p className="mb-0 text-end font12x500 textColor3">
              {generateCompleteAddress(
                slipData?.company?.address,
                slipData?.company?.city,
                slipData?.company?.state,
                slipData?.company?.country,
                slipData?.company?.pincode
              )
                .filter((item) => item?.length)
                .join(", ")}
            </p>
            <p className="mb-0 text-end font12x500 textColor3">
              {slipData?.company?.website?.length
                ? slipData?.company?.website
                : "--"}
            </p>
          </div>
        </div>

        <div className="text-center mt-4">
          <p className="mb-1 font12x500">
            {`Payslip for the month of ${moment()
              .month(slipData?.salarySlip?.attendance?.month ?? "")
              .format("MMMM")} ${slipData?.salarySlip?.attendance?.year}`}
          </p>
          {slipData?.salarySlip?.salaryType === "H" && (
            <p className="mb-0 font12x400">{`Hourly Basis(${slipData?.salarySlip?.pLHours} H/day)`}</p>
          )}
        </div>

        <div className="paySlip_structure_wrapper">
          <div className="d-flex justify-content-between px-1 pb-4">
            <div>
              <div className="d-flex gap-1">
                <p className="mb-0 font12x500" style={{ width: 90 }}>
                  Employee Name
                </p>
                <p className="mb-0 font12x500">:</p>
                <p className="mb-0 font12x400">{slipData?.salarySlip?.name}</p>
              </div>
              <div className="d-flex gap-1">
                <p className="mb-0 font12x500" style={{ width: 90 }}>
                  Department
                </p>
                <p className="mb-0 font12x500">:</p>
                <p className="mb-0 font12x400">
                  {slipData?.salarySlip?.department}
                </p>
              </div>
              <div className="d-flex gap-1">
                <p className="mb-0 font12x500" style={{ width: 90 }}>
                  Designation
                </p>
                <p className="mb-0 font12x500">:</p>
                <p className="mb-0 font12x400">
                  {slipData?.salarySlip?.designation}
                </p>
              </div>
              <div className="d-flex gap-1">
                <p className="mb-0 font12x500" style={{ width: 90 }}>
                  Date of Joining
                </p>
                <p className="mb-0 font12x500">:</p>
                <p className="mb-0 font12x400">
                  {moment(slipData?.salarySlip?.doj).format(
                    setting?.dateFormat
                  )}
                </p>
              </div>
            </div>
            <div>
              <div className="d-flex gap-1">
                <p className="mb-0 font12x500" style={{ width: 90 }}>
                  Working Days
                </p>
                <p className="mb-0 font12x500">:</p>
                <p className="mb-0 font12x400">
                  {slipData?.salarySlip?.workingDays}
                </p>
              </div>
              {slipData?.salarySlip?.salaryType === "H" && (
                <div className="d-flex gap-1">
                  <p className="mb-0 font12x500" style={{ width: 90 }}>
                    Working Hours
                  </p>
                  <p className="mb-0 font12x500">:</p>
                  <p className="mb-0 font12x400">
                    {slipData?.salarySlip?.workingHours}
                  </p>
                </div>
              )}
              {slipData?.salarySlip?.salaryType === "H" ? (
                <div className="d-flex gap-1">
                  <p className="mb-0 font12x500" style={{ width: 90 }}>
                    Payable Hours
                  </p>
                  <p className="mb-0 font12x500">:</p>
                  <p className="mb-0 font12x400">
                    {`${Number(
                      slipData?.salarySlip?.totalHours?.split(":")[0]
                    )}h ${Number(
                      slipData?.salarySlip?.totalHours?.split(":")[1]
                    )}m`}
                  </p>
                </div>
              ) : (
                <div className="d-flex gap-1">
                  <p className="mb-0 font12x500" style={{ width: 90 }}>
                    Payable Days
                  </p>
                  <p className="mb-0 font12x500">:</p>
                  <p className="mb-0 font12x400">
                    {slipData?.salarySlip?.daysPresent}
                  </p>
                </div>
              )}
              {slipData?.salarySlip?.leaves &&
                Object.keys(slipData?.salarySlip?.leaves).map((key, i) => {
                  return (
                    <div key={key} className="d-flex gap-1">
                      <p className="mb-0 font12x500" style={{ width: 90 }}>
                        {leaveTypesMap.get(key)}
                      </p>
                      <p className="mb-0 font12x500">:</p>
                      <p className="mb-0 font12x400">
                        {slipData?.salarySlip?.leaves[key]}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="paySlip_table_structure gap-2">
            <table className="table1">
              <thead>
                <tr>
                  <th colSpan={3}>
                    <h4 className="mb-0 lh-1 text-uppercase font12x500">
                      Earnings
                    </h4>
                  </th>
                </tr>
                <tr>
                  <th>
                    <h4 className="mb-0 lh-1 text-uppercase font12x500">
                      Salary Head
                    </h4>
                  </th>
                  <th>
                    <h4 className="mb-0 lh-1 text-uppercase font12x500">
                      Standard
                    </h4>
                  </th>
                  <th>
                    <h4 className="mb-0 lh-1 text-uppercase font12x500">
                      Actual Amount
                    </h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                {slipData?.salarySlip?.earnings
                  ?.slice(0, slipData?.salarySlip?.earnings?.length - 1)
                  .map((item: any, i: number) => {
                    return (
                      <tr key={i} className="mb-0 font12x400">
                        <td>
                          <p className="mb-0">{item?.label}</p>
                        </td>
                        <td>
                          <p className="mb-0 text-end">
                            {item?.valueStandard?.toLocaleString()}
                          </p>
                        </td>
                        <td>
                          <p className="mb-0 text-end">
                            {item?.valueActual?.toLocaleString()}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                <tr className="mb-0 font12x500">
                  <td>
                    <p className="mb-0">
                      {
                        slipData?.salarySlip?.earnings[
                          slipData?.salarySlip?.earnings?.length - 1
                        ]?.label
                      }
                    </p>
                  </td>
                  <td>
                    <p className="mb-0 text-end">
                      {slipData?.salarySlip?.earnings[
                        slipData?.salarySlip?.earnings?.length - 1
                      ].valueStandard?.toLocaleString()}
                    </p>
                  </td>
                  <td>
                    <p className="mb-0 text-end">
                      {slipData?.salarySlip?.earnings[
                        slipData?.salarySlip?.earnings?.length - 1
                      ].valueActual?.toLocaleString()}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table2">
              <thead>
                <tr>
                  <th colSpan={2} className="text-center">
                    <h4 className="mb-0 lh-1 text-uppercase font12x500">
                      Deductions
                    </h4>
                  </th>
                </tr>
                <tr>
                  <th>
                    <h4 className="mb-0 lh-1 text-uppercase font12x500">
                      Salary Head
                    </h4>
                  </th>
                  <th>
                    <h4 className="mb-0 lh-1 text-uppercase font12x500">
                      Amount
                    </h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                {slipData?.salarySlip?.deductions
                  ?.slice(0, slipData?.salarySlip?.deductions?.length - 1)
                  .map((item: any, i: number) => {
                    return (
                      <tr key={i} className="mb-0 font12x400">
                        <td>
                          <p className="mb-0">{item?.label}</p>
                        </td>
                        <td>
                          <p className="mb-0 text-end">
                            {item?.value?.toLocaleString()}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                <tr className="mb-0 font12x500">
                  <td>
                    <p className="mb-0">
                      {
                        slipData?.salarySlip?.deductions[
                          slipData?.salarySlip?.deductions?.length - 1
                        ]?.label
                      }
                    </p>
                  </td>
                  <td>
                    <p className="mb-0 text-end">
                      {slipData?.salarySlip?.deductions[
                        slipData?.salarySlip?.deductions?.length - 1
                      ]?.value?.toLocaleString()}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="px-1 py-4">
            <div className="d-flex gap-1">
              <p className="mb-0 font12x500" style={{ width: 90 }}>
                NET PAY
              </p>
              <p className="mb-0 font12x500">:</p>
              <p className="mb-0 font12x400">
                {slipData?.salarySlip?.netPay?.toLocaleString()}
              </p>
            </div>
          </div>
        </div>

        {/* <p className="mb-0 font12x400 text-center">No signature required</p> */}
        <div>
          <div className="pt-4 signature-text-container">
            <div className="signature-container">
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}${slipData?.company?.primarySignature}`}
                className="img-fluid"
                style={{ maxWidth: "100px" }}
                alt=""
              />
            </div>
            <p className="mt-1 mb-0 font10x500 text-start">Signature</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaySlipComponent;
