import { useFormik } from "formik";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import useLoading from "../../../../hooks/useLoading";
import { getDepartment } from "../../../../lib/api functions/setting/department";
import { updateUser } from "../../../../lib/api functions/user";
import { showToast } from "../../../../lib/toast/ShowToast";
import { UserContext } from "../../../../pages/main/user/add update user";
import { UserObject } from "../../../../pages/main/user/add update user/type";
import InputDateForm from "../../../../shared components/DateInput/InputDateForm";
import DropdownTwo from "../../../../shared components/Dropdown/DropdownTwo";
import InputError from "../../../../shared components/InputError/InputError";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import RadioButton from "../../../../shared components/RadioButton/RadioButton";
import InputField from "../../../../shared components/TextInput";
import SwitchToggle from "../../../../shared components/ToggleButton/SwitchToggle";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import { userOfficeDetailsSchema } from "../../../../utils/validationSchema";

const OfficeDetails = () => {
  const { companyId } = useContext(MainContext);
  const { userId, user, userLoading, getUser } = useContext(UserContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [departments, setDepartments] = useState<any>([]);
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });

  const fn = {
    getDepartments: async (params: any) => {
      const res = await getDepartment(params);
      if (res) {
        setDepartments(res.data.departments);
      }
    },
    putUserValueInState: () => {
      user.doj &&
        formik.setFieldValue("doj", moment(user.doj).format("YYYY-MM-DD"));
      formik.setFieldValue("isRemoteLocation", user.isRemoteLocation);
      formik.setFieldValue("department", user.department);
      formik.setFieldValue("designation", user.designation);
      formik.setFieldValue("salaryType", user.salaryType);
      formik.setFieldValue("salary", user.salary);
    },
  };

  const initialValues: UserObject = {
    doj: "",
    department: "",
    designation: "",
    salaryType: "",
    salary: 0,
    isRemoteLocation: false,
  };

  const formik = useFormik<UserObject>({
    initialValues,
    validationSchema: userOfficeDetailsSchema,
    onSubmit: async (values) => {
      let finalObj: UserObject = {};
      delete updatedValues.isObjChange;
      finalObj = updatedValues;

      showLoader();
      if (userId) {
        const res = await updateUser(finalObj, userId, hideLoader);
        if (res) {
          showToast(`User updated`, "success");
          getUser();
          hideLoader();
        }
      }
    },
  });

  useEffect(() => {
    fn.getDepartments({
      f: "name",
      company: companyId,
    });
  }, []);
  useEffect(() => {
    !userLoading && userId && fn.putUserValueInState();
  }, [userLoading]);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font16x600 textColor3">Office Details</p>
        </div>

        <div className="details_form_wrapper">
          {/* form */}
          <form onSubmit={formik.handleSubmit}>
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                    <label className="w-100 form-label font12x400 textColor1 mb-1">
                      Is remote location?
                    </label>

                    <div className="setting_input_wrapper text-start text-sm-end">
                      <SwitchToggle
                        state={formik.values.isRemoteLocation}
                        setState={(e: boolean) => {
                          formik.setFieldValue("isRemoteLocation", e);
                          setUpdatedValues({
                            ...updatedValues,
                            isRemoteLocation: e,
                            isObjChange: true,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <InputDateForm
                      id="doj"
                      label="Date of Joining"
                      type="date"
                      state={formik.values.doj}
                      setState={(e) => {
                        formik.setFieldValue("doj", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          doj: e.target.value,
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.doj && formik.errors.doj ? (
                      <InputError error={formik.errors.doj} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <DropdownTwo
                      label="Department"
                      placeholder="Select Department"
                      name="department"
                      data={departments}
                      state={formik.values.department}
                      setState={(e?: string, x?: string | number) => {
                        formik.setFieldValue("department", e);
                        setUpdatedValues({
                          ...updatedValues,
                          department: e,
                          isObjChange: true,
                        });
                      }}
                      labelField="name"
                      onBlur={() => {
                        formik.setFieldTouched("department", true);
                      }}
                    />
                    {formik.touched.department && formik.errors.department ? (
                      <InputError error={formik.errors.department} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="designation"
                      label="Designation"
                      type="text"
                      state={formik.values.designation}
                      setState={(e) => {
                        formik.setFieldValue("designation", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          designation: e.target.value,
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.designation && formik.errors.designation ? (
                      <InputError error={formik.errors.designation} />
                    ) : null}
                  </div>

                  <div className="mb-4">
                    <label className="form-label font12x400 textColor3 mb-1">
                      Salary Type
                    </label>
                    <div className="d-flex gap-3">
                      <RadioButton
                        label="Daily Basis"
                        htmlFor="daily"
                        name="salaryType"
                        value="D"
                        state={formik.values.salaryType}
                        setState={(x?: string | number) => {
                          formik.setFieldValue("salaryType", x);
                          setUpdatedValues({
                            ...updatedValues,
                            salaryType: x,
                            isObjChange: true,
                          });
                        }}
                      />
                      <RadioButton
                        label="Hourly Basis"
                        htmlFor="hourly"
                        name="salaryType"
                        value="H"
                        state={formik.values.salaryType}
                        setState={(x?: string | number) => {
                          formik.setFieldValue("salaryType", x);
                          setUpdatedValues({
                            ...updatedValues,
                            salaryType: x,
                            isObjChange: true,
                          });
                        }}
                      />
                    </div>
                    {formik.touched.salaryType && formik.errors.salaryType ? (
                      <InputError error={formik.errors.salaryType} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="salary"
                      label="Salary (Monthly)"
                      type="tel"
                      state={formik.values.salary}
                      setState={(e) => {
                        formik.setFieldValue("salary", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          salary: e.target.value,
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.salary && formik.errors.salary ? (
                      <InputError error={formik.errors.salary} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end my-4">
              <button
                type="submit"
                className="btn font12x500 shadow-none ms-3 custom_btn"
                disabled={updatedValues?.isObjChange ? false : true}
              >
                {loading ? (
                  <SpinLoader height="20" width="20" color="#fff" />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default OfficeDetails;
