import { useContext } from "react";
import { EmployeeContext } from "../../../../pages/main/setting/sub pages/employee";
import DropdownOne from "../../../../shared components/Dropdown/DropdownOne";
import InputError from "../../../../shared components/InputError/InputError";
import InputField from "../../../../shared components/TextInput";
import { pLCarryForwardMap } from "../../../../utils/constants";

const Employee = () => {
  const { formik } = useContext(EmployeeContext);

  return (
    <>
      <div className="d-flex justify-content-start align-items-sm-center justify-content-sm-between flex-column flex-sm-row mb-3">
        <label
          htmlFor="maxHours"
          className="form-label font12x400 textColor1 mb-0"
        >
          Max Hours
        </label>

        <div
          className="setting_input_wrapper"
          style={{
            width: "auto",
          }}
        >
          <div className="d-flex justify-content-start justify-content-sm-end">
            <InputField
              id="maxHours"
              type="number"
              state={formik.values.maxHours}
              setState={formik.handleChange}
              onBlur={formik.handleBlur}
              width="110px"
            />
          </div>
          {formik.touched.maxHours && formik.errors.maxHours ? (
            <InputError error={formik.errors.maxHours} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start align-items-sm-center justify-content-sm-between flex-column flex-sm-row mb-3">
        <label
          htmlFor="pLHours"
          className="form-label font12x400 textColor1 mb-0"
        >
          Paid Hours
        </label>

        <div
          className="setting_input_wrapper"
          style={{
            width: "auto",
          }}
        >
          <div className="d-flex justify-content-start justify-content-sm-end">
            <InputField
              id="pLHours"
              type="number"
              state={formik.values.pLHours}
              setState={formik.handleChange}
              onBlur={formik.handleBlur}
              width="110px"
            />
          </div>
          {formik.touched.pLHours && formik.errors.pLHours ? (
            <InputError error={formik.errors.pLHours} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start align-items-sm-center justify-content-sm-between flex-column flex-sm-row mb-3">
        <label
          htmlFor="numberOfPL"
          className="form-label font12x400 textColor1 mb-0"
        >
          Number of PL
        </label>

        <div
          className="setting_input_wrapper"
          style={{
            width: "auto",
          }}
        >
          <div className="d-flex justify-content-start justify-content-sm-end">
            <InputField
              id="numberOfPL"
              type="number"
              state={formik.values.numberOfPL}
              setState={formik.handleChange}
              onBlur={formik.handleBlur}
              width="110px"
            />
          </div>
          {formik.touched.numberOfPL && formik.errors.numberOfPL ? (
            <InputError error={formik.errors.numberOfPL} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start align-items-sm-center justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          PL Carry Forward
        </label>

        <div
          className="setting_input_wrapper"
          style={{
            width: "110px",
          }}
        >
          <DropdownOne
            placeholder=""
            name="pLCarryForward"
            width="110px"
            data={pLCarryForwardMap}
            state={formik.values?.pLCarryForward?.toString()}
            setState={(e?: string, x?: string | number) => {
              formik.setFieldValue("pLCarryForward", e);
            }}
            onBlur={() => formik.setFieldTouched("pLCarryForward", true)}
          />
          {formik.touched.pLCarryForward && formik.errors.pLCarryForward ? (
            <InputError error={formik.errors.pLCarryForward} />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Employee;
