import moment from "moment";
import React, { useState } from "react";
import DropdownOne from "../../shared components/Dropdown/DropdownOne";
import OutsideClickHandler from "react-outside-click-handler";

type propType = {
  state?: any;
  apiCall?: (val: any) => void;
  setState?: (val: any) => void;
};

const CalendarFilter: React.FC<propType> = ({ state, apiCall, setState }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [monthName, setNameMonth] = useState<string | undefined | number>(
    moment().month(state?.month).format("MMMM")
  );
  const [month, setMonth] = useState<string | undefined | number>(
    state?.month ?? ""
  );
  const [year, setYear] = useState<string | undefined | number>(
    state?.year ? Number(state?.year) : ""
  );

  const fn = {
    setFilterState: () => {
      setIsFilterOpen(!isFilterOpen);
    },
    addParam: () => {
      if (month != "" && year != "") {
        setState?.({
          ...state,
          month,
          year,
        });
        apiCall?.({
          ...state,
          page: 1,
          month,
          year,
        });
        fn.setFilterState();
      }
    },
    removeParam: () => {
      setMonth(moment().month().toString());
      setNameMonth(moment().month(moment().month().toString()).format("MMMM"));
      setYear(moment().year());
      setState?.({
        ...state,
        month: moment().month().toString(),
        year: moment().year().toString(),
      });
      apiCall?.({
        ...state,
        month: moment().month().toString(),
        year: moment().year().toString(),
      });
      fn.setFilterState();
    },
    generateMonths: () => {
      let arr: string[] = [];
      Array.apply(0, Array(12)).map((_, i) => {
        arr.push(moment().month(i).format("MMMM"));
      });
      return arr;
    },
    generateYears: () => {
      const today = moment("2100-01-01 00:00Z");
      const someday = moment("1999-01-01 00:00Z");
      const difff = today.diff(someday, "year");
      const arr = [...Array(difff).keys()].map((i) => i + someday.year());
      return arr;
    },
  };

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setIsFilterOpen(false)}>
        <div className="position-relative">
          <div
            className="filter_section_wrapper"
            onClick={fn.setFilterState}
            style={{
              backgroundColor: isFilterOpen ? "#fff" : "var(--bg_color2)",
              border: isFilterOpen ? "1px solid var(--border_color4)" : "none",
            }}
          >
            <img
              src={`/Assets/Svg/${
                isFilterOpen ? "filterClose.svg" : "calendarFilter.svg"
              }`}
              alt="filter"
            />
          </div>

          {isFilterOpen && (
            <div className="filter_option_wrapper">
              <h3
                className="mb-0 font14x600 p-2"
                style={{
                  borderBottom: "1px solid var(--border_color4)",
                }}
              >
                Select Month & Year
              </h3>
              <div className="p-2">
                <div className="mb-1">
                  <span className="font12x500 textColor3 me-1">Month</span>
                  <DropdownOne
                    placeholder="Month"
                    name="month"
                    data={fn.generateMonths()}
                    state={monthName}
                    setState={(e?: string, x?: string | number) => {
                      setNameMonth(e);
                      setMonth(x?.toString());
                    }}
                  />
                </div>
                <div className="">
                  <span className="font12x500 textColor3 me-1">Year</span>
                  <DropdownOne
                    placeholder="Year"
                    name="year"
                    data={fn.generateYears()}
                    state={year}
                    setState={(e?: string, x?: string | number) => {
                      setYear(e);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end p-2 gap-2">
                <div>
                  <button
                    className="btn font12x500 shadow-none btn_with_color"
                    onClick={fn.addParam}
                  >
                    Apply
                  </button>
                </div>
                <div>
                  <button
                    className="btn font12x500 shadow-none btn_without_color"
                    disabled={month === "" && year === "" ? true : false}
                    onClick={fn.removeParam}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default CalendarFilter;
