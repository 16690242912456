import Api from "../../../config/api";
import {
  CHECK_USER_SIGN_IN_OR_NOT,
  CREATE_ATTENDANCE,
  CREATE_DAILY_ATTENDANCE,
  CREATE_SALARY_CONSTRAINT,
  DAILY_ATTENDANCE_SEARCH,
  DELETE_PAYSLIP_BY_ID,
  GENERATE_PAYSLIP,
  GET_PAYSLIP_BY_ID,
  SEARCH_ATTENDANCE,
  SEARCH_ATTENDANCE_BY_ID,
  SEARCH_SALARY_CONSTRAINT,
  SEARCH_SALARY_CONSTRAINT_BY_ID,
  SIGN_IN,
  SIGN_OUT,
  UPDATE_ATTENDANCE,
  UPDATE_DAILY_ATTENDANCE,
  UPDATE_SALARY_CONSTRAINT,
} from "../../../utils/constants";
import { showToast } from "../../toast/ShowToast";

export const getAttendance = async (params?: any) => {
  try {
    const res = await Api(false).get(SEARCH_ATTENDANCE, { params });
    return Promise.resolve(res);
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
    return Promise.reject(error);
  }
};

export const getAttendanceById = async (id?: string) => {
  try {
    const res = await Api(false).get(`${SEARCH_ATTENDANCE_BY_ID}/${id}`);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const createAttendance = async (body: any, hideLoader: () => void) => {
  try {
    const res = await Api(false).post(CREATE_ATTENDANCE, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const updateAttendance = async (
  body: any,
  id: string,
  hideLoader: () => void
) => {
  try {
    const res = await Api(false).put(`${UPDATE_ATTENDANCE}/${id}`, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const signIn = async (body: any, hideLoader: () => void) => {
  try {
    const res = await Api(false).post(SIGN_IN, body);
    return Promise.resolve(res);
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
    return Promise.reject(error);
  }
};

export const signOut = async (body: any, hideLoader: () => void) => {
  try {
    const res = await Api(false).post(SIGN_OUT, body);
    return Promise.resolve(res);
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
    return Promise.reject(error);
  }
};

export const getDailyAttendance = async (params?: any) => {
  try {
    const res = await Api(false).get(DAILY_ATTENDANCE_SEARCH, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const updateDailyAttendance = async (
  body: any,
  id: string,
  hideLoader?: () => void
) => {
  try {
    const res = await Api(false).put(`${UPDATE_DAILY_ATTENDANCE}/${id}`, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader?.();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader?.();
    }
  }
};

export const getSalaryConstraint = async (params?: any) => {
  try {
    const res = await Api(false).get(SEARCH_SALARY_CONSTRAINT, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const getSalaryConstraintById = async (id?: string) => {
  try {
    const res = await Api(false).get(`${SEARCH_SALARY_CONSTRAINT_BY_ID}/${id}`);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const createSalaryConstraint = async (
  body: any,
  hideLoader: () => void
) => {
  try {
    const res = await Api(false).post(CREATE_SALARY_CONSTRAINT, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const updateSalaryConstraint = async (
  body: any,
  id: string,
  hideLoader: () => void
) => {
  try {
    const res = await Api(false).put(`${UPDATE_SALARY_CONSTRAINT}/${id}`, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const getPaySlipById = async (id?: string) => {
  try {
    const res = await Api(false).get(`${GET_PAYSLIP_BY_ID}/${id}`);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const generatePaySlip = async (id?: string, hideLoader?: () => void) => {
  try {
    const res = await Api(false).post(`${GENERATE_PAYSLIP}/${id}/slip`);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader?.();
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
      hideLoader?.();
    }
  }
};

export const deletePaySlipById = async (id: string, hideLoader: () => void) => {
  try {
    const res = await Api(false).delete(`${DELETE_PAYSLIP_BY_ID}/${id}`);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const createDailyAttendance = async (
  body: any,
  hideLoader: () => void
) => {
  try {
    const res = await Api(false).post(CREATE_DAILY_ATTENDANCE, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const checkUserSignInOrNot = async (date?: string) => {
  try {
    const res = await Api(false).get(`${CHECK_USER_SIGN_IN_OR_NOT}/${date}`);
    return Promise.resolve(res);
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
    return Promise.reject(error);
  }
};
