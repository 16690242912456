import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataNotFound from "../../../components/Data Not Found";
import Pagination from "../../../components/Pagination";
import MoveModal from "../../../components/Trashed Modal/MoveModal";
import UpdateData from "../../../components/View Lead Components/Components/UpdateData";
import CalendarFilter2 from "../../../Filters/CalendarFilter2";
import FunnelFilter from "../../../Filters/FunnelFilter";
import useLoading from "../../../hooks/useLoading";
import {
  getLead,
  getLeadStatusCount,
} from "../../../lib/api functions/lead/lead";
import ShowSelectedDate from "../../../shared components/DateInput/ShowSelectedDate";
import SkeletonLoader from "../../../shared components/Loader/SkeletonLoader";
import SpinLoader from "../../../shared components/Loader/SpinLoader";
import Search from "../../../shared components/Search";
import {
  DELETE_LEAD_SOFT,
  leadConfirmation,
  leadConfirmationMap,
  leadStatus,
  leadStatusMap,
  PRIMARY_COLOR,
} from "../../../utils/constants";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import { ParamType } from "./type";

export const setColor = (status: number): any => {
  switch (status) {
    case 0:
      return "#6BDA9F";
    case 1:
      return "#DA936B";
    case 2:
      return "#8F6BDA";
    case 3:
      return "#FF808B";
    case 4:
      return "#8081A5";
    default:
      return -1;
  }
};

export const getConfirmationColor = (confirmation: number): any => {
  switch (confirmation) {
    case 0:
      return "#F5BE5F";
    case 1:
      return "#6BDA9F";
    case 2:
      return "#FF808B";
    default:
      return null;
  }
};

export type commonType = {
  data: any;
  modal: boolean;
  check?: string;
};

const Lead = () => {
  const { userId, companyId, roles, setting } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const [leadCountLoading, showLoader2, hideLoader2] = useLoading();
  const [leads, setLeads] = useState<any>([]);
  const [leadStatusCount, setLeadStatusCount] = useState<any>();
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    company: companyId,
    isDeleted: false,
  });

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getLeads: async (params: ParamType) => {
      showLoader();
      const res = await getLead(params);
      if (res) {
        setLeads(res.data.leads);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
    getLeadStatusCount: async () => {
      showLoader2();
      const res = await getLeadStatusCount();
      if (res) {
        setLeadStatusCount(res.data.status);
        hideLoader2();
      }
    },
    addParam: (value?: number) => {
      let convertValue = value?.toString();
      if (!convertValue) {
        delete params?.status;
        fn.getLeads?.({ ...params, page: 1 });
      } else {
        setParams({
          ...params,
          status: Number(convertValue),
        });
        fn.getLeads?.({ ...params, page: 1, status: Number(convertValue) });
      }
    },
    gettingSelectedData: (
      isChecked: boolean,
      check: string,
      value?: string
    ) => {
      if (check === "all") {
        if (isChecked) {
          setSelectedRecords([]);
          leads?.map((item: any) => {
            setSelectedRecords((prev: any) => [...prev, item?._id]);
          });
        } else {
          setSelectedRecords([]);
        }
      } else {
        if (isChecked) {
          setSelectedRecords([...selectedRecords, value ?? ""]);
        } else {
          let filterSelectedValue = selectedRecords.filter(
            (item: any) => item !== value
          );
          setSelectedRecords(filterSelectedValue);
        }
      }
    },
  };

  useEffect(() => {
    if (roles?.length !== 0) {
      if (!roles[0]?.leads.complete) {
        params.user = userId;
        fn.getLeads(params);
      } else {
        fn.getLeads(params);
      }
    }
  }, []);

  useEffect(() => {
    fn.getLeadStatusCount();
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="data_render_wrapper">
          {/* filters */}
          <div className="filter_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-12 col-lg-4">
                  <Search
                    placeholder="Search by contact person name"
                    state={params}
                    setState={setParams}
                    apiCall={fn.getLeads}
                    paramValue="name"
                  />
                </div>
                <div className="col-12 col-lg-8 pt-3 pt-lg-0">
                  <div className="d-flex align-items-center gap-3 justify-content-start justify-content-lg-end">
                    {params?.start_date && params?.end_date && (
                      <>
                        <ShowSelectedDate
                          label="From:"
                          value={moment(params?.start_date).format(
                            setting?.dateFormat
                          )}
                        />
                        <ShowSelectedDate
                          label="To:"
                          value={moment(params?.end_date).format(
                            setting?.dateFormat
                          )}
                        />
                      </>
                    )}

                    <div className="d-flex gap-2">
                      <CalendarFilter2
                        state={params}
                        apiCall={fn.getLeads}
                        setState={setParams}
                        isFutureDateRequired={false}
                      />
                      <FunnelFilter
                        url="/lead/trashed"
                        deleteLabel="Leads"
                        state={params}
                        apiCall={fn.getLeads}
                        setState={setParams}
                      />

                      <button
                        className="btn font12x500 buttonOne shadow-none"
                        onClick={() => navigate("/lead/new")}
                      >
                        <img
                          src="/Assets/Svg/plusIcon.svg"
                          alt="icon"
                          className="me-2"
                        />
                        Add New Lead
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="list_view_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center pb-3">
                <div className="col-12 col-lg-3">
                  <span className="font16x600 textColor3">List of Leads</span>
                </div>
                <div className="col-12 col-lg-9 pt-3 pt-lg-0 dropdown_filter_wrapper">
                  <div className="d-flex justify-content-start justify-content-lg-end dropdown_filter_section">
                    {selectedRecords?.length !== 0 ? (
                      <div>
                        <button
                          className="btn font12x500 shadow-none modalBtnColor"
                          onClick={() =>
                            fn.openModal(
                              {
                                leadIds: selectedRecords,
                                isDeleted: true,
                              },
                              "move"
                            )
                          }
                          style={{
                            backgroundColor: "var(--error)",
                          }}
                        >
                          {`Move (${selectedRecords?.length})`}
                        </button>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <span className="font12x500 textColor3">Sort:</span>
                        <div className="sort_wrapper ms-2 d-flex">
                          <button
                            className="btn sort_btn font12x500"
                            style={{
                              backgroundColor:
                                params?.status === undefined
                                  ? "var(--bg_color2)"
                                  : "var(--bg_color4)",
                              color:
                                params?.status === undefined
                                  ? "#fff"
                                  : "var(--border_color2)",
                            }}
                            onClick={() => fn.addParam()}
                          >
                            All
                          </button>
                          {leadStatus?.map((item, i) => {
                            return (
                              <button
                                key={i}
                                className="btn sort_btn font12x500"
                                style={{
                                  backgroundColor:
                                    params?.status === item?.value
                                      ? "var(--bg_color2)"
                                      : "var(--bg_color4)",
                                  color:
                                    params?.status === item?.value
                                      ? "#fff"
                                      : "var(--border_color2)",
                                }}
                                onClick={() => fn.addParam(item?.value)}
                              >
                                {leadCountLoading ? (
                                  <SpinLoader
                                    height="15"
                                    width="15"
                                    color={PRIMARY_COLOR}
                                  />
                                ) : (
                                  `${
                                    leadStatusCount &&
                                    leadStatusCount[item?.value]
                                  } ${item?.label}`
                                )}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* table */}
            {leads?.length !== 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="font12x500 textColor3">
                        <th scope="col">
                          <div className="customCheck form-check d-flex align-items-center p-0">
                            <input
                              type="checkbox"
                              className="me-2"
                              id="checkbox"
                              name="rememberMe"
                              checked={
                                selectedRecords.length === leads.length
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                fn.gettingSelectedData(e.target.checked, "all");
                              }}
                            />
                          </div>
                        </th>
                        <th scope="col">#</th>
                        <th scope="col">Entry Date</th>
                        <th scope="col">Lead Details</th>
                        <th scope="col">Contact</th>
                        {roles[0]?.name === "admin" && (
                          <th scope="col">Created By</th>
                        )}
                        <th scope="col">Last Updated</th>
                        <th scope="col">Status</th>
                        <th scope="col">Confirmation</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {leads?.map((lead: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <th>
                              <div className="customCheck form-check d-flex align-items-center p-0">
                                <input
                                  type="checkbox"
                                  className="me-2"
                                  id="checkbox"
                                  name="rememberMe"
                                  value={lead?._id}
                                  checked={
                                    selectedRecords.includes(lead?._id)
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    fn.gettingSelectedData(
                                      e.target.checked,
                                      "single",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </th>
                            <th>
                              {(params.page - 1) * params.per_page + (i + 1)}
                            </th>
                            <td>
                              {moment(lead?.createdAt).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td>
                              <span className="textColor2 mb-1">
                                {lead?.name}
                              </span>
                              <br />
                              <span>{lead?.client?.name}</span>
                            </td>
                            <td>
                              <span className="textColor2 mb-1">
                                {lead?.email}
                              </span>
                              <br />
                              <span>{`${lead?.phoneCode} ${lead?.mobile}`}</span>
                            </td>
                            {roles[0]?.name === "admin" && (
                              <td>
                                <span className="textColor2 mb-1">
                                  {lead?.user?.name}
                                </span>
                                <br />
                                <span>{lead?.user?.role}</span>
                              </td>
                            )}
                            <td>
                              {moment(lead?.updatedAt).format(
                                setting?.dateFormat
                              )}
                            </td>
                            {roles[0]?.name === "super-admin" ||
                            roles[0]?.name === "admin" ? (
                              <td>
                                <UpdateData
                                  data={leadStatus}
                                  color={setColor(lead?.status)}
                                  label={leadStatusMap.get(lead?.status)}
                                  id={lead?._id}
                                  check="lead_status"
                                  apiCall={fn.getLeads}
                                  params={params}
                                  status={lead?.status}
                                  getLeadStatusCount={fn.getLeadStatusCount}
                                />
                              </td>
                            ) : (
                              <td
                                className="font12x500"
                                style={{
                                  color: setColor(lead?.status),
                                }}
                              >
                                {leadStatus?.map((item, i) => {
                                  if (item.value === lead?.status)
                                    return item.label;
                                })}
                              </td>
                            )}
                            {roles[0]?.name === "super-admin" ||
                            roles[0]?.name === "admin" ? (
                              <td>
                                <UpdateData
                                  data={leadConfirmation}
                                  color={getConfirmationColor(
                                    lead?.confirmation
                                  )}
                                  label={leadConfirmationMap.get(
                                    lead?.confirmation
                                  )}
                                  id={lead?._id}
                                  check="lead_confirmation"
                                  apiCall={fn.getLeads}
                                  params={params}
                                  status={lead?.confirmation}
                                />
                              </td>
                            ) : (
                              <td
                                className="font12x500"
                                style={{
                                  color: getConfirmationColor(
                                    lead?.confirmation
                                  ),
                                }}
                              >
                                {leadConfirmation?.map((item, i) => {
                                  if (item.value === lead?.confirmation)
                                    return item.label;
                                })}
                              </td>
                            )}
                            <td>
                              <button
                                className="btn font12x500 buttonTwo shadow-none"
                                onClick={() =>
                                  navigate(`/lead/view/${lead?._id}/profile`)
                                }
                              >
                                View Details
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : loading ? (
              <SkeletonLoader />
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>

        {/* pagination */}
        {leads?.length !== 0 && (
          <div className="pagination_wrapper pt-3 px-3">
            <Pagination
              currentPage={params.page}
              onPageChange={(e) => {
                fn.getLeads({ ...params, page: e });
                setParams({
                  ...params,
                  page: e,
                });
              }}
              pageSize={params.per_page}
              totalCount={Math.ceil(totalResult / params.per_page)}
              onChangePerPage={(e) => {
                fn.getLeads({ ...params, per_page: e });
                setParams({
                  ...params,
                  per_page: e,
                });
              }}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {commonData?.modal && commonData?.check === "move" && (
        <MoveModal
          label="Lead"
          dataIds={commonData?.data}
          setState={setCommonData}
          url={DELETE_LEAD_SOFT}
          variableName="leadIds"
          apiCall={fn.getLeads}
          params={params}
          setSelectedRecords={setSelectedRecords}
        />
      )}
    </>
  );
};

export default Lead;
