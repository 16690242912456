import React, { useContext, useEffect, useState } from "react";
import { commonType } from "../../../pages/main/lead";
import { MdFileDownload } from "react-icons/md";
import moment from "moment";
import {
  deletePaySlipById,
  getPaySlipById,
} from "../../../lib/api functions/attendance/attendance";
import useLoading from "../../../hooks/useLoading";
import SpinLoader from "../../../shared components/Loader/SpinLoader";
import PrintComponents from "react-print-components";
import PaySlipComponent from "./PaySlip";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import { showToast } from "../../../lib/toast/ShowToast";
import { PRIMARY_COLOR } from "../../../utils/constants";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall: (val: any) => void;
  params: any;
};

const PaySlip: React.FC<propType> = (props) => {
  const { state, setState, params, apiCall } = props;
  const { roles } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [loadingPaySlipDelete, showLoader2, hideLoader2] = useLoading();
  const [slipData, setSlipData] = useState<any>([]);

  const fn = {
    setCommonState: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
    getPaySlipById: async () => {
      showLoader();
      const res = await getPaySlipById(state?.data?.isSalarySlipExist);
      if (res) {
        setSlipData(res.data);
        hideLoader();
      }
    },
    deletePaySlipById: async (id: string) => {
      showLoader2();
      const res = await deletePaySlipById(id, hideLoader);
      if (res) {
        showToast(`Pay slip deleted.`, "success");
        apiCall(params);
        fn.setCommonState();
        hideLoader2();
      }
    },
  };

  useEffect(() => {
    fn.getPaySlipById();
  }, []);

  return (
    <>
      <div
        className="delete_modal_wrapper"
        style={{
          padding: "20px 20px",
          display: "block",
          overflow: "auto",
        }}
      >
        <div className="paySlip_wrapper">
          <div className="d-flex justify-content-between align-items-center px-4">
            <h3 className="font14x500 textColor3 mb-0">
              {`Pay Slip - ${moment()
                .month(state?.data?.month ?? "")
                .format("MMMM")} ${state?.data?.year}`}
            </h3>
            <img
              src="/Assets/Svg/closeIcon.svg"
              alt="close"
              style={{
                cursor: "pointer",
              }}
              onClick={fn.setCommonState}
              loading="lazy"
            />
          </div>

          {loading ? (
            <div className="h-100 d-flex justify-content-center align-items-center">
              <SpinLoader height="30" width="30" color={PRIMARY_COLOR} />
            </div>
          ) : (
            <>
              <PaySlipComponent slipData={slipData} />

              <div className="d-flex justify-content-between">
                {(roles[0]?.name === "admin" ||
                  roles[0]?.name === "super-admin") && (
                  <div className="mt-4 px-4">
                    <button
                      className="btn font12x500 shadow-none modalBtnColor"
                      style={{
                        backgroundColor: "var(--error)",
                      }}
                      onClick={() =>
                        fn.deletePaySlipById(state?.data?.isSalarySlipExist)
                      }
                    >
                      {loadingPaySlipDelete ? (
                        <SpinLoader height="20" width="20" color="#ffffff" />
                      ) : (
                        "Delete Slip"
                      )}
                    </button>
                  </div>
                )}

                <div className="mt-4 px-4">
                  <PrintComponents
                    trigger={
                      <button
                        className="btn font12x500 shadow-none modalBtnColor"
                        style={{
                          backgroundColor: "var(--bg_color2)",
                        }}
                      >
                        Download
                        <MdFileDownload
                          className="ms-1"
                          style={{
                            fontSize: 16,
                          }}
                        />
                      </button>
                    }
                  >
                    <PaySlipComponent slipData={slipData} />
                  </PrintComponents>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PaySlip;
