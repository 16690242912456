import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

type PropType = {
  loading: any;
  expenseId?: string;
  expense: any;
};

const ExpenseSidebar: React.FC<PropType> = (props) => {
  const { expense, loading, expenseId } = props;
  const navigate = useNavigate();

  return (
    <>
      <div className="">
        <div className="person_name_wrapper viewlead_content_header justify-content-between">
          <div
            className="cursor_pointer d-flex align-items-center"
            onClick={() => navigate("/expense")}
          >
            <img
              src="/Assets/Svg/backBtn.svg"
              alt="close"
              width={13}
              height={13}
            />
            <h3 className="mb-0 font14x600 textColor3 d-inline-block ms-2">
              Back
            </h3>
          </div>
          <div>
            <button
              className="btn font12x500 buttonOne shadow-none"
              onClick={() => navigate("/expense/form")}
            >
              <img
                src="/Assets/Svg/plusIcon.svg"
                alt="icon"
                className="me-2"
                width={13}
                height={13}
              />
              New
            </button>
          </div>
        </div>
        <div className="view_doc_menu_wrapper">
          {loading ? (
            <>
              <Skeleton height="50px" borderRadius="4px" />
              <Skeleton height="50px" borderRadius="4px" />
              <Skeleton height="50px" borderRadius="4px" />
              <Skeleton height="50px" borderRadius="4px" />
            </>
          ) : (
            expense?.map((item: any, i: number) => {
              return (
                <div
                  key={i}
                  className={
                    expenseId === item?._id
                      ? "view_single_doc_menu_active"
                      : "view_single_doc_menu"
                  }
                  onClick={() => navigate(`/expense/view/${item?._id}`)}
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="mb-0 font12x500 textColor2">
                        {item?.vendor?.name}
                      </p>
                      <p className="mb-0 font10x400 textColor1">
                        {`${item?.number}`}
                      </p>
                    </div>
                    <div>
                      <p className="mb-0 font12x500 textColor1">
                        {`₹ ${(
                          item?.calculation?.total - item?.paidAmount
                        )?.toLocaleString()}`}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default ExpenseSidebar;
