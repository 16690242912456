import { useFormik } from "formik";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import { createLead, updateLead } from "../../lib/api functions/lead/lead";
import { showToast } from "../../lib/toast/ShowToast";
import { LeadContext } from "../../pages/main/lead/new lead";
import { PersonDetails } from "../../pages/main/lead/new lead/type";
import PhoneCode from "../../shared components/Dropdown/PhoneCode";
import InputError from "../../shared components/InputError/InputError";
import SpinLoader from "../../shared components/Loader/SpinLoader";
import InputField from "../../shared components/TextInput";
import { MainContext } from "../../utils/private_routes/PrivateRoutes";
import { leadStepThreeSchema } from "../../utils/validationSchema";

type propType = {
  companySelected?: boolean;
  clientId?: string;
};

const ContactPersonForm: React.FC<propType> = (props) => {
  const { roles } = useContext(MainContext);
  const { clientId, companySelected } = props;
  const [loading, showLoader, hideLoader] = useLoading();
  const { fn, initialValuesPerson } = useContext(LeadContext);
  const navigate = useNavigate();

  const functions = {
    updateLead: async (leadId: string) => {
      let finalObj = {
        confirmation: 1,
      };
      const res = await updateLead(finalObj, leadId);
      return res;
    },
  };

  const formik = useFormik<PersonDetails>({
    initialValues: initialValuesPerson,
    validationSchema: leadStepThreeSchema,
    onSubmit: async (values) => {
      let finalObj = {
        client: clientId && clientId,
        name: values?.name,
        email: values?.email,
        mobile: values?.mobile,
        phoneCode: values?.phoneCode,
      };

      showLoader();
      const res = await createLead(finalObj, hideLoader);
      if (res) {
        showToast(`Lead created`, "success");
        if (roles[0]?.name === "admin" || roles[0]?.name === "super-admin") {
          const updateLeadRes = await functions.updateLead(res.data.lead._id);
          if (updateLeadRes) {
            showToast(`Lead updated`, "success");
            navigate("/lead", { replace: true });
          } else {
            navigate("/lead", { replace: true });
          }
        } else {
          navigate("/lead", { replace: true });
        }
        hideLoader();
      }
    },
  });

  return (
    <>
      <div className="details_form_wrapper p-3">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="font16x600 textColor3 mb-0">Contact Person Details</h3>
          {!companySelected && (
            <div className="step_wrapper font12x400 textColor3">3 of 3</div>
          )}
        </div>

        {/* form */}
        <div className="mt-4">
          <form onSubmit={formik.handleSubmit}>
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="mb-3">
                    <InputField
                      id="name"
                      label="Contact Person Name*"
                      type="text"
                      state={formik.values.name}
                      setState={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <InputError error={formik.errors.name} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="contactNumber"
                      className="form-label font12x400 textColor3 mb-1"
                    >
                      Phone*
                    </label>
                    <div className="input-group">
                      <PhoneCode
                        formik={formik}
                        fieldName="phoneCode"
                        fieldName2="mobile"
                      />
                      <input
                        type="text"
                        className="form-control shadow-none font12x600 border-0"
                        id="mobile"
                        value={formik.values.mobile}
                        onChange={(e) => {
                          formik.setFieldValue("mobile", e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                        autoComplete="new-mobile"
                      />
                    </div>
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <InputError error={formik.errors.mobile} />
                    ) : null}
                    {formik.touched.phoneCode && formik.errors.phoneCode ? (
                      <InputError error={formik.errors.phoneCode} />
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="mb-3">
                    <InputField
                      id="email"
                      label="Email"
                      type="text"
                      state={formik.values.email}
                      setState={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <InputError error={formik.errors.email} />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-flex justify-content-end mt-4">
                  {!companySelected && (
                    <button
                      type="button"
                      className="btn font12x500 shadow-none custom_btn_outline"
                      onClick={fn.prevStep}
                    >
                      Back
                    </button>
                  )}

                  <button
                    type="submit"
                    className="btn font12x500 shadow-none ms-3 custom_btn"
                  >
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Create"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactPersonForm;
