import { useFormik } from "formik";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import useLoading from "../../../../hooks/useLoading";
import { updateUser } from "../../../../lib/api functions/user";
import { showToast } from "../../../../lib/toast/ShowToast";
import { UserContext } from "../../../../pages/main/user/add update user";
import { UserObject } from "../../../../pages/main/user/add update user/type";
import DropdownOne from "../../../../shared components/Dropdown/DropdownOne";
import InputError from "../../../../shared components/InputError/InputError";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import InputField from "../../../../shared components/TextInput";
import { pLCarryForwardMap } from "../../../../utils/constants";
import { userAttendanceDetailsSchema } from "../../../../utils/validationSchema";

const UserAttendance = () => {
  const { userId, user, userLoading, getUser } = useContext(UserContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });

  const fn = {
    getConvertedDate: (time: string | null) => {
      let convertedTime: string = "";
      if (time) {
        convertedTime = moment()
          .hour(parseInt(time?.split(":")[0]))
          .minute(parseInt(time?.split(":")[1]))
          .second(0)
          .millisecond(0)
          .toISOString() as unknown as string;
      } else {
        convertedTime = "null";
      }
      return convertedTime;
    },
    putUserValueInState: () => {
      formik.setFieldValue(
        "startTime",
        user.workTimings
          ? moment(user.workTimings?.startTime).format("HH:mm")
          : null
      );
      formik.setFieldValue(
        "halfTime",
        user.workTimings
          ? moment(user.workTimings?.halfTime).format("HH:mm")
          : null
      );
      formik.setFieldValue(
        "endTime",
        user.workTimings
          ? moment(user.workTimings?.endTime).format("HH:mm")
          : null
      );
      formik.setFieldValue("maxHours", user.maxHours);
      formik.setFieldValue("pLHours", user.pLHours);
      formik.setFieldValue("numberOfPL", user.numberOfPL);
      formik.setFieldValue("pLCarryForward", user.pLCarryForward);
      formik.setFieldValue("isEdit", userId ? true : false);
    },
  };
  const initialValues: UserObject = {
    startTime: null,
    halfTime: null,
    endTime: null,
    maxHours: "",
    pLHours: "",
    numberOfPL: "",
    pLCarryForward: "",
    isEdit: false,
  };

  const formik = useFormik<UserObject>({
    initialValues,
    validationSchema: userAttendanceDetailsSchema,
    onSubmit: async (values) => {
      let finalObj = {};
      delete updatedValues.isObjChange;
      finalObj = {
        workTimings: {
          startTime: fn.getConvertedDate(values.startTime as string),
          halfTime: fn.getConvertedDate(values.halfTime as string),
          endTime: fn.getConvertedDate(values.endTime as string),
        },
        ...updatedValues,
      };

      showLoader();
      if (userId) {
        const res = await updateUser(finalObj, userId, hideLoader);
        if (res) {
          showToast(`User updated`, "success");
          getUser();
          hideLoader();
        }
      }
    },
  });
  useEffect(() => {
    !userLoading && userId && fn.putUserValueInState();
  }, [userLoading]);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font16x600 textColor3">Attendance</p>
        </div>

        <div className="details_form_wrapper">
          {/* form */}
          <form onSubmit={formik.handleSubmit}>
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="mb-3">
                    <div className="mb-2">
                      <h3 className="font12x600 textColor3 mb-0">
                        Work Timings
                      </h3>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <InputField
                          id="startTime"
                          label="Start Time*"
                          type="time"
                          name="startTime"
                          state={formik.values.startTime ?? ""}
                          setState={(e) => {
                            formik.setFieldValue("startTime", e.target.value);
                            setUpdatedValues({
                              ...updatedValues,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.startTime && formik.errors.startTime ? (
                          <InputError error={formik.errors.startTime} />
                        ) : null}
                      </div>
                      <div>
                        <InputField
                          id="halfTime"
                          label="Half Time*"
                          type="time"
                          name="halfTime"
                          state={formik.values.halfTime ?? ""}
                          setState={(e) => {
                            formik.setFieldValue("halfTime", e.target.value);
                            setUpdatedValues({
                              ...updatedValues,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.halfTime && formik.errors.halfTime ? (
                          <InputError error={formik.errors.halfTime} />
                        ) : null}
                      </div>
                      <div>
                        <InputField
                          id="endTime"
                          label="End Time*"
                          type="time"
                          name="endTime"
                          state={formik.values.endTime ?? ""}
                          setState={(e) => {
                            formik.setFieldValue("endTime", e.target.value);
                            setUpdatedValues({
                              ...updatedValues,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched?.endTime && formik.errors?.endTime ? (
                          <InputError error={formik.errors.endTime} />
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="maxHours"
                      label="Max Hours"
                      type="number"
                      state={formik.values.maxHours}
                      setState={(e) => {
                        formik.setFieldValue("maxHours", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          maxHours: e.target.value,
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                      width="200px"
                    />
                    {formik.touched.maxHours && formik.errors.maxHours ? (
                      <InputError error={formik.errors.maxHours} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="pLHours"
                      label="Paid Hours"
                      type="number"
                      state={formik.values.pLHours}
                      setState={(e) => {
                        formik.setFieldValue("pLHours", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          pLHours: e.target.value,
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                      width="200px"
                    />
                    {formik.touched.pLHours && formik.errors.pLHours ? (
                      <InputError error={formik.errors.pLHours} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="numberOfPL"
                      label="Number of PL"
                      type="number"
                      state={formik.values.numberOfPL}
                      setState={(e) => {
                        formik.setFieldValue("numberOfPL", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          numberOfPL: e.target.value,
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                      width="200px"
                    />
                    {formik.touched.numberOfPL && formik.errors.numberOfPL ? (
                      <InputError error={formik.errors.numberOfPL} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <DropdownOne
                      label="PL Carry Forward"
                      placeholder=""
                      name="pLCarryForward"
                      width="200px"
                      data={pLCarryForwardMap}
                      state={formik.values.pLCarryForward?.toString()}
                      setState={(e?: string, x?: string | number) => {
                        formik.setFieldValue("pLCarryForward", e);
                        setUpdatedValues({
                          ...updatedValues,
                          pLCarryForward: e,
                          isObjChange: true,
                        });
                      }}
                      onBlur={() =>
                        formik.setFieldTouched("pLCarryForward", true)
                      }
                    />
                    {formik.touched.pLCarryForward &&
                    formik.errors.pLCarryForward ? (
                      <InputError error={formik.errors.pLCarryForward} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end my-4">
              <button
                type="submit"
                className="btn font12x500 shadow-none ms-3 custom_btn"
                disabled={updatedValues?.isObjChange ? false : true}
              >
                {loading ? (
                  <SpinLoader height="20" width="20" color="#fff" />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UserAttendance;
