import React from "react";
import Api from "../../config/api";
import useLoading from "../../hooks/useLoading";
import { showToast } from "../../lib/toast/ShowToast";
import { commonType } from "../../pages/main/lead";
import SpinLoader from "../../shared components/Loader/SpinLoader";

type propType = {
  label: string;
  dataIds: any;
  setState: (val: commonType) => void;
  url?: string;
  variableName?: string;
  apiCall?: (val: any) => void;
  setSelectedRecords?: (val: any) => void;
  params?: any;
};

const MoveModal: React.FC<propType> = (props) => {
  const {
    label,
    dataIds,
    setState,
    url,
    variableName,
    apiCall,
    params,
    setSelectedRecords,
  } = props;
  const [loading, showLoader, hideLoader] = useLoading();

  const fn = {
    setCommonState: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
    deleteData: async () => {
      try {
        showLoader();
        const res = await Api(false).delete(`${url}`, { data: dataIds });
        if (res) {
          showToast(
            `${
              dataIds[variableName ?? ""]?.length > 1 ? label + "s" : label
            } Moved`,
            "success"
          );
          apiCall?.(params);
          setSelectedRecords?.([]);
          fn.setCommonState();
          hideLoader();
        }
      } catch (error: any) {
        if (error.response.status >= 400 && error.response.status <= 499) {
          showToast(`${error.response.statusText}`, "error");
          hideLoader();
        } else {
          showToast(`${error.response.data.message}`, "error");
          hideLoader();
        }
      }
    },
    returnMsgAccordingToPage: (): any => {
      switch (label) {
        case "Lead":
          return (
            <>
              <p className="mb-0 font10x400 textColor3">
                <span className="font10x500 textColor3">Important Note: </span>
                Data cannot be recovered for:
              </p>
              <ul className="mb-1 ps-3">
                <li className="font10x400 textColor3">Invoice</li>
                <li className="font10x400 textColor3">Payment</li>
              </ul>
              <p className="mb-0 font10x400 textColor3">
                Are you sure you want to move?
              </p>
            </>
          );
        default:
          return (
            <>
              <p className="mb-0 font10x400 textColor3">
                Are you sure you want to move?{" "}
                <span className="font10x500 textColor3">Note: </span>This action
                is recoverable.
              </p>
            </>
          );
      }
    },
  };

  return (
    <>
      <div className="delete_modal_wrapper">
        <div className="delete_modal_section">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="font14x500 textColor3 mb-0">{`Move ${
              dataIds[variableName ?? ""]?.length > 1 ? label + "s" : label
            } to bin`}</h3>
            <img
              src="/Assets/Svg/closeIcon.svg"
              alt="close"
              style={{
                cursor: "pointer",
              }}
              onClick={fn.setCommonState}
            />
          </div>
          <div className="mt-2">{fn.returnMsgAccordingToPage()}</div>
          <div className="mt-4 d-flex gap-2 justify-content-end">
            <button
              className="btn font12x500 shadow-none modalBtnOutline"
              onClick={fn.setCommonState}
            >
              Cancel
            </button>
            <button
              className="btn font12x500 shadow-none modalBtnColor"
              style={{
                backgroundColor: "var(--error)",
              }}
              onClick={fn.deleteData}
            >
              {loading ? (
                <SpinLoader height="20" width="20" color="#fff" />
              ) : (
                "Move"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoveModal;
