import React from "react";
import usePagination, { DOTS } from "../../hooks/usePagination";

type propType = {
  onPageChange: (e: any) => void;
  onChangePerPage: (e: any) => void;
  totalCount: number;
  currentPage: number;
  pageSize: number;
};

const Pagination: React.FC<propType> = ({
  onPageChange,
  totalCount,
  currentPage,
  pageSize,
  onChangePerPage,
}) => {
  let siblingCount = 1;
  const paginationRange = usePagination({
    totalCount,
    pageSize,
    siblingCount,
    currentPage,
  });

  //   if (currentPage === 0 || paginationRange.length < 2) {
  //     return null;
  //   }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  //   let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="d-flex">
          {currentPage !== 1 && (
            <div className="paginationBtn" onClick={onPrevious}>
              <img src="/Assets/Svg/lessThen.svg" alt="lessthen" width={7} />
            </div>
          )}

          <div className="d-flex">
            {/* {[...Array(totalPages)].map((v, pageNo) => {
            return (
              <div
                key={pageNo}
                className={`${
                  currentPage === pageNo + 1
                    ? "paginationBtn selected"
                    : "paginationBtn"
                }`}
                onClick={() => selectedPage(pageNo)}
              >
                <span>{pageNo + 1}</span>
              </div>
            );
          })} */}
            {paginationRange?.map((pageNumber) => {
              if (pageNumber === DOTS) {
                return (
                  <div key={Math.random()} className="paginationDots">
                    <span>{pageNumber}</span>
                  </div>
                );
              }
              return (
                <div
                  key={pageNumber}
                  className={`${
                    pageNumber === currentPage
                      ? "paginationBtn selected"
                      : "paginationBtn"
                  }`}
                  onClick={() => onPageChange(pageNumber)}
                >
                  <span className="font14x500">{pageNumber}</span>
                </div>
              );
            })}
          </div>
          {currentPage !== totalCount && (
            <div className="paginationBtn" onClick={onNext}>
              <img
                src="/Assets/Svg/greaterThen.svg"
                alt="greaterthen"
                width={7}
              />
            </div>
          )}
        </div>
      </div>
      <div className="per_page_container">
        <div>
          <select
            className="form-select font12x500 textColor3 shadow-none dropdown_filter"
            onChange={(e) => {
              onChangePerPage(e.target.value);
            }}
          >
            <option value={10}>10 per page</option>
            <option value={20}>20 per page</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default Pagination;
