import Api from "../../../config/api";
import {
  CREATE_PURCHASE_ORDER,
  SEARCH_PURCHASE_ORDER,
  SEARCH_PURCHASE_ORDER_BY_ID,
  UPDATE_PURCHASE_ORDER,
} from "../../../utils/constants";
import { showToast } from "../../toast/ShowToast";

export const getPO = async (params: any) => {
  try {
    const res = await Api(false).get(SEARCH_PURCHASE_ORDER, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const getPOById = async (id?: string) => {
  try {
    const res = await Api(false).get(`${SEARCH_PURCHASE_ORDER_BY_ID}/${id}`);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const createPO = async (body: any, hideLoader: () => void) => {
  try {
    const res = await Api(true).post(CREATE_PURCHASE_ORDER, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const updatePO = async (
  body: any,
  id: string,
  hideLoader: () => void
) => {
  try {
    const res = await Api(true).put(`${UPDATE_PURCHASE_ORDER}/${id}`, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};
