import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CgClose } from "react-icons/cg";
import { leadConfirmation } from "../utils/constants";
import OutsideClickHandler from "react-outside-click-handler";

type propType = {
  url: string;
  deleteLabel?: string;
  state: any;
  apiCall: (val: any) => void;
  setState: (val: any) => void;
};

const FunnelFilter: React.FC<propType> = (props) => {
  const { url, deleteLabel, state, apiCall, setState } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const fn = {
    setFilterState: () => {
      setIsFilterOpen(!isFilterOpen);
    },
    redirectToTrashed: (url: string) => {
      navigate(url);
      setIsFilterOpen(false);
    },
    addParam: (value?: string) => {
      if (!value) {
        delete state?.confirmation;
        apiCall?.({ ...state, page: 1 });
      } else {
        setState({
          ...state,
          confirmation: Number(value),
        });
        apiCall?.({ ...state, page: 1, confirmation: Number(value) });
      }
    },
  };

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setIsFilterOpen(false)}>
        <div className="position-relative">
          <div
            className="filter_section_wrapper"
            onClick={fn.setFilterState}
            style={{
              backgroundColor: isFilterOpen ? "#fff" : "var(--bg_color2)",
              border: isFilterOpen ? "1px solid var(--border_color4)" : "none",
            }}
          >
            <img
              src={`/Assets/Svg/${
                isFilterOpen ? "filterClose.svg" : "filter.svg"
              }`}
              alt="filter"
            />
          </div>

          {isFilterOpen && (
            <div
              className="filter_option_wrapper"
              style={{
                minWidth: "137px",
              }}
            >
              <div className="p-2">
                <div className="radioCustom d-flex align-items-center mt-2">
                  <input
                    type="radio"
                    className="me-2"
                    id="All"
                    name="confirmation"
                    value={undefined}
                    checked={!state.confirmation ? true : false}
                    onChange={(e) => {
                      fn.addParam(e.target.value);
                    }}
                  />
                  <label
                    className="font12x500 textColor1 form-check-label"
                    htmlFor="All"
                  >
                    All
                  </label>
                </div>
                {leadConfirmation?.map((item: any, i: number) => {
                  return (
                    <div className="radioCustom d-flex align-items-center mt-2">
                      <input
                        type="radio"
                        className="me-2"
                        id={item?.label}
                        value={item?.value}
                        name="confirmation"
                        checked={
                          state.confirmation === item?.value ? true : false
                        }
                        onChange={(e) => {
                          fn.addParam(e.target.value);
                        }}
                      />
                      <label
                        className="font12x500 textColor1 form-check-label"
                        htmlFor={item?.label}
                      >
                        {item?.label}
                      </label>
                    </div>
                  );
                })}
              </div>

              <div
                className="py-2"
                style={{
                  borderTop: "1px solid var(--border_color4)",
                }}
              >
                <div
                  className="py-2 text-center"
                  style={{
                    backgroundColor: pathname.includes("/trashed")
                      ? "var(--bg_color2)"
                      : "var(--error)",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => fn.redirectToTrashed(url)}
                >
                  <p
                    className="mb-0 font12x500 textColor1 d-flex justify-content-center align-items-center"
                    style={{
                      color: "#fff",
                    }}
                  >
                    {`Trash ${deleteLabel}`}

                    {pathname.includes("/trashed") && (
                      <CgClose className="ms-2" />
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default FunnelFilter;
