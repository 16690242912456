import React from "react";
import { useNavigate } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="error_404_wrapper">
        <div className="error_404_container">
          <div className="text-center">
            <img
              src="/Assets/Svg/404error.svg"
              width={300}
              height={300}
              alt="error_page"
              draggable={false}
            />
            <div className="text-center">
              <h1 className="font54x400">Page not found</h1>
              <p className="mb-0 font16x400">
                The page you are looking for doesn't exist or an
              </p>
              <p className="font16x400">other error occurred.</p>
              <button
                className="btn font12x500 buttonOne shadow-none d-inline-block"
                onClick={() => navigate(-1)}
              >
                <TiArrowBack className="me-1" />
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
