import React, { useContext } from "react";
import { ProfileContext } from "../../../../pages/main/lead/view lead/sub pages/profile";
import AddressDetails from "./AddressDetails";
import CompanyDetails from "./CompanyDetails";
import PersonDetails from "./PersonDetails";

const ProfileProcess = () => {
  const { step } = useContext(ProfileContext);

  switch (step) {
    case 1:
      return (
        <>
          <PersonDetails />
        </>
      );
    case 2:
      return (
        <>
          <CompanyDetails />
        </>
      );
    case 3:
      return (
        <>
          <AddressDetails />
        </>
      );
    default:
      return null;
  }
};

export default ProfileProcess;
