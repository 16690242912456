import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import useLoading from "../../../../../hooks/useLoading";
import {
  getDocumentSettingById,
  updateDocumentSetting,
} from "../../../../../lib/api functions/setting/document";
import { showToast } from "../../../../../lib/toast/ShowToast";
import InputError from "../../../../../shared components/InputError/InputError";
import SpinLoader from "../../../../../shared components/Loader/SpinLoader";
import InputField from "../../../../../shared components/TextInput";
import { MainContext } from "../../../../../utils/private_routes/PrivateRoutes";
import { locationSchema } from "../../../../../utils/validationSchema";
import { LocationType } from "./type";

const SettingLocation = () => {
  const { companyId, roles, setDocumentSetting } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [paymentLoading, showLoader2, hideLoader2] = useLoading();

  const initialValues: LocationType = {
    _id: "",
    lat: 0,
    lng: 0,
    validDistance: 0,
  };
  const fn = {
    getLocationSetting: async (params?: any) => {
      showLoader2();
      const res = await getDocumentSettingById(companyId, params);
      if (res) {
        formik.setFieldValue("_id", res.data.documentSetting._id);
        formik.setFieldValue("lat", res.data.documentSetting?.location?.lat);
        formik.setFieldValue("lng", res.data.documentSetting?.location?.lng);
        formik.setFieldValue(
          "validDistance",
          res.data.documentSetting?.location?.validDistance
        );
        hideLoader2();
      }
    },
    setCoordinatesIntoState: () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            formik.setFieldValue("lat", Number(position.coords.latitude));
            formik.setFieldValue("lng", Number(position.coords.longitude));
          },
          (error: GeolocationPositionError) => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                showToast(
                  "Please allow location for getting coordinates.",
                  "error"
                );
                break;
              case error.POSITION_UNAVAILABLE:
                showToast("Location information is unavailable.", "error");
                break;
              case error.TIMEOUT:
                showToast(
                  "The request to get user location timed out.",
                  "error"
                );
                break;
              default:
                showToast("An unknown error occurred.", "error");
            }
          },
          {
            enableHighAccuracy: true,
          }
        );
      }
    },
  };

  const formik = useFormik<LocationType>({
    initialValues,
    validationSchema: locationSchema,
    onSubmit: async (values) => {
      const formObj = {
        location: {
          lat: values.lat,
          lng: values.lng,
          validDistance: values.validDistance,
        },
      };
      showLoader();
      const res = await updateDocumentSetting(formObj, values._id, hideLoader);
      if (res) {
        setDocumentSetting(res.data.documentSetting);
        showToast(`Location setting updated`, "success");
        hideLoader();
      }
    },
  });

  useEffect(() => {
    if (roles?.length !== 0) {
      fn.getLocationSetting({
        f: "location",
        company: companyId,
      });
    }
  }, []);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font14x600 textColor3">Location</p>
        </div>

        {paymentLoading ? (
          <>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={50} height={20} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={50} height={20} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={50} height={20} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={50} height={20} borderRadius={8} />
              </div>
            </div>
          </>
        ) : (
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <div className="d-flex justify-content-start align-items-sm-center justify-content-sm-between flex-column flex-sm-row mb-3">
                  <label
                    htmlFor="lat"
                    className="form-label font12x400 textColor1 mb-0"
                  >
                    Latitude
                  </label>

                  <div
                    className="setting_input_wrapper"
                    style={{
                      width: "auto",
                    }}
                  >
                    <InputField
                      id="lat"
                      type="number"
                      state={formik.values.lat}
                      setState={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isDisabled={true}
                    />
                    {formik.touched.lat && formik.errors.lat ? (
                      <InputError error={formik.errors.lat} />
                    ) : null}
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-sm-center justify-content-sm-between flex-column flex-sm-row mb-3">
                  <label
                    htmlFor="lng"
                    className="form-label font12x400 textColor1 mb-0"
                  >
                    Longitude
                  </label>

                  <div
                    className="setting_input_wrapper"
                    style={{
                      width: "auto",
                    }}
                  >
                    <InputField
                      id="lng"
                      type="number"
                      state={formik.values.lng}
                      setState={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isDisabled={true}
                    />
                    {formik.touched.lng && formik.errors.lng ? (
                      <InputError error={formik.errors.lng} />
                    ) : null}
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-sm-center justify-content-sm-end mb-3">
                  <div
                    className="font12x500 d-flex align-items-center custom_btn cursor_pointer"
                    onClick={fn.setCoordinatesIntoState}
                  >
                    Get Coordinates
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-start align-items-sm-center justify-content-sm-between flex-column flex-sm-row mb-3">
                <label
                  htmlFor="validDistance"
                  className="form-label font12x400 textColor1 mb-0"
                >
                  Valid Distance (Km)
                </label>

                <div
                  className="setting_input_wrapper"
                  style={{
                    width: "auto",
                  }}
                >
                  <InputField
                    id="validDistance"
                    type="number"
                    state={formik.values.validDistance}
                    setState={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.validDistance &&
                  formik.errors.validDistance ? (
                    <InputError error={formik.errors.validDistance} />
                  ) : null}
                </div>
              </div>

              <div className="d-flex justify-content-end my-4">
                <button className="btn font12x500 shadow-none custom_btn ms-3">
                  {loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default SettingLocation;
