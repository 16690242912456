import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProposalSidebar from "../../../../../../../components/View Lead Components/MenuData/Proposal Components/View Components/ProposalSidebar";
import { MdOutlineEdit } from "react-icons/md";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { FaPrint } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import useLoading from "../../../../../../../hooks/useLoading";
import Skeleton from "react-loading-skeleton";
import {
  getProposal,
  getProposalById,
} from "../../../../../../../lib/api functions/lead/proposal";
import GenerateProposalDoc from "../../../../../../../components/GenerateDoc/GenerateProposalDoc";
import { MainContext } from "../../../../../../../utils/private_routes/PrivateRoutes";
import PrintComponents from "react-print-components";
import { getGeneralSetting } from "../../../../../../../lib/api functions/setting/general";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";

const ViewProposalPage = () => {
  const { setPageTitle, companyId } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [loading2, showLoader2, hideLoader2] = useLoading();
  const [proposal, setProposal] = useState<any>([]);
  const [singleProposal, setSingleProposal] = useState<any>([]);
  const [generalSetting, setGeneralSetting] = useState<any>("");
  const { proposalId, id } = useParams();
  const navigate = useNavigate();

  const fn = {
    getProposals: async (params: any) => {
      showLoader();
      const res = await getProposal(params);
      if (res) {
        setProposal(res.data.proposals);
        hideLoader();
      }
    },
    getProposalById: async () => {
      showLoader2();
      const res = await getProposalById(proposalId);
      if (res) {
        setSingleProposal(res.data.proposal);
        hideLoader2();
        fn.getGeneralSetting({ company: companyId });
      }
    },

    getGeneralSetting: async (params?: any) => {
      // showLoader2();
      const res = await getGeneralSetting(params);
      if (res) {
        setGeneralSetting(res.data.generalSettings[0]);
        // hideLoader2();
      }
    },
  };

  useEffect(() => {
    fn.getProposals({
      f: "proposalNumber,calculation",
      lead: id,
    });
    // setPageTitle("View Proposal");
  }, []);

  useEffect(() => {
    fn.getProposalById();
  }, [proposalId]);

  const generatePDF = () => {
    // const report = new JsPDF({
    //   orientation: "portrait",
    //   unit: "pt",
    //   format: "a4",
    // });

    // var width = report.internal.pageSize.getWidth();
    // var height = report.internal.pageSize.getHeight();

    // report.addFont('ArialMS', 'Arial', 'normal');

    // report.setFont('Arial');

    // report.setFont("PTSans");

    // report.html(viewDoc, {
    //   callback: function (doc) {
    //     doc.save();
    //   },
    //   width: 594,
    //   height: height,
    //   windowWidth: 630,
    // });

    const divToDisplay = document.getElementById("content");
    html2canvas(divToDisplay!).then(function (canvas) {
      const divImage = canvas.toDataURL("image/png");
      const pdf = new jsPDF("portrait", "em", "a4");
      const componentWidth = pdf.internal.pageSize.getWidth();
      const componentHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(divImage, "PNG", 0, 0, componentWidth, componentHeight);
      pdf.save(
        `proposal_${singleProposal?.proposalNumber}_${moment(
          singleProposal?.issueDate
        ).format("YYYY-MM-DD")}.pdf`
      );
    });
  };

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12 col-lg-3">
            <ProposalSidebar
              proposal={proposal}
              leadId={id}
              loading={loading}
              proposalId={proposalId}
            />
          </div>

          <div className="col-12 col-lg-9 pt-4 pt-lg-0">
            <div>
              <div className="viewlead_content_header d-flex justify-content-end view_doc_header">
                <div className="viewlead_content_header_section d-flex gap-3">
                  <div>
                    <button
                      className="btn font12x500 buttonOne shadow-none"
                      onClick={() =>
                        navigate(`/lead/view/${id}/proposal/form/${proposalId}`)
                      }
                    >
                      <MdOutlineEdit className="me-1" />
                      Edit
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn font12x500 buttonOne shadow-none"
                      onClick={() => generatePDF()}
                    >
                      <BsFileEarmarkPdf className="me-1" />
                      PDF
                    </button>
                  </div>
                  <div>
                    <div className="btn font12x500 buttonOne shadow-none print">
                      <PrintComponents
                        trigger={
                          <button>
                            <FaPrint className="me-1" /> Print
                          </button>
                        }
                      >
                        <GenerateProposalDoc
                          boxShadow="unset"
                          width="100%"
                          apiData={singleProposal}
                          generalSetting={generalSetting}
                        />
                      </PrintComponents>
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn font12x500 buttonOne shadow-none"
                      // onClick={() => redirect(url)}
                    >
                      <MdOutlineEmail
                        className="me-1"
                        style={{
                          fontSize: 16,
                        }}
                      />
                      Email
                    </button>
                  </div>
                </div>
              </div>

              {loading2 ? (
                <Skeleton height="300px" borderRadius="8px" />
              ) : (
                <div className="menu_content_wrapper p-3">
                  <GenerateProposalDoc
                    boxShadow="rgb(99 99 99 / 20%) 0px 2px 8px 0px"
                    width="635px"
                    apiData={singleProposal}
                    generalSetting={generalSetting}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProposalPage;
