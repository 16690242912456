import moment from "moment";
import React from "react";
import { CgClose } from "react-icons/cg";
import { commonType } from "../../../pages/main/lead";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  params: any;
};

const ViewSalary: React.FC<propType> = (props) => {
  const { state, setState, params } = props;
  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
  };
  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <div>
              <h1 className="font16x600 mb-0">{`Salary of ${moment()
                .month(params?.month)
                .format("MMMM")} ${params?.year}`}</h1>
              <p className="mb-0 font12x400">{state?.data?.attendance?.name}</p>
              <p className="mb-0 font12x400">
                {state?.data?.attendance?.user?.employeeId}
              </p>
            </div>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <div className="form_fields_wrapper">
              {/* <div className="mb-3">
                <label className="form-label font12x400 textColor3 mb-1">
                  User Id
                </label>
                <p className="mb-0 font12x600 textColor3">
                  {state?.data?.attendance?.userId}
                </p>
              </div>
              <div className="mb-3">
                <label className="form-label font12x400 textColor3 mb-1">
                  Computed Salary
                </label>
                <p className="mb-0 font12x600 textColor3">
                  {`₹ ${Math.ceil(
                    state?.data?.attendance?.computedSalary
                  ).toLocaleString()}`}
                </p>
              </div>
              <div className="mb-3">
                <label className="form-label font12x400 textColor3 mb-1">
                  Incentives
                </label>
                <p className="mb-0 font12x600 textColor3">
                  {`₹ ${
                    state?.data?.attendance?.incentives.length === 0
                      ? 0
                      : state?.data?.attendance?.incentives
                          ?.map((item: any) => Number(item.value))
                          .reduce((total: number, num: number) => {
                            return total + num;
                          })
                          .toLocaleString()
                  }`}
                </p>
              </div>
              <div className="mb-3">
                <label className="form-label font12x400 textColor3 mb-1">
                  Total Salary
                </label>
                <p className="mb-0 font12x600 textColor3">
                  {state?.data?.attendance?.incentives?.length === 0
                    ? `₹ ${Math.ceil(
                        (state?.data?.attendance?.computedSalary ?? 0) + 0
                      ).toLocaleString()}`
                    : `₹ ${Math.ceil(
                        state?.data?.attendance?.incentives
                          ?.map((item: any) => Number(item.value))
                          .reduce((total: number, num: number) => {
                            return total + num;
                          }) +
                          (state?.data?.attendance?.computedSalary
                            ? state?.data?.attendance?.computedSalary
                            : 0)
                      ).toLocaleString()}`}
                </p>
              </div>
              <div className="mb-3">
                <label className="form-label font12x400 textColor3 mb-1">
                  Paid Salary
                </label>
                <p className="mb-0 font12x600 textColor3">
                  {`₹ ${Math.ceil(
                    state?.data?.attendance?.paidSalary ?? 0
                  ).toLocaleString()}`}
                </p>
              </div>
              <div className="mb-3">
                <label className="form-label font12x400 textColor3 mb-1">
                  Net Pay
                </label>
                <p className="mb-0 font12x600 textColor3">
                  {`₹ ${Math.ceil(
                    state?.data?.attendance?.netPay ?? 0
                  ).toLocaleString()}`}
                </p>
              </div> */}

              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="font12x500 textColor3">
                        <th scope="col">User Id</th>
                        <th scope="col">Computed Salary</th>
                        <th scope="col">Incentives</th>
                        <th scope="col">Total Salary</th>
                        <th scope="col">Total Deductions</th>
                        <th scope="col">Paid Salary</th>
                        <th scope="col">Net Pay</th>
                      </tr>
                    </thead>
                    <tbody>
                      {" "}
                      <tr className="font12x400">
                        <td>{state?.data?.attendance?.userId}</td>
                        <td>{`₹ ${Math.ceil(
                          state?.data?.attendance?.computedSalary
                        ).toLocaleString()}`}</td>
                        <td>{`₹ ${
                          state?.data?.attendance?.incentives.length === 0
                            ? 0
                            : state?.data?.attendance?.incentives
                                ?.map((item: any) => Number(item.value))
                                .reduce((total: number, num: number) => {
                                  return total + num;
                                })
                                .toLocaleString()
                        }`}</td>
                        <td>
                          {state?.data?.attendance?.incentives?.length === 0
                            ? `₹ ${Math.ceil(
                                (state?.data?.attendance?.computedSalary ?? 0) +
                                  0
                              ).toLocaleString()}`
                            : `₹ ${Math.ceil(
                                state?.data?.attendance?.incentives
                                  ?.map((item: any) => Number(item.value))
                                  .reduce((total: number, num: number) => {
                                    return total + num;
                                  }) +
                                  (state?.data?.attendance?.computedSalary
                                    ? state?.data?.attendance?.computedSalary
                                    : 0)
                              ).toLocaleString()}`}
                        </td>
                        <td>
                          {`₹ ${Math.ceil(
                            state?.data?.attendance?.totalDeductions ?? 0
                          ).toLocaleString()}`}
                        </td>
                        <td>{`₹ ${Math.ceil(
                          state?.data?.attendance?.paidSalary ?? 0
                        ).toLocaleString()}`}</td>
                        <td>
                          {`₹ ${Math.ceil(
                            state?.data?.attendance?.netPay ?? 0
                          ).toLocaleString()}`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewSalary;
