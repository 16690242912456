import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataNotFound from "../../../components/Data Not Found";
import Pagination from "../../../components/Pagination";
import CalendarFilter2 from "../../../Filters/CalendarFilter2";
import useLoading from "../../../hooks/useLoading";
import { getClient } from "../../../lib/api functions/client";
import SkeletonLoader from "../../../shared components/Loader/SkeletonLoader";
import Search from "../../../shared components/Search";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import { commonType } from "../lead";
import { ParamType } from "./type";
import ShowSelectedDate from "../../../shared components/DateInput/ShowSelectedDate";
import DeleteModal from "../../../components/Trashed Modal/DeleteModal";
import { DELETE_CLIENT_HARD } from "../../../utils/constants";

const Client = () => {
  const { userId, companyId, roles, setting } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const [clients, setClients] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    type: JSON.stringify([1, 3]),
    company: companyId,
  });

  const fn = {
    deleteData: () => {
      setCommonData({
        data: {
          clientIds: selectedRecords,
        },
        modal: true,
        check: "delete",
      });
    },
    getClients: async (params: ParamType) => {
      showLoader();
      const res = await getClient(params);
      if (res) {
        setClients(res.data.clients);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
    gettingSelectedData: (
      isChecked: boolean,
      check: string,
      value?: string
    ) => {
      if (check === "all") {
        if (isChecked) {
          setSelectedRecords([]);
          clients?.map((item: any) => {
            setSelectedRecords((prev: any) => [...prev, item?._id]);
          });
        } else {
          setSelectedRecords([]);
        }
      } else {
        if (isChecked) {
          setSelectedRecords([...selectedRecords, value ?? ""]);
        } else {
          let filterSelectedValue = selectedRecords.filter(
            (item: any) => item !== value
          );
          setSelectedRecords(filterSelectedValue);
        }
      }
    },
  };

  useEffect(() => {
    if (roles?.length !== 0) {
      if (!roles[0]?.clients.complete) {
        params.user = userId;
        fn.getClients(params);
      } else {
        fn.getClients(params);
      }
    }
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="data_render_wrapper">
          {/* filters */}
          <div className="filter_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-12 col-lg-4">
                  <Search
                    placeholder="Search by client name"
                    state={params}
                    setState={setParams}
                    apiCall={fn.getClients}
                    paramValue="name"
                  />
                </div>
                <div className="col-12 col-lg-8 pt-3 pt-lg-0">
                  <div className="d-flex align-items-center gap-3 justify-content-start justify-content-lg-end">
                    {params?.start_date && params?.end_date && (
                      <>
                        <ShowSelectedDate
                          label="From:"
                          value={moment(params?.start_date).format(
                            setting?.dateFormat
                          )}
                        />
                        <ShowSelectedDate
                          label="To:"
                          value={moment(params?.end_date).format(
                            setting?.dateFormat
                          )}
                        />
                      </>
                    )}

                    <div className="d-flex gap-2">
                      <CalendarFilter2
                        state={params}
                        apiCall={fn.getClients}
                        setState={setParams}
                        isFutureDateRequired={false}
                      />

                      <button
                        className="btn font12x500 buttonOne shadow-none"
                        onClick={() => navigate("/client/form")}
                      >
                        <img
                          src="/Assets/Svg/plusIcon.svg"
                          alt="icon"
                          className="me-2"
                        />
                        Add New Client
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="list_view_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center pb-3">
                <div className="col-12 col-lg-3">
                  <span className="font16x600 textColor3">List of Clients</span>
                </div>
                <div className="col-12 col-lg-9 pt-3 pt-lg-0">
                  <div className="d-flex justify-content-start justify-content-lg-end">
                    {/* delete button */}
                    {selectedRecords?.length !== 0 && (
                      <div>
                        <button
                          className="btn font12x500 shadow-none modalBtnColor"
                          onClick={fn.deleteData}
                          style={{
                            backgroundColor: "var(--error)",
                          }}
                        >
                          {`Delete (${selectedRecords?.length})`}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* table */}
            {clients?.length !== 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="font12x500 textColor3">
                        <th scope="col">
                          <div className="customCheck form-check d-flex align-items-center p-0">
                            <input
                              type="checkbox"
                              className="me-2"
                              id="checkbox"
                              name="rememberMe"
                              checked={
                                selectedRecords.length === clients.length
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                fn.gettingSelectedData(e.target.checked, "all");
                              }}
                            />
                          </div>
                        </th>
                        <th scope="col">#</th>
                        <th scope="col">Entry Date</th>
                        <th scope="col">Client name</th>
                        <th scope="col">Contact</th>
                        <th scope="col">Last Updated</th>
                        <th scope="col">Balance</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clients?.map((client: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <th>
                              <div className="customCheck form-check d-flex align-items-center p-0">
                                <input
                                  type="checkbox"
                                  className="me-2"
                                  id="checkbox"
                                  name="rememberMe"
                                  value={client?._id}
                                  checked={
                                    selectedRecords.includes(client?._id)
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    fn.gettingSelectedData(
                                      e.target.checked,
                                      "single",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </th>
                            <th>
                              {(params.page - 1) * params.per_page + (i + 1)}
                            </th>
                            <td>
                              {moment(client?.createdAt).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td>
                              <span className="font12x500 mb-1">
                                {client?.name}
                              </span>
                              <br />
                              <span>{client?.completeAddress?.city}</span>
                            </td>
                            <td>
                              {client?.email?.length > 0 ||
                              client?.phoneCode?.length > 0 ||
                              client?.contactNumber?.length > 0 ? (
                                <>
                                  <span className="mb-1">
                                    {client?.email ?? "--"}
                                  </span>
                                  <br />
                                  <span>{`${client?.phoneCode} ${client?.contactNumber}`}</span>
                                </>
                              ) : (
                                "--"
                              )}
                            </td>
                            <td>
                              {moment(client?.updatedAt).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td>
                              {client?.clientBalance
                                ? client?.clientBalance?.toLocaleString()
                                : 0}
                            </td>
                            <td>
                              <button
                                className="btn font12x500 buttonTwo shadow-none"
                                onClick={() =>
                                  navigate(`/client/view/${client?._id}`)
                                }
                              >
                                View Details
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : loading ? (
              <SkeletonLoader />
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>

        {/* pagination */}
        {clients?.length !== 0 && (
          <div className="pagination_wrapper pt-3 px-3">
            <Pagination
              currentPage={params.page}
              onPageChange={(e) => {
                fn.getClients({ ...params, page: e });
                setParams({
                  ...params,
                  page: e,
                });
              }}
              pageSize={params.per_page}
              totalCount={Math.ceil(totalResult / params.per_page)}
              onChangePerPage={(e) => {
                fn.getClients({ ...params, per_page: e });
                setParams({
                  ...params,
                  per_page: e,
                });
              }}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {commonData?.modal && commonData?.check === "delete" && (
        <DeleteModal
          label="Client"
          dataIds={commonData?.data}
          setState={setCommonData}
          url={DELETE_CLIENT_HARD}
          variableName="clientIds"
          apiCall={fn.getClients}
          params={params}
          setSelectedRecords={setSelectedRecords}
        />
      )}
    </>
  );
};

export default Client;
