import Separator from "../../MobileSidebar/Separator";
import Menu from "./Menu";

const MenuBar = () => {
  return (
    <>
      <div>
        <div
          className="details_menu_wrapper"
          style={{
            minHeight: "calc(100vh - 102px)",
            height: "calc(100vh - 102px)",
          }}
        >
          <div className="menu_heading_wrapper">
            <p className="mb-0 font14x600">General</p>
          </div>
          <Menu path={`/setting/general`} label="General" />
          <Separator />
          <div className="menu_heading_wrapper">
            <p className="mb-0 font14x600">Company</p>
          </div>
          <Menu path={`/setting/profile`} label="Profile" />
          <Menu path={`/setting/location`} label="Location" />
          <Menu path={`/setting/department`} label="Department" />
          {/* <Menu path={`/setting/account`} label="Account" /> */}
          <Menu path={`/setting/user-role`} label="Users & Roles" />
          <Menu path={`/setting/gst`} label="GST" />
          {/* <Menu path={`/setting/taxes`} label="Taxes" /> */}
          <Menu path={`/setting/employee`} label="Employee" />
          <Menu path={`/setting/salary-slip`} label="Salary Slip" />
          <Separator />
          <div className="menu_heading_wrapper">
            <p className="mb-0 font14x600">Documents</p>
          </div>
          <Menu path={`/setting/proposal`} label="Proposal" />
          <Menu path={`/setting/invoice`} label="Invoice" />
          <Menu path={`/setting/expense`} label="Expense" />
          <Menu path={`/setting/payment`} label="Payment" />
          <Menu path={`/setting/credit-note`} label="Credit Note" />
        </div>
      </div>
    </>
  );
};

export default MenuBar;
