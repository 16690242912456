import React, { useContext } from "react";
import Header from "../../components/Header";
import MobileSidebar from "../../components/MobileSidebar";
import Sidebar from "../../components/Sidebar";
import { MainContext } from "../../utils/private_routes/PrivateRoutes";

type propType = {
  children: any;
};

const Layout: React.FC<propType> = ({ children }) => {
  const { isSidebarOpen } = useContext(MainContext);

  return (
    <>
      <div className="main">
        <Sidebar />

        {isSidebarOpen && <MobileSidebar />}

        <div
          className="content"
          style={{
            marginLeft: !isSidebarOpen ? "4rem" : "14rem",
          }}
        >
          <Header />
          {/* dynamic content */}
          {children}
        </div>
      </div>

      {/* desktop alert */}
      <div className="desktopAlert">
        <img
          src="/Assets/Svg/desktopAlert.svg"
          className="img-fluid"
          alt="alert"
          draggable={false}
        />
        <p className="mb-0 my-3 text-center">
          Please use <b>web view</b> (Desktop/Laptop) to access the portal.
        </p>
      </div>
    </>
  );
};

export default Layout;
