import { Tooltip } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { IoMdEye } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import DataNotFound from "../../../components/Data Not Found";
import Pagination from "../../../components/Pagination";
import Form from "../../../components/Task Components/Form";
import UpdateData from "../../../components/Task Components/UpdateData";
import ViewTask from "../../../components/Task Components/ViewTask";
import DeleteModal from "../../../components/Trashed Modal/DeleteModal";
import CalendarFilter from "../../../Filters/CalendarFilter";
import FunnelFilterTask from "../../../Filters/FunnelFilterTask";
import { getDefaultInitial } from "../../../function/other";
import useLoading from "../../../hooks/useLoading";
import { getDepartment } from "../../../lib/api functions/setting/department";
import { getTask } from "../../../lib/api functions/task";
import { getUser } from "../../../lib/api functions/user";
import ShowSelectedDate from "../../../shared components/DateInput/ShowSelectedDate";
import SkeletonLoader from "../../../shared components/Loader/SkeletonLoader";
import Search from "../../../shared components/Search";
import {
  DELETE_TASK,
  taskPriority,
  taskPriorityMap,
  taskStatus,
  taskStatusMap,
} from "../../../utils/constants";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import { commonType } from "../lead";
import { ParamType } from "./type";

export const getColor = (type: number): any => {
  switch (type) {
    case 0:
      return "#000000";
    case 1:
      return "#6BDA9F";
    case 2:
      return "#14639D";
    case 3:
      return "#198EE4";
    default:
      return null;
  }
};

export const getPriorityColor = (priority: number): any => {
  switch (priority) {
    case 0:
      return "#77777A";
    case 1:
      return "#F5BE5F";
    case 2:
      return "#FF808B";
    default:
      return null;
  }
};

const Task = () => {
  const { userId, companyId, roles, setting, user } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const [loading, showLoader, hideLoader] = useLoading();
  const [tasks, setTasks] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    company: companyId,
    isDeleted: false,
    status: window.localStorage.getItem("taskStatus") ?? undefined,
    priority: window.localStorage.getItem("taskPriority") ?? undefined,
    department: window.localStorage.getItem("taskDepartment") ?? undefined,
  });
  const navigate = useNavigate();

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getTasks: async (params?: ParamType) => {
      showLoader();
      const res = await getTask(params);
      if (res) {
        setTasks(res.data.tasks);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
    getUsers: async (params?: any) => {
      const res = await getUser(params);
      if (res) {
        setUsers(res.data.users);
      }
    },
    addParam: (value: string, check: string) => {
      if (check === "status") {
        if (value === "resetFilter") {
          window.localStorage.removeItem("taskStatus");
          delete params?.status;
          fn.getTasks?.({ ...params, page: 1 });
        } else {
          window.localStorage.setItem("taskStatus", value);
          setParams({
            ...params,
            status: value,
          });
          fn.getTasks?.({ ...params, page: 1, status: value });
        }
      } else if (check === "priority") {
        if (value === "resetFilter") {
          window.localStorage.removeItem("taskPriority");
          delete params?.priority;
          fn.getTasks?.({ ...params, page: 1 });
        } else {
          window.localStorage.setItem("taskPriority", value);
          setParams({
            ...params,
            priority: value,
          });
          fn.getTasks?.({ ...params, page: 1, priority: value });
        }
      } else if (check === "department") {
        if (value === "resetFilter") {
          window.localStorage.removeItem("taskDepartment");
          delete params?.department;
          fn.getTasks?.({ ...params, page: 1 });
        } else {
          window.localStorage.setItem("taskDepartment", value);
          setParams({
            ...params,
            department: value,
          });
          fn.getTasks?.({ ...params, page: 1, department: value });
        }
      }
    },
    gettingSelectedData: (
      isChecked: boolean,
      check: string,
      value?: string
    ) => {
      if (check === "all") {
        if (isChecked) {
          setSelectedRecords([]);
          tasks?.map((item: any) => {
            if (userId === item?.user?._id || roles[0]?.tasks?.complete) {
              setSelectedRecords((prev: any) => [...prev, item?._id]);
            }
          });
        } else {
          setSelectedRecords([]);
        }
      } else {
        if (isChecked) {
          setSelectedRecords([...selectedRecords, value ?? ""]);
        } else {
          let filterSelectedValue = selectedRecords.filter(
            (item: any) => item !== value
          );
          setSelectedRecords(filterSelectedValue);
        }
      }
    },
    getDepartments: async () => {
      const params = { f: "name", company: companyId };
      const res = await getDepartment(params);
      if (res) {
        setDepartments(res.data.departments);
      }
    },
    resetFilter: () => {
      if (roles[0]?.tasks.complete) {
        window.localStorage.removeItem("taskStatus");
        window.localStorage.removeItem("taskPriority");
        window.localStorage.removeItem("taskDepartment");
        delete params?.status;
        delete params?.priority;
        delete params?.department;
        fn.getTasks?.({ ...params, page: 1 });
      } else {
        delete params?.status;
        delete params?.priority;
        window.localStorage.removeItem("taskStatus");
        window.localStorage.removeItem("taskPriority");
        fn.getTasks?.({ ...params, page: 1 });
      }
    },
  };

  useEffect(() => {
    if (roles?.length !== 0) {
      if (!roles[0]?.tasks.complete) {
        params.department = user?.department;
        fn.getTasks(params);
        fn.getDepartments();
      } else {
        fn.getTasks(params);
        fn.getDepartments();
      }
    }
  }, []);

  useEffect(() => {
    fn.getUsers({ f: "name", company: companyId });
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="data_render_wrapper">
          {/* filters */}
          <div className="filter_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-12 col-lg-4">
                  <Search
                    placeholder="Search by task name"
                    state={params}
                    setState={setParams}
                    apiCall={fn.getTasks}
                    paramValue="name"
                  />
                </div>
                <div className="col-12 col-lg-8 pt-3 pt-lg-0">
                  <div className="d-flex align-items-center gap-3 justify-content-start justify-content-lg-end">
                    {params?.dF && (
                      <>
                        <ShowSelectedDate
                          label="From:"
                          value={moment(params?.dF?.split("_")[1]).format(
                            setting?.dateFormat
                          )}
                        />
                        <ShowSelectedDate
                          label="To:"
                          value={moment(params?.dF?.split("_")[2]).format(
                            setting?.dateFormat
                          )}
                        />
                      </>
                    )}

                    <div className="d-flex gap-2">
                      <CalendarFilter
                        state={params}
                        apiCall={fn.getTasks}
                        setState={setParams}
                        fieldName="createdAt"
                        label="Created On"
                        isFutureDateRequired={true}
                      />

                      <FunnelFilterTask
                        state={params}
                        apiCall={fn.getTasks}
                        setState={setParams}
                        userId={userId}
                      />

                      <button
                        className="btn font12x500 buttonOne shadow-none"
                        onClick={() => fn.openModal(undefined, "add_task")}
                      >
                        <img
                          src="/Assets/Svg/plusIcon.svg"
                          alt="icon"
                          className="me-2"
                        />
                        Add New Task
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="list_view_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center pb-3">
                <div className="col-12 col-lg-3">
                  <span className="font16x600 textColor3">List of Tasks</span>
                </div>
                <div className="col-12 col-lg-9 pt-3 pt-lg-0">
                  <div className="d-flex justify-content-start justify-content-lg-end">
                    {selectedRecords?.length !== 0 ? (
                      <div>
                        <button
                          className="btn font12x500 shadow-none modalBtnColor"
                          onClick={() =>
                            fn.openModal({ taskIds: selectedRecords }, "delete")
                          }
                          style={{
                            backgroundColor: "var(--error)",
                          }}
                        >
                          {`Delete (${selectedRecords?.length})`}
                        </button>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center gap-2">
                        <span className="font12x500 textColor3">Filter:</span>
                        <div className="d-flex gap-2">
                          <div>
                            <select
                              value={params?.status ?? ""}
                              className="form-select font12x500 textColor3 shadow-none dropdown_filter"
                              onChange={(e) => {
                                fn.addParam(e.target.value, "status");
                              }}
                            >
                              <option value="resetFilter">Select Status</option>
                              {taskStatus?.map((item: any, i: number) => {
                                return (
                                  <option key={i} value={item?.value}>
                                    {item?.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div>
                            <select
                              value={params?.priority ?? ""}
                              className="form-select font12x500 textColor3 shadow-none dropdown_filter"
                              onChange={(e) => {
                                fn.addParam(e.target.value, "priority");
                              }}
                            >
                              <option value="resetFilter">
                                Select Priority
                              </option>
                              {taskPriority?.map((item: any, i: number) => {
                                return (
                                  <option key={i} value={item?.value}>
                                    {item?.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {roles[0]?.tasks.complete ? (
                            <div>
                              <select
                                value={params?.department ?? ""}
                                className="form-select font12x500 textColor3 shadow-none dropdown_filter"
                                onChange={(e) => {
                                  fn.addParam(e.target.value, "department");
                                }}
                              >
                                <option value="resetFilter">
                                  Select Department
                                </option>
                                {departments?.length &&
                                  departments?.map((item: any, i: number) => {
                                    return (
                                      <option key={i} value={item?.name}>
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          ) : (
                            <div>
                              <select
                                className="form-select font12x500 textColor3 shadow-none dropdown_filter"
                                disabled={true}
                              >
                                <option value={params?.department}>
                                  {params?.department}
                                </option>
                              </select>
                            </div>
                          )}
                        </div>

                        {roles[0]?.tasks.complete ? (
                          <button
                            className="btn font12x500 buttonTwo shadow-none"
                            disabled={
                              params.status ||
                              params.department ||
                              params.priority
                                ? false
                                : true
                            }
                            onClick={() => fn.resetFilter()}
                          >
                            Reset All
                          </button>
                        ) : (
                          <button
                            className="btn font12x500 buttonTwo shadow-none"
                            disabled={
                              params.status || params.priority ? false : true
                            }
                            onClick={() => fn.resetFilter()}
                          >
                            Reset All
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* table */}
            {tasks?.length !== 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="font12x500 textColor3">
                        <th scope="col">
                          <div className="customCheck form-check d-flex align-items-center p-0">
                            <input
                              type="checkbox"
                              className="me-2"
                              id="checkbox"
                              name="rememberMe"
                              checked={
                                selectedRecords.length === tasks.length
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                fn.gettingSelectedData(e.target.checked, "all");
                              }}
                            />
                          </div>
                        </th>
                        <th scope="col">#</th>
                        <th scope="col">Created By</th>
                        <th scope="col">Created On</th>
                        <th scope="col">Task Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Due Date</th>
                        <th scope="col">Assigned to</th>
                        <th scope="col">Department</th>
                        <th scope="col">Tags</th>
                        <th scope="col">Priority</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tasks?.map((task: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <th>
                              {userId === task?.user?._id ||
                              roles[0]?.tasks?.complete ? (
                                <div className="customCheck form-check d-flex align-items-center p-0">
                                  <input
                                    type="checkbox"
                                    className="me-2"
                                    id="checkbox"
                                    name="rememberMe"
                                    value={task?._id}
                                    checked={
                                      selectedRecords.includes(task?._id)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      fn.gettingSelectedData(
                                        e.target.checked,
                                        "single",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="customCheck form-check d-flex align-items-center p-0">
                                  <input
                                    type="checkbox"
                                    className="me-2"
                                    id="checkbox"
                                    name="rememberMe"
                                    value={task?._id}
                                    checked={false}
                                    disabled={true}
                                  />
                                </div>
                              )}
                            </th>
                            <th>
                              {(params.page - 1) * params.per_page + (i + 1)}
                            </th>
                            <td>
                              <Tooltip
                                key={i}
                                color="#77777A"
                                title={task?.user?.name}
                              >
                                <span className="assignTo_section font10x500">
                                  {getDefaultInitial(task?.user?.name)}
                                </span>
                              </Tooltip>
                            </td>
                            <td>
                              {moment(task?.createdAt).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td>{task?.name}</td>
                            <td>
                              <UpdateData
                                data={taskStatus}
                                color={getColor(task?.status)}
                                label={taskStatusMap.get(task?.status)}
                                id={task?._id}
                                check="Status"
                                apiCall={fn.getTasks}
                                params={params}
                                status={task?.status}
                              />
                            </td>
                            <td>
                              {moment(task?.dueDate).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td>
                              <div className="d-flex gap-1">
                                {task?.assignTo?.map((item: any, i: number) => {
                                  if (item?.image === null) {
                                    return (
                                      <Tooltip
                                        key={i}
                                        color="#77777A"
                                        title={item?.name}
                                      >
                                        <span className="">
                                          <img
                                            src="/Assets/userDemo.png"
                                            alt="bell"
                                            width={23}
                                            height={23}
                                          />
                                        </span>
                                      </Tooltip>
                                    );
                                  } else {
                                    return (
                                      <Tooltip
                                        key={i}
                                        color="#77777A"
                                        title={item?.name}
                                      >
                                        <span className="assignTo_section font10x500">
                                          {getDefaultInitial(item?.name)}
                                        </span>
                                      </Tooltip>
                                    );
                                  }
                                })}
                              </div>
                            </td>
                            <td>{task?.department ?? "--"}</td>
                            <td>
                              {task?.tags?.length ? (
                                task?.tags?.map((item: string[], i: number) => {
                                  return (
                                    <span key={i} className="tags">
                                      {item}
                                    </span>
                                  );
                                })
                              ) : (
                                <span>--</span>
                              )}
                            </td>
                            <td>
                              <UpdateData
                                data={taskPriority}
                                color={getPriorityColor(task?.priority)}
                                label={taskPriorityMap.get(task?.priority)}
                                id={task?._id}
                                check="Priority"
                                apiCall={fn.getTasks}
                                params={params}
                                status={task?.priority}
                              />
                            </td>
                            <td>
                              <div className="d-flex gap-2">
                                <div
                                  className="d-flex align-items-center cursor_pointer"
                                  onClick={() =>
                                    fn.openModal(task, "view_task")
                                  }
                                  // onClick={() =>
                                  //   navigate(`/task/view/${task?._id}`)
                                  // }
                                >
                                  <IoMdEye
                                    style={{
                                      fontSize: 15,
                                      color: "var(--text2)",
                                    }}
                                  />
                                  <p className="mb-0 font12x400 textColor2 ms-1">
                                    View
                                  </p>
                                </div>

                                {userId === task?.user?._id ||
                                roles[0]?.tasks?.complete ? (
                                  <div
                                    className="d-flex align-items-center cursor_pointer"
                                    onClick={() =>
                                      fn.openModal(task, "edit_task")
                                    }
                                  >
                                    <img
                                      src="/Assets/Svg/editIcon.svg"
                                      alt="edit"
                                    />
                                    <p className="mb-0 font12x400 textColor2 ms-1">
                                      Edit
                                    </p>
                                  </div>
                                ) : (
                                  "--"
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : loading ? (
              <SkeletonLoader />
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>
        {/* pagination */}
        {tasks?.length !== 0 && (
          <div className="pagination_wrapper pt-3 px-3">
            <Pagination
              currentPage={params.page}
              onPageChange={(e) => {
                fn.getTasks({ ...params, page: e });
                setParams({
                  ...params,
                  page: e,
                });
              }}
              pageSize={params.per_page}
              totalCount={Math.ceil(totalResult / params.per_page)}
              onChangePerPage={(e) => {
                fn.getTasks({ ...params, per_page: e });
                setParams({
                  ...params,
                  per_page: e,
                });
              }}
            />
          </div>
        )}
      </div>

      {/* form modal */}
      {commonData?.modal &&
        (commonData?.check === "add_task" ||
          commonData?.check === "edit_task") && (
          <Form
            state={commonData}
            setState={setCommonData}
            apiCall={fn.getTasks}
            params={params}
          />
        )}

      {/* view modal */}
      {commonData?.modal && commonData?.check === "view_task" && (
        <ViewTask
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getTasks}
          params={params}
        />
      )}

      {/* delete modal */}
      {commonData?.modal && commonData?.check === "delete" && (
        <DeleteModal
          label="Task"
          dataIds={commonData?.data}
          setState={setCommonData}
          url={DELETE_TASK}
          variableName="taskIds"
          apiCall={fn.getTasks}
          params={params}
          setSelectedRecords={setSelectedRecords}
        />
      )}
    </>
  );
};

export default Task;
