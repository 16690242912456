import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { FaPrint } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import PaymentSidebar from "../../../../components/Payment Component/View/PaymentSidebar";
import useLoading from "../../../../hooks/useLoading";
import {
  getPayment,
  getPaymentById,
} from "../../../../lib/api functions/payment";
import Skeleton from "react-loading-skeleton";
import { commonType } from "../../lead";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import GeneratePaymentDoc from "../../../../components/GenerateDoc/GeneratePaymentDoc";
import ReceivedForm from "../../../../components/Payment Component/ReceivedForm";
import MadeForm from "../../../../components/Payment Component/MadeForm";
import EditReceived from "../../../../components/Payment Component/EditReceived";
import EditMade from "../../../../components/Payment Component/EditMade";
import { getGeneralSetting } from "../../../../lib/api functions/setting/general";
import PrintComponents from "react-print-components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";

const ViewPaymentPage = () => {
  const { setPageTitle, companyId } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const { paymentId } = useParams();
  const [loading, showLoader, hideLoader] = useLoading();
  const [loading2, showLoader2, hideLoader2] = useLoading();
  const [payment, setPayment] = useState<any>([]);
  const [singlePayment, setSinglePayment] = useState<any>([]);
  const [generalSetting, setGeneralSetting] = useState<any>("");

  const fn = {
    getPayments: async (params?: any) => {
      showLoader();
      const res = await getPayment(params);
      if (res) {
        setPayment(res.data.payments);
        hideLoader();
      }
    },
    getPaymentById: async () => {
      showLoader2();
      const res = await getPaymentById(paymentId);
      if (res) {
        setSinglePayment(res.data.payment);
        fn.getGeneralSetting({ company: companyId });
        hideLoader2();
      }
    },
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getGeneralSetting: async (params?: any) => {
      // showLoader2();
      const res = await getGeneralSetting(params);
      if (res) {
        setGeneralSetting(res.data.generalSettings[0]);
        // hideLoader2();
      }
    },
  };

  useEffect(() => {
    fn.getPayments({
      company: companyId,
      f: "number,amount,type",
    });
    setPageTitle("View Payment");
  }, []);

  useEffect(() => {
    fn.getPaymentById();
  }, [paymentId]);

  const generatePDF = () => {
    // const report = new JsPDF({
    //   orientation: "portrait",
    //   unit: "pt",
    //   format: "a4",
    // });

    // var width = report.internal.pageSize.getWidth();
    // var height = report.internal.pageSize.getHeight();

    // report.addFont('ArialMS', 'Arial', 'normal');

    // report.setFont('Arial');

    // report.setFont("PTSans");

    // report.html(viewDoc, {
    //   callback: function (doc) {
    //     doc.save();
    //   },
    //   width: 594,
    //   height: height,
    //   windowWidth: 630,
    // });

    const divToDisplay = document.getElementById("content");
    html2canvas(divToDisplay!).then(function (canvas) {
      const divImage = canvas.toDataURL("image/png");
      const pdf = new jsPDF("landscape", "mm", "a4");
      const componentWidth = pdf.internal.pageSize.getWidth();
      const componentHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(divImage, "PNG", 0, 0, componentWidth, componentHeight);
      pdf.save(
        `payment_${singlePayment?.number}_${moment(singlePayment?.date).format(
          "YYYY-MM-DD"
        )}.pdf`
      );
    });
  };

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12 col-lg-3">
            <PaymentSidebar
              payment={payment}
              loading={loading}
              paymentId={paymentId}
              openReceivedPaymentForm={() =>
                fn.openModal(undefined, "add_receive_payment")
              }
              openMadePaymentForm={() =>
                fn.openModal(undefined, "add_made_payment")
              }
            />
          </div>

          <div className="col-12 col-lg-9 pt-4 pt-lg-0">
            <div>
              <div className="viewlead_content_header d-flex justify-content-end view_doc_header">
                <div className="viewlead_content_header_section d-flex gap-3">
                  <div>
                    {!(
                      singlePayment?.invoice?.amountDue === 0 ||
                      singlePayment?.expense?.calculation?.total -
                        singlePayment?.expense?.paidAmount ===
                        0
                    ) && (
                      <button
                        className="btn font12x500 buttonOne shadow-none"
                        onClick={() =>
                          fn.openModal(
                            {
                              singlePayment,
                              accountId:
                                singlePayment?.type === 1
                                  ? singlePayment?.invoice?.client?._id
                                  : singlePayment?.expense?.vendor?._id,
                              accountName:
                                singlePayment?.type === 1
                                  ? singlePayment?.invoice?.client?.name
                                  : singlePayment?.expense?.vendor?.name,
                              docNumber: singlePayment?.documentNumber,
                              _id:
                                singlePayment?.type === 1
                                  ? singlePayment?.invoice?._id
                                  : singlePayment?.expense?._id,
                            },
                            singlePayment?.type === 1
                              ? "receive_payment"
                              : "made_payment"
                          )
                        }
                      >
                        <MdOutlineEdit className="me-1" />
                        Edit
                      </button>
                    )}
                  </div>
                  <div>
                    <button
                      className="btn font12x500 buttonOne shadow-none"
                      onClick={() => generatePDF()}
                    >
                      <BsFileEarmarkPdf className="me-1" />
                      PDF
                    </button>
                  </div>
                  <div>
                    <div className="btn font12x500 buttonOne shadow-none print">
                      <PrintComponents
                        trigger={
                          <button>
                            <FaPrint className="me-1" /> Print
                          </button>
                        }
                      >
                        <GeneratePaymentDoc
                          width="100%"
                          apiData={singlePayment}
                          generalSetting={generalSetting}
                          boxShadow="unset"
                        />
                      </PrintComponents>
                    </div>
                  </div>
                  <div>
                    <a
                      href={`mailto:${
                        singlePayment?.type === 1
                          ? singlePayment?.invoice?.client?.email
                          : singlePayment?.expense?.vendor?.email
                      }`}
                      className="text-decoration-none"
                    >
                      <button
                        className="btn font12x500 buttonOne shadow-none"
                        // onClick={() => redirect(url)}
                      >
                        <MdOutlineEmail
                          className="me-1"
                          style={{
                            fontSize: 16,
                          }}
                        />
                        Email
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              {loading2 ? (
                <Skeleton height="300px" borderRadius="8px" />
              ) : (
                <div className="menu_content_wrapper p-3">
                  {/* <img src="/Assets/demoDoc.png" alt="icon" /> */}
                  <GeneratePaymentDoc
                    boxShadow="rgb(99 99 99 / 20%) 0px 2px 8px 0px"
                    width="635px"
                    apiData={singlePayment}
                    generalSetting={generalSetting}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* form modal */}
      {commonData?.modal && commonData?.check === "receive_payment" && (
        <EditReceived
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getPayments}
          params={{ company: companyId, f: "number,amount,type" }}
          apiCall2={fn.getPaymentById}
        />
      )}

      {/* form modal */}
      {commonData?.modal && commonData?.check === "made_payment" && (
        <EditMade
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getPayments}
          params={{ company: companyId, f: "number,amount,type" }}
          apiCall2={fn.getPaymentById}
        />
      )}

      {/* form modal */}
      {commonData?.modal && commonData?.check === "add_receive_payment" && (
        <ReceivedForm
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getPayments}
          params={{ company: companyId, f: "number,amount,type" }}
        />
      )}

      {/* form modal */}
      {commonData?.modal && commonData?.check === "add_made_payment" && (
        <MadeForm
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getPayments}
          params={{ company: companyId, f: "number,amount,type" }}
        />
      )}
    </>
  );
};

export default ViewPaymentPage;
