import { useContext, useEffect, useState } from "react";
import { Calendar, momentLocalizer, Event } from "react-big-calendar";
import moment from "moment";
import { getMeeting } from "../../../lib/api functions/lead/meeting";
import { ParamType } from "./type";
import { commonType } from "../lead";
import MeetingForm from "../../../components/View Lead Components/Activities/MeetingForm";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import { getReminder } from "../../../lib/api functions/reminder";
import { getTask } from "../../../lib/api functions/task";
import { eventTypes } from "../../../utils/constants";
import Info from "../../../components/Calendar Components/Info";
import ReminderForm from "../../../components/Reminder Components/Form";
import TaskForm from "../../../components/Task Components/Form";

const ViewCalendar = () => {
  const { userId, companyId, roles } = useContext(MainContext);
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState<any>([]);
  const [meetings, setMeetings] = useState<any>([]);
  const [reminders, setReminders] = useState<any>([]);
  const [tasks, setTasks] = useState<any>([]);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const [params, setParams] = useState<ParamType>({
    company: companyId,
  });
  const [taskParams, settaskParams] = useState<any>({
    company: companyId,
    isDeleted: false,
  });
  const [reminderParams, setreminderParams] = useState<any>({
    company: companyId,
    user: userId,
    isDeleted: false,
  });

  const fn = {
    openForm: (data: any, check: string) => {
      setCommonData({
        data: data ? data : undefined,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getMeetingDetails: async (params?: ParamType) => {
      const res = await getMeeting(params);
      if (res) {
        setMeetings(res.data.meetings);
      }
    },
    generatingEvents: (meetings: any, reminders: any, tasks: any) => {
      setEvents([]);
      if (meetings && reminders && tasks) {
        meetings.map((item: any, i: number) => {
          return setEvents((prev: any) => [
            ...prev,
            {
              title: item?.title,
              allDay: false,
              start: new Date(
                moment(item?.date).year(),
                moment(item?.date).month(),
                moment(item?.date).date(),
                item?.startTime?.split(":")[0],
                item?.startTime?.split(":")[1],
                0
              ),
              end: new Date(
                moment(item?.date).year(),
                moment(item?.date).month(),
                moment(item?.date).date(),
                item?.endTime
                  ? item?.endTime?.split(":")[0]
                  : item?.startTime?.split(":")[0],
                item?.endTime
                  ? item?.endTime?.split(":")[1]
                  : item?.startTime?.split(":")[1],
                0
              ),
              resource: {
                _id: item?._id,
                title: item?.title,
                date: item?.date,
                startTime: moment(item?.startTime, "HH:mm").format("HH:mm"),
                endTime: moment(item?.endTime, "HH:mm").format("HH:mm"),
                description: item?.description,
                lead: item?.lead,
                user: item?.user,
                eventType: eventTypes[0].label,
              },
            },
          ]);
        });

        reminders.map((item: any, i: number) => {
          return setEvents((prev: any) => [
            ...prev,
            {
              title: item?.title,
              allDay: false,
              start: new Date(
                moment(item?.date).year(),
                moment(item?.date).month(),
                moment(item?.date).date(),
                item?.time?.split(":")[0],
                item?.time?.split(":")[1],
                0
              ),
              end: new Date(
                moment(item?.date).year(),
                moment(item?.date).month(),
                moment(item?.date).date(),
                item?.time?.split(":")[0],
                item?.time?.split(":")[1],
                0
              ),
              resource: {
                _id: item?._id,
                title: item?.title,
                date: item?.date,
                time: item?.time,
                taskDescription: item?.taskDescription,
                priority: item?.priority,
                updatedAt: item?.updatedAt,
                updatedBy: item?.updatedBy,
                eventType: eventTypes[1].label,
              },
            },
          ]);
        });

        tasks.map((item: any, i: number) => {
          return setEvents((prev: any) => [
            ...prev,
            {
              title: item?.name,
              allDay: item?.endTime ? false : true,
              start: new Date(
                moment(item?.dueDate).year(),
                moment(item?.dueDate).month(),
                moment(item?.dueDate).date(),
                0,
                0,
                0
              ),
              end: new Date(
                moment(item?.dueDate).year(),
                moment(item?.dueDate).month(),
                moment(item?.dueDate).date(),
                item?.endTime ? item?.endTime?.split(":")[0] : 0,
                item?.endTime ? item?.endTime?.split(":")[1] : 0,
                0
              ),
              resource: {
                name: item?.name,
                dueDate: item?.dueDate,
                endTime: moment(item?.endTime, "HH:mm").format("HH:mm"),
                assignTo: item?.assignTo,
                tags: item?.tags,
                department: item?.department,
                status: item?.status,
                taskDescription: item?.taskDescription,
                priority: item?.priority,
                updatedAt: item?.updatedAt,
                updatedBy: item?.updatedBy,
                eventType: eventTypes[2].label,
              },
            },
          ]);
        });
      }
    },
    getReminders: async (params?: any) => {
      const res = await getReminder(params);
      if (res) {
        setReminders(res.data.reminders);
      }
    },
    getTasks: async (params?: any) => {
      const res = await getTask(params);
      if (res) {
        setTasks(res.data.tasks);
      }
    },
    eventGetter: (
      event: Event,
      start: Date,
      end: Date,
      isSelected: boolean
    ) => {
      if (event?.resource?.eventType === eventTypes[0].label) {
        const style = {
          backgroundColor: eventTypes[0].color,
        };
        return {
          style: style,
        };
      } else if (event?.resource?.eventType === eventTypes[1].label) {
        const style = {
          backgroundColor: eventTypes[1].color,
        };
        return {
          style: style,
        };
      } else {
        const style = {
          backgroundColor: eventTypes[2].color,
        };
        return {
          style: style,
        };
      }
    },
  };

  useEffect(() => {
    if (roles?.length !== 0) {
      if (!roles[0]?.calendar.complete) {
        params.user = userId;
        taskParams.user = userId;
        fn.getMeetingDetails(params);
        fn.getTasks(taskParams);
      } else {
        fn.getMeetingDetails(params);
        fn.getTasks(taskParams);
      }
    }
  }, []);
  useEffect(() => {
    fn.getReminders(reminderParams);
  }, []);

  useEffect(() => {
    if (meetings && reminders && tasks) {
      fn.generatingEvents(meetings, reminders, tasks);
    }
  }, [meetings, reminders, tasks]);

  return (
    <>
      <div className="single_page p-3">
        <div
          style={{
            height: "450px",
          }}
        >
          <Calendar
            events={events}
            localizer={localizer}
            defaultView="month"
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={(e: Event) => {
              if (e.resource?.eventType === eventTypes[0].label) {
                fn.openForm(e.resource, "meeting_form");
              } else if (e?.resource?.eventType === eventTypes[1].label) {
                fn.openForm(e.resource, "reminder_detail");
              } else {
                fn.openForm(e.resource, "task_detail");
              }
            }}
            //@ts-ignore
            eventPropGetter={(...args) => fn.eventGetter(...args)}
          />
        </div>
      </div>

      {/* form modal */}
      {commonData?.modal && commonData?.check === "meeting_form" && (
        <MeetingForm
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getMeetingDetails}
          params={params}
        />
      )}

      {/* color scheme popup */}
      <Info />

      {/* reminder details modal */}
      {commonData?.modal && commonData?.check === "reminder_detail" && (
        <ReminderForm
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getReminders}
          params={reminderParams}
        />
      )}

      {/* task details modal */}
      {commonData?.modal && commonData?.check === "task_detail" && (
        <TaskForm
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getTasks}
          params={taskParams}
        />
      )}
    </>
  );
};

export default ViewCalendar;
