import React, { useContext } from "react";
import { MainContext } from "../../utils/private_routes/PrivateRoutes";
import Menu from "./Menu";
import Separator from "./Separator";
import { CgClose } from "react-icons/cg";

const MobileSidebar = () => {
  const { sidebarToggle, roles } = useContext(MainContext);

  return (
    <>
      <div className="mobile_sidebar_wrapper">
        <div className="mobile_main_sidebar_wrapper">
          <div className="inside_sidebar_wrapper">
            <div className="logo_wrapper">
              <img src={`/Assets/Svg/${"logoComplete.svg"}`} alt="menu_image" />
            </div>
            <div className="menus_wrapper">
              {roles && roles[0]?.dashboard.access && (
                <Menu
                  path="/dashboard"
                  icon="dashboard.svg"
                  colorIcon="dashboardColor.svg"
                  label="Dashboard"
                />
              )}

              {roles && !roles[0]?.userManagement.complete && (
                <Menu
                  path="/user/your-details"
                  icon="user.svg"
                  colorIcon="userColor.svg"
                  label="Profile"
                />
              )}

              {roles && roles[0]?.clients.access && (
                <Menu
                  path="/client"
                  icon="client.svg"
                  colorIcon="clientColor.svg"
                  label="Clients"
                />
              )}

              {roles && roles[0]?.vendors.access && (
                <Menu
                  path="/vendor"
                  icon="vendor.svg"
                  colorIcon="vendorColor.svg"
                  label="Vendors"
                />
              )}

              {roles && roles[0]?.leads.access && (
                <>
                  {/* <Separator /> */}

                  <Menu
                    path="/lead"
                    icon="lead.svg"
                    colorIcon="leadColor.svg"
                    label="Leads"
                  />

                  {/* <Separator /> */}
                </>
              )}

              {roles && roles[0]?.items.access && (
                <Menu
                  path="/item"
                  icon="item.svg"
                  colorIcon="itemColor.svg"
                  label="Items"
                />
              )}

              {roles && roles[0]?.invoices.access && (
                <Menu
                  path="/invoice"
                  icon="invoice.svg"
                  colorIcon="invoiceColor.svg"
                  label="Invoices"
                  subMenu={[
                    {
                      path: "/invoice/request-invoice",
                      icon: "invoice.svg",
                      colorIcon: "invoiceColor.svg",
                      label: "Requested Invoice",
                    },
                  ]}
                />
              )}

              {roles && roles[0]?.payments.access && (
                <Menu
                  path="/payment"
                  icon="payment.svg"
                  colorIcon="paymentColor.svg"
                  label="Payments"
                />
              )}

              {roles && roles[0]?.expenses.access && (
                <Menu
                  path="/expense"
                  icon="payment.svg"
                  colorIcon="paymentColor.svg"
                  label="Expenses"
                />
              )}

              {roles && roles[0]?.creditNotes.access && (
                <Menu
                  path="/credit-note"
                  icon="credit.svg"
                  colorIcon="creditColor.svg"
                  label="Credit Notes"
                />
              )}

              {roles && roles[0]?.demoRequests.access && (
                <Menu
                  path="/demo-request"
                  icon="request.svg"
                  colorIcon="requestColor.svg"
                  label="Demo Requests"
                />
              )}

              {/* <Separator /> */}

              {roles && roles[0]?.calendar.access && (
                <Menu
                  path="/calendar"
                  icon="calendar.svg"
                  colorIcon="calendarColor.svg"
                  label="Calender"
                />
              )}

              {roles && roles[0]?.tasks.access && (
                <Menu
                  path="/task"
                  icon="task.svg"
                  colorIcon="taskColor.svg"
                  label="Task"
                />
              )}

              <Menu
                path="/reminder"
                icon="reminder.svg"
                colorIcon="reminderColor.svg"
                label="Reminders"
              />

              <Menu
                path="/todo"
                icon="todo.svg"
                colorIcon="todoColor.svg"
                label="To-Do"
              />

              {/* <Separator /> */}

              {roles && roles[0]?.userManagement.complete && (
                <Menu
                  path="/user"
                  icon="user.svg"
                  colorIcon="userColor.svg"
                  label="User Management"
                />
              )}

              {roles && roles[0]?.attendance.access && (
                <Menu
                  path="/attendance"
                  icon="attendance.svg"
                  colorIcon="attendanceColor.svg"
                  label="Attendance"
                  subMenu={[
                    {
                      path: "/attendance/leave",
                      icon: "attendance.svg",
                      colorIcon: "attendanceColor.svg",
                      label: "Leaves",
                    },
                  ]}
                />
              )}

              {roles && roles[0]?.settings.access && (
                <Menu
                  path="/setting/general"
                  icon="setting.svg"
                  colorIcon="settingColor.svg"
                  label="Settings"
                />
              )}
            </div>
          </div>
        </div>
        <div
          className="menu_collapse mt-2 me-2"
          onClick={() => sidebarToggle(false)}
        >
          <CgClose />
        </div>
      </div>
    </>
  );
};

export default MobileSidebar;
