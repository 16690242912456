import { useEffect, useRef, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import OutsideClickHandler from "react-outside-click-handler";
import { countryOtherData } from "../../utils/constants";

type propType = {
  formik: any;
  updatedValues?: any;
  setUpdatedValues?: (val: any) => void;
  fieldName: string;
  fieldName2: string;
  defaultValue?: string;
};

const PhoneCode: React.FC<propType> = (props) => {
  const {
    formik,
    updatedValues,
    setUpdatedValues,
    fieldName,
    defaultValue,
    fieldName2,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [countries, setCountries] = useState<any>(countryOtherData);
  const input = useRef<any>(null);

  const fn = {
    setState: () => {
      setIsOpen(!isOpen);
    },
    addPhoneCode: (phoneCode: string) => {
      setInputValue("");
      setCountries(countryOtherData);
      formik.setFieldValue(fieldName, phoneCode);
      updatedValues &&
        setUpdatedValues?.({
          ...updatedValues,
          [fieldName]: phoneCode,
          isObjChange: true,
        });
      setIsOpen(false);
    },
    filterPhoneCode: (value: string) => {
      //filter phone code data acc. to input value
      if (value === "") {
        setCountries(countryOtherData);
      } else {
        let tempCountries = [...countryOtherData];
        const filterData = tempCountries?.filter((item: any) => {
          return (
            item?.phoneCode.includes(value) ||
            item?.name.toLowerCase().includes(value.toLowerCase())
          );
        });
        setCountries(filterData);
      }
    },
  };

  useEffect(() => {
    fn.filterPhoneCode(inputValue ?? "");
  }, []);

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
        <div
          className="input-group-text font12x600 border-0 cursor_pointer"
          onClick={fn.setState}
        >
          <p className="mb-0 font12x600 textColor5">
            {formik.values[fieldName]}
          </p>
          <IoMdArrowDropdown
            className="ms-1"
            style={{
              color: "var(--text5)",
            }}
          />
        </div>

        {isOpen && (
          <div className="select_country_section">
            <div className="search_wrapper_inside_dropdown">
              <input
                ref={input}
                type="text"
                placeholder="Search code by country name, code..."
                className="mb-0 input_without_style"
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  formik.setFieldValue(fieldName2, "");
                  fn.filterPhoneCode(e.target.value);
                }}
              />
            </div>
            {countries?.map((item: any, i: number) => {
              return (
                <div
                  key={i}
                  className="countries"
                  onClick={() => fn.addPhoneCode(item?.phoneCode)}
                  style={{
                    backgroundColor:
                      formik.values.phoneCode === item?.phoneCode
                        ? "var(--bg_color5)"
                        : "#fff",
                  }}
                >
                  <p className="mb-0">{`${item?.flag} ${item?.phoneCode}`}</p>
                </div>
              );
            })}
          </div>
        )}
      </OutsideClickHandler>
    </>
  );
};

export default PhoneCode;
