import { createContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isUserValid } from "../../function/isLogin";
import useLoading from "../../hooks/useLoading";
import { getCompanyById } from "../../lib/api functions/company";
import { getDocumentSettingById } from "../../lib/api functions/setting/document";
import { getGeneralSetting } from "../../lib/api functions/setting/general";
import { getRole } from "../../lib/api functions/setting/role";
import { getUserById } from "../../lib/api functions/user";
import { showToast } from "../../lib/toast/ShowToast";
import EmailLogin from "../../pages/common/EmailLogin";
import Layout from "../../shared components/Layout";
import MainLoader from "../../shared components/Loader/MainLoader";
import { sidebarRoutes } from "../constants";

export const MainContext = createContext<any>(null);

const PrivateRoutes = () => {
  const [pageTitle, setPageTitle] = useState("");
  const { pathname } = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const userId = localStorage.getItem("userId");
  const companyId = localStorage.getItem("companyId");
  const role = localStorage.getItem("role");
  const [roles, setRoles] = useState<any>([]);
  const [user, setUser] = useState<any>([]);
  const [company, setCompany] = useState<any>([]);
  const [setting, setSetting] = useState<any>([]);
  const [documentSetting, setDocumentSetting] = useState<any>([]);
  const [roleLoading, showLoader, hideLoader] = useLoading();

  const fn = {
    sidebarToggle: (toggle: boolean) => {
      setIsSidebarOpen(toggle);
    },
    addDefaultPageTitle: () => {
      sidebarRoutes?.map((route) => {
        if (route?.path === window.location.pathname) {
          setPageTitle(route?.label);
        }
      });
    },
    getUserById: async () => {
      const params = {
        type: "c",
      };
      const res = await getUserById(userId, params);
      if (res) {
        setUser(res?.data.user);
        window.localStorage.setItem("user", JSON.stringify(res?.data.user));
      }
    },
    getCompanyById: async () => {
      const res = await getCompanyById(companyId);
      if (res) {
        setCompany(res.data.company);
        window.localStorage.setItem(
          "company",
          JSON.stringify(res.data.company)
        );
      }
    },
    getRoles: async (params?: any) => {
      showLoader();
      const res = await getRole(params);
      if (res) {
        setTimeout(() => {
          setRoles(res.data.roles);
          hideLoader();
        }, 1000);
      }
    },
    getGeneralSetting: async (params?: any) => {
      const res = await getGeneralSetting(params);
      if (res) {
        setSetting(res.data.generalSettings[0]);
      }
    },
    getDocumentSetting: async (params?: any) => {
      const res = await getDocumentSettingById(companyId);
      if (res) {
        setDocumentSetting(res.data.documentSetting);
      }
    },
  };

  useEffect(() => {
    if (!userId && !companyId) {
      showToast("Unathorized!!!. Please login first.", "error");
    }
  }, []);

  useEffect(() => {
    fn.addDefaultPageTitle();
  }, [pathname]);

  useEffect(() => {
    if (userId) {
      fn.getUserById();
    }
  }, [userId]);
  useEffect(() => {
    if (companyId) {
      fn.getCompanyById();
    }
  }, [companyId]);

  useEffect(() => {
    if (companyId && userId) {
      fn.getRoles({ company: companyId, name: role });
    }
  }, [companyId, userId]);

  useEffect(() => {
    if (companyId) {
      fn.getGeneralSetting({ company: companyId });
      fn.getDocumentSetting({ company: companyId });
    }
  }, [companyId]);

  return isUserValid() ? (
    roleLoading && roles?.length === 0 ? (
      <MainLoader />
    ) : (
      <MainContext.Provider
        value={{
          pageTitle,
          setPageTitle,
          isSidebarOpen,
          sidebarToggle: fn.sidebarToggle,
          userId,
          companyId,
          roles,
          setting,
          setSetting,
          documentSetting,
          setDocumentSetting,
          user,
          company,
          updateUser: fn.getUserById,
        }}
      >
        <Layout>
          <Outlet />
        </Layout>
      </MainContext.Provider>
    )
  ) : (
    // <Navigate to="/" replace />
    <EmailLogin />
  );
};

export default PrivateRoutes;
