import React from "react";
import Skeleton from "react-loading-skeleton";
import Loader from "../../../shared components/Loader/SpinLoader";

type propType = {
  loading: boolean;
  data?: any;
};

const CompanyDetails: React.FC<propType> = (props) => {
  const { loading, data } = props;

  return (
    <>
      <div className="px-3 mt-4">
        <p className="mb-0 font12x500 textColor3">Company Details</p>
      </div>

      <div className="container-fluid px-3 mt-3">
        <div className="row">
          <div className="col-6 col-sm-4">
            <p className="mb-0 font12x400 textColor1">Company Name</p>
          </div>
          <div className="col-6 col-sm-8">
            <p className="mb-0 font12x400 textColor3">
              {loading ? (
                <Skeleton width={100} height={10} borderRadius={4} />
              ) : (
                <>{data?.name === "" ? "--" : data?.name}</>
              )}
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 col-sm-4">
            <p className="mb-0 font12x400 textColor1">Company Contact Number</p>
          </div>
          <div className="col-6 col-sm-8">
            <p className="mb-0 font12x400 textColor3">
              {loading ? (
                <Skeleton width={70} height={10} borderRadius={4} />
              ) : (
                <>{`${data?.phoneCode} ${
                  data?.contactNumber === "" ? "--" : data?.contactNumber
                }`}</>
              )}
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 col-sm-4">
            <p className="mb-0 font12x400 textColor1">Email</p>
          </div>
          <div className="col-6 col-sm-8 d-flex">
            <a
              href={`mailto:${data?.email}`}
              className="font12x400 textColor2 text-decoration-none"
              target="_blank"
            >
              {loading ? (
                <Skeleton width={140} height={10} borderRadius={4} />
              ) : (
                <>{data?.email === "" ? "--" : data?.email}</>
              )}
            </a>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 col-sm-4">
            <p className="mb-0 font12x400 textColor1">Company website</p>
          </div>
          <div className="col-6 col-sm-8 d-flex">
            <a
              href={`https://${data?.website}`}
              className="font12x400 textColor2 text-decoration-none"
              target="_blank"
              style={{
                wordBreak: "break-all",
              }}
            >
              {loading ? (
                <Skeleton width={120} height={10} borderRadius={4} />
              ) : (
                <>{data?.website === "" ? "--" : data?.website}</>
              )}
            </a>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 col-sm-4">
            <p className="mb-0 font12x400 textColor1">GST Type</p>
          </div>
          <div className="col-6 col-sm-8 d-flex">
            <p className="mb-0 font12x400 textColor3">
              {loading ? (
                <Skeleton width={120} height={10} borderRadius={4} />
              ) : (
                <>{data?.gstType === "" ? "--" : data?.gstType}</>
              )}
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 col-sm-4">
            <p className="mb-0 font12x400 textColor1">GSTIN</p>
          </div>
          <div className="col-6 col-sm-8 d-flex">
            <p className="mb-0 font12x400 textColor3">
              {loading ? (
                <Skeleton width={100} height={10} borderRadius={4} />
              ) : (
                <>{data?.gstin === "" ? "--" : data?.gstin}</>
              )}
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 col-sm-4">
            <p className="mb-0 font12x400 textColor1">PAN</p>
          </div>
          <div className="col-6 col-sm-8 d-flex">
            <p className="mb-0 font12x400 textColor3">
              {loading ? (
                <Skeleton width={50} height={10} borderRadius={4} />
              ) : (
                <>{data?.pan === "" ? "--" : data?.pan}</>
              )}
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 col-sm-4">
            <p className="mb-0 font12x400 textColor1">Currency</p>
          </div>
          <div className="col-6 col-sm-8 d-flex">
            <p className="mb-0 font12x400 textColor3">
              {loading ? (
                <Skeleton width={40} height={10} borderRadius={4} />
              ) : (
                <>{data?.currency === "" ? "--" : data?.currency}</>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
