import React from "react";

type proptype = {
  id: string;
  label?: string;
  state?: string;
  isDisabled?: boolean;
  setState?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: any;
};

const TextareaInput: React.FC<proptype> = (props) => {
  const { id, label, state, isDisabled, setState, onBlur } = props;

  return (
    <>
      {label && (
        <label htmlFor={id} className="form-label font12x400 textColor3 mb-1">
          {label}
        </label>
      )}

      <div
        className="input_wrapper d-flex align-items-center"
        style={{
          backgroundColor: isDisabled ? "#f2f2f2" : "#fff",
        }}
      >
        <textarea
          id={id}
          className="form-control shadow-none input_field"
          rows={4}
          value={state}
          onChange={setState}
          disabled={isDisabled}
          style={{
            cursor: isDisabled ? "no-drop" : "unset",
            backgroundColor: isDisabled ? "#f2f2f2" : "#fff",
            color: isDisabled ? "#9C9E9C" : "var(--text3)",
          }}
          onBlur={onBlur}
        ></textarea>
        {/* <div className="d-flex align-items-center me-2">
          <img src="/Assets/Svg/correct.svg" alt="correct" />
        </div> */}
      </div>
    </>
  );
};

export default TextareaInput;
