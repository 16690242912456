import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { FaPrint } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import CreditNoteSidebar from "../../../../components/Credit Note Components/View/CreditNoteSidebar";
import GenerateCreditNoteDoc from "../../../../components/GenerateDoc/GenerateCreditNoteDoc";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import useLoading from "../../../../hooks/useLoading";
import {
  getCreditNote,
  getCreditNoteById,
} from "../../../../lib/api functions/credit_note";
import Skeleton from "react-loading-skeleton";

import { getGeneralSetting } from "../../../../lib/api functions/setting/general";

import PrintComponents from "react-print-components";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";

const ViewCreditNotePage = () => {
  const { setPageTitle, companyId } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [loading2, showLoader2, hideLoader2] = useLoading();
  const [creditNote, setCreditNote] = useState<any>([]);
  const [singleCreditNote, setSingleCreditNote] = useState<any>([]);
  const [generalSetting, setGeneralSetting] = useState<any>("");
  const navigate = useNavigate();
  const { creditNoteId } = useParams();

  const fn = {
    getCreditNotes: async (params: any) => {
      showLoader();
      const res = await getCreditNote(params);
      if (res) {
        setCreditNote(res.data.creditNotes);
        hideLoader();
      }
    },
    getCreditNoteById: async () => {
      showLoader2();
      const res = await getCreditNoteById(creditNoteId);
      if (res) {
        setSingleCreditNote(res.data.creditNote);
        hideLoader2();
        fn.getGeneralSetting({ company: companyId });
      }
    },

    getGeneralSetting: async (params?: any) => {
      // showLoader2();
      const res = await getGeneralSetting(params);
      if (res) {
        setGeneralSetting(res.data.generalSettings[0]);
        // hideLoader2();
      }
    },
  };

  useEffect(() => {
    fn.getCreditNotes({
      f: "creditNoteNumber,calculation,client",
      company: companyId,
      isDeleted: false,
    });
    setPageTitle("View Credit Note");
  }, []);

  useEffect(() => {
    fn.getCreditNoteById();
  }, [creditNoteId]);

  const generatePDF = () => {
    // const report = new JsPDF({
    //   orientation: "portrait",
    //   unit: "pt",
    //   format: "a4",
    // });

    // var width = report.internal.pageSize.getWidth();
    // var height = report.internal.pageSize.getHeight();

    // report.addFont('ArialMS', 'Arial', 'normal');

    // report.setFont('Arial');

    // report.setFont("PTSans");

    // report.html(viewDoc, {
    //   callback: function (doc) {
    //     doc.save();
    //   },
    //   width: 594,
    //   height: height,
    //   windowWidth: 630,
    // });

    const divToDisplay = document.getElementById("content");
    html2canvas(divToDisplay!).then(function (canvas) {
      const divImage = canvas.toDataURL("image/png");
      const pdf = new jsPDF("portrait", "em", "a4");
      const componentWidth = pdf.internal.pageSize.getWidth();
      const componentHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(divImage, "PNG", 0, 0, componentWidth, componentHeight);
      pdf.save(
        `credit_note_${singleCreditNote?.creditNoteNumber}_${moment(
          singleCreditNote?.creditNoteDate
        ).format("YYYY-MM-DD")}.pdf`
      );
    });
  };

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12 col-lg-3">
            <CreditNoteSidebar
              creditNote={creditNote}
              loading={loading}
              creditNoteId={creditNoteId}
            />
          </div>

          <div className="col-12 col-lg-9 pt-4 pt-lg-0">
            <div>
              <div className="viewlead_content_header d-flex justify-content-end view_doc_header">
                <div className="viewlead_content_header_section d-flex gap-3">
                  <div>
                    <button
                      className="btn font12x500 buttonOne shadow-none"
                      onClick={() =>
                        navigate(`/credit-note/form/${creditNoteId}`)
                      }
                    >
                      <MdOutlineEdit className="me-1" />
                      Edit
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn font12x500 buttonOne shadow-none"
                      onClick={() => generatePDF()}
                    >
                      <BsFileEarmarkPdf className="me-1" />
                      PDF
                    </button>
                  </div>
                  <div>
                    <div className="btn font12x500 buttonOne shadow-none print">
                      <PrintComponents
                        trigger={
                          <button>
                            <FaPrint className="me-1" /> Print
                          </button>
                        }
                      >
                        <GenerateCreditNoteDoc
                          width="100%"
                          apiData={singleCreditNote}
                          generalSetting={generalSetting}
                          boxShadow="none"
                        />
                      </PrintComponents>
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn font12x500 buttonOne shadow-none"
                      // onClick={() => redirect(url)}
                    >
                      <MdOutlineEmail
                        className="me-1"
                        style={{
                          fontSize: 16,
                        }}
                      />
                      Email
                    </button>
                  </div>
                </div>
              </div>

              {loading2 ? (
                <Skeleton height="300px" borderRadius="8px" />
              ) : (
                <div className="menu_content_wrapper p-3">
                  <GenerateCreditNoteDoc
                    width="635px"
                    apiData={singleCreditNote}
                    generalSetting={generalSetting}
                    boxShadow="rgb(99 99 99 / 20%) 0px 2px 8px 0px"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCreditNotePage;
