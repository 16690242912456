import React, { useContext, useState } from "react";
import InputField from "../../../../shared components/TextInput";
import { CgClose } from "react-icons/cg";
import { commonType } from "../../../../pages/main/lead";
import UpdationDetail from "../../../Data Update Detail";
import RadioButton from "../../../../shared components/RadioButton/RadioButton";
import { ViewLeadContext } from "../../../../pages/main/lead/view lead";
import useLoading from "../../../../hooks/useLoading";
import { showToast } from "../../../../lib/toast/ShowToast";
import { CredentialObject } from "./Type";
import { useFormik } from "formik";
import { credentialSchema } from "../../../../utils/validationSchema";
import InputError from "../../../../shared components/InputError/InputError";
import {
  createCredential,
  updateCredential,
} from "../../../../lib/api functions/lead/credential";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall: (val: any) => void;
  params: any;
};

const Form: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params } = props;
  const { id, getActivity } = useContext(ViewLeadContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "";
    },
  };

  const initialValues: CredentialObject = {
    title: state?.data?.title ?? "",
    userId: state?.data?.userId ?? "",
    password: state?.data?.password ?? "",
    loginLink: state?.data?.loginLink ?? "",
    type: state?.data?.type.toString() ?? "1",
  };

  const formik = useFormik<CredentialObject>({
    initialValues,
    validationSchema: credentialSchema,
    onSubmit: async (values) => {
      let finalObj = {};

      if (state?.data) {
        delete updatedValues.isObjChange;
        finalObj = updatedValues;
      } else {
        finalObj = {
          lead: id,
          title: values.title,
          userId: values.userId,
          password: values.password,
          loginLink: values.loginLink,
          type: values.type,
        };
      }
      showLoader();
      if (state?.data) {
        const res = await updateCredential(
          finalObj,
          state?.data?._id,
          hideLoader
        );
        if (res) {
          showToast(`Credential updated`, "success");
          fn.setPositionToBody();
          apiCall(params);
          getActivity({ lead: id });
          hideLoader();
        }
      } else {
        const res = await createCredential(finalObj, hideLoader);
        if (res) {
          showToast(`Credential created`, "success");
          fn.setPositionToBody();
          apiCall(params);
          getActivity({ lead: id });
          hideLoader();
        }
      }
    },
  });

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">
              {state.data ? "Edit Credentials" : "Attach New Credentials"}
            </h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <InputField
                    id="title"
                    label="Title*"
                    type="text"
                    state={formik.values.title}
                    setState={(e) => {
                      formik.setFieldValue("title", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        title: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <InputError error={formik.errors.title} />
                  ) : null}
                </div>
                <div className="mb-3">
                  <InputField
                    id="userId"
                    label="User ID*"
                    type="text"
                    state={formik.values.userId}
                    setState={(e) => {
                      formik.setFieldValue("userId", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        userId: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.userId && formik.errors.userId ? (
                    <InputError error={formik.errors.userId} />
                  ) : null}
                </div>
                <div className="mb-3">
                  <InputField
                    id="password"
                    label="Password*"
                    type="password"
                    state={formik.values.password}
                    setState={(e) => {
                      formik.setFieldValue("password", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        password: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <InputError error={formik.errors.password} />
                  ) : null}
                </div>
                <div className="mb-3">
                  <InputField
                    id="loginLink"
                    label="Login Link*"
                    type="text"
                    state={formik.values.loginLink}
                    setState={(e) => {
                      formik.setFieldValue("loginLink", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        loginLink: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.loginLink && formik.errors.loginLink ? (
                    <InputError error={formik.errors.loginLink} />
                  ) : null}
                </div>

                <div className="container-fluid p-0">
                  <div className="row">
                    <label className="form-label font12x400 textColor3 mb-1">
                      Type*
                    </label>
                    <div className="col-4">
                      <RadioButton
                        label="Demo"
                        htmlFor="demo"
                        name="type"
                        value="1"
                        state={formik.values.type}
                        setState={(x?: string | number) => {
                          formik.setFieldValue("type", x);
                          setUpdatedValues({
                            ...updatedValues,
                            type: x,
                            isObjChange: true,
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <RadioButton
                        label="Live"
                        htmlFor="live"
                        name="type"
                        value="2"
                        state={formik.values.type}
                        setState={(x?: string | number) => {
                          formik.setFieldValue("type", x);
                          setUpdatedValues({
                            ...updatedValues,
                            type: x,
                            isObjChange: true,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>

                {state.data ? (
                  <button
                    className="btn font12x500 shadow-none custom_btn ms-3"
                    disabled={updatedValues?.isObjChange ? false : true}
                  >
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )}
                  </button>
                ) : (
                  <button className="btn font12x500 shadow-none custom_btn ms-3">
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Save"
                    )}
                  </button>
                )}
              </div>
            </form>

            {state.data && (
              <UpdationDetail
                lastUpdate={state?.data?.updatedAt}
                updatedBy={state?.data?.updatedBy}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
