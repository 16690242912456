import { useContext } from "react";
import { UserProfileContext } from "../../../../pages/main/user/User Profile";
import {
  YOUR_ATTENDANCE,
  YOUR_BANK_DETAILS,
  YOUR_DETAILS,
  YOUR_DOCUMENTS,
  YOUR_OFFICE_DETAILS,
  YOUR_SALARY_SLIP,
} from "../../../../utils/constants";
import UserAttendance from "./attendance";
import BankDetails from "./bank details";
import Documents from "./documents";
import OfficeDetails from "./office details";
import UserSalarySlip from "./salary slip";
import UserDetails from "./user details";

const MenuData = () => {
  const { routeName } = useContext(UserProfileContext);
  switch (routeName) {
    case YOUR_DETAILS:
      return (
        <>
          <UserDetails />
        </>
      );
    case YOUR_BANK_DETAILS:
      return (
        <>
          <BankDetails />
        </>
      );
    case YOUR_OFFICE_DETAILS:
      return (
        <>
          <OfficeDetails />
        </>
      );
    case YOUR_DOCUMENTS:
      return (
        <>
          <Documents />
        </>
      );
    case YOUR_ATTENDANCE:
      return (
        <>
          <UserAttendance />
        </>
      );
    case YOUR_SALARY_SLIP:
      return (
        <>
          <UserSalarySlip />
        </>
      );
    default:
      return null;
  }
};

export default MenuData;
