import React from "react";
import { Link } from "react-router-dom";
import { commonType } from "../../../pages/main/lead";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
};

const Info: React.FC<propType> = (props) => {
  const { state, setState } = props;
  const fn = {
    setCommonState: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
  };

  return (
    <>
      <div
        className="delete_modal_wrapper"
        style={{
          padding: "20px 20px",
          display: "block",
          overflow: "auto",
        }}
      >
        <div className="paySlip_wrapper">
          <div className="d-flex justify-content-between align-items-center px-4">
            <h3 className="font14x500 textColor3 mb-0">Mandatory Settings:</h3>
            <img
              src="/Assets/Svg/closeIcon.svg"
              alt="close"
              style={{
                cursor: "pointer",
              }}
              onClick={fn.setCommonState}
              loading="lazy"
            />
          </div>

          <div className="px-4 mt-3">
            <h5 className="mb-1 font13x500">For User Creation</h5>
            <ul className="font12x400">
              <li>
                <Link to={`/setting/user-role`}>Create User and Roles</Link>
              </li>
              <li>
                <Link to={`/setting/location`}>Update Location</Link>
              </li>
              <li>
                <Link to={`/setting/employee`}>Create Employee Setting</Link>
              </li>
              <li>
                <Link to={`/setting/salary-slip`}>
                  Create Salary Slip Labels
                </Link>
              </li>
            </ul>
            <h5 className="mb-1 font13x500">For Attendance Creation</h5>
            <ul className="font12x400">
              <li>
                <Link to={`/setting/department`}>Update Department</Link>
              </li>
              <li>
                Update User Office Details (
                {`user management -> edit -> office details`})
              </li>
              <li>
                Update User Attendance (
                {`user management -> edit -> attendance -> work timings`})
              </li>
            </ul>
            <h5 className="mb-1 font13x500">For Salary Slip Creation</h5>
            <ul className="font12x400">
              <li>
                Update Paid Salary (
                {`attendance -> edit user attendance -> paid salary label`})
              </li>
              <li>Update Add Value ({`attendance -> add value button`})</li>
              <li>
                <Link to={`/setting/profile`}>Update Company Logo</Link>
              </li>
              <li>
                <Link to={`/setting/general`}>Update Primary Signature</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
