import React from "react";

const Separator = () => {
  return (
    <>
      <div
        className="menu_separator"
        style={{
          borderBottom: "2px solid var(--border_color4)",
          marginBottom: "8px",
        }}
      ></div>
    </>
  );
};

export default Separator;
