import moment from "moment";
import React, { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
import useLoading from "../../../hooks/useLoading";
import { updateDemoRequest } from "../../../lib/api functions/demo_request";
import { updateLead } from "../../../lib/api functions/lead/lead";
import { updatePO } from "../../../lib/api functions/lead/po";
import { showToast } from "../../../lib/toast/ShowToast";
import { commonType } from "../../../pages/main/lead";
import { ViewLeadContext } from "../../../pages/main/lead/view lead";
import SpinLoader from "../../../shared components/Loader/SpinLoader";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import MeetingForm from "./MeetingForm";

const Activities = () => {
  const {
    lead,
    activity,
    activityLoading,
    id,
    getLeadById,
    po,
    getPOs,
    getActivity,
  } = useContext(ViewLeadContext);
  const { roles, companyId } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const [loading, showLoader, hideLoader] = useLoading();

  const fn = {
    openForm: (data: any) => {
      setCommonData({
        data: data ? data : undefined,
        modal: true,
        check: "meeting_form",
      });
      document.body.style.overflow = "hidden";
    },
    updateLead: async (confirmation: number) => {
      let finalObj = {
        confirmation,
      };
      // showLoader();
      const res = await updateLead(finalObj, id, hideLoader);
      if (res) {
        showToast(
          `${confirmation === 1 ? "Lead approved" : "Lead rejected"}`,
          "success"
        );
        getLeadById();
        getActivity({ lead: id });
        // hideLoader();
      }
    },
    updatePO: async (status: number) => {
      let finalObj = {
        status,
      };
      // showLoader();
      const res = await updatePO(finalObj, po[0]?._id, hideLoader);
      if (res) {
        showToast(`${status === 1 ? "PO approved" : "PO rejected"}`, "success");
        getPOs({ lead: id });
        getActivity({ lead: id });
        // hideLoader();
      }
    },
  };

  return (
    <>
      <div className="lead_right_side">
        <div className="activities_header_wrapper">
          <div className="viewlead_content_header d-flex justify-content-between align-items-center">
            <p className="m-0 font14x500 textColor3">Latest Activity</p>
            <div
              className="d-flex cursor_pointer"
              onClick={() => fn.openForm(null)}
            >
              <img src="/Assets/Svg/meetIcon.svg" alt="meet" />
              <p className="m-0 font14x500 textColor2">Schedule Meeting</p>
            </div>
          </div>
        </div>

        <div className="activities_section_wrapper">
          {activity?.length !== 0 ? (
            activity?.map((activity: any, i: number) => {
              return (
                <div key={i} className="activities">
                  <div className="d-flex justify-content-between align-items-start mb-3 mt-1">
                    <h3 className="font12x600 mb-0 textColor3">
                      {activity?.title}
                    </h3>
                    <p className="activityTag mb-0 font10x500">
                      {activity?.tag}
                    </p>
                  </div>

                  {activity?.description !== "" && (
                    <p className="font12x400 textColor1 mb-2">
                      {activity?.description}
                    </p>
                  )}

                  {activity?.attachments?.length !== 0 &&
                    activity?.attachments?.map((item: any, i: number) => {
                      return (
                        <div
                          key={i}
                          className="doc_section d-flex align-items-center mb-1"
                        >
                          <img src="/Assets/Svg/pdfIcon.svg" alt="doc" />
                          <a
                            className="font12x400 textColor2 text-decoration-none ms-2"
                            href={`${process.env.REACT_APP_IMAGE_URL}${item?.url}`}
                            target="_blank"
                          >
                            {item?.filename}
                          </a>
                        </div>
                      );
                    })}

                  <div className="d-flex justify-content-end align-items-center">
                    <div className="activity_dot"></div>
                    <p className="mb-0 font8x400 textColor1 ms-1">
                      {moment(activity?.createdAt).format("DD MMM YYYY")}
                    </p>
                  </div>
                </div>
              );
            })
          ) : activityLoading ? (
            <div className="d-flex flex-column gap-2">
              <Skeleton borderRadius={8} height="100px" />
              <Skeleton borderRadius={8} height="100px" />
              <Skeleton borderRadius={8} height="100px" />
              <Skeleton borderRadius={8} height="100px" />
              <Skeleton borderRadius={8} height="100px" />
            </div>
          ) : (
            <p className="mb-0 font14x400">No activity found</p>
          )}
        </div>

        {/* for lead approval */}
        {roles[0]?.name === "admin" && lead?.confirmation === 0 && (
          <div className="approval_wrapper">
            <p className="font12x600 textColor3">Lead Approval Request</p>
            <p className="font12x400 textColor3">
              {`${lead?.user?.name} from ${lead?.user?.department} department has requested for a Lead approval.`}
            </p>
            <div className="d-flex justify-content-between gap-2">
              <button
                className="w-100 btn font12x500 modalBtnColor shadow-none"
                style={{
                  backgroundColor: "var(--bg_color2)",
                }}
                onClick={() => fn.updateLead(1)}
              >
                {loading ? (
                  <SpinLoader height="20" width="20" color="#fff" />
                ) : (
                  "Accept"
                )}
              </button>
              <button
                className="w-100 btn font12x500 modalBtnColor shadow-none"
                style={{
                  backgroundColor: "var(--error)",
                }}
                onClick={() => fn.updateLead(2)}
              >
                {loading ? (
                  <SpinLoader height="20" width="20" color="#fff" />
                ) : (
                  "Reject"
                )}
              </button>
            </div>
          </div>
        )}

        {/* for po approval */}
        {roles[0]?.name === "admin" &&
          po?.length !== 0 &&
          po[0]?.status === 0 && (
            <div className="approval_wrapper">
              <p className="font12x600 textColor3">PO Approval Request</p>
              <p className="font12x400 textColor3">
                {`${po[0]?.user?.name} from ${po[0]?.user?.department} has requested for a PO approval.`}
              </p>
              <div className="d-flex justify-content-between gap-2">
                <button
                  className="w-100 btn font12x500 modalBtnColor shadow-none"
                  style={{
                    backgroundColor: "var(--bg_color2)",
                  }}
                  onClick={() => fn.updatePO(1)}
                >
                  {loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Accept"
                  )}
                </button>
                <button
                  className="w-100 btn font12x500 modalBtnColor shadow-none"
                  style={{
                    backgroundColor: "var(--error)",
                  }}
                  onClick={() => fn.updatePO(2)}
                >
                  {loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Reject"
                  )}
                </button>
              </div>
            </div>
          )}
      </div>

      {/* form modal */}
      {commonData?.modal && commonData?.check === "meeting_form" && (
        <MeetingForm
          state={commonData}
          setState={setCommonData}
          lead={lead}
          getActivity={getActivity}
        />
      )}
    </>
  );
};

export default Activities;
