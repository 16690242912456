import { createContext, useContext, useEffect, useState } from "react";
import NewLeadProcess from "../../../../components/New Lead Process";
import { ONLY_CLIENT } from "../../../../utils/constants";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import { ClientObject, CompleteAddress, PersonDetails } from "./type";

export const LeadContext = createContext<any>(null);

const NewLead = () => {
  const { setPageTitle } = useContext(MainContext);
  useEffect(() => {
    setPageTitle("Add New Lead");
  }, []);
  const companyObj = JSON.parse(window.localStorage.getItem("company") || "");
  const [step, setStep] = useState<number>(1);

  const initialValuesClient: ClientObject = {
    name: "",
    contactNumber: "",
    phoneCode: companyObj?.phoneCode,
    email: "",
    website: "",
    gstType: "",
    gstin: "",
    pan: "",
    currency: "INR",
    type: ONLY_CLIENT,
    isCompanySelected: false,
  };
  const initialValuesAddress: CompleteAddress = {
    address: "",
    state: "",
    city: "",
    pincode: "",
    country: "India",
    extra: {
      countryCode: "IN",
      stateCode: "",
    },
  };
  const initialValuesPerson: PersonDetails = {
    name: "",
    email: "",
    mobile: "",
    phoneCode: companyObj?.phoneCode,
  };

  const fn = {
    nextStep: () => {
      setStep(step + 1);
    },
    prevStep: () => {
      setStep(step - 1);
    },
    jumpToLastStep: () => {
      setStep(4);
    },
  };

  return (
    <>
      <div
        className="single_page"
        style={{
          overflow: "unset",
        }}
      >
        <LeadContext.Provider
          value={{
            step,
            fn,
            initialValuesClient,
            initialValuesAddress,
            initialValuesPerson,
          }}
        >
          <NewLeadProcess />
        </LeadContext.Provider>
      </div>
    </>
  );
};

export default NewLead;
