import React from "react";

type PropType = {
  bgColor: string;
  image: string;
  onClickFn?: () => void;
};

const Box: React.FC<PropType> = (props) => {
  const { bgColor, image, onClickFn } = props;

  return (
    <>
      <div
        onClick={onClickFn}
        className="cursor_pointer"
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: bgColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={image} className="img-fluid" alt="action" />
      </div>
    </>
  );
};

export default Box;
