import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import useLoading from "../../../../hooks/useLoading";
import { getDocumentNumberById } from "../../../../lib/api functions/common";
import { createUser, updateUser } from "../../../../lib/api functions/user";
import { showToast } from "../../../../lib/toast/ShowToast";
import { UserContext } from "../../../../pages/main/user/add update user";
import { UserObject } from "../../../../pages/main/user/add update user/type";
import PhoneCode from "../../../../shared components/Dropdown/PhoneCode";
import InputError from "../../../../shared components/InputError/InputError";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import FileInput from "../../../../shared components/SingleFileInput/FileInput";
import MultiFileInput from "../../../../shared components/SingleFileInput/MultiFileInput";
import TextareaInput from "../../../../shared components/TextareaInput";
import InputField from "../../../../shared components/TextInput";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import { userDetailsSchema } from "../../../../utils/validationSchema";
import ProfilePhoto from "./ProfilePhoto";

const UserDetails = () => {
  const { companyId } = useContext(MainContext);
  const { userId, user, userLoading, getUser } = useContext(UserContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();
  const [singleFile, setSingleFile] = useState<any>(undefined);
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });
  const companyObj = JSON.parse(window.localStorage.getItem("company") || "");

  const fn = {
    getDocNumber: async (params?: any) => {
      const res = await getDocumentNumberById(companyId, params);
      if (res) {
        formik.setFieldValue(
          "customUserId",
          res.data.documentNumber.user.number.toString()
        );
      }
    },
    putUserValueInState: () => {
      formik.setFieldValue("customUserId", user.customUserId);
      user.image && formik.setFieldValue("image", user.image);
      formik.setFieldValue("name", user.name);
      formik.setFieldValue("employeeId", user.employeeId);
      formik.setFieldValue("address", user.address ?? "");
      formik.setFieldValue("email", user.email ?? "");
      formik.setFieldValue("mobile", user.mobile ?? "");
      formik.setFieldValue("phoneCode", user.phoneCode ?? "");
      formik.setFieldValue("emergencyNumber", user.emergencyNumber ?? "");
      formik.setFieldValue("emergencyPhoneCode", user.emergencyPhoneCode ?? "");
      formik.setFieldValue("bloodGroup", user.bloodGroup ?? "");
      formik.setFieldValue("pan", user.pan ?? "");
      formik.setFieldValue("aadharNumber", user.aadharNumber ?? "");
      user.p && formik.setFieldValue("p", [user.p]);
      user.a && formik.setFieldValue("a", user.a);
    },
  };
  const initialValues: UserObject = {
    customUserId: "",
    image: "",
    name: "",
    employeeId: "",
    address: "",
    email: "",
    mobile: "",
    phoneCode: companyObj?.phoneCode,
    emergencyNumber: "",
    emergencyPhoneCode: companyObj?.phoneCode,
    bloodGroup: "",
    pan: "",
    aadharNumber: "",
    p: [],
    a: [],
  };

  const formik = useFormik<UserObject>({
    initialValues,
    validationSchema: userDetailsSchema,
    onSubmit: async (values) => {
      let finalObj: UserObject = {};

      if (!userId) {
        finalObj["customUserId"] = values.customUserId;
      }
      // finalObj = {
      //   ...finalObj,
      //   name: values.name,
      //   address: values.address,
      //   email: values.email,
      //   mobile: values.mobile,
      //   phoneCode: values.phoneCode,
      //   emergencyNumber: values.emergencyNumber,
      //   emergencyPhoneCode: values.emergencyPhoneCode,
      //   bloodGroup: values.bloodGroup,
      //   pan: values.pan,
      //   aadharNumber: values.aadharNumber,
      //   status: 1,
      // };
      delete updatedValues?.isObjChange;
      finalObj = {
        ...updatedValues,
        status: 1,
      };

      showLoader();
      if (userId) {
        const res = await updateUser(finalObj, userId, hideLoader);
        if (res) {
          showToast(`User updated`, "success");
          getUser();
          hideLoader();
        }
      } else {
        const res = await createUser(finalObj, hideLoader);
        if (res) {
          showToast(`User created`, "success");
          navigate("/user", { replace: true });
        }
      }
    },
  });

  useEffect(() => {
    if (!userId) {
      fn.getDocNumber({ f: "user" });
    }
  }, []);
  useEffect(() => {
    !userLoading && userId && fn.putUserValueInState();
  }, [userLoading]);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font16x600 textColor3">User Details</p>
        </div>

        {userLoading ? (
          <p>loading...</p>
        ) : (
          <div className="details_form_wrapper">
            {/* form */}
            <form onSubmit={formik.handleSubmit}>
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-12 col-sm-6 order-2 order-sm-1">
                    <div className="mb-3">
                      <InputField
                        id="name"
                        label="Name"
                        type="text"
                        state={formik.values.name}
                        setState={(e) => {
                          formik.setFieldValue("name", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            name: e.target.value,
                            isObjChange: true,
                          });
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <InputError error={formik.errors.name} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <InputField
                        id="employeeId"
                        label="Employee Id"
                        type="text"
                        state={formik.values.employeeId}
                        setState={(e) => {
                          formik.setFieldValue("employeeId", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            employeeId: e.target.value,
                            isObjChange: true,
                          });
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.employeeId && formik.errors.employeeId ? (
                        <InputError error={formik.errors.employeeId} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <TextareaInput
                        id="address"
                        label="Address"
                        state={formik.values.address}
                        setState={(e) => {
                          formik.setFieldValue("address", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            address: e.target.value,
                            isObjChange: true,
                          });
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.address && formik.errors.address ? (
                        <InputError error={formik.errors.address} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <InputField
                        id="email"
                        label="Email"
                        type="text"
                        state={formik.values.email}
                        setState={(e) => {
                          formik.setFieldValue("email", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            email: e.target.value.toLowerCase(),
                            isObjChange: true,
                          });
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <InputError error={formik.errors.email} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <label
                        htmlFor="contactNumber"
                        className="form-label font12x400 textColor3 mb-1"
                      >
                        Phone No
                      </label>
                      <div className="input-group">
                        {!userLoading && (
                          <PhoneCode
                            formik={formik}
                            fieldName="phoneCode"
                            fieldName2="mobile"
                            updatedValues={updatedValues}
                            setUpdatedValues={setUpdatedValues}
                          />
                        )}

                        <input
                          type="text"
                          className="form-control shadow-none font12x600 border-0"
                          id="mobile"
                          value={formik.values.mobile}
                          onChange={(e) => {
                            formik.setFieldValue("mobile", e.target.value);
                            setUpdatedValues({
                              ...updatedValues,
                              mobile: e.target.value,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                          autoComplete="new-mobile"
                        />
                      </div>
                      {formik.touched.mobile && formik.errors.mobile ? (
                        <InputError error={formik.errors.mobile} />
                      ) : null}
                      {formik.touched.phoneCode && formik.errors.phoneCode ? (
                        <InputError error={formik.errors.phoneCode} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <label
                        htmlFor="contactNumber"
                        className="form-label font12x400 textColor3 mb-1"
                      >
                        Emergency No
                      </label>
                      <div className="input-group">
                        <PhoneCode
                          formik={formik}
                          fieldName="emergencyPhoneCode"
                          fieldName2="emergencyNumber"
                          updatedValues={updatedValues}
                          setUpdatedValues={setUpdatedValues}
                        />
                        <input
                          type="text"
                          className="form-control shadow-none font12x600 border-0"
                          id="emergencyNumber"
                          value={formik.values.emergencyNumber}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "emergencyNumber",
                              e.target.value
                            );
                            setUpdatedValues({
                              ...updatedValues,
                              emergencyNumber: e.target.value,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                          autoComplete="new-emergencyNumber"
                        />
                      </div>
                      {formik.touched.emergencyNumber &&
                      formik.errors.emergencyNumber ? (
                        <InputError error={formik.errors.emergencyNumber} />
                      ) : null}
                      {formik.touched.emergencyPhoneCode &&
                      formik.errors.emergencyPhoneCode ? (
                        <InputError error={formik.errors.emergencyPhoneCode} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <InputField
                        id="bloodGroup"
                        label="Blood Group"
                        type="text"
                        state={formik.values.bloodGroup}
                        setState={(e) => {
                          formik.setFieldValue("bloodGroup", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            bloodGroup: e.target.value,
                            isObjChange: true,
                          });
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.bloodGroup && formik.errors.bloodGroup ? (
                        <InputError error={formik.errors.bloodGroup} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <InputField
                        id="pan"
                        label="PAN No"
                        type="text"
                        state={formik.values.pan}
                        setState={(e) => {
                          formik.setFieldValue("pan", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            pan: e.target.value,
                            isObjChange: true,
                          });
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.pan && formik.errors.pan ? (
                        <InputError error={formik.errors.pan} />
                      ) : null}
                      <FileInput
                        formik={formik}
                        accept=".jpeg, .png, .jpg, .pdf"
                        multiple={false}
                        backendStateKey="p"
                        frontendStateKey={singleFile}
                        setSingleFile={setSingleFile}
                      />
                    </div>

                    <div className="mb-3">
                      <InputField
                        id="aadharNumber"
                        label="Aadhaar No"
                        type="text"
                        state={formik.values.aadharNumber}
                        setState={(e) => {
                          formik.setFieldValue("aadharNumber", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            aadharNumber: e.target.value,
                            isObjChange: true,
                          });
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.aadharNumber &&
                      formik.errors.aadharNumber ? (
                        <InputError error={formik.errors.aadharNumber} />
                      ) : null}
                      <MultiFileInput
                        formik={formik}
                        accept=".jpeg, .png, .jpg, .pdf"
                        multiple={true}
                        backendStateKey="a"
                        frontendStateKey={singleFile}
                        setSingleFile={setSingleFile}
                        maxDataAllowed={2}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 order-1 order-sm-2">
                    {userLoading ? (
                      <div className="d-flex align-items-center flex-column gap-2">
                        <Skeleton width={100} height={15} borderRadius={4} />
                        <Skeleton width={150} height={150} borderRadius={8} />
                      </div>
                    ) : (
                      <ProfilePhoto
                        state={formik.values.image}
                        userId={userId}
                        userLoading={userLoading}
                        formik={formik}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end my-4">
                <button
                  type="submit"
                  className="btn font12x500 shadow-none ms-3 custom_btn"
                  disabled={updatedValues?.isObjChange ? false : true}
                >
                  {userId ? (
                    loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )
                  ) : loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default UserDetails;
