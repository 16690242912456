import { useContext, useState } from "react";
import { CgMenu } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { getDefaultInitial } from "../../function/other";
import { deleteUserDevice } from "../../lib/api functions/common";
import { MainContext } from "../../utils/private_routes/PrivateRoutes";
import NotificationComponent from "../Notification";
import OutsideClickHandler from "react-outside-click-handler";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { pageTitle, isSidebarOpen, sidebarToggle, user } =
    useContext(MainContext);
  const userDeviceId = window.localStorage.getItem("userDeviceId");

  const fn = {
    navigateFn: (isLogout: boolean, route: string) => {
      if (isLogout) {
        fn.deleteUserDevice();
      } else {
        navigate(route);
      }
    },
    deleteUserDevice: async () => {
      await deleteUserDevice(userDeviceId);
      window.localStorage.clear();
      navigate("/", { replace: true });
    },
  };

  return (
    <>
      <div className="header_wrapper">
        <div className="menu_heading d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div
              className="menu_collapse"
              onClick={() => sidebarToggle(!isSidebarOpen)}
            >
              <CgMenu
                style={{
                  color: "var(--bg_color7)",
                }}
              />
            </div>
            <h3 className="font16x600 textColor3 mb-0 ms-3">{pageTitle}</h3>
          </div>

          <div className="d-flex align-items-center gap-3">
            {/* notications */}
            <NotificationComponent />

            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
              <div
                className="user_menu_wrapper"
                onClick={() => setIsOpen(!isOpen)}
              >
                <div className="d-flex align-items-center gap-2">
                  <div className="user_image_wrapper">
                    {user?.image === null ? (
                      <span className="assignTo_section font10x500">
                        {getDefaultInitial(user?.name)}
                      </span>
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_URL}${user?.image?.url}`}
                        alt="user"
                        width={22}
                        height={22}
                        style={{ borderRadius: "50%" }}
                      />
                    )}
                    <div className="status_dot"></div>
                  </div>
                  <p className="mb-0 desktop font12x500 textColor3 text-capitalize">
                    {user?.name ?? ""}
                  </p>
                  <img src="/Assets/Svg/dropdownIcon.svg" alt="icon" />
                </div>

                {isOpen && (
                  <div className="user_menus">
                    <div
                      className="menus"
                      onClick={() => fn.navigateFn(false, `/user-profile`)}
                    >
                      <img src="/Assets/Svg/settingBlack.svg" alt="setting" />
                      <span className="ms-2 font12x500">Settings</span>
                    </div>
                    <div
                      className="menus"
                      onClick={() => fn.navigateFn(true, "")}
                    >
                      <img src="/Assets/Svg/logout.svg" alt="setting" />
                      <span className="ms-2 font12x500">Log Out</span>
                    </div>
                  </div>
                )}
              </div>
            </OutsideClickHandler>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
