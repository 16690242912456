import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { BiSave } from "react-icons/bi";
import useLoading from "../../../../hooks/useLoading";
import { updateUser } from "../../../../lib/api functions/user";
import { showToast } from "../../../../lib/toast/ShowToast";
import { UserContext } from "../../../../pages/main/user/add update user";
import {
  SalarySlipLabelsType,
  UserObject,
} from "../../../../pages/main/user/add update user/type";
import DropdownOne from "../../../../shared components/Dropdown/DropdownOne";
import InputError from "../../../../shared components/InputError/InputError";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import InputField from "../../../../shared components/TextInput";
import { salaryTypeMap, salaryValueTypeMap } from "../../../../utils/constants";
import { userSalarySlipDetailsSchema } from "../../../../utils/validationSchema";
import Box from "../../../Action Component/Box";

const UserSalarySlip = () => {
  const { userId, user, userLoading, getUser } = useContext(UserContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const initialValues: UserObject = {
    slipLabels: [],
  };
  const [singleItem, setSingleItem] = useState<SalarySlipLabelsType>({
    type: "",
    typeName: "",
    label: "",
    value: "",
    valueType: "",
    valueTypeName: "",
    isEditable: false,
  });
  const fn = {
    putUserValueInState: () => {
      let slipLabels: any = [];
      user.slipLabels?.map((item: any) => {
        slipLabels.push({
          type: item.type,
          typeName: salaryTypeMap.get(item.type),
          label: item.label,
          value: item.value,
          valueType: item.valueType,
          valueTypeName: salaryValueTypeMap.get(item.valueType),
          isEditable: false,
        });
      });
      formik.setFieldValue("slipLabels", slipLabels);
    },
    addDataIntoState: (singleItem: UserObject) => {
      formik.setFieldValue("slipLabels", [
        // @ts-ignore
        ...formik.values.slipLabels,
        singleItem,
      ]);

      setSingleItem({
        type: "",
        typeName: "",
        label: "",
        value: "",
        valueType: "",
        valueTypeName: "",
        isEditable: false,
      });
    },
    updateDataIntoState: (index: number) => {
      let _state = formik.values;

      formik.setFieldValue(
        `slipLabels[${index}.isEditable]`, // @ts-ignore
        !_state.slipLabels[index].isEditable
      );
    },
    saveDataIntoState: (item: any, index: number) => {
      let _state = formik.values;

      formik.setFieldValue(
        `slipLabels[${index}.label]`, // @ts-ignore
        _state.slipLabels[index].label
      );
      formik.setFieldValue(
        `slipLabels[${index}.type]`, // @ts-ignore
        _state.slipLabels[index].type
      );
      formik.setFieldValue(
        `slipLabels[${index}.typeName]`, // @ts-ignore
        _state.slipLabels[index].typeName
      );
      formik.setFieldValue(
        `slipLabels[${index}.value]`, // @ts-ignore
        _state.slipLabels[index].value
      );
      formik.setFieldValue(
        `slipLabels[${index}.valueType]`, // @ts-ignore
        _state.slipLabels[index].valueType
      );
      formik.setFieldValue(
        `slipLabels[${index}.valueTypeName]`, // @ts-ignore
        _state.slipLabels[index].valueTypeName
      );
      formik.setFieldValue(`slipLabels[${index}.isEditable]`, false);
    },
    deleteDataIntoState: (index: number) => {
      // @ts-ignore
      const singleList = [...formik.values.slipLabels];
      const finalList = singleList.filter((item: any, i: number) => {
        return i !== index;
      });
      formik.setFieldValue("slipLabels", finalList);
    },
  };

  const formik = useFormik<UserObject>({
    initialValues,
    validationSchema: userSalarySlipDetailsSchema,
    onSubmit: async (values) => {
      let formObj: any = {
        slipLabels: [],
      };
      values?.slipLabels?.forEach((item) => {
        formObj.slipLabels.push({
          type: item?.type,
          label: item?.label,
          value: item?.value,
          valueType: item?.valueType,
        });
      });
      showLoader();
      const res = await updateUser(formObj, userId, hideLoader);
      if (res) {
        showToast(`User updated`, "success");
        getUser();
        hideLoader();
      }
    },
  });

  useEffect(() => {
    !userLoading && userId && fn.putUserValueInState();
  }, [userLoading]);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font16x600 textColor3">Salary Slip</p>
        </div>

        <div className="details_form_wrapper">
          <form onSubmit={formik.handleSubmit}>
            <div className="entry_table_wrapper">
              <div className="table_wrapper">
                <table className="table">
                  <thead className="table_head">
                    <tr className="font12x500 textColor3">
                      <th scope="col">Label</th>
                      <th scope="col">Type</th>
                      <th scope="col">Value</th>
                      <th scope="col">Value Type</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values.slipLabels?.map(
                      (slipLabel: any, i: number) => {
                        return slipLabel?.isEditable ? (
                          <tr className="font12x400">
                            <td>
                              <InputField
                                id="label"
                                type="text"
                                placeholder="Enter label"
                                state={slipLabel?.label}
                                setState={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  formik.setFieldValue(
                                    `slipLabels[${i}].label`,
                                    e.target.value
                                  );
                                }}
                                width="200px"
                              />
                            </td>
                            <td>
                              <DropdownOne
                                placeholder="Type"
                                name="typeName"
                                data={salaryTypeMap}
                                state={slipLabel?.typeName}
                                setState={(e?: string, x?: string | number) => {
                                  formik.setFieldValue(
                                    `slipLabels[${i}].typeName`,
                                    e
                                  );
                                  formik.setFieldValue(
                                    `slipLabels[${i}].type`,
                                    x
                                  );
                                }}
                                width="150px"
                              />
                            </td>
                            <td>
                              <InputField
                                id="value"
                                type="tel"
                                placeholder="0"
                                state={slipLabel?.value}
                                setState={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  formik.setFieldValue(
                                    `slipLabels[${i}].value`,
                                    e.target.value
                                  );
                                }}
                                width="150px"
                              />
                            </td>
                            <td>
                              <DropdownOne
                                placeholder="Value type"
                                name="valueTypeName"
                                data={salaryValueTypeMap}
                                state={slipLabel?.valueTypeName}
                                setState={(e?: string, x?: string | number) => {
                                  formik.setFieldValue(
                                    `slipLabels[${i}].valueTypeName`,
                                    e
                                  );
                                  formik.setFieldValue(
                                    `slipLabels[${i}].valueType`,
                                    x
                                  );
                                }}
                                width="150px"
                              />
                            </td>
                            <td>
                              <div
                                className="d-flex align-items-center cursor_pointer"
                                onClick={() =>
                                  fn.saveDataIntoState(slipLabel, i)
                                }
                              >
                                <BiSave
                                  style={{
                                    fontSize: 18,
                                    color: "var(--text2)",
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <tr key={i} className="font12x400">
                            <td>{slipLabel?.label}</td>
                            <td>{slipLabel?.typeName}</td>
                            <td>{slipLabel?.value}</td>
                            <td>{slipLabel?.valueTypeName}</td>
                            <td>
                              <div className="d-flex gap-2">
                                <Box
                                  bgColor="var(--btn_color1)"
                                  image="/Assets/Svg/edit.svg"
                                  onClickFn={() => {
                                    fn.updateDataIntoState(i);
                                  }}
                                />
                                <Box
                                  bgColor="var(--btn_color2)"
                                  image="/Assets/Svg/delete.svg"
                                  onClickFn={() => {
                                    fn.deleteDataIntoState(i);
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )}
                    <tr className="font12x400">
                      <td>
                        <InputField
                          id="label"
                          type="text"
                          placeholder="Enter label"
                          state={singleItem?.label}
                          setState={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setSingleItem({
                              ...singleItem,
                              label: e.target.value,
                            });
                          }}
                          width="200px"
                        />
                      </td>
                      <td>
                        <DropdownOne
                          placeholder="Type"
                          name="typeName"
                          data={salaryTypeMap}
                          state={singleItem?.typeName}
                          setState={(e?: string, x?: string | number) => {
                            setSingleItem({
                              ...singleItem,
                              typeName: e as string,
                              type: x as string,
                            });
                          }}
                          width="150px"
                        />
                      </td>
                      <td>
                        <InputField
                          id="value"
                          type="tel"
                          placeholder="0"
                          state={singleItem?.value}
                          setState={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setSingleItem({
                              ...singleItem,
                              value: e.target.value,
                            });
                          }}
                          width="150px"
                        />
                      </td>
                      <td>
                        <DropdownOne
                          placeholder="Value type"
                          name="valueTypeName"
                          data={salaryValueTypeMap}
                          state={singleItem?.valueTypeName}
                          setState={(e?: string, x?: string | number) => {
                            setSingleItem({
                              ...singleItem,
                              valueTypeName: e as string,
                              valueType: x as string,
                            });
                          }}
                          width="150px"
                        />
                      </td>
                      <td>
                        {singleItem?.label !== "" &&
                        singleItem?.value !== "" &&
                        singleItem?.valueType !== "" &&
                        singleItem?.type !== "" ? (
                          <div
                            className="btn"
                            style={{
                              width: 40,
                              height: "100%",
                              backgroundColor: "var(--bg_color2)",
                            }}
                            onClick={
                              // @ts-ignore
                              () => fn.addDataIntoState(singleItem)
                            }
                          >
                            <img
                              src="/Assets/Svg/plusIcon.svg"
                              alt="icon"
                              width={15}
                              height={15}
                            />
                          </div>
                        ) : (
                          <div
                            className="btn"
                            style={{
                              width: 40,
                              height: "100%",
                              backgroundColor: "var(--bg_color2)",
                              cursor: "no-drop",
                              opacity: 0.5,
                            }}
                          >
                            <img
                              src="/Assets/Svg/plusIcon.svg"
                              alt="icon"
                              width={15}
                              height={15}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {formik.touched.slipLabels && formik.errors.slipLabels ? (
              <InputError error={formik.errors.slipLabels} />
            ) : null}

            <div className="d-flex justify-content-end mt-5">
              <button className="btn font12x500 shadow-none custom_btn ms-3">
                {loading ? (
                  <SpinLoader height="20" width="20" color="#fff" />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UserSalarySlip;
