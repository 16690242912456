import moment from "moment";
import React, { useContext, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { onMessageListener } from "../../firebase";
import { getDefaultInitial } from "../../function/other";
import useLoading from "../../hooks/useLoading";
import {
  clearAllNotification,
  getNotification,
  updateNotification,
} from "../../lib/api functions/notification";
import { showToast } from "../../lib/toast/ShowToast";
import SpinLoader from "../../shared components/Loader/SpinLoader";
import { MainContext } from "../../utils/private_routes/PrivateRoutes";
import AllNotification from "./AllNotification";
import NotificationPopup from "./NotificationPopup";
import { ParamType } from "./type";
import OutsideClickHandler from "react-outside-click-handler";

const NotificationComponent = () => {
  const { userId } = useContext(MainContext);
  const [someNotification, setSomeNotification] = useState(false);
  const [allNotification, setAllNotification] = useState(false);
  const [notifications, setNotifications] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);
  const [loading, showLoader, hideLoader] = useLoading();
  const [clearAllloading, showLoader2, hideLoader2] = useLoading();
  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 7,
    toUser: userId,
    status: 1,
  });
  const [showFirebaseNoti, setShowFirebaseNoti] = useState<boolean>(false);
  const [singleNoti, setSingleNoti] = useState({});
  const audioPlayer = useRef<any>(null);

  const fn = {
    openAllNotification: () => {
      setAllNotification(true);
      setSomeNotification(false);
      document.body.style.overflow = "hidden";
    },
    openSomeNotification: () => {
      setSomeNotification(!someNotification);
      if (!someNotification) {
        fn.getNotifications(params);
      }
      if (someNotification) {
        setNotifications([]);
      }
    },
    getNotifications: async (params?: ParamType) => {
      showLoader();
      setNotifications([]);
      const res = await getNotification(params);
      if (res) {
        setNotifications((prev: any) => [...prev, ...res.data.notifications]);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
    updateNotification: async (
      notificationId: string,
      isRead: boolean,
      link: string
    ) => {
      let formObj = {
        isRead: true,
      };

      window.open(link);

      if (!isRead) {
        const res = await updateNotification(formObj, notificationId);
        if (res) {
          showToast("Notification read", "success");
        }
      }
    },
    playAudio: () => {
      audioPlayer.current.play();
    },
    clearAllNotifications: async () => {
      showLoader2();
      const res = await clearAllNotification();
      if (res) {
        showToast("All notifications cleared", "success");
        fn.getNotifications(params);
        hideLoader2();
      }
    },
  };

  onMessageListener()
    .then((payload: any) => {
      setShowFirebaseNoti(true);
      setSingleNoti({});
      setSingleNoti({
        title: payload.data.title,
        body: payload.data.body,
        createdAt: payload.data.createdAt,
        link: payload.data.link,
      });
      fn.playAudio();
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <>
      <audio
        ref={audioPlayer}
        src="/assets/Sound/notificationSound.mp3"
        autoPlay={false}
      />

      <OutsideClickHandler onOutsideClick={() => setSomeNotification(false)}>
        <div className="position-relative">
          <div
            className="icon_wrapper"
            onClick={() => fn.openSomeNotification()}
          >
            <div className="position-relative d-flex align-items-center">
              <img
                src="/Assets/Svg/notification.svg"
                alt="bell"
                width={22}
                height={22}
              />
              {/* <div className="bell_dot"></div> */}
            </div>
          </div>
          {someNotification && (
            <div className="notification_wrapper">
              <div className="notification_heading d-flex justify-content-between align-items-center">
                <span className="font12x500 textColor3">Notifications</span>
                <span
                  className="font12x500 textColor2 cursor_pointer"
                  onClick={fn.clearAllNotifications}
                  style={{
                    pointerEvents:
                      notifications?.length === 0 ? "none" : "unset",
                    color:
                      notifications?.length === 0
                        ? "var(--text5)"
                        : "var(--text2)",
                  }}
                >
                  {clearAllloading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Clear all"
                  )}
                </span>
              </div>
              <div className="notification_body d-flex flex-column">
                {notifications?.length !== 0 ? (
                  notifications?.slice(0, 4).map((item: any, i: number) => {
                    return (
                      <div
                        key={i}
                        className="single_notification d-flex gap-2 px-3 py-2 cursor_pointer"
                        onClick={() =>
                          fn.updateNotification(
                            item?._id,
                            item?.isRead,
                            item?.link
                          )
                        }
                      >
                        <div
                          className="d-flex gap-1"
                          style={{ height: "fit-content" }}
                        >
                          <div
                            className="unread_dot"
                            style={{
                              visibility: !item?.isRead ? "visible" : "hidden",
                            }}
                          ></div>
                          {!item?.fromUser?.image ? (
                            <span className="assignTo_section font10x500">
                              {getDefaultInitial(item?.fromUser?.name)}
                            </span>
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}${item?.fromUser?.image?.url}`}
                              alt="user"
                              width={28}
                              height={28}
                              style={{ borderRadius: "50%" }}
                            />
                          )}
                        </div>
                        <div>
                          <p className="mb-1 font12x400 textColor3">
                            {item?.body}
                          </p>
                          <p className="mb-0 font10x400 textColor1">
                            {moment(item?.createdAt).fromNow()}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : loading ? (
                  <>
                    <div className="d-flex gap-2 px-3 py-2">
                      <Skeleton width={28} height={28} borderRadius="50%" />
                      <div>
                        <Skeleton width={120} height={10} borderRadius="4px" />
                        <Skeleton width={40} height={7} borderRadius="4px" />
                      </div>
                    </div>
                    <div className="d-flex gap-2 px-3 py-2">
                      <Skeleton width={28} height={28} borderRadius="50%" />
                      <div>
                        <Skeleton width={120} height={10} borderRadius="4px" />
                        <Skeleton width={40} height={7} borderRadius="4px" />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="text-center font12x500 textColor1 py-3">
                    No notification found
                  </div>
                )}

                {notifications?.length !== 0 && (
                  <div className="text-center py-2">
                    <span
                      className="font12x500 textColor2 cursor_pointer"
                      onClick={() => fn.openAllNotification()}
                    >
                      View all
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}

          {showFirebaseNoti && (
            <NotificationPopup
              setShowFirebaseNoti={setShowFirebaseNoti}
              singleNoti={singleNoti}
            />
          )}
        </div>
      </OutsideClickHandler>

      {allNotification && (
        <AllNotification
          setState={setAllNotification}
          getNotification={fn.getNotifications}
          params={params}
          setParams={setParams}
          notifications={notifications}
          setNotifications={setNotifications}
          totalResult={totalResult}
          updateNotification={fn.updateNotification}
          clearAllNotifications={fn.clearAllNotifications}
          clearAllLoading={clearAllloading}
        />
      )}
    </>
  );
};

export default NotificationComponent;
