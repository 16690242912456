import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";

type PropType = {
  loading: any;
  paymentId?: string;
  payment: any;
  openReceivedPaymentForm: () => void;
  openMadePaymentForm: () => void;
};

const PaymentSidebar: React.FC<PropType> = (props) => {
  const {
    payment,
    loading,
    paymentId,
    openReceivedPaymentForm,
    openMadePaymentForm,
  } = props;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="">
        <div className="person_name_wrapper viewlead_content_header justify-content-between">
          <div
            className="cursor_pointer d-flex align-items-center"
            onClick={() => navigate("/payment")}
          >
            <img
              src="/Assets/Svg/backBtn.svg"
              alt="close"
              width={13}
              height={13}
            />
            <h3 className="mb-0 font14x600 textColor3 d-inline-block ms-2">
              Back
            </h3>
          </div>
          <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
            <div className="new_btn_wrapper" onClick={() => setIsOpen(!isOpen)}>
              <div>
                <button className="btn font12x500 buttonOne shadow-none">
                  <img
                    src="/Assets/Svg/plusIcon.svg"
                    alt="icon"
                    className="me-2"
                  />
                  New
                </button>
              </div>
              {isOpen && (
                <div className="new_btn_menus">
                  <div
                    className="menus cursor_pointer"
                    onClick={openReceivedPaymentForm}
                  >
                    <span className="font12x500">Payment Received</span>
                  </div>
                  <div
                    className="menus cursor_pointer"
                    onClick={openMadePaymentForm}
                  >
                    <span className="font12x500">Payment Made</span>
                  </div>
                </div>
              )}
            </div>
          </OutsideClickHandler>
        </div>
        <div className="view_doc_menu_wrapper">
          {loading ? (
            <>
              <Skeleton height="50px" borderRadius="4px" />
              <Skeleton height="50px" borderRadius="4px" />
              <Skeleton height="50px" borderRadius="4px" />
              <Skeleton height="50px" borderRadius="4px" />
            </>
          ) : (
            payment?.map((item: any, i: number) => {
              return (
                <div
                  key={i}
                  className={
                    paymentId === item?._id
                      ? "view_single_doc_menu_active"
                      : "view_single_doc_menu"
                  }
                  onClick={() => navigate(`/payment/view/${item?._id}`)}
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="mb-0 font12x500 textColor2">
                        {item?.type === 1
                          ? `${item?.invoice?.client?.name}`
                          : `${item?.expense?.vendor?.name}`}
                      </p>
                      <p className="mb-0 font10x400 textColor1">
                        {`${item?.number}`}
                      </p>
                    </div>
                    <div>
                      <p className="mb-0 font12x500 textColor1">
                        {`₹ ${item?.amount?.toLocaleString()}`}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentSidebar;
