import React, { useEffect, useRef, useState } from "react";
import { RiAttachment2 } from "react-icons/ri";
import { toast } from "react-toastify";

type proptype = {
  id: string;
  label: string;
  formik?: any;
  state?: any;
  setState?: (val: any) => void;
};

const SingleFileInput: React.FC<proptype> = (props) => {
  const { id, label, formik, state, setState } = props;

  const [fileName, setfileName] = useState<string[]>([]);
  const file = useRef<any>(null);

  const fn = {
    handleSingleFile: () => {
      file.current.click();
    },
    setFilePath: (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = e.target.files;
      //@ts-ignore
      let arrayFile = Array.from(selectedFile);
      if (
        selectedFile &&
        selectedFile.length <= 5 - formik.values.viewAttachments.length
      ) {
        formik.setFieldValue("attachments", Array.from(arrayFile));
        // arrayFile?.map((item: any, i: number) => {
        //   setfileName((prev: any) => [...prev, item?.name]);
        // });
        fn.setFilePathToViewAttachments(Array.from(selectedFile));
      } else {
        toast.error("Maximum limit is 5", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    },
    setFilePathToViewAttachments: (selectedFile: any) => {
      let viewAttachments = [...formik.values.viewAttachments];
      selectedFile?.forEach((file: any) => {
        viewAttachments.push(file);
        setfileName((prev: any) => [...prev, file?.name]);
      });
      formik.setFieldValue("viewAttachments", viewAttachments);
    },
    deleteFile: (index: number) => {
      let newDeleteObject = formik.values.viewAttachments[index];
      let newDeleteString = fileName[index];
      let filterData = fileName.filter((ele, i) => {
        return ele !== newDeleteString;
      });
      setfileName(filterData);

      if (formik.values.allAttachments.indexOf(newDeleteObject) > -1) {
        formik.setFieldValue("deleteAttachments", [
          ...formik.values.deleteAttachments,
          newDeleteObject,
        ]);
      }

      let filterData2 = formik.values.viewAttachments.filter(
        (ele: any, i: number) => {
          return ele !== newDeleteObject;
        }
      );
      formik.setFieldValue("viewAttachments", filterData2);
      let filterData3 = formik.values.attachments.filter((ele: any) => {
        return ele !== newDeleteObject;
      });
      formik.setFieldValue("attachments", filterData3);
    },
  };

  useEffect(() => {
    formik?.values?.viewAttachments?.length !== 0 &&
      formik?.values?.viewAttachments?.map((item: any) => {
        setfileName((prev: any) => [...prev, item?.filename]);
      });
  }, []);

  return (
    <>
      <label htmlFor={id} className="form-label font12x400 textColor3 mb-1">
        {label}
      </label>
      <div
        className="input_wrapper d-flex justify-content-center align-items-center p-0 cursor_pointer"
        onClick={fn.handleSingleFile}
      >
        <div className="input_click_button font12x500 textColor1">
          <RiAttachment2 className="me-1" />
          attach file
        </div>
        <input
          ref={file}
          type="file"
          className="form-control shadow-none input_field"
          id={id}
          accept={".pdf"}
          hidden
          onChange={(e) => {
            fn.setFilePath(e);
          }}
          multiple={true}
        />
      </div>
      {fileName?.length !== 0 &&
        fileName?.map((item: any, i: number) => {
          return (
            <div key={i} className="mt-2 d-flex gap-2 align-items-center">
              <p className="mb-0 font12x500 textColor2">{item}</p>
              <img
                src="/Assets/Svg/closeIcon.svg"
                width={20}
                className="cursor_pointer"
                alt="delete"
                onClick={() => fn.deleteFile(i)}
              />
            </div>
          );
        })}
    </>
  );
};

export default SingleFileInput;
