import Api from "../../config/api";
import {
  SEARCH_COMPANY,
  SEARCH_COMPANY_BY_ID,
  UPDATE_COMPANY,
} from "../../utils/constants";
import { showToast } from "../toast/ShowToast";

export const getCompany = async (params?: any) => {
  try {
    const res = await Api(false).get(SEARCH_COMPANY, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const getCompanyById = async (id?: string | null) => {
  try {
    const res = await Api(false).get(`${SEARCH_COMPANY_BY_ID}/${id}`);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const updateCompany = async (
  body: any,
  id: string,
  hideLoader: () => void
) => {
  try {
    const res = await Api(false).put(`${UPDATE_COMPANY}/${id}`, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};
