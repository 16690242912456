import React, { useContext, useEffect, useState } from "react";
import InputField from "../../../../shared components/TextInput";
import { CgClose } from "react-icons/cg";
import { commonType } from "../../../../pages/main/lead";
import UpdationDetail from "../../../Data Update Detail";
import TextareaInput from "../../../../shared components/TextareaInput";
import InputError from "../../../../shared components/InputError/InputError";
import { itemTypesMap } from "../../../../utils/constants";
import DropdownOne from "../../../../shared components/Dropdown/DropdownOne";
import { RequestObject } from "./Type";
import { useFormik } from "formik";
import { requestSchema } from "../../../../utils/validationSchema";
import DropdownTwo from "../../../../shared components/Dropdown/DropdownTwo";
import { getItem } from "../../../../lib/api functions/item";
import {
  createDemoRequest,
  updateDemoRequest,
} from "../../../../lib/api functions/demo_request";
import { showToast } from "../../../../lib/toast/ShowToast";
import useLoading from "../../../../hooks/useLoading";
import { ViewLeadContext } from "../../../../pages/main/lead/view lead";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall: (val: any) => void;
  params: any;
};

const Form: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params } = props;
  const { companyId } = useContext(MainContext);
  const [itemsList, setItemsList] = useState<any>([]);
  const [loading, showLoader, hideLoader] = useLoading();
  const { id, getActivity } = useContext(ViewLeadContext);
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "";
    },
    getItems: async (params?: any) => {
      const res = await getItem(params);
      if (res) {
        setItemsList(res.data.items);
      }
    },
  };

  const initialValues: RequestObject = {
    title: state?.data?.request?.title ?? "",
    notes: state?.data?.request?.notes ?? "",
    type: state?.data?.request?.type ?? 0,
    typeLabel: itemTypesMap.get(state?.data?.request?.type) ?? "",
    itemName: state?.data?.request?.item?.name ?? "",
    item: state?.data?.request?.item?._id ?? "",
    qty: state?.data?.request?.qty ?? "",
    status: state?.data?.request?.status,
  };

  const formik = useFormik<RequestObject>({
    initialValues,
    validationSchema: requestSchema,
    onSubmit: async (values) => {
      let finalObj = {};
      if (state?.data) {
        delete updatedValues.isObjChange;
        finalObj = { ...updatedValues, status: values.status };
      } else {
        finalObj = {
          lead: id,
          title: values.title,
          type: values.type,
          item: values.item,
          qty: values.qty,
          notes: values.notes,
        };
      }

      showLoader();
      if (state.data) {
        const res = await updateDemoRequest(
          finalObj,
          state.data.request._id,
          hideLoader
        );
        if (res) {
          showToast(`Demo request updated`, "success");
          fn.setPositionToBody();
          apiCall(params);
          getActivity({ lead: id });
          hideLoader();
        }
      } else {
        const res = await createDemoRequest(finalObj, hideLoader);
        if (res) {
          showToast(`Demo request created`, "success");
          fn.setPositionToBody();
          apiCall(params);
          getActivity({ lead: id });
          hideLoader();
        }
      }
    },
  });

  useEffect(() => {
    fn.getItems({ f: "name", company: companyId, isDeleted: false });
  }, []);

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">
              {state.data ? "Edit Request" : "New Request"}
            </h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <InputField
                    id="title"
                    label="Title*"
                    type="text"
                    state={formik.values.title}
                    setState={(e) => {
                      formik.setFieldValue("title", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        title: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <InputError error={formik.errors.title} />
                  ) : null}
                </div>
                <div className="mb-3">
                  <DropdownOne
                    label="Type*"
                    placeholder="Select Type"
                    name="typeLabel"
                    data={itemTypesMap}
                    state={formik.values.typeLabel}
                    setState={(e?: string, x?: string | number) => {
                      formik.setFieldValue("typeLabel", e);
                      formik.setFieldValue("type", x);
                      setUpdatedValues({
                        ...updatedValues,
                        type: x,
                        isObjChange: true,
                      });
                    }}
                    isDisabled={state?.data ? true : false}
                    onBlur={() => formik.setFieldTouched("typeLabel", true)}
                  />
                  {formik.touched.typeLabel && formik.errors.typeLabel ? (
                    <InputError error={formik.errors.typeLabel} />
                  ) : null}
                </div>

                <div className="container_fluid p-0">
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <DropdownTwo
                          label="Item*"
                          placeholder="Select item"
                          name="name"
                          data={itemsList}
                          state={formik.values.itemName}
                          setState={(e?: string, x?: string | number) => {
                            formik.setFieldValue("itemName", e);
                            formik.setFieldValue("item", x);
                            setUpdatedValues({
                              ...updatedValues,
                              item: x,
                              isObjChange: true,
                            });
                          }}
                          labelField="name"
                          onBlur={() =>
                            formik.setFieldTouched("itemName", true)
                          }
                        />
                        {formik.touched.itemName && formik.errors.itemName ? (
                          <InputError error={formik.errors.itemName} />
                        ) : null}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <InputField
                          id="qty"
                          label="Quantity*"
                          type="tel"
                          state={formik.values.qty}
                          setState={(e) => {
                            formik.setFieldValue("qty", e.target.value);
                            setUpdatedValues({
                              ...updatedValues,
                              qty: e.target.value,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.qty && formik.errors.qty ? (
                          <InputError error={formik.errors.qty} />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <TextareaInput
                    id="notes"
                    label="Notes"
                    state={formik.values.notes}
                    setState={(e) => {
                      formik.setFieldValue("notes", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        notes: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.notes && formik.errors.notes ? (
                    <InputError error={formik.errors.notes} />
                  ) : null}
                </div>
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>

                {state.data ? (
                  <button
                    className="btn font12x500 shadow-none custom_btn ms-3"
                    disabled={updatedValues?.isObjChange ? false : true}
                  >
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )}
                  </button>
                ) : (
                  <button className="btn font12x500 shadow-none custom_btn ms-3">
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Save"
                    )}
                  </button>
                )}
              </div>
            </form>

            {state.data && (
              <UpdationDetail
                lastUpdate={state?.data?.request?.updatedAt}
                updatedBy={state?.data?.request?.updatedBy}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
