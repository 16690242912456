import { useFormik } from "formik";
import { createContext, useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import Employee from "../../../../../components/Setting Components/MenuData/Employee/Employee";
import useLoading from "../../../../../hooks/useLoading";
import {
  getDocumentSettingById,
  updateDocumentSetting,
} from "../../../../../lib/api functions/setting/document";
import { showToast } from "../../../../../lib/toast/ShowToast";
import SpinLoader from "../../../../../shared components/Loader/SpinLoader";
import { MainContext } from "../../../../../utils/private_routes/PrivateRoutes";
import { employeeSchema } from "../../../../../utils/validationSchema";
import { EmployeeType } from "./type";

export const EmployeeContext = createContext<any>(null);

const SettingEmployee = () => {
  const { companyId, roles, setDocumentSetting } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [employeeLoading, showLoader2, hideLoader2] = useLoading();

  const initialValues: EmployeeType = {
    _id: "",
    maxHours: "",
    pLHours: "",
    numberOfPL: "",
    pLCarryForward: "",
  };

  const fn = {
    getEmployeeSetting: async (params?: any) => {
      showLoader2();
      const res = await getDocumentSettingById(companyId, params);
      if (res) {
        formik.setFieldValue("_id", res.data.documentSetting._id);
        if (res.data.documentSetting.employee) {
          formik.setFieldValue(
            "maxHours",
            res.data.documentSetting.employee.maxHours
          );
          formik.setFieldValue(
            "pLHours",
            res.data.documentSetting.employee.pLHours
          );
          formik.setFieldValue(
            "numberOfPL",
            res.data.documentSetting.employee.numberOfPL
          );
          formik.setFieldValue(
            "pLCarryForward",
            res.data.documentSetting.employee.pLCarryForward
          );
        }
        hideLoader2();
      }
    },
  };

  const formik = useFormik<EmployeeType>({
    initialValues,
    validationSchema: employeeSchema,
    onSubmit: async (values) => {
      const formObj = {
        employee: {
          maxHours: values.maxHours,
          pLHours: values.pLHours,
          numberOfPL: values.numberOfPL,
          pLCarryForward: values.pLCarryForward,
        },
      };
      showLoader();
      const res = await updateDocumentSetting(formObj, values._id, hideLoader);
      if (res) {
        setDocumentSetting(res.data.documentSetting);
        showToast(`Employee setting updated`, "success");
        hideLoader();
      }
    },
  });

  useEffect(() => {
    if (roles?.length !== 0) {
      fn.getEmployeeSetting({
        f: "employee",
        company: companyId,
      });
    }
  }, []);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font14x600 textColor3">Employee</p>
        </div>
        {employeeLoading ? (
          <>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={50} height={20} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={50} height={20} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={50} height={20} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={50} height={20} borderRadius={8} />
              </div>
            </div>
          </>
        ) : (
          <div>
            <form onSubmit={formik.handleSubmit}>
              <EmployeeContext.Provider
                value={{
                  formik,
                  employeeLoading,
                }}
              >
                <Employee />
              </EmployeeContext.Provider>

              <div className="d-flex justify-content-end my-4">
                <button className="btn font12x500 shadow-none custom_btn ms-3">
                  {loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default SettingEmployee;
