import React from "react";
type PropType = {
  label: string;
  htmlFor: string;
  name: string;
  value: number | string;
  state?: string | number;
  setState?: (e: string | number) => void;
  isDisabled?: boolean;
  onBlur?: any;
};

const RadioButton: React.FC<PropType> = (props) => {
  const { label, htmlFor, name, value, state, setState, isDisabled, onBlur } =
    props;

  return (
    <>
      <div className="radioCustom d-flex align-items-center mt-2">
        <input
          type="radio"
          id={htmlFor}
          name={name}
          value={value}
          onChange={(e) => {
            setState?.(e.target.value);
          }}
          checked={state === value ? true : false}
          disabled={isDisabled}
          style={{
            cursor: isDisabled ? "no-drop" : "pointer",
          }}
          onBlur={onBlur}
        />
        <label
          htmlFor={htmlFor}
          className="form-label mb-0 ps-2 font12x500 textColor3"
          style={{
            cursor: isDisabled ? "no-drop" : "pointer",
          }}
        >
          {label}
        </label>
      </div>
    </>
  );
};

export default RadioButton;
