import React from "react";
import { useNavigate } from "react-router-dom";

const UserNotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="d-flex justify-content-center align-items-center flex-column px-3 px-md-5">
        <h3 className="font20x400 textColor3">More users, Less problems!</h3>
        <p className="font10x400 textColor3">
          With role-based access, you can add your colleagues and your CA to
          your organization. You can give them special access to either sales,
          bills, everything or just the option to download reports.
        </p>
        <button
          className="btn font12x500 buttonOne shadow-none"
          onClick={() => navigate("/user/user-role/form")}
        >
          <img src="/Assets/Svg/plusIcon.svg" alt="icon" className="me-2" />
          Add a user to your organization
        </button>
        <img
          src="/Assets/Svg/userNotFound.svg"
          alt="not-found"
          width={350}
          draggable={false}
        />
      </div>
    </>
  );
};

export default UserNotFound;
