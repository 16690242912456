import { useContext, useRef, useState } from "react";
import { checkFileAllFormat, checkFileSize } from "../../function/other";
import useLoading from "../../hooks/useLoading";
import {
  deleteFile,
  updateUser,
  uploadFile,
} from "../../lib/api functions/user";
import { showToast } from "../../lib/toast/ShowToast";
import { UserContext } from "../../pages/main/user/add update user";

type PropType = {
  formik?: any;
  accept?: string;
  id?: string;
  multiple?: boolean;
  backendStateKey?: string;
  frontendStateKey?: string;
  setSingleFile?: (val: any) => void;
};

const FileInput: React.FC<PropType> = (props) => {
  const {
    formik,
    id,
    accept,
    multiple,
    backendStateKey,
    frontendStateKey,
    setSingleFile,
  } = props;
  const { userId, getUser } = useContext(UserContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const file = useRef<any>(null);
  const [singleFileError, setSingleFileError] = useState<string>("");

  const fn = {
    handleFileOpen: () => {
      file.current.click();
    },
    setFilePath: async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        if (!checkFileAllFormat(Array.from(e.target.files))) {
          setSingleFileError(
            "File format is incorrect. Supported format is PNG, JPEG, PDF, JPG"
          );
        } else if (!checkFileSize(Array.from(e.target.files))) {
          setSingleFileError("File size must be less then 1 mb");
        } else {
          setSingleFile?.(Array.from(e.target.files));
          fn.fileUpload(Array.from(e.target.files));
        }
      }
    },
    fileUpload: async (file: any) => {
      const formData = new FormData();
      // @ts-ignore
      formData.append("file", file[0]);
      const res = await uploadFile(formData, hideLoader);
      if (res) {
        fn.updateUser(res.data.file._id);
      }
    },
    updateUser: async (fileId: string) => {
      let finalObj = {
        [backendStateKey as string]: fileId,
      };
      const res = await updateUser(finalObj, userId, hideLoader);
      if (res) {
        showToast(`Document updated successfully.`, "success");
        setSingleFile?.(undefined);
        setSingleFileError("");
        getUser();
      }
    },
    deleteFile: async (id: string) => {
      formik.setFieldValue(frontendStateKey, "");
      formik.setFieldValue(backendStateKey, "");
      showLoader();
      const res = await deleteFile(id, hideLoader);
      if (res) {
        showToast(`Document deleted.`, "success");
        getUser();
      }
    },
    deleteFileTemp: async () => {
      setSingleFile?.(undefined);
      setSingleFileError("");
    },
  };

  return (
    <>
      <div className="mt-2 d-flex flex-wrap gap-2">
        {(frontendStateKey ||
          formik.values[backendStateKey ?? ""]?.length === 0) && (
          <div
            className="upload_doc_wrapper d-flex align-items-center"
            onClick={fn.handleFileOpen}
          >
            <img src="/Assets/Svg/open-folder.svg" alt="document" />
            <p className="mb-0 ms-2 font12x400 textColor5">Upload Document</p>
            <input
              ref={file}
              type="file"
              className="form-control shadow-none input_field"
              id={id}
              accept={accept}
              multiple={multiple}
              hidden
              onChange={(e) => {
                fn.setFilePath(e);
              }}
            />
          </div>
        )}
        {frontendStateKey
          ? formik.values[frontendStateKey ?? ""]?.length > 0 &&
            formik.values[frontendStateKey ?? ""]?.map(
              (item: any, i: number) => {
                return (
                  <div className="selected_doc_wrapper" key={i}>
                    <p className="mb-0 lh-lg font12x500 textColor2">
                      {item?.name}
                    </p>
                    <div className="crossFile" onClick={fn.deleteFileTemp}>
                      <img src="/Assets/Svg/crossFile.svg" alt="cross" />
                    </div>
                  </div>
                );
              }
            )
          : formik.values[backendStateKey ?? ""]?.length > 0 &&
            formik.values[backendStateKey ?? ""]?.map(
              (item: any, i: number) => {
                return (
                  <div className="selected_doc_wrapper" key={i}>
                    <a
                      className="text-decoration-none"
                      href={`${process.env.REACT_APP_IMAGE_URL}${item?.url}`}
                      target="_blank"
                    >
                      <p className="mb-0 lh-lg font12x500 textColor2">
                        {item?.name}
                      </p>
                    </a>
                    <div
                      className="crossFile"
                      onClick={() => fn.deleteFile(item?._id)}
                    >
                      <img src="/Assets/Svg/crossFile.svg" alt="cross" />
                    </div>
                  </div>
                );
              }
            )}
      </div>
      {singleFileError?.length > 0 && (
        <p className="errorDanger mt-1 mb-0">{singleFileError}</p>
      )}
    </>
  );
};

export default FileInput;
