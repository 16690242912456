import React, { useContext, useEffect, useRef, useState } from "react";
import { getDefaultInitial } from "../../../../function/other";
import useLoading from "../../../../hooks/useLoading";
import {
  deleteFile,
  updateUser,
  uploadFile,
} from "../../../../lib/api functions/user";
import { showToast } from "../../../../lib/toast/ShowToast";
import { UserContext } from "../../../../pages/main/user/add update user";
import InputError from "../../../../shared components/InputError/InputError";

type PropType = {
  userId: string;
  userLoading: boolean;
  formik: any;
  state: any;
};

const ProfilePhoto: React.FC<PropType> = (props) => {
  const { userId, userLoading, formik, state } = props;
  const { getUser } = useContext(UserContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const file = useRef<any>(null);
  const [imagePath, setImagePath] = useState("");

  useEffect(() => {
    setImagePath(
      state ? `${process.env.REACT_APP_IMAGE_URL}${state?.url}` : ""
    );
  }, [state]);

  const fn = {
    handleSingleFile: () => {
      file.current.click();
    },
    setFilePath: async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        setImagePath(URL.createObjectURL(e.target.files[0]));
        formik.setFieldValue("image", Array.from(e.target.files));
        fn.fileUpload(Array.from(e.target.files));
      }
    },
    deleteFile: async (imageId: string) => {
      formik.setFieldValue("image", "");
      setImagePath("");
      showLoader();
      const res = await deleteFile(imageId, hideLoader);
      if (res) {
        showToast(`Profile photo deleted.`, "success");
      }
    },
    fileUpload: async (image: any) => {
      showLoader();
      const formData = new FormData();
      // @ts-ignore
      formData.append("file", image[0]);
      const res = await uploadFile(formData, hideLoader);
      if (res) {
        fn.updateUser(res.data.file._id);
        getUser();
      }
    },
    updateUser: async (imageId: string) => {
      let finalObj = {
        image: imageId,
      };
      const res = await updateUser(finalObj, userId, hideLoader);
      if (res) {
        showToast(`Profile photo uploaded successfully.`, "success");
      }
    },
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center flex-column mb-4 mb-lg-0">
        <div className="mb-4">
          <h3 className="font14x600 textColor3 mb-0">Profile Photo</h3>
        </div>

        {userId ? (
          formik.values.image ? (
            <>
              <div
                style={{
                  border: "2px dotted var(--border_color4)",
                  padding: 20,
                  textAlign: "center",
                  borderRadius: 8,
                }}
              >
                <div
                  className="mb-2 text-end"
                  onClick={() => {
                    fn.deleteFile(formik.values.image?._id);
                  }}
                >
                  <img
                    src="/Assets/Svg/deleteColor.svg"
                    alt="close"
                    className="img-fluid cursor_pointer"
                    draggable={false}
                  />
                </div>
                <div className="has_profile_photo font96x600">
                  <img
                    src={imagePath}
                    alt="profile_image"
                    draggable={false}
                    width={150}
                    height={150}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  border: "2px dotted var(--border_color4)",
                  padding: 20,
                  textAlign: "center",
                  borderRadius: 8,
                }}
              >
                <div
                  className="has_profile_photo font96x600"
                  style={{
                    backgroundColor: "var(--bg_color2)",
                  }}
                >
                  {getDefaultInitial(formik.values.name ?? "")}
                </div>
                <p
                  className="mb-0 mt-2 font12x500 textColor2 cursor_pointer"
                  onClick={fn.handleSingleFile}
                >
                  Upload
                </p>
                <input
                  ref={file}
                  type="file"
                  className="form-control shadow-none input_field"
                  id="image"
                  accept={".jpeg, .png"}
                  hidden
                  onChange={(e) => {
                    fn.setFilePath(e);
                  }}
                />
              </div>
            </>
          )
        ) : (
          ""
        )}

        {!userId && (
          <div className="profile_image_wrapper">
            {!imagePath && (
              <div className="d-flex justify-content-center align-items-center flex-column">
                <img
                  src="/Assets/browseImage.png"
                  alt="browseImage"
                  draggable={false}
                />
                <p className="mb-0 font12x600 text-center">
                  <span
                    className="textColor2 cursor_pointer"
                    onClick={fn.handleSingleFile}
                  >
                    Upload
                  </span>
                </p>
                <input
                  ref={file}
                  type="file"
                  className="form-control shadow-none input_field"
                  id="image"
                  accept={".jpeg, .png"}
                  hidden
                  onChange={(e) => {
                    fn.setFilePath(e);
                  }}
                />
              </div>
            )}

            {imagePath && (
              <div>
                <div
                  className="mb-2 text-end"
                  onClick={() => {
                    fn.deleteFile(formik.values.image?._id);
                  }}
                >
                  <img
                    src="/Assets/Svg/deleteColor.svg"
                    alt="close"
                    className="img-fluid cursor_pointer"
                    draggable={false}
                  />
                </div>
                <div className="select_profile_image">
                  <img
                    src={imagePath}
                    alt="browseImage"
                    draggable={false}
                    width={150}
                    height={150}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {formik.errors.image ? (
          <InputError error={formik.errors.image} />
        ) : null}
      </div>
    </>
  );
};

export default ProfilePhoto;
