import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { gettingCities, gettingStates } from "../../function/other";
import useLoading from "../../hooks/useLoading";
import { createClient } from "../../lib/api functions/client";
import { showToast } from "../../lib/toast/ShowToast";
import { LeadContext } from "../../pages/main/lead/new lead";
import { CompleteAddress } from "../../pages/main/lead/new lead/type";
import DropdownOne from "../../shared components/Dropdown/DropdownOne";
import InputError from "../../shared components/InputError/InputError";
import SpinLoader from "../../shared components/Loader/SpinLoader";
import InputField from "../../shared components/TextInput";
import { countryMap } from "../../utils/constants";
import { leadStepTwoSchema } from "../../utils/validationSchema";

type propType = {
  newClient?: any;
  setNewClient: (val?: any) => void;
  setClientId: (val?: string) => void;
};

const BillingAddressForm: React.FC<propType> = (props) => {
  const { newClient, setNewClient, setClientId } = props;
  const { fn, initialValuesAddress } = useContext(LeadContext);
  const [updatedValues, setUpdatedValues] = useState<any>({
    ...newClient,
    isObjChange: false,
  });

  const [cities, setCities] = useState<string[]>([]);
  const [states, setStates] = useState<string[]>([]);
  const [loading, showLoader, hideLoader] = useLoading();

  const formik = useFormik<CompleteAddress>({
    initialValues: initialValuesAddress,
    validationSchema: leadStepTwoSchema,
    onSubmit: async (values) => {
      // setNewClient({ ...newClient, ...values });
      setNewClient({ ...newClient, ...updatedValues });

      setUpdatedValues({ ...updatedValues, ...newClient });

      delete updatedValues.isObjChange;
      let finalObj = {
        // name: newClient.name,
        // contactNumber: newClient.contactNumber,
        // phoneCode: newClient.phoneCode,
        // email: newClient.email,
        // website: newClient.website,
        // gstType: newClient.gstType,
        // gstin: newClient.gstin,
        // pan: newClient.pan,
        // currency: newClient.currency,
        ...updatedValues,
        type: newClient.type,
        completeAddress: {
          address: values.address,
          state: values.state,
          city: values.city,
          pincode: values.pincode,
          country: values.country,
          sCode: values.extra.stateCode,
          cCode: values.extra.countryCode,
        },
      };

      // let finalObj = {
      //   type: newClient.type,
      //   ...updatedValues,
      // };

      showLoader();
      const res = await createClient(finalObj, hideLoader);
      if (res) {
        showToast(`Client created`, "success");
        setClientId(res.data.client._id);
        fn.nextStep();
        hideLoader();
      }
    },
  });

  useEffect(() => {
    setStates(gettingStates(formik.values.extra.countryCode));
  }, [formik.values.extra.countryCode]);

  useEffect(() => {
    setCities(
      gettingCities(
        formik.values.extra.countryCode,
        formik.values.extra.stateCode
      )
    );
  }, [formik.values.extra.stateCode]);

  return (
    <>
      <div className="details_form_wrapper p-3">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="font16x600 textColor3 mb-0">Billing Address</h3>
          <div className="step_wrapper font12x400 textColor3">2 of 3</div>
        </div>

        {/* form */}
        <div className="mt-4">
          <form onSubmit={formik.handleSubmit}>
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="mb-3">
                    <InputField
                      id="address"
                      label="Address"
                      type="text"
                      state={formik.values.address}
                      setState={(e) => {
                        formik.setFieldValue("address", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,

                          completeAddress: {
                            ...updatedValues.completeAddress,
                            address: e.target.value,
                          },
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <InputError error={formik.errors.address} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <DropdownOne
                      label="Country*"
                      placeholder="Select country"
                      name="country"
                      data={countryMap}
                      state={formik.values.country}
                      setState={(e?: string, x?: string | number) => {
                        formik.setFieldValue("country", e);
                        formik.setFieldValue("extra.countryCode", x);
                        formik.setFieldValue("state", "");
                        formik.setFieldValue("extra.stateCode", "");
                        formik.setFieldValue("city", "");
                        setUpdatedValues({
                          ...updatedValues,

                          completeAddress: {
                            ...updatedValues.completeAddress,
                            country: e,
                          },
                          isObjChange: true,
                        });
                      }}
                      onBlur={() => {
                        formik.setFieldTouched("country", true);
                      }}
                    />
                    {formik.touched.country && formik.errors.country ? (
                      <InputError error={formik.errors.country} />
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="mb-3">
                    <DropdownOne
                      label="State*"
                      placeholder="Select State"
                      name="state"
                      data={states}
                      state={formik.values.state}
                      setState={(e?: string, x?: string | number) => {
                        formik.setFieldValue("state", e);
                        formik.setFieldValue("extra.stateCode", x);
                        formik.setFieldValue("city", "");
                        setUpdatedValues({
                          ...updatedValues,

                          completeAddress: {
                            ...updatedValues.completeAddress,
                            state: e,
                          },
                          isObjChange: true,
                        });
                      }}
                      onBlur={() => {
                        formik.setFieldTouched("state", true);
                      }}
                    />
                    {formik.touched.state && formik.errors.state ? (
                      <InputError error={formik.errors.state} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <DropdownOne
                      label="City"
                      placeholder="Select City"
                      name="city"
                      data={cities}
                      state={formik.values.city}
                      setState={(e?: string) => {
                        formik.setFieldValue("city", e);
                        setUpdatedValues({
                          ...updatedValues,

                          completeAddress: {
                            ...updatedValues.completeAddress,
                            city: e,
                          },
                          isObjChange: true,
                        });
                      }}
                      onBlur={() => {
                        formik.setFieldTouched("city", true);
                      }}
                    />
                    {formik.touched.city && formik.errors.city ? (
                      <InputError error={formik.errors.city} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="pincode"
                      label="Pin Code"
                      type="tel"
                      state={formik.values.pincode}
                      setState={(e) => {
                        formik.setFieldValue("pincode", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,

                          completeAddress: {
                            ...updatedValues.completeAddress,
                            pincode: e.target.value,
                          },
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.pincode && formik.errors.pincode ? (
                      <InputError error={formik.errors.pincode} />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-flex justify-content-end mt-4">
                  <button
                    className="btn font12x500 shadow-none custom_btn_outline"
                    onClick={fn.prevStep}
                  >
                    Back
                  </button>

                  <button
                    type="submit"
                    className="btn font12x500 shadow-none ms-3 custom_btn"
                  >
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Next"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BillingAddressForm;
