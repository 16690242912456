import { useContext, useEffect, useState } from "react";
import DataNotFound from "../../../../../components/Data Not Found";
import Form from "../../../../../components/Setting Components/MenuData/Department/Form";
import useLoading from "../../../../../hooks/useLoading";
import { getDepartment } from "../../../../../lib/api functions/setting/department";
import SkeletonLoader from "../../../../../shared components/Loader/SkeletonLoader";
import { MainContext } from "../../../../../utils/private_routes/PrivateRoutes";
import { commonType } from "../../../lead";
import { ParamType } from "./type";

const SettingDepartment = () => {
  const { userId, companyId, roles } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const [loading, showLoader, hideLoader] = useLoading();
  const [departments, setDepartments] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [params, setParams] = useState<ParamType>({
    // page: 1,
    // per_page: 10,
    company: companyId,
  });

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getDepartments: async (params?: ParamType) => {
      showLoader();
      const res = await getDepartment(params);
      if (res) {
        setDepartments(res.data.departments);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
  };

  useEffect(() => {
    if (roles?.length !== 0) {
      fn.getDepartments(params);
    }
  }, []);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font14x600 textColor3">Departments</p>
          <button
            className="btn font12x500 buttonOne shadow-none"
            onClick={() => fn.openModal(undefined, "add_department")}
          >
            <img src="/Assets/Svg/plusIcon.svg" alt="icon" className="me-2" />
            New
          </button>
        </div>

        {departments?.length !== 0 ? (
          <div className="entry_table_wrapper">
            <div className="table_wrapper">
              <table className="table">
                <thead className="table_head">
                  <tr className="font12x500 textColor3">
                    <th scope="col">Department</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {departments?.map((department: any, i: number) => {
                    return (
                      <tr key={i} className="font12x400">
                        <td>{department?.name}</td>
                        <td>
                          <div className="d-flex gap-2">
                            <div
                              className="d-flex align-items-center cursor_pointer"
                              onClick={() =>
                                fn.openModal({ department }, "edit_department")
                              }
                            >
                              <img src="/Assets/Svg/editIcon.svg" alt="edit" />
                              <p className="mb-0 font12x400 textColor2 ms-1">
                                Edit
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : loading ? (
          <SkeletonLoader />
        ) : (
          <DataNotFound />
        )}
      </div>

      {/* form modal */}
      {commonData?.modal &&
        (commonData?.check === "edit_department" ||
          commonData?.check === "add_department") && (
          <Form
            state={commonData}
            setState={setCommonData}
            apiCall={fn.getDepartments}
            params={params}
          />
        )}
    </>
  );
};

export default SettingDepartment;
