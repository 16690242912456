import moment from "moment";
import { useContext, useEffect, useState } from "react";
import DataNotFound from "../../../components/Data Not Found";
import Pagination from "../../../components/Pagination";
import Form from "../../../components/Reminder Components/Form";
import KebabMenu from "../../../components/Reminder Components/KebabMenu";
import useLoading from "../../../hooks/useLoading";
import { getReminder } from "../../../lib/api functions/reminder";
import SpinLoader from "../../../shared components/Loader/SpinLoader";
import Search from "../../../shared components/Search";
import {
  DELETE_REMINDER,
  PRIMARY_COLOR,
  taskPriority,
} from "../../../utils/constants";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import { commonType } from "../lead";
import { getPriorityColor } from "../task";
import { ParamType } from "./type";

const Reminder = () => {
  const { userId, companyId, setting } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });

  const [loading, showLoader, hideLoader] = useLoading();
  const [reminders, setReminders] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    company: companyId,
    user: userId,
    isDeleted: false,
  });

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getReminders: async (params?: ParamType) => {
      showLoader();
      const res = await getReminder(params);
      if (res) {
        setReminders(res.data.reminders);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
  };

  useEffect(() => {
    fn.getReminders(params);
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="data_render_wrapper">
          {/* filters */}
          <div className="filter_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-12 col-md-4">
                  <Search
                    placeholder="Search reminders"
                    state={params}
                    setState={setParams}
                    apiCall={fn.getReminders}
                    paramValue="title"
                  />
                </div>
                <div className="col-12 col-md-8 pt-3 pt-md-0">
                  <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                    <div className="d-flex gap-2">
                      <button
                        className="btn font12x500 buttonOne shadow-none"
                        onClick={() => fn.openModal(undefined, "add_reminder")}
                      >
                        <img
                          src="/Assets/Svg/plusIcon.svg"
                          alt="icon"
                          className="me-2"
                        />
                        Add New Reminder
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="list_view_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center pb-3">
                <div className="col-12 col-lg-3">
                  <span className="font16x600 textColor3">
                    List of Reminders
                  </span>
                </div>
              </div>
            </div>

            {/* table */}
            {reminders?.length !== 0 ? (
              <>
                {reminders?.map((reminder: any, i: number) => {
                  return (
                    <div key={i} className="card_wrapper mb-3">
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <img
                            src={`/Assets/Svg/${
                              reminder?.priority === 0
                                ? "reminderLow.svg"
                                : reminder?.priority === 1
                                ? "reminderMedium.svg"
                                : "reminderHigh.svg"
                            }`}
                            alt="icon"
                            className="me-2"
                          />
                          <h3 className="mb-0 font12x500 textColor3">
                            Reminder
                          </h3>
                        </div>
                        <KebabMenu
                          openFormModal={() =>
                            fn.openModal(reminder, "edit_reminder")
                          }
                          label="Reminder"
                          dataIds={{ reminderIds: [reminder?._id] }}
                          url={DELETE_REMINDER}
                          apiCall={fn.getReminders}
                          params={params}
                        />
                      </div>
                      <div className="card_body">
                        <p className="mb-2 font12x600 textColor3">
                          {reminder?.title}
                        </p>
                        <p className="mb-2 font12x400 textColor1">
                          {reminder?.taskDescription}
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="priority_div font10x600"
                            style={{
                              backgroundColor: getPriorityColor(
                                reminder?.priority
                              ),
                            }}
                          >
                            {taskPriority[reminder?.priority]?.label}
                          </span>
                          <div className="d-flex">
                            <p className="mb-0 font10x400 textColor3">
                              {moment(reminder?.createdAt).format("hh:mm a")},
                            </p>
                            <p className="mb-0 ms-2 font10x400 textColor3">
                              {moment(reminder?.createdAt).format(
                                setting?.dateFormat
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : loading ? (
              <SpinLoader height="40" width="40" color={PRIMARY_COLOR} />
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>

        {/* pagination */}
        {reminders?.length !== 0 && (
          <div className="pagination_wrapper pt-3 px-3">
            <Pagination
              currentPage={params.page}
              onPageChange={(e) => {
                fn.getReminders({ ...params, page: e });
                setParams({
                  ...params,
                  page: e,
                });
              }}
              pageSize={params.per_page}
              totalCount={Math.ceil(totalResult / params.per_page)}
              onChangePerPage={(e) => {
                fn.getReminders({ ...params, per_page: e });
                setParams({
                  ...params,
                  per_page: e,
                });
              }}
            />
          </div>
        )}
      </div>

      {/* form modal */}
      {commonData?.modal &&
        (commonData?.check === "add_reminder" ||
          commonData?.check === "edit_reminder") && (
          <Form
            state={commonData}
            setState={setCommonData}
            apiCall={fn.getReminders}
            params={params}
          />
        )}
    </>
  );
};

export default Reminder;
