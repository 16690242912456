import React, { useState } from "react";
import { BiSort } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { userSort } from "../utils/constants";

type propType = {
  state: any;
  apiCall: (val: any) => void;
  setState: (val: any) => void;
};

const SortData: React.FC<propType> = (props) => {
  const { state, apiCall, setState } = props;

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const fn = {
    setFilterState: () => {
      setIsFilterOpen(!isFilterOpen);
    },
    addParam: (value?: string) => {
      setState({
        ...state,
        sort_order: value,
      });
      apiCall?.({ ...state, page: 1, sort_order: value });
    },
  };

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setIsFilterOpen(false)}>
        <div className="position-relative">
          <div
            className="filter_section_wrapper"
            onClick={fn.setFilterState}
            style={{
              backgroundColor: isFilterOpen ? "#fff" : "var(--bg_color2)",
              border: isFilterOpen ? "1px solid var(--border_color4)" : "none",
            }}
          >
            {isFilterOpen ? (
              <img src="/Assets/Svg/filterClose.svg" alt="filter" />
            ) : (
              <BiSort className="text-white" />
            )}
          </div>

          {isFilterOpen && (
            <div
              className="filter_option_wrapper"
              style={{
                minWidth: "137px",
              }}
            >
              <div className="p-2 pb-0">
                <p className="mb-0 font12x500 textColor1">Sort by: User ID</p>
              </div>
              <div className="p-2 pt-0">
                {userSort?.map((item: any, i: number) => {
                  return (
                    <div className="radioCustom d-flex align-items-center mt-2">
                      <input
                        type="radio"
                        className="me-2"
                        id={item?.label}
                        value={item?.value}
                        name="userId"
                        checked={
                          state.sort_order === item?.value ? true : false
                        }
                        onChange={(e) => {
                          fn.addParam(e.target.value);
                        }}
                      />
                      <label
                        className="font12x500 textColor1 form-check-label"
                        htmlFor={item?.label}
                      >
                        {item?.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default SortData;
