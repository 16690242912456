import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Activities from "../../../../components/View Lead Components/Activities";
import MenuBar from "../../../../components/View Lead Components/Menubar";
import MenuData from "../../../../components/View Lead Components/MenuData";
import useLoading from "../../../../hooks/useLoading";
import { getActivity } from "../../../../lib/api functions/lead/activity";
import { getLeadById } from "../../../../lib/api functions/lead/lead";
import { getPO } from "../../../../lib/api functions/lead/po";
import {
  ADD_EDIT_PROPOSAL,
  VIEW_PROPOSAL_PAGE,
} from "../../../../utils/constants";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import AddUpdateProposal from "./sub pages/proposal/add update proposal";
import ViewProposalPage from "./sub pages/proposal/view proposal";

type propType = {
  routeName?: string;
};

export const ViewLeadContext = createContext<any>(null);

const ViewLead: React.FC<propType> = ({ routeName }) => {
  const { setPageTitle, companyId } = useContext(MainContext);
  const { id } = useParams();
  const [loading, showLoader, hideLoader] = useLoading();
  const [activityLoading, showLoader2, hideLoader2] = useLoading();
  const [lead, setLead] = useState<any>([]);
  const [po, setPO] = useState<any>([]);
  const [activity, setActivity] = useState<any>([]);

  const fn = {
    getLeadById: async () => {
      showLoader();
      const res = await getLeadById(id);
      if (res) {
        setLead(res.data.lead);
        hideLoader();
      }
    },
    getActivity: async (params: any) => {
      showLoader2();
      const res = await getActivity(params);
      if (res) {
        setActivity(res.data.activities);
        hideLoader2();
      }
    },
    getPOs: async (params: any) => {
      const res = await getPO(params);
      if (res) {
        setPO(res.data.purchaseOrders);
      }
    },
  };

  useEffect(() => {
    fn.getLeadById();
    fn.getActivity({
      lead: id,
    });
    fn.getPOs({
      lead: id,
    });
    setPageTitle("Lead View");
  }, []);

  return (
    <>
      <ViewLeadContext.Provider
        value={{
          id,
          personName: lead?.name,
          loading,
          lead,
          activityLoading,
          activity,
          getLeadById: fn.getLeadById,
          getPOs: fn.getPOs,
          getActivity: fn.getActivity,
          po,
        }}
      >
        {routeName === VIEW_PROPOSAL_PAGE ? (
          <ViewProposalPage />
        ) : (
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3 pe-lg-2">
                <MenuBar />
              </div>

              {routeName === ADD_EDIT_PROPOSAL ? (
                <AddUpdateProposal />
              ) : (
                <>
                  <div className="col-12 col-md-8 col-lg-6 px-lg-2">
                    <MenuData routeName={routeName ?? ""} />
                  </div>
                  <div className="col-12 col-lg-3 ps-lg-2">
                    <Activities />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </ViewLeadContext.Provider>
    </>
  );
};

export default ViewLead;
