import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MenuBar from "../../../../components/User Components/Menubar";
import MenuData from "../../../../components/User Components/MenuData";
import useLoading from "../../../../hooks/useLoading";
import { getUserById } from "../../../../lib/api functions/user";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";

type propType = {
  routeName?: string;
};

export const UserContext = createContext<any>(null);

const AddUpdateUser: React.FC<propType> = ({ routeName }) => {
  const { setPageTitle } = useContext(MainContext);
  const { userId } = useParams();
  const [userLoading, showLoader2, hideLoader2] = useLoading();
  const [user, setUser] = useState<any>([]);

  const fn = {
    getUserById: async () => {
      const params = {
        type: "c",
      };
      showLoader2();
      const res = await getUserById(userId, params);
      if (res) {
        setUser(res.data.user);
        hideLoader2();
      }
    },
  };

  useEffect(() => {
    setPageTitle(userId ? "Update User" : "Add New User");
  }, []);
  useEffect(() => {
    if (userId) {
      fn.getUserById();
    }
  }, [userId]);

  return (
    <>
      <UserContext.Provider
        value={{
          routeName,
          userLoading,
          userId,
          user,
          getUser: fn.getUserById,
        }}
      >
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3 pe-lg-2">
              <MenuBar />
            </div>
            <div className="col-12 col-md-8 col-lg-9 px-lg-2">
              <MenuData />
            </div>
          </div>
        </div>
      </UserContext.Provider>
    </>
  );
};

export default AddUpdateUser;
