import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { LeadContext } from "../../pages/main/lead/new lead";
import { ClientObject } from "../../pages/main/lead/new lead/type";
import DropdownOne from "../../shared components/Dropdown/DropdownOne";
import PhoneCode from "../../shared components/Dropdown/PhoneCode";
import InputError from "../../shared components/InputError/InputError";
import InputField from "../../shared components/TextInput";
import {
  BOTH_CLIENT_VENDOR,
  currenciesMap,
  gstTypesMap,
  ONLY_CLIENT,
} from "../../utils/constants";
import { leadStepOneSchema } from "../../utils/validationSchema";

type propType = {
  newClient?: any;
  setNewClient: (val?: any) => void;
};

const CompanyDetailForm: React.FC<propType> = (props) => {
  const { newClient, setNewClient } = props;
  const { fn, initialValuesClient } = useContext(LeadContext);
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });

  const formik = useFormik<ClientObject>({
    initialValues: newClient ? newClient : initialValuesClient,
    validationSchema: leadStepOneSchema,
    onSubmit: async (values) => {
      // setNewClient({ ...values });
      setNewClient({ ...updatedValues, type: values.type });
      fn.nextStep();
    },
  });

  return (
    <>
      <div className="details_form_wrapper p-3">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="font16x600 textColor3 mb-0">Company Details</h3>
          <div className="step_wrapper font12x400 textColor3">1 of 3</div>
        </div>

        {/* form */}
        <div className="mt-4">
          <form onSubmit={formik.handleSubmit}>
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="mb-3">
                    <InputField
                      id="name"
                      label="Company Name*"
                      type="text"
                      state={formik.values.name}
                      setState={(e) => {
                        formik.setFieldValue("name", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          name: e.target.value,
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <InputError error={formik.errors.name} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="email"
                      label="Email"
                      type="text"
                      state={formik.values.email}
                      setState={(e) => {
                        formik.setFieldValue("email", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          email: e.target.value,
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <InputError error={formik.errors.email} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <DropdownOne
                      label="GST Type*"
                      placeholder="Select GST type"
                      name="gstType"
                      data={gstTypesMap}
                      state={formik.values.gstType}
                      setState={(e?: string) => {
                        formik.setFieldValue("gstType", e);
                        setUpdatedValues({
                          ...updatedValues,
                          gstType: e,
                          isObjChange: true,
                        });
                      }}
                      onBlur={() => {
                        formik.setFieldTouched("gstType", true);
                      }}
                    />
                    {formik.touched.gstType && formik.errors.gstType ? (
                      <InputError error={formik.errors.gstType} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="pan"
                      label="PAN"
                      type="text"
                      state={formik.values.pan}
                      setState={(e) => {
                        formik.setFieldValue("pan", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          pan: e.target.value,
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.pan && formik.errors.pan ? (
                      <InputError error={formik.errors.pan} />
                    ) : null}
                  </div>

                  <div className="customCheck form-check d-flex align-items-center p-0">
                    <input
                      type="checkbox"
                      className="me-2"
                      id="type"
                      name="type"
                      checked={
                        formik.values.type === BOTH_CLIENT_VENDOR ? true : false
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.target.checked
                          ? formik.setFieldValue("type", BOTH_CLIENT_VENDOR)
                          : formik.setFieldValue("type", ONLY_CLIENT);
                      }}
                    />
                    <label
                      className="font12x500 textColor3 form-check-label cursor_pointer"
                      htmlFor="type"
                    >
                      Use this also as vendor
                    </label>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="contactNumber"
                      className="form-label font12x400 textColor3 mb-1"
                    >
                      Company Contact Number
                    </label>
                    <div className="input-group">
                      <PhoneCode
                        formik={formik}
                        updatedValues={updatedValues}
                        setUpdatedValues={setUpdatedValues}
                        fieldName="phoneCode"
                        fieldName2="contactNumber"
                      />
                      <input
                        type="text"
                        className="form-control shadow-none font12x600 border-0"
                        id="contactNumber"
                        value={formik.values.contactNumber}
                        onChange={(e) => {
                          formik.setFieldValue("contactNumber", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            contactNumber: e.target.value,
                            isObjChange: true,
                          });
                        }}
                        onBlur={formik.handleBlur}
                        autoComplete="new-contactNumber"
                      />
                    </div>
                    {formik.touched.contactNumber &&
                    formik.errors.contactNumber ? (
                      <InputError error={formik.errors.contactNumber} />
                    ) : null}
                    {formik.touched.phoneCode && formik.errors.phoneCode ? (
                      <InputError error={formik.errors.phoneCode} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <InputField
                      placeholder="eg: https://www.example.com"
                      id="website"
                      label="Website"
                      type="text"
                      state={formik.values.website}
                      setState={(e) => {
                        formik.setFieldValue("website", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          website: e.target.value,
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.website && formik.errors.website ? (
                      <InputError error={formik.errors.website} />
                    ) : null}
                  </div>

                  {!(
                    formik.values.gstType === "Unregistered Business" ||
                    formik.values.gstType === "Registered - Comp. Scheme" ||
                    formik.values.gstType === "Consumer (B2C)" ||
                    formik.values.gstType === "Overseas (Outside India)"
                  ) && (
                    <div className="mb-3">
                      <InputField
                        id="gstin"
                        label={`${
                          formik.values.gstType === ""
                            ? "GSTIN"
                            : !(
                                formik.values.gstType ===
                                  "Unregistered Business" ||
                                formik.values.gstType ===
                                  "Registered - Comp. Scheme" ||
                                formik.values.gstType === "Consumer (B2C)" ||
                                formik.values.gstType ===
                                  "Overseas (Outside India)"
                              )
                            ? "GSTIN*"
                            : "GSTIN"
                        }`}
                        type="text"
                        state={formik.values.gstin}
                        setState={(e) => {
                          formik.setFieldValue("gstin", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            gstin: e.target.value,
                            isObjChange: true,
                          });
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.gstin && formik.errors.gstin ? (
                        <InputError error={formik.errors.gstin} />
                      ) : null}
                    </div>
                  )}

                  <div className="mb-3">
                    <DropdownOne
                      label="Currency"
                      placeholder="Select currency"
                      name="currency"
                      data={currenciesMap}
                      state={formik.values.currency}
                      setState={(e?: string) => {
                        formik.setFieldValue("currency", e);
                        setUpdatedValues({
                          ...updatedValues,
                          currency: e,
                          isObjChange: true,
                        });
                      }}
                      onBlur={() => {
                        formik.setFieldTouched("currency", true);
                      }}
                    />
                    {formik.touched.currency && formik.errors.currency ? (
                      <InputError error={formik.errors.currency} />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-flex justify-content-end mt-4">
                  <button
                    type="submit"
                    className="btn font12x500 shadow-none custom_btn"
                    disabled={updatedValues?.isObjChange ? false : true}
                    // onClick={fn.nextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CompanyDetailForm;
