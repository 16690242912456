import React from "react";
import { BsPlusLg } from "react-icons/bs";
import InputError from "../../shared components/InputError/InputError";
import InputField from "../../shared components/TextInput";

type PropType = {
  formik: any;
};

const AddTags: React.FC<PropType> = (props) => {
  const { formik } = props;

  const fn = {
    updateTags: (tag?: string) => {
      if (formik.values.tags.includes(tag)) {
        const newTags = formik.values.tags.filter((ele: string) => {
          return ele !== tag;
        });
        formik.setFieldValue("tags", newTags);
        formik.setFieldValue("tag", "");
      } else {
        formik.setFieldValue("tags", [...formik.values.tags, tag]);
        formik.setFieldValue("tag", "");
      }
    },
  };

  return (
    <>
      <div className="mb-3">
        <div className="mb-3 position-relative">
          <InputField
            id="tag"
            label="Tags"
            type="text"
            state={formik.values.tag}
            setState={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.values.tag !== "" && (
            <div
              className="add_icon_wrapper cursor_pointer"
              onClick={() => {
                fn.updateTags(formik.values.tag);
              }}
            >
              <BsPlusLg
                style={{
                  color: "var(--text1)",
                }}
              />
            </div>
          )}
        </div>
        {formik.values.tags.length !== 0 && (
          <div className="multi_data_wrapper">
            {formik.values.tags?.map((item: any, i: number) => {
              return (
                <div key={i} className="indi_multi_data">
                  <p className="mb-0 font12x400 textColor3">{item}</p>
                  <div
                    className="remove_data cursor_pointer"
                    onClick={() => fn.updateTags(item)}
                  >
                    <img
                      src="/Assets/Svg/closeIcon.svg"
                      width={12}
                      height={12}
                      alt="remove"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {formik.touched.tag && formik.errors.tag ? (
          <InputError error={formik.errors.tag} />
        ) : null}
        {/* {formik.touched.tags && formik.errors.tags ? (
          <InputError error={formik.errors.tags} />
        ) : null} */}
      </div>
    </>
  );
};

export default AddTags;
