import React, { useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { VscChromeClose } from "react-icons/vsc";

type propType = {
  placeholder: string;
  state?: any;
  paramValue?: string;
  apiCall?: (val: any) => void;
  setState?: (val: any) => void;
};

const Search: React.FC<propType> = ({
  placeholder,
  state,
  paramValue,
  apiCall,
  setState,
}) => {
  const [value, setValue] = useState("");

  const fn = {
    addParam: (e: any) => {
      e.preventDefault();

      if (value != "") {
        setState?.({
          ...state,
          q: `${paramValue}:${value}`,
        });
        apiCall?.({ ...state, page: 1, q: `${paramValue}:${value}` });
      }
    },
    removeParam: () => {
      setValue("");
      delete state.q;
      apiCall?.({ ...state });
    },
  };

  return (
    <>
      <form>
        <div className="searchWrapper">
          <div className="w-100">
            <input
              type="text"
              className="form-control shadow-none"
              autoComplete="off"
              placeholder={placeholder}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                if (e.target.value === "") {
                  delete state.q;
                  apiCall?.({ ...state });
                }
              }}
            />
          </div>
          {value !== "" && (
            <div className="crossBtn" onClick={fn.removeParam}>
              <VscChromeClose />
            </div>
          )}
          <button
            type="submit"
            className="searchBtn"
            onClick={(e) => fn.addParam(e)}
          >
            <HiOutlineSearch />
          </button>
        </div>
      </form>
    </>
  );
};

export default Search;
