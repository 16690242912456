// import { toast } from "react-toastify";

// const defaultOptions = {
//   autoClose: 3000,
//   hideProgressBar: false,
//   closeButton: true,
//   closeOnClick: true,
//   pauseOnHover: true,
//   position: toast.POSITION.BOTTOM_CENTER,
// };

// export const showToast = (
//   message: string,
//   type: "info" | "success" | "error"
// ): any => {
//   // write notification toast here if notification exist

//   return toast[type](message, { ...defaultOptions });
// };

import toast from "react-hot-toast";
import { PRIMARY_COLOR } from "../../utils/constants";

export const showToast = (
  message: any,
  type: "success" | "error" | "custom"
): any => {
  return toast[type](message, {
    duration: 3000,
    position: "bottom-center",
    style: {
      fontSize: "12px",
      fontWeight: 500,
      border: `1px solid ${PRIMARY_COLOR}`,
      padding: "10px",
    },
    iconTheme: {
      primary: PRIMARY_COLOR,
      secondary: "#ffffff",
    },
  });
};
