import React from "react";
import SpinLoader from "../../shared components/Loader/SpinLoader";
import { CgClose } from "react-icons/cg";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { getDefaultInitial } from "../../function/other";
import { PRIMARY_COLOR } from "../../utils/constants";

type propType = {
  setState: (val: any) => void;
  setNotifications: (val: any) => void;
  getNotification: (val: any) => void;
  updateNotification: (val1: string, val2: boolean, val3: string) => void;
  clearAllNotifications: () => void;
  params: any;
  notifications: any;
  totalResult: number;
  setParams: (val: any) => void;
  clearAllLoading: boolean;
};

const AllNotification: React.FC<propType> = (props) => {
  const {
    setState,
    getNotification,
    params,
    notifications,
    setNotifications,
    updateNotification,
    totalResult,
    setParams,
    clearAllNotifications,
    clearAllLoading,
  } = props;

  const fn = {
    setPositionToBody: () => {
      setState(false);
      setNotifications([]);
      setParams({
        ...params,
        page: 1,
      });
      document.body.style.overflow = "unset";
    },
    nextData: () => {
      let page = params.page + 1;
      setParams({
        ...params,
        page: page,
      });
      if (totalResult >= params.per_page * params.page) {
        getNotification({ ...params, page });
      }
    },
  };

  return (
    <>
      <div className="form_wrapper">
        <div
          className="form_container"
          style={{
            overflow: "unset",
          }}
        >
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">Notifications</h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section p-0">
            <InfiniteScroll
              dataLength={notifications.length}
              next={fn.nextData}
              hasMore={notifications.length >= totalResult ? false : true}
              height="75vh"
              loader={
                <SpinLoader width="30" height="30" color={PRIMARY_COLOR} />
              }
              endMessage={
                <p className="text-center font12x400 textColor1 py-2">
                  No more Notifications!
                </p>
              }
            >
              <div className="notification_body d-flex flex-column">
                {notifications &&
                  notifications?.map((item: any, i: number) => {
                    return (
                      <div
                        key={i}
                        className="single_notification d-flex gap-2 px-4 py-3 cursor_pointer"
                        onClick={() =>
                          updateNotification(
                            item?._id,
                            item?.isRead,
                            item?.link
                          )
                        }
                      >
                        <div
                          className="d-flex gap-2"
                          style={{ height: "fit-content" }}
                        >
                          <div
                            className="unread_dot"
                            style={{
                              visibility: !item?.isRead ? "visible" : "hidden",
                            }}
                          ></div>
                          {item?.fromUser?.image ? (
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}${item?.fromUser?.image?.url}`}
                              alt="user"
                              width={28}
                              height={28}
                              style={{ borderRadius: "50%" }}
                            />
                          ) : (
                            <span className="assignTo_section font10x500">
                              {getDefaultInitial(item?.fromUser?.name)}
                            </span>
                          )}
                        </div>
                        <div>
                          <p className="mb-1 font12x400 textColor3">
                            {item?.body}
                          </p>
                          <p className="mb-0 font10x400 textColor1">
                            {moment(item?.createdAt).fromNow()}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </InfiniteScroll>
            <div
              className="d-flex justify-content-end py-3 px-4"
              style={{
                background: "#fff",
                position: "sticky",
                bottom: 0,
              }}
            >
              {notifications?.length === 0 ? (
                <button
                  className="btn font12x500 shadow-none custom_btn ms-3"
                  disabled={true}
                >
                  Clear all
                </button>
              ) : (
                <button
                  className="btn font12x500 shadow-none custom_btn ms-3"
                  onClick={clearAllNotifications}
                >
                  {clearAllLoading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Clear all"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllNotification;
