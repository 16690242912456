import moment from "moment";
import React, { useContext } from "react";
import { generateCompleteAddress } from "../../function/other";
import { modesMap } from "../../utils/constants";
import { MainContext } from "../../utils/private_routes/PrivateRoutes";

type PropType = {
  apiData: any;
  generalSetting: any;
  width?: string;
  boxShadow?: string;
};

const GeneratePaymentDoc: React.FC<PropType> = (props) => {
  const { setting, documentSetting } = useContext(MainContext);

  const { apiData, generalSetting, width, boxShadow } = props;
  const companyObj = JSON.parse(window.localStorage.getItem("company") || "");

  return (
    <>
      <div
        id="content"
        style={{ width: width, margin: "auto", boxShadow: boxShadow }}
        className="doc_wrapper py-3 px-4"
      >
        <div className="text-end font16x600 textColor3">Original Copy</div>
        <div className="container-fluid p-0 mt-3">
          <div className="row">
            <div className="col-5">
              <h3 className="font20x600 textColor3">WaffleBytes</h3>
              <div className="mt-4">
                <p className="mb-0 font12x500 textColor3">
                  {generateCompleteAddress(
                    companyObj?.address,
                    companyObj?.city,
                    companyObj?.state,
                    companyObj?.country,
                    companyObj?.pincode
                  )
                    .filter((item) => item?.length)
                    .join(", ")}
                </p>
                <p className="mb-0 font12x500 textColor3">
                  {`${companyObj?.phoneCode} ${companyObj?.phoneNumber}`}
                </p>
                <p className="mb-0 font12x500 textColor3">
                  {companyObj?.emailAddress}
                </p>
                <p className="mb-0 font12x500 textColor3">
                  Contact Name: <span>{companyObj?.contactName}</span>
                </p>
                <p className="mb-0 font12x500 textColor3">
                  GSTIN: <span>--</span>
                </p>
              </div>
            </div>
            <div className="col-7">
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0 font16x600 textColor3">
                  {apiData.invoice
                    ? "Payment Receipt"
                    : apiData?.expense
                    ? "Payment Made"
                    : ""}
                </p>
                <p className="mb-0 font20x600 textColor3">{apiData?.number}</p>
              </div>
              <div className="mt-3">
                <div
                  className="d-flex justify-content-between align-items-center px-3"
                  style={{
                    backgroundColor: "var(--bg_color2)",
                  }}
                >
                  <p className="mb-0 text-white font16x500">
                    {apiData.invoice
                      ? "Amount Received"
                      : apiData?.expense
                      ? "Amount Paid"
                      : ""}
                    :
                  </p>
                  <p className="mb-0 text-white font16x500">
                    ₹{apiData?.amount?.toLocaleString()}
                  </p>
                </div>
                <div
                  className="mt-3"
                  style={{
                    width: "50%",
                    margin: "0 0 0 auto",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="font12x500 textColor3">Date:</span>
                    <span className="font12x400 textColor3">
                      {moment(apiData?.createdAt).format(setting?.dateFormat)}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="font12x500 textColor3">Mode:</span>
                    <span className="font12x400 textColor3">
                      {modesMap.get(apiData?.mode)}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {apiData?.invoice ? (
              <div className="mt-3">
                <h3 className="font14x600 textColor3">Receipt to</h3>
                <div>
                  <p className="mb-0 font12x500 textColor3">
                    {apiData?.invoice?.client?.name}
                  </p>
                  <p className="mb-0 font12x500 textColor3">
                    {apiData?.invoice?.client?.contactNumber}
                  </p>
                  <p className="mb-0 font12x500 textColor3">
                    {generateCompleteAddress(
                      apiData?.invoice?.client?.completeAddress?.address,
                      apiData?.invoice?.client?.completeAddress?.city,
                      apiData?.invoice?.client?.completeAddress?.state,
                      apiData?.invoice?.client?.completeAddress?.country,
                      apiData?.invoice?.client?.completeAddress?.pincode
                    )
                      .filter((item) => item?.length)
                      .join(", ")}
                  </p>
                  <p className="mb-0 font12x500 textColor3">
                    {apiData?.invoice?.client?.email}
                  </p>
                  {!(
                    apiData?.invoice?.client?.gstType ===
                      "Unregistered Business" ||
                    apiData?.invoice?.client?.gstType === "Consumer (B2C)" ||
                    apiData?.invoice?.client?.gstType ===
                      "Overseas (Outside India)"
                  ) &&
                    apiData?.invoice?.client?.gstin !== "" && (
                      <p className="mb-0 font12x500 textColor3">
                        GSTIN:{" "}
                        <span className="font12x400">
                          {apiData?.invoice?.client?.gstin}
                        </span>
                      </p>
                    )}
                  {apiData?.invoice?.client?.pan !== "" && (
                    <p className="mb-0 font12x500 textColor3">
                      PAN:{" "}
                      <span className="font12x400">
                        {apiData?.invoice?.client?.pan}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            ) : apiData?.expense ? (
              <div className="mt-3">
                <h3 className="font14x600 textColor3">Receipt to</h3>
                <div>
                  <p className="mb-0 font12x500 textColor3">
                    {apiData?.expense?.vendor?.name}
                  </p>
                  <p className="mb-0 font12x500 textColor3">
                    {apiData?.expense?.vendor?.contactNumber}
                  </p>
                  <p className="mb-0 font12x500 textColor3">
                    {generateCompleteAddress(
                      apiData?.expense?.vendor?.completeAddress?.address,
                      apiData?.expense?.vendor?.completeAddress?.city,
                      apiData?.expense?.vendor?.completeAddress?.state,
                      apiData?.expense?.vendor?.completeAddress?.country,
                      apiData?.expense?.vendor?.completeAddress?.pincode
                    )
                      .filter((item) => item?.length)
                      .join(", ")}
                  </p>
                  <p className="mb-0 font12x500 textColor3">
                    {apiData?.expense?.vendor?.email}
                  </p>
                  {!(
                    apiData?.expense?.vendor?.gstType ===
                      "Unregistered Business" ||
                    apiData?.expense?.vendor?.gstType === "Consumer (B2C)" ||
                    apiData?.expense?.vendor?.gstType ===
                      "Overseas (Outside India)"
                  ) &&
                    apiData?.expense?.vendor?.gstin !== "" && (
                      <p className="mb-0 font12x500 textColor3">
                        GSTIN:{" "}
                        <span className="font12x400">
                          {apiData?.expense?.vendor?.gstin}
                        </span>
                      </p>
                    )}
                  {apiData?.expense?.vendor?.pan !== "" && (
                    <p className="mb-0 font12x500 textColor3">
                      PAN:{" "}
                      <span className="font12x400">
                        {apiData?.expense?.vendor?.pan}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="mt-3">
              <table className="doc_table">
                <thead
                  style={{
                    backgroundColor: "var(--bg_color2)",
                  }}
                >
                  <tr>
                    <th>#</th>
                    {/* <th>Item & Description</th> */}
                    <th>Document Number</th>
                    <th>Document Date</th>
                    <th>Document Amount</th>
                    <th>Payment Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {apiData?.invoice ? (
                    <tr>
                      <td>{1}</td>
                      <td>{apiData?.documentNumber}</td>
                      <td>
                        {moment(apiData?.date).format(setting.dateFormat)}
                      </td>
                      <td>{apiData?.invoice?.calculation?.total}</td>
                      <td>{apiData?.amount}</td>
                    </tr>
                  ) : apiData?.expense ? (
                    <tr>
                      <td>{1}</td>
                      <td>{apiData?.documentNumber}</td>
                      <td>
                        {moment(apiData?.date).format(setting.dateFormat)}
                      </td>
                      <td>
                        {apiData?.expense?.calculation?.total?.toLocaleString()}
                      </td>
                      <td>{apiData?.amount?.toLocaleString()}</td>
                    </tr>
                  ) : (
                    ""
                  )}

                  {/* <tr>
                    {signature && (
                      <td colSpan={5}>
                        <div className="d-flex align-items-center">
                          <div className="ps-1 pe-4 pt-4">
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}${signature}`}
                              className="img-fluid"
                              width={100}
                              alt=""
                            />
                            <p className="mt-2 mb-0 font10x500">
                              Provider Signature
                            </p>
                          </div>
                        </div>
                      </td>
                    )}
                  </tr> */}
                </tbody>
              </table>

              <div className="d-flex justify-content-end gap-2">
                {documentSetting?.payment?.pS &&
                  generalSetting?.primarySignature && (
                    <div>
                      <div className="pt-4 signature-text-container">
                        <div className="signature-container">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}${generalSetting?.primarySignature}`}
                            width={100}
                          />
                        </div>
                        <p className="mt-2 mb-0 font10x500 text-start">
                          {generalSetting?.pSName}
                        </p>
                      </div>
                    </div>
                  )}

                {documentSetting?.payment?.sS &&
                  generalSetting?.secondarySignature && (
                    <div>
                      <div className="pt-4 signature-text-container">
                        <div className="signature-container">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}${generalSetting?.secondarySignature}`}
                            width={100}
                          />
                        </div>
                        <p className="mt-2 mb-0 font10x500 text-start">
                          {generalSetting?.sSName}
                        </p>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneratePaymentDoc;
