import React, { useState, useEffect } from "react";
import useLoading from "../../hooks/useLoading";
import { commonType } from "../../pages/main/lead";
import { CgClose } from "react-icons/cg";
import {
  createSalaryConstraint,
  getSalaryConstraint,
  updateSalaryConstraint,
} from "../../lib/api functions/attendance/attendance";
import moment from "moment";
import InputError from "../../shared components/InputError/InputError";
import InputField from "../../shared components/TextInput";
import SpinLoader from "../../shared components/Loader/SpinLoader";
import { SalaryConstraintType } from "./type";
import { useFormik } from "formik";
import { showToast } from "../../lib/toast/ShowToast";
import { salaryConstraintSchema } from "../../utils/validationSchema";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall: (val: any) => void;
  params: any;
};

const AddValue: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params } = props;
  const [loading, showLoader, hideLoader] = useLoading();
  const [salaryConstraintLoading, showLoader2, hideLoader2] = useLoading();

  const [addValueParams, setAddValueParams] = useState<any>({
    month: params.month ? params.month : moment().month(),
    year: params.year ? params.year : moment().year(),
    company: params.company,
  });

  const initialValues: SalaryConstraintType = {
    _id: "",
    requiredHours: 0,
    requiredDays: 0,
    holidays: 0,
  };

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
    getSalaryConstraints: async (params: any) => {
      showLoader2();
      const res = await getSalaryConstraint(params);
      if (res) {
        formik.setFieldValue(
          "_id",
          res.data.salaryConstraints.length !== 0
            ? res.data.salaryConstraints[0]._id
            : ""
        );
        formik.setFieldValue(
          "requiredHours",
          res.data.salaryConstraints.length !== 0
            ? res.data.salaryConstraints[0].requiredHours
            : 0
        );
        formik.setFieldValue(
          "requiredDays",
          res.data.salaryConstraints.length !== 0
            ? res.data.salaryConstraints[0].requiredDays
            : 0
        );
        formik.setFieldValue(
          "holidays",
          res.data.salaryConstraints.length !== 0
            ? res.data.salaryConstraints[0].holidays
            : 0
        );
        hideLoader2();
      }
    },
  };

  const formik = useFormik<SalaryConstraintType>({
    initialValues,
    validationSchema: salaryConstraintSchema,
    onSubmit: async (values) => {
      let finalObj = {};
      if (values._id !== "") {
        finalObj = {
          requiredHours: Number(values.requiredHours),
          requiredDays: Number(values.requiredDays),
          holidays: Number(values.holidays),
        };
      } else {
        finalObj = {
          month: addValueParams.month,
          year: addValueParams.year,
          requiredHours: Number(values.requiredHours),
          requiredDays: Number(values.requiredDays),
          holidays: Number(values.holidays),
        };
      }

      showLoader();
      if (values._id !== "") {
        const res = await updateSalaryConstraint(
          finalObj,
          values._id,
          hideLoader
        );
        if (res) {
          showToast(`Salary constraint updated`, "success");
          fn.setPositionToBody();
          apiCall(params);
          hideLoader();
        }
      } else {
        const res = await createSalaryConstraint(finalObj, hideLoader);
        if (res) {
          showToast(`Salary constraint created`, "success");
          fn.setPositionToBody();
          apiCall(params);
          hideLoader();
        }
      }
    },
  });

  useEffect(() => {
    fn.getSalaryConstraints(addValueParams);
  }, []);

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">Add Value</h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="container-fluid p-0 mb-3">
                  <div className="row">
                    <div className="col-6">
                      <p className="mb-0 font14x500 textColor3">
                        Month:{" "}
                        <span className="font12x400">
                          {moment().month(addValueParams?.month).format("MMMM")}
                        </span>
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="mb-0 font14x500 textColor3">
                        Year:{" "}
                        <span className="font12x400">
                          {addValueParams?.year}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <InputField
                    id="requiredHours"
                    label="Required Hours*"
                    type="text"
                    state={formik.values.requiredHours}
                    setState={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.requiredHours &&
                  formik.errors.requiredHours ? (
                    <InputError error={formik.errors.requiredHours} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <InputField
                    id="requiredDays"
                    label="Required Days*"
                    type="text"
                    state={formik.values.requiredDays}
                    setState={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.requiredDays && formik.errors.requiredDays ? (
                    <InputError error={formik.errors.requiredDays} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <InputField
                    id="holidays"
                    label="Holidays*"
                    type="text"
                    state={formik.values.holidays}
                    setState={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.holidays && formik.errors.holidays ? (
                    <InputError error={formik.errors.holidays} />
                  ) : null}
                </div>
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>

                <button className="btn font12x500 shadow-none custom_btn ms-3">
                  {formik.values._id !== "" ? (
                    loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )
                  ) : loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddValue;
