import React, { useContext, useEffect, useState } from "react";
import { commonType } from "../../pages/main/lead";
import { CgClose } from "react-icons/cg";
import UpdationDetail from "../Data Update Detail";
import InputField from "../../shared components/TextInput";
import TextareaInput from "../../shared components/TextareaInput";
import DropdownOne from "../../shared components/Dropdown/DropdownOne";
import { itemTypesMap } from "../../utils/constants";
import { ItemType } from "../../pages/main/item/type";
import { useFormik } from "formik";
import { itemSchema } from "../../utils/validationSchema";
import InputError from "../../shared components/InputError/InputError";
import { showToast } from "../../lib/toast/ShowToast";
import useLoading from "../../hooks/useLoading";
import { createItem, updateItem } from "../../lib/api functions/item";
import SpinLoader from "../../shared components/Loader/SpinLoader";
import { getTax } from "../../lib/api functions/setting/tax";
import DropdownTwo from "../../shared components/Dropdown/DropdownTwo";
import { MainContext } from "../../utils/private_routes/PrivateRoutes";
import { demoUnitMap } from "../../utils/constants";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall?: (val: any) => void;
  params: any;
};

const Form: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params } = props;
  const { userId, companyId } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [taxes, setTaxes] = useState<any>([]);
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
    getGstTaxes: async (params?: any) => {
      const res = await getTax(params);
      if (res) {
        setTaxes(res.data.taxes);
      }
    },
  };

  const initialValues: ItemType = {
    name: state?.data?.item?.name ?? "",
    description: state?.data?.item?.description ?? "",
    type: state?.data?.item?.type ?? 0,
    unitAmount: state?.data?.item?.unitAmount ?? 0,
    tax: state?.data?.item?.tax ?? "",
    hsn: state?.data?.item?.hsn ?? "",
    sac: state?.data?.item?.sac ?? "",
    stock: state?.data?.item?.stock ?? "1",
    unit: state?.data?.item?.unit ?? "",
    typeLabel: itemTypesMap.get(state?.data?.item?.type) ?? "",
  };

  const formik = useFormik<ItemType>({
    initialValues,
    validationSchema: itemSchema,
    onSubmit: async (values) => {
      let finalObj: ItemType = {};

      if (state?.data) {
        delete updatedValues.isObjChange;
        finalObj = updatedValues;
      } else {
        finalObj = {
          type: values.type,
          name: values.name,
          description: values.description,
          unitAmount: values.unitAmount,
          unit: values.unit,
          tax: values.tax,
        };
      }

      if (!state?.data && values.type === 1) {
        finalObj.hsn = values.hsn;
        finalObj.stock = values.stock;
      } else if (!state?.data && values.type === 2) {
        finalObj.sac = values.sac;
      }

      showLoader();

      if (state.data) {
        const res = await updateItem(finalObj, state.data.item._id, hideLoader);
        if (res) {
          showToast(`Item updated`, "success");
          fn.setPositionToBody();
          apiCall?.(params);
          hideLoader();
        }
      } else {
        const res = await createItem(finalObj, hideLoader);
        if (res) {
          showToast(`Item created`, "success");
          fn.setPositionToBody();
          apiCall?.(params);
          hideLoader();
        }
      }
    },
  });

  useEffect(() => {
    fn.getGstTaxes({
      f: "percent",
      type: 1,
      company: companyId,
    });
  }, []);

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">
              {state.data ? "View/Edit Item Details" : "Add New Item"}
            </h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <InputField
                    id="name"
                    label="Item Name*"
                    type="text"
                    state={formik.values.name}
                    setState={formik.handleChange}
                    isDisabled={state?.data ? true : false}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <InputError error={formik.errors.name} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <TextareaInput
                    id="description"
                    label="Description"
                    state={formik.values.description}
                    setState={(e) => {
                      formik.setFieldValue("description", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        description: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <InputError error={formik.errors.description} />
                  ) : null}
                </div>

                <div className="container-fluid p-0">
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <DropdownOne
                          label="Type*"
                          placeholder="Select Type"
                          name="typeLabel"
                          data={itemTypesMap}
                          state={formik.values.typeLabel}
                          setState={(e?: string, x?: string | number) => {
                            formik.setFieldValue("typeLabel", e);
                            formik.setFieldValue("type", x);
                          }}
                          isDisabled={state?.data ? true : false}
                          onBlur={() =>
                            formik.setFieldTouched("typeLabel", true)
                          }
                        />
                        {formik.touched.typeLabel && formik.errors.typeLabel ? (
                          <InputError error={formik.errors.typeLabel} />
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <DropdownTwo
                          label="Tax (%)*"
                          placeholder="Select Tax"
                          name="tax"
                          data={taxes}
                          state={formik.values.tax}
                          setState={(e?: string, x?: string | number) => {
                            formik.setFieldValue("tax", e);
                            setUpdatedValues({
                              ...updatedValues,
                              tax: e,
                              isObjChange: true,
                            });
                          }}
                          labelField="percent"
                          onBlur={() => formik.setFieldTouched("tax", true)}
                        />
                        {formik.touched.tax && formik.errors.tax ? (
                          <InputError error={formik.errors.tax} />
                        ) : null}
                      </div>

                      {formik.values.type === 1 ? (
                        <div className="mb-3">
                          <InputField
                            id="hsn"
                            label="HSN*"
                            type="text"
                            state={formik.values.hsn}
                            setState={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isDisabled={state?.data ? true : false}
                          />
                          {formik.touched.hsn && formik.errors.hsn ? (
                            <InputError error={formik.errors.hsn} />
                          ) : null}
                        </div>
                      ) : (
                        ""
                      )}

                      {formik.values.type === 2 ? (
                        <div className="mb-3">
                          <InputField
                            id="sac"
                            label="SAC*"
                            type="text"
                            state={formik.values.sac}
                            setState={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isDisabled={state?.data ? true : false}
                          />
                          {formik.touched.sac && formik.errors.sac ? (
                            <InputError error={formik.errors.sac} />
                          ) : null}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <InputField
                          id="unitAmount"
                          label="Unit Amount (₹)*"
                          type="tel"
                          name="unitAmount"
                          state={formik.values.unitAmount}
                          setState={(e) => {
                            formik.setFieldValue("unitAmount", e.target.value);
                            setUpdatedValues({
                              ...updatedValues,
                              unitAmount: e.target.value,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.unitAmount &&
                        formik.errors.unitAmount ? (
                          <InputError error={formik.errors.unitAmount} />
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <DropdownOne
                          label="Unit*"
                          placeholder="Unit"
                          name="unit"
                          data={demoUnitMap}
                          state={formik.values.unit}
                          setState={(e?: string, x?: string | number) => {
                            formik.setFieldValue("unit", e);
                            setUpdatedValues({
                              ...updatedValues,
                              unit: e,
                              isObjChange: true,
                            });
                          }}
                          onBlur={() => formik.setFieldTouched("unit", true)}
                        />
                        {formik.touched.unit && formik.errors.unit ? (
                          <InputError error={formik.errors.unit} />
                        ) : null}
                      </div>

                      {formik.values.type === 1 ? (
                        <div className="mb-3">
                          <InputField
                            id="stock"
                            label="Stock*"
                            type="text"
                            state={formik.values.stock}
                            setState={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isDisabled={state?.data ? true : false}
                          />
                          {formik.touched.stock && formik.errors.stock ? (
                            <InputError error={formik.errors.stock} />
                          ) : null}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>

                {state.data ? (
                  <button
                    className="btn font12x500 shadow-none custom_btn ms-3"
                    disabled={updatedValues?.isObjChange ? false : true}
                  >
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )}
                  </button>
                ) : (
                  <button className="btn font12x500 shadow-none custom_btn ms-3">
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Save"
                    )}
                  </button>
                )}
              </div>
            </form>

            {state.data && (
              <UpdationDetail
                lastUpdate={state?.data?.item?.updatedAt}
                updatedBy={state?.data?.item?.updatedBy}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
