import React, { useContext, useEffect, useState } from "react";
import DataNotFound from "../../../../../components/Data Not Found";
import Form from "../../../../../components/Setting Components/MenuData/GST/Form";
import useLoading from "../../../../../hooks/useLoading";
import { getTax } from "../../../../../lib/api functions/setting/tax";
import SkeletonLoader from "../../../../../shared components/Loader/SkeletonLoader";
import { MainContext } from "../../../../../utils/private_routes/PrivateRoutes";
import { commonType } from "../../../lead";
import { ParamType } from "./type";

const SettingGst = () => {
  const { userId, companyId, roles } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const [loading, showLoader, hideLoader] = useLoading();
  const [taxes, setTaxes] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    company: companyId,
    type: 1,
  });

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getTaxes: async (params?: ParamType) => {
      showLoader();
      const res = await getTax(params);
      if (res) {
        setTaxes(res.data.taxes);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
  };

  useEffect(() => {
    if (roles?.length !== 0) {
      fn.getTaxes(params);
    }
  }, []);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font14x600 textColor3">GST</p>
          <button
            className="btn font12x500 buttonOne shadow-none"
            onClick={() => fn.openModal(undefined, "add_tax")}
          >
            <img src="/Assets/Svg/plusIcon.svg" alt="icon" className="me-2" />
            New
          </button>
        </div>

        {taxes?.length !== 0 ? (
          <div className="entry_table_wrapper">
            <div className="table_wrapper">
              <table className="table">
                <thead className="table_head">
                  <tr className="font12x500 textColor3">
                    <th scope="col">Percent</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {taxes?.map((tax: any, i: number) => {
                    return (
                      <tr key={i} className="font12x400">
                        <td>{tax?.percent}</td>
                        <td>
                          <div
                            className="d-flex align-items-center cursor_pointer"
                            onClick={() => fn.openModal({ tax }, "edit_tax")}
                          >
                            <img src="/Assets/Svg/editIcon.svg" alt="edit" />
                            <p className="mb-0 font12x400 textColor2 ms-1">
                              Edit
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : loading ? (
          <SkeletonLoader />
        ) : (
          <DataNotFound />
        )}
      </div>

      {/* form modal */}
      {commonData?.modal &&
        (commonData?.check === "edit_tax" ||
          commonData?.check === "add_tax") && (
          <Form
            state={commonData}
            setState={setCommonData}
            apiCall={fn.getTaxes}
            params={params}
          />
        )}
    </>
  );
};

export default SettingGst;
