import React, { useContext, useEffect, useState } from "react";
import Form from "../../../../../components/Setting Components/MenuData/UserRole/Form";
import DeleteModal from "../../../../../components/Trashed Modal/DeleteModal";
import useLoading from "../../../../../hooks/useLoading";
import { getRole } from "../../../../../lib/api functions/setting/role";
import SkeletonLoader from "../../../../../shared components/Loader/SkeletonLoader";
import { MainContext } from "../../../../../utils/private_routes/PrivateRoutes";
import { commonType } from "../../../lead";
import { ParamType } from "./type";

const SettingUserRole = () => {
  const { userId, companyId, roles } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const [loading, showLoader, hideLoader] = useLoading();
  const [userRoles, setRoles] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [params, setParams] = useState<ParamType>({
    // page: 1,
    // per_page: 10,
    company: companyId,
  });

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getRoles: async (params?: ParamType) => {
      showLoader();
      const res = await getRole(params);
      if (res) {
        setRoles(res.data.roles);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
  };

  useEffect(() => {
    if (roles?.length !== 0) {
      fn.getRoles(params);
    }
  }, []);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font14x600 textColor3">Roles</p>
          <button
            className="btn font12x500 buttonOne shadow-none"
            onClick={() => fn.openModal(undefined, "add_role")}
          >
            <img src="/Assets/Svg/plusIcon.svg" alt="icon" className="me-2" />
            New
          </button>
        </div>

        {userRoles?.length !== 0 ? (
          <div className="entry_table_wrapper">
            <div className="table_wrapper">
              <table className="table">
                <thead className="table_head">
                  <tr className="font12x500 textColor3">
                    <th scope="col">Role</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userRoles?.map((role: any, i: number) => {
                    return (
                      <tr key={i} className="font12x400">
                        <td>{role?.name}</td>
                        <td>
                          <div className="d-flex gap-2">
                            <div
                              className="d-flex align-items-center cursor_pointer"
                              onClick={() =>
                                fn.openModal({ role }, "edit_role")
                              }
                            >
                              <img src="/Assets/Svg/editIcon.svg" alt="edit" />
                              <p className="mb-0 font12x400 textColor2 ms-1">
                                Edit
                              </p>
                            </div>
                            <div
                              className="d-flex align-items-center cursor_pointer"
                              onClick={() =>
                                fn.openModal([role?._id], "delete")
                              }
                            >
                              <img
                                src="/Assets/Svg/deleteColor.svg"
                                alt="delete"
                              />
                              <p className="mb-0 font12x400 errorDanger ms-1">
                                Delete
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : loading ? (
          <SkeletonLoader />
        ) : (
          <div className="d-flex justify-content-center align-items-center flex-column px-3 px-md-5">
            <h3 className="font20x400 textColor3">Assign Roles</h3>
            <p className="text-center font10x400 textColor3">
              With role-based access, you can add your colleagues and your CA to
              your organization. You can give them special access to either
              sales, bills, everything or just the option to download reports.
            </p>
            <img
              src="/Assets/Svg/userNotFound.svg"
              alt="not-found"
              width={350}
              draggable={false}
            />
          </div>
        )}
      </div>

      {/* form modal */}
      {commonData?.modal &&
        (commonData?.check === "edit_role" ||
          commonData?.check === "add_role") && (
          <Form
            state={commonData}
            setState={setCommonData}
            apiCall={fn.getRoles}
            params={params}
          />
        )}

      {/* delete modal */}
      {commonData?.modal && commonData?.check === "delete" && (
        <DeleteModal
          label="Role"
          dataIds={commonData?.data}
          setState={setCommonData}
        />
      )}
    </>
  );
};

export default SettingUserRole;
