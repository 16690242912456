import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import useLoading from "../../../../../hooks/useLoading";
import {
  getDocumentSettingById,
  updateDocumentSetting,
} from "../../../../../lib/api functions/setting/document";
import { showToast } from "../../../../../lib/toast/ShowToast";
import SpinLoader from "../../../../../shared components/Loader/SpinLoader";
import InputField from "../../../../../shared components/TextInput";
import SwitchToggle from "../../../../../shared components/ToggleButton/SwitchToggle";
import { MainContext } from "../../../../../utils/private_routes/PrivateRoutes";
import { generalSettingSchema } from "../../../../../utils/validationSchema";
import { InvoiceDocType } from "./type";

const SettingInvoice = () => {
  const { companyId, roles, setDocumentSetting } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [invoiceLoading, showLoader2, hideLoader2] = useLoading();

  const initialValues: InvoiceDocType = {
    _id: "",
    initials: "",
    sA: false,
    tIW: false,
    tAD: false,
    nRTS: false,
    cPBTS: false,
    pS: false,
    sS: false,
    fIHAP: false,
  };

  const fn = {
    getInvoiceDocSetting: async (params?: any) => {
      showLoader2();
      const res = await getDocumentSettingById(companyId, params);
      if (res) {
        formik.setFieldValue("_id", res.data.documentSetting._id);
        formik.setFieldValue(
          "initials",
          res.data.documentSetting.invoice.initials
        );
        formik.setFieldValue("sA", res.data.documentSetting.invoice.sA);
        formik.setFieldValue("tIW", res.data.documentSetting.invoice.tIW);
        formik.setFieldValue("tAD", res.data.documentSetting.invoice.tAD);
        formik.setFieldValue("nRTS", res.data.documentSetting.invoice.nRTS);
        formik.setFieldValue("pS", res.data.documentSetting.invoice.pS);
        formik.setFieldValue("sS", res.data.documentSetting.invoice.sS);
        formik.setFieldValue("cPBTS", res.data.documentSetting.invoice.cPBTS);
        formik.setFieldValue("fIHAP", res.data.documentSetting.invoice.fIHAP);
        hideLoader2();
      }
    },
  };

  const formik = useFormik<InvoiceDocType>({
    initialValues,
    validationSchema: generalSettingSchema,
    onSubmit: async (values) => {
      const formObj = {
        invoice: {
          initials: values.initials,
          sA: values.sA,
          tIW: values.tIW,
          tAD: values.tAD,
          nRTS: values.nRTS,
          cPBTS: values.cPBTS,
          pS: values.pS,
          sS: values.sS,
          fIHAP: values.fIHAP,
        },
      };
      showLoader();
      const res = await updateDocumentSetting(formObj, values._id, hideLoader);
      if (res) {
        setDocumentSetting(res.data.documentSetting);
        showToast(`Invoice document setting updated`, "success");
        hideLoader();
      }
    },
  });

  useEffect(() => {
    if (roles?.length !== 0) {
      fn.getInvoiceDocSetting({
        f: "invoice",
        company: companyId,
      });
    }
  }, []);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font14x600 textColor3">Invoice</p>
        </div>

        {invoiceLoading ? (
          <>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={50} height={20} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={50} height={20} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={50} height={20} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={50} height={20} borderRadius={8} />
              </div>
            </div>
          </>
        ) : (
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex justify-content-start align-items-sm-center justify-content-sm-between flex-column flex-sm-row mb-3">
                <label
                  htmlFor="initials"
                  className="form-label font12x400 textColor1 mb-0"
                >
                  Initials
                </label>

                <div
                  className="setting_input_wrapper"
                  style={{
                    width: "auto",
                  }}
                >
                  <InputField
                    id="initials"
                    type="text"
                    state={formik.values.initials}
                    setState={formik.handleChange}
                    width="100px"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                <label className="form-label font12x400 textColor1 mb-1">
                  Show Shipping Address
                </label>

                <div className="setting_input_wrapper text-start text-sm-end">
                  <SwitchToggle
                    state={formik.values.sA}
                    setState={(e: boolean) => {
                      formik.setFieldValue("sA", e);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                <label className="form-label font12x400 textColor1 mb-1">
                  Show Total in Words
                </label>

                <div className="setting_input_wrapper text-start text-sm-end">
                  <SwitchToggle
                    state={formik.values.tIW}
                    setState={(e: boolean) => {
                      formik.setFieldValue("tIW", e);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                <label className="form-label font12x400 textColor1 mb-1">
                  Show Total Amount Due
                </label>

                <div className="setting_input_wrapper text-start text-sm-end">
                  <SwitchToggle
                    state={formik.values.tAD}
                    setState={(e: boolean) => {
                      formik.setFieldValue("tAD", e);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                <label className="form-label font12x400 textColor1 mb-1">
                  Show Net Received in the Totals Section
                </label>

                <div className="setting_input_wrapper text-start text-sm-end">
                  <SwitchToggle
                    state={formik.values.nRTS}
                    setState={(e: boolean) => {
                      formik.setFieldValue("nRTS", e);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                <label className="form-label font12x400 textColor1 mb-1">
                  Show Contact Person in Bill To section
                </label>

                <div className="setting_input_wrapper text-start text-sm-end">
                  <SwitchToggle
                    state={formik.values.cPBTS}
                    setState={(e: boolean) => {
                      formik.setFieldValue("cPBTS", e);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                <label className="form-label font12x400 textColor1 mb-1">
                  Show Primary Signature
                </label>

                <div className="setting_input_wrapper text-start text-sm-end">
                  <SwitchToggle
                    state={formik.values.pS}
                    setState={(e: boolean) => {
                      formik.setFieldValue("pS", e);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                <label className="form-label font12x400 textColor1 mb-1">
                  Show Secondary Signature
                </label>

                <div className="setting_input_wrapper text-start text-sm-end">
                  <SwitchToggle
                    state={formik.values.sS}
                    setState={(e: boolean) => {
                      formik.setFieldValue("sS", e);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                <label className="form-label font12x400 textColor1 mb-1">
                  Show Total Tax Amount (S)
                </label>

                <div className="setting_input_wrapper text-start text-sm-end">
                  <SwitchToggle
                    state={formik.values.fIHAP}
                    setState={(e: boolean) => {
                      formik.setFieldValue("fIHAP", e);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                <label className="form-label font12x400 textColor1 mb-1">
                  Show Full Invoice Header on ALL Pages (PDF & Print)
                </label>

                <div className="setting_input_wrapper text-start text-sm-end">
                  <SwitchToggle
                    state={formik.values.fIHAP}
                    setState={(e: boolean) => {
                      formik.setFieldValue("fIHAP", e);
                    }}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end my-4">
                <button className="btn font12x500 shadow-none custom_btn ms-3">
                  {loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default SettingInvoice;
