import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import useLoading from "../../hooks/useLoading";
import { changedPassword } from "../../lib/api functions/user";
import { showToast } from "../../lib/toast/ShowToast";
import InputError from "../../shared components/InputError/InputError";
import SpinLoader from "../../shared components/Loader/SpinLoader";
import { MainContext } from "../../utils/private_routes/PrivateRoutes";
import { changePasswordSchema } from "../../utils/validationSchema";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

type ChangePassword = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const UserProfile = () => {
  const { setPageTitle, userId } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const userObj = JSON.parse(window.localStorage.getItem("user") || "");

  const [isNewPasswordShow, setIsNewPasswordShow] = useState<boolean>(false);
  const [isConfirmPasswordShow, setIsConfirmPasswordShow] =
    useState<boolean>(false);
  const [isCurrentPasswordShow, setIsCurrentPasswordShow] =
    useState<boolean>(false);
  const newPassRef = useRef<any>(null);
  const confirmPassRef = useRef<any>(null);
  const currentPassRef = useRef<any>(null);

  const fn = {
    passwordToggle: (check: string) => {
      if (check === "current") {
        if (currentPassRef?.current?.type === "password") {
          currentPassRef.current.type = "text";
          setIsCurrentPasswordShow(true);
        } else {
          currentPassRef.current.type = "password";
          setIsCurrentPasswordShow(false);
        }
      } else if (check === "new") {
        if (newPassRef?.current?.type === "password") {
          newPassRef.current.type = "text";
          setIsNewPasswordShow(true);
        } else {
          newPassRef.current.type = "password";
          setIsNewPasswordShow(false);
        }
      } else {
        if (confirmPassRef?.current?.type === "password") {
          confirmPassRef.current.type = "text";
          setIsConfirmPasswordShow(true);
        } else {
          confirmPassRef.current.type = "password";
          setIsConfirmPasswordShow(false);
        }
      }
    },
  };

  const initialValues: ChangePassword = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const formik = useFormik<ChangePassword>({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: async (values) => {
      let finalObj = {
        ...values,
      };

      showLoader();
      const res = await changedPassword(finalObj, userId, hideLoader);
      if (res) {
        showToast(`Password changed`, "success");
        formik.setFieldValue("currentPassword", "");
        formik.setFieldValue("newPassword", "");
        formik.setFieldValue("confirmPassword", "");
        formik.setFieldTouched("currentPassword", false);
        formik.setFieldTouched("newPassword", false);
        formik.setFieldTouched("confirmPassword", false);
        hideLoader();
      }
    },
  });

  useEffect(() => {
    setPageTitle("User Profile");
  }, []);

  return (
    <>
      <div className="single_page p-3">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-lg-6">
              <form onSubmit={formik.handleSubmit}>
                <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                  <label
                    htmlFor="name"
                    className="form-label font12x400 textColor3 mb-1"
                  >
                    User Id
                  </label>
                  <div className="">
                    <p className="mb-0 font12x500 textColor3">
                      {userObj?.customUserId}
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                  <label
                    htmlFor="name"
                    className="form-label font12x400 textColor3 mb-1"
                  >
                    Full Name
                  </label>
                  <div className="">
                    <p className="mb-0 font12x500 textColor3">
                      {userObj?.name}
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                  <label
                    htmlFor="name"
                    className="form-label font12x400 textColor3 mb-1"
                  >
                    Phone
                  </label>
                  <div className="">
                    <p className="mb-0 font12x500 textColor3">
                      {userObj?.mobile}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                  <label
                    htmlFor="name"
                    className="form-label font12x400 textColor3 mb-1"
                  >
                    Email
                  </label>
                  <div className="">
                    <p className="mb-0 font12x500 textColor3">
                      {userObj?.email}
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-between py-3">
                  <p className="m-0 font14x600 textColor3">Change Password</p>
                </div>

                <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                  <label
                    htmlFor="currentPassword"
                    className="form-label font12x400 textColor3 mb-1"
                  >
                    Current Password
                  </label>
                  <div className="setting_input_wrapper">
                    <div className="input-group">
                      <input
                        ref={currentPassRef}
                        type="password"
                        className="form-control shadow-none font12x600 border-0"
                        id="currentPassword"
                        value={formik.values.currentPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="new-password"
                      />
                      <div
                        className="input-group-text font12x600 border-0 cursor_pointer"
                        onClick={() => {
                          fn.passwordToggle("current");
                        }}
                      >
                        {isCurrentPasswordShow ? (
                          <EyeOutlined className="textColor1" />
                        ) : (
                          <EyeInvisibleOutlined className="textColor1" />
                        )}
                      </div>
                    </div>
                    {formik.touched.currentPassword &&
                    formik.errors.currentPassword ? (
                      <InputError error={formik.errors.currentPassword} />
                    ) : null}
                  </div>
                </div>

                <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                  <label
                    htmlFor="newPassword"
                    className="form-label font12x400 textColor3 mb-1"
                  >
                    New Password
                  </label>
                  <div className="setting_input_wrapper">
                    <div className="input-group">
                      <input
                        ref={newPassRef}
                        type="password"
                        className="form-control shadow-none font12x600 border-0"
                        id="newPassword"
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="new-password"
                      />
                      <div
                        className="input-group-text font12x600 border-0 cursor_pointer"
                        onClick={() => {
                          fn.passwordToggle("new");
                        }}
                      >
                        {isNewPasswordShow ? (
                          <EyeOutlined className="textColor1" />
                        ) : (
                          <EyeInvisibleOutlined className="textColor1" />
                        )}
                      </div>
                    </div>
                    {formik.touched.newPassword && formik.errors.newPassword ? (
                      <InputError error={formik.errors.newPassword} />
                    ) : null}
                  </div>
                </div>

                <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                  <label
                    htmlFor="confirmPassword"
                    className="form-label font12x400 textColor3 mb-1"
                  >
                    Confirm Password
                  </label>
                  <div className="setting_input_wrapper">
                    <div className="input-group">
                      <input
                        ref={confirmPassRef}
                        type="password"
                        className="form-control shadow-none font12x600 border-0"
                        id="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="confirm-password"
                      />
                      <div
                        className="input-group-text font12x600 border-0 cursor_pointer"
                        onClick={() => {
                          fn.passwordToggle("confirm");
                        }}
                      >
                        {isConfirmPasswordShow ? (
                          <EyeOutlined className="textColor1" />
                        ) : (
                          <EyeInvisibleOutlined className="textColor1" />
                        )}
                      </div>
                    </div>
                    {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword ? (
                      <InputError error={formik.errors.confirmPassword} />
                    ) : null}
                  </div>
                </div>

                <div className="d-flex justify-content-end my-4">
                  <button className="btn font12x500 shadow-none custom_btn ms-3">
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
