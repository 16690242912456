import { useFormik } from "formik";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddProposalList from "../../../../components/Credit Note Components/Add Update Components/AddProposalList";
import Calculation from "../../../../components/Credit Note Components/Add Update Components/Calculation";
import Form from "../../../../components/Credit Note Components/Add Update Components/Form";
import useLoading from "../../../../hooks/useLoading";
import { getClient, getClientById } from "../../../../lib/api functions/client";
import {
  createCreditNote,
  getCreditNoteById,
  updateCreditNote,
} from "../../../../lib/api functions/credit_note";
import {
  getInvoice,
  getInvoiceById,
} from "../../../../lib/api functions/invoice/invoice";
import { getTax } from "../../../../lib/api functions/setting/tax";
import { showToast } from "../../../../lib/toast/ShowToast";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import { creditNoteSchema } from "../../../../utils/validationSchema";
import { CreditNoteType, ItemsType } from "./type";
import { getDocumentNumberById } from "../../../../lib/api functions/common";
import UpdationDetail from "../../../../components/Data Update Detail";
import { generateCompleteAddress } from "../../../../function/other";

export const CreditNoteContext = createContext<any>(null);

const AddUpdateCreditNote = () => {
  const { setPageTitle, userId, companyId } = useContext(MainContext);
  const { creditNoteId } = useParams();
  useEffect(() => {
    setPageTitle(creditNoteId ? "Update Credit Note" : "Add Credit Note");
  }, []);
  const companyObj = JSON.parse(window.localStorage.getItem("company") || "");
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const [clientloading, showLoader2, hideLoader2] = useLoading();
  const [invoiceloading, showLoader3, hideLoader3] = useLoading();
  const [itemsList, setItemsList] = useState<any>([]);
  const [clientList, setClientList] = useState<any>([]);
  const [invoiceList, setInvoiceList] = useState<any>([]);
  const [creditItemsToBeUpdate, setCreditItemsToBeUpdate] = useState<any>([]);
  const [taxes, setTaxes] = useState<any>([]);
  const [calculation, setCalculation] = useState<any>({
    subTotal: 0,
    cgst: 0,
    sgst: 0,
    igst: 0,
    discount: 0,
    total: 0,
  });

  const [creditNote, setCreditNote] = useState<CreditNoteType>({
    client: "",
    clientName: "",
    clientAddress: "",
    placeOfSupply: "",
    companyState: companyObj?.state,
    gstNo: "",
    creditNoteNumber: "",
    creditNoteDate: "",
    invoice: "",
    invoiceNumber: "",
    invoiceDate: "",
    reason: "",
    items: [],
  });
  const [singleItem, setSingleItem] = useState<ItemsType>({
    id: "",
    name: "",
    description: "",
    unit: "",
    hsnOrsac: "",
    qty: 1,
    tempQty: 0,
    type: 0,
    unitQtyPrice: 0,
    discount: 0,
    cgst: 0,
    sgst: 0,
    igst: 0,
    tax: 0,
    total: 0,
    error: "",
    isError: false,
    isEditable: false,
  });
  const [updationDetails, setUpdationDetails] = useState({
    lastUpdate: "",
    updatedBy: undefined,
  });

  const fn = {
    getDocNumber: async (params?: any) => {
      const res = await getDocumentNumberById(companyId, params);
      if (res) {
        formik.setFieldValue(
          "creditNoteNumber",
          res.data.documentNumber.creditNote.number.toString()
        );
      }
    },
    getGstTaxes: async (params?: any) => {
      const res = await getTax(params);
      if (res) {
        setTaxes(res.data.taxes);
      }
    },
    getAllClients: async (params?: any) => {
      const res = await getClient(params);
      if (res) {
        setClientList(res.data.clients);
      }
    },
    getClientById: async () => {
      showLoader2();
      const res = await getClientById(formik.values.client);
      if (res) {
        formik.setFieldValue(
          "clientAddress",
          generateCompleteAddress(
            res.data?.client?.completeAddress?.address,
            res.data?.client?.completeAddress?.city,
            res.data?.client?.completeAddress?.state,
            res.data?.client?.completeAddress?.country,
            res.data?.client?.completeAddress?.pincode
          )
            .filter((item) => item?.length)
            .join(", ")
        );
        formik.setFieldValue("gstNo", res.data.client.gstin);
        if (!creditNoteId) {
          formik.setFieldValue(
            "placeOfSupply",
            res.data.client.completeAddress?.state
          );
        }
        if (creditNoteId) {
          formik.setFieldValue("clientName", res.data.client.name);
        }
        hideLoader2();
      }
    },
    getAllInvoices: async (params?: any) => {
      setItemsList([]);
      const res = await getInvoice(params);
      if (res) {
        setInvoiceList(res.data.invoices);
      }
    },
    getInvoiceById: async () => {
      setItemsList([]);
      showLoader3();
      const res = await getInvoiceById(formik.values.invoice);
      if (res) {
        setItemsList(res.data.invoice?.items);
        formik.setFieldValue(
          "invoiceDate",
          moment(res.data.invoice.invoiceDate).format("YYYY-MM-DD")
        );
        formik.setFieldValue("client", res.data.invoice.client?._id);
        formik.setFieldValue("clientName", res.data.invoice.client?.name);
        hideLoader3();
      }
    },
    getCreditNoteById: async () => {
      showLoader();
      const res = await getCreditNoteById(creditNoteId);
      if (res) {
        setCreditItemsToBeUpdate(res.data.creditNote.items);
        formik.setFieldValue("client", res.data.creditNote.client?._id);
        formik.setFieldValue(
          "placeOfSupply",
          res.data.creditNote.placeOfSupply
        );
        formik.setFieldValue(
          "invoiceNumber",
          res.data.creditNote.invoice.invoiceNumber
        );
        formik.setFieldValue(
          "creditNoteNumber",
          res.data.creditNote.creditNoteNumber
        );
        formik.setFieldValue("reason", res.data.creditNote.reason);
        formik.setFieldValue("invoice", res.data.creditNote.invoice._id);
        formik.setFieldValue(
          "creditNoteDate",
          moment(res.data.creditNote.creditNoteDate).format("YYYY-MM-DD")
        );
        formik.setFieldValue("items", res.data.creditNote.items);
        setCalculation({
          subTotal: res.data.creditNote?.calculation.subTotal,
          cgst: res.data.creditNote?.calculation.cgst,
          sgst: res.data.creditNote?.calculation.sgst,
          igst: res.data.creditNote?.calculation.igst,
          discount: res.data.creditNote?.calculation.discount,
          total: res.data.creditNote?.calculation.total,
        });
        setUpdationDetails({
          lastUpdate: res.data.creditNote.updatedAt,
          updatedBy: res.data.creditNote.updatedBy,
        });
        hideLoader();
      }
    },
  };

  const formik = useFormik<CreditNoteType>({
    initialValues: creditNote,
    validationSchema: creditNoteSchema,
    onSubmit: async (values) => {
      let finalItem: any = [];
      values?.items?.forEach((item) => {
        finalItem.push({
          name: item.name,
          hsnOrsac: item.hsnOrsac,
          description: item.description,
          unit: item.unit ?? "",
          qty: item.qty,
          type: item.type,
          unitQtyPrice: item.unitQtyPrice,
          discount: item.discount,
          tax: item.tax,
          total: item.total,
          id: item.id,
        });
      });

      showLoader();
      let finalObj = {};

      if (creditNoteId) {
        finalObj = {
          creditNoteDate: values.creditNoteDate,
          reason: values.reason,
          items: finalItem,
          placeOfSupply: values.placeOfSupply,
          calculation: calculation,
        };

        const res = await updateCreditNote(finalObj, creditNoteId, hideLoader);
        if (res) {
          showToast(`Credit note updated`, "success");
          res?.data?.items?.length &&
            showToast(
              <div>
                {res?.data?.items?.map((item: any, i: number) => {
                  return (
                    item?.stock <= 0 && (
                      <>
                        <p className="mb-0 font12x500">{`Please update ${item?.name} stock`}</p>
                      </>
                    )
                  );
                })}
              </div>,
              "success"
            );
          hideLoader();
        }
      } else {
        finalObj = {
          client: values.client,
          creditNoteNumber: values.creditNoteNumber,
          creditNoteDate: values.creditNoteDate,
          invoice: values.invoice,
          reason: values.reason,
          items: finalItem,
          placeOfSupply: values.placeOfSupply,
          calculation: calculation,
        };

        const res = await createCreditNote(finalObj, hideLoader);
        if (res.response && res.response.status === 502) {
          formik.setFieldError(
            "creditNoteNumber",
            "Credit note number already taken"
          );
        } else {
          showToast(`Credit note created`, "success");
          hideLoader();
          navigate(`/credit-note`);
        }
      }
    },
  });

  useEffect(() => {
    if (!creditNoteId) {
      fn.getDocNumber({ f: "creditNote" });
    }
    fn.getAllClients({
      company: companyId,
      f: "name",
      type: JSON.stringify([1, 3]),
    });
    fn.getAllInvoices({
      company: companyId,
      f: "invoiceNumber",
      isCancelled: false,
    });
    fn.getGstTaxes({
      f: "percent",
      type: 1,
      company: companyId,
    });
  }, []);
  useEffect(() => {
    if (formik.values.client) {
      fn.getClientById();
      fn.getAllInvoices({
        company: companyId,
        f: "invoiceNumber",
        client: formik.values.client,
        isCancelled: false,
      });
    }
  }, [formik.values.client]);
  useEffect(() => {
    if (formik.values.invoice) {
      fn.getInvoiceById();
    }
  }, [formik.values.invoice]);
  useEffect(() => {
    if (creditNoteId) {
      fn.getCreditNoteById();
    }
  }, [creditNoteId]);

  return (
    <>
      <div
        className="single_page"
        style={{
          overflow: "unset",
        }}
      >
        <div className="back_section px-3">
          <div
            className="cursor_pointer d-flex align-items-center"
            onClick={() => navigate(`/credit-note`)}
          >
            <img src="/Assets/Svg/backBtn.svg" alt="close" />
            <h3 className="mb-0 font16x600 textColor3 d-inline-block ms-2">
              Back to list
            </h3>
          </div>
        </div>
        <div className="form_section">
          <form onSubmit={formik.handleSubmit}>
            <CreditNoteContext.Provider
              value={{
                creditNoteId,
                formik,
                itemsList,
                clientList,
                invoiceList,
                singleItem,
                setSingleItem,
                loading,
                setCalculation,
                calculation,
                clientloading,
                invoiceloading,
                taxes,
                getDocNumber: fn.getDocNumber,
              }}
            >
              <Form />

              <AddProposalList />

              <Calculation />
            </CreditNoteContext.Provider>

            <div className="d-flex justify-content-end mt-5 px-3">
              {/* <button className="btn font12x500 shadow-none modalBtnOutline">
                Cancel
              </button> */}
              <button
                type="submit"
                className="btn font12x500 shadow-none custom_btn ms-3"
              >
                {creditNoteId ? (
                  loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Update"
                  )
                ) : loading ? (
                  <SpinLoader height="20" width="20" color="#fff" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>

          {creditNoteId && (
            <UpdationDetail
              lastUpdate={updationDetails.lastUpdate}
              updatedBy={updationDetails.updatedBy}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AddUpdateCreditNote;
