import React, { useContext, useState } from "react";
import { commonType } from "../../../../pages/main/lead";
import { ViewLeadContext } from "../../../../pages/main/lead/view lead";
import { ProfileContext } from "../../../../pages/main/lead/view lead/sub pages/profile";
import Form from "./Form";

const AddressDetails = () => {
  const { lead } = useContext(ViewLeadContext);
  const { step } = useContext(ProfileContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });

  const initialValues = {
    address: lead?.client?.completeAddress?.address ?? "--",
    city: lead?.client?.completeAddress?.city ?? "--",
    state: lead?.client?.completeAddress?.state ?? "--",
    pincode: lead?.client?.completeAddress?.pincode ?? "--",
    country: lead?.client?.completeAddress?.country ?? "India",
    clientId: lead?.client?._id ?? "",
    extra: {
      countryCode: lead?.client?.completeAddress?.cCode ?? "IN",
      stateCode: lead?.client?.completeAddress?.sCode ?? "",
    },
    lastUpdate: lead?.client?.updatedAt ?? "",
    updatedBy: lead?.client?.updatedBy ? lead?.client?.updatedBy : undefined,
  };

  const fn = {
    openForm: () => {
      setCommonData({
        data: initialValues,
        modal: true,
        check: "address_menu",
      });
      document.body.style.overflow = "hidden";
    },
  };

  return (
    <>
      <div className="profile_menu_content_section">
        <div className="d-flex justify-content-between align-items-center">
          <p className="mb-0 font12x500 textColor3">Address</p>
          <div
            className="d-flex justify-content-between align-items-center cursor_pointer"
            onClick={fn.openForm}
          >
            <img src="/Assets/Svg/editIcon.svg" alt="edit" />
            <p className="mb-0 font12x400 textColor2 ms-1">Edit</p>
          </div>
        </div>
        <div className="container-fluid p-0 mt-3">
          <div className="row">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">Address</p>
            </div>
            <div className="col-8">
              <p className="mb-0 font12x400 textColor3">
                {initialValues.address?.length > 0
                  ? initialValues.address
                  : "--"}
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">Country</p>
            </div>
            <div className="col-8 d-flex">
              <p className="mb-0 font12x400 textColor3">
                {initialValues.country?.length > 0
                  ? initialValues.country
                  : "--"}
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">State</p>
            </div>
            <div className="col-8 d-flex">
              <p className="mb-0 font12x400 textColor3">
                {initialValues.state?.length > 0 ? initialValues.state : "--"}
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">City</p>
            </div>
            <div className="col-8">
              <p className="mb-0 font12x400 textColor3">
                {initialValues.city?.length > 0 ? initialValues.city : "--"}
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <p className="mb-0 font12x400 textColor1">Pin Code</p>
            </div>
            <div className="col-8 d-flex">
              <p className="mb-0 font12x400 textColor3">
                {initialValues.pincode?.length > 0
                  ? initialValues.pincode
                  : "--"}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* form modal */}
      {commonData?.modal && commonData?.check === "address_menu" && (
        <Form step={step} state={commonData} setState={setCommonData} />
      )}
    </>
  );
};

export default AddressDetails;
