import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataNotFound from "../../../components/Data Not Found";
import Pagination from "../../../components/Pagination";
import MoveModal from "../../../components/Trashed Modal/MoveModal";
import CalendarFilter from "../../../Filters/CalendarFilter";
import DeleteFilter from "../../../Filters/DeleteFilter";
import useLoading from "../../../hooks/useLoading";
import { getCreditNote } from "../../../lib/api functions/credit_note";
import ShowSelectedDate from "../../../shared components/DateInput/ShowSelectedDate";
import SkeletonLoader from "../../../shared components/Loader/SkeletonLoader";
import Search from "../../../shared components/Search";
import { DELETE_CREDIT_NOTE_SOFT } from "../../../utils/constants";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import { commonType } from "../lead";
import { ParamType } from "./type";

const CreditNotes = () => {
  const { userId, companyId, roles, setting } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const [creditNotes, setCreditNotes] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    company: companyId,
    isDeleted: false,
  });

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getCreditNotes: async (params: ParamType) => {
      showLoader();
      const res = await getCreditNote(params);
      if (res) {
        setCreditNotes(res.data.creditNotes);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
    gettingSelectedData: (
      isChecked: boolean,
      check: string,
      value?: string
    ) => {
      if (check === "all") {
        if (isChecked) {
          setSelectedRecords([]);
          creditNotes?.map((item: any) => {
            setSelectedRecords((prev: any) => [...prev, item?._id]);
          });
        } else {
          setSelectedRecords([]);
        }
      } else {
        if (isChecked) {
          setSelectedRecords([...selectedRecords, value ?? ""]);
        } else {
          let filterSelectedValue = selectedRecords.filter(
            (item: any) => item !== value
          );
          setSelectedRecords(filterSelectedValue);
        }
      }
    },
  };

  useEffect(() => {
    if (roles?.length !== 0) {
      if (!roles[0]?.creditNotes.complete) {
        params.user = userId;
        fn.getCreditNotes(params);
      } else {
        fn.getCreditNotes(params);
      }
    }
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="data_render_wrapper">
          {/* filters */}
          <div className="filter_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-12 col-lg-4">
                  <Search
                    placeholder="Search by doc number"
                    state={params}
                    setState={setParams}
                    apiCall={fn.getCreditNotes}
                    paramValue="creditNoteNumber"
                  />
                </div>
                <div className="col-12 col-lg-8 pt-3 pt-lg-0">
                  <div className="d-flex align-items-center gap-3 justify-content-start justify-content-lg-end">
                    {params?.dF && (
                      <>
                        <ShowSelectedDate
                          label="From:"
                          value={moment(params?.dF?.split("_")[1]).format(
                            setting?.dateFormat
                          )}
                        />
                        <ShowSelectedDate
                          label="To:"
                          value={moment(params?.dF?.split("_")[2]).format(
                            setting?.dateFormat
                          )}
                        />
                      </>
                    )}

                    <div className="d-flex gap-2">
                      <CalendarFilter
                        state={params}
                        apiCall={fn.getCreditNotes}
                        setState={setParams}
                        fieldName="creditNoteDate"
                        label="Issue date"
                        isFutureDateRequired={true}
                      />

                      <DeleteFilter
                        url="/credit-note/trashed"
                        title="View trashed credit note"
                      />

                      <button
                        className="btn font12x500 buttonOne shadow-none"
                        onClick={() => navigate("/credit-note/form")}
                      >
                        <img
                          src="/Assets/Svg/plusIcon.svg"
                          alt="icon"
                          className="me-2"
                        />
                        Add New Credit Note
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="list_view_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center pb-3">
                <div className="col-12 col-lg-3">
                  <span className="font16x600 textColor3">
                    List of Credit Notes
                  </span>
                </div>
                <div className="col-12 col-lg-9 pt-3 pt-lg-0">
                  <div className="d-flex justify-content-start justify-content-lg-end">
                    {selectedRecords?.length !== 0 && (
                      <div>
                        <button
                          className="btn font12x500 shadow-none modalBtnColor"
                          onClick={() =>
                            fn.openModal(
                              {
                                creditNoteIds: selectedRecords,
                                isDeleted: true,
                              },
                              "move"
                            )
                          }
                          style={{
                            backgroundColor: "var(--error)",
                          }}
                        >
                          {`Move (${selectedRecords?.length})`}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* table */}
            {creditNotes?.length !== 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="font12x500 textColor3">
                        <th scope="col">
                          <div className="customCheck form-check d-flex align-items-center p-0">
                            <input
                              type="checkbox"
                              className="me-2"
                              id="checkbox"
                              name="rememberMe"
                              checked={
                                selectedRecords.length === creditNotes.length
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                fn.gettingSelectedData(e.target.checked, "all");
                              }}
                            />
                          </div>
                        </th>
                        <th scope="col">#</th>
                        <th scope="col">Issue Date</th>
                        <th scope="col">Doc No</th>
                        <th scope="col">Client Name</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Reason</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {creditNotes?.map((creditNote: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <th>
                              <div className="customCheck form-check d-flex align-items-center p-0">
                                <input
                                  type="checkbox"
                                  className="me-2"
                                  id="checkbox"
                                  name="rememberMe"
                                  value={creditNote?._id}
                                  checked={
                                    selectedRecords.includes(creditNote?._id)
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    fn.gettingSelectedData(
                                      e.target.checked,
                                      "single",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </th>
                            <th>
                              {(params.page - 1) * params.per_page + (i + 1)}
                            </th>
                            <td>
                              {moment(creditNote?.creditNoteDate).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td>{creditNote?.creditNoteNumber}</td>
                            <td>{creditNote?.client?.name}</td>
                            <td>{`₹ ${creditNote?.calculation?.total?.toLocaleString()}`}</td>
                            <td className="font12x500">{creditNote?.reason}</td>
                            <td>
                              <button
                                className="btn font12x500 buttonTwo shadow-none"
                                onClick={() =>
                                  navigate(
                                    `/credit-note/view/${creditNote?._id}`
                                  )
                                }
                              >
                                View Details
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : loading ? (
              <SkeletonLoader />
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>

        {/* pagination */}
        {creditNotes?.length !== 0 && (
          <div className="pagination_wrapper pt-3 px-3">
            <Pagination
              currentPage={params.page}
              onPageChange={(e) => {
                fn.getCreditNotes({ ...params, page: e });
                setParams({
                  ...params,
                  page: e,
                });
              }}
              pageSize={params.per_page}
              totalCount={Math.ceil(totalResult / params.per_page)}
              onChangePerPage={(e) => {
                fn.getCreditNotes({ ...params, per_page: e });
                setParams({
                  ...params,
                  per_page: e,
                });
              }}
            />
          </div>
        )}
      </div>

      {/* move modal */}
      {commonData?.modal && commonData?.check === "move" && (
        <MoveModal
          label="Credit Note"
          dataIds={commonData?.data}
          setState={setCommonData}
          url={DELETE_CREDIT_NOTE_SOFT}
          variableName="creditNoteIds"
          apiCall={fn.getCreditNotes}
          params={params}
          setSelectedRecords={setSelectedRecords}
        />
      )}
    </>
  );
};

export default CreditNotes;
