import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { gettingStates } from "../../../function/other";
import { InvoiceContext } from "../../../pages/main/invoice/add update invoice";
import InputDateForm from "../../../shared components/DateInput/InputDateForm";
import DropdownOne from "../../../shared components/Dropdown/DropdownOne";
import DropdownTwo from "../../../shared components/Dropdown/DropdownTwo";
import InputError from "../../../shared components/InputError/InputError";
import { AiOutlineReload } from "react-icons/ai";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";

const Form = () => {
  const { documentSetting } = useContext(MainContext);
  const {
    invoiceId,
    loading,
    formik,
    clientList,
    clientloading,
    getDocNumber,
  } = useContext(InvoiceContext);

  const [states, setStates] = useState<string[]>([]);

  useEffect(() => {
    setStates(gettingStates("IN"));
  }, []);

  return (
    <>
      <div className="container-fluid px-4">
        <div
          className="row py-2"
          style={{
            backgroundColor: "var(--bg_color5)",
            borderRadius: "8px",
          }}
        >
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <DropdownTwo
                label="Client Name*"
                placeholder="Select client"
                name="clientName"
                data={clientList}
                state={formik.values.clientName}
                setState={(e?: string, x?: string | number) => {
                  formik.setFieldValue("clientName", e);
                  formik.setFieldValue("client", x);
                }}
                isDisabled={invoiceId ? true : false}
                labelField="name"
                onBlur={() => formik.setFieldTouched("clientName", true)}
              />
              {formik.touched.clientName && formik.errors.clientName ? (
                <InputError error={formik.errors.clientName} />
              ) : null}
            </div>

            {clientloading ? (
              <Skeleton width="120px" borderRadius={4} />
            ) : (
              formik.values.placeOfSupply && (
                <>
                  <div>
                    <p className="mb-0 font12x400 textColor3">
                      <span className="font12x600 textColor3">GSTIN: </span>
                      {formik.values.gstNo}
                    </p>
                  </div>

                  <div className="my-3">
                    <DropdownOne
                      label="Place of Supply*"
                      placeholder="Place of supply"
                      name="placeOfSupply"
                      data={states}
                      state={formik.values.placeOfSupply}
                      setState={(e?: string, x?: string | number) => {
                        formik.setFieldValue("placeOfSupply", e);
                      }}
                      onBlur={() =>
                        formik.setFieldTouched("placeOfSupply", true)
                      }
                    />
                    {formik.touched.placeOfSupply &&
                    formik.errors.placeOfSupply ? (
                      <InputError error={formik.errors.placeOfSupply} />
                    ) : null}
                  </div>
                </>
              )
            )}
          </div>
          <div className="col-12 col-lg-6">
            {clientloading ? (
              <Skeleton width="200px" borderRadius={4} />
            ) : (
              formik.values.clientAddress && (
                <div>
                  <p className="mb-0 font12x600 textColor3 mb-0 mb-lg-2">
                    Billing Address:
                  </p>
                  <p className="mb-0 font12x400 textColor3">
                    {clientloading ? (
                      <Skeleton width="200px" borderRadius={4} />
                    ) : (
                      formik.values.clientAddress
                    )}
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div className="container-fluid mt-3 px-3">
        <div className="row">
          <div className="mb-3 col-12 col-lg-6">
            <label
              htmlFor="invoiceNumber"
              className="form-label font12x400 textColor3 mb-1"
            >
              Invoice No*
            </label>
            <div className="input-group">
              {!invoiceId && (
                <div className="input-group-text font12x600 border-0">
                  {documentSetting?.invoice?.initials !== ""
                    ? `${documentSetting?.invoice?.initials}/`
                    : ""}
                </div>
              )}
              <input
                type="text"
                className="form-control shadow-none font12x600 border-0"
                id="invoiceNumber"
                value={formik.values.invoiceNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={invoiceId ? true : false}
                style={{
                  cursor: invoiceId ? "no-drop" : "unset",
                  backgroundColor: invoiceId ? "#f2f2f2" : "#fff",
                  color: invoiceId ? "#9C9E9C" : "var(--text3)",
                }}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              {formik.touched.invoiceNumber && formik.errors.invoiceNumber ? (
                <InputError error={formik.errors.invoiceNumber} />
              ) : null}
              {formik.touched.invoiceNumber && formik.errors.invoiceNumber ? (
                <AiOutlineReload
                  className="cursor_pointer"
                  onClick={() => getDocNumber({ f: "invoice" })}
                />
              ) : null}
            </div>
          </div>
          <div className="mb-3 col-12 col-lg-6">
            <InputDateForm
              id="invoiceDate"
              label="Invoice Date*"
              type="date"
              state={moment(formik.values.invoiceDate).format("YYYY-MM-DD")}
              setState={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.invoiceDate && formik.errors.invoiceDate ? (
              <InputError error={formik.errors.invoiceDate} />
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="mb-3 col-12 col-lg-6">
            <InputDateForm
              id="dueDate"
              label="Due Date*"
              type="date"
              state={moment(formik.values.dueDate).format("YYYY-MM-DD")}
              setState={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.dueDate && formik.errors.dueDate ? (
              <InputError error={formik.errors.dueDate} />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
