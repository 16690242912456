import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import Api from "../../config/api";
import { showToast } from "../../lib/toast/ShowToast";

type propType = {
  openFormModal: () => void;
  label: string;
  dataIds: any;
  url?: string;
  apiCall?: (val: any) => void;
  params?: any;
};

const KebabMenu: React.FC<propType> = (props) => {
  const { openFormModal, label, dataIds, url, apiCall, params } = props;
  const [isOpen, setIsOpen] = useState(false);
  const fn = {
    openDropdown: () => {
      setIsOpen(!isOpen);
    },
    closeDropdown: () => {
      setIsOpen(false);
    },
    deleteData: async () => {
      try {
        const res = await Api(false).delete(`${url}`, { data: dataIds });
        if (res) {
          showToast(`${label} Deleted`, "success");
          apiCall?.(params);
        }
      } catch (error: any) {
        if (error.response.status >= 400 && error.response.status <= 499) {
          showToast(`${error.response.statusText}`, "error");
        } else {
          showToast(`${error.response.data.message}`, "error");
        }
      }
    },
  };

  return (
    <>
      <OutsideClickHandler onOutsideClick={fn.closeDropdown}>
        <div className="position-relative" onClick={fn.openDropdown}>
          <span className="cursor_pointer">...</span>

          {isOpen && (
            <div className="kebab_menus_section">
              <div className="kebab_menus" onClick={openFormModal}>
                <span className="font12x400 textColor3">Edit</span>
              </div>
              <div className="kebab_menus" onClick={fn.deleteData}>
                <span className="font12x400 textColor3">Delete</span>
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default KebabMenu;
