import { useFormik } from "formik";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddProposalList from "../../../../components/Invoice components/Add Update Components/AddProposalList";
import Calculation from "../../../../components/Invoice components/Add Update Components/Calculation";
import Form from "../../../../components/Invoice components/Add Update Components/Form";
import useLoading from "../../../../hooks/useLoading";
import { getClient, getClientById } from "../../../../lib/api functions/client";
import {
  createInvoice,
  getInvoiceById,
  updateInvoice,
} from "../../../../lib/api functions/invoice/invoice";
import { getDocumentNumberById } from "../../../../lib/api functions/common";
import { getItem, getItemById } from "../../../../lib/api functions/item";
import { getTax } from "../../../../lib/api functions/setting/tax";
import { showToast } from "../../../../lib/toast/ShowToast";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import { invoiceSchema } from "../../../../utils/validationSchema";
import { InvoiceType, ItemsType } from "./type";
import UpdationDetail from "../../../../components/Data Update Detail";
import { generateCompleteAddress } from "../../../../function/other";

export const InvoiceContext = createContext<any>(null);

const AddUpdateInvoice = () => {
  const { setPageTitle, userId, companyId } = useContext(MainContext);
  const { invoiceId } = useParams();
  useEffect(() => {
    setPageTitle(invoiceId ? "Update Invoice" : "Add Invoice");
  }, []);
  const companyObj = JSON.parse(window.localStorage.getItem("company") || "");
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const [clientloading, showLoader2, hideLoader2] = useLoading();
  const [itemsList, setItemsList] = useState<any>([]);
  const [invoiceItemsToBeUpdate, setInvoiceItemsToBeUpdate] = useState<any>([]);
  const [clientList, setClientList] = useState<any>([]);
  const [taxes, setTaxes] = useState<any>([]);
  const [calculation, setCalculation] = useState<any>({
    subTotal: 0,
    cgst: 0,
    sgst: 0,
    igst: 0,
    discount: 0,
    total: 0,
  });
  const [invoice, setInvoice] = useState<InvoiceType>({
    client: "",
    clientName: "",
    clientAddress: "",
    placeOfSupply: "",
    companyState: companyObj?.state,
    gstNo: "",
    invoiceNumber: "",
    invoiceDate: "",
    dueDate: "",
    items: [],
  });
  const [singleItem, setSingleItem] = useState<ItemsType>({
    id: "",
    name: "",
    description: "",
    unit: "",
    hsnOrsac: "",
    qty: 1,
    tempQty: 0,
    type: 0,
    unitQtyPrice: 0,
    discount: 0,
    cgst: 0,
    sgst: 0,
    igst: 0,
    tax: 0,
    total: 0,
    error: "",
    isError: false,
    isEditable: false,
  });
  const [updationDetails, setUpdationDetails] = useState({
    lastUpdate: "",
    updatedBy: undefined,
  });

  const fn = {
    getDocNumber: async (params?: any) => {
      const res = await getDocumentNumberById(companyId, params);
      if (res) {
        formik.setFieldValue(
          "invoiceNumber",
          res.data.documentNumber.invoice.number.toString()
        );
      }
    },
    getItems: async (params?: any) => {
      const res = await getItem(params);
      if (res) {
        setItemsList(res.data.items);
      }
    },
    getItemById: async () => {
      // showLoader2();
      const res = await getItemById(singleItem?.id);
      if (res) {
        if (res.data.item?.type === 1 && res.data.item?.stock <= 0) {
          showToast(`${res.data.item?.name} is out of stock`, "error");
          setSingleItem({
            ...singleItem,
            id: "",
            name: "",
          });
        } else {
          setSingleItem({
            ...singleItem,
            description: res.data.item?.description,
            hsnOrsac: res.data.item?.hsn
              ? res.data.item?.hsn
              : res.data.item?.sac,
            unitQtyPrice: res.data.item?.unitAmount,
            unit: res.data.item?.unit,
            tax: res.data.item?.tax,
            type: res.data.item?.type,
            qty: 1,
            tempQty: res.data.item?.type === 1 ? res.data.item?.stock : 0,
          });
        }
        // hideLoader2();
      }
    },
    getGstTaxes: async (params?: any) => {
      const res = await getTax(params);
      if (res) {
        setTaxes(res.data.taxes);
      }
    },
    getAllClients: async (params?: any) => {
      const res = await getClient(params);
      if (res) {
        setClientList(res.data.clients);
      }
    },
    getClientById: async () => {
      showLoader2();
      const res = await getClientById(formik.values.client);
      if (res) {
        formik.setFieldValue(
          "clientAddress",
          generateCompleteAddress(
            res.data?.client?.completeAddress?.address,
            res.data?.client?.completeAddress?.city,
            res.data?.client?.completeAddress?.state,
            res.data?.client?.completeAddress?.country,
            res.data?.client?.completeAddress?.pincode
          )
            .filter((item) => item?.length)
            .join(", ")
        );
        formik.setFieldValue("gstNo", res.data.client.gstin);
        if (!invoiceId) {
          formik.setFieldValue(
            "placeOfSupply",
            res.data.client.completeAddress?.state
          );
        }
        if (invoiceId) {
          formik.setFieldValue("clientName", res.data.client.name);
        }
        hideLoader2();
      }
    },
    getInvoiceById: async () => {
      showLoader();
      const res = await getInvoiceById(invoiceId);
      if (res) {
        setInvoiceItemsToBeUpdate(res.data.invoice.items);

        formik.setFieldValue("client", res.data.invoice.client?._id);
        formik.setFieldValue("invoiceNumber", res.data.invoice.invoiceNumber);
        formik.setFieldValue("placeOfSupply", res.data.invoice.placeOfSupply);
        formik.setFieldValue(
          "invoiceDate",
          moment(res.data.invoice.invoiceDate).format("YYYY-MM-DD")
        );
        formik.setFieldValue(
          "dueDate",
          moment(res.data.invoice.dueDate).format("YYYY-MM-DD")
        );
        formik.setFieldValue("items", res.data.invoice.items);
        setCalculation({
          subTotal: res.data.invoice?.calculation.subTotal,
          cgst: res.data.invoice?.calculation.cgst,
          sgst: res.data.invoice?.calculation.sgst,
          igst: res.data.invoice?.calculation.igst,
          discount: res.data.invoice?.calculation.discount,
          total: res.data.invoice?.calculation.total,
        });
        setUpdationDetails({
          lastUpdate: res.data.invoice.updatedAt,
          updatedBy: res.data.invoice.updatedBy,
        });
        hideLoader();
      }
    },
  };
  const formik = useFormik<InvoiceType>({
    initialValues: invoice,
    validationSchema: invoiceSchema,
    onSubmit: async (values) => {
      let finalItem: any = [];
      values?.items?.forEach((item) => {
        finalItem.push({
          name: item.name,
          hsnOrsac: item.hsnOrsac,
          description: item.description,
          unit: item.unit ?? "",
          qty: item.qty,
          type: item.type,
          unitQtyPrice: item.unitQtyPrice,
          discount: item.discount,
          tax: item.tax,
          total: item.total,
          id: item.id,
        });
      });

      showLoader();
      let finalObj = {};

      if (invoiceId) {
        finalObj = {
          invoiceDate: values.invoiceDate,
          dueDate: values.dueDate,
          items: finalItem,
          placeOfSupply: values.placeOfSupply,
          calculation: calculation,
        };

        const res = await updateInvoice(finalObj, invoiceId, hideLoader);
        if (res) {
          showToast(`Invoice updated`, "success");
          res?.data?.items?.length &&
            showToast(
              <div>
                {res?.data?.items?.map((item: any, i: number) => {
                  return (
                    item?.stock <= 0 && (
                      <>
                        <p className="mb-0 font12x500">{`Please update ${item?.name} stock`}</p>
                      </>
                    )
                  );
                })}
              </div>,
              "success"
            );
          hideLoader();
        }
      } else {
        finalObj = {
          client: values.client,
          invoiceNumber: values.invoiceNumber,
          invoiceDate: values.invoiceDate,
          dueDate: values.dueDate,
          placeOfSupply: values.placeOfSupply,
          items: finalItem,
          calculation: calculation,
        };

        const res = await createInvoice(finalObj, hideLoader);
        if (res.response && res.response.status === 502) {
          formik.setFieldError("invoiceNumber", "Invoice number already taken");
        } else {
          showToast(`Invoice created`, "success");
          hideLoader();
          navigate(`/invoice`);
        }
      }
    },
  });

  useEffect(() => {
    fn.getItems({ company: companyId, f: "name", isDeleted: false });
    if (!invoiceId) {
      fn.getDocNumber({ f: "invoice" });
    }
    fn.getAllClients({
      company: companyId,
      f: "name",
      type: JSON.stringify([1, 3]),
    });
    fn.getGstTaxes({
      f: "percent",
      type: 1,
      company: companyId,
    });
  }, []);
  useEffect(() => {
    if (formik.values.client) {
      fn.getClientById();
    }
  }, [formik.values.client]);
  useEffect(() => {
    if (singleItem?.id) {
      fn.getItemById();
    }
  }, [singleItem?.id]);
  useEffect(() => {
    if (invoiceId) {
      fn.getInvoiceById();
    }
  }, [invoiceId]);

  return (
    <>
      <div
        className="single_page"
        style={{
          overflow: "unset",
        }}
      >
        <div className="back_section px-3">
          <div
            className="cursor_pointer d-flex align-items-center"
            onClick={() => navigate(`/invoice`)}
          >
            <img src="/Assets/Svg/backBtn.svg" alt="close" />
            <h3 className="mb-0 font16x600 textColor3 d-inline-block ms-2">
              Back to list
            </h3>
          </div>
        </div>
        <div className="form_section">
          <form onSubmit={formik.handleSubmit}>
            <InvoiceContext.Provider
              value={{
                invoiceId,
                formik,
                itemsList,
                clientList,
                singleItem,
                setSingleItem,
                loading,
                setCalculation,
                calculation,
                clientloading,
                taxes,
                getDocNumber: fn.getDocNumber,
              }}
            >
              <Form />

              <AddProposalList />

              <Calculation />
            </InvoiceContext.Provider>

            <div className="d-flex justify-content-end mt-5 px-3">
              {/* <button className="btn font12x500 shadow-none modalBtnOutline">
                Cancel
              </button> */}
              <button
                type="submit"
                className="btn font12x500 shadow-none custom_btn ms-3"
              >
                {invoiceId ? (
                  loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Update"
                  )
                ) : loading ? (
                  <SpinLoader height="20" width="20" color="#fff" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>

          {invoiceId && (
            <UpdationDetail
              lastUpdate={updationDetails.lastUpdate}
              updatedBy={updationDetails.updatedBy}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AddUpdateInvoice;
