import Api from "../../../config/api";
import {
  CREATE_MEETING,
  SEARCH_MEETING,
  SEARCH_MEETING_BY_ID,
  UPDATE_MEETING,
} from "../../../utils/constants";
import { showToast } from "../../toast/ShowToast";

export const getMeeting = async (params: any) => {
  try {
    const res = await Api(false).get(SEARCH_MEETING, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const getMeetingById = async (id?: string) => {
  try {
    const res = await Api(false).get(`${SEARCH_MEETING_BY_ID}/${id}`);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const createMeeting = async (body: any, hideLoader: () => void) => {
  try {
    const res = await Api(false).post(CREATE_MEETING, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const updateMeeting = async (
  body: any,
  id: string,
  hideLoader: () => void
) => {
  try {
    const res = await Api(false).put(`${UPDATE_MEETING}/${id}`, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};
