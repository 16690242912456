import React from "react";
import ViewCredential from "../../../pages/main/lead/view lead/sub pages/credential";
import ViewInvoice from "../../../pages/main/lead/view lead/sub pages/invoice";
import ViewPayment from "../../../pages/main/lead/view lead/sub pages/payment";
import ViewPo from "../../../pages/main/lead/view lead/sub pages/po";
import ViewProfile from "../../../pages/main/lead/view lead/sub pages/profile";
import ViewProposal from "../../../pages/main/lead/view lead/sub pages/proposal";
import ViewQuotation from "../../../pages/main/lead/view lead/sub pages/quotation";
import ViewRequestDemo from "../../../pages/main/lead/view lead/sub pages/request demo";
import ViewRequestInvoice from "../../../pages/main/lead/view lead/sub pages/request invoice";
import ViewUpdate from "../../../pages/main/lead/view lead/sub pages/update";
import {
  VIEWP_QUOTATION,
  VIEW_CREDENTIAL,
  VIEW_INVOICE,
  VIEW_PAYMENT,
  VIEW_PO,
  VIEW_PROFILE,
  VIEW_PROPOSAL,
  VIEW_REQUEST_DEMO,
  VIEW_REQUEST_INVOICE,
  VIEW_UPDATE,
} from "../../../utils/constants";

type propType = {
  routeName: string;
};

const MenuData: React.FC<propType> = ({ routeName }) => {
  switch (routeName) {
    case VIEW_PROFILE:
      return (
        <>
          <ViewProfile />
        </>
      );
    case VIEW_UPDATE:
      return (
        <>
          <ViewUpdate />
        </>
      );
    case VIEW_PROPOSAL:
      return (
        <>
          <ViewProposal />
        </>
      );
    case VIEWP_QUOTATION:
      return (
        <>
          <ViewQuotation />
        </>
      );
    case VIEW_PO:
      return (
        <>
          <ViewPo />
        </>
      );
    case VIEW_INVOICE:
      return (
        <>
          <ViewInvoice />
        </>
      );
    case VIEW_REQUEST_INVOICE:
      return (
        <>
          <ViewRequestInvoice />
        </>
      );
    case VIEW_PAYMENT:
      return (
        <>
          <ViewPayment />
        </>
      );
    case VIEW_CREDENTIAL:
      return (
        <>
          <ViewCredential />
        </>
      );
    case VIEW_REQUEST_DEMO:
      return (
        <>
          <ViewRequestDemo />
        </>
      );
    default:
      return null;
  }
};

export default MenuData;
