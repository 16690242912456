import React, { useContext, useEffect, useState } from "react";
import DataNotFound from "../../../../components/Data Not Found";
import Pagination from "../../../../components/Pagination";
import useLoading from "../../../../hooks/useLoading";
import { getItem, restoreItem } from "../../../../lib/api functions/item";
import { showToast } from "../../../../lib/toast/ShowToast";
import SkeletonLoader from "../../../../shared components/Loader/SkeletonLoader";
import Search from "../../../../shared components/Search";
import { itemTypesMap } from "../../../../utils/constants";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import { ParamType } from "./../type";

const TrashedItem = () => {
  const { setPageTitle, userId, companyId, roles, setting } =
    useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [items, setItems] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    company: companyId,
    isDeleted: true,
  });

  const fn = {
    getItems: async (params?: ParamType) => {
      showLoader();
      const res = await getItem(params);
      if (res) {
        setItems(res.data.items);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
    restoreItem: async (itemId: string) => {
      let finalObj = {
        itemIds: [itemId],
        isDeleted: false,
      };
      const res = await restoreItem(finalObj, hideLoader);
      if (res) {
        showToast(`Item Restored`, "success");
        fn.getItems(params);
      }
    },
  };

  useEffect(() => {
    if (roles?.length !== 0) {
      if (!roles[0]?.items.complete) {
        params.user = userId;
        fn.getItems(params);
      } else {
        fn.getItems(params);
      }
    }
  }, []);

  useEffect(() => {
    setPageTitle("Trashed Item");
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="data_render_wrapper">
          {/* filters */}
          <div className="filter_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-12 col-lg-4">
                  <Search
                    placeholder="Search by item name"
                    state={params}
                    setState={setParams}
                    apiCall={fn.getItems}
                    paramValue="name"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="list_view_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center pb-3">
                <div className="col-12 col-lg-3">
                  <span className="font16x600 textColor3">
                    List of Trashed Items
                  </span>
                </div>
              </div>
            </div>

            {/* table */}
            {items?.length !== 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="font12x500 textColor3">
                        <th scope="col">#</th>
                        <th scope="col">Item Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Type</th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Unit</th>
                        <th scope="col">HSN/SAC</th>
                        <th scope="col">Tax</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items?.map((item: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <th>
                              {(params.page - 1) * params.per_page + (i + 1)}
                            </th>
                            <td>{item?.name}</td>
                            <td
                              title={item?.description}
                              style={{ maxWidth: 200 }}
                            >
                              {`${
                                item?.description?.length >= 90
                                  ? item?.description?.substring(0, 90) + "..."
                                  : item?.description
                              }`}
                            </td>
                            <td>{itemTypesMap.get(item?.type)}</td>
                            <td>{`₹ ${item?.unitAmount?.toLocaleString()}`}</td>
                            <td>{item.unit}</td>
                            <td>
                              {item?.type === 1
                                ? item?.hsn
                                : item?.type === 2
                                ? item?.sac
                                : "--"}
                            </td>
                            <td>{`${item?.tax}%`}</td>
                            <td>{item?.stock}</td>
                            <td>
                              <button
                                className="btn font12x500 buttonTwo shadow-none"
                                onClick={() => fn.restoreItem(item?._id)}
                              >
                                Restore
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : loading ? (
              <SkeletonLoader />
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>

        {/* pagination */}
        {items?.length !== 0 && (
          <div className="pagination_wrapper pt-3 px-3">
            <Pagination
              currentPage={params.page}
              onPageChange={(e) => {
                fn.getItems({ ...params, page: e });
                setParams({
                  ...params,
                  page: e,
                });
              }}
              pageSize={params.per_page}
              totalCount={Math.ceil(totalResult / params.per_page)}
              onChangePerPage={(e) => {
                fn.getItems({ ...params, per_page: e });
                setParams({
                  ...params,
                  per_page: e,
                });
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TrashedItem;
