import { State, City } from "country-state-city";
import { constants } from "fs/promises";
import moment from "moment";

// getting cities
export const gettingCities = (countryCode: string, stateCode: string): any => {
  let citiesMap = new Map();
  City.getCitiesOfState(countryCode, stateCode).map((city) => {
    citiesMap.set(city.name, city.name);
  });
  return citiesMap;
};

// getting states
export const gettingStates = (countryCode: string): any => {
  let statesMap = new Map();
  State.getStatesOfCountry(countryCode).map((state) => {
    statesMap.set(state.isoCode, state.name);
  });
  return statesMap;
};

export const checkFileSize = (files: any) => {
  let valid = true;
  if (files) {
    files?.map((file: any) => {
      const size = file?.size / 1024;
      if (size > 1024) {
        valid = false;
      }
    });
  }
  return valid;
};

export const checkFileFormat = (files: any) => {
  let valid = true;
  if (files) {
    files?.map((file: any) => {
      if (!["image/jpeg", "image/png", "image/jpg"].includes(file?.type)) {
        valid = false;
      }
    });
  }
  return valid;
};

export const checkFileFormatPDF = (files: any) => {
  let valid = true;
  if (files) {
    files?.map((file: any) => {
      if (!["application/pdf"].includes(file?.type)) {
        valid = false;
      }
    });
  }
  return valid;
};

export const checkFileAllFormat = (files: any) => {
  let valid = true;
  if (files) {
    files?.map((file: any) => {
      if (
        !["application/pdf", "image/jpeg", "image/png", "image/jpg"].includes(
          file?.type
        )
      ) {
        valid = false;
      }
    });
  }
  return valid;
};

//get default initial of user
export const getDefaultInitial = (str: string): string => {
  let arr = str?.split(" ");
  let finalStr = "";
  if (arr?.length === 1) {
    finalStr = `${arr[0]?.charAt(0)}`;
  } else {
    finalStr = `${arr[0]?.charAt(0)}${arr[arr?.length - 1]?.charAt(0)}`;
  }
  return finalStr?.toUpperCase();
};

//rounding function
export const roundToTwo = (num: number) => {
  return +(Math.round(+(num + "e+2")) + "e-2");
};

//handle OTP time
export const handleTime = (expireTime: number): string => {
  let s = expireTime % 60 > 9 ? expireTime % 60 : "0" + (expireTime % 60);
  let m =
    Math.floor(expireTime / 60) > 9
      ? Math.floor(expireTime / 60)
      : "0" + Math.floor(expireTime / 60);
  return `${m}:${s}`;
};

//change timestamp to string for signin
export const changeTimeStamps = (arr: string[], convert: string): string[] => {
  let tempArr: string[] = [];
  if (convert === "timeToString") {
    arr.map((item: any) => {
      tempArr.push(moment(item).format("HH:mm"));
    });
  } else {
    arr.map((item: any) => {
      tempArr.push(moment(item, "HH:mm").toISOString());
    });
  }
  return tempArr;
};

//change timestamp to string for signout
export const changeSignOutTimeStamps = (
  arr: string[],
  signInLength: number,
  signOutLength: number,
  convert: string
): string[] => {
  let tempArr: string[] = [];
  if (convert === "timeToString") {
    if (signInLength > signOutLength) {
      arr.map((item: any) => {
        tempArr.push(moment(item).format("HH:mm"));
      });
      tempArr[signOutLength] = "";
    } else {
      arr.map((item: any) => {
        tempArr.push(moment(item).format("HH:mm"));
      });
    }
  } else {
    arr.map((item: any) => {
      tempArr.push(moment(item, "HH:mm").toISOString());
    });
  }
  return tempArr;
};

//calculate user attendance total time
export const calculateTotalTime = (data: any): string => {
  let totalTime = "00:00";

  data &&
    data?.map((item: any) => {
      const [timeHHString, timeMMString] = totalTime.split(":");
      const [addTimeHHString, addTimeMMString] = item.timeDevoted.split(":");
      const timeHH = parseInt(timeHHString);
      const timeMM = parseInt(timeMMString);
      const addTimeHH = parseInt(addTimeHHString);
      const addTimeMM = parseInt(addTimeMMString);
      let minutes = timeMM + addTimeMM;
      let hours = timeHH + addTimeHH;
      if (minutes > 59) {
        minutes -= 60;
        hours += 1;
      }
      let minutesString =
        minutes >= 0 && minutes <= 9 ? `0${minutes}` : `${minutes}`;
      let hoursString = hours >= 0 && hours <= 9 ? `0${hours}` : `${hours}`;

      totalTime = `${hoursString}:${minutesString}`;
    });

  return totalTime;
};

//calculate user total day devoted
export const calculateDayDevoted = (data: any): number => {
  let totalTime: number = 0;

  data &&
    data?.map((item: any) => {
      totalTime += item?.dayDevoted;
    });

  return totalTime;
};

//generate complete address string
export const generateCompleteAddress = (
  address: string,
  city: string,
  state: string,
  country: string,
  pincode: string
): string[] => {
  let str: string[] = [];
  str.push(address, city, state, country, pincode);
  return str;
};

//get user current location details
type LocationDetailsType = {
  latitude: number | null;
  longitude: number | null;
  error?: string;
};
export const getCurrentLocationDetails = () => {
  let locationDetails: LocationDetailsType = {
    latitude: null,
    longitude: null,
    error: undefined,
  };
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        locationDetails.latitude = +position.coords.latitude;
        locationDetails.longitude = +position.coords.longitude;
      },
      (error: GeolocationPositionError) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            locationDetails.error = "User denied the request for Geolocation.";
            break;
          case error.POSITION_UNAVAILABLE:
            locationDetails.error = "Location information is unavailable.";
            break;
          case error.TIMEOUT:
            locationDetails.error =
              "The request to get user location timed out.";
            break;
          default:
            locationDetails.error = "An unknown error occurred.";
            break;
        }
      }
    );
  }
  return locationDetails;
};
//get user current location details

export const checkPhoneCodeForPlus = (phoneCode: string): string => {
  if (phoneCode.charAt(0) === "+") {
    return phoneCode;
  } else {
    return `+${phoneCode}`;
  }
};

//get default initial of user
export const getInitialOfName = (str: string): string => {
  let arr = str?.split(" ");
  let finalStr = "";
  if (arr?.length === 1) {
    finalStr = `${arr[0]?.charAt(0)}`;
  } else {
    finalStr = `${arr[0]?.charAt(0)}${arr[1]?.charAt(0)}`;
  }
  return finalStr?.toUpperCase();
};

//get current month available PL
export const getCurrentMonthAvailablePL = (user: any): number => {
  let availablePL =
    user?.currentMonthPL?.available -
    user?.currentMonthPL?.applied -
    user?.currentMonthPL?.approved -
    user?.currentMonthPL?.credited;

  if (isNaN(availablePL)) {
    availablePL = 0;
  }

  return availablePL;
};

//get next month available PL
export const getNextMonthAvailablePL = (user: any): number => {
  let availablePL =
    user?.nextMonthPL?.available -
    user?.nextMonthPL?.applied -
    user?.nextMonthPL?.approved -
    user?.nextMonthPL?.credited;

  if (isNaN(availablePL)) {
    availablePL = 0;
  }

  return availablePL;
};
