import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonLoader = () => {
  return (
    <>
      <div className="loader_wrapper mt-3">
        {[...Array(6)].map((item, i) => {
          return (
            <div
              key={i}
              className="d-flex justify-content-between mb-3 gap-3 gap-md-0"
            >
              <Skeleton width="20px" height="20px" borderRadius={4} />
              <Skeleton width="20px" height="20px" borderRadius={4} />
              <Skeleton width="120px" height="20px" borderRadius={4} />
              <Skeleton width="110px" height="20px" borderRadius={4} />
              <Skeleton width="80px" height="20px" borderRadius={4} />
              <Skeleton width="80px" height="20px" borderRadius={4} />
              <Skeleton width="60px" height="20px" borderRadius={4} />
              <Skeleton width="90px" height="20px" borderRadius={4} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SkeletonLoader;
