import React from "react";

type proptype = {
  id: string;
  label?: string;
  type: string;
  isDisabled?: boolean;
  state?: string;
  setState?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: any;
  width?: string;
  max?: string;
  min?: string;
};

const InputDateForm: React.FC<proptype> = (props) => {
  const {
    id,
    label,
    type,
    isDisabled,
    state,
    setState,
    onBlur,
    width,
    max,
    min,
  } = props;

  return (
    <>
      {label && (
        <label htmlFor={id} className="form-label font12x400 textColor3 mb-1">
          {label}
        </label>
      )}
      <div
        className="input_wrapper d-flex align-items-center"
        style={{
          backgroundColor: isDisabled ? "#f2f2f2" : "#fff",
          width: width && width,
        }}
      >
        <input
          type={type}
          className="form-control shadow-none input_field"
          id={id}
          value={state}
          onChange={setState}
          disabled={isDisabled}
          style={{
            cursor: isDisabled ? "no-drop" : "unset",
            backgroundColor: isDisabled ? "#f2f2f2" : "#fff",
            color: isDisabled ? "#9C9E9C" : "var(--text3)",
          }}
          min={min ?? ""}
          max={max ?? ""}
          onBlur={onBlur}
        />
      </div>
    </>
  );
};

export default InputDateForm;
