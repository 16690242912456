import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BillingDetails from "../../../../components/Vendor Components/View/BillingDetails";
import CompanyDetails from "../../../../components/Vendor Components/View/CompanyDetails";
import useLoading from "../../../../hooks/useLoading";
import { getClientById } from "../../../../lib/api functions/client";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";

const ViewVendor = () => {
  const { setPageTitle, setting } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [client, setClient] = useState<any>([]);
  const navigate = useNavigate();
  const { vendorId } = useParams();
  const fn = {
    getClientById: async () => {
      showLoader();
      const res = await getClientById(vendorId);
      if (res) {
        setClient(res.data.client);
        hideLoader();
      }
    },
  };
  useEffect(() => {
    fn.getClientById();
    setPageTitle("Vendor Profile View");
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="back_section justify-content-between justify-content-sm-start px-3">
          <div
            className="cursor_pointer d-flex align-items-center"
            onClick={() => navigate("/vendor")}
          >
            <img src="/Assets/Svg/backBtn.svg" alt="close" />
            <h3 className="mb-0 font16x600 textColor3 d-inline-block ms-2">
              Back to list
            </h3>
          </div>
          <div
            className="d-flex justify-content-between align-items-center cursor_pointer ms-0 ms-sm-5"
            onClick={() =>
              navigate(`/vendor/form/${vendorId}`, { state: { client } })
            }
          >
            <img src="/Assets/Svg/editIcon.svg" alt="edit" />
            <p className="mb-0 font12x400 textColor2 ms-1">Edit</p>
          </div>
        </div>

        <div>
          <CompanyDetails loading={loading} data={client} />

          <BillingDetails loading={loading} data={client?.completeAddress} />
        </div>

        {/* updation details */}
        <div className="mt-5 px-3">
          <p className="mb-0 font12x400 textColor1">
            <span className="font12x500">Last Update: </span>{" "}
            {client?.updatedAt
              ? moment(client?.updatedAt).format(
                  `${setting?.dateFormat} - hh:mm a`
                )
              : "--"}
          </p>
          {client?.updatedBy && (
            <p className="mb-0 font12x400 textColor1">
              <span className="font12x500">By: </span>{" "}
              {client?.updatedBy
                ? `${client?.updatedBy?.name} (${client?.updatedBy?.role})`
                : "--"}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewVendor;
