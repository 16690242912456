import React, { useContext } from "react";
import { CompanyContext } from "../../../../pages/main/setting/sub pages/profile";
import InputError from "../../../../shared components/InputError/InputError";
import InputField from "../../../../shared components/TextInput";

const BankDetails = () => {
  const { formik, updatedValues, setUpdatedValues } =
    useContext(CompanyContext);

  return (
    <>
      <div className="d-flex justify-content-between py-3">
        <p className="m-0 font14x600 textColor3">Bank Details</p>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          Bank name
        </label>

        <div className="setting_input_wrapper">
          <InputField
            id="bankName"
            type="text"
            state={formik.values.bankName}
            setState={(e) => {
              formik.setFieldValue("bankName", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                bankName: e.target.value,
                isObjChange: true,
              });
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.bankName && formik.errors.bankName ? (
            <InputError error={formik.errors.bankName} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          Account No.
        </label>

        <div className="setting_input_wrapper">
          <InputField
            id="accountNumber"
            type="text"
            state={formik.values.accountNumber}
            setState={(e) => {
              formik.setFieldValue("accountNumber", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                accountNumber: e.target.value,
                isObjChange: true,
              });
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.accountNumber && formik.errors.accountNumber ? (
            <InputError error={formik.errors.accountNumber} />
          ) : null}
        </div>
      </div>
      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          Branch name
        </label>

        <div className="setting_input_wrapper">
          <InputField
            id="branchName"
            type="text"
            state={formik.values.branchName}
            setState={(e) => {
              formik.setFieldValue("branchName", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                branchName: e.target.value,
                isObjChange: true,
              });
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.branchName && formik.errors.branchName ? (
            <InputError error={formik.errors.branchName} />
          ) : null}
        </div>
      </div>
      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          IFSC Code
        </label>

        <div className="setting_input_wrapper">
          <InputField
            id="ifscCode"
            type="text"
            state={formik.values.ifscCode}
            setState={(e) => {
              formik.setFieldValue("ifscCode", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                ifscCode: e.target.value,
                isObjChange: true,
              });
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.ifscCode && formik.errors.ifscCode ? (
            <InputError error={formik.errors.ifscCode} />
          ) : null}
        </div>
      </div>
      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          SWIFT Code
        </label>

        <div className="setting_input_wrapper">
          <InputField
            id="swiftCode"
            type="text"
            state={formik.values.swiftCode}
            setState={(e) => {
              formik.setFieldValue("swiftCode", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                swiftCode: e.target.value,
                isObjChange: true,
              });
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.swiftCode && formik.errors.swiftCode ? (
            <InputError error={formik.errors.swiftCode} />
          ) : null}
        </div>
      </div>
      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          AD Code
        </label>

        <div className="setting_input_wrapper">
          <InputField
            id="adCode"
            type="tel"
            state={formik.values.adCode}
            setState={(e) => {
              formik.setFieldValue("adCode", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                adCode: e.target.value,
                isObjChange: true,
              });
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.adCode && formik.errors.adCode ? (
            <InputError error={formik.errors.adCode} />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default BankDetails;
