import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import useLoading from "../../../../hooks/useLoading";
import { updateUser } from "../../../../lib/api functions/user";
import { showToast } from "../../../../lib/toast/ShowToast";
import { UserContext } from "../../../../pages/main/user/add update user";
import { UserObject } from "../../../../pages/main/user/add update user/type";
import FileInput from "../../../../shared components/SingleFileInput/FileInput";
import MultiFileInput from "../../../../shared components/SingleFileInput/MultiFileInput";
import { userDocDetailsSchema } from "../../../../utils/validationSchema";

const Documents = () => {
  const { userId, user, userLoading, getUser } = useContext(UserContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [singleFile, setSingleFile] = useState<any>(undefined);

  const fn = {
    putUserValueInState: () => {
      user.nda && formik.setFieldValue("nda", [user.nda]);
      user.resume && formik.setFieldValue("resume", [user.resume]);
      user.eL && formik.setFieldValue("eL", [user.eL]);
      user.rL && formik.setFieldValue("rL", [user.rL]);
      user.mMS && formik.setFieldValue("mMS", [user.mMS]);
      user.iMS && formik.setFieldValue("iMS", [user.iMS]);
      user.oC && formik.setFieldValue("oC", user.oC);
    },
  };

  const initialValues: UserObject = {
    nda: "",
    resume: "",
    eL: "",
    rL: "",
    mMS: "",
    iMS: "",
    oC: "",
  };

  const formik = useFormik<UserObject>({
    initialValues,
    validationSchema: userDocDetailsSchema,
    onSubmit: async (values) => {
      let finalObj: UserObject = {};

      if (!userId) {
        finalObj["customUserId"] = values.customUserId;
      }
      finalObj = {
        ...finalObj,
        name: values.name,
        address: values.address,
        email: values.email,
        mobile: values.mobile,
        emergencyNumber: values.emergencyNumber,
        bloodGroup: values.bloodGroup,
        pan: values.pan,
        aadharNumber: values.aadharNumber,
        status: 1,
      };

      showLoader();
      if (userId) {
        const res = await updateUser(finalObj, userId, hideLoader);
        if (res) {
          showToast(`User updated`, "success");
          getUser();
          hideLoader();
        }
      }
    },
  });

  useEffect(() => {
    !userLoading && userId && fn.putUserValueInState();
  }, [userLoading]);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font16x600 textColor3">Documents</p>
        </div>

        <div className="details_form_wrapper">
          {/* form */}
          <form>
            <div className="mb-3">
              <label className="form-label font12x400 textColor3 mb-0">
                NDA
              </label>
              <FileInput
                formik={formik}
                accept=".jpeg, .png, .jpg, .pdf"
                multiple={false}
                backendStateKey="nda"
                frontendStateKey={singleFile}
                setSingleFile={setSingleFile}
              />
            </div>
            <div className="mb-3">
              <label className="form-label font12x400 textColor3 mb-0">
                Resume
              </label>
              <FileInput
                formik={formik}
                accept=".jpeg, .png, .jpg, .pdf"
                multiple={false}
                backendStateKey="resume"
                frontendStateKey={singleFile}
                setSingleFile={setSingleFile}
              />
            </div>
            <div className="mb-3">
              <label className="form-label font12x400 textColor3 mb-0">
                Experience Letter
              </label>
              <FileInput
                formik={formik}
                accept=".jpeg, .png, .jpg, .pdf"
                multiple={false}
                backendStateKey="eL"
                frontendStateKey={singleFile}
                setSingleFile={setSingleFile}
              />
            </div>
            <div className="mb-3">
              <label className="form-label font12x400 textColor3 mb-0">
                Relieving Letter
              </label>
              <FileInput
                formik={formik}
                accept=".jpeg, .png, .jpg, .pdf"
                multiple={false}
                backendStateKey="rL"
                frontendStateKey={singleFile}
                setSingleFile={setSingleFile}
              />
            </div>
            <div className="mb-3">
              <label className="form-label font12x400 textColor3 mb-0">
                12th Marksheet
              </label>
              <FileInput
                formik={formik}
                accept=".jpeg, .png, .jpg, .pdf"
                multiple={false}
                backendStateKey="mMS"
                frontendStateKey={singleFile}
                setSingleFile={setSingleFile}
              />
            </div>
            <div className="mb-3">
              <label className="form-label font12x400 textColor3 mb-0">
                10th Marksheet
              </label>
              <FileInput
                formik={formik}
                accept=".jpeg, .png, .jpg, .pdf"
                multiple={false}
                backendStateKey="iMS"
                frontendStateKey={singleFile}
                setSingleFile={setSingleFile}
              />
            </div>
            <div className="mb-3">
              <label className="form-label font12x400 textColor3 mb-0">
                Other Certificates
              </label>
              <MultiFileInput
                formik={formik}
                accept=".jpeg, .png, .jpg, .pdf"
                multiple={true}
                backendStateKey="oC"
                frontendStateKey={singleFile}
                setSingleFile={setSingleFile}
                maxDataAllowed={5}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Documents;
