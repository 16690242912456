import { useState } from "react";
import { BsInfoLg } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import OutsideClickHandler from "react-outside-click-handler";
import { eventTypes } from "../../utils/constants";

const Info = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const fn = {
    setFilterState: () => {
      setIsFilterOpen(!isFilterOpen);
    },
  };

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setIsFilterOpen(false)}>
        <div className="calendar_color_schemes_wrapper">
          <div
            className="calendar_color_schemes_btn_click"
            onClick={fn.setFilterState}
          >
            {isFilterOpen ? (
              <CgClose
                style={{
                  color: "#fff",
                  fontSize: 16,
                  fontWeight: "bold",
                  strokeWidth: 2,
                }}
              />
            ) : (
              <BsInfoLg
                style={{
                  color: "#fff",
                  fontSize: 16,
                }}
              />
            )}
          </div>
          {isFilterOpen && (
            <div
              className="calendar_color_schemes_section py-3 d-flex flex-column gap-3"
              style={{
                minWidth: "137px",
              }}
            >
              {eventTypes?.map((item: any, i: number) => {
                return (
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0 font13x500">{item?.label}</p>
                    <span
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                        backgroundColor: item?.color,
                      }}
                    ></span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default Info;
