import React, { useState } from "react";
import { BiSave } from "react-icons/bi";
import { IncentivesType } from "../../../pages/main/attendance/type";
import InputField from "../../../shared components/TextInput";
import Box from "../../Action Component/Box";

type propType = {
  formik: any;
};

const Incentives: React.FC<propType> = (props) => {
  const { formik } = props;
  const [singleItem, setSingleItem] = useState<IncentivesType>({
    label: "",
    value: "",
    isEditable: false,
  });

  const fn = {
    calculateTotalPaidSalary: (incentives: any) => {
      let totalIncentives =
        incentives.length === 0
          ? 0
          : incentives
              ?.map((item: any) => Number(item.value))
              .reduce((total: any, num: any) => {
                return total + num;
              });
      formik.setFieldValue("totalIncentives", totalIncentives);

      let totalPaidSalary =
        Number(formik.values.computedSalary) + totalIncentives;

      formik.setFieldValue("paidSalary", totalPaidSalary);

      formik.setFieldValue("totalPaidSalary", totalPaidSalary);
    },
    addDataIntoState: (singleItem: IncentivesType) => {
      formik.setFieldValue("incentives", [
        ...formik.values.incentives,
        singleItem,
      ]);

      let _state = formik.values;
      _state = {
        ..._state,
        incentives: [...formik.values.incentives, singleItem],
      };
      formik.setFieldValue("incentives", _state.incentives);
      formik.setFieldValue("isAnythingUpdated", true);

      setSingleItem({
        label: "",
        value: "",
        isEditable: false,
      });

      fn.calculateTotalPaidSalary(_state?.incentives);
    },
    updateDataIntoState: (index: number) => {
      let _state = formik.values;

      formik.setFieldValue(
        `incentives[${index}.isEditable]`,
        !_state.incentives[index].isEditable
      );
    },
    saveDataIntoState: (item: any, index: number) => {
      let _state = formik.values;

      formik.setFieldValue(
        `incentives[${index}.label]`,
        _state.incentives[index].label
      );
      formik.setFieldValue(
        `incentives[${index}.value]`,
        _state.incentives[index].value
      );
      formik.setFieldValue(`incentives[${index}.isEditable]`, false);
      formik.setFieldValue("isAnythingUpdated", true);
      fn.calculateTotalPaidSalary(_state?.incentives);
    },
    deleteDataIntoState: (index: number) => {
      const singleList = [...formik.values.incentives];

      const finalList = singleList.filter((item: any, i: number) => {
        return i !== index;
      });

      formik.setFieldValue("incentives", finalList);
      formik.setFieldValue("isAnythingUpdated", true);
      fn.calculateTotalPaidSalary(finalList);
    },
  };

  return (
    <>
      {/* multiple incentives */}
      <div className="mb-0">
        <label className="form-label font12x400 textColor3 mb-1">
          Incentives
        </label>
        <div className="entry_table_wrapper">
          <div className="table_wrapper">
            <table className="table">
              <thead className="table_head">
                <tr className="font12x500 textColor3">
                  <th scope="col">Label</th>
                  <th scope="col">Value</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {formik.values.incentives?.map((incentive: any, i: number) => {
                  return incentive?.isEditable ? (
                    <tr key={i} className="font12x400">
                      <td>
                        <InputField
                          id="label"
                          type="text"
                          placeholder="Enter label"
                          state={incentive?.label}
                          setState={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            formik.setFieldValue(
                              `incentives[${i}].label`,
                              e.target.value
                            );
                          }}
                          width="150px"
                        />
                      </td>
                      <td>
                        <InputField
                          id="value"
                          type="tel"
                          placeholder="0"
                          state={incentive?.value}
                          setState={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            formik.setFieldValue(
                              `incentives[${i}].value`,
                              e.target.value
                            );
                          }}
                          width="100px"
                        />
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <div
                          className="d-flex align-items-center cursor_pointer"
                          onClick={() => fn.saveDataIntoState(incentive, i)}
                        >
                          <BiSave
                            style={{
                              fontSize: 18,
                              color: "var(--text2)",
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr key={i} className="font12x400">
                      <td>{incentive?.label}</td>
                      <td>{incentive?.value}</td>
                      <td>
                        <div className="d-flex gap-2">
                          <Box
                            bgColor="var(--btn_color1)"
                            image="/Assets/Svg/edit.svg"
                            onClickFn={() => {
                              fn.updateDataIntoState(i);
                            }}
                          />
                          <Box
                            bgColor="var(--btn_color2)"
                            image="/Assets/Svg/delete.svg"
                            onClickFn={() => {
                              fn.deleteDataIntoState(i);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
                <tr className="font12x400">
                  <td>
                    <InputField
                      id="label"
                      type="text"
                      placeholder="Enter label"
                      state={singleItem?.label}
                      setState={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSingleItem({
                          ...singleItem,
                          label: e.target.value,
                        });
                      }}
                      width="150px"
                    />
                  </td>
                  <td>
                    <InputField
                      id="value"
                      type="tel"
                      placeholder="0"
                      state={singleItem?.value}
                      setState={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSingleItem({
                          ...singleItem,
                          value: e.target.value,
                        });
                      }}
                      width="100px"
                    />
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    {singleItem?.label !== "" && singleItem?.value !== "" ? (
                      <div
                        className="btn d-flex justify-content-center align-items-center"
                        style={{
                          width: 30,
                          height: 30,
                          backgroundColor: "var(--bg_color2)",
                        }}
                        onClick={() => fn.addDataIntoState(singleItem)}
                      >
                        <img
                          src="/Assets/Svg/plusIcon.svg"
                          alt="icon"
                          width={13}
                          height={13}
                        />
                      </div>
                    ) : (
                      <div
                        className="btn d-flex justify-content-center align-items-center"
                        style={{
                          width: 30,
                          height: 30,
                          backgroundColor: "var(--bg_color2)",
                          cursor: "no-drop",
                          opacity: 0.5,
                        }}
                      >
                        <img
                          src="/Assets/Svg/plusIcon.svg"
                          alt="icon"
                          width={13}
                          height={13}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Total Incentives */}
        <div className="mb-2">
          <p className="mb-0 font12x500">
            {`Total Incentives: ₹ ${formik.values.totalIncentives?.toLocaleString()}`}
          </p>
        </div>
        {/* seperator */}
        <div
          className="menu_separator"
          style={{
            borderBottom: "2px solid var(--border_color4)",
            marginBottom: "8px",
          }}
        ></div>
      </div>
    </>
  );
};

export default Incentives;
