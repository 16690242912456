import moment from "moment";
import { useContext, useEffect, useState } from "react";
import CalendarFilter from "../../../components/Attendance Components/CalendarFilter";
import DataNotFound from "../../../components/Data Not Found";
import Pagination from "../../../components/Pagination";
import useLoading from "../../../hooks/useLoading";
import {
  checkUserSignInOrNot,
  generatePaySlip,
  getAttendance,
  signIn,
  signOut,
} from "../../../lib/api functions/attendance/attendance";
import { showToast } from "../../../lib/toast/ShowToast";
import SkeletonLoader from "../../../shared components/Loader/SkeletonLoader";
import SpinLoader from "../../../shared components/Loader/SpinLoader";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import { ParamType } from "./type";
import { IoMdEye } from "react-icons/io";
import ViewAttendance from "../../../components/Attendance Components/Daily Attendance/ViewAttendance";
import { commonType } from "../lead";
import PaySlip from "../../../components/Attendance Components/PaySlip";
import ViewSalary from "../../../components/Attendance Components/View Salary";
import { getCurrentMonthAvailablePL } from "../../../function/other";

const ForUser = () => {
  const { userId, companyId, roles, setting, updateUser } =
    useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [loading, showLoader, hideLoader] = useLoading();
  const [loadingSignIn, showLoader2, hideLoader2] = useLoading();
  const [loadingSignOut, showLoader3, hideLoader3] = useLoading();
  const [loadingSlip, showLoader4, hideLoader4] = useLoading();
  const [attendances, setAttendances] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);
  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    month: moment().month().toString(),
    year: moment().year().toString(),
    company: companyId,
    user: userId,
  });

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getAttendances: async (params?: ParamType) => {
      showLoader();
      const res = await getAttendance(params);
      if (res) {
        setAttendances(res.data.attendances);
        setTotalResult(res.data.total);
        hideLoader();
      }
      return Promise.resolve(res);
    },
    getCoordsForAttendanceSignIn: () => {
      showLoader2();
      // check for coordinates
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            fn.userSignIn(
              position.coords.latitude,
              position.coords.longitude
            ).then((res) => {
              hideLoader2();
            });
          },
          (error: GeolocationPositionError) => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                showToast(
                  "Please allow location for getting coordinates.",
                  "error"
                );
                break;
              case error.POSITION_UNAVAILABLE:
                showToast("Location information is unavailable.", "error");
                break;
              case error.TIMEOUT:
                showToast(
                  "The request to get user location timed out.",
                  "error"
                );
                break;
              default:
                showToast("An unknown error occurred.", "error");
            }
          }
        );
      }
    },
    getCoordsForAttendanceSignout: () => {
      showLoader3();
      // check for coordinates
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            fn.userSignOut(position.coords.latitude, position.coords.longitude);
          },
          (error: GeolocationPositionError) => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                showToast(
                  "Please allow location for getting coordinates.",
                  "error"
                );
                break;
              case error.POSITION_UNAVAILABLE:
                showToast("Location information is unavailable.", "error");
                break;
              case error.TIMEOUT:
                showToast(
                  "The request to get user location timed out.",
                  "error"
                );
                break;
              default:
                showToast("An unknown error occurred.", "error");
            }
          }
        );
      }
    },
    userSignIn: async (lat: number, lng: number) => {
      let todaysTime = moment().format("HH:mm");
      let convertedDate = moment(moment().format("YYYY-MM-DD"))
        .add(todaysTime.split(":")[0], "h")
        .add(todaysTime.split(":")[1], "m")
        .toISOString();
      const body = {
        signIn: convertedDate,
        lat,
        lng,
      };
      const res = await signIn(body, hideLoader2);
      if (res) {
        showToast(`You are signed in`, "success");
        await fn.getAttendances(params);
        await updateUser();
        await fn.checkUserSignInOrNot(moment().format("YYYY-MM-DD"));
      }
      return Promise.resolve("called");
    },
    userSignOut: (lat: number, lng: number) => {
      let todaysTime = moment().format("HH:mm");
      let convertedDate = moment(moment().format("YYYY-MM-DD"))
        .add(todaysTime.split(":")[0], "h")
        .add(todaysTime.split(":")[1], "m")
        .toISOString();
      const body = {
        signOut: convertedDate,
        lat,
        lng,
      };
      signOut(body, hideLoader3).then((res) => {
        showToast(`You are signed out`, "success");
        fn.sequentialCalling().then((res) => {
          hideLoader3();
        });
      });
    },
    checkUserSignInOrNot: async (todayDate: string) => {
      const res = await checkUserSignInOrNot(todayDate);
      setIsSignedIn(res.data?.isSignedIn);
      return Promise.resolve(res);
    },
    generateSalarySlip: async (id: string) => {
      showLoader4();
      const res = await generatePaySlip(id);
      if (res) {
        showToast(
          `Your Pay slip is generated for the month of ${moment()
            .month(params?.month ?? "")
            .format("MMMM")}`,
          "success"
        );
        fn.getAttendances(params);
        hideLoader4();
      }
    },
    sequentialCalling: async () => {
      await fn.getAttendances(params);
      //checkUserSignInOrNot
      await fn.checkUserSignInOrNot(moment().format("YYYY-MM-DD"));

      return Promise.resolve("called");
    },
  };

  useEffect(() => {
    fn.sequentialCalling().then((res) => {});
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="data_render_wrapper">
          {/* filters */}
          <div className="filter_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-12 col-md-4"></div>
                <div className="col-12 col-md-8 pt-3 pt-md-0">
                  <div className="d-flex align-items-center gap-2 justify-content-start justify-content-md-end">
                    {params?.month && params?.year && (
                      <>
                        <div className="d-flex align-items-center">
                          <span className="font14x600 textColor3">
                            {`${moment().month(params?.month).format("MMMM")} ${
                              params?.year
                            }`}
                          </span>
                        </div>
                      </>
                    )}

                    <div className="d-flex gap-2">
                      <CalendarFilter
                        state={params}
                        apiCall={fn.getAttendances}
                        setState={setParams}
                      />

                      {isSignedIn ? (
                        <button
                          className="btn font12x500 buttonOne shadow-none btnOpacityLight"
                          disabled={true}
                        >
                          {loadingSignIn ? (
                            <SpinLoader height="20" width="20" color="#fff" />
                          ) : (
                            "Sign In"
                          )}
                        </button>
                      ) : (
                        <button
                          className="btn font12x500 buttonOne shadow-none btnOpacityLight"
                          onClick={() => fn.getCoordsForAttendanceSignIn()}
                        >
                          {loadingSignIn ? (
                            <SpinLoader height="20" width="20" color="#fff" />
                          ) : (
                            "Sign In"
                          )}
                        </button>
                      )}

                      {!isSignedIn ? (
                        <button
                          className="btn font12x500 buttonOne shadow-none btnOpacityLight"
                          disabled={true}
                        >
                          {loadingSignOut ? (
                            <SpinLoader height="20" width="20" color="#fff" />
                          ) : (
                            "Sign Out"
                          )}
                        </button>
                      ) : (
                        <button
                          className="btn font12x500 buttonOne shadow-none btnOpacityLight"
                          onClick={() => fn.getCoordsForAttendanceSignout()}
                        >
                          {loadingSignOut ? (
                            <SpinLoader height="20" width="20" color="#fff" />
                          ) : (
                            "Sign Out"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="list_view_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center pb-3">
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <span className="font16x600 textColor3">Attendance List</span>
                </div>
              </div>
            </div>

            {/* table */}
            {attendances?.length > 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="font12x500 textColor3">
                        <th scope="col">#</th>
                        <th scope="col">Month</th>
                        <th scope="col">User Id</th>
                        <th scope="col">Employee Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Available PL</th>
                        <th scope="col">Days Present</th>
                        <th scope="col">Total Hours</th>
                        <th scope="col">Last Update</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attendances?.map((attendance: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <th>
                              {(params.page - 1) * params.per_page + (i + 1)}
                            </th>
                            <th>{`${moment()
                              .month(attendance?.month)
                              .format("MMMM")}, ${attendance?.year}`}</th>
                            <td>{attendance?.userId}</td>
                            <td>{attendance?.user?.employeeId}</td>
                            <td>
                              <span>{attendance?.name}</span>
                              <br />
                              <span className="font10x600 textColor2">
                                {attendance?.user?.department !== ""
                                  ? attendance?.user?.department
                                  : "--"}
                              </span>
                            </td>
                            <td>
                              {getCurrentMonthAvailablePL(attendance?.user)}
                            </td>
                            <td>{attendance?.daysPresent}</td>
                            <td>{attendance?.totalHours}</td>
                            <td>
                              {moment(attendance?.updatedAt).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td>
                              <div className="d-flex gap-3">
                                {/* {!(
                                  moment().format("MMMM") ===
                                  moment()
                                    .month(params?.month ?? "")
                                    .format("MMMM")
                                ) && (
                                  <div
                                    className="d-flex align-items-center cursor_pointer"
                                    onClick={() =>
                                      fn.openModal(attendance?._id, "pay_slip")
                                    }
                                  >
                                    <IoMdEye
                                      style={{
                                        fontSize: 15,
                                        color: "var(--text2)",
                                      }}
                                    />
                                    <p className="mb-0 font12x400 textColor2 ms-1">
                                      Slip
                                    </p>
                                  </div>
                                )} */}

                                <div className="d-flex flex-column gap-2">
                                  {/* view attendance */}
                                  <div
                                    className="d-flex align-items-center cursor_pointer"
                                    onClick={() =>
                                      fn.openModal(
                                        {
                                          _id: attendance?._id,
                                          salaryType:
                                            attendance?.user.salaryType,
                                          userId: attendance?.user?._id,
                                          month: params?.month,
                                          availablePL:
                                            getCurrentMonthAvailablePL(
                                              attendance?.user
                                            ),
                                        },
                                        "view_attendance"
                                      )
                                    }
                                  >
                                    <IoMdEye
                                      style={{
                                        fontSize: 15,
                                        color: "var(--text2)",
                                      }}
                                    />
                                    <p className="mb-0 font12x400 textColor2 ms-1">
                                      View Attendance
                                    </p>
                                  </div>

                                  {/* view salary */}
                                  <div
                                    className="d-flex align-items-center cursor_pointer"
                                    onClick={() =>
                                      fn.openModal(
                                        {
                                          attendance,
                                        },
                                        "view_salary"
                                      )
                                    }
                                  >
                                    <IoMdEye
                                      style={{
                                        fontSize: 15,
                                        color: "var(--text2)",
                                      }}
                                    />
                                    <p className="mb-0 font12x400 textColor2 ms-1">
                                      View Salary
                                    </p>
                                  </div>
                                </div>

                                <div className="d-flex flex-column gap-2">
                                  {/* view salary slip */}
                                  {attendance?.salarySlip && (
                                    <div
                                      className="d-flex align-items-center cursor_pointer"
                                      onClick={() =>
                                        fn.openModal(
                                          {
                                            _id: attendance?._id,
                                            isSalarySlipExist:
                                              attendance?.salarySlip,
                                            month: attendance?.month,
                                            year: attendance?.year,
                                          },
                                          "pay_slip"
                                        )
                                      }
                                    >
                                      <IoMdEye
                                        style={{
                                          fontSize: 15,
                                          color: "var(--text2)",
                                        }}
                                      />
                                      <p className="mb-0 font12x400 textColor2 ms-1">
                                        Slip
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : loading ? (
              <SkeletonLoader />
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>

        {/* pagination */}
        {attendances?.length !== 0 && (
          <div className="pagination_wrapper pt-3 px-3">
            <Pagination
              currentPage={params.page}
              onPageChange={(e) => {
                fn.getAttendances({
                  ...params,
                  page: e,
                });
                setParams({
                  ...params,
                  page: e,
                });
              }}
              pageSize={params.per_page}
              totalCount={Math.ceil(totalResult / params.per_page)}
              onChangePerPage={(e) => {
                fn.getAttendances({
                  ...params,
                  per_page: e,
                });
                setParams({
                  ...params,
                  per_page: e,
                });
              }}
            />
          </div>
        )}
      </div>

      {/* view attendance modal */}
      {commonData?.modal && commonData?.check === "view_attendance" && (
        <ViewAttendance
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getAttendances}
          params={params}
        />
      )}

      {/* pay slip modal */}
      {commonData?.modal && commonData?.check === "pay_slip" && (
        <PaySlip
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getAttendances}
          params={params}
        />
      )}

      {/* view salary modal */}
      {commonData?.modal && commonData?.check === "view_salary" && (
        <ViewSalary
          state={commonData}
          setState={setCommonData}
          params={params}
        />
      )}
    </>
  );
};

export default ForUser;
