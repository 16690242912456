import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataNotFound from "../../../../components/Data Not Found";
import Pagination from "../../../../components/Pagination";
import DeleteModal from "../../../../components/Trashed Modal/DeleteModal";
import CalendarFilter from "../../../../Filters/CalendarFilter";
import useLoading from "../../../../hooks/useLoading";
import {
  getInvoice,
  restoreInvoice,
} from "../../../../lib/api functions/invoice/invoice";
import { showToast } from "../../../../lib/toast/ShowToast";
import ShowSelectedDate from "../../../../shared components/DateInput/ShowSelectedDate";
import SkeletonLoader from "../../../../shared components/Loader/SkeletonLoader";
import Search from "../../../../shared components/Search";
import {
  DELETE_INVOICE_HARD,
  invoiceStatussMap,
} from "../../../../utils/constants";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import { commonType } from "../../lead";
import { ParamType } from "./../type";

const ViewCancelledInvoice = () => {
  const { setPageTitle, userId, companyId, roles, setting } =
    useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const [invoices, setInvoices] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    company: companyId,
    isCancelled: true,
  });

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getInvoices: async (params: ParamType) => {
      showLoader();
      const res = await getInvoice(params);
      if (res) {
        setInvoices(res.data.invoices);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
    restoreInvoice: async (invoiceId: string) => {
      let finalObj = {
        invoiceIds: [invoiceId],
        isCancelled: false,
      };
      const res = await restoreInvoice(finalObj, hideLoader);
      if (res) {
        showToast(`Invoice Restored`, "success");
        fn.getInvoices(params);
      }
    },
  };

  useEffect(() => {
    if (roles?.length !== 0) {
      if (!roles[0]?.invoices.complete) {
        params.user = userId;
        fn.getInvoices(params);
      } else {
        fn.getInvoices(params);
      }
    }
  }, []);

  useEffect(() => {
    setPageTitle("Cancelled Invoice");
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="data_render_wrapper">
          {/* filters */}
          <div className="filter_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-12 col-lg-4">
                  <Search
                    placeholder="Search by invoice number"
                    state={params}
                    setState={setParams}
                    apiCall={fn.getInvoices}
                    paramValue="invoiceNumber"
                  />
                </div>
                <div className="col-12 col-lg-8 pt-3 pt-lg-0">
                  <div className="d-flex align-items-center gap-3 justify-content-start justify-content-lg-end">
                    {params?.dF && (
                      <>
                        <ShowSelectedDate
                          label="From:"
                          value={moment(params?.dF?.split("_")[1]).format(
                            setting?.dateFormat
                          )}
                        />
                        <ShowSelectedDate
                          label="To:"
                          value={moment(params?.dF?.split("_")[2]).format(
                            setting?.dateFormat
                          )}
                        />
                      </>
                    )}

                    <div className="d-flex gap-2">
                      <CalendarFilter
                        state={params}
                        apiCall={fn.getInvoices}
                        setState={setParams}
                        fieldName="issueDate"
                        label="Issue date"
                        isFutureDateRequired={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="list_view_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center pb-3">
                <div className="col-12 col-lg-3">
                  <span className="font16x600 textColor3">
                    List of Cancelled Invoice
                  </span>
                </div>
              </div>
            </div>

            {/* table */}
            {invoices?.length !== 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="font12x500 textColor3">
                        <th scope="col">#</th>
                        <th scope="col">Issue Date</th>
                        <th scope="col">Invoice Number</th>
                        <th scope="col">Client Name</th>
                        <th scope="col">Due Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Tax</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Balance</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoices?.map((invoice: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <th>
                              {(params.page - 1) * params.per_page + (i + 1)}
                            </th>
                            <td>
                              {moment(invoice?.date).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td>{invoice?.invoiceNumber}</td>
                            <td>
                              <span className="textColor2 mb-1">
                                {invoice?.client?.name}
                              </span>
                              <br />
                              <span>
                                {`${invoice?.client?.completeAddress?.city}, ${invoice?.client?.completeAddress?.state}`}
                              </span>
                            </td>
                            <td>
                              {moment(invoice?.dueDate).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td className="font12x500">
                              {invoiceStatussMap.get(invoice?.status)}
                            </td>
                            <td>{`₹ ${(
                              invoice?.calculation?.cgst +
                              invoice?.calculation?.sgst +
                              invoice?.calculation?.igst
                            )?.toLocaleString()}`}</td>
                            <td>{`₹ ${invoice?.calculation?.total?.toLocaleString()}`}</td>
                            <td>{`₹ ${invoice?.dueAmount?.toLocaleString()}`}</td>
                            <td>
                              <div className="d-flex align-items-center gap-3">
                                <button
                                  className="btn font12x500 buttonTwo shadow-none"
                                  onClick={() =>
                                    fn.restoreInvoice(invoice?._id)
                                  }
                                >
                                  Restore
                                </button>
                                <div
                                  className="cursor_pointer"
                                  onClick={() =>
                                    fn.openModal(
                                      { invoiceIds: [invoice?._id] },
                                      "delete"
                                    )
                                  }
                                >
                                  <img
                                    src="/Assets/Svg/deleteColor.svg"
                                    alt="close"
                                    className="img-fluid"
                                    draggable={false}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : loading ? (
              <SkeletonLoader />
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>

        {/* pagination */}
        {invoices?.length !== 0 && (
          <div className="pagination_wrapper pt-3 px-3">
            <Pagination
              currentPage={params.page}
              onPageChange={(e) => {
                fn.getInvoices({ ...params, page: e });
                setParams({
                  ...params,
                  page: e,
                });
              }}
              pageSize={params.per_page}
              totalCount={Math.ceil(totalResult / params.per_page)}
              onChangePerPage={(e) => {
                fn.getInvoices({ ...params, per_page: e });
                setParams({
                  ...params,
                  per_page: e,
                });
              }}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {commonData?.modal && commonData?.check === "delete" && (
        <DeleteModal
          label="Invoice"
          dataIds={commonData?.data}
          setState={setCommonData}
          url={DELETE_INVOICE_HARD}
          variableName="invoiceIds"
          apiCall={fn.getInvoices}
          params={params}
        />
      )}
    </>
  );
};

export default ViewCancelledInvoice;
