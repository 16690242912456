import React, { useContext, useEffect, useState } from "react";
import useLoading from "../../hooks/useLoading";
import { getClient } from "../../lib/api functions/client";
import { LeadContext } from "../../pages/main/lead/new lead";
import ButtonOne from "../../shared components/Button/ButtonOne";
import SpinLoader from "../../shared components/Loader/SpinLoader";
import Loader from "../../shared components/Loader/SpinLoader";
import { CHARACTER_LENGTH, PRIMARY_COLOR } from "../../utils/constants";
import { MainContext } from "../../utils/private_routes/PrivateRoutes";

type ParamType = {
  q: string;
  f: string;
  type: string;
  company: string;
};

type propType = {
  clientId?: string;
  setClientId: (val?: string) => void;
  setCompanySelected: (val?: boolean) => void;
  companySelected?: boolean;
};

const SearchCompany: React.FC<propType> = (props) => {
  const { companyId } = useContext(MainContext);
  const { clientId, setClientId, setCompanySelected, companySelected } = props;
  const { fn } = useContext(LeadContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [clients, setClients] = useState<any>([]);
  const [searchInput, setSearchInput] = useState<string>("");

  const functions = {
    getClients: async (params: ParamType) => {
      showLoader();
      const res = await getClient(params);
      if (res) {
        setClients(res.data.clients);
        hideLoader();
      }
    },
    searchClients: (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      setSearchInput(value);
      if (value !== "") {
        if (value.length >= CHARACTER_LENGTH) {
          functions.getClients({
            q: `name:${value}`,
            f: "name,completeAddress.city",
            type: JSON.stringify([1, 3]),
            company: companyId,
          });
        }
        setClients([]);
        setClientId();
      } else {
        setClients([]);
        setClientId();
        hideLoader();
      }
    },
    resetSearch: () => {
      setSearchInput("");
      setClients([]);
      setClientId();
      setCompanySelected(false);
    },
    settingClientId: (name: string, id: string) => {
      setSearchInput(name);
      setClientId(id);
      setCompanySelected(true);
      setClients([]);
    },
  };

  return (
    <>
      <div className="inside_single_page">
        <div className="search_details_wrapper d-flex flex-column align-items-center">
          <h3 className="font16x600 textColor3">Search Company Name</h3>
          <div className="search_wrapper">
            <div
              className="search_wrapper_section"
              style={{
                boxShadow:
                  searchInput.length > 0
                    ? clientId
                      ? "none"
                      : "0px 12px 30px rgba(0, 0, 0, 0.1)"
                    : "none",
              }}
            >
              {/* search field */}
              <div className="search_field_section d-flex align-items-center">
                <div className="">
                  <img src="/Assets/Svg/searchIcon.svg" alt="user" />
                </div>
                <div className="w-100">
                  <input
                    type="text"
                    className="form-control shadow-none company_search"
                    placeholder="Type company name here..."
                    value={searchInput ?? ""}
                    onChange={(e) => {
                      functions.searchClients(e);
                    }}
                  />
                </div>

                {searchInput.length > 0 && (
                  <div
                    className="cursor_pointer"
                    onClick={functions.resetSearch}
                  >
                    <img src="/Assets/Svg/closeIcon.svg" alt="user" />
                  </div>
                )}
              </div>

              {/* search results */}
              <div className="search_result_wrapper pt-2">
                {clients?.length !== 0 &&
                  clients?.map((item: any, i: number) => {
                    return (
                      <div
                        key={i}
                        className="search_result_section px-5"
                        onClick={() =>
                          functions.settingClientId(item?.name, item?._id)
                        }
                        style={{
                          backgroundColor:
                            clientId === item?._id ? "var(--bg_color5)" : "",
                        }}
                      >
                        <p className="mb-0 font12x500 textColor3">
                          {item?.name}
                        </p>
                        <p className="mb-0 font10x400 textColor1">
                          {item?.completeAddress?.city}
                        </p>
                      </div>
                    );
                  })}
                {loading && (
                  <SpinLoader height="30" width="30" color={PRIMARY_COLOR} />
                )}
              </div>
            </div>

            {/* buttons */}
            <div className="d-flex justify-content-end mt-4">
              {searchInput.length > 1 && (
                <button
                  className="btn font12x500 buttonOne shadow-none"
                  onClick={() => {
                    fn.nextStep();
                    setCompanySelected(false);
                  }}
                >
                  <img
                    src="/Assets/Svg/plusIcon.svg"
                    alt="icon"
                    className="me-2"
                  />
                  Create New Company
                </button>
              )}

              {clientId && (
                <button
                  className="btn font12x500 buttonOne shadow-none ms-2"
                  onClick={fn.jumpToLastStep}
                >
                  Continue
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchCompany;
