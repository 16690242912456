import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ViewLeadContext } from "../..";
import DataNotFound from "../../../../../../components/Data Not Found";
import useLoading from "../../../../../../hooks/useLoading";
import { ParamType } from "./type";
import Skeleton from "react-loading-skeleton";
import { getProposal } from "../../../../../../lib/api functions/lead/proposal";
import { MainContext } from "../../../../../../utils/private_routes/PrivateRoutes";

const ViewProposal = () => {
  const { setting } = useContext(MainContext);
  const { id } = useContext(ViewLeadContext);
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const [proposal, setProposal] = useState<any>([]);
  const [params, setParams] = useState<ParamType>({
    // page: 1,
    // per_page: 10,
    lead: id,
  });

  const fn = {
    getProposals: async (params: ParamType) => {
      showLoader();
      const res = await getProposal(params);
      if (res) {
        setProposal(res.data.proposals);
        hideLoader();
      }
    },
  };

  useEffect(() => {
    fn.getProposals(params);
  }, []);

  return (
    <>
      <div>
        <div className="viewlead_content_header d-flex justify-content-between">
          <p className="m-0 font14x500 textColor3">Proposal</p>
          <button
            className="btn font12x500 buttonOne shadow-none"
            onClick={() => navigate(`/lead/view/${id}/proposal/form`)}
          >
            <img src="/Assets/Svg/plusIcon.svg" alt="icon" className="me-2" />
            Add New Proposal
          </button>
        </div>

        {loading ? (
          <Skeleton height="190px" borderRadius="8px" />
        ) : (
          <div className="menu_content_wrapper">
            {/* table */}
            {proposal?.length !== 0 ? (
              <div className="table_wrapper">
                <table className="table">
                  <thead className="table_head_small">
                    <tr className="font12x500 textColor3">
                      <th scope="col">Issue Date</th>
                      <th scope="col">Proposal no.</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Valid Till</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proposal?.map((proposal: any, i: number) => {
                      return (
                        <tr key={i} className="font12x400">
                          <td>
                            {moment(proposal?.date).format(setting.dateFormat)}
                          </td>
                          <td>{proposal?.proposalNumber}</td>
                          <td>{`₹ ${proposal?.calculation?.total?.toLocaleString()}`}</td>
                          <td>
                            {moment(proposal?.validTill).format(
                              setting?.dateFormat
                            )}
                          </td>
                          <td>
                            <div className="d-flex">
                              <p
                                className="mb-0 font10x400 textColor2 cursor_pointer"
                                onClick={() =>
                                  navigate(
                                    `/lead/view/${id}/proposal/form/${proposal?._id}`
                                  )
                                }
                              >
                                Edit
                              </p>
                              <p
                                className="mb-0 font10x400 textColor2 ms-2 cursor_pointer"
                                onClick={() =>
                                  navigate(
                                    `/lead/view/${id}/proposal/view/${proposal?._id}`
                                  )
                                }
                              >
                                View
                              </p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <DataNotFound />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ViewProposal;
