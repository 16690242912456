import React from "react";

const DataNotFound = () => {
  return (
    <>
      <div className="text-center px-3 px-md-5">
        <img
          src="/Assets/Svg/notFound.svg"
          alt="not-found"
          width={350}
          draggable={false}
          className="img-fluid"
        />
        <p className="mb-0 font14x400 textColor3">Data not found</p>
      </div>
    </>
  );
};

export default DataNotFound;
