import React from "react";
import useLoading from "../../../../hooks/useLoading";
import { commonType } from "../../../../pages/main/lead";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import InputField from "../../../../shared components/TextInput";
import UpdationDetail from "../../../Data Update Detail";
import { CgClose } from "react-icons/cg";
import { departmentSchema } from "../../../../utils/validationSchema";
import { useFormik } from "formik";
import InputError from "../../../../shared components/InputError/InputError";
import { showToast } from "../../../../lib/toast/ShowToast";
import { DepartmentType } from "../../../../pages/main/setting/sub pages/department/type";
import {
  createDepartment,
  updateDepartment,
} from "../../../../lib/api functions/setting/department";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall?: (val: any) => void;
  params: any;
};

const Form: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params } = props;
  const [loading, showLoader, hideLoader] = useLoading();

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
  };

  const initialValues: DepartmentType = {
    name: state?.data?.department?.name ?? "",
  };

  const formik = useFormik<DepartmentType>({
    initialValues,
    validationSchema: departmentSchema,
    onSubmit: async (values) => {
      let finalObj = {
        name: values.name,
      };

      showLoader();
      if (state.data) {
        const res = await updateDepartment(
          finalObj,
          state.data.department._id,
          hideLoader
        );
        if (res) {
          showToast(`Department updated`, "success");
          fn.setPositionToBody();
          apiCall?.(params);
          hideLoader();
        }
      } else {
        const res = await createDepartment(finalObj, hideLoader);
        if (res) {
          showToast(`Department created`, "success");
          fn.setPositionToBody();
          apiCall?.(params);
          hideLoader();
        }
      }
    },
  });

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">
              {state.data ? "Edit Details" : "Add New Department"}
            </h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <InputField
                    id="name"
                    label="Department Name*"
                    type="text"
                    state={formik.values.name}
                    setState={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <InputError error={formik.errors.name} />
                  ) : null}
                </div>
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="btn font12x500 shadow-none custom_btn ms-3"
                >
                  {state.data ? (
                    loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )
                  ) : loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>

            {state.data && (
              <UpdationDetail
                lastUpdate={state?.data?.department?.updatedAt}
                updatedBy={state?.data?.department?.updatedBy}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
