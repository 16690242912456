import Api from "../../config/api";
import {
  CREATE_TASK,
  SEARCH_TASK,
  SEARCH_TASK_BY_ID,
  UPDATE_TASK,
} from "../../utils/constants";
import { showToast } from "../toast/ShowToast";

export const getTask = async (params?: any) => {
  try {
    const res = await Api(false).get(SEARCH_TASK, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const getTaskById = async (id?: string) => {
  try {
    const res = await Api(false).get(`${SEARCH_TASK_BY_ID}/${id}`);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const createTask = async (body: any, hideLoader: () => void) => {
  try {
    const res = await Api(true).post(CREATE_TASK, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const updateTask = async (
  body: any,
  id: string,
  hideLoader: () => void
) => {
  try {
    const res = await Api(true).put(`${UPDATE_TASK}/${id}`, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};
