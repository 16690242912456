import React, { useContext, useEffect } from "react";
import { roundToTwo } from "../../../../../function/other";
import { ProfileContext } from "../../../../../pages/main/lead/view lead/sub pages/profile";
import DropdownOne from "../../../../../shared components/Dropdown/DropdownOne";
import DropdownTwo from "../../../../../shared components/Dropdown/DropdownTwo";
import InputError from "../../../../../shared components/InputError/InputError";
import TextareaInput from "../../../../../shared components/TextareaInput";
import InputField from "../../../../../shared components/TextInput";
import { demoUnitMap } from "../../../../../utils/constants";
import Box from "../../../../Action Component/Box";

const AddProposalList = () => {
  const {
    itemsList,
    formik,
    setSingleItem,
    singleItem,
    setCalculation,
    taxes,
  } = useContext(ProfileContext);

  const fn = {
    singleItemCalculation: (item: any): number => {
      let discountAmount = 0;
      let taxAmount = 0;
      let partialTotal = 0;
      let itemTotal = 0;
      partialTotal = roundToTwo(item?.qty * item?.unitQtyPrice);
      discountAmount = roundToTwo((partialTotal * item?.discount) / 100);
      partialTotal -= discountAmount;
      partialTotal = roundToTwo(partialTotal);
      taxAmount = roundToTwo((partialTotal * item?.tax) / 100);
      itemTotal = roundToTwo(partialTotal + taxAmount);
      return itemTotal;
    },
    addItemLineInList: (singleItem: any) => {
      if (singleItem.tax === undefined) {
        singleItem.tax = 0;
      }
      //checking for tax
      if (formik.values.placeOfSupply === formik.values.companyState) {
        singleItem.cgst = isNaN(roundToTwo(singleItem.tax / 2))
          ? 0
          : roundToTwo(singleItem.tax / 2);
        singleItem.sgst = isNaN(roundToTwo(singleItem.tax / 2))
          ? 0
          : roundToTwo(singleItem.tax / 2);
        singleItem.igst = 0;
      } else {
        singleItem.cgst = 0;
        singleItem.sgst = 0;
        singleItem.igst = isNaN(singleItem.tax) ? 0 : singleItem.tax;
      }
      singleItem.total = fn.singleItemCalculation(singleItem);

      let _state = formik.values;
      _state = { ..._state, items: [...formik.values.items, singleItem] };
      formik.setFieldValue("items", _state.items);

      setSingleItem({
        name: "",
        description: "",
        hsnOrsac: 0,
        qty: 0,
        type: 0,
        unit: 0,
        unitQtyPrice: 0,
        discount: 0,
        cgst: 0,
        sgst: 0,
        igst: 0,
        tax: 0,
        total: 0,
        id: "",
        isEditable: false,
      });

      fn.calculation(_state.items);
    },
    deleteItemLineInList: (index: number) => {
      const singleList = [...formik.values.items];

      const finalList = singleList.filter((item: any, i: number) => {
        return i !== index;
      });

      formik.setFieldValue("items", finalList);
      fn.calculation(finalList);
    },
    updateItemLineInList: (index: number) => {
      formik.setFieldValue(`items[${index}.isEditable]`, true);
    },
    saveUpdatedData: (item: any, index: number) => {
      let _state = formik.values;

      if (item.tax === undefined) {
        _state.items[index].tax = 0;
        item.tax = 0;
      }

      //checking for tax
      if (formik.values.placeOfSupply === formik.values.companyState) {
        _state.items[index].cgst = isNaN(roundToTwo(item.tax / 2))
          ? 0
          : roundToTwo(item.tax / 2);
        _state.items[index].sgst = isNaN(roundToTwo(item.tax / 2))
          ? 0
          : roundToTwo(item.tax / 2);
        _state.items[index].igst = 0;
      } else {
        _state.items[index].cgst = 0;
        _state.items[index].sgst = 0;
        _state.items[index].igst = item.tax ? item.tax : 0;
      }

      _state.items[index].total = fn.singleItemCalculation(item);
      _state.items[index].isEditable = false;

      formik.setFieldValue(`items[${index}.cgst]`, _state.items[index].cgst);
      formik.setFieldValue(`items[${index}.sgst]`, _state.items[index].sgst);
      formik.setFieldValue(`items[${index}.igst]`, _state.items[index].igst);
      formik.setFieldValue(`items[${index}.total]`, _state.items[index].total);
      formik.setFieldValue(
        `items[${index}.isEditable]`,
        _state.items[index].isEditable
      );

      fn.calculation(_state.items);
    },
    calculation: (viewList: any) => {
      let discountAmount = 0;
      let taxAmount = 0;
      let partialTotal = 0;
      let subTotal = 0;
      let totalDiscount = 0;
      let totalTaxAmount = 0;
      let cgst = 0;
      let sgst = 0;
      let igst = 0;

      viewList?.map((item: any, index: number) => {
        partialTotal = roundToTwo(item?.qty * item?.unitQtyPrice);
        subTotal += partialTotal;
        discountAmount = roundToTwo((partialTotal * item?.discount) / 100);
        totalDiscount += discountAmount;
        partialTotal -= discountAmount;
        partialTotal = roundToTwo(partialTotal);
        taxAmount = roundToTwo((partialTotal * item?.tax) / 100);
        totalTaxAmount += taxAmount;

        if (formik.values.placeOfSupply === formik.values.companyState) {
          cgst = roundToTwo(totalTaxAmount / 2);
          formik.setFieldValue(
            `items[${index}.cgst]`,
            roundToTwo(item?.tax / 2)
          );
          sgst = roundToTwo(totalTaxAmount / 2);
          formik.setFieldValue(
            `items[${index}.sgst]`,
            roundToTwo(item?.tax / 2)
          );
          formik.setFieldValue(`items[${index}.igst]`, 0);
        } else {
          igst = roundToTwo(totalTaxAmount);
          formik.setFieldValue(`items[${index}.cgst]`, 0);
          formik.setFieldValue(`items[${index}.sgst]`, 0);
          formik.setFieldValue(`items[${index}.igst]`, item?.tax);
        }
      });
      totalDiscount = roundToTwo(totalDiscount);
      subTotal = roundToTwo(subTotal);
      let finalTotal = roundToTwo(
        subTotal - totalDiscount + cgst + sgst + igst
      );
      setCalculation({
        subTotal,
        cgst,
        sgst,
        igst,
        discount: totalDiscount,
        total: finalTotal,
      });
    },
  };

  useEffect(() => {
    fn.calculation(formik.values.items);
  }, [formik.values.placeOfSupply, formik.values.items]);

  return (
    <>
      <div className="entry_table_wrapper pt-3">
        <div className="table_wrapper">
          <table className="table">
            <thead className="table_head_list">
              <tr className="font12x500 textColor3">
                <th scope="col">No.</th>
                <th scope="col">Item Name</th>
                <th scope="col">HSN/SAC</th>
                <th scope="col">Unit</th>
                <th scope="col">Qty</th>
                <th scope="col">Price</th>
                <th scope="col">Discount (%)</th>
                <th scope="col">CGST (%)</th>
                <th scope="col">SGST (%)</th>
                <th scope="col">IGST (%)</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              {formik?.values?.items?.map((item: any, i: number) => {
                return item?.isEditable ? (
                  <tr key={i} className="font12x400">
                    <th>{i + 1}</th>
                    <td>
                      <DropdownTwo
                        placeholder="Select item"
                        name="name"
                        data={itemsList}
                        state={item?.name}
                        setState={(e?: string, x?: string | number) => {
                          formik.setFieldValue(`items[${i}].name`, e);
                          formik.setFieldValue(`items[${i}].id`, x);
                        }}
                        labelField="name"
                        isDisabled={item?.isEditable ? true : false}
                      />

                      <div className="mt-3">
                        <h3 className="mb-0 font12x600 mb-1">Description</h3>
                        <TextareaInput
                          id="description"
                          state={item?.description}
                          setState={(
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            formik.setFieldValue(
                              `items[${i}].description`,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <InputField
                        id="hsnOrsac"
                        type="tel"
                        placeholder="0"
                        state={item?.hsnOrsac}
                        setState={(e: React.ChangeEvent<HTMLInputElement>) => {
                          formik.setFieldValue(`items[${i}].hsnOrsac`, e);
                        }}
                        width="70px"
                        isDisabled={true}
                      />
                    </td>
                    <td>
                      <DropdownOne
                        placeholder="Unit"
                        name="unit"
                        data={demoUnitMap}
                        state={item?.unit}
                        setState={(e?: string, x?: string | number) => {
                          formik.setFieldValue(`items[${i}].unit`, e);
                        }}
                      />
                    </td>
                    <td>
                      <InputField
                        id="qty"
                        type="tel"
                        placeholder="0"
                        state={item?.qty}
                        setState={(e: React.ChangeEvent<HTMLInputElement>) => {
                          formik.setFieldValue(
                            `items[${i}].qty`,
                            Number(e.target.value)
                          );
                        }}
                        width="70px"
                      />
                    </td>
                    <td>
                      <InputField
                        id="unitQtyPrice"
                        type="tel"
                        placeholder="price"
                        state={item?.unitQtyPrice}
                        setState={(e: React.ChangeEvent<HTMLInputElement>) => {
                          formik.setFieldValue(
                            `items[${i}].unitQtyPrice`,
                            Number(e.target.value)
                          );
                        }}
                        width="70px"
                      />
                    </td>
                    <td>
                      <InputField
                        id="discount"
                        type="tel"
                        placeholder="discount"
                        state={item?.discount}
                        setState={(e: React.ChangeEvent<HTMLInputElement>) => {
                          formik.setFieldValue(
                            `items[${i}].discount`,
                            Number(e.target.value)
                          );
                        }}
                        width="70px"
                      />
                    </td>
                    <td colSpan={3}>
                      <DropdownTwo
                        placeholder="Select Tax"
                        name="tax"
                        data={taxes}
                        state={item?.tax}
                        setState={(e?: string, x?: string | number) => {
                          formik.setFieldValue(`items[${i}].tax`, e);
                        }}
                        labelField="percent"
                      />
                    </td>
                    <td>
                      <div
                        className="btn font12x500 buttonOne shadow-none"
                        onClick={() => fn.saveUpdatedData(item, i)}
                      >
                        Save
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr key={i} className="font12x400">
                    <th>{i + 1}</th>
                    <td>
                      {item?.name}
                      <div className="mt-3">
                        <h3 className="mb-0 font12x600">Description</h3>
                        <div
                          className=""
                          style={{
                            width: "150px",
                            overflowWrap: "anywhere",
                          }}
                        >
                          {item?.description !== "" ? item?.description : "--"}
                        </div>
                      </div>
                    </td>
                    <td>{item?.hsnOrsac}</td>
                    <td>{item?.unit}</td>
                    <td>{item?.qty}</td>
                    <td>{`₹ ${item?.unitQtyPrice?.toLocaleString()}`}</td>
                    <td>{`${item?.discount}%`}</td>
                    <td>{`${item?.cgst}%`}</td>
                    <td>{`${item?.sgst}%`}</td>
                    <td>{`${item?.igst}%`}</td>
                    <td>
                      <div className="d-flex justify-content-between">
                        <div>{`₹ ${item?.total?.toLocaleString()}`}</div>
                        <div className="d-flex gap-2">
                          <Box
                            bgColor="var(--btn_color1)"
                            image="/Assets/Svg/edit.svg"
                            onClickFn={() => {
                              fn.updateItemLineInList(i);
                            }}
                          />
                          <Box
                            bgColor="var(--btn_color2)"
                            image="/Assets/Svg/delete.svg"
                            onClickFn={() => {
                              fn.deleteItemLineInList(i);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
              <tr className="font12x400">
                <th>{formik?.values?.items?.length + 1}</th>
                <td>
                  <DropdownTwo
                    placeholder="Select item"
                    name="name"
                    data={itemsList}
                    state={singleItem?.name}
                    setState={(e?: string, x?: string | number) => {
                      setSingleItem((prev: any) => {
                        return {
                          ...prev,
                          name: e,
                          id: x,
                          qty: singleItem.qty > 0 ? singleItem.qty : 1,
                        };
                      });
                    }}
                    labelField="name"
                  />

                  <div className="mt-3">
                    <h3 className="mb-0 font12x600 mb-1">Description</h3>
                    <TextareaInput
                      id="description"
                      state={singleItem?.description}
                      setState={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setSingleItem((prev: any) => {
                          return {
                            ...prev,
                            description: e.target.value,
                          };
                        });
                      }}
                    />
                  </div>
                </td>
                <td>
                  <InputField
                    id="hsnOrsac"
                    type="tel"
                    placeholder="0"
                    state={singleItem?.hsnOrsac}
                    setState={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSingleItem((prev: any) => {
                        return {
                          ...prev,
                          hsnOrsac: e,
                        };
                      });
                    }}
                    width="70px"
                    isDisabled={true}
                  />
                </td>
                <td>
                  <DropdownOne
                    placeholder="Unit"
                    name="unit"
                    data={demoUnitMap}
                    state={singleItem?.unit}
                    setState={(e?: string, x?: string | number) => {
                      setSingleItem((prev: any) => {
                        return {
                          ...prev,
                          unit: e,
                        };
                      });
                    }}
                  />
                </td>
                <td>
                  <InputField
                    id="qty"
                    type="tel"
                    placeholder="0"
                    state={singleItem?.qty}
                    setState={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSingleItem((prev: any) => {
                        return {
                          ...prev,
                          qty: Number(e.target.value),
                        };
                      });
                    }}
                    width="70px"
                  />
                </td>
                <td>
                  <InputField
                    id="unitQtyPrice"
                    type="tel"
                    placeholder="price"
                    state={singleItem?.unitQtyPrice}
                    setState={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSingleItem((prev: any) => {
                        return {
                          ...prev,
                          unitQtyPrice: Number(e.target.value),
                        };
                      });
                    }}
                    width="70px"
                  />
                </td>
                <td>
                  <InputField
                    id="discount"
                    type="tel"
                    placeholder="discount"
                    state={singleItem?.discount}
                    setState={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSingleItem((prev: any) => {
                        return {
                          ...prev,
                          discount: Number(e.target.value),
                        };
                      });
                    }}
                    width="70px"
                  />
                </td>
                <td colSpan={3}>
                  <DropdownTwo
                    placeholder="Select Tax"
                    name="tax"
                    data={taxes}
                    state={singleItem?.tax}
                    setState={(e?: string, x?: string | number) => {
                      setSingleItem((prev: any) => {
                        return {
                          ...prev,
                          tax: e,
                        };
                      });
                    }}
                    labelField="percent"
                  />
                </td>
                <td>
                  {singleItem?.id && singleItem?.name !== "" ? (
                    <div
                      className="btn font12x500 buttonOne shadow-none"
                      onClick={() => fn.addItemLineInList(singleItem)}
                    >
                      <img
                        src="/Assets/Svg/plusIcon.svg"
                        alt="icon"
                        className="me-2"
                        width={10}
                        height={10}
                      />
                      Add Line
                    </div>
                  ) : (
                    <div
                      className="btn font12x500 buttonOne shadow-none"
                      style={{ cursor: "no-drop", opacity: 0.5 }}
                    >
                      <img
                        src="/Assets/Svg/plusIcon.svg"
                        alt="icon"
                        className="me-2"
                        width={10}
                        height={10}
                      />
                      Add Line
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {formik.touched.items && formik.errors.items ? (
          <InputError error={formik.errors.items} />
        ) : null}
      </div>
    </>
  );
};

export default AddProposalList;
