import React from "react";
import useLoading from "../../../../hooks/useLoading";
import { commonType } from "../../../../pages/main/lead";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import InputField from "../../../../shared components/TextInput";
import UpdationDetail from "../../../Data Update Detail";
import { CgClose } from "react-icons/cg";
import { rolePermission } from "../../../../utils/constants";
import { roleSchema } from "../../../../utils/validationSchema";
import { useFormik } from "formik";
import InputError from "../../../../shared components/InputError/InputError";
import {
  createRole,
  updateRole,
} from "../../../../lib/api functions/setting/role";
import { showToast } from "../../../../lib/toast/ShowToast";
import { RoleType } from "../../../../pages/main/setting/sub pages/user_and_role/type";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall?: (val: any) => void;
  params: any;
};

const Form: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params } = props;
  const [loading, showLoader, hideLoader] = useLoading();

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
  };

  const initialValues: RoleType = {
    name: state?.data?.role?.name ?? "",
    dashboard: {
      access: state?.data?.role["dashboard"].access ?? false,
      complete: state?.data?.role["dashboard"].complete ?? false,
    },
    clients: {
      access: state?.data?.role["clients"].access ?? false,
      complete: state?.data?.role["clients"].complete ?? false,
    },
    vendors: {
      access: state?.data?.role["vendors"].access ?? false,
      complete: state?.data?.role["vendors"].complete ?? false,
    },
    leads: {
      access: state?.data?.role["leads"].access ?? false,
      complete: state?.data?.role["leads"].complete ?? false,
    },
    items: {
      access: state?.data?.role["items"].access ?? false,
      complete: state?.data?.role["items"].complete ?? false,
    },
    invoices: {
      access: state?.data?.role["invoices"].access ?? false,
      complete: state?.data?.role["invoices"].complete ?? false,
    },
    payments: {
      access: state?.data?.role["payments"].access ?? false,
      complete: state?.data?.role["payments"].complete ?? false,
    },
    expenses: {
      access: state?.data?.role["expenses"]?.access ?? false,
      complete: state?.data?.role["expenses"]?.complete ?? false,
    },
    creditNotes: {
      access: state?.data?.role["creditNotes"].access ?? false,
      complete: state?.data?.role["creditNotes"].complete ?? false,
    },
    demoRequests: {
      access: state?.data?.role["demoRequests"].access ?? false,
      complete: state?.data?.role["demoRequests"].complete ?? false,
    },
    calendar: {
      access: state?.data?.role["calendar"].access ?? false,
      complete: state?.data?.role["calendar"].complete ?? false,
    },
    tasks: {
      access: state?.data?.role["tasks"].access ?? false,
      complete: state?.data?.role["tasks"].complete ?? false,
    },
    userManagement: {
      access: state?.data?.role["userManagement"].access ?? false,
      complete: state?.data?.role["userManagement"].complete ?? false,
    },
    attendance: {
      access: state?.data?.role["attendance"].access ?? false,
      complete: state?.data?.role["attendance"].complete ?? false,
    },
    settings: {
      access: state?.data?.role["settings"].access ?? false,
      complete: state?.data?.role["settings"].complete ?? false,
    },
  };

  const formik = useFormik<RoleType>({
    initialValues,
    validationSchema: roleSchema,
    onSubmit: async (values) => {
      let finalObj = {};
      if (state?.data) {
        finalObj = {
          dashboard: values.dashboard,
          clients: values.clients,
          vendors: values.vendors,
          leads: values.leads,
          items: values.items,
          invoices: values.invoices,
          payments: values.payments,
          expenses: values.expenses,
          creditNotes: values.creditNotes,
          demoRequests: values.demoRequests,
          calendar: values.calendar,
          tasks: values.tasks,
          userManagement: values.userManagement,
          attendance: values.attendance,
          settings: values.settings,
        };
      } else {
        finalObj = {
          name: values.name.toLowerCase(),
          dashboard: values.dashboard,
          clients: values.clients,
          vendors: values.vendors,
          leads: values.leads,
          items: values.items,
          invoices: values.invoices,
          payments: values.payments,
          expenses: values.expenses,
          creditNotes: values.creditNotes,
          demoRequests: values.demoRequests,
          calendar: values.calendar,
          tasks: values.tasks,
          userManagement: values.userManagement,
          attendance: values.attendance,
          settings: values.settings,
        };
      }
      showLoader();
      if (state.data) {
        const res = await updateRole(finalObj, state.data.role._id, hideLoader);
        if (res) {
          showToast(`Role updated`, "success");
          fn.setPositionToBody();
          apiCall?.(params);
          hideLoader();
        }
      } else {
        const res = await createRole(finalObj, hideLoader);
        if (res) {
          showToast(`Role created`, "success");
          fn.setPositionToBody();
          apiCall?.(params);
          hideLoader();
        }
      }
    },
  });

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">
              {state.data ? "Edit Details" : "Add New Role"}
            </h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <InputField
                    id="name"
                    label="Role Name*"
                    type="text"
                    state={formik.values.name}
                    setState={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isDisabled={state?.data ? true : false}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <InputError error={formik.errors.name} />
                  ) : null}
                </div>

                <div>
                  <label className="form-label font12x400 textColor3 mb-2">
                    Role Accessibility
                  </label>
                  <div className="">
                    <table className="table">
                      <thead className="table_head">
                        <tr className="font12x500 textColor3">
                          <th scope="col">Feature</th>
                          <th scope="col">Individual Access</th>
                          <th scope="col">Complete Access</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rolePermission?.map((item2: any) => {
                          return Object.keys(item2).map(
                            (item: any, idx: number) => {
                              return (
                                <tr key={idx} className="font12x400">
                                  <td className="font12x500">
                                    {item2[item]?.label}
                                  </td>
                                  <td>
                                    <div className="customCheck form-check d-flex align-items-center gap-3 p-0">
                                      <input
                                        type="checkbox"
                                        id={`${item}.access`}
                                        name={`${item}.access`}
                                        checked={
                                          // @ts-ignore
                                          formik.values[item]?.access
                                        }
                                        onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          e.target.checked
                                            ? formik.setFieldValue(
                                                `${item}.access`,
                                                true
                                              )
                                            : formik.setFieldValue(
                                                `${item}.access`,
                                                false
                                              );
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="customCheck form-check d-flex align-items-center gap-3 p-0">
                                      <input
                                        type="checkbox"
                                        id={`${item}.complete`}
                                        name={`${item}.complete`}
                                        checked={
                                          // @ts-ignore
                                          formik.values[item]?.complete
                                        }
                                        onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          if (e.target.checked) {
                                            formik.setFieldValue(
                                              `${item}.complete`,
                                              true
                                            );
                                            formik.setFieldValue(
                                              `${item}.access`,
                                              true
                                            );
                                          } else {
                                            formik.setFieldValue(
                                              `${item}.complete`,
                                              false
                                            );
                                            formik.setFieldValue(
                                              `${item}.access`,
                                              false
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn font12x500 shadow-none custom_btn ms-3"
                >
                  {state.data ? (
                    loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )
                  ) : loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>

            {state.data && (
              <UpdationDetail
                lastUpdate={state?.data?.role?.updatedAt}
                updatedBy={state?.data?.role?.updatedBy}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
