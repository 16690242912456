import moment from "moment";
import React from "react";

type propType = {
  state?: any;
  setState?: (val: any) => void;
  isDisabled?: boolean;
  isFutureDateRequired?: boolean;
  width?: string;
};

const InputDate: React.FC<propType> = ({
  state,
  setState,
  isDisabled,
  isFutureDateRequired,
  width,
}) => {
  return (
    <>
      <div className="date_inputs">
        <div>
          <input
            type="date"
            className="w-100"
            placeholder="dd/mm/yyyy"
            name="date"
            id="date"
            value={state}
            onChange={(e) => {
              setState?.(e.target.value);
            }}
            max={isFutureDateRequired ? "" : moment().format("YYYY-MM-DD")}
            disabled={isDisabled}
          />
        </div>
      </div>
    </>
  );
};

export default InputDate;
