import Api from "../../../config/api";
import { SEARCH_ACTIVITIES } from "../../../utils/constants";
import { showToast } from "../../toast/ShowToast";

export const getActivity = async (params: any) => {
  try {
    const res = await Api(false).get(SEARCH_ACTIVITIES, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};
