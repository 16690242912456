import moment from "moment";
import React, { useState } from "react";
import { showToast } from "../lib/toast/ShowToast";
import InputDate from "../shared components/DateInput/InputDate";
import OutsideClickHandler from "react-outside-click-handler";

type propType = {
  state?: any;
  apiCall?: (val: any) => void;
  setState?: (val: any) => void;
  isFutureDateRequired?: boolean;
};

const CalendarFilter2: React.FC<propType> = (props) => {
  const { state, apiCall, setState, isFutureDateRequired } = props;
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fn = {
    setFilterState: () => {
      setIsFilterOpen(!isFilterOpen);
    },
    addParam: () => {
      if (startDate != "" && endDate != "") {
        const checkDate = moment(endDate, "YYYY-MM-DD").isSameOrAfter(
          moment(startDate, "YYYY-MM-DD")
        );
        if (checkDate) {
          setState?.({
            ...state,
            start_date: startDate,
            end_date: endDate,
          });
          apiCall?.({
            ...state,
            page: 1,
            start_date: startDate,
            end_date: endDate,
          });
          fn.setFilterState();
        } else {
          showToast("From date must be less than To date", "error");
        }
      }
    },
    removeParam: () => {
      setStartDate("");
      setEndDate("");
      delete state.start_date;
      delete state.end_date;
      apiCall?.({ ...state });
      fn.setFilterState();
    },
  };

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setIsFilterOpen(false)}>
        <div className="position-relative">
          <div
            className="filter_section_wrapper"
            onClick={fn.setFilterState}
            style={{
              backgroundColor: isFilterOpen ? "#fff" : "var(--bg_color2)",
              border: isFilterOpen ? "1px solid var(--border_color4)" : "none",
            }}
          >
            <img
              src={`/Assets/Svg/${
                isFilterOpen ? "filterClose.svg" : "calendarFilter.svg"
              }`}
              alt="filter"
            />
          </div>

          {isFilterOpen && (
            <div className="filter_option_wrapper">
              <div className="d-flex p-2">
                <div className="">
                  <span className="font12x500 textColor3 me-1">From:</span>
                  <InputDate
                    state={startDate}
                    setState={(e) => setStartDate(e)}
                    isFutureDateRequired={isFutureDateRequired}
                  />
                </div>
                <div className="ms-3">
                  <span className="font12x500 textColor3 me-1">To:</span>
                  <InputDate
                    state={endDate}
                    setState={(e) => setEndDate(e)}
                    isFutureDateRequired={isFutureDateRequired}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end p-2 gap-2">
                <div>
                  <button
                    className="btn font12x500 shadow-none btn_with_color"
                    onClick={fn.addParam}
                  >
                    Apply
                  </button>
                </div>
                <div>
                  <button
                    className="btn font12x500 shadow-none btn_without_color"
                    disabled={startDate === "" && endDate === "" ? true : false}
                    onClick={fn.removeParam}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default CalendarFilter2;
