import { Country } from "country-state-city";
import { checkPhoneCodeForPlus } from "../function/other";

//main color
export const PRIMARY_COLOR = "#c1964e";

//routes for sidebar
//add other routes also
export const sidebarRoutes = [
  {
    path: "/dashboard",
    label: "Dashboard",
  },
  {
    path: "/client",
    label: "Clients Profile",
  },
  {
    path: "/vendor",
    label: "Vendors Profile",
  },
  {
    path: "/lead",
    label: "Leads",
  },
  {
    path: "/item",
    label: "Items",
  },
  {
    path: "/invoice",
    label: "Invoices",
  },
  {
    path: "/invoice/request-invoice",
    label: "Requested Invoice",
  },
  {
    path: "/payment",
    label: "Payments",
  },
  {
    path: "/expense",
    label: "Expenses",
  },
  {
    path: "/credit-note",
    label: "Credit Notes",
  },
  {
    path: "/demo-request",
    label: "Demo Requests",
  },
  {
    path: "/calendar",
    label: "Calender",
  },
  {
    path: "/task",
    label: "Task",
  },
  {
    path: "/reminder",
    label: "Reminders",
  },
  {
    path: "/todo",
    label: "To-Do",
  },
  {
    path: "/user",
    label: "User Management",
  },
  {
    path: "/attendance",
    label: "Attendance",
  },
  {
    path: "/attendance/leave",
    label: "Leaves",
  },
  {
    path: "/setting/general",
    label: "Settings",
  },
];

//getting all countries
export const countryMap = new Map();
Country.getAllCountries().map((country) => {
  countryMap.set(country.isoCode, country.name);
});

//getting all currencies
export const currenciesMap = new Map();
Country.getAllCountries().map((country) => {
  currenciesMap.set(country.currency, country.currency);
});

//getting all currencies
export const countryOtherData: any = [];
Country.getAllCountries().map((country) => {
  countryOtherData.push({
    flag: country.flag,
    name: country.name,
    phoneCode: checkPhoneCodeForPlus(country.phonecode),
  });
});

//client type
export const ONLY_CLIENT: number = 1;
export const ONLY_VENDOR: number = 2;
export const BOTH_CLIENT_VENDOR: number = 3;

//search character length
export const CHARACTER_LENGTH: number = 2;

//view lead routes
export const VIEW_PROFILE = "PROFILE";
export const VIEW_UPDATE = "UPDATE";
export const VIEW_PROPOSAL = "PROPOSAL";
export const VIEWP_QUOTATION = "QUOTATION";
export const VIEW_PO = "PO";
export const VIEW_INVOICE = "INVOICE";
export const VIEW_REQUEST_INVOICE = "REQUEST_INVOICE";
export const VIEW_PAYMENT = "PAYMENT";
export const VIEW_CREDENTIAL = "CREDENTIAL";
export const VIEW_REQUEST_DEMO = "REQUEST_DEMO";
export const ADD_EDIT_PROPOSAL = "ADD_EDIT_PROPOSAL";
export const VIEW_PROPOSAL_PAGE = "VIEW_PROPOSAL_PAGE";

//create user routes
export const USER_ROLES = "USER_ROLES";
export const USER_DETAILS = "USER_DETAILS";
export const USER_BANK_DETAILS = "USER_BANK_DETAILS";
export const USER_OFFICE_DETAILS = "USER_OFFICE_DETAILS";
export const USER_DOCUMENTS = "USER_DOCUMENTS";
export const USER_ATTENDANCE = "USER_ATTENDANCE";
export const USER_SALARY_SLIP = "USER_SALARY_SLIP";

//create user profile routes
export const YOUR_DETAILS = "YOUR_DETAILS";
export const YOUR_BANK_DETAILS = "YOUR_BANK_DETAILS";
export const YOUR_OFFICE_DETAILS = "YOUR_OFFICE_DETAILS";
export const YOUR_DOCUMENTS = "YOUR_DOCUMENTS";
export const YOUR_ATTENDANCE = "YOUR_ATTENDANCE";
export const YOUR_SALARY_SLIP = "YOUR_SALARY_SLIP";

//setting routes
export const SETTING_GENERAL = "GENERAL";
export const SETTING_PROFILE = "PROFILE";
export const SETTING_LOCATION = "LOCATION";
export const SETTING_DEPARTMENT = "DEPARTMENT";
// export const SETTING_ACCOUNT = "ACCOUNT";
export const SETTING_USER_ROLE = "USER_ROLE";
export const SETTING_GST = "GST";
// export const SETTING_TAXES = "TAXES";
export const SETTING_EMPLOYEE = "EMPLOYEE";
export const SETTING_SALARY_SLIP = "SALARY_SLIP";
export const SETTING_INVOICE = "INVOICE";
export const SETTING_EXPENSE = "EXPENSE";
export const SETTING_PROPOSAL = "PROPOSAL";
export const SETTING_PAYMENT = "PAYMENT";
export const SETTING_CREDITNOTE = "CREDITNOTE";

//GST Types
export const gstTypesMap = new Map();
gstTypesMap.set("Registered - Regular GST", "Registered - Regular GST");
gstTypesMap.set("Registered - Comp. Scheme", "Registered - Comp. Scheme");
gstTypesMap.set("Unregistered Business", "Unregistered Business");
gstTypesMap.set("Consumer (B2C)", "Consumer (B2C)");
gstTypesMap.set("Overseas (Outside India)", "Overseas (Outside India)");
gstTypesMap.set("Special Economic Zone (SEZ)", "Special Economic Zone (SEZ)");
gstTypesMap.set("Deemed Export", "Deemed Export");
export const gstTypes = [
  // {
  //   label: "Registered - Regular GST",
  //   value: "Registered - Regular GST",
  // },
  // {
  //   label: "Registered - Comp. Scheme",
  //   value: "Registered - Comp. Scheme",
  // },
  // {
  //   label: "Unregistered Business",
  //   value: "Unregistered Business",
  // },
  // {
  //   label: "Consumer (B2C)",
  //   value: "Consumer (B2C)",
  // },
  // {
  //   label: "Overseas (Outside India)",
  //   value: "Overseas (Outside India)",
  // },
  // {
  //   label: "Special Economic Zone (SEZ)",
  //   value: "Special Economic Zone (SEZ)",
  // },
  // {
  //   label: "Deemed Export",
  //   value: "Deemed Export",
  // },
];

//item type
// export const itemTypes = [
//   {
//     label: "Product",
//     value: 1,
//   },
//   {
//     label: "Service",
//     value: 2,
//   },
// ];

export const itemTypesMap = new Map();
itemTypesMap.set(1, "Product");
itemTypesMap.set(2, "Service");

//lead status
export const leadStatus = [
  {
    value: 0,
    label: "Hot Lead",
  },
  {
    value: 1,
    label: "Warm Lead",
  },
  {
    value: 2,
    label: "Cold Lead",
  },
  {
    value: 3,
    label: "Lost Lead",
  },
  {
    value: 4,
    label: "Won Lead",
  },
];
export const leadStatusMap = new Map();
leadStatusMap.set(0, "Hot Lead");
leadStatusMap.set(1, "Warm Lead");
leadStatusMap.set(2, "Cold Lead");
leadStatusMap.set(3, "Lost Lead");
leadStatusMap.set(4, "Won Lead");

//lead confimation
export const leadConfirmation = [
  {
    value: 0,
    label: "Pending",
  },
  {
    value: 1,
    label: "Approved",
  },
  {
    value: 2,
    label: "Rejected",
  },
];
export const leadConfirmationMap = new Map();
leadConfirmationMap.set(0, "Pending");
leadConfirmationMap.set(1, "Approved");
leadConfirmationMap.set(2, "Rejected");

//different tax
export const taxesList = [
  {
    value: 18,
    label: "Tax1(18%)",
  },
  {
    value: 18,
    label: "Tax2(18%)",
  },
  {
    value: 18,
    label: "Tax3(18%)",
  },
];
export const taxesListMap = new Map();
taxesListMap.set(18, "Tax1(18%)");
taxesListMap.set(19, "Tax2(19%)");
taxesListMap.set(17, "Tax3(17%)");

//task status
export const taskStatus = [
  {
    value: 0,
    label: "Todo",
  },
  {
    value: 1,
    label: "Active",
  },
  {
    value: 2,
    label: "In Progress",
  },
  {
    value: 3,
    label: "Completed",
  },
];
export const taskStatusMap = new Map();
taskStatusMap.set(0, "Todo");
taskStatusMap.set(1, "Active");
taskStatusMap.set(2, "In Progress");
taskStatusMap.set(3, "Completed");

//task priority
export const taskPriority = [
  {
    value: 0,
    label: "Low",
  },
  {
    value: 1,
    label: "Medium",
  },
  {
    value: 2,
    label: "High",
  },
];
export const taskPriorityMap = new Map();
taskPriorityMap.set(0, "Low");
taskPriorityMap.set(1, "Medium");
taskPriorityMap.set(2, "High");

//demo request status
export const demoRequestStatusMap = new Map();
demoRequestStatusMap.set(0, "Pending");
demoRequestStatusMap.set(1, "Approved");
demoRequestStatusMap.set(2, "Rejected");

export const demoRequestStatus = [
  {
    value: 0,
    label: "Pending",
  },
  {
    value: 1,
    label: "Approved",
  },
  {
    value: 2,
    label: "Rejected",
  },
];

//request invoice status
export const invoiceRequestStatusMap = new Map();
invoiceRequestStatusMap.set(0, "Pending");
invoiceRequestStatusMap.set(1, "Made");

export const invoiceRequestStatus = [
  {
    value: 0,
    label: "Pending",
  },
  {
    value: 1,
    label: "Made",
  },
];

export const rolePermission = [
  {
    dashboard: { access: false, complete: false, label: "Dashboard" },
    clients: { access: false, complete: false, label: "Clients" },
    vendors: { access: false, complete: false, label: "Vendors" },
    leads: { access: false, complete: false, label: "Leads" },
    items: { access: false, complete: false, label: "Items" },
    invoices: { access: false, complete: false, label: "Invoices" },
    payments: { access: false, complete: false, label: "Payments" },
    expenses: { access: false, complete: false, label: "Expenses" },
    creditNotes: { access: false, complete: false, label: "Credit Notes" },
    demoRequests: { access: false, complete: false, label: "Demo Requests" },
    calendar: { access: false, complete: false, label: "Calendar" },
    tasks: { access: false, complete: false, label: "Tasks" },
    userManagement: {
      access: false,
      complete: false,
      label: "User Management",
    },
    attendance: { access: false, complete: false, label: "Attendance" },
    settings: { access: false, complete: false, label: "Settings" },
  },
];

//date Format Map
export const dateFormatMap = new Map();
dateFormatMap.set("DD-MM-YYYY", "DD-MM-YYYY");
dateFormatMap.set("YYYY-MM-DD", "YYYY-MM-DD");
dateFormatMap.set("DD-MMM-YYYY", "DD-MMM-YYYY");
dateFormatMap.set("MMM-DD-YYYY", "MMM-DD-YYYY");

//mode map
export const modesMap = new Map();
modesMap.set(0, "Cash");
modesMap.set(1, "Cash Memo");
modesMap.set(2, "Credit Card");
modesMap.set(3, "Cheque");
modesMap.set(4, "Bank Transfer");
modesMap.set(5, "Other");

//invoicestatus map
export const invoiceStatussMap = new Map();
invoiceStatussMap.set(0, "Unpaid");
invoiceStatussMap.set(1, "Partial Paid");
invoiceStatussMap.set(2, "Paid");

//credit not reason map
export const noteReasonMap = new Map();
noteReasonMap.set("Reason 1", "Reason 1");
noteReasonMap.set("Reason 2", "Reason 2");
noteReasonMap.set("Reason 3", "Reason 3");

//demo unit map
export const demoUnitMap = new Map();
demoUnitMap.set("Hours", "Hours");
demoUnitMap.set("Kilometer", "Kilometer");
demoUnitMap.set("Milimeter", "Milimeter");

//payment type map
export const paymentTypeMap = new Map();
paymentTypeMap.set(1, "Received");
paymentTypeMap.set(2, "Made");

//postatus map
export const poStatusMap = new Map();
poStatusMap.set(0, "Pending");
poStatusMap.set(1, "Approved");
poStatusMap.set(2, "Rejected");

//credential Types Map
export const credentialTypesMap = new Map();
credentialTypesMap.set(1, "Demo");
credentialTypesMap.set(2, "Live");

//leave type
export const leaveType = [
  {
    value: "UPL",
    label: "Unpaid Leave",
  },
  {
    value: "PL",
    label: "Paid Leave",
  },
  {
    value: "HD",
    label: "Half Day",
  },
];
//leave type map
export const leaveTypesMap = new Map();
leaveTypesMap.set("UPL", "Unpaid Leave");
leaveTypesMap.set("PL", "Paid Leave");
leaveTypesMap.set("HD", "Half Day");

//attendance type
export const attendanceTypeHourly = [
  {
    value: "UPL",
    label: "Unpaid Leave",
  },
  {
    value: "PL",
    label: "Paid Leave",
  },
  {
    value: "DA",
    label: "Daily Attendance",
  },
];
export const attendanceTypeDaily = [
  {
    value: "UPL",
    label: "Unpaid Leave",
  },
  {
    value: "PL",
    label: "Paid Leave",
  },
  {
    value: "HD",
    label: "Half Day",
  },
  {
    value: "DA",
    label: "Daily Attendance",
  },
];

//leave status
export const leaveStatusMap = new Map();
leaveStatusMap.set(0, "Pending");
leaveStatusMap.set(1, "Accepted");
leaveStatusMap.set(2, "Rejected");

//PL carry forward
export const pLCarryForwardMap = new Map();
pLCarryForwardMap.set(1, "1");
pLCarryForwardMap.set(3, "3");
pLCarryForwardMap.set(4, "4");
pLCarryForwardMap.set(6, "6");
pLCarryForwardMap.set(12, "12");

//attendance day devoted
export const dayDevotedMap = new Map();
dayDevotedMap.set(0, "A");
dayDevotedMap.set(0.5, "HD");
dayDevotedMap.set(1, "P");

//salary Type map
export const salaryTypeMap = new Map();
salaryTypeMap.set("E", "Earning");
salaryTypeMap.set("D", "Deduction");

//salary value type map
export const salaryValueTypeMap = new Map();
salaryValueTypeMap.set("P", "Percentage");
salaryValueTypeMap.set("F", "Fixed");

//user status
export const userStatus = [
  {
    value: 1,
    label: "Active",
  },
  {
    value: 2,
    label: "Inactive",
  },
];
export const userStatusMap = new Map();
userStatusMap.set(1, "Active");
userStatusMap.set(2, "Inactive");

//user sort
export const userSort = [
  {
    value: "asc",
    label: "Ascending",
  },
  {
    value: "desc",
    label: "Descending",
  },
];

//calander event types
export const eventTypes = [
  { label: "Meeting", color: "#e67c73" },
  { label: "Reminder", color: "#33b679" },
  { label: "Task", color: "#ef6c00" },
];

//taxation Types
export const taxTypesMap = new Map();
taxTypesMap.set("GST", "GST");
taxTypesMap.set("Composition Scheme", "Composition Scheme");
taxTypesMap.set("Non-GST (international)", "Non-GST (international)");

//API URL's
export const PASSWORD_LOGIN = `/auth`;
export const OTP_LOGIN = `/auth/send-otp`;
export const VERIFY_OTP = `/auth/verify-otp`;
export const GET_LOGGED_IN_USER = `/user/id`;
export const CREATE_USER = `/user`;
export const UPDATE_USER = `/user`;
export const SEARCH_USER = `/user/search`;
export const SEARCH_USER_BY_ID = `/user`;
export const UPLOAD_USER_FILE = `/user/form/file/upload`;
export const UPLOAD_MANY_USER_FILE = `/user/form/file/upload/many`;
export const DELETE_USER_FILE_BY_ID = `/user/file`;
export const CHANGED_PASSWORD = `/user`;
export const CREATE_CLIENT = `/client`;
export const UPDATE_CLIENT = `/client`;
export const SEARCH_CLIENT = `/client/search`;
export const SEARCH_CLIENT_BY_ID = `/client`;
export const DELETE_CLIENT_HARD = `/client/multiple/hard`;
export const CREATE_LEAD = `/lead`;
export const UPDATE_LEAD = `/lead`;
export const SEARCH_LEAD = `/lead/search`;
export const SEARCH_LEAD_BY_ID = `/lead`;
export const GET_LEAD_STATUS_COUNT = `/lead/status-count`;
export const CREATE_ITEM = `/item`;
export const UPDATE_ITEM = `/item`;
export const SEARCH_ITEM = `/item/search`;
export const SEARCH_ITEM_BY_ID = `/item`;
export const DELETE_ITEM_SOFT = `/item/multiple/soft`;
export const CREATE_PROPOSAL = `/proposal`;
export const UPDATE_PROPOSAL = `/proposal`;
export const SEARCH_PROPOSAL = `/proposal/search`;
export const SEARCH_PROPOSAL_BY_ID = `/proposal`;
export const CREATE_LEAD_UPDATE = `/update/form`;
export const UPDATE_LEAD_UPDATE = `/update/form`;
export const SEARCH_LEAD_UPDATE = `/update/search`;
export const SEARCH_LEAD_UPDATE_BY_ID = `/update`;
export const DELETE_LEAD_SOFT = `/lead/multiple/soft`;
export const DELETE_LEAD_HARD = `/lead/multiple/hard`;
export const CREATE_LEAD_QUOTATION = `/quotation/form`;
export const UPDATE_LEAD_QUOTATION = `/quotation/form`;
export const SEARCH_LEAD_QUOTATION = `/quotation/search`;
export const SEARCH_LEAD_QUOTATION_BY_ID = `/quotation`;
export const CREATE_MEETING = `/meeting`;
export const UPDATE_MEETING = `/meeting`;
export const SEARCH_MEETING = `/meeting/search`;
export const SEARCH_MEETING_BY_ID = `/meeting`;
export const CREATE_PURCHASE_ORDER = `/purchase-order/form`;
export const UPDATE_PURCHASE_ORDER = `/purchase-order/form`;
export const SEARCH_PURCHASE_ORDER = `/purchase-order/search`;
export const SEARCH_PURCHASE_ORDER_BY_ID = `/purchase-order`;
export const CREATE_INVOICE = `/invoice`;
export const UPDATE_INVOICE = `/invoice`;
export const SEARCH_INVOICE = `/invoice/search`;
export const SEARCH_INVOICE_BY_ID = `/invoice`;
export const DELETE_INVOICE_SOFT = `/invoice/multiple/soft`;
export const DELETE_INVOICE_HARD = `/invoice/multiple/hard`;
export const UPDATE_ITEMS_QUANTITY = `/item/quantity`;
export const CREATE_TASK = `/task/form`;
export const UPDATE_TASK = `/task/form`;
export const SEARCH_TASK = `/task/search`;
export const SEARCH_TASK_BY_ID = `/task`;
export const DELETE_TASK = `/task/multiple/hard`;
export const CREATE_TODO = `/todo`;
export const UPDATE_TODO = `/todo`;
export const SEARCH_TODO = `/todo/search`;
export const SEARCH_TODO_BY_ID = `/todo`;
export const DELETE_TODO = `/todo/multiple/hard`;
export const CREATE_REMINDER = `/reminder`;
export const UPDATE_REMINDER = `/reminder`;
export const SEARCH_REMINDER = `/reminder/search`;
export const SEARCH_REMINDER_BY_ID = `/reminder`;
export const DELETE_REMINDER = `/reminder/multiple/hard`;
export const SEARCH_ACTIVITIES = `/activity/search`;
export const CREATE_PAYMENT = `/payment`;
export const SEARCH_PAYMENT = `/payment/search`;
export const SEARCH_PAYMENT_BY_ID = `/payment`;
export const UPDATE_PAYMENT = `/payment`;
export const DELETE_PAYMENT = `/payment/multiple/hard`;
export const CREATE_DEMO_REQUEST = `/demo-request`;
export const UPDATE_DEMO_REQUEST = `/demo-request`;
export const SEARCH_DEMO_REQUEST = `/demo-request/search`;
export const SEARCH_DEMO_REQUEST_BY_ID = `/demo-request`;
export const CREATE_CREDIT_NOTE = `/credit-note`;
export const UPDATE_CREDIT_NOTE = `/credit-note`;
export const SEARCH_CREDIT_NOTE = `/credit-note/search`;
export const SEARCH_CREDIT_NOTE_BY_ID = `/credit-note`;
export const DELETE_CREDIT_NOTE_SOFT = `/credit-note/multiple/soft`;
export const DELETE_CREDIT_NOTE_HARD = `/credit-note/multiple/hard`;
export const CREATE_ATTENDANCE = `/attendance`;
export const UPDATE_ATTENDANCE = `/attendance`;
export const SEARCH_ATTENDANCE = `/attendance/search`;
export const SEARCH_ATTENDANCE_BY_ID = `/attendance`;
export const GENERATE_PAYSLIP = `/attendance`;
export const GET_PAYSLIP_BY_ID = `/attendance/slip`;
export const DELETE_PAYSLIP_BY_ID = `/attendance/slip`;
export const CREATE_SALARY_CONSTRAINT = `/salary-constraint`;
export const UPDATE_SALARY_CONSTRAINT = `/salary-constraint`;
export const SEARCH_SALARY_CONSTRAINT = `/salary-constraint/search`;
export const SEARCH_SALARY_CONSTRAINT_BY_ID = `/salary-constraint`;
export const SIGN_IN = `/attendance/sign-in`;
export const CHECK_USER_SIGN_IN_OR_NOT = `/daily-attendance/signed-in-status`;
export const SIGN_OUT = `/attendance/sign-out`;
export const CREATE_DAILY_ATTENDANCE = `/daily-attendance`;
export const UPDATE_DAILY_ATTENDANCE = `/daily-attendance`;
export const DELETE_DAILY_ATTENDANCE = `/daily-attendance`;
export const DAILY_ATTENDANCE_SEARCH = `/daily-attendance/search`;
export const CREATE_ROLE = `/company/role`;
export const UPDATE_ROLE = `/company/role`;
export const SEARCH_ROLE = `/company/role/search`;
export const SEARCH_ROLE_BY_ID = `/company/role`;
export const CREATE_TAX = `/company/tax`;
export const UPDATE_TAX = `/company/tax`;
export const SEARCH_TAX = `/company/tax/search`;
export const SEARCH_TAX_BY_ID = `/company/tax`;
export const GET_LOGGED_IN_COMPANY = `/company/id`;
export const UPDATE_COMPANY = `/company`;
export const SEARCH_COMPANY = `/company/search`;
export const SEARCH_COMPANY_BY_ID = `/company`;
export const UPDATE_GENERAL = `/company/setting/general/form`;
export const SEARCH_GENERAL = `/company/setting/general/search`;
export const SEARCH_GENERAL_BY_ID = `/company/setting/general`;
export const CREATE_EXPENSE = `/expense`;
export const UPDATE_EXPENSE = `/expense`;
export const SEARCH_EXPENSE = `/expense/search`;
export const SEARCH_EXPENSE_BY_ID = `/expense`;
export const DELETE_EXPENSE_SOFT = `/expense/multiple/soft`;
export const DELETE_EXPENSE_HARD = `/expense/multiple/hard`;
export const SEARCH_SETTING_DOCUMENT = `/company/setting/document/search`;
export const SETTING_DOCUMENT_BY_ID = `/company/setting/document`;
export const UPDATE_SETTING_DOCUMENT = `/company/setting/document`;
export const GET_DOCUMENT_NUMBER = `/company/document/number`;
export const GET_NOTIFICATION = `/notification/search`;
export const UPDATE_NOTIFICATION = `/notification`;
export const CLEAR_ALL_NOTIFICATION = `/notification/clear-all`;
export const USER_DEVICE = `/user-device`;
export const DELETE_USER_DEVICE = `/user-device`;
export const CREATE_CREDENTIAL = `/credential`;
export const UPDATE_CREDENTIAL = `/credential`;
export const SEARCH_CREDENTIAL = `/credential/search`;
export const SEARCH_CREDENTIAL_BY_ID = `/credential`;
export const CREATE_LEAVE = `/leave`;
export const UPDATE_LEAVE = `/leave`;
export const SEARCH_LEAVE = `/leave/search`;
export const DELETE_LEAVE_BY_ID = `/leave`;
export const CREATE_REQUEST_INVOICE = `/invoice-request`;
export const UPDATE_REQUEST_INVOICE = `/invoice-request`;
export const SEARCH_REQUEST_INVOICE = `/invoice-request/search`;
export const SEARCH_REQUEST_INVOICE_BY_ID = `/invoice-request`;
export const CREATE_DEPARTMENT = `/company/department`;
export const UPDATE_DEPARTMENT = `/company/department`;
export const SEARCH_DEPARTMENT = `/company/department/search`;
export const SEARCH_DEPARTMENT_BY_ID = `/company/department`;
