import Api from "../../config/api";
import {
  CREATE_REMINDER,
  SEARCH_REMINDER,
  SEARCH_REMINDER_BY_ID,
  UPDATE_REMINDER,
} from "../../utils/constants";
import { showToast } from "../toast/ShowToast";

export const getReminder = async (params?: any) => {
  try {
    const res = await Api(false).get(SEARCH_REMINDER, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const getReminderById = async (id?: string) => {
  try {
    const res = await Api(false).get(`${SEARCH_REMINDER_BY_ID}/${id}`);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const createReminder = async (body: any, hideLoader: () => void) => {
  try {
    const res = await Api(false).post(CREATE_REMINDER, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const updateReminder = async (
  body: any,
  id: string,
  hideLoader: () => void
) => {
  try {
    const res = await Api(false).put(`${UPDATE_REMINDER}/${id}`, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};
