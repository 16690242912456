import { useContext } from "react";
import { UserProfileContext } from "../../../../../pages/main/user/User Profile";
import PhoneCode from "../../../../../shared components/Dropdown/PhoneCode";
import ViewFileInput from "../../../../../shared components/SingleFileInput/ViewFileInput";
import ViewMultiFileInput from "../../../../../shared components/SingleFileInput/ViewMultiFileInput";
import TextareaInput from "../../../../../shared components/TextareaInput";
import InputField from "../../../../../shared components/TextInput";
import ProfilePhoto from "./ProfilePhoto";

const UserDetails = () => {
  const { userObj } = useContext(UserProfileContext);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font16x600 textColor3">Your Details</p>
        </div>

        <div className="details_form_wrapper">
          {/* form */}
          <form>
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12 col-sm-6 order-2 order-sm-1">
                  <div className="mb-3">
                    <InputField
                      id="customUserId"
                      label="User Id"
                      type="text"
                      state={userObj?.customUserId}
                      isDisabled={true}
                    />
                  </div>
                  <div className="mb-3">
                    <InputField
                      id="employeeId"
                      label="Employee Id"
                      type="text"
                      state={userObj?.employeeId}
                      isDisabled={true}
                    />
                  </div>
                  <div className="mb-3">
                    <InputField
                      id="name"
                      label="Name"
                      type="text"
                      state={userObj?.name}
                      isDisabled={true}
                    />
                  </div>

                  <div className="mb-3">
                    <TextareaInput
                      id="address"
                      label="Address"
                      state={userObj?.address}
                      isDisabled={true}
                    />
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="email"
                      label="Email"
                      type="text"
                      state={userObj?.email}
                      isDisabled={true}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="contactNumber"
                      className="form-label font12x400 textColor3 mb-1"
                    >
                      Phone No
                    </label>
                    <div className="input-group">
                      <div
                        className="input-group-text font12x600 border-0 cursor_pointer"
                        style={{
                          cursor: "no-drop",
                          backgroundColor: "#f2f2f2",
                          color: "#9C9E9C",
                        }}
                      >
                        <p className="mb-0 font12x600">{userObj?.phoneCode}</p>
                      </div>
                      <input
                        type="text"
                        className="form-control shadow-none font12x600 border-0"
                        id="mobile"
                        value={userObj?.mobile}
                        disabled={true}
                        style={{
                          cursor: "no-drop",
                          backgroundColor: "#f2f2f2",
                          color: "#9C9E9C",
                        }}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="contactNumber"
                      className="form-label font12x400 textColor3 mb-1"
                    >
                      Emergency No
                    </label>
                    <div className="input-group">
                      <div
                        className="input-group-text font12x600 border-0 cursor_pointer"
                        style={{
                          cursor: "no-drop",
                          backgroundColor: "#f2f2f2",
                          color: "#9C9E9C",
                        }}
                      >
                        <p className="mb-0 font12x600">
                          {userObj?.emergencyPhoneCode}
                        </p>
                      </div>
                      <input
                        type="text"
                        className="form-control shadow-none font12x600 border-0"
                        id="emergencyNumber"
                        value={userObj?.emergencyNumber}
                        disabled={true}
                        style={{
                          cursor: "no-drop",
                          backgroundColor: "#f2f2f2",
                          color: "#9C9E9C",
                        }}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="bloodGroup"
                      label="Blood Group"
                      type="text"
                      state={userObj?.bloodGroup}
                      isDisabled={true}
                    />
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="pan"
                      label="PAN No"
                      type="text"
                      state={userObj?.pan}
                      isDisabled={true}
                    />
                    {userObj?.p && (
                      <ViewFileInput
                        url={userObj?.p?.url}
                        name={userObj?.p?.name}
                      />
                    )}
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="aadharNumber"
                      label="Aadhaar No"
                      type="text"
                      state={userObj?.aadharNumber}
                      isDisabled={true}
                    />
                    {userObj?.a?.length > 0 && (
                      <ViewMultiFileInput state={userObj?.a} />
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-6 order-1 order-sm-2">
                  <ProfilePhoto state={userObj?.image} name={userObj?.name} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
