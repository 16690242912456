import Api from "../../config/api";
import { emailLogin } from "../../pages/common/EmailLogin";
import { otpLogin } from "../../pages/common/OtpLogin";
import {
  GET_LOGGED_IN_COMPANY,
  GET_LOGGED_IN_USER,
  OTP_LOGIN,
  PASSWORD_LOGIN,
  VERIFY_OTP,
  GET_DOCUMENT_NUMBER,
  USER_DEVICE,
  DELETE_USER_DEVICE,
} from "../../utils/constants";
import { showToast } from "../toast/ShowToast";

export const passwordLogin = async (
  body: emailLogin,
  hideLoader: () => void
) => {
  try {
    const res = await Api(false).post(PASSWORD_LOGIN, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const otpLoginMethod = async (body: otpLogin) => {
  try {
    const res = await Api(false).post(OTP_LOGIN, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error.response.data.message}`, "error");
    }
  }
};

export const verifyOtpMethod = async (body: any, hideLoader: () => void) => {
  try {
    const res = await Api(false).post(VERIFY_OTP, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const getloggedInUserId = async (params: any) => {
  try {
    const res = await Api(false).get(GET_LOGGED_IN_USER, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const getloggedInCompanyId = async (params: any) => {
  try {
    const res = await Api(false).get(GET_LOGGED_IN_COMPANY, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const getDocumentNumberById = async (
  id?: string | null,
  params?: any
) => {
  try {
    const res = await Api(false).get(`${GET_DOCUMENT_NUMBER}/${id}`, {
      params,
    });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const captureUserDevice = async (body: any) => {
  try {
    const res = await Api(false).post(USER_DEVICE, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      // showToast(`${error.response.statusText}`, "error");
    } else {
      // showToast(`${error.response.data.message}`, "error");
    }
  }
};

export const deleteUserDevice = async (id: any) => {
  try {
    const res = await Api(false).delete(`${DELETE_USER_DEVICE}/${id}`);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      // showToast(`${error.response.statusText}`, "error");
    } else {
      // showToast(`${error.response.data.message}`, "error");
    }
  }
};
