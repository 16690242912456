import Menu from "./Menu";

const MenuBar = () => {
  return (
    <>
      <div>
        <div
          className="details_menu_wrapper"
          style={{
            minHeight: "calc(100vh - 102px)",
            height: "calc(100vh - 102px)",
          }}
        >
          <Menu path={`/user/your-details`} label="Your Details" />
          <Menu path={`/user/your-bank-details`} label="Bank Details" />
          <Menu path={`/user/your-office-details`} label="Office Details" />
          <Menu path={`/user/your-documents`} label="Documents" />
          <Menu path={`/user/your-attendance`} label="Attendance" />
          <Menu path={`/user/your-salary-slip`} label="Salary Slip" />
        </div>
      </div>
    </>
  );
};

export default MenuBar;
