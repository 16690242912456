import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

type propType = {
  state: any;
  apiCall: (val: any) => void;
  setState: (val: any) => void;
  userId: string;
};

const FunnelFilterTask: React.FC<propType> = (props) => {
  const { state, apiCall, setState, userId } = props;

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const fn = {
    setFilterState: () => {
      setIsFilterOpen(!isFilterOpen);
    },
    addParam: (value?: string) => {
      if (value === "all") {
        delete state?.user;
        delete state?.assignTo;
        setState({
          ...state,
        });
        apiCall?.({ ...state, page: 1 });
      } else if (value === "assigned_Me") {
        delete state?.user;
        setState({
          ...state,
          assignTo: userId,
        });
        apiCall?.({ ...state, page: 1, assignTo: userId });
      } else {
        delete state?.assignTo;
        setState({
          ...state,
          user: userId,
        });
        apiCall?.({ ...state, page: 1, user: userId });
      }
    },
  };

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setIsFilterOpen(false)}>
        <div className="position-relative">
          <div
            className="filter_section_wrapper"
            onClick={fn.setFilterState}
            style={{
              backgroundColor: isFilterOpen ? "#fff" : "var(--bg_color2)",
              border: isFilterOpen ? "1px solid var(--border_color4)" : "none",
            }}
          >
            <img
              src={`/Assets/Svg/${
                isFilterOpen ? "filterClose.svg" : "filter.svg"
              }`}
              alt="filter"
            />
          </div>

          {isFilterOpen && (
            <div
              className="filter_option_wrapper"
              style={{
                minWidth: "137px",
              }}
            >
              {/* <div className="p-2 pb-0">
                <p className="mb-0 font12x500 textColor1">Assigned</p>
              </div> */}
              <div className="p-2 pt-0">
                <div className="radioCustom d-flex align-items-center mt-2">
                  <input
                    type="radio"
                    className="me-2"
                    id="all"
                    value=""
                    checked={state?.user || state?.assignTo ? false : true}
                    onChange={(e) => {
                      fn.addParam("all");
                    }}
                  />
                  <label
                    className="font12x500 textColor1 form-check-label"
                    htmlFor="all"
                  >
                    All
                  </label>
                </div>
                <div className="radioCustom d-flex align-items-center mt-2">
                  <input
                    type="radio"
                    className="me-2"
                    id="created_Me"
                    value=""
                    checked={state?.user ? true : false}
                    onChange={(e) => {
                      fn.addParam("created_Me");
                    }}
                  />
                  <label
                    className="font12x500 textColor1 form-check-label"
                    htmlFor="created_Me"
                  >
                    Created by me
                  </label>
                </div>
                <div className="radioCustom d-flex align-items-center mt-2">
                  <input
                    type="radio"
                    className="me-2"
                    id="assigned_Me"
                    value=""
                    checked={state?.assignTo ? true : false}
                    onChange={(e) => {
                      fn.addParam("assigned_Me");
                    }}
                  />
                  <label
                    className="font12x500 textColor1 form-check-label"
                    htmlFor="assigned_Me"
                  >
                    Assigned to me
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default FunnelFilterTask;
