import React, { useContext } from "react";
import { CompanyContext } from "../../../../pages/main/setting/sub pages/profile";
import DropdownOne from "../../../../shared components/Dropdown/DropdownOne";
import InputError from "../../../../shared components/InputError/InputError";
import InputField from "../../../../shared components/TextInput";
import { currenciesMap } from "../../../../utils/constants";

const OtherDetails = () => {
  const { formik, updatedValues, setUpdatedValues } =
    useContext(CompanyContext);

  return (
    <>
      <div className="d-flex justify-content-between py-3">
        <p className="m-0 font14x600 textColor3">Other Details</p>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          TIN No
        </label>

        <div className="setting_input_wrapper">
          <InputField
            id="tinNumber"
            type="text"
            state={formik.values.tinNumber}
            setState={(e) => {
              formik.setFieldValue("tinNumber", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                tinNumber: e.target.value,
                isObjChange: true,
              });
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.tinNumber && formik.errors.tinNumber ? (
            <InputError error={formik.errors.tinNumber} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          PAN
        </label>

        <div className="setting_input_wrapper">
          <InputField
            id="pan"
            type="text"
            state={formik.values.pan}
            setState={(e) => {
              formik.setFieldValue("pan", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                pan: e.target.value,
                isObjChange: true,
              });
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.pan && formik.errors.pan ? (
            <InputError error={formik.errors.pan} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          VAT
        </label>

        <div className="setting_input_wrapper">
          <InputField
            id="vat"
            type="text"
            state={formik.values.vat}
            setState={(e) => {
              formik.setFieldValue("vat", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                vat: e.target.value,
                isObjChange: true,
              });
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.vat && formik.errors.vat ? (
            <InputError error={formik.errors.vat} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          TAN
        </label>

        <div className="setting_input_wrapper">
          <InputField
            id="tan"
            type="text"
            state={formik.values.tan}
            setState={(e) => {
              formik.setFieldValue("tan", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                tan: e.target.value,
                isObjChange: true,
              });
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.tan && formik.errors.tan ? (
            <InputError error={formik.errors.tan} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          Default Currency
        </label>

        <div className="setting_input_wrapper">
          <DropdownOne
            placeholder="Select currency"
            name="currency"
            data={currenciesMap}
            state={formik.values.currency}
            setState={(e?: string) => {
              formik.setFieldValue("currency", e);
              setUpdatedValues({
                ...updatedValues,
                currency: e,
                isObjChange: true,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default OtherDetails;
