import moment from "moment";
import { useContext } from "react";
import { UserProfileContext } from "../../../../../pages/main/user/User Profile";
import InputField from "../../../../../shared components/TextInput";

const UserAttendance = () => {
  const { userObj } = useContext(UserProfileContext);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font16x600 textColor3">Attendance</p>
        </div>

        <div className="details_form_wrapper">
          {/* form */}
          <form>
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="mb-3">
                    <div className="mb-2">
                      <h3 className="font12x600 textColor3 mb-0">
                        Work Timings
                      </h3>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <InputField
                          id="startTime"
                          label="Start Time*"
                          type="time"
                          name="startTime"
                          state={
                            userObj?.workTimings &&
                            moment(userObj?.workTimings?.startTime).format(
                              "HH:mm"
                            )
                          }
                          isDisabled={true}
                        />
                      </div>
                      <div>
                        <InputField
                          id="halfTime"
                          label="Half Time*"
                          type="time"
                          name="halfTime"
                          state={
                            userObj?.workTimings &&
                            moment(userObj?.workTimings?.halfTime).format(
                              "HH:mm"
                            )
                          }
                          isDisabled={true}
                        />
                      </div>
                      <div>
                        <InputField
                          id="endTime"
                          label="End Time*"
                          type="time"
                          name="endTime"
                          state={
                            userObj?.workTimings &&
                            moment(userObj?.workTimings?.endTime).format(
                              "HH:mm"
                            )
                          }
                          isDisabled={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="maxHours"
                      label="Max Hours"
                      type="number"
                      state={userObj?.maxHours}
                      width="200px"
                      isDisabled={true}
                    />
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="pLHours"
                      label="Paid Hours"
                      type="number"
                      state={userObj?.pLHours}
                      isDisabled={true}
                      width="200px"
                    />
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="numberOfPL"
                      label="Number of PL"
                      type="number"
                      state={userObj?.numberOfPL}
                      isDisabled={true}
                      width="200px"
                    />
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="pLCarryForward"
                      label="PL Carry Forward"
                      type="number"
                      state={userObj?.pLCarryForward}
                      isDisabled={true}
                      width="200px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UserAttendance;
