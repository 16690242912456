import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataNotFound from "../../../../../components/Data Not Found";
import Pagination from "../../../../../components/Pagination";
import UpdateData from "../../../../../components/Requested Invoice Component/UpdateData";
import DeleteModal from "../../../../../components/Trashed Modal/DeleteModal";
import useLoading from "../../../../../hooks/useLoading";
import { getRequestInvoice } from "../../../../../lib/api functions/invoice/request_invoice";
import SkeletonLoader from "../../../../../shared components/Loader/SkeletonLoader";
import Search from "../../../../../shared components/Search";
import {
  invoiceRequestStatus,
  invoiceRequestStatusMap,
} from "../../../../../utils/constants";
import { MainContext } from "../../../../../utils/private_routes/PrivateRoutes";
import { commonType } from "../../../lead";
import { ParamType } from "./type";

export const getRequestColor = (status: number): any => {
  switch (status) {
    case 0:
      return "#F5BE5F";
    case 1:
      return "#6BDA9F";
    default:
      return null;
  }
};

const RequestedInvoice = () => {
  const { userId, companyId, roles, setting } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const [requests, setRequests] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    company: companyId,
  });

  const fn = {
    deleteData: () => {
      setCommonData({
        data: ["ex1"],
        modal: true,
        check: "delete",
      });
    },
    getInvoiceRequests: async (params?: ParamType) => {
      showLoader();
      const res = await getRequestInvoice(params);
      if (res) {
        setRequests(res.data.invoiceRequests);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
  };

  useEffect(() => {
    if (roles?.length !== 0) {
      if (!roles[0]?.invoices.complete) {
        params.user = userId;
        fn.getInvoiceRequests(params);
      } else {
        fn.getInvoiceRequests(params);
      }
    }
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="data_render_wrapper">
          {/* filters */}
          <div className="filter_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-12 col-md-4">
                  <Search
                    placeholder="Search by PO Number"
                    state={params}
                    setState={setParams}
                    apiCall={fn.getInvoiceRequests}
                    paramValue="poNumber"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="list_view_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center pb-3">
                <div className="col-12 col-lg-3">
                  <span className="font16x600 textColor3">
                    List of Requested Invoices
                  </span>
                </div>
              </div>
            </div>

            {/* table */}
            {requests?.length !== 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="font12x500 textColor3">
                        <th scope="col">#</th>
                        <th scope="col">Date Created</th>
                        <th scope="col">PO Number</th>
                        {/* <th scope="col">User Id</th> */}
                        <th scope="col">Sales Person Details</th>
                        <th scope="col">Lead Details</th>
                        <th scope="col">Description</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {requests?.map((request: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <th>
                              {(params.page - 1) * params.per_page + (i + 1)}
                            </th>
                            <td>
                              {moment(request?.createdAt).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td>{request?.poNumber}</td>
                            <td>
                              <span className="textColor2 mb-1">
                                {request?.user?.name}
                              </span>
                              <br />
                              <span>{request?.user?.customUserId}</span>
                            </td>
                            <td
                              className="cursor_pointer"
                              onClick={() =>
                                navigate(
                                  `/lead/view/${request?.lead?._id}/request-invoice`,
                                  { state: { _id: request?._id } }
                                )
                              }
                            >
                              <span className="textColor2 mb-1">
                                {request?.lead?.name}
                              </span>
                              <br />
                              <span>{request?.lead?.client?.name}</span>
                            </td>
                            <td
                              style={{
                                maxWidth: 200,
                              }}
                            >
                              {request?.description !== ""
                                ? request?.description
                                : "--"}
                            </td>
                            <td>
                              <UpdateData
                                data={invoiceRequestStatus}
                                color={getRequestColor(request?.status)}
                                label={invoiceRequestStatusMap.get(
                                  request?.status
                                )}
                                id={request?._id}
                                apiCall={fn.getInvoiceRequests}
                                params={params}
                                status={request?.status}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : loading ? (
              <SkeletonLoader />
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>

        {/* pagination */}
        {requests?.length !== 0 && (
          <div className="pagination_wrapper pt-3 px-3">
            <Pagination
              currentPage={params.page}
              onPageChange={(e) => {
                fn.getInvoiceRequests({ ...params, page: e });
                setParams({
                  ...params,
                  page: e,
                });
              }}
              pageSize={params.per_page}
              totalCount={Math.ceil(totalResult / params.per_page)}
              onChangePerPage={(e) => {
                fn.getInvoiceRequests({ ...params, per_page: e });
                setParams({
                  ...params,
                  per_page: e,
                });
              }}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {commonData?.modal && commonData?.check === "delete" && (
        <DeleteModal
          label="Lead"
          dataIds={commonData?.data}
          setState={setCommonData}
        />
      )}
    </>
  );
};

export default RequestedInvoice;
