import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { ViewLeadContext } from "../..";
import DataNotFound from "../../../../../../components/Data Not Found";
import useLoading from "../../../../../../hooks/useLoading";
import { getPayment } from "../../../../../../lib/api functions/payment";
import { modesMap } from "../../../../../../utils/constants";
import { MainContext } from "../../../../../../utils/private_routes/PrivateRoutes";
import { ParamType } from "./type";

const ViewPayment = () => {
  const { id } = useContext(ViewLeadContext);
  const { companyId, setting } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [payments, setPayments] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [params, setParams] = useState<ParamType>({
    // page: 1,
    // per_page: 10,
    lead: id,
    company: companyId,
  });

  const fn = {
    getPayments: async (params?: ParamType) => {
      showLoader();
      const res = await getPayment(params);
      if (res) {
        setPayments(res.data.payments);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
  };

  useEffect(() => {
    fn.getPayments(params);
  }, []);

  return (
    <>
      <div>
        <div className="viewlead_content_header d-flex justify-content-between">
          <p className="m-0 font14x500 textColor3">Payments</p>
        </div>

        {loading ? (
          <Skeleton height="190px" borderRadius="8px" />
        ) : (
          <div className="menu_content_wrapper">
            {/* table */}
            {payments?.length !== 0 ? (
              <div className="table_wrapper">
                <table className="table">
                  <thead className="table_head_small">
                    <tr className="font12x500 textColor3">
                      <th scope="col">Date</th>
                      <th scope="col">Payment no</th>
                      <th scope="col">Invoice no</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Mode</th>
                      {/* <th scope="col">Available credit</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {payments?.map((payment: any, i: number) => {
                      return (
                        <tr key={i} className="font12x400">
                          <td>
                            {moment(payment?.date).format(setting?.dateFormat)}
                          </td>
                          <td>{payment?.number}</td>
                          <td>{payment?.invoice?.invoiceNumber}</td>
                          <td>{`₹ ${payment?.amount?.toLocaleString()}`}</td>
                          <td>{modesMap.get(payment?.mode)}</td>
                          {/* <td>not define</td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <DataNotFound />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ViewPayment;
