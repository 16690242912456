import moment from "moment";
import { useContext } from "react";
import { UserProfileContext } from "../../../../../pages/main/user/User Profile";
import InputDateForm from "../../../../../shared components/DateInput/InputDateForm";
import RadioButton from "../../../../../shared components/RadioButton/RadioButton";
import InputField from "../../../../../shared components/TextInput";
import SwitchToggle from "../../../../../shared components/ToggleButton/SwitchToggle";

const OfficeDetails = () => {
  const { userObj } = useContext(UserProfileContext);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font16x600 textColor3">Office Details</p>
        </div>

        <div className="details_form_wrapper">
          {/* form */}
          <form>
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                    <label className="w-100 form-label font12x400 textColor1 mb-1">
                      Is remote location?
                    </label>

                    <div className="setting_input_wrapper text-start text-sm-end">
                      <SwitchToggle
                        state={userObj?.isRemoteLocation}
                        isDisabled={true}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <InputDateForm
                      id="doj"
                      label="Date of Joining"
                      type="date"
                      state={moment(userObj?.doj).format("YYYY-MM-DD")}
                      isDisabled={true}
                    />
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="department"
                      label="Department"
                      type="text"
                      state={userObj?.department}
                      isDisabled={true}
                    />
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="designation"
                      label="Designation"
                      type="text"
                      state={userObj?.designation}
                      isDisabled={true}
                    />
                  </div>

                  <div className="mb-4">
                    <label className="form-label font12x400 textColor3 mb-1">
                      Salary Type
                    </label>
                    <div className="d-flex gap-3">
                      <RadioButton
                        label="Daily Basis"
                        htmlFor="daily"
                        name="salaryType"
                        value="D"
                        state={userObj?.salaryType}
                        isDisabled={true}
                      />
                      <RadioButton
                        label="Hourly Basis"
                        htmlFor="hourly"
                        name="salaryType"
                        value="H"
                        state={userObj?.salaryType}
                        isDisabled={true}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="salary"
                      label="Salary"
                      type="tel"
                      state={userObj?.salary}
                      isDisabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default OfficeDetails;
