import React from "react";
import { BsCalendarWeek } from "react-icons/bs";

type PropsType = {
  label: string;
  value: string;
};
const ShowSelectedDate: React.FC<PropsType> = ({ label, value }) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <span className="font12x500 textColor3 me-1">{label}</span>
        <div className="selected_date_wrapper font12x400 d-flex align-items-center">
          {value}
          <BsCalendarWeek className="ms-2" />
        </div>
      </div>
    </>
  );
};

export default ShowSelectedDate;
