import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { FaPrint } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { BiRupee } from "react-icons/bi";
import InvoiceSidebar from "../../../../components/Invoice components/View/InvoiceSidebar";
import useLoading from "../../../../hooks/useLoading";
import {
  getInvoice,
  getInvoiceById,
} from "../../../../lib/api functions/invoice/invoice";
import Skeleton from "react-loading-skeleton";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import { commonType } from "../../lead";
import GenerateInvoiceDoc from "../../../../components/GenerateDoc/GenerateInvoiceDoc";
import ReceivedForm from "../../../../components/Payment Component/ReceivedForm";
import { getGeneralSetting } from "../../../../lib/api functions/setting/general";
import PrintComponents from "react-print-components";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { roundToTwo } from "../../../../function/other";
import moment from "moment";

const ViewInvoicePage = () => {
  const companyObj = JSON.parse(window.localStorage.getItem("company") || "");

  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const { setPageTitle, companyId } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [loading2, showLoader2, hideLoader2] = useLoading();
  const [invoice, setInvoice] = useState<any>([]);
  const [singleInvoice, setSingleInvoice] = useState<any>([]);
  const [generalSetting, setGeneralSetting] = useState<any>("");
  const navigate = useNavigate();
  const { invoiceId } = useParams();

  const fn = {
    getInvoices: async (params: any) => {
      showLoader();
      const res = await getInvoice(params);
      if (res) {
        setInvoice(res.data.invoices);
        hideLoader();
      }
    },
    getInvoiceById: async () => {
      showLoader2();
      const res = await getInvoiceById(invoiceId);
      if (res) {
        setSingleInvoice(res.data.invoice);
        fn.getGeneralSetting({ company: companyId });
        hideLoader2();
      }
    },
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getGeneralSetting: async (params?: any) => {
      // showLoader2();
      const res = await getGeneralSetting(params);
      if (res) {
        setGeneralSetting(res.data.generalSettings[0]);
        // hideLoader2();
      }
    },

    calculate: (item: any) => {
      let obj = {
        discount: 0,
        cgst: 0,
        sgst: 0,
        igst: 0,
        taxOutsideState: 0,
        taxinsideState: 0,
      };

      let subTotal = item?.qty * item?.unitQtyPrice;
      obj.discount = subTotal * (item?.discount / 100);

      let totalAfterDiscount = subTotal - obj.discount;

      let totalTax = totalAfterDiscount * (item?.tax / 100);

      if (singleInvoice?.placeOfSupply === companyObj?.state) {
        obj.cgst = roundToTwo(totalTax / 2);
        obj.sgst = roundToTwo(totalTax / 2);
        obj.igst = 0;
        obj.taxinsideState = item?.tax / 2;
      } else {
        obj.cgst = 0;
        obj.sgst = 0;
        obj.igst = roundToTwo(totalTax);
        obj.taxOutsideState = item?.tax;
      }

      return obj;
    },
  };

  useEffect(() => {
    fn.getInvoices({
      f: "invoiceNumber,calculation,client",
      company: companyId,
      isCancelled: false,
    });
    setPageTitle("View Invoice");
  }, []);

  useEffect(() => {
    fn.getInvoiceById();
  }, [invoiceId]);

  const generatePDF = () => {
    // const report = new JsPDF({
    //   orientation: "portrait",
    //   unit: "pt",
    //   format: "a4",
    // });

    // var width = report.internal.pageSize.getWidth();
    // var height = report.internal.pageSize.getHeight();

    // report.addFont('ArialMS', 'Arial', 'normal');

    // report.setFont('Arial');

    // report.setFont("PTSans");

    // report.html(viewDoc, {
    //   callback: function (doc) {
    //     doc.save();
    //   },
    //   width: 594,
    //   height: height,
    //   windowWidth: 630,
    // });

    const divToDisplay = document.getElementById(
      "content"
    ) as HTMLElement | null;

    html2canvas(divToDisplay!).then(function (canvas) {
      const divImage = canvas.toDataURL("image/png");
      const pdf = new jsPDF("portrait", "em", "a4");
      const componentWidth = pdf.internal.pageSize.getWidth();
      const componentHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(divImage, "PNG", 0, 0, componentWidth, componentHeight);
      pdf.save(
        `invoice_${singleInvoice?.invoiceNumber}_${moment(
          singleInvoice?.invoiceDate
        ).format("YYYY-MM-DD")}.pdf`
      );
    });

    // doc.html(reportTemplateRef.current, {
    //   async callback(doc) {
    //     await doc.save("document");
    //   },
    // });
  };

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12 col-lg-3">
            <InvoiceSidebar
              invoice={invoice}
              loading={loading}
              invoiceId={invoiceId}
            />
          </div>

          <div className="col-12 col-lg-9 pt-3 pt-lg-0">
            <div>
              <div className="viewlead_content_header d-flex justify-content-end view_doc_header">
                <div className="viewlead_content_header_section d-flex gap-3">
                  {singleInvoice?.status < 2 && (
                    <div>
                      <button
                        className="btn font12x500 buttonOne shadow-none"
                        onClick={() =>
                          fn.openModal(
                            {
                              accountId: singleInvoice?.client?._id,
                              accountName: singleInvoice?.client?.name,
                              docNumber: singleInvoice?.invoiceNumber,
                              _id: singleInvoice?._id,
                            },
                            "received_payment"
                          )
                        }
                      >
                        <BiRupee
                          className="me-1"
                          style={{
                            fontSize: 16,
                          }}
                        />
                        Add Payment
                      </button>
                    </div>
                  )}

                  <div>
                    <button
                      className="btn font12x500 buttonOne shadow-none"
                      onClick={() => navigate(`/invoice/form/${invoiceId}`)}
                    >
                      <MdOutlineEdit className="me-1" />
                      Edit
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn font12x500 buttonOne shadow-none"
                      onClick={() => generatePDF()}
                    >
                      <BsFileEarmarkPdf className="me-1" />
                      PDF
                    </button>
                  </div>
                  <div>
                    <div className="btn font12x500 buttonOne shadow-none print">
                      <PrintComponents
                        trigger={
                          <button>
                            <FaPrint className="me-1" /> Print
                          </button>
                        }
                      >
                        <GenerateInvoiceDoc
                          apiData={singleInvoice}
                          generalSetting={generalSetting}
                          width="100%"
                          boxShadow="unset"
                        />
                      </PrintComponents>
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn font12x500 buttonOne shadow-none"
                      // onClick={() => redirect(url)}
                    >
                      <MdOutlineEmail
                        className="me-1"
                        style={{
                          fontSize: 16,
                        }}
                      />
                      Email
                    </button>
                  </div>
                </div>
              </div>

              {loading2 ? (
                <Skeleton height="300px" borderRadius="8px" />
              ) : (
                <div className="menu_content_wrapper p-3">
                  <GenerateInvoiceDoc
                    apiData={singleInvoice}
                    generalSetting={generalSetting}
                    width="635px"
                    boxShadow="rgb(99 99 99 / 20%) 0px 2px 8px 0px"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* form modal */}
      {commonData?.modal && commonData?.check === "received_payment" && (
        <ReceivedForm
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getInvoiceById}
        />
      )}
    </>
  );
};

export default ViewInvoicePage;
