import React, { useEffect } from "react";
import { MobileOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import { useFormik } from "formik";
import { otpLoginSchema } from "../../utils/validationSchema";
import { otpLoginMethod } from "../../lib/api functions/common";
import InputError from "../../shared components/InputError/InputError";
import SpinLoader from "../../shared components/Loader/SpinLoader";
import PhoneCode from "../../shared components/Dropdown/PhoneCode";

export type otpLogin = {
  mobile: string;
  phoneCode?: string;
};

const OtpLogin = () => {
  const [loading, showLoader] = useLoading();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const mobile = sessionStorage.getItem("mobile");
  useEffect(() => {
    if (token && role) {
      navigate("/dashboard");
    }
  }, []);

  const initialValues: otpLogin = {
    mobile: "",
    phoneCode: "+91",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: otpLoginSchema,
    onSubmit: async (values) => {
      showLoader();
      const res = await otpLoginMethod(values);
      if (res) {
        navigate("/verify-otp", { state: { ...res.data } });
        window.sessionStorage.setItem("mobile", formik.values.mobile);
      }
    },
  });

  useEffect(() => {
    //persist mobile state when click on back button from verify otp page
    mobile
      ? formik.setFieldValue("mobile", mobile)
      : formik.setFieldValue("mobile", "");
  }, []);

  return (
    <>
      <div className="container-fluid m-0 loginSection">
        <div className="row">
          <div className="col-12 col-sm-7 authLeft d-flex justify-content-center align-items-center">
            <div>
              <h1 className="mb-0 textColor3">Welcome to our CRM</h1>
              <h1 className="m-0 text-center textColor3">Log in using OTP</h1>

              <div className="mt-5">
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-4">
                    <label
                      htmlFor="mobile"
                      className="font14x500 textColor1 form-label mb-1"
                    >
                      Mobile Number*
                    </label>
                    <div className="input-group">
                      <PhoneCode
                        formik={formik}
                        fieldName="phoneCode"
                        fieldName2="mobile"
                      />
                      <input
                        type="text"
                        className="form-control shadow-none font14x500 border-0"
                        id="mobile"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      <div className="input-group-text border-0">
                        <MobileOutlined className="textColor1" />
                      </div>
                    </div>
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <InputError error={formik.errors.mobile} />
                    ) : null}
                    {formik.touched.phoneCode && formik.errors.phoneCode ? (
                      <InputError error={formik.errors.phoneCode} />
                    ) : null}
                  </div>

                  <button
                    type="submit"
                    className="btn font14x500 shadow-none fullWidthButton py-2"
                  >
                    {loading ? (
                      <SpinLoader height="22" width="22" color="#fff" />
                    ) : (
                      "Get OTP"
                    )}
                  </button>
                </form>

                <div className="font14x500 text-center pt-2">
                  <p className="font14x500 textColor1 mt-1">
                    You'll receive an OTP via SMS
                  </p>

                  <Link to="/" className="anchorTag">
                    Sign in using Email
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-5 d-flex justify-content-center align-items-center">
            <img
              src="/Assets/Svg/login.svg"
              alt="loginDefault"
              draggable={false}
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      {/* desktop alert */}
      <div className="desktopAlert">
        <img
          src="/Assets/Svg/desktopAlert.svg"
          className="img-fluid"
          alt="alert"
          draggable={false}
        />
        <p className="mb-0 my-3 text-center">
          Please use <b>web view</b> (Desktop/Laptop) to access the portal.
        </p>
      </div>
    </>
  );
};

export default OtpLogin;
