import React, { useContext } from "react";
import { CompanyContext } from "../../../../pages/main/setting/sub pages/profile";
import DropdownOne from "../../../../shared components/Dropdown/DropdownOne";
import InputError from "../../../../shared components/InputError/InputError";
import InputField from "../../../../shared components/TextInput";
import SwitchToggle from "../../../../shared components/ToggleButton/SwitchToggle";
import { taxTypesMap } from "../../../../utils/constants";

const TaxDetails = () => {
  const { formik, updatedValues, setUpdatedValues } =
    useContext(CompanyContext);

  return (
    <>
      <div className="d-flex justify-content-between py-3">
        <p className="m-0 font14x600 textColor3">Taxation</p>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          Taxation Type
        </label>

        <div className="setting_input_wrapper">
          <DropdownOne
            placeholder="Select Taxation Type"
            name="taxationType"
            data={taxTypesMap}
            state={formik.values.taxationType}
            setState={(e?: string) => {
              formik.setFieldValue("taxationType", e);
              setUpdatedValues({
                ...updatedValues,
                taxationType: e,
                isObjChange: true,
              });
            }}
            onBlur={() => formik.setFieldTouched("taxationType", true)}
          />
          {formik.touched.taxationType && formik.errors.taxationType ? (
            <InputError error={formik.errors.taxationType} />
          ) : null}
        </div>
      </div>

      {!(formik.values.taxationType === "Non-GST (international)") && (
        <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
          <label
            htmlFor="name"
            className="form-label font12x400 textColor1 mb-1"
          >
            GSTIN
          </label>

          <div className="setting_input_wrapper">
            <InputField
              id="gstin"
              type="text"
              state={formik.values.gstin}
              setState={(e) => {
                formik.setFieldValue("gstin", e.target.value);
                setUpdatedValues({
                  ...updatedValues,
                  gstin: e.target.value,
                  isObjChange: true,
                });
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.gstin && formik.errors.gstin ? (
              <InputError error={formik.errors.gstin} />
            ) : null}
          </div>
        </div>
      )}

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          Service Tax No.
        </label>

        <div className="setting_input_wrapper">
          <InputField
            id="serviceTaxNumber"
            type="text"
            state={formik.values.serviceTaxNumber}
            setState={(e) => {
              formik.setFieldValue("serviceTaxNumber", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                serviceTaxNumber: e.target.value,
                isObjChange: true,
              });
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.serviceTaxNumber && formik.errors.serviceTaxNumber ? (
            <InputError error={formik.errors.serviceTaxNumber} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          Tax Inclusive Rates
        </label>

        <div className="setting_input_wrapper">
          <SwitchToggle
            state={formik.values.taxInclusiveRates}
            setState={(e: boolean) => {
              formik.setFieldValue("taxInclusiveRates", e);
              setUpdatedValues({
                ...updatedValues,
                taxInclusiveRates: e,
                isObjChange: true,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TaxDetails;
