type PropType = {
  state?: any;
};

const ViewMultiFileInput: React.FC<PropType> = (props) => {
  const { state } = props;

  return (
    <>
      <div className="mt-2 d-flex flex-wrap gap-2">
        {state?.length > 0 &&
          state?.map((item: any, i: number) => {
            return (
              <div className="selected_doc_wrapper" key={i}>
                <a
                  className="text-decoration-none"
                  href={`${process.env.REACT_APP_IMAGE_URL}${item?.url}`}
                  target="_blank"
                >
                  <p className="mb-0 lh-lg font12x500 textColor2">
                    {item?.name}
                  </p>
                </a>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ViewMultiFileInput;
