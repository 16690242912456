import { useFormik } from "formik";
import moment from "moment";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ViewLeadContext } from "../../..";
import UpdationDetail from "../../../../../../../components/Data Update Detail";
import AddProposalList from "../../../../../../../components/View Lead Components/MenuData/Proposal Components/Add Update Components/AddProposalList";
import Calculation from "../../../../../../../components/View Lead Components/MenuData/Proposal Components/Add Update Components/Calculation";
import Form from "../../../../../../../components/View Lead Components/MenuData/Proposal Components/Add Update Components/Form";
import useLoading from "../../../../../../../hooks/useLoading";
import { getDocumentNumberById } from "../../../../../../../lib/api functions/common";
import {
  getItem,
  getItemById,
} from "../../../../../../../lib/api functions/item";
import {
  createProposal,
  getProposalById,
  updateProposal,
} from "../../../../../../../lib/api functions/lead/proposal";
import { getTax } from "../../../../../../../lib/api functions/setting/tax";
import { showToast } from "../../../../../../../lib/toast/ShowToast";
import SpinLoader from "../../../../../../../shared components/Loader/SpinLoader";
import { MainContext } from "../../../../../../../utils/private_routes/PrivateRoutes";
import { proposalSchema } from "../../../../../../../utils/validationSchema";
import { ProfileContext } from "../../profile";
import { ItemsType, ProposalType } from "./type";

export const ProposalContext = createContext<any>(null);

const AddUpdateProposal = () => {
  const { companyId } = useContext(MainContext);
  const { id, lead, getActivity } = useContext(ViewLeadContext);
  const { proposalId } = useParams();
  const companyObj = JSON.parse(window.localStorage.getItem("company") || "");
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const [itemsList, setItemsList] = useState<any>([]);
  const [taxes, setTaxes] = useState<any>([]);
  const [calculation, setCalculation] = useState<any>({
    subTotal: 0,
    cgst: 0,
    sgst: 0,
    igst: 0,
    discount: 0,
    total: 0,
  });
  const [proposal, setProposal] = useState<ProposalType>({
    proposalNumber: "",
    issueDate: "",
    placeOfSupply: lead?.client?.completeAddress?.state ?? "",
    companyState: companyObj?.state,
    validTill: "",
    items: [],
  });
  const [singleItem, setSingleItem] = useState<ItemsType>({
    id: "",
    name: "",
    description: "",
    unit: "",
    hsnOrsac: "",
    qty: 1,
    type: 0,
    unitQtyPrice: 0,
    discount: 0,
    cgst: 0,
    sgst: 0,
    igst: 0,
    tax: 0,
    total: 0,
    isEditable: false,
  });
  const [updationDetails, setUpdationDetails] = useState({
    lastUpdate: "",
    updatedBy: undefined,
  });

  const fn = {
    getDocNumber: async (params?: any) => {
      const res = await getDocumentNumberById(companyId, params);
      if (res) {
        formik.setFieldValue(
          "proposalNumber",
          res.data.documentNumber.proposal.number.toString()
        );
      }
    },
    getItems: async (params?: any) => {
      const res = await getItem(params);
      if (res) {
        setItemsList(res.data.items);
      }
    },
    getItemById: async () => {
      // showLoader2();
      const res = await getItemById(singleItem?.id);
      if (res) {
        setSingleItem({
          ...singleItem,
          description: res.data.item?.description,
          hsnOrsac: res.data.item?.hsn
            ? res.data.item?.hsn
            : res.data.item?.sac,
          unitQtyPrice: res.data.item?.unitAmount,
          unit: res.data.item?.unit,
          tax: res.data.item?.tax,
          type: res.data.item?.type,
        });
        // hideLoader2();
      }
    },
    getGstTaxes: async (params?: any) => {
      const res = await getTax(params);
      if (res) {
        setTaxes(res.data.taxes);
      }
    },
    getProposalById: async () => {
      showLoader();
      const res = await getProposalById(proposalId);
      if (res) {
        formik.setFieldValue(
          "proposalNumber",
          res.data.proposal.proposalNumber
        );
        formik.setFieldValue("placeOfSupply", res.data.proposal.placeOfSupply);
        formik.setFieldValue(
          "issueDate",
          moment(res.data.proposal.issueDate).format("YYYY-MM-DD")
        );
        formik.setFieldValue(
          "validTill",
          moment(res.data.proposal.validTill).format("YYYY-MM-DD")
        );
        formik.setFieldValue("items", res.data.proposal.items);
        setCalculation({
          subTotal: res.data.proposal?.calculation.subTotal,
          cgst: res.data.proposal?.calculation?.cgst,
          sgst: res.data.proposal?.calculation?.sgst,
          igst: res.data.proposal?.calculation?.igst,
          discount: res.data.proposal?.calculation.discount,
          total: res.data.proposal?.calculation.total,
        });
        setUpdationDetails({
          lastUpdate: res.data.proposal.updatedAt,
          updatedBy: res.data.proposal.updatedBy,
        });
        hideLoader();
      }
    },
  };

  const formik = useFormik<ProposalType>({
    initialValues: proposal,
    validationSchema: proposalSchema,
    onSubmit: async (values) => {
      let finalItem: any = [];
      values?.items?.forEach((item) => {
        finalItem.push({
          name: item.name,
          hsnOrsac: item.hsnOrsac,
          description: item.description,
          unit: item.unit ?? "",
          qty: item.qty,
          type: item.type,
          unitQtyPrice: item.unitQtyPrice,
          discount: item.discount,
          tax: item.tax,
          total: item.total,
          id: item.id,
        });
      });

      showLoader();
      let finalObj = {};
      if (proposalId) {
        finalObj = {
          issueDate: values.issueDate,
          validTill: values.validTill,
          placeOfSupply: values.placeOfSupply,
          items: finalItem,
          calculation: calculation,
        };

        const res = await updateProposal(finalObj, proposalId, hideLoader);
        if (res) {
          showToast(`Proposal updated`, "success");
          getActivity({ lead: id });
          hideLoader();
        }
      } else {
        finalObj = {
          lead: id,
          proposalNumber: values.proposalNumber,
          issueDate: values.issueDate,
          validTill: values.validTill,
          placeOfSupply: values.placeOfSupply,
          items: finalItem,
          calculation: calculation,
        };
        const res = await createProposal(finalObj, hideLoader);
        if (res.response && res.response.status === 502) {
          formik.setFieldError(
            "proposalNumber",
            "Proposal number already taken"
          );
        } else {
          showToast(`Proposal created`, "success");
          getActivity({ lead: id });
          navigate(`/lead/view/${id}/proposal`);
          hideLoader();
        }
      }
    },
  });

  useEffect(() => {
    fn.getItems({ f: "name", company: companyId, isDeleted: false });
    if (!proposalId) {
      fn.getDocNumber({ f: "proposal" });
    }
    fn.getGstTaxes({
      f: "percent",
      type: 1,
      company: companyId,
    });
  }, []);
  useEffect(() => {
    if (singleItem?.id) {
      fn.getItemById();
    }
  }, [singleItem?.id]);
  useEffect(() => {
    if (proposalId) {
      fn.getProposalById();
    }
  }, [proposalId]);

  return (
    <>
      <div className="col-12 col-md-8 col-lg-9 mt-3 mt-md-0">
        <div
          className="single_page"
          style={{
            overflow: "unset",
          }}
        >
          <div className="back_section px-3">
            <div
              className="cursor_pointer d-flex align-items-center"
              onClick={() => navigate(`/lead/view/${id}/proposal`)}
            >
              <img src="/Assets/Svg/backBtn.svg" alt="close" />
              <h3 className="mb-0 font16x600 textColor3 d-inline-block ms-2">
                Back to list
              </h3>
            </div>
          </div>
          <div className="form_section">
            <form onSubmit={formik.handleSubmit}>
              <ProfileContext.Provider
                value={{
                  proposalId,
                  proposal,
                  setProposal,
                  formik,
                  itemsList,
                  singleItem,
                  setSingleItem,
                  loading,
                  setCalculation,
                  calculation,
                  taxes,
                  getDocNumber: fn.getDocNumber,
                }}
              >
                <Form />

                <AddProposalList />

                <Calculation />
              </ProfileContext.Provider>

              <div className="d-flex justify-content-end mt-5 px-3">
                {/* <button className="btn font12x500 shadow-none modalBtnOutline">
                  Cancel
                </button> */}
                <button
                  type="submit"
                  className="btn font12x500 shadow-none custom_btn ms-3"
                >
                  {proposalId ? (
                    loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )
                  ) : loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>

            {proposalId && (
              <UpdationDetail
                lastUpdate={updationDetails.lastUpdate}
                updatedBy={updationDetails.updatedBy}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUpdateProposal;
