import React, { useContext } from "react";
import { ProfileContext } from "../../../../../pages/main/lead/view lead/sub pages/profile";

const Calculation = () => {
  const { calculation } = useContext(ProfileContext);

  return (
    <>
      <div className="d-sm-flex justify-content-end mt-4 px-3">
        <div className="calculation_wrapper">
          <div className="calculation_field d-flex justify-content-between mb-3">
            <p className="mb-0 font12x400 textColor3">Subtotal:</p>
            <p className="mb-0 font12x600 textColor3">
              {`₹ ${calculation.subTotal?.toLocaleString()}`}
            </p>
          </div>
          <div className="calculation_field d-flex justify-content-between mb-3">
            <p className="mb-0 font12x400 textColor3">CGST:</p>
            <p className="mb-0 font12x600 textColor3">
              {`₹ ${calculation.cgst?.toLocaleString()}`}
            </p>
          </div>
          <div className="calculation_field d-flex justify-content-between mb-3">
            <p className="mb-0 font12x400 textColor3">SGST:</p>
            <p className="mb-0 font12x600 textColor3">
              {`₹ ${calculation.sgst?.toLocaleString()}`}
            </p>
          </div>
          <div className="calculation_field d-flex justify-content-between mb-3">
            <p className="mb-0 font12x400 textColor3">IGST:</p>
            <p className="mb-0 font12x600 textColor3">
              {`₹ ${calculation.igst?.toLocaleString()}`}
            </p>
          </div>
          <div className="calculation_field d-flex justify-content-between mb-3">
            <p className="mb-0 font12x400 textColor3">Discount:</p>
            <p className="mb-0 font12x600 textColor3">{`- ₹ ${calculation.discount?.toLocaleString()}`}</p>
          </div>
          <div className="calculation_field d-flex justify-content-between">
            <p className="mb-0 font12x400 textColor6">Total:</p>
            <p className="mb-0 font12x600 textColor6">
              {`₹ ${calculation.total?.toLocaleString()}`}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calculation;
