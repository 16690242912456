import React, { useState } from "react";
import { commonType } from "../../pages/main/lead";
import { CgClose } from "react-icons/cg";
import UpdationDetail from "../Data Update Detail";
import InputField from "../../shared components/TextInput";
import TextareaInput from "../../shared components/TextareaInput";
import DropdownOne from "../../shared components/Dropdown/DropdownOne";
import { taskPriorityMap } from "../../utils/constants";
import { useFormik } from "formik";
import { reminderSchema } from "../../utils/validationSchema";
import InputError from "../../shared components/InputError/InputError";
import { showToast } from "../../lib/toast/ShowToast";
import useLoading from "../../hooks/useLoading";
import InputDateForm from "../../shared components/DateInput/InputDateForm";
import moment from "moment";
import { ReminderType } from "../../pages/main/reminder/type";
import {
  createReminder,
  updateReminder,
} from "../../lib/api functions/reminder";
import SpinLoader from "../../shared components/Loader/SpinLoader";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall: (val: any) => void;
  params: any;
};

const Form: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params } = props;
  const [loading, showLoader, hideLoader] = useLoading();
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
  };

  const initialValues: ReminderType = {
    title: state?.data?.title ?? "",
    date: state?.data?.date ?? "",
    time: state?.data?.time ?? "",
    taskDescription: state?.data?.taskDescription ?? "",
    priority: state?.data?.priority ?? 0,
    priorityName: taskPriorityMap.get(state?.data?.priority),
  };

  const formik = useFormik<ReminderType>({
    initialValues,
    validationSchema: reminderSchema,
    onSubmit: async (values) => {
      let finalObj = {};

      if (state?.data) {
        delete updatedValues.isObjChange;
        finalObj = updatedValues;
      } else {
        finalObj = {
          title: values.title,
          date: moment(values.date).format("YYYY-MM-DD"),
          time: values.time,
          taskDescription: values.taskDescription,
          priority: values.priority,
        };
      }

      showLoader();
      if (state.data) {
        const res = await updateReminder(finalObj, state.data._id, hideLoader);
        if (res) {
          showToast(`Reminder updated`, "success");
          fn.setPositionToBody();
          apiCall(params);
          hideLoader();
        }
      } else {
        const res = await createReminder(finalObj, hideLoader);
        if (res) {
          showToast(`Reminder created`, "success");
          fn.setPositionToBody();
          apiCall(params);
          hideLoader();
        }
      }
    },
  });

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">
              {state?.data ? "Update reminder" : "Add new reminder"}
            </h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <InputField
                    id="title"
                    label="Title*"
                    type="text"
                    state={formik.values.title}
                    setState={(e) => {
                      formik.setFieldValue("title", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        title: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <InputError error={formik.errors.title} />
                  ) : null}
                </div>

                <div className="container-fluid p-0">
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <InputDateForm
                          id="date"
                          label="Select Date*"
                          type="date"
                          state={moment(formik.values.date).format(
                            "YYYY-MM-DD"
                          )}
                          setState={(e) => {
                            formik.setFieldValue("date", e.target.value);
                            setUpdatedValues({
                              ...updatedValues,
                              date: e.target.value,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                          isDisabled={
                            moment().isSameOrAfter(state?.data?.date, "date") &&
                            moment().isAfter(state?.data?.date, "date")
                          }
                          min={moment().format("YYYY-MM-DD")}
                        />
                        {formik.touched.date && formik.errors.date ? (
                          <InputError error={formik.errors.date} />
                        ) : null}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <InputField
                          id="time"
                          label="Select Time*"
                          type="time"
                          name="time"
                          state={formik.values.time}
                          setState={(e) => {
                            formik.setFieldValue("time", e.target.value);
                            setUpdatedValues({
                              ...updatedValues,
                              time: e.target.value,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.time && formik.errors.time ? (
                          <InputError error={formik.errors.time} />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <DropdownOne
                    label="Priority*"
                    placeholder="Select Priority"
                    name="priorityName"
                    data={taskPriorityMap}
                    state={formik.values.priorityName}
                    setState={(e?: string, x?: string | number) => {
                      formik.setFieldValue("priorityName", e);
                      formik.setFieldValue("priority", x);
                      setUpdatedValues({
                        ...updatedValues,
                        priority: x,
                        isObjChange: true,
                      });
                    }}
                    onBlur={() => formik.setFieldTouched("priorityName", true)}
                  />
                  {formik.touched.priorityName && formik.errors.priorityName ? (
                    <InputError error={formik.errors.priorityName} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <TextareaInput
                    id="taskDescription"
                    label="Description*"
                    state={formik.values.taskDescription}
                    setState={(e) => {
                      formik.setFieldValue("taskDescription", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        taskDescription: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.taskDescription &&
                  formik.errors.taskDescription ? (
                    <InputError error={formik.errors.taskDescription} />
                  ) : null}
                </div>
              </div>
              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>
                {state.data ? (
                  <button
                    type="submit"
                    className="btn font12x500 shadow-none custom_btn ms-3"
                    disabled={updatedValues?.isObjChange ? false : true}
                  >
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn font12x500 shadow-none custom_btn ms-3"
                  >
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Save"
                    )}
                  </button>
                )}
              </div>
            </form>

            {state.data && (
              <UpdationDetail
                lastUpdate={state?.data?.updatedAt}
                updatedBy={state?.data?.updatedBy}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
