import moment from "moment";
import React, { useEffect } from "react";

type propType = {
  setShowFirebaseNoti: (val: boolean) => void;
  singleNoti: any;
};

const NotificationPopup: React.FC<propType> = (props) => {
  const { setShowFirebaseNoti, singleNoti } = props;

  const fn = {
    redirection: (link: string) => {
      window.open(link);
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setShowFirebaseNoti(false);
    }, 5000);
  }, []);

  return (
    <>
      <div
        className="noti_popup_wrapper d-flex gap-2 px-3 py-2 cursor_pointer"
        onClick={() => fn.redirection(singleNoti?.link)}
      >
        <div>
          <img src="/Assets/userDemo.png" alt="user" width={28} height={28} />
        </div>
        <div>
          <p className="mb-1 font12x400 textColor3">{singleNoti?.body}</p>
          <p className="mb-0 font10x400 textColor1">
            {moment(singleNoti?.createdAt).fromNow()}
          </p>
        </div>
      </div>
    </>
  );
};

export default NotificationPopup;
