import React from "react";
import { TailSpin } from "react-loader-spinner";

type PropType = {
  height: string;
  width: string;
  color: string;
};

const SpinLoader: React.FC<PropType> = (props) => {
  const { height, width, color } = props;
  return (
    <>
      <div className="d-flex justify-content-center">
        <TailSpin
          height={height}
          width={width}
          color={color}
          ariaLabel="tail-spin-loading"
          radius="3"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    </>
  );
};

export default SpinLoader;
