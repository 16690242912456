import React, { useContext } from "react";
import Unauthorized from "../../pages/common/Unauthorized";
import { MainContext } from "./PrivateRoutes";

type PropType = {
  children: any;
  pageName: string;
};

const ProtectedRoutes: React.FC<PropType> = (props) => {
  const { children, pageName } = props;
  const { roles } = useContext(MainContext);

  return (
    <>
      {roles?.length !== 0 && roles[0][pageName]?.access ? (
        children
      ) : (
        <Unauthorized />
      )}
    </>
  );
};

export default ProtectedRoutes;
