import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MainContext } from "../../utils/private_routes/PrivateRoutes";
import { IoIosArrowDown } from "react-icons/io";

type proptype = {
  path: string;
  icon: string;
  colorIcon: string;
  label: string;
  subMenu?: any;
};

const Menu: React.FC<proptype> = (props) => {
  const { path, icon, colorIcon, label, subMenu } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const { setPageTitle, sidebarToggle } = useContext(MainContext);
  const navigateToRoute = (route: string) => {
    navigate(route);
  };

  return (
    <>
      <div
        style={{
          marginBottom: 8,
        }}
      >
        <div className="menu_highlighter_wrapper">
          <div
            className={
              location.pathname.includes(path)
                ? "single_menu_active mb-1"
                : "single_menu"
            }
            onClick={() => {
              navigateToRoute(path);
              setPageTitle(label);
              sidebarToggle(false);
            }}
            style={{
              width: "100%",
            }}
          >
            <img
              src={`/Assets/Svg/${
                location.pathname.includes(path) ? colorIcon : icon
              }`}
              alt="menu_image"
            />

            <div className="menu w-100 d-flex justify-content-between align-items-center">
              <span
                className={
                  location.pathname.includes(path)
                    ? "font14x500 textColor2"
                    : "font14x500 textColor1"
                }
              >
                {label}
              </span>
              {subMenu && (
                <IoIosArrowDown
                  className={
                    location.pathname.includes(path)
                      ? "font14x500 textColor2"
                      : "font14x500 textColor1"
                  }
                />
              )}
            </div>
          </div>

          <div
            className="menu_highlight"
            style={{
              backgroundColor: location.pathname.includes(path)
                ? "var(--bg_color7)"
                : "#fff",
            }}
          ></div>
        </div>

        {subMenu &&
          location.pathname.includes(path) &&
          subMenu?.map((item: any, i: number) => {
            return (
              <div key={i} className="menu_highlighter_wrapper">
                <div
                  className={
                    location.pathname.includes(item?.path)
                      ? "single_menu_active"
                      : "single_menu"
                  }
                  onClick={() => {
                    navigateToRoute(item?.path);
                    setPageTitle(item?.label);
                    sidebarToggle(false);
                  }}
                  style={{
                    width: "100%",
                    marginBottom: 4,
                    padding: "5px 0.938rem",
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{
                      marginLeft: "30px",
                    }}
                  >
                    <img
                      src={`/Assets/Svg/${
                        location.pathname.includes(item?.path)
                          ? colorIcon
                          : icon
                      }`}
                      width={21}
                      height={21}
                      alt="menu_image"
                      style={{
                        marginTop: "2px",
                      }}
                    />

                    <div className="sub_menu">
                      <span
                        className={
                          location.pathname.includes(item?.path)
                            ? "font13x500 textColor2"
                            : "font13x500 textColor1"
                        }
                      >
                        {item?.label}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className="menu_highlight"
                  style={{
                    backgroundColor: location.pathname.includes(item?.path)
                      ? "var(--bg_color7)"
                      : "#fff",
                    height: 30,
                  }}
                ></div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Menu;
