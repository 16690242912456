import Api from "../../../config/api";
import {
  SEARCH_SETTING_DOCUMENT,
  SETTING_DOCUMENT_BY_ID,
  UPDATE_SETTING_DOCUMENT,
} from "../../../utils/constants";
import { showToast } from "../../toast/ShowToast";

export const getDocumentSetting = async (params?: any) => {
  try {
    const res = await Api(false).get(SEARCH_SETTING_DOCUMENT, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const getDocumentSettingById = async (
  id?: string | null,
  params?: any
) => {
  try {
    const res = await Api(false).get(`${SETTING_DOCUMENT_BY_ID}/${id}`, {
      params,
    });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const updateDocumentSetting = async (
  body: any,
  id: string,
  hideLoader: () => void
) => {
  try {
    const res = await Api(false).put(`${UPDATE_SETTING_DOCUMENT}/${id}`, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};
