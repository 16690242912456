import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";

type propType = {
  label?: string;
  placeholder: string;
  name: string;
  data?: any;
  isDisabled?: boolean;
  width?: string;
  state?: string | number;
  setState: (e?: string, x?: string | number) => void;
  labelField: string;
  onBlur?: any;
};

type MyOptionType = {
  id: number;
  label: string;
  value: string | number;
};

type IsMulti = false;

const DropdownTwo: React.FC<propType> = (props) => {
  const {
    label,
    placeholder,
    name,
    data,
    width,
    isDisabled,
    state,
    setState,
    labelField,
    onBlur,
  } = props;

  const [options, setOptions] = useState<MyOptionType[]>([]);
  const pushObj = () => {
    setOptions([]);
    data?.forEach((item: any, i: any) => {
      setOptions((current) => [
        ...current,
        {
          id: i,
          value: item._id,
          label: item[labelField],
        },
      ]);
    });
  };

  useEffect(() => {
    if (data) {
      pushObj();
    }
  }, [data]);

  const styles: StylesConfig<MyOptionType, IsMulti> = {
    container: (styles) => {
      return {
        ...styles,
        width: "100%",
        height: "30px",
        bottom: "3px",
      };
    },
    control: (styles) => {
      return {
        ...styles,
        boxShadow: "none",
        cursor: "pointer",
        border: "none",
        height: "30px",
        minHeight: "30px",
      };
    },
    placeholder: (styles) => {
      return {
        ...styles,
        fontSize: "12px",
        color: "var(--text5)",
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        fontSize: "12px",
        textTransform: "capitalize",
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        borderRadius: "8px",
        margin: 0,
        backgroundColor: "#fff",
        top: "45px",
        border: "1px solid var(--border_color4)",
        overflow: "hidden",
        zIndex: 11,
      };
    },
    noOptionsMessage: (styles) => {
      return {
        ...styles,
        fontSize: "12px",
      };
    },
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        color: isSelected ? "var(--text3)" : "var(--text1)",
        backgroundColor: isSelected ? "var(--bg_color5)" : "#fff",
        borderBottom: "1px solid var(--border_primary)",
        textAlign: "left",
        margin: 0,
        cursor: "pointer",
        fontSize: "12px",
        fontWeight: 600,
        padding: "8px 10px",
        textTransform: "capitalize",
        ":hover": {
          backgroundColor: "var(--bg_color5)",
        },
        ":last-child": {
          borderBottom: "none",
        },
      };
    },
  };

  return (
    <>
      {label && (
        <label htmlFor="html" className="form-label font12x400 textColor3 mb-1">
          {label}
        </label>
      )}

      <div
        className="input_wrapper d-flex align-items-center"
        style={{
          backgroundColor: isDisabled ? "#f2f2f2" : "#fff",
          cursor: isDisabled ? "no-drop" : "unset",
          width: width,
        }}
      >
        <Select
          options={options}
          placeholder={placeholder}
          styles={styles}
          name={name}
          maxMenuHeight={150}
          className="input_field"
          isDisabled={isDisabled}
          value={options.filter((option) => {
            return option.label === state;
          })}
          onChange={(e) => {
            setState(e?.label, e?.value);
          }}
          onBlur={onBlur}
          isClearable={true}
        />
      </div>
    </>
  );
};

export default DropdownTwo;
