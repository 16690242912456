import React from "react";

type proptype = {
  id: string;
  label?: string;
  type: string;
  placeholder?: string;
  name?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  state?: string | number;
  setState?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: any;
  width?: string;
};

const numberInputOnWheelPreventChange = (e: any) => {
  // Prevent the input value change
  e.target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();

  // Refocus immediately, on the next tick (after the current
  // function is done)
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

const InputField: React.FC<proptype> = (props) => {
  const {
    id,
    label,
    type,
    placeholder,
    name,
    isDisabled,
    state,
    setState,
    width,
    onBlur,
    isReadOnly,
  } = props;

  return (
    <>
      {label && (
        <label htmlFor={id} className="form-label font12x400 textColor3 mb-1">
          {label}
        </label>
      )}

      <div
        className="input_wrapper d-flex align-items-center"
        style={{
          backgroundColor: isDisabled ? "#f2f2f2" : "#fff",
          width: width,
        }}
      >
        <input
          type={type}
          className="form-control shadow-none input_field"
          id={id}
          placeholder={placeholder}
          autoComplete={`new-${type}`}
          name={name ?? ""}
          disabled={isDisabled}
          style={{
            cursor: isDisabled ? "no-drop" : "unset",
            backgroundColor: isDisabled ? "#f2f2f2" : "#fff",
            color: isDisabled ? "#9C9E9C" : "var(--text3)",
          }}
          value={state}
          onChange={setState}
          onBlur={onBlur}
          readOnly={isReadOnly}
          onWheel={
            type === "number"
              ? (numberInputOnWheelPreventChange as any)
              : undefined
          }
        />
      </div>
    </>
  );
};

export default InputField;
