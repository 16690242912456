import React, { useEffect } from "react";
import { commonType } from "../../../pages/main/lead";
import { CgClose } from "react-icons/cg";
import UpdationDetail from "../../Data Update Detail";
import InputField from "../../../shared components/TextInput";
import { useFormik } from "formik";
import { attendanceSchema } from "../../../utils/validationSchema";
import InputError from "../../../shared components/InputError/InputError";
import { showToast } from "../../../lib/toast/ShowToast";
import useLoading from "../../../hooks/useLoading";
import {
  createAttendance,
  updateAttendance,
} from "../../../lib/api functions/attendance/attendance";
import SpinLoader from "../../../shared components/Loader/SpinLoader";
import { AttendanceType } from "../../../pages/main/attendance/type";
import Skeleton from "react-loading-skeleton";
import { getUserById } from "../../../lib/api functions/user";
import moment from "moment";
import Incentives from "./Incentives";
import Deductions from "./Deductions";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall: (val: any) => void;
  params: any;
};

const Form: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params } = props;
  const [loading, showLoader, hideLoader] = useLoading();
  const [userLoading, showLoader2, hideLoader2] = useLoading();

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
    getUserById: async () => {
      const params = {
        type: "p",
        f: "name,salary,slipLabels",
      };
      showLoader2();
      const res = await getUserById(state?.data?.attendance.user?._id, params);
      if (res) {
        formik.setFieldValue("name", res.data.user.name);
        formik.setFieldValue("userSalary", res.data.user.salary);
        !state?.data?.attendance?.paidSalary
          ? fn.settingDeduction(res.data.user.slipLabels, res.data.user.salary)
          : fn.settingDeduction(
              state?.data?.attendance?.deductions,
              res.data.user.salary
            );
        hideLoader2();
      }
    },
    settingDeduction: (deductions: any, salary: number) => {
      // setting deductions into state
      const tempDeductions = [...deductions];
      const filterDeductions = tempDeductions?.filter((item: any) => {
        return item?.type === "D";
      });
      formik.setFieldValue("deductions", filterDeductions);

      //calculate total deductions
      let totalArrOfValue: number[] = [];
      tempDeductions?.map((item) => {
        if (item?.type === "D") {
          if (item?.valueType === "P") {
            totalArrOfValue.push((item?.value / 100) * salary);
          } else {
            totalArrOfValue.push(item?.value);
          }
        }
      });

      const totalDeductions =
        totalArrOfValue?.length > 0
          ? totalArrOfValue
              ?.map((item) => item)
              .reduce((total, num) => {
                return total + num;
              })
          : 0;
      formik.setFieldValue("totalDeductions", totalDeductions);
    },
  };

  const initialValues: AttendanceType = {
    attendanceUser: state?.data?.attendance.user?._id ?? "",
    customUserId: state?.data?.attendance.userId ?? "",
    name: state?.data?.attendance.name ?? "",
    daysPresent: state?.data?.attendance.daysPresent ?? 0,
    paidSalary: state?.data?.attendance.paidSalary ?? 0,
    previousPaidSalary: state?.data?.attendance.paidSalary ?? 0,
    userSalary: 0,
    computedSalary: state?.data?.attendance.computedSalary
      ? Math.ceil(state?.data?.attendance.computedSalary)
      : 0,
    monthName: state?.data?.attendance
      ? moment().month(state?.data?.attendance.month).format("MMMM")
      : moment().format("MMMM"),
    month: state?.data?.attendance.month ?? moment().month(),
    year: state?.data?.attendance.year ?? moment().year(),
    incentives: state?.data?.attendance.incentives ?? [],
    totalDeductions: state?.data?.attendance.totalDeductions ?? 0,
    totalIncentives: state?.data?.attendance.totalIncentives ?? 0,
    deductions: state?.data?.attendance.deductions ?? [],
    netPay: 0,
    totalPaidSalary: 0,
    isAnythingUpdated: false,
  };

  const formik = useFormik<AttendanceType>({
    initialValues,
    validationSchema: attendanceSchema,
    onSubmit: async (values) => {
      let finalObj = {};
      if (state?.data) {
        // delete updatedValues.isObjChange;
        // finalObj = updatedValues;
        let temp: any = [];
        values?.incentives?.forEach((item) => {
          temp.push({
            label: item?.label,
            value: item?.value,
          });
        });
        let temp2: any = [];
        values?.deductions?.forEach((item) => {
          temp2.push({
            label: item?.label,
            type: item?.type,
            value: item?.value,
            valueType: item?.valueType,
          });
        });
        finalObj = {
          incentives: temp,
          totalIncentives: values.totalIncentives,
          deductions: temp2,
          totalDeductions: values.totalDeductions,
          paidSalary: values.paidSalary,
          netPay: values.netPay,
        };
      } else {
        finalObj = {
          attendanceUser: values.attendanceUser,
          daysPresent: values.daysPresent,
          paidSalary: values.paidSalary,
          incentives: values.incentives,
          month: values.month,
          year: values.year,
        };
      }

      showLoader();
      if (state.data) {
        const res = await updateAttendance(
          finalObj,
          state.data.attendance._id,
          hideLoader
        );
        if (res) {
          showToast(`Attendance updated`, "success");
          fn.setPositionToBody();
          apiCall(params);
          hideLoader();
        }
      } else {
        const res = await createAttendance(finalObj, hideLoader);
        if (res) {
          showToast(`Attendance created`, "success");
          fn.setPositionToBody();
          apiCall(params);
          hideLoader();
        }
      }
    },
  });

  useEffect(() => {
    if (formik.values.attendanceUser) {
      fn.getUserById();
    }
  }, [formik.values.attendanceUser]);

  useEffect(() => {
    // calculate net pay
    const netPay = formik.values.paidSalary - formik.values.totalDeductions;

    if (state?.data?.attendance.paidSalary || formik.values.paidSalary > 0) {
      formik.setFieldValue("netPay", netPay);
    } else {
      formik.setFieldValue("netPay", 0);
    }
  }, [formik.values.paidSalary, formik.values.totalDeductions]);

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <div>
              <h1 className="font16x600 mb-0">
                {state.data ? "Edit Details" : "Add New"}
              </h1>
              <p className="mb-0 font12x400">{state?.data?.attendance?.name}</p>
              <p className="mb-0 font12x400">
                {state?.data?.attendance?.user?.employeeId}
              </p>
            </div>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <InputField
                    id="userId"
                    label="User Id*"
                    type="text"
                    state={formik.values.customUserId}
                    isDisabled={true}
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label font12x400 textColor3 mb-1">
                    Name
                  </label>
                  <p className="mb-0 font12x600 textColor3">
                    {userLoading ? (
                      <Skeleton width="80px" borderRadius={4} />
                    ) : (
                      <>{formik.values.name ? formik.values.name : "--"}</>
                    )}
                  </p>
                </div>

                <div className="mb-3">
                  <InputField
                    id="month"
                    label="Month*"
                    type="text"
                    state={formik.values.monthName}
                    isDisabled={true}
                  />
                </div>

                <div className="mb-3">
                  <InputField
                    id="year"
                    label="Year*"
                    type="text"
                    state={formik.values.year}
                    isDisabled={true}
                  />
                </div>

                <div className="mb-3">
                  <InputField
                    id="daysPresent"
                    label="Days Present*"
                    type="text"
                    state={formik.values.daysPresent}
                    setState={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isDisabled={state?.data ? true : false}
                  />
                  {formik.touched.daysPresent && formik.errors.daysPresent ? (
                    <InputError error={formik.errors.daysPresent} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <InputField
                    id="userSalary"
                    label="User Salary (₹)*"
                    type="text"
                    state={formik.values.userSalary}
                    isDisabled={true}
                  />
                </div>

                <div className="mb-3">
                  <InputField
                    id="computedSalary"
                    label="Computed Salary (₹)*"
                    type="text"
                    state={formik.values.computedSalary}
                    isDisabled={true}
                  />
                </div>

                {formik.values.deductions?.length ? (
                  <Deductions formik={formik} />
                ) : (
                  ""
                )}

                <Incentives formik={formik} />

                <div className="mb-3">
                  <InputField
                    id="paidSalary"
                    label="Paid Salary (₹)*"
                    type="text"
                    state={formik.values.paidSalary}
                    setState={(e) => {
                      formik.setFieldValue("paidSalary", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.paidSalary && formik.errors.paidSalary ? (
                    <InputError error={formik.errors.paidSalary} />
                  ) : null}
                </div>

                {formik.values.isAnythingUpdated && (
                  <div className="mb-3">
                    <InputField
                      id="previousPaidSalary"
                      label="Previous Paid Salary (₹)*"
                      type="text"
                      state={formik.values.previousPaidSalary}
                      isDisabled={true}
                    />
                  </div>
                )}

                <div className="mb-3">
                  <InputField
                    id="netPay"
                    label="Net Pay (₹)*"
                    type="text"
                    state={formik.values.netPay}
                    isDisabled={true}
                  />
                  {formik.touched.netPay && formik.errors.netPay ? (
                    <InputError error={formik.errors.netPay} />
                  ) : null}
                </div>
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>

                {state.data ? (
                  <button className="btn font12x500 shadow-none custom_btn ms-3">
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )}
                  </button>
                ) : (
                  <button className="btn font12x500 shadow-none custom_btn ms-3">
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Save"
                    )}
                  </button>
                )}
              </div>
            </form>

            {state.data && (
              <UpdationDetail
                lastUpdate={state?.data?.attendance?.updatedAt}
                updatedBy={state?.data?.attendance?.updatedBy}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
