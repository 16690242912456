import React, { createContext } from "react";
import MenuBar from "../../../components/Setting Components/Menubar";
import MenuData from "../../../components/Setting Components/MenuData";

type propType = {
  routeName?: string;
};

export const SettingContext = createContext<any>(null);

const Setting: React.FC<propType> = ({ routeName }) => {
  return (
    <>
      <SettingContext.Provider value={{}}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3 pe-lg-2">
              <MenuBar />
            </div>
            <div className="col-12 col-md-8 col-lg-9 px-lg-2">
              <MenuData routeName={routeName ?? ""} />
            </div>
          </div>
        </div>
      </SettingContext.Provider>
    </>
  );
};

export default Setting;
