import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import useLoading from "../../../../hooks/useLoading";
import { updateUser } from "../../../../lib/api functions/user";
import { showToast } from "../../../../lib/toast/ShowToast";
import { UserContext } from "../../../../pages/main/user/add update user";
import { UserObject } from "../../../../pages/main/user/add update user/type";
import InputError from "../../../../shared components/InputError/InputError";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import FileInput from "../../../../shared components/SingleFileInput/FileInput";
import InputField from "../../../../shared components/TextInput";
import { userBankDetailsSchema } from "../../../../utils/validationSchema";

const BankDetails = () => {
  const { userId, user, userLoading, getUser } = useContext(UserContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });
  const [singleFile, setSingleFile] = useState<any>(undefined);

  const fn = {
    putUserValueInState: () => {
      formik.setFieldValue("bankName", user.bankName);
      formik.setFieldValue("accNumber", user.accNumber);
      formik.setFieldValue("ifsc", user.ifsc);
      formik.setFieldValue("branchName", user.branchName);
      user.cC && formik.setFieldValue("cC", [user.cC]);
    },
  };
  const initialValues: UserObject = {
    bankName: "",
    accNumber: "",
    ifsc: "",
    branchName: "",
    cC: "",
  };

  const formik = useFormik<UserObject>({
    initialValues,
    validationSchema: userBankDetailsSchema,
    onSubmit: async (values) => {
      let finalObj: UserObject = {};
      delete updatedValues.isObjChange;
      finalObj = updatedValues;
      showLoader();
      if (userId) {
        const res = await updateUser(finalObj, userId, hideLoader);
        if (res) {
          showToast(`User updated`, "success");
          getUser();
          hideLoader();
        }
      }
    },
  });

  useEffect(() => {
    !userLoading && userId && fn.putUserValueInState();
  }, [userLoading]);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font16x600 textColor3">Bank Details</p>
        </div>

        <div className="details_form_wrapper">
          {/* form */}
          <form onSubmit={formik.handleSubmit}>
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12 col-sm-6 order-2 order-sm-1">
                  <div className="mb-3">
                    <InputField
                      id="bankName"
                      label="Bank Name"
                      type="text"
                      state={formik.values.bankName}
                      setState={(e) => {
                        formik.setFieldValue("bankName", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          bankName: e.target.value,
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.bankName && formik.errors.bankName ? (
                      <InputError error={formik.errors.bankName} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="accNumber"
                      label="Account No"
                      type="text"
                      state={formik.values.accNumber}
                      setState={(e) => {
                        formik.setFieldValue("accNumber", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          accNumber: e.target.value,
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.accNumber && formik.errors.accNumber ? (
                      <InputError error={formik.errors.accNumber} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="ifsc"
                      label="IFSC"
                      type="text"
                      state={formik.values.ifsc}
                      setState={(e) => {
                        formik.setFieldValue("ifsc", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          ifsc: e.target.value,
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.ifsc && formik.errors.ifsc ? (
                      <InputError error={formik.errors.ifsc} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="branchName"
                      label="Branch Name"
                      type="text"
                      state={formik.values.branchName}
                      setState={(e) => {
                        formik.setFieldValue("branchName", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          branchName: e.target.value,
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.branchName && formik.errors.branchName ? (
                      <InputError error={formik.errors.branchName} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <label className="form-label font12x400 textColor3 mb-0">
                      Attachment (Cancelled Cheque)
                    </label>
                    <FileInput
                      formik={formik}
                      accept=".jpeg, .png, .jpg, .pdf"
                      multiple={false}
                      backendStateKey="cC"
                      frontendStateKey={singleFile}
                      setSingleFile={setSingleFile}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end my-4">
              <button
                type="submit"
                className="btn font12x500 shadow-none ms-3 custom_btn"
                disabled={updatedValues?.isObjChange ? false : true}
              >
                {loading ? (
                  <SpinLoader height="20" width="20" color="#fff" />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BankDetails;
