import React from "react";
import Skeleton from "react-loading-skeleton";

type propType = {
  loading: boolean;
  data?: any;
};

const BillingDetails: React.FC<propType> = (props) => {
  const { loading, data } = props;

  return (
    <>
      <div className="px-3 mt-5">
        <p className="mb-0 font12x500 textColor3">Billing Details</p>
      </div>

      <div className="container-fluid px-3 mt-3">
        <div className="row">
          <div className="col-6 col-sm-4">
            <p className="mb-0 font12x400 textColor1">Address</p>
          </div>
          <div className="col-6 col-sm-8">
            <p className="mb-0 font12x400 textColor3">
              {loading ? (
                <Skeleton width={100} height={10} borderRadius={4} />
              ) : (
                <>{data?.address === "" ? "--" : data?.address}</>
              )}
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 col-sm-4">
            <p className="mb-0 font12x400 textColor1">City</p>
          </div>
          <div className="col-6 col-sm-8">
            <p className="mb-0 font12x400 textColor3">
              {loading ? (
                <Skeleton width={50} height={10} borderRadius={4} />
              ) : (
                <>{data?.city === "" ? "--" : data?.city}</>
              )}
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 col-sm-4">
            <p className="mb-0 font12x400 textColor1">State</p>
          </div>
          <div className="col-6 col-sm-8 d-flex">
            <p className="mb-0 font12x400 textColor3">
              {loading ? (
                <Skeleton width={80} height={10} borderRadius={4} />
              ) : (
                <>{data?.state === "" ? "--" : data?.state}</>
              )}
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 col-sm-4">
            <p className="mb-0 font12x400 textColor1">Pin Code</p>
          </div>
          <div className="col-6 col-sm-8 d-flex">
            <p className="mb-0 font12x400 textColor3">
              {loading ? (
                <Skeleton width={60} height={10} borderRadius={4} />
              ) : (
                <>{data?.pincode === "" ? "--" : data?.pincode}</>
              )}
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 col-sm-4">
            <p className="mb-0 font12x400 textColor1">Country</p>
          </div>
          <div className="col-6 col-sm-8 d-flex">
            <p className="mb-0 font12x400 textColor3">
              {loading ? (
                <Skeleton width={40} height={10} borderRadius={4} />
              ) : (
                <>{data?.country === "" ? "--" : data?.country}</>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingDetails;
