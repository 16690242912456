import Api from "../../config/api";
import {
  CREATE_ITEM,
  DELETE_ITEM_SOFT,
  SEARCH_ITEM,
  SEARCH_ITEM_BY_ID,
  UPDATE_ITEM,
  UPDATE_ITEMS_QUANTITY,
} from "../../utils/constants";
import { showToast } from "../toast/ShowToast";

export const getItem = async (params?: any) => {
  try {
    const res = await Api(false).get(SEARCH_ITEM, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const getItemById = async (id?: string) => {
  try {
    const res = await Api(false).get(`${SEARCH_ITEM_BY_ID}/${id}`);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const createItem = async (body: any, hideLoader: () => void) => {
  try {
    const res = await Api(false).post(CREATE_ITEM, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const updateItem = async (
  body: any,
  id: string,
  hideLoader: () => void
) => {
  try {
    const res = await Api(false).put(`${UPDATE_ITEM}/${id}`, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const restoreItem = async (body: any, hideLoader: () => void) => {
  try {
    const res = await Api(false).delete(`${DELETE_ITEM_SOFT}`, {
      data: body,
    });
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const updateItemsQuantity = async (
  body: any,
  hideLoader: () => void
) => {
  try {
    const res = await Api(false).put(`${UPDATE_ITEMS_QUANTITY}`, body);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
      hideLoader();
    }
  }
};
