import React, { useContext, useEffect, useState } from "react";
import { commonType } from "../../pages/main/lead";
import { CgClose } from "react-icons/cg";
import UpdationDetail from "../Data Update Detail";
import InputField from "../../shared components/TextInput";
import TextareaInput from "../../shared components/TextareaInput";
import DropdownOne from "../../shared components/Dropdown/DropdownOne";
import { taskPriorityMap, taskStatusMap } from "../../utils/constants";
import { useFormik } from "formik";
import { taskSchema } from "../../utils/validationSchema";
import InputError from "../../shared components/InputError/InputError";
import { showToast } from "../../lib/toast/ShowToast";
import useLoading from "../../hooks/useLoading";
import SingleFileInput from "../../shared components/SingleFileInput";
import InputDateForm from "../../shared components/DateInput/InputDateForm";
import moment from "moment";
import { TaskType } from "../../pages/main/task/type";
import AssignUser from "./AssignUser";
import AddTags from "./AddTags";
import { createTask, updateTask } from "../../lib/api functions/task";
import SpinLoader from "../../shared components/Loader/SpinLoader";
import { getDepartment } from "../../lib/api functions/setting/department";
import { MainContext } from "../../utils/private_routes/PrivateRoutes";
import DropdownTwo from "../../shared components/Dropdown/DropdownTwo";
import { getUser } from "../../lib/api functions/user";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall: (val: any) => void;
  params: any;
};

const Form: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params } = props;
  const { companyId } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [users, setUsers] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const user = JSON.parse(window.localStorage.getItem("user") || "");

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
    assignUserId: (userObj: any): string[] => {
      let ids: string[] = [];
      userObj?.map((item: any) => {
        ids.push(item?._id);
      });
      return ids;
    },
    getUsersByDepartment: async (params: any) => {
      const res = await getUser(params);
      if (res) {
        setUsers(res.data.users);
      }
    },
    getDepartments: async () => {
      const params = { f: "name", company: companyId };
      const res = await getDepartment(params);
      if (res) {
        setDepartments(res.data.departments);
      }
    },
  };

  const initialValues: TaskType = {
    name: state?.data?.name ?? "",
    dueDate: state?.data?.dueDate
      ? moment(state?.data?.dueDate).format("YYYY-MM-DD")
      : "",
    endTime: state?.data?.endTime ? state?.data?.endTime : "",
    priority: state?.data?.priority ?? 0,
    priorityName:
      state?.data?.priority !== undefined
        ? taskPriorityMap.get(state?.data?.priority)
        : "",
    taskDescription: state?.data?.taskDescription ?? "",
    department: state?.data?.assignTo[0]?.department ?? user?.department,
    assignTo:
      state?.data?.assignTo !== undefined
        ? fn.assignUserId(state?.data?.assignTo)
        : [],
    assignToName:
      state?.data?.assignTo !== undefined ? state?.data?.assignTo : [],
    tag: "",
    tags: state?.data?.tags !== undefined ? state?.data?.tags : [],
    attachments: [],
    deleteAttachments: [],
    allAttachments: state?.data?.attachments ?? [],
    viewAttachments: state?.data?.attachments ?? [],
  };

  const formik = useFormik<TaskType>({
    initialValues,
    validationSchema: taskSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      for (let i = 0; i < values.attachments.length; i++) {
        formData.append("attachments", values.attachments[i]);
      }
      state.data &&
        values.deleteAttachments.length !== 0 &&
        formData.append(
          "deleteAttachments",
          JSON.stringify(values.deleteAttachments)
        );
      state.data &&
        formData.append(
          "allAttachments",
          JSON.stringify(values.allAttachments)
        );
      !state.data && formData.append("name", values.name);
      formData.append("dueDate", values.dueDate);
      values.endTime?.length && formData.append("endTime", values.endTime);
      !state.data && formData.append("priority", values.priority + "");
      formData.append("taskDescription", values.taskDescription);
      formData.append("department", values.department);
      formData.append("assignTo", JSON.stringify(values.assignTo));
      formData.append("tags", JSON.stringify(values.tags));

      showLoader();
      if (state.data) {
        const res = await updateTask(formData, state.data._id, hideLoader);
        if (res) {
          showToast(`Task updated`, "success");
          fn.setPositionToBody();
          apiCall(params);
          hideLoader();
        }
      } else {
        const res = await createTask(formData, hideLoader);
        if (res) {
          showToast(`Task created`, "success");
          fn.setPositionToBody();
          apiCall(params);
          hideLoader();
        }
      }
    },
  });

  useEffect(() => {
    if (formik?.values?.department && formik?.values?.department?.length > 0) {
      fn.getUsersByDepartment({
        f: "name",
        department: formik.values.department,
        company: companyId,
      });
    }
  }, [formik.values.department]);

  useEffect(() => {
    fn.getDepartments();
  }, []);

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">Add New Task</h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <InputField
                    id="name"
                    label="Task Name*"
                    type="text"
                    state={formik.values.name}
                    setState={formik.handleChange}
                    // isDisabled={state?.data ? true : false}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <InputError error={formik.errors.name} />
                  ) : null}
                </div>

                <div className="container-fluid p-0">
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <InputDateForm
                          id="dueDate"
                          label="Due Date*"
                          type="date"
                          state={moment(formik.values.dueDate).format(
                            "YYYY-MM-DD"
                          )}
                          setState={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.dueDate && formik.errors.dueDate ? (
                          <InputError error={formik.errors.dueDate} />
                        ) : null}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <InputField
                          id="endTime"
                          label="End Time*"
                          type="time"
                          name="endTime"
                          state={formik.values.endTime}
                          setState={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.endTime && formik.errors.endTime ? (
                          <InputError error={formik.errors.endTime} />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <DropdownOne
                    label="Priority*"
                    placeholder="Select Priority"
                    name="priorityName"
                    data={taskPriorityMap}
                    state={formik.values.priorityName}
                    setState={(e?: string, x?: string | number) => {
                      formik.setFieldValue("priorityName", e);
                      formik.setFieldValue("priority", x);
                    }}
                    isDisabled={state?.data ? true : false}
                    onBlur={() => formik.setFieldTouched("priorityName", true)}
                  />
                  {formik.touched.priorityName && formik.errors.priorityName ? (
                    <InputError error={formik.errors.priorityName} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <DropdownTwo
                    label="Department*"
                    placeholder="Select Department"
                    name="department"
                    data={departments}
                    state={formik.values.department}
                    setState={(e?: string, x?: string | number) => {
                      formik.setFieldValue("department", e);
                    }}
                    labelField="name"
                    onBlur={() => {
                      formik.setFieldTouched("department", true);
                    }}
                  />
                  {formik.touched.department && formik.errors.department ? (
                    <InputError error={formik.errors.department} />
                  ) : null}
                </div>

                {/* assign user */}
                <AssignUser users={users} formik={formik} />

                {/* add tags */}
                <AddTags formik={formik} />

                <div className="mb-3">
                  <TextareaInput
                    id="taskDescription"
                    label="Task Description"
                    state={formik.values.taskDescription}
                    setState={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.taskDescription &&
                  formik.errors.taskDescription ? (
                    <InputError error={formik.errors.taskDescription} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <SingleFileInput
                    id="attachments"
                    label="Attach File"
                    state={formik.values.attachments}
                    formik={formik}
                  />
                  {formik.errors.attachments ? (
                    <InputError error={formik.errors.attachments} />
                  ) : null}
                </div>
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>
                <button className="btn font12x500 shadow-none custom_btn ms-3">
                  {state.data ? (
                    loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )
                  ) : loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>

            {state.data && (
              <UpdationDetail
                lastUpdate={state?.data?.updatedAt}
                updatedBy={state?.data?.updatedBy}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
