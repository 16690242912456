import React from "react";

type propType = {
  formik: any;
};

const Deductions: React.FC<propType> = (props) => {
  const { formik } = props;

  return (
    <>
      {/* seperator */}
      <div
        className="menu_separator"
        style={{
          borderBottom: "2px solid var(--border_color4)",
          marginBottom: "5px",
        }}
      ></div>
      {/* multiple Deductions */}
      <div className="mb-3">
        <label className="form-label font12x400 textColor3 mb-1">
          Deductions
        </label>
        <div className="entry_table_wrapper">
          <div className="table_wrapper">
            <table className="table">
              <thead className="table_head">
                <tr className="font12x500 textColor3">
                  <th scope="col">Label</th>
                  <th scope="col">Value</th>
                </tr>
              </thead>
              <tbody>
                {formik.values.deductions?.map((deduction: any, i: number) => {
                  return (
                    <tr key={i} className="font12x400">
                      <td>{deduction?.label}</td>
                      <td>
                        {deduction?.valueType === "P"
                          ? (deduction?.value / 100) * formik.values.userSalary
                          : deduction?.value}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* Total Deductions */}
        <div className="mb-2">
          <p className="mb-0 font12x500">
            {`Total Deductions: ₹ ${formik.values.totalDeductions?.toLocaleString()}`}
          </p>
        </div>
        {/* seperator */}
        <div
          className="menu_separator"
          style={{
            borderBottom: "2px solid var(--border_color4)",
            marginBottom: "8px",
          }}
        ></div>
      </div>
    </>
  );
};

export default Deductions;
