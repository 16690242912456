import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../utils/private_routes/PrivateRoutes";
import { ToWords } from "to-words";
import { generateCompleteAddress, roundToTwo } from "../../function/other";

type PropType = {
  apiData: any;
  generalSetting: any;
  width?: string;
  boxShadow?: string;
};

const GenerateCreditNoteDoc: React.FC<PropType> = (props) => {
  const toWords = new ToWords();
  const { setting, documentSetting } = useContext(MainContext);

  const { apiData, generalSetting, width, boxShadow } = props;
  const companyObj = JSON.parse(window.localStorage.getItem("company") || "");

  const taxesWithPercent: any = [];

  const [finalTaxesWithPercent, setFinalTaxesWithPercent] = useState([]);

  const checkItemTax = new Set();

  const fn = {
    calculate: (item: any) => {
      let obj = {
        discount: 0,
        cgst: 0,
        sgst: 0,
        igst: 0,
        taxOutsideState: 0,
        taxinsideState: 0,
      };

      let subTotal = item?.qty * item?.unitQtyPrice;
      obj.discount = subTotal * (item?.discount / 100);

      let totalAfterDiscount = subTotal - obj.discount;

      let totalTax = totalAfterDiscount * (item?.tax / 100);

      if (apiData?.placeOfSupply === companyObj?.state) {
        obj.cgst = roundToTwo(totalTax / 2);
        obj.sgst = roundToTwo(totalTax / 2);
        obj.igst = 0;
        obj.taxinsideState = item?.tax / 2;
      } else {
        obj.cgst = 0;
        obj.sgst = 0;
        obj.igst = roundToTwo(totalTax);
        obj.taxOutsideState = item?.tax;
      }

      return obj;
    },

    calculateIgst: (item: any) => {
      let obj = {
        discount: 0,
        cgst: 0,
        sgst: 0,
        igst: 0,
        taxOutsideState: 0,
        taxinsideState: 0,
      };

      let subTotal = item?.qty * item?.unitQtyPrice;
      obj.discount = subTotal * (item?.discount / 100);

      let totalAfterDiscount = subTotal - obj.discount;

      let totalTax = totalAfterDiscount * (item?.tax / 100);

      obj.igst = roundToTwo(totalTax);

      return obj.igst;
    },

    calculateSgst: (item: any) => {
      let obj = {
        discount: 0,
        cgst: 0,
        sgst: 0,
        igst: 0,
        taxOutsideState: 0,
        taxinsideState: 0,
      };

      let subTotal = item?.qty * item?.unitQtyPrice;
      obj.discount = subTotal * (item?.discount / 100);

      let totalAfterDiscount = subTotal - obj.discount;

      let totalTax = totalAfterDiscount * (item?.tax / 100);

      obj.sgst = roundToTwo(totalTax / 2);

      return obj.sgst;
    },

    calculateCgst: (item: any) => {
      let obj = {
        discount: 0,
        cgst: 0,
        sgst: 0,
        igst: 0,
        taxOutsideState: 0,
        taxinsideState: 0,
      };

      let subTotal = item?.qty * item?.unitQtyPrice;
      obj.discount = subTotal * (item?.discount / 100);

      let totalAfterDiscount = subTotal - obj.discount;

      let totalTax = totalAfterDiscount * (item?.tax / 100);

      obj.cgst = roundToTwo(totalTax / 2);

      return obj.cgst;
    },

    calculateTotal: (item: any) => {
      let obj = {
        discount: 0,
        cgst: 0,
        sgst: 0,
        igst: 0,
        taxOutsideState: 0,
        taxinsideState: 0,
        total: 0,
      };

      let subTotal = item?.qty * item?.unitQtyPrice;
      obj.discount = subTotal * (item?.discount / 100);

      let totalAfterDiscount = subTotal - obj.discount;

      let totalTax = totalAfterDiscount * (item?.tax / 100);

      if (apiData?.placeOfSupply === companyObj?.state) {
        obj.cgst = roundToTwo(totalTax / 2);
        obj.sgst = roundToTwo(totalTax / 2);
        obj.igst = 0;
        obj.taxinsideState = item?.tax / 2;
        obj.total = obj.cgst + obj.sgst;
      } else {
        obj.cgst = 0;
        obj.sgst = 0;
        obj.igst = roundToTwo(totalTax);
        obj.taxOutsideState = item?.tax;
        obj.total = obj.igst;
      }

      return obj.total;
    },

    calculateTaxes: () => {
      apiData?.items?.forEach((element: any) => {
        if (checkItemTax.has(element.tax)) {
          taxesWithPercent.forEach((taxElement: any) => {
            if (element.tax === taxElement.tax) {
              taxElement.taxableValue =
                element.unitQtyPrice * element.qty -
                roundToTwo(fn.calculate(element).discount);
              taxElement.cgst = taxElement.cgst + fn.calculateCgst(element);
              taxElement.sgst = taxElement.sgst + fn.calculateSgst(element);
              taxElement.igst = taxElement.igst + fn.calculateIgst(element);
              taxElement.total = taxElement.total + fn.calculateTotal(element);
            }
          });
        } else {
          checkItemTax.add(element.tax);

          taxesWithPercent.push({
            taxableValue:
              element.unitQtyPrice * element.qty -
              roundToTwo(fn.calculate(element).discount),
            tax: element.tax,
            cgst: fn.calculateCgst(element),
            sgst: fn.calculateSgst(element),
            igst: fn.calculateIgst(element),
            total: fn.calculateTotal(element),
          });
        }
      });
      setFinalTaxesWithPercent(taxesWithPercent);
    },
  };

  useEffect(() => {
    if (apiData?.items?.length > 0) {
      fn.calculateTaxes();
    }
  }, [apiData]);

  return (
    <>
      <div
        id="content"
        style={{ margin: "auto", width: width, boxShadow: boxShadow }}
        className="doc_wrapper py-3 px-4"
      >
        <div className="text-end font16x600 textColor3">Original Copy</div>
        <div className="container-fluid p-0 mt-3">
          <div className="row">
            <div className="col-5">
              <h3 className="font20x600 textColor3">{companyObj?.name}</h3>
              <div className="mt-4">
                <p className="mb-0 font12x500 textColor3">
                  {generateCompleteAddress(
                    companyObj?.address,
                    companyObj?.city,
                    companyObj?.state,
                    companyObj?.country,
                    companyObj?.pincode
                  )
                    .filter((item) => item?.length)
                    .join(", ")}
                </p>
                <p className="mb-0 font12x500 textColor3">
                  {`${companyObj?.phoneCode} ${companyObj?.phoneNumber}`}
                </p>
                <p className="mb-0 font12x500 textColor3">
                  {companyObj?.emailAddress}
                </p>
                <p className="mb-0 font12x500 textColor3">
                  Contact Name: <span>{companyObj?.contactName}</span>
                </p>
                <p className="mb-0 font12x500 textColor3">
                  GSTIN: <span>--</span>
                </p>
              </div>
            </div>
            <div className="col-7">
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0 font16x600 textColor3">CREDIT NOTE</p>
                <p className="mb-0 font20x600 textColor3">CN-1</p>
              </div>
              <div className="mt-3">
                <div
                  className="d-flex justify-content-between align-items-center px-3"
                  style={{
                    backgroundColor: "var(--bg_color2)",
                  }}
                >
                  <p className="mb-0 text-white font16x500">
                    Credits Available:
                  </p>
                  <p className="mb-0 text-white font16x500">
                    {apiData?.calculation?.total?.toLocaleString()}
                  </p>
                </div>
                <div
                  className="mt-3"
                  style={{
                    width: "50%",
                    margin: "0 0 0 auto",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="font12x500 textColor3">Date:</span>
                    <span className="font12x400 textColor3">
                      {moment(apiData?.creditNoteDate).format(
                        setting?.dateFormat
                      )}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="font12x500 textColor3">Invoice no:</span>
                    <span className="font12x400 textColor3">
                      {apiData?.invoice?.invoiceNumber}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="font12x500 textColor3">Invoice date:</span>
                    <span className="font12x400 textColor3">
                      {moment(apiData?.invoiceDate).format(setting?.dateFormat)}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="font12x500 textColor3">
                      Place of Supply:
                    </span>
                    <span className="font12x400 textColor3">
                      {apiData?.placeOfSupply}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <h3 className="font14x600 textColor3">Bill to</h3>
              <div>
                <p className="mb-0 font12x500 textColor3">
                  {apiData?.client?.name}
                </p>
                <p className="mb-0 font12x500 textColor3">
                  {apiData?.client?.contactNumber}
                </p>
                <p className="mb-0 font12x500 textColor3">
                  {generateCompleteAddress(
                    apiData?.client?.completeAddress?.address,
                    apiData?.client?.completeAddress?.city,
                    apiData?.client?.completeAddress?.state,
                    apiData?.client?.completeAddress?.country,
                    apiData?.client?.completeAddress?.pincode
                  )
                    .filter((item) => item?.length)
                    .join(", ")}
                </p>
                <p className="mb-0 font12x500 textColor3">
                  {apiData?.client?.email}
                </p>
                {!(
                  apiData?.client?.gstType === "Unregistered Business" ||
                  apiData?.client?.gstType === "Consumer (B2C)" ||
                  apiData?.client?.gstType === "Overseas (Outside India)"
                ) &&
                  apiData?.client?.gstin !== "" && (
                    <p className="mb-0 font12x500 textColor3">
                      GSTIN:{" "}
                      <span className="font12x400">
                        {apiData?.client?.gstin}
                      </span>
                    </p>
                  )}
                {apiData?.client?.pan !== "" && (
                  <p className="mb-0 font12x500 textColor3">
                    PAN:{" "}
                    <span className="font12x400">{apiData?.client?.pan}</span>
                  </p>
                )}
              </div>
            </div>

            <div className="mt-3">
              <table className="doc_table">
                <thead
                  style={{
                    backgroundColor: "var(--bg_color2)",
                  }}
                >
                  <tr>
                    <th>#</th>
                    <th>Item & Description</th>
                    <th scope="col">HSN/SAC</th>
                    <th>Qty</th>
                    <th>Amount</th>
                    <th>Discount (%)</th>
                    <th>Taxable Value (₹)</th>
                    {apiData?.placeOfSupply === companyObj?.state ? (
                      <>
                        <th scope="col">CGST (₹)</th>
                        <th scope="col">SGST (₹)</th>
                      </>
                    ) : (
                      <th scope="col">IGST (₹)</th>
                    )}
                    <th>Total (₹)</th>
                  </tr>
                </thead>
                <tbody>
                  {apiData?.items?.map((item: any, i: number) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td
                          style={{
                            width: 250,
                          }}
                        >
                          <p className="mb-0 font14x500 textColor2">
                            {item?.name}
                          </p>
                          <p className="mb-0 font12x500 textColor1">
                            {item?.description}
                          </p>
                        </td>
                        <td>{item?.hsnOrsac}</td>
                        <td>{item?.qty}</td>
                        <td>{`₹ ${item?.unitQtyPrice?.toLocaleString()}`}</td>
                        <td>
                          <p className="mb-0 textColor1">
                            {roundToTwo(fn.calculate(item).discount)}
                          </p>
                          <p className="mb-0 font10x500 textColor1">
                            {`${item?.discount}%`}
                          </p>
                        </td>
                        <td>
                          {(
                            item?.unitQtyPrice * item?.qty -
                            roundToTwo(fn.calculate(item).discount)
                          )?.toLocaleString()}
                        </td>
                        {apiData?.placeOfSupply === companyObj?.state ? (
                          <>
                            <td>
                              <p className="mb-0 textColor1">
                                {roundToTwo(fn.calculate(item).cgst)}
                              </p>
                              <p className="mb-0 font10x500 textColor1">
                                {`${roundToTwo(
                                  fn.calculate(item).taxinsideState
                                )}%`}
                              </p>
                            </td>
                            <td>
                              <p className="mb-0 textColor1">
                                {roundToTwo(fn.calculate(item).sgst)}
                              </p>
                              <p className="mb-0 font10x500 textColor1">
                                {`${roundToTwo(
                                  fn.calculate(item).taxinsideState
                                )}%`}
                              </p>
                            </td>
                          </>
                        ) : (
                          <td>
                            <p className="mb-0 textColor1">
                              {roundToTwo(fn.calculate(item).igst)}
                            </p>
                            <p className="mb-0 font10x500 textColor1">
                              {`${roundToTwo(
                                fn.calculate(item).taxOutsideState
                              )}%`}
                            </p>
                          </td>
                        )}
                        <td>{roundToTwo(item?.total)?.toLocaleString()}</td>
                      </tr>
                    );
                  })}

                  {/* <tr>
                    <td colSpan={3}>
                      {documentSetting?.invoice?.pS && signature ? (
                        <div className="d-flex align-items-center">
                          <div className="px-4">
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}${signature}`}
                              className="img-fluid"
                              width={100}
                              alt=""
                            />

                            <p className="mt-2 mb-0 font10x500">
                              Provider Signature
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td colSpan={4}>
                      <div className="d-flex justify-content-end my-2 px-2">
                        <div className="calculation_wrapper">
                          <div className="calculation_field d-flex justify-content-between mb-3">
                            <p className="mb-0 font12x600 textColor3">
                              Subtotal:
                            </p>
                            <p className="mb-0 font12x600 textColor3">
                              {`₹ ${apiData?.calculation?.subTotal}`}
                            </p>
                          </div>

                          {apiData?.placeOfSupply === companyObj?.state ? (
                            <>
                              <div className="calculation_field d-flex justify-content-between mb-3">
                                <p className="mb-0 font12x600 textColor3">
                                  CGST:
                                </p>
                                <p className="mb-0 font12x600 textColor3">
                                  {`₹ ${apiData?.calculation?.cgst}`}
                                </p>
                              </div>
                              <div className="calculation_field d-flex justify-content-between mb-3">
                                <p className="mb-0 font12x600 textColor3">
                                  SGST:
                                </p>
                                <p className="mb-0 font12x600 textColor3">
                                  {`₹ ${apiData?.calculation?.sgst}`}
                                </p>
                              </div>
                            </>
                          ) : (
                            <div className="calculation_field d-flex justify-content-between mb-3">
                              <p className="mb-0 font12x600 textColor3">
                                IGST:
                              </p>
                              <p className="mb-0 font12x600 textColor3">
                                {`₹ ${apiData?.calculation?.igst}`}
                              </p>
                            </div>
                          )}

                          <div className="calculation_field d-flex justify-content-between mb-3">
                            <p className="mb-0 font12x600 textColor3">
                              Discount:
                            </p>
                            <p className="mb-0 font12x600 textColor3">
                              {`- ₹ ${apiData?.calculation?.discount}`}
                            </p>
                          </div>

                          <div className="calculation_field d-flex justify-content-between">
                            <p className="mb-0 font12x600 textColor6">Total:</p>
                            <p className="mb-0 font12x600 textColor6">
                              {`₹ ${apiData?.calculation?.total}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={9}>
                      <div
                        className="pt-3"
                        style={{ width: "70%", marginLeft: "auto" }}
                      >
                        <div className="d-flex justify-content-between mb-3">
                          <p className="mb-0 font12x600 textColor3">
                            Total Taxable Value
                          </p>
                          <p className="mb-0 font12x600 textColor3">
                            {`₹ ${apiData?.calculation?.subTotal}`}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between mb-3">
                          <p className="mb-0 font12x600 textColor3">
                            Total Value (in figure)
                          </p>
                          <p className="mb-0 font12x600 textColor3">
                            {`₹ ${apiData?.calculation?.total}`}
                          </p>
                        </div>
                        {documentSetting?.invoice?.tIW ? (
                          <div className="d-flex justify-content-between mb-3">
                            <p className="mb-0 font12x600 textColor3">
                              Total Value (in words)
                            </p>
                            {apiData &&
                            apiData.calculation &&
                            apiData.calculation.total ? (
                              <p className="mb-0 font12x600 textColor3">
                                ₹
                                {toWords.convert(apiData?.calculation?.total, {
                                  ignoreDecimal: true,
                                })}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr> */}
                </tbody>

                <tbody>
                  {finalTaxesWithPercent?.map((element: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td className="no-border"></td>
                        <td className="no-border"></td>
                        <td className="no-border"></td>
                        <td className="no-border"></td>
                        <td className="no-border"></td>
                        <td className="no-border d-flex justify-content-between">
                          <p className="mb-0 font12x600 textColor1">
                            {index === 0 ? `Total` : ""}
                          </p>
                          <p className="mb-0 font12x600 textColor1 ms-3">
                            @{element.tax}%
                          </p>
                        </td>
                        <td>{element?.taxableValue?.toLocaleString()}</td>
                        {apiData?.placeOfSupply === companyObj?.state ? (
                          <>
                            <td>{element?.cgst}</td>
                            <td>{element?.sgst}</td>
                          </>
                        ) : (
                          <td>{element?.igst}</td>
                        )}

                        <td>{element?.total?.toLocaleString()}</td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td colSpan={10} className="no-top-border">
                      <div
                        className="pt-3"
                        style={{ width: "55%", marginLeft: "auto" }}
                      >
                        {apiData?.calculation?.discount != 0 && (
                          <div className="total-section d-grid justify-content-between mb-1">
                            <p className="mb-0 font12x600 textColor3 text-end">
                              Discount
                            </p>
                            <p className="mb-0 font12x600 textColor3 text-end">
                              {`₹ ${apiData?.calculation?.discount?.toLocaleString()}`}
                            </p>
                          </div>
                        )}
                        <div className="total-section d-grid justify-content-between mb-1">
                          <p className="mb-0 font12x600 textColor3 text-end">
                            Total Taxable Value
                          </p>
                          <p className="mb-0 font12x600 textColor3 text-end">
                            {`₹ ${(
                              apiData?.calculation?.subTotal -
                              apiData?.calculation?.discount
                            )?.toLocaleString()}`}
                          </p>
                        </div>
                        <div className="total-section d-grid justify-content-between mb-1">
                          <p className="mb-0 font12x600 textColor3 text-end">
                            Total Value (in figure)
                          </p>
                          <p className="mb-0 font12x600 textColor3 text-end">
                            {`₹ ${apiData?.calculation?.total?.toLocaleString()}`}
                          </p>
                        </div>
                        {documentSetting?.invoice?.tIW ? (
                          <div className="total-section d-grid justify-content-between mb-3">
                            <p className="mb-0 font12x600 textColor3 text-end">
                              Total Value (in words)
                            </p>
                            {apiData &&
                            apiData.calculation &&
                            apiData.calculation.total ? (
                              <p className="mb-0 font12x600 textColor3 text-end">
                                {toWords.convert(apiData?.calculation?.total, {
                                  // ignoreDecimal: true,
                                  currency: true,
                                })}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="d-flex justify-content-end gap-2">
                {documentSetting?.creditNote?.pS &&
                  generalSetting?.primarySignature && (
                    <div>
                      <div className="pt-4 signature-text-container">
                        <div className="signature-container">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}${generalSetting?.primarySignature}`}
                            width={100}
                          />
                        </div>
                        <p className="mt-2 mb-0 font10x500 text-start">
                          {generalSetting?.pSName}
                        </p>
                      </div>
                    </div>
                  )}

                {documentSetting?.creditNote?.sS &&
                  generalSetting?.secondarySignature && (
                    <div>
                      <div className="pt-4 signature-text-container">
                        <div className="signature-container">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}${generalSetting?.secondarySignature}`}
                            width={100}
                          />
                        </div>
                        <p className="mt-2 mb-0 font10x500 text-start">
                          {generalSetting?.sSName}
                        </p>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateCreditNoteDoc;
