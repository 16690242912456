import React from "react";
import { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";
import AllRoutes from "./routes";

const App = () => {
  return (
    <>
      <AllRoutes />

      {/* <ToastContainer /> */}

      <Toaster />
    </>
  );
};

export default App;
