import React, { useContext, useState } from "react";
import { LeadContext } from "../../pages/main/lead/new lead";
import BillingAddressForm from "./BillingAddressForm";
import CompanyDetailForm from "./CompanyDetailForm";
import ContactPersonForm from "./ContactPersonForm";
import SearchCompany from "./SearchCompany";

const NewLeadProcess = () => {
  const { step } = useContext(LeadContext);
  const [newClient, setNewClient] = useState<any | undefined>();
  const [clientId, setClientId] = useState<string | undefined>();
  const [companySelected, setCompanySelected] = useState<boolean>();

  switch (step) {
    case 1:
      return (
        <>
          <SearchCompany
            clientId={clientId}
            setClientId={setClientId}
            companySelected={companySelected}
            setCompanySelected={setCompanySelected}
          />
        </>
      );
    case 2:
      return (
        <>
          <CompanyDetailForm
            newClient={newClient}
            setNewClient={setNewClient}
          />
        </>
      );
    case 3:
      return (
        <>
          <BillingAddressForm
            newClient={newClient}
            setNewClient={setNewClient}
            setClientId={setClientId}
          />
        </>
      );
    case 4:
      return (
        <>
          <ContactPersonForm
            companySelected={companySelected}
            clientId={clientId}
          />
        </>
      );
    default:
      return null;
  }
};

export default NewLeadProcess;
