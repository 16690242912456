import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { FaPrint } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import useLoading from "../../../../hooks/useLoading";
import {
  getExpense,
  getExpenseById,
} from "../../../../lib/api functions/expense";
import Skeleton from "react-loading-skeleton";
import { commonType } from "../../lead";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import ExpenseSidebar from "../../../../components/Expense Component/View/ExpenseSidebar";
import GenerateExpenseDoc from "../../../../components/GenerateDoc/GenerateExpenseDoc";
import PrintComponents from "react-print-components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { BiRupee } from "react-icons/bi";

import { getGeneralSetting } from "../../../../lib/api functions/setting/general";
import MadeForm from "../../../../components/Payment Component/MadeForm";
import moment from "moment";

const ViewExpensePage = () => {
  const { setPageTitle, companyId } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const { expenseId } = useParams();
  const [loading, showLoader, hideLoader] = useLoading();
  const [loading2, showLoader2, hideLoader2] = useLoading();
  const [expense, setExpense] = useState<any>([]);
  const [generalSetting, setGeneralSetting] = useState<any>("");
  const [singleExpense, setSingleExpense] = useState<any>([]);
  const navigate = useNavigate();

  const fn = {
    getExpenses: async (params?: any) => {
      showLoader();
      const res = await getExpense(params);
      if (res) {
        setExpense(res.data.expenses);
        hideLoader();
      }
    },
    getExpenseById: async () => {
      showLoader2();
      const res = await getExpenseById(expenseId);
      if (res) {
        setSingleExpense(res.data.expense);
        fn.getGeneralSetting({ company: companyId });
        hideLoader2();
      }
    },
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getGeneralSetting: async (params?: any) => {
      // showLoader2();
      const res = await getGeneralSetting(params);
      if (res) {
        setGeneralSetting(res.data.generalSettings[0]);
        // hideLoader2();
      }
    },
  };

  useEffect(() => {
    fn.getExpenses({
      company: companyId,
      f: "number,paidAmount,calculation",
      isDeleted: false,
    });
    setPageTitle("View Expense");
  }, []);

  useEffect(() => {
    fn.getExpenseById();
  }, [expenseId]);

  const generatePDF = () => {
    // const report = new JsPDF({
    //   orientation: "portrait",
    //   unit: "pt",
    //   format: "a4",
    // });

    // var width = report.internal.pageSize.getWidth();
    // var height = report.internal.pageSize.getHeight();

    // report.addFont('ArialMS', 'Arial', 'normal');

    // report.setFont('Arial');

    // report.setFont("PTSans");

    // report.html(viewDoc, {
    //   callback: function (doc) {
    //     doc.save();
    //   },
    //   width: 594,
    //   height: height,
    //   windowWidth: 630,
    // });

    const divToDisplay = document.getElementById("content");
    html2canvas(divToDisplay!).then(function (canvas) {
      const divImage = canvas.toDataURL("image/png");
      const pdf = new jsPDF("portrait", "em", "a4");
      const componentWidth = pdf.internal.pageSize.getWidth();
      const componentHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(divImage, "PNG", 0, 0, componentWidth, componentHeight);
      pdf.save(
        `expense_${singleExpense?.number}_${moment(singleExpense?.date).format(
          "YYYY-MM-DD"
        )}.pdf`
      );
    });
  };

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12 col-lg-3">
            <ExpenseSidebar
              expense={expense}
              loading={loading}
              expenseId={expenseId}
            />
          </div>

          <div className="col-12 col-lg-9 pt-4 pt-lg-0">
            <div>
              <div className="viewlead_content_header d-flex justify-content-end view_doc_header">
                <div className="viewlead_content_header_section d-flex gap-3">
                  {singleExpense?.status < 2 && (
                    <div>
                      <button
                        className="btn font12x500 buttonOne shadow-none"
                        onClick={() =>
                          fn.openModal(
                            {
                              accountId: singleExpense?.client?._id,
                              accountName: singleExpense?.client?.name,
                              docNumber: singleExpense?.number,
                              _id: singleExpense?._id,
                            },
                            "add_made_payment"
                          )
                        }
                      >
                        <BiRupee
                          className="me-1"
                          style={{
                            fontSize: 16,
                          }}
                        />
                        Add Payment
                      </button>
                    </div>
                  )}
                  <div>
                    <button
                      className="btn font12x500 buttonOne shadow-none"
                      onClick={() => navigate(`/expense/form/${expenseId}`)}
                    >
                      <MdOutlineEdit className="me-1" />
                      Edit
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn font12x500 buttonOne shadow-none"
                      onClick={() => generatePDF()}
                    >
                      <BsFileEarmarkPdf className="me-1" />
                      PDF
                    </button>
                  </div>
                  <div>
                    <div className="btn font12x500 buttonOne shadow-none print">
                      <PrintComponents
                        trigger={
                          <button>
                            <FaPrint className="me-1" /> Print
                          </button>
                        }
                      >
                        <GenerateExpenseDoc
                          apiData={singleExpense}
                          generalSetting={generalSetting}
                          width="100%"
                          boxShadow="none"
                        />
                      </PrintComponents>
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn font12x500 buttonOne shadow-none"
                      // onClick={() => redirect(url)}
                    >
                      <MdOutlineEmail
                        className="me-1"
                        style={{
                          fontSize: 16,
                        }}
                      />
                      Email
                    </button>
                  </div>
                </div>
              </div>

              {loading2 ? (
                <Skeleton height="300px" borderRadius="8px" />
              ) : (
                <div className="menu_content_wrapper p-3">
                  <GenerateExpenseDoc
                    apiData={singleExpense}
                    generalSetting={generalSetting}
                    width="635px"
                    boxShadow="rgb(99 99 99 / 20%) 0px 2px 8px 0px"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* form modal */}
      {commonData?.modal && commonData?.check === "add_made_payment" && (
        <MadeForm
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getExpenseById}
        />
      )}
    </>
  );
};

export default ViewExpensePage;
