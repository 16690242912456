import moment from "moment";
import { useContext, useEffect, useState } from "react";
import DataNotFound from "../../../components/Data Not Found";
import Pagination from "../../../components/Pagination";
import KebabMenu from "../../../components/Reminder Components/KebabMenu";
import Form from "../../../components/ToDoComponents/Form";
import useLoading from "../../../hooks/useLoading";
import { getTodo } from "../../../lib/api functions/todo";
import SpinLoader from "../../../shared components/Loader/SpinLoader";
import Search from "../../../shared components/Search";
import {
  DELETE_TODO,
  PRIMARY_COLOR,
  taskPriorityMap,
} from "../../../utils/constants";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import { commonType } from "../lead";
import { getPriorityColor } from "../task";
import { ParamType } from "./type";

const Todo = () => {
  const { userId, companyId, roles, setting } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });

  const [loading, showLoader, hideLoader] = useLoading();
  const [todos, setTodos] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    company: companyId,
    user: userId,
    isDeleted: false,
  });

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getTodos: async (params?: ParamType) => {
      showLoader();
      const res = await getTodo(params);
      if (res) {
        setTodos(res.data.todos);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
  };

  useEffect(() => {
    fn.getTodos(params);
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="data_render_wrapper">
          {/* filters */}
          <div className="filter_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-12 col-md-4">
                  <Search
                    placeholder="Search todos"
                    state={params}
                    setState={setParams}
                    apiCall={fn.getTodos}
                    paramValue="title"
                  />
                </div>
                <div className="col-12 col-md-8 pt-3 pt-md-0">
                  <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                    <div className="d-flex gap-2">
                      <button
                        className="btn font12x500 buttonOne shadow-none"
                        onClick={() => fn.openModal(undefined, "add_todo")}
                      >
                        <img
                          src="/Assets/Svg/plusIcon.svg"
                          alt="icon"
                          className="me-2"
                        />
                        Add New To-Do
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="list_view_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center pb-3">
                <div className="col-12 col-lg-3">
                  <span className="font16x600 textColor3">List of To-Do</span>
                </div>
              </div>
            </div>

            {/* table */}
            {todos?.length !== 0 ? (
              <>
                {todos?.map((todo: any, i: number) => {
                  return (
                    <div className="card_wrapper mb-3">
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <img
                            src="/Assets/Svg/toDoDetail.svg"
                            alt="icon"
                            className="me-2"
                          />
                          <h3 className="mb-0 font12x500 textColor3">To-Do</h3>
                        </div>
                        <KebabMenu
                          openFormModal={() =>
                            fn.openModal({ todo }, "edit_todo")
                          }
                          label="Todo"
                          dataIds={{ todoIds: [todo?._id] }}
                          url={DELETE_TODO}
                          apiCall={fn.getTodos}
                          params={params}
                        />
                      </div>
                      <div className="card_body">
                        <p className="mb-2 font12x600 textColor3">
                          {todo?.title}
                        </p>
                        <p className="mb-2 font12x400 textColor1">
                          {todo?.taskDescription}
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="priority_div font10x600"
                            style={{
                              backgroundColor: getPriorityColor(todo?.priority),
                            }}
                          >
                            {taskPriorityMap.get(todo?.priority)}
                          </span>
                          <div className="d-flex">
                            <p className="mb-0 font10x400 textColor3">
                              {moment(todo?.createdAt).format("hh:mm a")},
                            </p>
                            <p className="mb-0 ms-2 font10x400 textColor3">
                              {moment(todo?.createdAt).format(
                                setting?.dateFormat
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : loading ? (
              <SpinLoader height="40" width="40" color={PRIMARY_COLOR} />
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>

        {/* pagination */}
        {todos?.length !== 0 && (
          <div className="pagination_wrapper pt-3 px-3">
            <Pagination
              currentPage={params.page}
              onPageChange={(e) => {
                fn.getTodos({ ...params, page: e });
                setParams({
                  ...params,
                  page: e,
                });
              }}
              pageSize={params.per_page}
              totalCount={Math.ceil(totalResult / params.per_page)}
              onChangePerPage={(e) => {
                fn.getTodos({ ...params, per_page: e });
                setParams({
                  ...params,
                  per_page: e,
                });
              }}
            />
          </div>
        )}
      </div>

      {/* form modal */}
      {commonData?.modal &&
        (commonData?.check === "add_todo" ||
          commonData?.check === "edit_todo") && (
          <Form
            state={commonData}
            setState={setCommonData}
            apiCall={fn.getTodos}
            params={params}
          />
        )}
    </>
  );
};

export default Todo;
