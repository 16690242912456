import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  generateCompleteAddress,
  gettingStates,
} from "../../../../../function/other";
import { ViewLeadContext } from "../../../../../pages/main/lead/view lead";
import { ProfileContext } from "../../../../../pages/main/lead/view lead/sub pages/profile";
import InputDateForm from "../../../../../shared components/DateInput/InputDateForm";
import DropdownOne from "../../../../../shared components/Dropdown/DropdownOne";
import InputError from "../../../../../shared components/InputError/InputError";
import InputField from "../../../../../shared components/TextInput";
import { AiOutlineReload } from "react-icons/ai";
import { MainContext } from "../../../../../utils/private_routes/PrivateRoutes";

const Form = () => {
  const { documentSetting } = useContext(MainContext);
  const { id, lead } = useContext(ViewLeadContext);
  const { proposalId, loading, formik, getDocNumber } =
    useContext(ProfileContext);

  const [states, setStates] = useState<string[]>([]);

  useEffect(() => {
    setStates(gettingStates("IN"));
  }, []);

  return (
    <>
      <div className="container-fluid px-4">
        <div
          className="row py-2"
          style={{
            backgroundColor: "var(--bg_color5)",
            borderRadius: "8px",
          }}
        >
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <InputField
                id="clientName"
                label="Client Name*"
                type="text"
                state={lead?.client?.name}
                isDisabled={true}
              />
            </div>

            <div>
              <p className="mb-0 font12x400 textColor3">
                <span className="font12x600 textColor3">GSTIN: </span>
                {lead?.client?.gstNo}
              </p>
            </div>

            <div className="my-3">
              <DropdownOne
                label="Place of Supply*"
                placeholder="Place of supply"
                name="placeOfSupply"
                data={states}
                state={formik.values.placeOfSupply}
                setState={(e?: string, x?: string | number) => {
                  formik.setFieldValue("placeOfSupply", e);
                }}
                onBlur={() => formik.setFieldTouched("placeOfSupply", true)}
              />
              {formik.touched.placeOfSupply && formik.errors.placeOfSupply ? (
                <InputError error={formik.errors.placeOfSupply} />
              ) : null}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div>
              <p className="mb-0 font12x600 textColor3 mb-0 mb-lg-2">
                Billing Address:
              </p>
              <p className="mb-0 font12x400 textColor3">
                {generateCompleteAddress(
                  lead?.client?.completeAddress?.address,
                  lead?.client?.completeAddress?.city,
                  lead?.client?.completeAddress?.state,
                  lead?.client?.completeAddress?.country,
                  lead?.client?.completeAddress?.pincode
                )
                  .filter((item) => item?.length)
                  .join(", ")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-3 px-3">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <label
                htmlFor="proposalNumber"
                className="form-label font12x400 textColor3 mb-1"
              >
                Proposal No*
              </label>
              <div className="input-group">
                {!proposalId && (
                  <div className="input-group-text font12x600 border-0">
                    {documentSetting?.proposal?.initials !== ""
                      ? `${documentSetting?.proposal?.initials}/`
                      : ""}
                  </div>
                )}
                <input
                  type="text"
                  className="form-control shadow-none font12x600 border-0"
                  id="proposalNumber"
                  value={formik.values.proposalNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={proposalId ? true : false}
                  style={{
                    cursor: proposalId ? "no-drop" : "unset",
                    backgroundColor: proposalId ? "#f2f2f2" : "#fff",
                    color: proposalId ? "#9C9E9C" : "var(--text3)",
                  }}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                {formik.touched.proposalNumber &&
                formik.errors.proposalNumber ? (
                  <InputError error={formik.errors.proposalNumber} />
                ) : null}
                {formik.touched.proposalNumber &&
                formik.errors.proposalNumber ? (
                  <AiOutlineReload
                    className="cursor_pointer"
                    onClick={() => getDocNumber({ f: "proposal" })}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <InputDateForm
                id="issueDate"
                label="Issue Date*"
                type="date"
                state={moment(formik.values.issueDate).format("YYYY-MM-DD")}
                setState={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.issueDate && formik.errors.issueDate ? (
                <InputError error={formik.errors.issueDate} />
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <InputDateForm
                id="validTill"
                label="Valid till*"
                type="date"
                state={moment(formik.values.validTill).format("YYYY-MM-DD")}
                setState={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.validTill && formik.errors.validTill ? (
                <InputError error={formik.errors.validTill} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
