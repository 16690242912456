import { useContext } from "react";
import { UserProfileContext } from "../../../../../pages/main/user/User Profile";
import ViewFileInput from "../../../../../shared components/SingleFileInput/ViewFileInput";
import InputField from "../../../../../shared components/TextInput";

const BankDetails = () => {
  const { userObj } = useContext(UserProfileContext);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font16x600 textColor3">Bank Details</p>
        </div>

        <div className="details_form_wrapper">
          {/* form */}
          <form>
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12 col-sm-6 order-2 order-sm-1">
                  <div className="mb-3">
                    <InputField
                      id="bankName"
                      label="Bank Name"
                      type="text"
                      state={userObj?.bankName}
                      isDisabled={true}
                    />
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="accNumber"
                      label="Account No"
                      type="text"
                      state={userObj?.accNumber}
                      isDisabled={true}
                    />
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="ifsc"
                      label="IFSC"
                      type="text"
                      state={userObj?.ifsc}
                      isDisabled={true}
                    />
                  </div>

                  <div className="mb-3">
                    <InputField
                      id="branchName"
                      label="Branch Name"
                      type="text"
                      state={userObj?.branchName}
                      isDisabled={true}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label font12x400 textColor3 mb-0">
                      Attachment (Cancelled Cheque)
                    </label>
                    {userObj?.cC ? (
                      <ViewFileInput
                        url={userObj?.cC?.url}
                        name={userObj?.cC?.name}
                      />
                    ) : (
                      <div>--</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BankDetails;
