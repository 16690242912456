import React, { useContext, useEffect, useState } from "react";
import InputField from "../../../../shared components/TextInput";
import { CgClose } from "react-icons/cg";
import { commonType } from "../../../../pages/main/lead";
import UpdationDetail from "../../../Data Update Detail";
import TextareaInput from "../../../../shared components/TextareaInput";
import InputError from "../../../../shared components/InputError/InputError";
import { useFormik } from "formik";
import { requestInvoiceSchema } from "../../../../utils/validationSchema";
import DropdownTwo from "../../../../shared components/Dropdown/DropdownTwo";
import useLoading from "../../../../hooks/useLoading";
import { ViewLeadContext } from "../../../../pages/main/lead/view lead";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import { RequestInvoiceObject } from "./Type";
import { getPO } from "../../../../lib/api functions/lead/po";
import {
  createRequestInvoice,
  updateRequestInvoice,
} from "../../../../lib/api functions/invoice/request_invoice";
import { showToast } from "../../../../lib/toast/ShowToast";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall: (val: any) => void;
  params: any;
};

const Form: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params } = props;
  const { companyId } = useContext(MainContext);
  const [poList, setPOList] = useState<any>([]);
  const [loading, showLoader, hideLoader] = useLoading();
  const { id, getActivity, getDemoRequest } = useContext(ViewLeadContext);
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "";
    },
    getPOs: async (params?: any) => {
      const res = await getPO(params);
      if (res) {
        setPOList(res.data.purchaseOrders);
      }
    },
  };

  const initialValues: RequestInvoiceObject = {
    title: state?.data?.request?.title ?? "",
    description: state?.data?.request?.description ?? "",
    number: state?.data?.request?.poNumber ?? "",
    po: state?.data?.request?._id ?? "",
  };

  const formik = useFormik<RequestInvoiceObject>({
    initialValues,
    validationSchema: requestInvoiceSchema,
    onSubmit: async (values) => {
      let finalObj = {};
      if (state?.data) {
        delete updatedValues.isObjChange;
        finalObj = { ...updatedValues };
      } else {
        finalObj = {
          lead: id,
          title: values.title,
          description: values.description,
          po: values.po,
        };
      }

      showLoader();
      if (state.data) {
        const res = await updateRequestInvoice(
          finalObj,
          state.data.request._id,
          hideLoader
        );
        if (res) {
          showToast(`Invoice request updated`, "success");
          fn.setPositionToBody();
          apiCall(params);
          getActivity({ lead: id });
          hideLoader();
        }
      } else {
        const res = await createRequestInvoice(finalObj, hideLoader);
        if (res) {
          showToast(`Invoice request created`, "success");
          fn.setPositionToBody();
          apiCall(params);
          getDemoRequest({ lead: id, company: companyId });
          getActivity({ lead: id });
          hideLoader();
        }
      }
    },
  });

  useEffect(() => {
    fn.getPOs({ lead: id });
  }, []);

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">
              {state.data ? "Edit Invoice Request" : "New Invoice Request"}
            </h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <InputField
                    id="title"
                    label="Title*"
                    type="text"
                    state={formik.values.title}
                    setState={(e) => {
                      formik.setFieldValue("title", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        title: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <InputError error={formik.errors.title} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <DropdownTwo
                    label="Attach PO*"
                    placeholder="Select PO number"
                    name="number"
                    data={poList}
                    state={formik.values.number}
                    setState={(e?: string, x?: string | number) => {
                      formik.setFieldValue("number", e);
                      formik.setFieldValue("po", x);
                    }}
                    labelField="number"
                    onBlur={() => formik.setFieldTouched("number", true)}
                  />
                  {formik.touched.number && formik.errors.number ? (
                    <InputError error={formik.errors.number} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <TextareaInput
                    id="description"
                    label="Description"
                    state={formik.values.description}
                    setState={(e) => {
                      formik.setFieldValue("description", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        description: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <InputError error={formik.errors.description} />
                  ) : null}
                </div>
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>

                {state.data ? (
                  <button
                    className="btn font12x500 shadow-none custom_btn ms-3"
                    disabled={updatedValues?.isObjChange ? false : true}
                  >
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )}
                  </button>
                ) : (
                  <button className="btn font12x500 shadow-none custom_btn ms-3">
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Save"
                    )}
                  </button>
                )}
              </div>
            </form>

            {state.data && (
              <UpdationDetail
                lastUpdate={state?.data?.request?.updatedAt}
                updatedBy={state?.data?.request?.updatedBy}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
