import React, { useRef, useState } from "react";
import { RiAttachment2 } from "react-icons/ri";

type proptype = {
  id: string;
  extraText?: string;
  imgWidth?: string;
  imgHeight?: string;
  fieldValue: string;
  fieldValueDelete?: any;
  state?: string | null;
  setState?: (val: any) => void;
  formik?: any;
  updatedValues?: any;
  setUpdatedValues?: (val: any) => void;
};

const SingleFile: React.FC<proptype> = (props) => {
  const {
    id,
    extraText,
    imgWidth,
    imgHeight,
    fieldValue,
    fieldValueDelete,
    state,
    setState,
    formik,
    updatedValues,
    setUpdatedValues,
  } = props;

  const [path, setPath] = useState(
    state ? `${process.env.REACT_APP_IMAGE_URL}${state}` : null
  );

  const file = useRef<any>(null);

  const fn = {
    handleSingleFile: () => {
      file.current.click();
    },
    setFilePath: (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        setPath(URL.createObjectURL(e.target.files[0]));
        setState?.(e.target.files);
      }
    },
    deleteFile: () => {
      setPath(null);
      file.current.value = "";
      formik.setFieldValue(fieldValue, null);
      setUpdatedValues?.({
        ...updatedValues,
        [fieldValueDelete]: state,
        isObjChange: true,
      });
    },
  };

  return (
    <>
      <div
        className="input_wrapper d-flex justify-content-center align-items-center p-0 cursor_pointer"
        onClick={fn.handleSingleFile}
      >
        <div className="input_click_button font12x500 textColor1 border-end-0">
          <RiAttachment2 className="me-1" />
          attach file
        </div>
        <input
          ref={file}
          type="file"
          className="form-control shadow-none input_field"
          id={id}
          accept={".jpeg, .png"}
          hidden
          onChange={(e) => {
            fn.setFilePath(e);
          }}
        />
      </div>

      <p className="mb-0 mt-1 font10x400 text-end textColor1">{extraText}</p>

      {path && (
        <div className="image_wrapper d-inline-block mt-3">
          <img
            src={path}
            alt="browseImage"
            draggable={false}
            width={imgWidth}
            height={imgHeight}
          />
          <div
            className="delete_file"
            onClick={() => {
              fn.deleteFile();
            }}
          >
            <img src="/Assets/Svg/closeIcon.svg" alt="delete" />
          </div>
        </div>
      )}
    </>
  );
};

export default SingleFile;
