import { useFormik } from "formik";
import moment from "moment";
import { HiOutlineArrowLeft } from "react-icons/hi";
import useLoading from "../../../hooks/useLoading";
import { createDailyAttendance } from "../../../lib/api functions/attendance/attendance";
import { showToast } from "../../../lib/toast/ShowToast";
import InputDateForm from "../../../shared components/DateInput/InputDateForm";
import InputError from "../../../shared components/InputError/InputError";
import SpinLoader from "../../../shared components/Loader/SpinLoader";
import RadioButton from "../../../shared components/RadioButton/RadioButton";
import InputField from "../../../shared components/TextInput";
import {
  attendanceTypeDaily,
  attendanceTypeHourly,
} from "../../../utils/constants";
import { createAttendanceSchema } from "../../../utils/validationSchema";
import { CreateAttendanceType } from "../type";

type propType = {
  userId: string;
  month: string;
  year: string;
  state: any;
  setState: (val: any) => void;
  apiCall: () => void;
};

type CreateAttendanceAPIType = {
  date: string;
  attendanceType: string;
  userId: string;
  customSignIn?: string[];
  customSignOut?: string[];
};

const CreateDailyAttendance: React.FC<propType> = (props) => {
  const { userId, month, year, state, setState, apiCall } = props;
  const [loading, showLoader, hideLoader] = useLoading();

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
    checkValidDate: (e: any) => {
      let tempDate: string[] = [];
      state.data?.attendances &&
        state.data?.attendances?.map((item: any) => {
          tempDate.push(moment(item?.date).format("YYYY-MM-DD"));
        });

      if (tempDate.includes(e.target.value)) {
        showToast("Attendance exist for selected date", "error");
      } else {
        formik.setFieldValue("date", e.target.value);
      }
    },
    getConvertedDate: (time: string | null) => {
      let convertedTime: string = "";
      if (time) {
        convertedTime = moment(formik.values.date, "YYYY-MM-DD")
          .hour(parseInt(time?.split(":")[0]))
          .minute(parseInt(time?.split(":")[1]))
          .second(0)
          .millisecond(0)
          .toISOString() as unknown as string;
      } else {
        convertedTime = "null";
      }
      return convertedTime;
    },
  };

  const initialValues: CreateAttendanceType = {
    date: "",
    attendanceType: "",
    customSignIn: "",
    customSignOut: "",
  };

  const formik = useFormik<CreateAttendanceType>({
    initialValues,
    validationSchema: createAttendanceSchema,
    onSubmit: async (values) => {
      let finalObj: CreateAttendanceAPIType = {
        date: values.date,
        attendanceType: values.attendanceType,
        userId: userId,
      };
      if (
        formik.values.attendanceType === "HD" ||
        formik.values.attendanceType === "DA"
      ) {
        finalObj.customSignIn = [fn.getConvertedDate(values.customSignIn)];
        finalObj.customSignOut = [fn.getConvertedDate(values.customSignOut)];
      }
      showLoader();
      const res = await createDailyAttendance(finalObj, hideLoader);
      if (res) {
        showToast(`Attendance created`, "success");
        fn.setPositionToBody();
        apiCall();
        hideLoader();
      }
    },
  });

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">Create Daily Attendance</h1>
            <span onClick={fn.setPositionToBody}>
              <HiOutlineArrowLeft />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <InputDateForm
                    id="date"
                    label="Date*"
                    type="date"
                    state={moment(formik.values.date).format("YYYY-MM-DD")}
                    setState={(e: any) => {
                      fn.checkValidDate(e);
                    }}
                    onBlur={formik.handleBlur}
                    min={moment()
                      .month(month)
                      .startOf("month")
                      .year(+year)
                      .format("YYYY-MM-DD")}
                    max={moment()
                      .month(month)
                      .endOf("month")
                      .year(+year)
                      .format("YYYY-MM-DD")}
                  />
                  {formik.touched.date && formik.errors.date ? (
                    <InputError error={formik.errors.date} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <label className="form-label font12x400 textColor3 mb-1">
                    Attendance Type*
                  </label>
                  <div className="d-flex gap-3">
                    {state?.data?.salaryType === "H"
                      ? attendanceTypeHourly?.map((type: any, i: number) => {
                          return (
                            <RadioButton
                              key={i}
                              label={type?.label}
                              htmlFor={type?.value}
                              name="attendanceType"
                              value={type?.value}
                              state={formik.values.attendanceType}
                              setState={(x?: string | number) => {
                                formik.setFieldValue("attendanceType", x);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched("attendanceType", true);
                              }}
                            />
                          );
                        })
                      : attendanceTypeDaily?.map((type: any, i: number) => {
                          return (
                            <RadioButton
                              key={i}
                              label={type?.label}
                              htmlFor={type?.value}
                              name="attendanceType"
                              value={type?.value}
                              state={formik.values.attendanceType}
                              setState={(x?: string | number) => {
                                formik.setFieldValue("attendanceType", x);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched("attendanceType", true);
                              }}
                            />
                          );
                        })}
                  </div>
                  {formik.touched.attendanceType &&
                  formik.errors.attendanceType ? (
                    <InputError error={formik.errors.attendanceType} />
                  ) : null}
                </div>

                {(formik.values.attendanceType === "HD" ||
                  formik.values.attendanceType === "DA") && (
                  <div className="mb-3">
                    <div className="mb-2">
                      <h3 className="font12x600 textColor3 mb-0">Time</h3>
                    </div>
                    <div className="d-flex gap-3">
                      <div>
                        <InputField
                          id="customSignIn"
                          label="Sign In*"
                          type="time"
                          name="customSignIn"
                          state={formik.values.customSignIn ?? ""}
                          setState={(e) => {
                            formik.setFieldValue(
                              "customSignIn",
                              e.target.value
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.customSignIn &&
                        formik.errors.customSignIn ? (
                          <InputError error={formik.errors.customSignIn} />
                        ) : null}
                      </div>
                      <div>
                        <InputField
                          id="customSignOut"
                          label="Sign Out*"
                          type="time"
                          name="customSignOut"
                          state={formik.values.customSignOut ?? ""}
                          setState={(e) => {
                            formik.setFieldValue(
                              "customSignOut",
                              e.target.value
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.customSignOut &&
                        formik.errors.customSignOut ? (
                          <InputError error={formik.errors.customSignOut} />
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn font12x500 shadow-none custom_btn ms-3"
                >
                  {loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateDailyAttendance;
