import { useContext, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Info from "../../../components/Dashboard Component/Info";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import { commonType } from "../lead";

const Dashboard = () => {
  const { user, roles } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  // const arr = [{ name: "welcome" }, { name: "neeraj" }, { name: "vanvari" }];

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
  };

  return (
    <>
      <div
        className="single_page"
        style={{
          display: "block",
        }}
      >
        {roles[0]?.dashboard.complete && (
          <div
            className="text-end p-2"
            onClick={() => fn.openModal("", "info")}
          >
            <AiOutlineInfoCircle
              style={{
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "100px",
          }}
        >
          <h1>Welcome {user?.name}</h1>
        </div>

        {/* <div>
          {arr
            .map((item: any, i: number) => {
              // return i === arr.length - 1 ? item?.name : `${item.name}, `;
              return item?.name;
            })
            .join(", ")}
        </div> */}
      </div>

      {/* info modal */}
      {commonData?.modal && commonData?.check === "info" && (
        <Info state={commonData} setState={setCommonData} />
      )}
    </>
  );
};

export default Dashboard;
