import React from "react";
import { commonType } from "../../pages/main/lead";
import { CgClose } from "react-icons/cg";
import InputField from "../../shared components/TextInput";
import TextareaInput from "../../shared components/TextareaInput";
import DropdownOne from "../../shared components/Dropdown/DropdownOne";
import { modesMap } from "../../utils/constants";
import { useFormik } from "formik";
import { paymentSchema } from "../../utils/validationSchema";
import InputError from "../../shared components/InputError/InputError";
import { showToast } from "../../lib/toast/ShowToast";
import useLoading from "../../hooks/useLoading";
import InputDateForm from "../../shared components/DateInput/InputDateForm";
import SpinLoader from "../../shared components/Loader/SpinLoader";
import { PaymentObject } from "./type";
import moment from "moment";
import { createPayment, updatePayment } from "../../lib/api functions/payment";
import Skeleton from "react-loading-skeleton";
import UpdationDetail from "../Data Update Detail";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall?: (val: any) => void;
  apiCall2?: () => void;
  params?: any;
};

const EditMade: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params, apiCall2 } = props;
  const [loading, showLoader, hideLoader] = useLoading();
  const [vendorLoading, showLoader2, hideLoader2] = useLoading();

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
  };

  const initialValues: PaymentObject = {
    vendorName: state?.data?.accountName ?? "",
    vendorAddress:
      state?.data?.singlePayment?.expense?.vendor?.completeAddress?.address,
    number: state?.data?.singlePayment?.number ?? "",
    mode:
      state?.data?.singlePayment?.mode === 0
        ? state?.data?.singlePayment?.mode
        : state?.data?.singlePayment?.mode,
    modeName:
      state?.data?.singlePayment?.mode === 0
        ? modesMap.get(state?.data?.singlePayment?.mode)
        : modesMap.get(state?.data?.singlePayment?.mode),
    expenseNumber: state?.data?.docNumber ?? "",
    amountDue:
      state?.data?.singlePayment?.expense?.calculation?.total -
      state?.data?.singlePayment?.expense?.paidAmount +
      state?.data?.singlePayment?.amount,
    date: state?.data?.singlePayment?.date
      ? moment(state?.data?.singlePayment?.date).format("YYYY-MM-DD")
      : "",
    amount: state?.data?.singlePayment?.amount,
    description: state?.data?.singlePayment?.description ?? "",
    type: 2,
  };

  const formik = useFormik<PaymentObject>({
    initialValues,
    validationSchema: paymentSchema,
    onSubmit: async (values) => {
      let finalObj = {};

      if (state?.data) {
        finalObj = {
          date: values.date,
          mode: values.mode,
          amount: Number(values.amount),
          description: values.description,
        };
      } else {
        finalObj = {
          type: values.type,
          expense: values.expense,
          number: values.number,
          date: values.date,
          mode: values.mode,
          amount: Number(values.amount),
          description: values.description,
        };
      }

      showLoader();
      if (state?.data) {
        const res = await updatePayment(
          finalObj,
          state?.data?.singlePayment?._id,
          hideLoader
        );
        if (res) {
          showToast(`Payment received updated`, "success");
          fn.setPositionToBody();
          //   typeof apiCall === "function" && apiCall   ();
          apiCall?.(params);
          apiCall2?.();
          hideLoader();
        }
      } else {
        const res = await createPayment(finalObj, hideLoader);
        if (res) {
          showToast(`Payment received added`, "success");
          fn.setPositionToBody();
          //   typeof apiCall === "function" && apiCall   ();
          apiCall?.(params);
          apiCall2?.();
          hideLoader();
        }
      }
    },
  });

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">
              {state?.data ? "Edit Payment Made" : "Add New Payment Made"}
            </h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <InputField
                    id="vendorName"
                    label="Vendor Name*"
                    type="text"
                    state={formik.values.vendorName}
                    setState={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isDisabled={state?.data ? true : false}
                  />
                  {formik.touched.vendorName && formik.errors.vendorName ? (
                    <InputError error={formik.errors.vendorName} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <label className="form-label font12x400 textColor3 mb-1">
                    Billing Address
                  </label>
                  <p className="mb-0 font12x600 textColor3">
                    {vendorLoading ? (
                      <Skeleton width="80px" borderRadius={4} />
                    ) : (
                      <>
                        {formik.values.vendorAddress
                          ? formik.values.vendorAddress
                          : "--"}
                      </>
                    )}
                  </p>
                </div>

                <div className="mb-3">
                  <InputField
                    id="number"
                    label="Payment No.*"
                    type="text"
                    state={formik.values.number}
                    setState={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isDisabled={state?.data ? true : false}
                  />
                  {formik.touched.number && formik.errors.number ? (
                    <InputError error={formik.errors.number} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <DropdownOne
                    label="Mode*"
                    placeholder="Select method"
                    name="typeLabel"
                    data={modesMap}
                    state={formik.values.modeName}
                    setState={(e?: string, x?: string | number) => {
                      formik.setFieldValue("modeName", e);
                      formik.setFieldValue("mode", x);
                    }}
                    onBlur={() => formik.setFieldTouched("modeName", true)}
                  />
                  {formik.touched.modeName && formik.errors.modeName ? (
                    <InputError error={formik.errors.modeName} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <InputField
                    id="expenseNumber"
                    label="Expense Number*"
                    type="text"
                    state={formik.values.expenseNumber}
                    setState={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isDisabled={state?.data ? true : false}
                  />
                  {formik.touched.expenseNumber &&
                  formik.errors.expenseNumber ? (
                    <InputError error={formik.errors.expenseNumber} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <label className="form-label font12x400 textColor3 mb-1">
                    Amount Due*
                  </label>
                  <p className="mb-0 font12x600 textColor3">
                    {formik.values.amountDue}
                  </p>
                </div>

                <div className="container-fluid p-0">
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <InputDateForm
                          id="date"
                          label="Select Date*"
                          type="date"
                          state={moment(formik.values.date).format(
                            "YYYY-MM-DD"
                          )}
                          setState={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.date && formik.errors.date ? (
                          <InputError error={formik.errors.date} />
                        ) : null}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <InputField
                          id="amount"
                          label="Amount (₹)*"
                          type="tel"
                          name="amount"
                          state={formik.values.amount}
                          setState={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.amount && formik.errors.amount ? (
                          <InputError error={formik.errors.amount} />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <TextareaInput
                    id="description"
                    label="Description"
                    state={formik.values.description}
                    setState={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <InputError error={formik.errors.description} />
                  ) : null}
                </div>
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>
                <button className="btn font12x500 shadow-none custom_btn ms-3">
                  {state.data ? (
                    loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )
                  ) : loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>

            {state.data && (
              <UpdationDetail
                lastUpdate={state?.data?.singlePayment?.updatedAt}
                updatedBy={state?.data?.singlePayment?.updatedBy}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMade;
