import Api from "../../../config/api";
import {
  CREATE_LEAD_UPDATE,
  SEARCH_LEAD_UPDATE,
  SEARCH_LEAD_UPDATE_BY_ID,
  UPDATE_LEAD_UPDATE,
} from "../../../utils/constants";
import { showToast } from "../../toast/ShowToast";

export const getUpdate = async (params: any) => {
  try {
    const res = await Api(false).get(SEARCH_LEAD_UPDATE, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const getUpdateById = async (id?: string) => {
  try {
    const res = await Api(false).get(`${SEARCH_LEAD_UPDATE_BY_ID}/${id}`);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const createUpdate = async (body: any, hideLoader: () => void) => {
  try {
    const res = await Api(true).post(CREATE_LEAD_UPDATE, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const updateUpdate = async (
  body: any,
  id: string,
  hideLoader: () => void
) => {
  try {
    const res = await Api(true).put(`${UPDATE_LEAD_UPDATE}/${id}`, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};
