import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import { ViewLeadContext } from "../..";
import { commonType } from "../../..";
import DataNotFound from "../../../../../../components/Data Not Found";
import Form from "../../../../../../components/View Lead Components/MenuData/Request Demo Component/Form";
import useLoading from "../../../../../../hooks/useLoading";
import { getDemoRequest } from "../../../../../../lib/api functions/demo_request";
import {
  demoRequestStatusMap,
  itemTypesMap,
} from "../../../../../../utils/constants";
import { MainContext } from "../../../../../../utils/private_routes/PrivateRoutes";
import { ParamType } from "./type";

const ViewRequestDemo = () => {
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const { state } = useLocation();
  const { id } = useContext(ViewLeadContext);
  const { companyId } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [requests, setRequests] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [params, setParams] = useState<ParamType>({
    // page: 1,
    // per_page: 10,
    lead: id,
    company: companyId,
  });

  const fn = {
    openForm: (data: any) => {
      setCommonData({
        data: data ? data : undefined,
        modal: true,
        check: "request_menu",
      });
      document.body.style.overflow = "hidden";
    },
    getDemoRequests: async (params?: ParamType) => {
      showLoader();
      const res = await getDemoRequest(params);
      if (res) {
        setRequests(res.data.demoRequests);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
  };

  useEffect(() => {
    fn.getDemoRequests(params);
  }, []);

  return (
    <>
      <div>
        <div className="viewlead_content_header d-flex justify-content-between">
          <p className="m-0 font14x500 textColor3">Request Demo</p>
          <button
            className="btn font12x500 buttonOne shadow-none"
            onClick={() => fn.openForm(null)}
          >
            <img src="/Assets/Svg/plusIcon.svg" alt="icon" className="me-2" />
            New Request
          </button>
        </div>

        {loading ? (
          <Skeleton height="190px" borderRadius="8px" />
        ) : (
          <div className="menu_content_wrapper">
            {/* table */}
            {requests?.length !== 0 ? (
              <div className="table_wrapper">
                <table className="table">
                  <thead className="table_head_small">
                    <tr className="font12x500 textColor3">
                      <th scope="col">Type</th>
                      <th scope="col">Item</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Note</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {requests?.map((request: any, i: number) => {
                      return (
                        <tr
                          key={i}
                          className={`font12x400 ${
                            state?._id === request?._id ? "indication" : ""
                          }`}
                        >
                          <td>{itemTypesMap.get(request?.type)}</td>
                          <td>{request?.item?.name}</td>
                          <td>{request?.qty}</td>
                          <td
                            style={{
                              maxWidth: 100,
                            }}
                          >
                            {request?.notes !== "" ? request?.notes : "--"}
                          </td>
                          <td>{demoRequestStatusMap.get(request?.status)}</td>
                          <td>
                            {request?.status === 1 || request?.status === 2 ? (
                              <p
                                className="mb-0 font10x400 textColor1"
                                style={{
                                  cursor: "no-drop",
                                }}
                              >
                                Edit
                              </p>
                            ) : (
                              <p
                                className="mb-0 font10x400 textColor2 cursor_pointer"
                                onClick={() => fn.openForm({ request })}
                              >
                                Edit
                              </p>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <DataNotFound />
            )}
          </div>
        )}
      </div>

      {/* form modal */}
      {commonData?.modal && commonData?.check === "request_menu" && (
        <Form
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getDemoRequests}
          params={params}
        />
      )}
    </>
  );
};

export default ViewRequestDemo;
