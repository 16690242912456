import { useFormik } from "formik";
import { createContext, useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import BankDetails from "../../../../../components/Setting Components/MenuData/Profile/BankDetails";
import OtherDetails from "../../../../../components/Setting Components/MenuData/Profile/OtherDetails";
import Profile from "../../../../../components/Setting Components/MenuData/Profile/Profile";
import TaxDetails from "../../../../../components/Setting Components/MenuData/Profile/TaxDetails";
import useLoading from "../../../../../hooks/useLoading";
import {
  getCompanyById,
  updateCompany,
} from "../../../../../lib/api functions/company";
import { showToast } from "../../../../../lib/toast/ShowToast";
import SpinLoader from "../../../../../shared components/Loader/SpinLoader";
import { MainContext } from "../../../../../utils/private_routes/PrivateRoutes";
import { companySchema } from "../../../../../utils/validationSchema";
import { CompanyType } from "./type";

export const CompanyContext = createContext<any>(null);

const SettingProfile = () => {
  const { companyId, roles } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [profileLoading, showLoader2, hideLoader2] = useLoading();
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });
  const companyObj = JSON.parse(window.localStorage.getItem("company") || "");

  const fn = {
    getCompanyById: async () => {
      showLoader2();
      const res = await getCompanyById(companyId);
      if (res) {
        formik.setFieldValue("_id", res.data.company._id);
        formik.setFieldValue("address", res.data.company.address);
        formik.setFieldValue("city", res.data.company.city);
        formik.setFieldValue("state", res.data.company.state);
        formik.setFieldValue("country", res.data.company.country);
        formik.setFieldValue("pincode", res.data.company.pincode);
        formik.setFieldValue("extra.countryCode", res.data.company.cCode);
        formik.setFieldValue("extra.stateCode", res.data.company.sCode);
        formik.setFieldValue("website", res.data.company.website);
        formik.setFieldValue("contactName", res.data.company.contactName);
        formik.setFieldValue("phoneCode", res.data.company.phoneCode);
        formik.setFieldValue("emailAddress", res.data.company.emailAddress);
        formik.setFieldValue("phoneNumber", res.data.company.phoneNumber);
        formik.setFieldValue("bankName", res.data.company.bankName);
        formik.setFieldValue("accountNumber", res.data.company.accountNumber);
        formik.setFieldValue("branchName", res.data.company.branchName);
        formik.setFieldValue("ifscCode", res.data.company.ifscCode);
        formik.setFieldValue("swiftCode", res.data.company.swiftCode);
        formik.setFieldValue("adCode", res.data.company.adCode);
        formik.setFieldValue("taxationType", res.data.company.taxationType);
        formik.setFieldValue("gstin", res.data.company.gstin);
        formik.setFieldValue(
          "serviceTaxNumber",
          res.data.company.serviceTaxNumber
        );
        formik.setFieldValue("tinNumber", res.data.company.tinNumber);
        formik.setFieldValue("pan", res.data.company.pan);
        formik.setFieldValue("vat", res.data.company.vat);
        formik.setFieldValue("tan", res.data.company.tan);
        formik.setFieldValue("currency", res.data.company.currency);
        formik.setFieldValue(
          "taxInclusiveRates",
          res.data.company.taxInclusiveRates
        );
        formik.setFieldValue("name", res.data.company.name);
        formik.setFieldValue("logo", res.data.company.logo);
        formik.setFieldValue("deleteLogo", res.data.company.logo);
        hideLoader2();
      }
    },
  };

  const initialValues: CompanyType = {
    _id: "",
    address: "",
    city: "",
    state: "",
    country: "India",
    pincode: "",
    website: "",
    contactName: "",
    emailAddress: "",
    phoneNumber: "",
    phoneCode: companyObj?.phoneCode,
    bankName: "",
    accountNumber: "",
    branchName: "",
    ifscCode: "",
    swiftCode: "",
    adCode: "",
    taxationType: "",
    gstin: "",
    serviceTaxNumber: "",
    tinNumber: "",
    pan: "",
    vat: "",
    tan: "",
    currency: "",
    logo: null,
    deleteLogo: null,
    name: "",
    taxInclusiveRates: false,
    extra: {
      countryCode: "IN",
      stateCode: "",
    },
  };

  const formik = useFormik<CompanyType>({
    initialValues,
    validationSchema: companySchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      updatedValues.hasOwnProperty("address") &&
        formData.append("address", values.address);
      updatedValues.hasOwnProperty("name") &&
        formData.append("name", values.name);
      updatedValues.hasOwnProperty("city") &&
        formData.append("city", values.city);
      updatedValues.hasOwnProperty("state") &&
        formData.append("state", values.state);
      updatedValues.hasOwnProperty("country") &&
        formData.append("country", values.country);
      updatedValues.hasOwnProperty("pincode") &&
        formData.append("pincode", values.pincode);
      updatedValues?.extra?.hasOwnProperty("countryCode") &&
        formData.append("cCode", values.extra.countryCode);
      updatedValues?.extra?.hasOwnProperty("stateCode") &&
        formData.append("sCode", values.extra.stateCode);
      updatedValues.hasOwnProperty("website") &&
        formData.append("website", values.website);
      updatedValues.hasOwnProperty("contactName") &&
        formData.append("contactName", values.contactName);
      updatedValues.hasOwnProperty("emailAddress") &&
        formData.append("emailAddress", values.emailAddress);
      updatedValues.hasOwnProperty("phoneNumber") &&
        formData.append("phoneNumber", values.phoneNumber);
      (updatedValues.hasOwnProperty("phoneNumber") ||
        updatedValues.hasOwnProperty("phoneCode")) &&
        formData.append("phoneCode", values.phoneCode);
      updatedValues.hasOwnProperty("bankName") &&
        formData.append("bankName", values.bankName);
      updatedValues.hasOwnProperty("accountNumber") &&
        formData.append("accountNumber", values.accountNumber);
      updatedValues.hasOwnProperty("branchName") &&
        formData.append("branchName", values.branchName);
      updatedValues.hasOwnProperty("ifscCode") &&
        formData.append("ifscCode", values.ifscCode);
      updatedValues.hasOwnProperty("adCode") &&
        formData.append("adCode", values.adCode);
      updatedValues.hasOwnProperty("swiftCode") &&
        formData.append("swiftCode", values.swiftCode);
      updatedValues.hasOwnProperty("taxationType") &&
        formData.append("taxationType", values.taxationType);
      updatedValues.hasOwnProperty("gstin") &&
        values.taxationType !== "Non-GST (international)" &&
        formData.append("gstin", values.gstin);
      updatedValues.hasOwnProperty("serviceTaxNumber") &&
        formData.append("serviceTaxNumber", values.serviceTaxNumber);
      updatedValues.hasOwnProperty("tinNumber") &&
        formData.append("tinNumber", values.tinNumber);
      updatedValues.hasOwnProperty("pan") && formData.append("pan", values.pan);
      updatedValues.hasOwnProperty("vat") && formData.append("vat", values.vat);
      updatedValues.hasOwnProperty("tan") && formData.append("tan", values.tan);
      updatedValues.hasOwnProperty("currency") &&
        formData.append("currency", values.currency);
      updatedValues.hasOwnProperty("logo") &&
        formData.append("logo", values.logo[0]);
      (updatedValues.hasOwnProperty("deleteLogo") ||
        updatedValues.hasOwnProperty("logo")) &&
        formData.append("deleteLogo", values.deleteLogo);
      updatedValues.hasOwnProperty("taxInclusiveRates") &&
        formData.append(
          "taxInclusiveRates",
          values.taxInclusiveRates.toString()
        );

      showLoader();
      const res = await updateCompany(formData, values._id, hideLoader);
      if (res) {
        showToast(`Profile updated`, "success");
        setUpdatedValues({});
        window.localStorage.setItem(
          "company",
          JSON.stringify(res.data.company)
        );
        hideLoader();
      }
    },
  });

  useEffect(() => {
    if (roles?.length !== 0) {
      if (companyId) {
        fn.getCompanyById();
      }
    }
  }, []);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font14x600 textColor3">Profile</p>
        </div>

        {profileLoading ? (
          <>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={300} height={40} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={300} height={40} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={300} height={40} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={300} height={40} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={300} height={40} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={300} height={40} borderRadius={8} />
              </div>
            </div>
          </>
        ) : (
          <div>
            <form onSubmit={formik.handleSubmit}>
              <CompanyContext.Provider
                value={{
                  formik,
                  profileLoading,
                  updatedValues,
                  setUpdatedValues,
                }}
              >
                <Profile />
                <BankDetails />
                <TaxDetails />
                <OtherDetails />
              </CompanyContext.Provider>
              <div className="d-flex justify-content-end my-4">
                <button
                  className="btn font12x500 shadow-none custom_btn ms-3"
                  disabled={updatedValues?.isObjChange ? false : true}
                >
                  {loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default SettingProfile;
