import React from "react";
import { commonType } from "../../pages/main/lead";
import { CgClose } from "react-icons/cg";
import InputField from "../../shared components/TextInput";
import { StockType } from "../../pages/main/item/type";
import { useFormik } from "formik";
import { stockSchema } from "../../utils/validationSchema";
import InputError from "../../shared components/InputError/InputError";
import { showToast } from "../../lib/toast/ShowToast";
import useLoading from "../../hooks/useLoading";
import { updateItemsQuantity } from "../../lib/api functions/item";
import SpinLoader from "../../shared components/Loader/SpinLoader";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall?: (val: any) => void;
  params: any;
};

const UpdateStock: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params } = props;
  const [loading, showLoader, hideLoader] = useLoading();

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
  };

  const initialValues: StockType = {
    id: state?.data?._id,
    count: "",
  };

  const formik = useFormik<StockType>({
    initialValues,
    validationSchema: stockSchema,
    onSubmit: async (values) => {
      let finalObj = {
        items: [
          {
            id: values?.id,
            count: values?.count,
          },
        ],
      };

      showLoader();
      if (state.data) {
        const res = await updateItemsQuantity(finalObj, hideLoader);
        if (res) {
          showToast(`Stock added`, "success");
          fn.setPositionToBody();
          apiCall?.(params);
          hideLoader();
        }
      }
    },
  });

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">Add Stock</h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <InputField
                    id="count"
                    label="Count*"
                    type="number"
                    state={formik.values.count}
                    setState={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.count && formik.errors.count ? (
                    <InputError error={formik.errors.count} />
                  ) : null}
                </div>
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="btn font12x500 shadow-none custom_btn ms-3"
                >
                  {loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Add"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateStock;
