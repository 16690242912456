import moment from "moment";
import React, { useContext } from "react";
import { MainContext } from "../../utils/private_routes/PrivateRoutes";

type PropType = {
  lastUpdate?: string;
  updatedBy?: any;
};

const UpdationDetail: React.FC<PropType> = (props) => {
  const { setting } = useContext(MainContext);
  const { lastUpdate, updatedBy } = props;

  return (
    <>
      <div
        className="my-3"
        style={{
          padding: "0 25px",
        }}
      >
        <p className="mb-0 font12x400 textColor1">
          <span className="font12x500">Last Update: </span>{" "}
          {lastUpdate
            ? moment(lastUpdate).format(`${setting?.dateFormat} - hh:mm a`)
            : "--"}
        </p>
        {updatedBy && (
          <p className="mb-0 font12x400 textColor1">
            <span className="font12x500">By: </span>{" "}
            {updatedBy ? `${updatedBy?.name} (${updatedBy?.role})` : "--"}
          </p>
        )}
      </div>
    </>
  );
};

export default UpdationDetail;
