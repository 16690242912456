import React, { useContext, useEffect, useState } from "react";
import { commonType } from "../../../pages/main/lead";
import { CgClose } from "react-icons/cg";
import InputField from "../../../shared components/TextInput";
import TextareaInput from "../../../shared components/TextareaInput";
import { useFormik } from "formik";
import { meetingSchema } from "../../../utils/validationSchema";
import InputError from "../../../shared components/InputError/InputError";
import { showToast } from "../../../lib/toast/ShowToast";
import useLoading from "../../../hooks/useLoading";
import InputDateForm from "../../../shared components/DateInput/InputDateForm";
import moment from "moment";
import { MeetingType } from "./type";
import {
  createMeeting,
  updateMeeting,
} from "../../../lib/api functions/lead/meeting";
import { getLeadById } from "../../../lib/api functions/lead/lead";
import Skeleton from "react-loading-skeleton";
import SpinLoader from "../../../shared components/Loader/SpinLoader";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";

type propType = {
  state: commonType;
  lead?: any;
  setState: (val: commonType) => void;
  apiCall?: (val: any) => void;
  getActivity?: (val: any) => void;
  params?: any;
};

const MeetingForm: React.FC<propType> = (props) => {
  const { userId } = useContext(MainContext);
  const { state, setState, lead, apiCall, params, getActivity } = props;
  const [loading, showLoader, hideLoader] = useLoading();
  const [loading2, showLoader2, hideLoader2] = useLoading();
  const [leadById, setLeadById] = useState<any>();
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });
  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
    getLeadById: async () => {
      showLoader2();
      const res = await getLeadById(state?.data?.lead);
      if (res) {
        setLeadById(res.data.lead);
        hideLoader2();
      }
    },
  };

  useEffect(() => {
    if (state?.data?.lead) {
      fn.getLeadById();
    }
  }, []);

  const initialValues: MeetingType = {
    title: state?.data?.title ?? "",
    description: state?.data?.description ?? "",
    date: state?.data?.date ?? "",
    startTime: state?.data?.startTime ?? "",
    endTime: state?.data?.endTime ?? "",
  };

  const formik = useFormik<MeetingType>({
    initialValues,
    validationSchema: meetingSchema,
    onSubmit: async (values) => {
      let finalObj = {};

      if (state?.data) {
        delete updatedValues.isObjChange;
        finalObj = updatedValues;
      } else {
        delete updatedValues.isObjChange;
        finalObj = {
          lead: lead?._id,
          ...updatedValues,
        };
      }
      showLoader();
      if (state.data) {
        const res = await updateMeeting(finalObj, state.data?._id, hideLoader);
        if (res) {
          showToast(`Meeting updated`, "success");
          fn.setPositionToBody();
          apiCall?.(params);
          hideLoader();
        }
      } else {
        const res = await createMeeting(finalObj, hideLoader);
        if (res) {
          showToast(`Meeting created`, "success");
          fn.setPositionToBody();
          getActivity?.({ lead: lead?._id });
          hideLoader();
        }
      }
    },
  });

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">
              {state.data ? "Edit Meeting Details" : "Schedule Meeting"}
            </h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <div className="form_fields_wrapper pb-0">
              <p className="mb-0 font12x500 textColor3">Person</p>
              <div className="container-fluid p-0 mt-3">
                <div className="row">
                  <div className="col-4">
                    <p className="mb-0 font12x400 textColor1">Name</p>
                  </div>
                  <div className="col-8">
                    {loading2 ? (
                      <Skeleton
                        height="12px"
                        width="100px"
                        borderRadius="4px"
                      />
                    ) : (
                      <p className="mb-0 font12x400 textColor3">
                        {lead ? lead?.name : leadById?.name}
                      </p>
                    )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-4">
                    <p className="mb-0 font12x400 textColor1">Phone</p>
                  </div>
                  <div className="col-8">
                    {loading2 ? (
                      <Skeleton
                        height="12px"
                        width="100px"
                        borderRadius="4px"
                      />
                    ) : (
                      <p className="mb-0 font12x400 textColor3">
                        {lead
                          ? `${lead?.phoneCode} ${lead?.mobile}`
                          : `${leadById?.phoneCode} ${leadById?.mobile}`}
                      </p>
                    )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-4">
                    <p className="mb-0 font12x400 textColor1">Email</p>
                  </div>
                  <div className="col-8 d-flex">
                    {loading2 ? (
                      <Skeleton
                        height="12px"
                        width="100px"
                        borderRadius="4px"
                      />
                    ) : (
                      <a
                        href={`mailto:${lead ? lead?.email : leadById?.email}`}
                        className="font12x400 textColor2 text-decoration-none"
                        target="_blank"
                      >
                        {lead ? lead?.email : leadById?.email}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <form className="mt-4" onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <InputField
                    id="title"
                    label="Title*"
                    type="text"
                    state={formik.values.title}
                    setState={(e) => {
                      formik.setFieldValue("title", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        title: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                    isDisabled={
                      state?.data && state?.data?.user !== userId ? true : false
                    }
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <InputError error={formik.errors.title} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <InputDateForm
                    id="date"
                    label="Meeting Date*"
                    type="date"
                    state={moment(formik.values.date).format("YYYY-MM-DD")}
                    setState={(e) => {
                      formik.setFieldValue("date", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        date: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                    min={moment().format("YYYY-MM-DD")}
                    isDisabled={
                      state?.data && state?.data?.user !== userId ? true : false
                    }
                  />
                  {formik.touched.date && formik.errors.date ? (
                    <InputError error={formik.errors.date} />
                  ) : null}
                </div>

                <div className="container-fluid p-0">
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <InputField
                          id="startTime"
                          label="Start Time*"
                          type="time"
                          name="startTime"
                          state={formik.values.startTime}
                          setState={(e) => {
                            formik.setFieldValue("startTime", e.target.value);
                            setUpdatedValues({
                              ...updatedValues,
                              startTime: e.target.value,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                          isDisabled={
                            state?.data && state?.data?.user !== userId
                              ? true
                              : false
                          }
                        />
                        {formik.touched.startTime && formik.errors.startTime ? (
                          <InputError error={formik.errors.startTime} />
                        ) : null}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <InputField
                          id="endTime"
                          label="End Time*"
                          type="time"
                          name="endTime"
                          state={formik.values.endTime}
                          setState={(e) => {
                            formik.setFieldValue("endTime", e.target.value);
                            setUpdatedValues({
                              ...updatedValues,
                              endTime: e.target.value,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                          isDisabled={
                            state?.data && state?.data?.user !== userId
                              ? true
                              : false
                          }
                        />
                        {formik.touched.endTime && formik.errors.endTime ? (
                          <InputError error={formik.errors.endTime} />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <TextareaInput
                    id="description"
                    label="Description*"
                    state={formik.values.description}
                    setState={(e) => {
                      formik.setFieldValue("description", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        description: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                    isDisabled={
                      state?.data && state?.data?.user !== userId ? true : false
                    }
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <InputError error={formik.errors.description} />
                  ) : null}
                </div>
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>

                {state.data ? (
                  <button
                    className="btn font12x500 shadow-none custom_btn ms-3"
                    disabled={updatedValues?.isObjChange ? false : true}
                  >
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )}
                  </button>
                ) : (
                  <button
                    className="btn font12x500 shadow-none custom_btn ms-3"
                    disabled={updatedValues?.isObjChange ? false : true}
                  >
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Save"
                    )}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetingForm;
