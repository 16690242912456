import React, { useContext, useEffect, useState } from "react";
import useLoading from "../../../hooks/useLoading";
import { commonType } from "../../../pages/main/lead";
import { CgClose } from "react-icons/cg";
import { useFormik } from "formik";
import { ApplyLeaveType } from "../type";
import { applyLeaveSchema } from "../../../utils/validationSchema";
import SpinLoader from "../../../shared components/Loader/SpinLoader";
import TextareaInput from "../../../shared components/TextareaInput";
import InputError from "../../../shared components/InputError/InputError";
import RadioButton from "../../../shared components/RadioButton/RadioButton";
import { leaveStatusMap, leaveType } from "../../../utils/constants";
import DropdownOne from "../../../shared components/Dropdown/DropdownOne";
import MultipleDateSelection from "./MultipleDateSelection";
import {
  createLeave,
  updateLeave,
} from "../../../lib/api functions/attendance/leave";
import { showToast } from "../../../lib/toast/ShowToast";
import moment from "moment";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import UpdationDetail from "../../Data Update Detail";
import { getCurrentMonthAvailablePL } from "../../../function/other";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall: (val: any) => void;
  params: any;
};

const LeaveApply: React.FC<propType> = (props) => {
  const { updateUser, roles, setting } = useContext(MainContext);
  const { state, setState, apiCall, params } = props;
  const [loading, showLoader, hideLoader] = useLoading();
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });
  const [prompt, setPrompt] = useState<string>("");
  const user = JSON.parse(window.localStorage.getItem("user") || "");

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
    getDateCorrectFormat: (dates: string[]): string[] => {
      let convertedDates: string[] = [];
      dates &&
        dates?.map((date: string) =>
          convertedDates.push(moment(date).format("YYYY-MM-DD"))
        );
      return convertedDates;
    },
  };

  const initialValues: ApplyLeaveType = {
    tempDate: "",
    dates: state?.data?.leave?.dates
      ? fn.getDateCorrectFormat(state?.data?.leave?.dates)
      : [],
    type: state?.data?.leave?.type ?? "",
    reason: state?.data?.leave?.reason ?? "",
    statusName:
      state?.data?.leave?.status === 0
        ? leaveStatusMap.get(state?.data?.leave?.status)
        : leaveStatusMap.get(state?.data?.leave?.status),
    status:
      state?.data?.leave?.status === 0
        ? state?.data?.leave?.status
        : state?.data?.leave?.status,
    rejectionReason: state?.data?.leave?.rejectionReason ?? "",
    isEdit: state?.data && roles[0]?.attendance.complete ? true : false,
  };

  const formik = useFormik<ApplyLeaveType>({
    initialValues,
    validationSchema: applyLeaveSchema,
    onSubmit: async (values) => {
      let finalObj = {};
      if (state?.data) {
        delete updatedValues.isObjChange;
        finalObj = {
          ...updatedValues,
        };
      } else {
        finalObj = {
          type: values.type,
          reason: values.reason,
          dates:
            values.dates.length === 1
              ? values.dates
              : values.dates.sort((a, b): any => {
                  const date1: any = moment(a);
                  const date2: any = moment(b);
                  return date1 - date2;
                }),
        };
      }
      showLoader();
      if (state.data) {
        const res = await updateLeave(
          finalObj,
          state.data.leave._id,
          hideLoader
        );
        if (res) {
          showToast(`Leave updated`, "success");
          fn.setPositionToBody();
          apiCall(params);
          await updateUser();
          hideLoader();
        }
      } else {
        const res = await createLeave(finalObj, hideLoader);
        if (res) {
          showToast(`Leave created`, "success");
          fn.setPositionToBody();
          apiCall(params);
          await updateUser();
          hideLoader();
        }
      }
    },
  });

  useEffect(() => {
    if (formik.values.type === "UPL" && getCurrentMonthAvailablePL(user) > 0) {
      setPrompt(`Note: You have ${getCurrentMonthAvailablePL(user)} PL left`);
    } else {
      setPrompt("");
    }
  }, [formik.values.type]);

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <div>
              <h1 className="font16x600 mb-0">
                {state?.data ? "Edit Leave Details" : "Apply for Leave"}
              </h1>
              <p className="mb-0 font12x400">
                {state?.data?.leave?.user?.name}
              </p>
            </div>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <label className="form-label font12x400 textColor3 mb-1">
                    Date*
                  </label>
                  <MultipleDateSelection formik={formik} state={state} />
                </div>

                <div className="mb-3">
                  <label className="form-label font12x400 textColor3 mb-1">
                    Type*
                  </label>
                  <div className="d-flex gap-3">
                    {leaveType?.map((type: any, i: number) => {
                      return (
                        <RadioButton
                          key={i}
                          label={type?.label}
                          htmlFor={type?.value}
                          name="type"
                          value={type?.value}
                          state={formik.values.type}
                          setState={(x?: string | number) => {
                            formik.setFieldValue("type", x);
                          }}
                          onBlur={() => {
                            formik.setFieldTouched("type", true);
                          }}
                          isDisabled={state.data ? true : false}
                        />
                      );
                    })}
                  </div>
                  {formik.touched.type && formik.errors.type ? (
                    <InputError error={formik.errors.type} />
                  ) : null}
                  {!state?.data && prompt?.length > 0 && (
                    <p className="errorDanger mt-2 mb-0">{prompt}</p>
                  )}
                </div>

                <div className="mb-3">
                  <TextareaInput
                    id="reason"
                    label="Reason*"
                    state={formik.values.reason}
                    setState={(e) => {
                      formik.setFieldValue("reason", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        reason: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.reason && formik.errors.reason ? (
                    <InputError error={formik.errors.reason} />
                  ) : null}
                </div>

                {state?.data && roles[0]?.attendance.complete && (
                  <div className="mb-3">
                    <DropdownOne
                      label="Status*"
                      placeholder="Select Status"
                      name="statusName"
                      data={leaveStatusMap}
                      state={formik.values.statusName}
                      setState={(e?: string, x?: string | number) => {
                        formik.setFieldValue("statusName", e);
                        formik.setFieldValue("status", x);
                        setUpdatedValues({
                          ...updatedValues,
                          status: x,
                          isObjChange: true,
                        });
                      }}
                      onBlur={() => formik.setFieldTouched("statusName", true)}
                      isDisabled={
                        state?.data?.leave?.status === 2 ? true : false
                      }
                    />
                    {formik.touched.statusName && formik.errors.statusName ? (
                      <InputError error={formik.errors.statusName} />
                    ) : null}
                  </div>
                )}

                {state?.data && formik.values.status === 2 && (
                  <div className="mb-3">
                    <TextareaInput
                      id="rejectionReason"
                      label="Rejection Reason*"
                      state={formik.values.rejectionReason}
                      setState={(e) => {
                        formik.setFieldValue("rejectionReason", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          rejectionReason: e.target.value,
                          isObjChange: true,
                        });
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.rejectionReason &&
                    formik.errors.rejectionReason ? (
                      <InputError error={formik.errors.rejectionReason} />
                    ) : null}
                  </div>
                )}
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>

                {state.data ? (
                  <button
                    className="btn font12x500 shadow-none custom_btn ms-3"
                    disabled={updatedValues?.isObjChange ? false : true}
                  >
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )}
                  </button>
                ) : (
                  <button className="btn font12x500 shadow-none custom_btn ms-3">
                    {loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Apply"
                    )}
                  </button>
                )}
              </div>
            </form>

            {state.data && (
              // <UpdationDetail
              //   lastUpdate={state?.data?.leave?.updatedAt}
              //   updatedBy={state?.data?.leave?.updatedBy}
              // />

              <div
                className="my-3"
                style={{
                  padding: "0 25px",
                }}
              >
                <p className="mb-0 font12x400 textColor1">
                  <span className="font12x500">Last Update: </span>{" "}
                  {state?.data?.leave?.updatedAt
                    ? moment(state?.data?.leave?.updatedAt).format(
                        `${setting?.dateFormat} - hh:mm a`
                      )
                    : "--"}
                </p>
                {state?.data?.leave?.updatedBy?.length > 0 && (
                  <p className="mb-0 font12x400 textColor1">
                    <span className="font12x500">By: </span>{" "}
                    {`${
                      state?.data?.leave?.updatedBy[
                        state?.data?.leave?.updatedBy?.length - 1
                      ]?.name
                    } (${
                      state?.data?.leave?.updatedBy[
                        state?.data?.leave?.updatedBy?.length - 1
                      ]?.role
                    })`}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveApply;
