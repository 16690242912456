import { useContext, useEffect, useState } from "react";
import { gettingCities, gettingStates } from "../../../../function/other";
import { CompanyContext } from "../../../../pages/main/setting/sub pages/profile";
import DropdownOne from "../../../../shared components/Dropdown/DropdownOne";
import PhoneCode from "../../../../shared components/Dropdown/PhoneCode";
import InputError from "../../../../shared components/InputError/InputError";
import SingleFile from "../../../../shared components/SingleFileInput/SingleFile";
import TextareaInput from "../../../../shared components/TextareaInput";
import InputField from "../../../../shared components/TextInput";
import { countryMap } from "../../../../utils/constants";

const Profile = () => {
  const { formik, updatedValues, setUpdatedValues } =
    useContext(CompanyContext);

  const [cities, setCities] = useState<string[]>([]);
  const [states, setStates] = useState<string[]>([]);

  useEffect(() => {
    setStates(gettingStates(formik.values.extra.countryCode));
  }, [formik.values.extra.countryCode]);

  useEffect(() => {
    setCities(
      gettingCities(
        formik.values.extra.countryCode,
        formik.values.extra.stateCode
      )
    );
  }, [formik.values.extra.stateCode]);

  return (
    <>
      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          Your LOGO
        </label>

        <div className="setting_input_wrapper text-center">
          <SingleFile
            id="logo"
            extraText="Preferred image size: 160px x 40px"
            imgWidth="160px"
            imgHeight="auto"
            fieldValue="logo"
            fieldValueDelete="deleteLogo"
            state={formik.values.logo}
            setState={(e: any) => {
              formik.setFieldValue("logo", Array.from(e));
              setUpdatedValues({
                ...updatedValues,
                logo: Array.from(e),
                isObjChange: true,
              });
            }}
            formik={formik}
            updatedValues={updatedValues}
            setUpdatedValues={setUpdatedValues}
          />
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          Company Name*
        </label>

        <div className="setting_input_wrapper">
          <InputField
            id="name"
            type="text"
            state={formik.values.name}
            setState={(e) => {
              formik.setFieldValue("name", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                name: e.target.value,
                isObjChange: true,
              });
            }}
          />
          {formik.touched.name && formik.errors.name ? (
            <InputError error={formik.errors.name} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          Address
        </label>

        <div className="setting_input_wrapper">
          <TextareaInput
            id="address"
            state={formik.values.address}
            setState={(e) => {
              formik.setFieldValue("address", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                address: e.target.value,
                isObjChange: true,
              });
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.address && formik.errors.address ? (
            <InputError error={formik.errors.address} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          Country*
        </label>

        <div className="setting_input_wrapper">
          <DropdownOne
            placeholder="Select country"
            name="country"
            data={countryMap}
            state={formik.values.country}
            setState={(e?: string, x?: string | number) => {
              formik.setFieldValue("country", e);
              formik.setFieldValue("extra.countryCode", x);
              formik.setFieldValue("state", "");
              formik.setFieldValue("extra.stateCode", "");
              formik.setFieldValue("city", "");
              setUpdatedValues({
                ...updatedValues,
                country: e,
                extra: { ...updatedValues.extra, countryCode: x },
                isObjChange: true,
              });
            }}
            onBlur={() => formik.setFieldTouched("country", true)}
          />
          {formik.touched.country && formik.errors.country ? (
            <InputError error={formik.errors.country} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          State*
        </label>

        <div className="setting_input_wrapper">
          <DropdownOne
            placeholder="Select State"
            name="state"
            data={states}
            state={formik.values.state}
            setState={(e?: string, x?: string | number) => {
              formik.setFieldValue("state", e);
              formik.setFieldValue("extra.stateCode", x);
              formik.setFieldValue("city", "");
              setUpdatedValues({
                ...updatedValues,
                state: e,
                extra: { ...updatedValues.extra, stateCode: x },
                isObjChange: true,
              });
            }}
            onBlur={() => formik.setFieldTouched("state", true)}
          />
          {formik.touched.state && formik.errors.state ? (
            <InputError error={formik.errors.state} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          City*
        </label>

        <div className="setting_input_wrapper">
          {formik.values.extra.stateCode !== "" ? (
            <>
              <DropdownOne
                placeholder="Select City"
                name="city"
                data={cities}
                state={formik.values.city}
                setState={(e?: string) => {
                  formik.setFieldValue("city", e);
                  setUpdatedValues({
                    ...updatedValues,
                    city: e,
                    isObjChange: true,
                  });
                }}
                onBlur={() => formik.setFieldTouched("city", true)}
              />
            </>
          ) : (
            <InputField
              id="city"
              type="text"
              state={formik.values.city}
              isReadOnly={true}
            />
          )}
          {formik.touched.city && formik.errors.city ? (
            <InputError error={formik.errors.city} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          PIN Code
        </label>

        <div className="setting_input_wrapper">
          <InputField
            id="pincode"
            type="text"
            state={formik.values.pincode}
            setState={(e) => {
              formik.setFieldValue("pincode", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                pincode: e.target.value,
                isObjChange: true,
              });
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.pincode && formik.errors.pincode ? (
            <InputError error={formik.errors.pincode} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          Website
        </label>

        <div className="setting_input_wrapper">
          <InputField
            placeholder="eg: https://www.example.com"
            id="website"
            type="text"
            state={formik.values.website}
            setState={(e) => {
              formik.setFieldValue("website", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                website: e.target.value,
                isObjChange: true,
              });
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.website && formik.errors.website ? (
            <InputError error={formik.errors.website} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          Contact Name*
        </label>

        <div className="setting_input_wrapper">
          <InputField
            id="contactName"
            type="text"
            state={formik.values.contactName}
            setState={(e) => {
              formik.setFieldValue("contactName", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                contactName: e.target.value,
                isObjChange: true,
              });
            }}
          />
          {formik.touched.contactName && formik.errors.contactName ? (
            <InputError error={formik.errors.contactName} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          Email Address*
        </label>

        <div className="setting_input_wrapper">
          <InputField
            id="emailAddress"
            type="text"
            state={formik.values.emailAddress}
            setState={(e) => {
              formik.setFieldValue("emailAddress", e.target.value);
              setUpdatedValues({
                ...updatedValues,
                emailAddress: e.target.value,
                isObjChange: true,
              });
            }}
          />
          {formik.touched.emailAddress && formik.errors.emailAddress ? (
            <InputError error={formik.errors.emailAddress} />
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
        <label htmlFor="name" className="form-label font12x400 textColor1 mb-1">
          Phone No.*
        </label>

        <div className="setting_input_wrapper">
          <div className="input-group">
            <PhoneCode
              formik={formik}
              updatedValues={updatedValues}
              setUpdatedValues={setUpdatedValues}
              fieldName="phoneCode"
              fieldName2="phoneNumber"
            />
            <input
              type="text"
              className="form-control shadow-none font12x600 border-0"
              id="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={(e) => {
                formik.setFieldValue("phoneNumber", e.target.value);
                setUpdatedValues({
                  ...updatedValues,
                  phoneNumber: e.target.value,
                  isObjChange: true,
                });
              }}
              onBlur={formik.handleBlur}
              autoComplete="new-phoneNumber"
            />
          </div>
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <InputError error={formik.errors.phoneNumber} />
          ) : null}
          {formik.touched.phoneCode && formik.errors.phoneCode ? (
            <InputError error={formik.errors.phoneCode} />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Profile;
