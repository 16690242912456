import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import useLoading from "../../../../../hooks/useLoading";
import {
  getGeneralSetting,
  updateGeneralSetting,
} from "../../../../../lib/api functions/setting/general";
import { showToast } from "../../../../../lib/toast/ShowToast";
import DropdownOne from "../../../../../shared components/Dropdown/DropdownOne";
import InputError from "../../../../../shared components/InputError/InputError";
import SpinLoader from "../../../../../shared components/Loader/SpinLoader";
import SingleFile from "../../../../../shared components/SingleFileInput/SingleFile";
import InputField from "../../../../../shared components/TextInput";
import SwitchToggle from "../../../../../shared components/ToggleButton/SwitchToggle";
import { dateFormatMap } from "../../../../../utils/constants";
import { MainContext } from "../../../../../utils/private_routes/PrivateRoutes";
import { generalSettingSchema } from "../../../../../utils/validationSchema";
import { GeneralType } from "./type";

const SettingGeneral = () => {
  const { companyId, roles, setSetting } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [generalLoading, showLoader2, hideLoader2] = useLoading();
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });

  const initialValues: GeneralType = {
    _id: "",
    dateFormat: "",
    showInvoiceDueAmount: false,
    primarySignature: null,
    pSName: "",
    secondarySignature: null,
    sSName: "",
    deletePrimarySignature: null,
    deleteSecondarySignature: null,
  };

  const fn = {
    getGeneralSetting: async (params?: any) => {
      showLoader2();
      const res = await getGeneralSetting(params);
      if (res) {
        formik.setFieldValue("_id", res.data.generalSettings[0]._id);
        formik.setFieldValue(
          "dateFormat",
          res.data.generalSettings[0].dateFormat
        );
        formik.setFieldValue(
          "showInvoiceDueAmount",
          res.data.generalSettings[0].showInvoiceDueAmount
        );
        formik.setFieldValue(
          "primarySignature",
          res.data.generalSettings[0].primarySignature
        );
        formik.setFieldValue("pSName", res.data.generalSettings[0].pSName);
        formik.setFieldValue(
          "secondarySignature",
          res.data.generalSettings[0].secondarySignature
        );
        formik.setFieldValue("sSName", res.data.generalSettings[0].sSName);
        formik.setFieldValue(
          "deletePrimarySignature",
          res.data.generalSettings[0].primarySignature
        );
        formik.setFieldValue(
          "deleteSecondarySignature",
          res.data.generalSettings[0].secondarySignature
        );
        hideLoader2();
      }
    },
  };

  const formik = useFormik<GeneralType>({
    initialValues,
    validationSchema: generalSettingSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      updatedValues.hasOwnProperty("dateFormat") &&
        formData.append("dateFormat", updatedValues.dateFormat);
      updatedValues.hasOwnProperty("showInvoiceDueAmount") &&
        formData.append(
          "showInvoiceDueAmount",
          updatedValues.showInvoiceDueAmount.toString()
        );
      updatedValues.hasOwnProperty("primarySignature") &&
        formData.append("primarySignature", updatedValues.primarySignature[0]);
      updatedValues.hasOwnProperty("pSName") &&
        formData.append("pSName", updatedValues.pSName);
      updatedValues.hasOwnProperty("secondarySignature") &&
        formData.append(
          "secondarySignature",
          updatedValues.secondarySignature[0]
        );
      updatedValues.hasOwnProperty("sSName") &&
        formData.append("sSName", updatedValues.sSName);
      (updatedValues.hasOwnProperty("deletePrimarySignature") ||
        updatedValues.hasOwnProperty("primarySignature")) &&
        formData.append(
          "deletePrimarySignature",
          values.deletePrimarySignature
        );
      (updatedValues.hasOwnProperty("deleteSecondarySignature") ||
        updatedValues.hasOwnProperty("secondarySignature")) &&
        formData.append(
          "deleteSecondarySignature",
          values.deleteSecondarySignature
        );

      showLoader();
      const res = await updateGeneralSetting(formData, values._id, hideLoader);
      if (res) {
        setSetting(res.data.user);
        setUpdatedValues({});
        showToast(`General setting updated`, "success");
        hideLoader();
      }
    },
  });

  useEffect(() => {
    if (roles?.length !== 0) {
      fn.getGeneralSetting({
        company: companyId,
      });
    }
  }, []);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font14x600 textColor3">General</p>
        </div>

        {generalLoading ? (
          <>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={300} height={40} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={300} height={40} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={300} height={40} borderRadius={8} />
              </div>
              <div className="d-flex justify-content-between flex-column flex-sm-row">
                <Skeleton width={100} height={15} borderRadius={4} />
                <Skeleton width={300} height={40} borderRadius={8} />
              </div>
            </div>
          </>
        ) : (
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                <label className="form-label font12x400 textColor1 mb-1">
                  Date Format
                </label>

                <div className="setting_input_wrapper">
                  <DropdownOne
                    placeholder="Select Date Format"
                    name="dateFormat"
                    data={dateFormatMap}
                    state={formik.values.dateFormat}
                    setState={(e?: string) => {
                      formik.setFieldValue("dateFormat", e);
                      setUpdatedValues({
                        ...updatedValues,
                        dateFormat: e,
                        isObjChange: true,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                <label className="form-label font12x400 textColor1 mb-1">
                  Show Pending Amount Due in invoice
                </label>

                <div className="setting_input_wrapper">
                  <SwitchToggle
                    state={formik.values.showInvoiceDueAmount}
                    setState={(e: boolean) => {
                      formik.setFieldValue("showInvoiceDueAmount", e);
                      setUpdatedValues({
                        ...updatedValues,
                        showInvoiceDueAmount: e,
                        isObjChange: true,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                <label className="form-label font12x400 textColor1 mb-1">
                  Primary Signature
                </label>

                <div className="setting_input_wrapper text-center">
                  <div className="mb-2">
                    <InputField
                      id="pSName"
                      type="text"
                      state={formik.values.pSName}
                      setState={(e) => {
                        formik.setFieldValue("pSName", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          pSName: e.target.value,
                          isObjChange: true,
                        });
                      }}
                    />
                  </div>
                  <SingleFile
                    id="p_signature"
                    extraText="This signature will appear on the documents.(invoices, proposal, etc.) Preferred image size: 240px x 80px"
                    imgWidth="240px"
                    imgHeight="80px"
                    fieldValue="primarySignature"
                    fieldValueDelete="deletePrimarySignature"
                    state={formik.values.primarySignature}
                    setState={(e: any) => {
                      formik.setFieldValue("primarySignature", Array.from(e));
                      setUpdatedValues({
                        ...updatedValues,
                        primarySignature: Array.from(e),
                        isObjChange: true,
                      });
                    }}
                    formik={formik}
                    updatedValues={updatedValues}
                    setUpdatedValues={setUpdatedValues}
                  />
                  {formik.errors.primarySignature ? (
                    <InputError error={formik.errors.primarySignature} />
                  ) : null}
                </div>
              </div>

              <div className="d-flex justify-content-start justify-content-sm-between flex-column flex-sm-row mb-3">
                <label className="form-label font12x400 textColor1 mb-1">
                  Secondary Signature
                </label>

                <div className="setting_input_wrapper text-center">
                  <div className="mb-2">
                    <InputField
                      id="sSName"
                      type="text"
                      state={formik.values.sSName}
                      setState={(e) => {
                        formik.setFieldValue("sSName", e.target.value);
                        setUpdatedValues({
                          ...updatedValues,
                          sSName: e.target.value,
                          isObjChange: true,
                        });
                      }}
                    />
                  </div>
                  <SingleFile
                    id="s_signature"
                    extraText="This signature will appear on the documents.(invoices, proposal, etc.) Preferred image size: 240px x 80px"
                    imgWidth="240px"
                    imgHeight="80px"
                    fieldValue="secondarySignature"
                    fieldValueDelete="deleteSecondarySignature"
                    state={formik.values.secondarySignature}
                    setState={(e: any) => {
                      formik.setFieldValue("secondarySignature", Array.from(e));
                      setUpdatedValues({
                        ...updatedValues,
                        secondarySignature: Array.from(e),
                        isObjChange: true,
                      });
                    }}
                    formik={formik}
                    updatedValues={updatedValues}
                    setUpdatedValues={setUpdatedValues}
                  />
                  {formik.errors.secondarySignature ? (
                    <InputError error={formik.errors.secondarySignature} />
                  ) : null}
                </div>
              </div>

              <div className="d-flex justify-content-end my-4">
                <button
                  className="btn font12x500 shadow-none custom_btn ms-3"
                  disabled={updatedValues?.isObjChange ? false : true}
                >
                  {loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default SettingGeneral;
