import React, { useContext, useEffect, useState } from "react";
import { commonType } from "../../..";
import Form from "../../../../../../components/View Lead Components/MenuData/Update Components/Form";
import useLoading from "../../../../../../hooks/useLoading";
import { getUpdate } from "../../../../../../lib/api functions/lead/update";
import { ParamType } from "./type";
import Skeleton from "react-loading-skeleton";
import DataNotFound from "../../../../../../components/Data Not Found";
import moment from "moment";
import { MainContext } from "../../../../../../utils/private_routes/PrivateRoutes";
import { ViewLeadContext } from "../..";

const ViewUpdate = () => {
  const { setting } = useContext(MainContext);
  const { id } = useContext(ViewLeadContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });

  const [loading, showLoader, hideLoader] = useLoading();
  const [updates, setUpdates] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [params, setParams] = useState<ParamType>({
    // page: 1,
    // per_page: 10,
    lead: id,
  });

  const fn = {
    openForm: (data: any) => {
      setCommonData({
        data: data ? data : undefined,
        modal: true,
        check: "update_menu",
      });
      document.body.style.overflow = "hidden";
    },
    getUpdates: async (params?: ParamType) => {
      showLoader();
      const res = await getUpdate(params);
      if (res) {
        setUpdates(res.data.updates);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
  };

  useEffect(() => {
    fn.getUpdates(params);
  }, []);

  return (
    <>
      <div>
        <div className="viewlead_content_header d-flex justify-content-between">
          <p className="m-0 font14x500 textColor3">Update</p>
          <button
            className="btn font12x500 buttonOne shadow-none"
            onClick={() => fn.openForm(null)}
          >
            <img src="/Assets/Svg/plusIcon.svg" alt="icon" className="me-2" />
            Add New Update
          </button>
        </div>

        {loading ? (
          <Skeleton height="190px" borderRadius="8px" />
        ) : (
          <div className="menu_content_wrapper">
            {/* table */}
            {updates?.length !== 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head_small">
                      <tr className="font12x500 textColor3">
                        <th scope="col">Entry Date</th>
                        <th scope="col">Title</th>
                        <th scope="col">Attachments</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {updates?.map((update: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <td>
                              {moment(update?.createdAt).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td
                              title={update?.description}
                              style={{ maxWidth: 100 }}
                            >
                              <span>{update?.title}</span>
                              <br />
                              <span className="textColor2 mb-1 font10x400">
                                {update?.description}
                              </span>
                            </td>
                            <td style={{ maxWidth: 100 }}>
                              {update?.attachments?.map(
                                (item: any, i: number) => {
                                  return (
                                    <a
                                      className="text-decoration-none"
                                      href={`${process.env.REACT_APP_IMAGE_URL}${item?.url}`}
                                      target="_blank"
                                    >
                                      <p
                                        key={i}
                                        className="mb-1 textColor2 font10x600 cursor_pointer"
                                      >
                                        {item?.filename}
                                      </p>
                                    </a>
                                  );
                                }
                              )}
                            </td>
                            <td>
                              <p
                                className="mb-0 font10x400 textColor2 cursor_pointer"
                                onClick={() => fn.openForm(update)}
                              >
                                Edit
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <DataNotFound />
            )}
          </div>
        )}
      </div>

      {/* form modal */}
      {commonData?.modal && commonData?.check === "update_menu" && (
        <Form
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getUpdates}
          params={params}
        />
      )}
    </>
  );
};

export default ViewUpdate;
