import { useContext } from "react";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import ForAdmin from "./ForAdmin";
import ForUser from "./ForUser";

const Attendance = () => {
  const { roles } = useContext(MainContext);

  return <>{roles[0]?.attendance.complete ? <ForAdmin /> : <ForUser />}</>;
};

export default Attendance;
