import Api from "../../../config/api";
import {
  CREATE_DEPARTMENT,
  SEARCH_DEPARTMENT,
  SEARCH_DEPARTMENT_BY_ID,
  UPDATE_DEPARTMENT,
} from "../../../utils/constants";
import { showToast } from "../../toast/ShowToast";

export const getDepartment = async (params?: any) => {
  try {
    const res = await Api(false).get(SEARCH_DEPARTMENT, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const getDepartmentById = async (id?: string) => {
  try {
    const res = await Api(false).get(`${SEARCH_DEPARTMENT_BY_ID}/${id}`);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const createDepartment = async (body: any, hideLoader: () => void) => {
  try {
    const res = await Api(false).post(CREATE_DEPARTMENT, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const updateDepartment = async (
  body: any,
  id: string,
  hideLoader: () => void
) => {
  try {
    const res = await Api(false).put(`${UPDATE_DEPARTMENT}/${id}`, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};
