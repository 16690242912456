import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { gettingCities, gettingStates } from "../../../../function/other";
import useLoading from "../../../../hooks/useLoading";
import { updateClient } from "../../../../lib/api functions/client";
import { updateLead } from "../../../../lib/api functions/lead/lead";
import { showToast } from "../../../../lib/toast/ShowToast";
import { commonType } from "../../../../pages/main/lead";
import { ViewLeadContext } from "../../../../pages/main/lead/view lead";
import DropdownOne from "../../../../shared components/Dropdown/DropdownOne";
import PhoneCode from "../../../../shared components/Dropdown/PhoneCode";
import InputError from "../../../../shared components/InputError/InputError";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import InputField from "../../../../shared components/TextInput";
import {
  countryMap,
  currenciesMap,
  gstTypesMap,
} from "../../../../utils/constants";
import {
  leadStepOneSchema,
  leadStepThreeSchema,
  leadStepTwoSchema,
} from "../../../../utils/validationSchema";
import UpdationDetail from "../../../Data Update Detail";

type propType = {
  step: number;
  state: commonType;
  setState: (val: commonType) => void;
};

const Form: React.FC<propType> = (props) => {
  const { id, getLeadById, getActivity } = useContext(ViewLeadContext);
  const { step, state, setState } = props;
  const [loading, showLoader, hideLoader] = useLoading();
  const [loading2, showLoader2, hideLoader2] = useLoading();
  const [loading3, showLoader3, hideLoader3] = useLoading();
  const [cities, setCities] = useState<string[]>([]);
  const [states, setStates] = useState<string[]>([]);
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "";
    },
  };

  const formik = useFormik({
    initialValues: state.data,
    validationSchema: leadStepThreeSchema,
    onSubmit: async (values) => {
      delete updatedValues.isObjChange;
      let finalObj = updatedValues;
      showLoader();
      const res = await updateLead(finalObj, values?.leadId, hideLoader);
      if (res) {
        showToast(`Person details updated`, "success");
        getActivity({ lead: id });
        getLeadById();
        hideLoader();
      }
    },
  });

  const formik2 = useFormik({
    initialValues: state.data,
    validationSchema: leadStepOneSchema,
    onSubmit: async (values) => {
      delete updatedValues.isObjChange;
      let finalObj = updatedValues;
      // let finalObj = {
      //   name: values.name,
      //   contactNumber: values.contactNumber,
      //   phoneCode: values.phoneCode,
      //   email: values.email,
      //   website: values.website,
      //   gstType: values.gstType,
      //   gstin: values.gstin,
      //   pan: values.pan,
      //   currency: values.currency,
      //   type: values.type,
      // };
      showLoader2();
      const res = await updateClient(finalObj, values?.clientId, hideLoader2);
      if (res) {
        showToast(`Client details updated`, "success");
        getLeadById();
        hideLoader2();
      }
    },
  });

  const formik3 = useFormik({
    initialValues: state.data,
    validationSchema: leadStepTwoSchema,
    onSubmit: async (values) => {
      let finalObj = {
        completeAddress: {
          address: values.address,
          state: values.state,
          city: values.city,
          pincode: values.pincode,
          country: values.country,
          cCode: values.extra.countryCode,
          sCode: values.extra.stateCode,
        },
      };
      showLoader3();
      const res = await updateClient(finalObj, values?.clientId, hideLoader2);
      if (res) {
        showToast(`Client address details updated`, "success");
        getLeadById();
        hideLoader3();
      }
    },
  });

  useEffect(() => {
    setStates(gettingStates(formik3?.values?.extra?.countryCode));
  }, [formik3?.values?.extra?.countryCode]);

  useEffect(() => {
    setCities(
      gettingCities(
        formik3?.values?.extra?.countryCode,
        formik3?.values?.extra?.stateCode
      )
    );
  }, [formik3?.values?.extra?.stateCode]);

  switch (step) {
    case 1:
      return (
        <>
          <div className="form_wrapper">
            <div className="form_container">
              <div className="form_heading_section">
                <h1 className="font16x600 mb-0">Edit Person Details</h1>
                <span onClick={fn.setPositionToBody}>
                  <CgClose />
                </span>
              </div>
              <div className="form_content_section">
                <form onSubmit={formik.handleSubmit}>
                  <div className="form_fields_wrapper">
                    <div className="mb-3">
                      <InputField
                        id="name"
                        label="Contact Person Name*"
                        type="text"
                        state={formik.values.name}
                        setState={(e) => {
                          formik.setFieldValue("name", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            name: e.target.value,
                            isObjChange: true,
                          });
                        }}
                      />
                      {formik.errors.name ? (
                        <InputError error={formik.errors.name} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <label
                        htmlFor="mobile"
                        className="form-label font12x400 textColor3 mb-1"
                      >
                        Phone*
                      </label>
                      <div className="input-group">
                        <PhoneCode
                          formik={formik}
                          updatedValues={updatedValues}
                          setUpdatedValues={setUpdatedValues}
                          fieldName="phoneCode"
                          fieldName2="mobile"
                        />
                        <input
                          type="text"
                          className="form-control shadow-none font12x600 border-0"
                          id="mobile"
                          value={formik.values.mobile}
                          onChange={(e) => {
                            formik.setFieldValue("mobile", e.target.value);
                            setUpdatedValues({
                              ...updatedValues,
                              mobile: e.target.value,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                          autoComplete="new-mobile"
                        />
                      </div>
                      {formik.touched.mobile && formik.errors.mobile ? (
                        <InputError error={formik.errors.mobile} />
                      ) : null}
                      {formik.touched.phoneCode && formik.errors.phoneCode ? (
                        <InputError error={formik.errors.phoneCode} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <InputField
                        id="email"
                        label="Email"
                        type="text"
                        state={formik.values.email}
                        setState={(e) => {
                          formik.setFieldValue("email", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            email: e.target.value,
                            isObjChange: true,
                          });
                        }}
                      />
                      {formik.errors.email ? (
                        <InputError error={formik.errors.email} />
                      ) : null}
                    </div>
                  </div>

                  <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                    <button
                      type="button"
                      className="btn font12x500 shadow-none modalBtnOutline"
                      onClick={fn.setPositionToBody}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn font12x500 shadow-none custom_btn ms-3"
                      disabled={updatedValues?.isObjChange ? false : true}
                    >
                      {loading ? (
                        <SpinLoader height="20" width="20" color="#fff" />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </form>

                {state.data && (
                  <UpdationDetail
                    lastUpdate={state?.data?.lastUpdate}
                    updatedBy={state?.data?.updatedBy}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      );
    case 2:
      return (
        <>
          <div className="form_wrapper">
            <div className="form_container">
              <div className="form_heading_section">
                <h1 className="font16x600 mb-0">Edit Company Details</h1>
                <span onClick={fn.setPositionToBody}>
                  <CgClose />
                </span>
              </div>
              <div className="form_content_section">
                <form onSubmit={formik2.handleSubmit}>
                  <div className="form_fields_wrapper">
                    <div className="mb-3">
                      <InputField
                        id="name"
                        label="Company Name*"
                        type="text"
                        state={formik2.values.name}
                        setState={(e) => {
                          formik2.setFieldValue("name", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            name: e.target.value,
                            isObjChange: true,
                          });
                        }}
                      />
                      {formik2.errors.name ? (
                        <InputError error={formik2.errors.name} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <label
                        htmlFor="contactNumber"
                        className="form-label font12x400 textColor3 mb-1"
                      >
                        Company Contact Number*
                      </label>
                      <div className="input-group">
                        <PhoneCode
                          formik={formik2}
                          updatedValues={updatedValues}
                          setUpdatedValues={setUpdatedValues}
                          fieldName="phoneCode"
                          fieldName2="contactNumber"
                        />
                        <input
                          type="text"
                          className="form-control shadow-none font12x600 border-0"
                          id="contactNumber"
                          value={formik2.values.contactNumber}
                          onChange={(e) => {
                            formik2.setFieldValue(
                              "contactNumber",
                              e.target.value
                            );
                            setUpdatedValues({
                              ...updatedValues,
                              contactNumber: e.target.value,
                              isObjChange: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                          autoComplete="new-contactNumber"
                        />
                      </div>
                      {formik2.touched.contactNumber &&
                      formik2.errors.contactNumber ? (
                        <InputError error={formik2.errors.contactNumber} />
                      ) : null}
                      {formik2.touched.phoneCode && formik2.errors.phoneCode ? (
                        <InputError error={formik2.errors.phoneCode} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <InputField
                        id="email"
                        label="Email"
                        type="text"
                        state={formik2.values.email}
                        setState={(e) => {
                          formik2.setFieldValue("email", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            email: e.target.value,
                            isObjChange: true,
                          });
                        }}
                      />
                      {formik2.errors.email ? (
                        <InputError error={formik2.errors.email} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <InputField
                        id="website"
                        label="Website"
                        type="text"
                        state={formik2.values.website}
                        setState={(e) => {
                          formik2.setFieldValue("website", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            website: e.target.value,
                            isObjChange: true,
                          });
                        }}
                      />
                      {formik2.errors.website ? (
                        <InputError error={formik2.errors.website} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <DropdownOne
                        label="GST Type*"
                        placeholder="Select GST type"
                        name="gstType"
                        data={gstTypesMap}
                        state={formik2.values.gstType}
                        setState={(e?: string) => {
                          formik2.setFieldValue("gstType", e);
                          setUpdatedValues({
                            ...updatedValues,
                            gstType: e,
                            isObjChange: true,
                          });
                        }}
                      />
                      {formik2.errors.gstType ? (
                        <InputError error={formik2.errors.gstType} />
                      ) : null}
                    </div>

                    {!(
                      formik2.values.gstType === "Unregistered Business" ||
                      formik2.values.gstType === "Registered - Comp. Scheme" ||
                      formik2.values.gstType === "Consumer (B2C)" ||
                      formik2.values.gstType === "Overseas (Outside India)"
                    ) && (
                      <div className="mb-3">
                        <InputField
                          id="gstin"
                          label={`${
                            formik2.values.gstType === ""
                              ? "GSTIN"
                              : !(
                                  formik2.values.gstType ===
                                    "Unregistered Business" ||
                                  formik2.values.gstType ===
                                    "Registered - Comp. Scheme" ||
                                  formik2.values.gstType === "Consumer (B2C)" ||
                                  formik2.values.gstType ===
                                    "Overseas (Outside India)"
                                )
                              ? "GSTIN*"
                              : "GSTIN"
                          }`}
                          type="text"
                          state={formik2.values.gstin}
                          setState={(e) => {
                            formik2.setFieldValue("gstin", e.target.value);
                            setUpdatedValues({
                              ...updatedValues,
                              gstin: e.target.value,
                              isObjChange: true,
                            });
                          }}
                        />
                        {formik2.errors.gstin ? (
                          <InputError error={formik2.errors.gstin} />
                        ) : null}
                      </div>
                    )}

                    <div className="mb-3">
                      <InputField
                        id="pan"
                        label="PAN"
                        type="text"
                        state={formik2.values.pan}
                        setState={(e) => {
                          formik2.setFieldValue("pan", e.target.value);
                          setUpdatedValues({
                            ...updatedValues,
                            pan: e.target.value,
                            isObjChange: true,
                          });
                        }}
                      />
                      {formik2.errors.pan ? (
                        <InputError error={formik2.errors.pan} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <DropdownOne
                        label="Currency*"
                        placeholder="Select currency"
                        name="currency"
                        data={currenciesMap}
                        state={formik2.values.currency}
                        setState={(e?: string) => {
                          formik2.setFieldValue("currency", e);
                          setUpdatedValues({
                            ...updatedValues,
                            currency: e,
                            isObjChange: true,
                          });
                        }}
                      />
                      {formik2.errors.currency ? (
                        <InputError error={formik2.errors.currency} />
                      ) : null}
                    </div>
                  </div>

                  <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                    <button
                      type="button"
                      className="btn font12x500 shadow-none modalBtnOutline"
                      onClick={fn.setPositionToBody}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn font12x500 shadow-none custom_btn ms-3"
                    >
                      {loading2 ? (
                        <SpinLoader height="20" width="20" color="#fff" />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </form>

                {state.data && (
                  <UpdationDetail
                    lastUpdate={state?.data?.lastUpdate}
                    updatedBy={state?.data?.updatedBy}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      );
    case 3:
      return (
        <>
          <div className="form_wrapper">
            <div className="form_container">
              <div className="form_heading_section">
                <h1 className="font16x600 mb-0">
                  Edit Billing Address Details
                </h1>
                <span onClick={fn.setPositionToBody}>
                  <CgClose />
                </span>
              </div>
              <div className="form_content_section">
                <form onSubmit={formik3.handleSubmit}>
                  <div className="form_fields_wrapper">
                    <div className="mb-3">
                      <InputField
                        id="address"
                        label="Address"
                        type="text"
                        state={formik3.values.address}
                        setState={formik3.handleChange}
                      />
                      {formik3.errors.address ? (
                        <InputError error={formik3.errors.address} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <DropdownOne
                        label="Country*"
                        placeholder="Select country"
                        name="country"
                        data={countryMap}
                        state={formik3.values.country}
                        setState={(e?: string, x?: string | number) => {
                          formik3.setFieldValue("country", e);
                          formik3.setFieldValue("extra.countryCode", x);
                          formik3.setFieldValue("state", "");
                          formik3.setFieldValue("extra.stateCode", "");
                          formik3.setFieldValue("city", "");
                        }}
                      />
                      {formik3.errors.country ? (
                        <InputError error={formik3.errors.country} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <DropdownOne
                        label="State*"
                        placeholder="Select State"
                        name="state"
                        data={states}
                        state={formik3.values.state}
                        setState={(e?: string, x?: string | number) => {
                          formik3.setFieldValue("state", e);
                          formik3.setFieldValue("extra.stateCode", x);
                          formik3.setFieldValue("city", "");
                        }}
                      />
                      {formik3.errors.state ? (
                        <InputError error={formik3.errors.state} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      {formik3.values.extra.stateCode !== "" ? (
                        <>
                          <DropdownOne
                            label="City"
                            placeholder="Select City"
                            name="city"
                            data={cities}
                            state={formik3.values.city}
                            setState={(e?: string) => {
                              formik3.setFieldValue("city", e);
                            }}
                          />
                        </>
                      ) : (
                        <InputField
                          id="city"
                          label="City*"
                          type="text"
                          state={formik3.values.city}
                          isReadOnly={true}
                        />
                      )}
                      {formik3.errors.city ? (
                        <InputError error={formik3.errors.city} />
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <InputField
                        id="pincode"
                        label="Pin Code"
                        type="tel"
                        state={formik3.values.pincode}
                        setState={formik3.handleChange}
                      />
                      {formik3.errors.pincode ? (
                        <InputError error={formik3.errors.pincode} />
                      ) : null}
                    </div>
                  </div>

                  <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                    <button
                      type="button"
                      className="btn font12x500 shadow-none modalBtnOutline"
                      onClick={fn.setPositionToBody}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn font12x500 shadow-none custom_btn ms-3"
                      // disabled={updatedValues?.isObjChange ? false : true}
                    >
                      {loading3 ? (
                        <SpinLoader height="20" width="20" color="#fff" />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </form>

                {state.data && (
                  <UpdationDetail
                    lastUpdate={state?.data?.lastUpdate}
                    updatedBy={state?.data?.updatedBy}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      );
    default:
      return null;
  }
};

export default Form;
