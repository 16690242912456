import { useContext } from "react";
import { UserProfileContext } from "../../../../../pages/main/user/User Profile";
import ViewFileInput from "../../../../../shared components/SingleFileInput/ViewFileInput";
import ViewMultiFileInput from "../../../../../shared components/SingleFileInput/ViewMultiFileInput";

const Documents = () => {
  const { userObj } = useContext(UserProfileContext);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font16x600 textColor3">Documents</p>
        </div>

        <div className="details_form_wrapper">
          {/* form */}
          <form>
            <div className="mb-3">
              <label className="form-label font12x400 textColor3 mb-0">
                NDA
              </label>
              {userObj?.nda ? (
                <ViewFileInput
                  url={userObj?.nda?.url}
                  name={userObj?.nda?.name}
                />
              ) : (
                <div>--</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label font12x400 textColor3 mb-0">
                Resume
              </label>
              {userObj?.resume ? (
                <ViewFileInput
                  url={userObj?.resume?.url}
                  name={userObj?.resume?.name}
                />
              ) : (
                <div>--</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label font12x400 textColor3 mb-0">
                Experience Letter
              </label>
              {userObj?.eL ? (
                <ViewFileInput
                  url={userObj?.eL?.url}
                  name={userObj?.eL?.name}
                />
              ) : (
                <div>--</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label font12x400 textColor3 mb-0">
                Relieving Letter
              </label>
              {userObj?.rL ? (
                <ViewFileInput
                  url={userObj?.rL?.url}
                  name={userObj?.rL?.name}
                />
              ) : (
                <div>--</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label font12x400 textColor3 mb-0">
                12th Marksheet
              </label>
              {userObj?.mMS ? (
                <ViewFileInput
                  url={userObj?.mMS?.url}
                  name={userObj?.mMS?.name}
                />
              ) : (
                <div>--</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label font12x400 textColor3 mb-0">
                10th Marksheet
              </label>
              {userObj?.iMS ? (
                <ViewFileInput
                  url={userObj?.iMS?.url}
                  name={userObj?.iMS?.name}
                />
              ) : (
                <div>--</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label font12x400 textColor3 mb-0">
                Other Certificates
              </label>
              {userObj?.oC?.length > 0 ? (
                <ViewMultiFileInput state={userObj?.oC} />
              ) : (
                <div>--</div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Documents;
