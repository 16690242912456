import { Switch } from "antd";
import React from "react";

type propType = {
  state?: boolean;
  isDisabled?: boolean;
  setState?: (val: boolean) => void;
};

const SwitchToggle: React.FC<propType> = (props) => {
  const { state, setState, isDisabled } = props;
  return (
    <>
      <div>
        <Switch
          className="toggle_switch"
          checked={state}
          onChange={(e) => {
            setState?.(e);
          }}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default SwitchToggle;
