import Api from "../../config/api";
import {
  CLEAR_ALL_NOTIFICATION,
  GET_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from "../../utils/constants";
import { showToast } from "../toast/ShowToast";

export const getNotification = async (params?: any) => {
  try {
    const res = await Api(false).get(GET_NOTIFICATION, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const updateNotification = async (
  body: any,
  id: string,
  hideLoader?: () => void
) => {
  try {
    const res = await Api(false).put(`${UPDATE_NOTIFICATION}/${id}`, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader?.();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader?.();
    }
  }
};

export const clearAllNotification = async (hideLoader?: () => void) => {
  try {
    const res = await Api(false).put(CLEAR_ALL_NOTIFICATION);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader?.();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader?.();
    }
  }
};
