import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import UserNotFound from "../../../../components/User Components/UserNotFound";
import SortData from "../../../../Filters/SortData";
import useLoading from "../../../../hooks/useLoading";
import { getDepartment } from "../../../../lib/api functions/setting/department";
import { getRole } from "../../../../lib/api functions/setting/role";
import { getUser } from "../../../../lib/api functions/user";
import SkeletonLoader from "../../../../shared components/Loader/SkeletonLoader";
import Search from "../../../../shared components/Search";
import { userStatusMap } from "../../../../utils/constants";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import { ParamType } from "../type";

export const getColor = (type: number): any => {
  switch (type) {
    case 1:
      return "var(--text6)";
    case 2:
      return "var(--text1)";
    default:
      return null;
  }
};

const UserManagement = () => {
  const { companyId, setting } = useContext(MainContext);
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const [users, setUsers] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);

  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    company: companyId,
    sort_order: "asc",
    sort_by: "customUserId",
  });

  const fn = {
    getUsers: async (params: ParamType) => {
      showLoader();
      const res = await getUser(params);
      if (res) {
        setUsers(res.data.users);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
    getRoles: async () => {
      const params = { f: "name", company: companyId };
      const res = await getRole(params);
      if (res) {
        setRoles(res.data.roles);
      }
    },
    getDepartments: async () => {
      const params = { f: "name", company: companyId };
      const res = await getDepartment(params);
      if (res) {
        setDepartments(res.data.departments);
      }
    },
    addParam: (check: string, value: string) => {
      if (check === "salaryType") {
        if (value === "resetFilter") {
          delete params?.salaryType;
          fn.getUsers?.({ ...params, page: 1 });
        } else {
          setParams({
            ...params,
            salaryType: value,
          });
          fn.getUsers?.({ ...params, page: 1, salaryType: value });
        }
      } else if (check === "department") {
        if (value === "resetFilter") {
          delete params?.department;
          fn.getUsers?.({ ...params, page: 1 });
        } else {
          setParams({
            ...params,
            department: value,
          });
          fn.getUsers?.({ ...params, page: 1, department: value });
        }
      } else {
        if (value === "resetFilter") {
          delete params?.role;
          fn.getUsers?.({ ...params, page: 1 });
        } else {
          setParams({
            ...params,
            role: value,
          });
          fn.getUsers?.({ ...params, page: 1, role: value });
        }
      }
    },
  };

  useEffect(() => {
    fn.getUsers(params);
  }, []);
  useEffect(() => {
    fn.getRoles();
    fn.getDepartments();
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="data_render_wrapper">
          {/* filters */}
          <div className="filter_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-12 col-md-4">
                  <Search
                    placeholder="Search by user name"
                    state={params}
                    setState={setParams}
                    apiCall={fn.getUsers}
                    paramValue="name"
                  />
                </div>
                <div className="col-12 col-md-8 pt-3 pt-md-0">
                  <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                    <div className="d-flex gap-2">
                      <SortData
                        state={params}
                        apiCall={fn.getUsers}
                        setState={setParams}
                      />

                      <button
                        className="btn font12x500 buttonOne shadow-none"
                        onClick={() => navigate("/user/user-role/form")}
                      >
                        <img
                          src="/Assets/Svg/plusIcon.svg"
                          alt="icon"
                          className="me-2"
                        />
                        Add New User
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="list_view_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center pb-3">
                <div className="col-12 col-lg-3">
                  <span className="font16x600 textColor3">User Management</span>
                </div>
                <div className="col-12 col-lg-9 pt-3 pt-lg-0">
                  <div className="d-flex justify-content-start justify-content-lg-end">
                    <div className="d-flex align-items-center gap-2">
                      <span className="font12x500 textColor3">Filter:</span>
                      <div className="d-flex gap-2">
                        <div>
                          <select
                            className="form-select font12x500 textColor3 shadow-none dropdown_filter"
                            onChange={(e) => {
                              fn.addParam("salaryType", e.target.value);
                            }}
                          >
                            <option value="resetFilter">
                              ---Salary Type---
                            </option>
                            <option value="H">Hourly</option>
                            <option value="D">Daily</option>
                          </select>
                        </div>
                        <div>
                          <select
                            className="form-select font12x500 textColor3 shadow-none dropdown_filter"
                            onChange={(e) => {
                              fn.addParam("department", e.target.value);
                            }}
                          >
                            <option value="resetFilter">
                              ---Department---
                            </option>
                            {departments?.length &&
                              departments?.map((item: any, i: number) => {
                                return (
                                  <option key={i} value={item?.name}>
                                    {item?.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div>
                          <select
                            className="form-select font12x500 textColor3 shadow-none dropdown_filter"
                            onChange={(e) => {
                              fn.addParam("role", e.target.value);
                            }}
                          >
                            <option value="resetFilter">---Role---</option>
                            {roles?.length &&
                              roles?.map((item: any, i: number) => {
                                return (
                                  <option
                                    className="text-capitalize"
                                    key={i}
                                    value={item?.name}
                                  >
                                    {item?.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* table */}
            {users?.length !== 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="font12x500 textColor3">
                        <th scope="col">#</th>
                        <th scope="col">Date Added</th>
                        <th scope="col">Employee Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Role</th>
                        <th scope="col">Department</th>
                        <th scope="col">Salary Type</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users?.map((user: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <th>
                              {(params.page - 1) * params.per_page + (i + 1)}
                            </th>
                            <td>
                              {moment(user?.createdAt).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td>{user?.employeeId}</td>
                            <td>
                              <span className="textColor2 mb-1">
                                {user?.name}
                              </span>
                              <br />
                              <span>{user?.customUserId}</span>
                            </td>
                            <td>{user?.email}</td>
                            <td>{user?.role}</td>
                            <td>{user?.department}</td>
                            <td>
                              {user?.salaryType
                                ? user?.salaryType === "D"
                                  ? "Daily"
                                  : "Hourly"
                                : "--"}
                            </td>
                            <td>
                              <p
                                className="mb-0"
                                style={{
                                  color: getColor(user?.status),
                                  fontWeight: "bold",
                                }}
                              >
                                {userStatusMap.get(user?.status)}
                              </p>
                            </td>
                            <td>
                              <div
                                className="d-flex align-items-center cursor_pointer"
                                onClick={() =>
                                  navigate(
                                    `/user/user-details/form/${user?._id}`
                                  )
                                }
                              >
                                <img
                                  src="/Assets/Svg/editIcon.svg"
                                  alt="edit"
                                />
                                <p className="mb-0 font12x400 textColor2 ms-1">
                                  Edit
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : loading ? (
              <SkeletonLoader />
            ) : (
              <UserNotFound />
            )}
          </div>
        </div>

        {/* pagination */}
        {users?.length !== 0 && (
          <div className="pagination_wrapper pt-3 px-3">
            <Pagination
              currentPage={params.page}
              onPageChange={(e) => {
                fn.getUsers({ ...params, page: e });
                setParams({
                  ...params,
                  page: e,
                });
              }}
              pageSize={params.per_page}
              totalCount={Math.ceil(totalResult / params.per_page)}
              onChangePerPage={(e) => {
                fn.getUsers({ ...params, per_page: e });
                setParams({
                  ...params,
                  per_page: e,
                });
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default UserManagement;
