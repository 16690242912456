type PropType = {
  url?: string;
  name?: string;
};

const ViewFileInput: React.FC<PropType> = (props) => {
  const { url, name } = props;

  return (
    <>
      <div className="mt-2 d-flex flex-wrap gap-2">
        <div className="selected_doc_wrapper">
          <a
            className="text-decoration-none"
            href={`${process.env.REACT_APP_IMAGE_URL}${url}`}
            target="_blank"
          >
            <p className="mb-0 lh-lg font12x500 textColor2">{name}</p>
          </a>
        </div>
      </div>
    </>
  );
};

export default ViewFileInput;
