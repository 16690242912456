import React, { useContext, useEffect, useState } from "react";
import { commonType } from "../../pages/main/lead";
import { CgClose } from "react-icons/cg";
import InputField from "../../shared components/TextInput";
import TextareaInput from "../../shared components/TextareaInput";
import DropdownOne from "../../shared components/Dropdown/DropdownOne";
import { modesMap } from "../../utils/constants";
import { useFormik } from "formik";
import { paymentSchema } from "../../utils/validationSchema";
import InputError from "../../shared components/InputError/InputError";
import { showToast } from "../../lib/toast/ShowToast";
import useLoading from "../../hooks/useLoading";
import InputDateForm from "../../shared components/DateInput/InputDateForm";
import SpinLoader from "../../shared components/Loader/SpinLoader";
import DropdownTwo from "../../shared components/Dropdown/DropdownTwo";
import { getExpense, getExpenseById } from "../../lib/api functions/expense";
import { PaymentObject } from "./type";
import moment from "moment";
import { createPayment } from "../../lib/api functions/payment";
import Skeleton from "react-loading-skeleton";
import { getClient, getClientById } from "../../lib/api functions/client";
import { MainContext } from "../../utils/private_routes/PrivateRoutes";
import { generateCompleteAddress, roundToTwo } from "../../function/other";
import { getDocumentNumberById } from "../../lib/api functions/common";
import { AiOutlineReload } from "react-icons/ai";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall?: (val: any) => void;
  params?: any;
};

const MadeForm: React.FC<propType> = (props) => {
  const { companyId, documentSetting } = useContext(MainContext);
  const { state, setState, apiCall, params } = props;
  const [loading, showLoader, hideLoader] = useLoading();
  const [expenses, setExpenses] = useState<any>([]);
  const [vendorLoading, showLoader2, hideLoader2] = useLoading();
  const [expenseloading, showLoader3, hideLoader3] = useLoading();
  const [vendorList, setVendorList] = useState<any>([]);

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
    getDocNumber: async (params?: any) => {
      const res = await getDocumentNumberById(companyId, params);
      if (res) {
        formik.setFieldValue(
          "number",
          res.data.documentNumber.payment.number.toString()
        );
      }
    },
    getAllVendors: async (params?: any) => {
      const res = await getClient(params);
      if (res) {
        setVendorList(res.data.clients);
      }
    },
    getClientById: async () => {
      showLoader2();
      const res = await getClientById(formik.values.vendor);
      if (res) {
        formik.setFieldValue(
          "vendorAddress",
          generateCompleteAddress(
            res.data?.client?.completeAddress?.address,
            res.data?.client?.completeAddress?.city,
            res.data?.client?.completeAddress?.state,
            res.data?.client?.completeAddress?.country,
            res.data?.client?.completeAddress?.pincode
          )
            .filter((item) => item?.length)
            .join(", ")
        );
        hideLoader2();
      }
    },
    getExpenses: async (params: any) => {
      const res = await getExpense(params);
      if (res) {
        setExpenses(res.data.expenses);
      }
    },
    getExpenseById: async () => {
      showLoader3();
      const res = await getExpenseById(formik.values.expense);
      if (res) {
        formik.setFieldValue(
          "amountDue",
          roundToTwo(
            res.data.expense.calculation.total - res.data.expense.paidAmount
          )
        );
        formik.setFieldValue("vendor", res.data.expense.vendor?._id);
        formik.setFieldValue("vendorName", res.data.expense.vendor?.name);
        hideLoader3();
      }
    },
  };

  const initialValues: PaymentObject = {
    vendorName: state?.data?.accountName ?? "",
    vendor: state?.data?.accountId ?? "",
    vendorAddress: "",
    number: state?.data?.singlePayment?.number ?? "",
    mode:
      state?.data?.singlePayment?.mode === 0
        ? state?.data?.singlePayment?.mode
        : state?.data?.singlePayment?.mode,
    modeName:
      state?.data?.singlePayment?.mode === 0
        ? modesMap.get(state?.data?.singlePayment?.mode)
        : modesMap.get(state?.data?.singlePayment?.mode),
    expenseNumber: state?.data?.docNumber ?? "",
    expense: state?.data?._id ?? "",
    amountDue: 0,
    date: state?.data?.singlePayment?.date
      ? moment(state?.data?.singlePayment?.date).format("YYYY-MM-DD")
      : "",
    amount: 0,
    description: state?.data?.singlePayment?.description ?? "",
    type: 2,
  };

  const formik = useFormik<PaymentObject>({
    initialValues,
    validationSchema: paymentSchema,
    onSubmit: async (values) => {
      let finalObj = {};

      finalObj = {
        type: values.type,
        expense: values.expense,
        number: values.number,
        date: values.date,
        mode: values.mode,
        amount: Number(values.amount),
        description: values.description,
      };

      showLoader();

      const res = await createPayment(finalObj, hideLoader);
      if (res.response && res.response.status === 502) {
        formik.setFieldError("number", "Payment number already taken");
      } else {
        showToast(`Payment received added`, "success");
        fn.setPositionToBody();
        //   typeof apiCall === "function" && apiCall   ();
        apiCall?.(params);
        hideLoader();
      }
    },
  });

  useEffect(() => {
    fn.getExpenses({
      company: companyId,
      f: "number",
      isDeleted: false,
    });
    fn.getDocNumber({ f: "payment" });
    fn.getAllVendors({
      company: companyId,
      f: "name",
      type: JSON.stringify([2, 3]),
    });
  }, []);
  useEffect(() => {
    if (formik.values.expense) {
      fn.getExpenseById();
    }
  }, [formik.values.expense]);
  useEffect(() => {
    if (formik.values.vendor) {
      fn.getClientById();
      fn.getExpenses({
        company: companyId,
        f: "number",
        vendor: formik.values.vendor,
        isDeleted: false,
      });
    }
  }, [formik.values.vendor]);

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">Add New Payment Made</h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <DropdownTwo
                    label="Vendor Name*"
                    placeholder="Select vendor"
                    name="vendorName"
                    data={vendorList}
                    state={formik.values.vendorName}
                    setState={(e?: string, x?: string | number) => {
                      formik.setFieldValue("vendorName", e);
                      formik.setFieldValue("vendor", x);
                    }}
                    isDisabled={state?.data ? true : false}
                    labelField="name"
                    onBlur={() => formik.setFieldTouched("vendorName", true)}
                  />
                  {formik.touched.vendorName && formik.errors.vendorName ? (
                    <InputError error={formik.errors.vendorName} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <label className="form-label font12x400 textColor3 mb-1">
                    Billing Address
                  </label>
                  <p className="mb-0 font12x600 textColor3">
                    {vendorLoading ? (
                      <Skeleton width="80px" borderRadius={4} />
                    ) : (
                      <>
                        {formik.values.vendorAddress
                          ? formik.values.vendorAddress
                          : "--"}
                      </>
                    )}
                  </p>
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="number"
                    className="form-label font12x400 textColor3 mb-1"
                  >
                    Payment No.*
                  </label>
                  <div className="input-group">
                    <div className="input-group-text font12x600 border-0">
                      {documentSetting?.payment?.initials !== ""
                        ? `${documentSetting?.payment?.initials}/`
                        : ""}
                    </div>
                    <input
                      type="text"
                      className="form-control shadow-none font12x600 border-0"
                      id="number"
                      value={formik.values.number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    {formik.touched.number && formik.errors.number ? (
                      <InputError error={formik.errors.number} />
                    ) : null}
                    {formik.touched.number && formik.errors.number ? (
                      <AiOutlineReload
                        className="cursor_pointer"
                        onClick={() => fn.getDocNumber({ f: "payment" })}
                      />
                    ) : null}
                  </div>
                </div>

                <div className="mb-3">
                  <DropdownOne
                    label="Mode*"
                    placeholder="Select method"
                    name="typeLabel"
                    data={modesMap}
                    state={formik.values.modeName}
                    setState={(e?: string, x?: string | number) => {
                      formik.setFieldValue("modeName", e);
                      formik.setFieldValue("mode", x);
                    }}
                    onBlur={() => formik.setFieldTouched("modeName", true)}
                  />
                  {formik.touched.modeName && formik.errors.modeName ? (
                    <InputError error={formik.errors.modeName} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <DropdownTwo
                    label="Expense Number*"
                    placeholder="Select expense number"
                    name="expenseNumber"
                    data={expenses}
                    state={formik.values.expenseNumber}
                    setState={(e?: string, x?: string | number) => {
                      formik.setFieldValue("expenseNumber", e);
                      formik.setFieldValue("expense", x);
                    }}
                    labelField="number"
                    isDisabled={state?.data ? true : false}
                    onBlur={() => formik.setFieldTouched("expenseNumber", true)}
                  />
                  {formik.touched.expenseNumber &&
                  formik.errors.expenseNumber ? (
                    <InputError error={formik.errors.expenseNumber} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <label className="form-label font12x400 textColor3 mb-1">
                    Amount Due*
                  </label>
                  <p className="mb-0 font12x600 textColor3">
                    {formik.values.amountDue}
                  </p>
                </div>

                <div className="container-fluid p-0">
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <InputDateForm
                          id="date"
                          label="Select Date*"
                          type="date"
                          state={moment(formik.values.date).format(
                            "YYYY-MM-DD"
                          )}
                          setState={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.date && formik.errors.date ? (
                          <InputError error={formik.errors.date} />
                        ) : null}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <InputField
                          id="amount"
                          label="Amount (₹)*"
                          type="tel"
                          name="amount"
                          state={formik.values.amount}
                          setState={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.amount && formik.errors.amount ? (
                          <InputError error={formik.errors.amount} />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <TextareaInput
                    id="description"
                    label="Description"
                    state={formik.values.description}
                    setState={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <InputError error={formik.errors.description} />
                  ) : null}
                </div>
              </div>

              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>
                <button className="btn font12x500 shadow-none custom_btn ms-3">
                  {state.data ? (
                    loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )
                  ) : loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>

            {/* {state.data?.data && (
              <UpdationDetail
                lastUpdate={state?.data?.item?.updatedAt}
                updatedBy={undefined}
              />
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MadeForm;
