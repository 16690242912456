import { useContext } from "react";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import ErrorPage from "../../common/ErrorPage";
import UserManagement from "./For Admin";

const User = () => {
  const { roles } = useContext(MainContext);

  return (
    <>
      {roles[0]?.userManagement.complete ? <UserManagement /> : <ErrorPage />}
    </>
  );
};
export default User;
