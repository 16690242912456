import moment from "moment";
import { useContext, useEffect, useState } from "react";
import LeaveApply from "../../../../../components/Attendance Components/Leave/LeaveApply";
import DataNotFound from "../../../../../components/Data Not Found";
import Pagination from "../../../../../components/Pagination";
import useLoading from "../../../../../hooks/useLoading";
import SkeletonLoader from "../../../../../shared components/Loader/SkeletonLoader";
import Search from "../../../../../shared components/Search";
import { MainContext } from "../../../../../utils/private_routes/PrivateRoutes";
import { commonType } from "../../../lead";
import { ParamType } from "../../type";
import {
  deleteLeaveById,
  getLeave,
} from "../../../../../lib/api functions/attendance/leave";
import { leaveStatusMap, leaveType } from "../../../../../utils/constants";
import { showToast } from "../../../../../lib/toast/ShowToast";
import PassSingleDate from "../../../../../Filters/PassSingleDate";
import ShowSelectedDate from "../../../../../shared components/DateInput/ShowSelectedDate";
import {
  getCurrentMonthAvailablePL,
  getNextMonthAvailablePL,
} from "../../../../../function/other";

const AttendanceLeave = () => {
  const { userId, companyId, roles, setting, updateUser } =
    useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const [loading, showLoader, hideLoader] = useLoading();
  const [leaves, setLeaves] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);
  const user = JSON.parse(window.localStorage.getItem("user") || "");

  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    company: companyId,
  });

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getLeaves: async (params?: ParamType) => {
      showLoader();
      const res = await getLeave(params);
      if (res) {
        setLeaves(res.data.leaves);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
    deleteLeaveById: async (id: string) => {
      const res = await deleteLeaveById(id);
      if (res) {
        showToast("Leave deleted.", "success");
        fn.getLeaves(params);
        await updateUser();
      }
    },
    addParam: (value: string) => {
      if (value === "resetFilter") {
        delete params?.type;
        fn.getLeaves?.({ ...params, page: 1 });
      } else {
        fn.getLeaves?.({ ...params, page: 1, type: value });
      }
    },
  };

  useEffect(() => {
    if (roles?.length !== 0) {
      if (!roles[0]?.attendance.complete) {
        params.user = userId;
        fn.getLeaves(params);
      } else {
        fn.getLeaves(params);
      }
    }
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="data_render_wrapper">
          {/* filters */}
          <div className="filter_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-12 pt-3 pt-md-0">
                  <div className="d-flex align-items-center gap-2 justify-content-start justify-content-md-end flex-wrap">
                    <div className="outline-div-wrapper">
                      <p className="mb-0 font12x500 textColor2">{`Available Current Month PL: ${getCurrentMonthAvailablePL(
                        user
                      )}`}</p>
                    </div>

                    <div className="outline-div-wrapper">
                      <p className="mb-0 font12x500 textColor2">{`Available Next Month PL: ${getNextMonthAvailablePL(
                        user
                      )}`}</p>
                    </div>

                    {params?.applicationDate && (
                      <>
                        <ShowSelectedDate
                          label="Application Date:"
                          value={moment(params?.applicationDate).format(
                            setting?.dateFormat
                          )}
                        />
                      </>
                    )}

                    <div className="d-flex align-items-center gap-2">
                      <PassSingleDate
                        state={params}
                        apiCall={fn.getLeaves}
                        setState={setParams}
                        fieldName="applicationDate"
                        label="Application date"
                      />

                      <button
                        className="btn font12x500 buttonOne shadow-none"
                        onClick={() => fn.openModal(undefined, "leave_apply")}
                      >
                        <img
                          src="/Assets/Svg/plusIcon.svg"
                          alt="icon"
                          className="me-2"
                        />
                        Apply Leave
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="list_view_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center pb-3">
                <div className="col-12 col-lg-3">
                  <span className="font16x600 textColor3">Leave List</span>
                </div>
                <div className="col-12 col-lg-9 pt-3 pt-lg-0">
                  <div className="d-flex justify-content-start justify-content-lg-end">
                    <div className="d-flex align-items-center">
                      <span className="font12x500 textColor3">Sort:</span>
                      <div className="ms-2">
                        <select
                          className="form-select font12x500 textColor3 shadow-none dropdown_filter"
                          onChange={(e) => {
                            fn.addParam(e.target.value);
                          }}
                        >
                          <option value="resetFilter">---Type---</option>
                          {leaveType?.map((item: any, i: number) => {
                            return (
                              <option key={i} value={item?.value}>
                                {item?.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* table */}
            {leaves?.length !== 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="font12x500 textColor3">
                        <th scope="col">#</th>
                        <th scope="col">Application Date</th>
                        <th scope="col">User Details</th>
                        <th scope="col">Applied Dates</th>
                        <th scope="col">Type</th>
                        <th scope="col">Reason</th>
                        <th scope="col">Status</th>
                        {!roles[0]?.attendance.complete && (
                          <th scope="col">Rejection Reason</th>
                        )}
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {leaves?.map((leave: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <th>
                              {(params.page - 1) * params.per_page + (i + 1)}
                            </th>
                            <td>
                              {moment(leave?.applicationDate).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td>
                              <span className="textColor2 mb-1">
                                {leave?.user?.name}
                              </span>
                              <br />
                              <span>{leave?.user?.customUserId}</span>
                            </td>
                            <td>
                              {leave?.dates?.map((item: string, i: number) => {
                                return (
                                  <p key={i} className="mb-0">
                                    {moment(item).format(setting?.dateFormat)}
                                  </p>
                                );
                              })}
                            </td>
                            <td>{leave?.type}</td>
                            <td
                              style={{
                                maxWidth: "250px",
                              }}
                            >
                              {leave?.reason}
                            </td>
                            <td>{leaveStatusMap.get(leave?.status)}</td>
                            {!roles[0]?.attendance.complete && (
                              <td
                                style={{
                                  maxWidth: "250px",
                                }}
                              >
                                {leave?.rejectionReason?.length
                                  ? leave?.rejectionReason
                                  : "--"}
                              </td>
                            )}
                            {roles[0]?.attendance.complete ||
                            (userId === leave?.user?._id &&
                              leave?.status === 0) ? (
                              <td>
                                <div className="d-flex align-items-center gap-3">
                                  {(roles[0]?.attendance.complete ||
                                    (userId === leave?.user?._id &&
                                      leave?.status === 0)) && (
                                    <div
                                      className="d-flex align-items-center cursor_pointer"
                                      onClick={() =>
                                        fn.openModal({ leave }, "leave_apply")
                                      }
                                    >
                                      <img
                                        src="/Assets/Svg/editIcon.svg"
                                        alt="edit"
                                      />
                                      <p className="mb-0 font12x400 textColor2 ms-1">
                                        Edit
                                      </p>
                                    </div>
                                  )}

                                  {userId === leave?.user?._id &&
                                    leave?.status === 0 && (
                                      <div
                                        className="cursor_pointer"
                                        onClick={() =>
                                          fn.deleteLeaveById(leave?._id)
                                        }
                                      >
                                        <img
                                          src="/Assets/Svg/deleteColor.svg"
                                          alt="close"
                                          className="img-fluid"
                                          draggable={false}
                                        />
                                      </div>
                                    )}
                                </div>
                              </td>
                            ) : (
                              <td>--</td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : loading ? (
              <SkeletonLoader />
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>

        {/* pagination */}
        {leaves?.length !== 0 && (
          <div className="pagination_wrapper pt-3 px-3">
            <Pagination
              currentPage={params.page}
              onPageChange={(e) => {
                fn.getLeaves({
                  ...params,
                  page: e,
                });
                setParams({
                  ...params,
                  page: e,
                });
              }}
              pageSize={params.per_page}
              totalCount={Math.ceil(totalResult / params.per_page)}
              onChangePerPage={(e) => {
                fn.getLeaves({
                  ...params,
                  per_page: e,
                });
                setParams({
                  ...params,
                  per_page: e,
                });
              }}
            />
          </div>
        )}
      </div>

      {/* apply leave modal */}
      {commonData?.modal && commonData?.check === "leave_apply" && (
        <LeaveApply
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getLeaves}
          params={params}
        />
      )}
    </>
  );
};

export default AttendanceLeave;
