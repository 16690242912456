import moment from "moment";
import * as Yup from "yup";
import {
  checkFileAllFormat,
  checkFileFormat,
  checkFileFormatPDF,
  checkFileSize,
} from "../function/other";

export const emailLoginSchema = Yup.object().shape({
  email: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required")
    // .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email"),
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/, "Invalid email"),
  password: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
});

export const otpLoginSchema = Yup.object().shape({
  mobile: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required")
    .matches(/^[0-9 ]*$/, "Must contain only digits"),
  phoneCode: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Phone code is required"),
});

export const clientSchema = Yup.object().shape({
  name: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  contactNumber: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional()
    // .required("Required")
    .matches(/^[0-9 ]*$/, "Must contain only digits"),
  phoneCode: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    // .required("Phone code is required")
    .optional(),
  email: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    // .optional()
    // .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email"),
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/, "Invalid email"),
  website: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional()
    .url("Invalid website url"),
  gstType: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  gstin: Yup.string()
    .when("gstType", {
      is: "Registered - Regular GST",
      then: Yup.string()
        .trim("Must not include white spaces")
        .strict(true)
        .required("Required")
        .length(15, "GSTIN must be of length 15")
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          "Invalid GSTIN"
        ),
    })
    .when("gstType", {
      is: "Special Economic Zone (SEZ)",
      then: Yup.string()
        .trim("Must not include white spaces")
        .strict(true)
        .required("Required")
        .length(15, "GSTIN must be of length 15")
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          "Invalid GSTIN"
        ),
    })
    .when("gstType", {
      is: "Deemed Export",
      then: Yup.string()
        .trim("Must not include white spaces")
        .strict(true)
        .required("Required")
        .length(15, "GSTIN must be of length 15")
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          "Invalid GSTIN"
        ),
    }),
  pan: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional()
    .length(10, "Length must be 10")
    .matches(
      /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
      "Invalid PAN number. Format: ABCDE1234Z"
    ),
  currency: Yup.string().trim("Must not include white spaces").strict(true),
  // .required("Required"),
  completeAddress: Yup.object().shape({
    address: Yup.string().trim("Must not include white spaces").strict(true),
    // .required("Required"),
    state: Yup.string()
      .trim("Must not include white spaces")
      .strict(true)
      .required("Required"),
    city: Yup.string().trim("Must not include white spaces").strict(true),
    // .required("Required"),
    pincode: Yup.string()
      .trim("Must not include white spaces")
      .strict(true)
      // .required("Required")
      .matches(/^[0-9]{4,6}$/, "Must contain 4 to 6 digits"),
    country: Yup.string().trim("Must not include white spaces").strict(true),
    // .required("Required"),
  }),
});

export const leadStepOneSchema = Yup.object().shape({
  name: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  contactNumber: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    // .required("Required")
    .matches(/^[0-9 ]*$/, "Must contain only digits"),
  phoneCode: Yup.string().trim("Must not include white spaces").strict(true),
  // .required("Required"),
  email: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional()
    // .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email"),
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/, "Invalid email"),
  website: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional()
    .url("Invalid website url"),
  gstType: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  gstin: Yup.string()
    .when("gstType", {
      is: "Registered - Regular GST",
      then: Yup.string()
        .trim("Must not include white spaces")
        .strict(true)
        .required("Required")
        .length(15, "GSTIN must be of length 15")
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          "Invalid GSTIN"
        ),
    })
    .when("gstType", {
      is: "Special Economic Zone (SEZ)",
      then: Yup.string()
        .trim("Must not include white spaces")
        .strict(true)
        .required("Required")
        .length(15, "GSTIN must be of length 15")
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          "Invalid GSTIN"
        ),
    })
    .when("gstType", {
      is: "Deemed Export",
      then: Yup.string()
        .trim("Must not include white spaces")
        .strict(true)
        .required("Required")
        .length(15, "GSTIN must be of length 15")
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          "Invalid GSTIN"
        ),
    }),
  pan: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional()
    .length(10, "Length must be 10")
    .matches(
      /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
      "Invalid PAN number. Format: ABCDE1234Z"
    ),
  currency: Yup.string().trim("Must not include white spaces").strict(true),
  // .required("Required"),
});

export const leadStepTwoSchema = Yup.object().shape({
  address: Yup.string().trim("Must not include white spaces").strict(true),
  // .required("Required"),
  state: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  city: Yup.string().trim("Must not include white spaces").strict(true),
  // .required("Required"),
  pincode: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    // .required("Required")
    .matches(/^[0-9]{4,6}$/, "Must contain 4 to 6 digits"),
  country: Yup.string().trim("Must not include white spaces").strict(true),
  // .required("Required"),
});

export const leadStepThreeSchema = Yup.object().shape({
  name: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  mobile: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required")
    .matches(/^[0-9 ]*$/, "Must contain only digits"),
  phoneCode: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  email: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional()
    // .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email"),
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/, "Invalid email"),
});

export const itemSchema = Yup.object().shape({
  name: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  description: Yup.string().trim("Must not include white spaces").strict(true),
  // .required("Required"),
  typeLabel: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  unitAmount: Yup.number().required("Required").positive("Invalid amount"),
  unit: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  stock: Yup.number().when("typeLabel", {
    is: "Product",
    then: Yup.number()
      .required("Required")
      .min(1)
      .positive("Must contain positive value"),
  }),
  tax: Yup.string().required("Required"),
  hsn: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .when("typeLabel", {
      is: "Product",
      then: Yup.string()
        .trim("Must not include white spaces")
        .strict(true)
        .required("Required"),
    })
    .matches(/^[0-9]*$/, "Must contain only numbers")
    .min(6, "Minimum digits is 6 & maximum digits is 8")
    .max(8, "Minimum digits is 6 & maximum digits is 8"),
  sac: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .when("typeLabel", {
      is: "Service",
      then: Yup.string()
        .trim("Must not include white spaces")
        .strict(true)
        .required("Required"),
    })
    .matches(/^[0-9]{6}$/, "Must contain 6 digits"),
});

export const proposalSchema = Yup.object().shape({
  proposalNumber: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  issueDate: Yup.date().required("Required"),
  validTill: Yup.date()
    .required("Required")
    .test(
      "Till date must be greater than issue date",
      "Till date must be greater than issue date",
      (value, context) => {
        return moment(value, "YYYY-MM-DD").isSameOrAfter(
          moment(context.parent.issueDate, "YYYY-MM-DD")
        );
      }
    ),
  placeOfSupply: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  items: Yup.array().min(1, "Please add at least one item"),
});

export const updateSchema = Yup.object().shape({
  title: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  description: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional(),
  attachments: Yup.array()
    .optional()
    .nullable()
    .max(5, "Maximum limit is 5")
    .test(
      "is-correct-file-size",
      "File size must be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is PDF",
      checkFileFormatPDF
    ),
});

export const quotationSchema = Yup.object().shape({
  title: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  description: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  attachments: Yup.array()
    .optional()
    .test(
      "is-correct-file-size",
      "File size must be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is PDF",
      checkFileFormatPDF
    ),
});

export const meetingSchema = Yup.object().shape({
  title: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  description: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  date: Yup.date().required("Required"),
  startTime: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  endTime: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional(),
});

export const POSchema = Yup.object().shape({
  title: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  attachments: Yup.array()
    .optional()
    .test(
      "is-correct-file-size",
      "File size must be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is PDF",
      checkFileFormatPDF
    ),
});

export const invoiceSchema = Yup.object().shape({
  clientName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  invoiceNumber: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  invoiceDate: Yup.date().required("Required"),
  dueDate: Yup.date()
    .required("Required")
    .test(
      "Due date must be greater than invoice date",
      "Due date must be greater than invoice date",
      (value, context) => {
        return moment(value, "YYYY-MM-DD").isSameOrAfter(
          moment(context.parent.invoiceDate, "YYYY-MM-DD")
        );
      }
    ),
  items: Yup.array().min(1, "Please add at least one item"),
});

export const taskSchema = Yup.object().shape({
  name: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  dueDate: Yup.date().required("Required"),
  endTime: Yup.string().optional(),
  priorityName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  department: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  taskDescription: Yup.string()
    .trim("Must not include white spaces")
    .strict(true),
  // .required("Required"),
  assignTo: Yup.array().min(1, "Assigned atleast one user"),
  tag: Yup.string().trim("Must not include white spaces").strict(true),
  // tags: Yup.array().min(1, "Required atleast one tag"),
  attachments: Yup.array()
    .optional()
    .nullable()
    .max(5, "Maximum limit is 5")
    .test(
      "is-correct-file-size",
      "File size must be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is PDF",
      checkFileFormatPDF
    ),
});

export const todoSchema = Yup.object().shape({
  title: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  priorityName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  taskDescription: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
});

export const reminderSchema = Yup.object().shape({
  title: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  date: Yup.date().required("Required"),
  time: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  priorityName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  taskDescription: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
});

export const leadInvoiceSchema = Yup.object().shape({
  invoiceNumber: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
});

export const requestSchema = Yup.object().shape({
  title: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  notes: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional(),
  typeLabel: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  qty: Yup.number().required("Required"),
  itemName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
});

export const paymentSchema = Yup.object().shape({
  clientName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .when("type", {
      is: 1,
      then: Yup.string()
        .trim("Must not include white spaces")
        .strict(true)
        .required("Required"),
    }),
  vendorName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .when("type", {
      is: 2,
      then: Yup.string()
        .trim("Must not include white spaces")
        .strict(true)
        .required("Required"),
    }),
  number: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  description: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional(),
  modeName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  amount: Yup.number()
    .required("Required")
    .positive("Amount invalid")
    .test(
      "Amount must be less than or equal to Amount due",
      "Amount must be less than or equal to Amount due",
      (value, context) => {
        return Number(value) <= context.parent.amountDue;
      }
    ),
  invoiceNumber: Yup.string().when("type", {
    is: 1,
    then: Yup.string()
      .trim("Must not include white spaces")
      .strict(true)
      .required("Required"),
  }),
  expenseNumber: Yup.string().when("type", {
    is: 2,
    then: Yup.string()
      .trim("Must not include white spaces")
      .strict(true)
      .required("Required"),
  }),
  date: Yup.date().required("Required"),
});

export const creditNoteSchema = Yup.object().shape({
  clientName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  creditNoteNumber: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  creditNoteDate: Yup.date().required("Required"),
  invoiceNumber: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  reason: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  items: Yup.array().min(1, "Please add at least one item"),
});

export const attendanceSchema = Yup.object().shape({
  attendanceUser: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  monthName: Yup.string().required("Required"),
  year: Yup.string().required("Required"),
  daysPresent: Yup.number()
    .required("Required")
    .when("monthName", {
      is: "January",
      then: Yup.number()
        .min(0, "Enter days between 0 to 31")
        .max(31, "Enter days between 0 to 31"),
    })
    .when("monthName", {
      is: "February",
      then: Yup.number()
        .min(0, "Enter days between 0 to 28")
        .max(28, "Enter days between 0 to 28"),
    })
    .when("isLeapYear", {
      is: true,
      then: Yup.number()
        .min(0, "Enter days between 0 to 29")
        .max(29, "Enter days between 0 to 29"),
    })
    .when("monthName", {
      is: "March",
      then: Yup.number()
        .min(0, "Enter days between 0 to 31")
        .max(31, "Enter days between 0 to 31"),
    })
    .when("monthName", {
      is: "April",
      then: Yup.number()
        .min(0, "Enter days between 0 to 30")
        .max(30, "Enter days between 0 to 30"),
    })
    .when("monthName", {
      is: "May",
      then: Yup.number()
        .min(0, "Enter days between 0 to 31")
        .max(31, "Enter days between 0 to 31"),
    })
    .when("monthName", {
      is: "June",
      then: Yup.number()
        .min(0, "Enter days between 0 to 30")
        .max(30, "Enter days between 0 to 30"),
    })
    .when("monthName", {
      is: "July",
      then: Yup.number()
        .min(0, "Enter days between 0 to 31")
        .max(31, "Enter days between 0 to 31"),
    })
    .when("monthName", {
      is: "August",
      then: Yup.number()
        .min(0, "Enter days between 0 to 31")
        .max(31, "Enter days between 0 to 31"),
    })
    .when("monthName", {
      is: "September",
      then: Yup.number()
        .min(0, "Enter days between 0 to 30")
        .max(30, "Enter days between 0 to 30"),
    })
    .when("monthName", {
      is: "October",
      then: Yup.number()
        .min(0, "Enter days between 0 to 31")
        .max(31, "Enter days between 0 to 31"),
    })
    .when("monthName", {
      is: "November",
      then: Yup.number()
        .min(0, "Enter days between 0 to 30")
        .max(30, "Enter days between 0 to 30"),
    })
    .when("monthName", {
      is: "December",
      then: Yup.number()
        .min(0, "Enter days between 0 to 31")
        .max(31, "Enter days between 0 to 31"),
    }),
  paidSalary: Yup.number()
    .required("Required")
    .min(0)
    .positive("Enter only positive number"),
  netPay: Yup.number()
    .required("Required")
    .min(0)
    .positive("Enter only positive number"),
});

export const userDetailsSchema = Yup.object().shape({
  image: Yup.array()
    .optional()
    .nullable()
    .max(1, "Maximum limit is 1")
    .test(
      "is-correct-file-size",
      "File size must be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is PNG, JPEG",
      checkFileFormat
    ),
  name: Yup.string()
    .required("Required")
    .trim("Must not include white spaces")
    .strict(true)
    .matches(/^[A-z ]*$/, "Must contain only letters"),
  employeeId: Yup.string()
    .required("Required")
    .trim("Must not include white spaces")
    .strict(true),
  address: Yup.string().trim("Must not include white spaces").strict(true),
  email: Yup.string()
    .required("Required")
    .trim("Must not include white spaces")
    .strict(true)
    // .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email"),
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/, "Invalid email"),
  mobile: Yup.string()
    .required("Required")
    .trim("Must not include white spaces")
    .strict(true)
    .matches(/^[0-9 ]*$/, "Must contain only digits"),
  phoneCode: Yup.string()
    .required("Required")
    .trim("Must not include white spaces")
    .strict(true),
  emergencyNumber: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .matches(/^[0-9 ]*$/, "Must contain only digits"),
  bloodGroup: Yup.string().trim("Must not include white spaces").strict(true),
  pan: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .length(10, "Length must be 10")
    .matches(
      /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
      "Invalid PAN number. Format: ABCDE1234Z"
    ),
  aadharNumber: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .length(12, "Length must be 12")
    .matches(/^[0-9 ]*$/, "Invalid aadhaar number. Format: 123456789012"),
});

export const userBankDetailsSchema = Yup.object().shape({
  bankName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional()
    .matches(/^[A-z ]*$/, "Must contain only letters"),
  accNumber: Yup.string()
    .trim("Must not include white spaces")
    .strict(true) // .matches(/^\d{9,18}$/, "Enter valid account number")
    .min(9, "Account number must be at least 9 to 18 digits")
    .max(18, "Account number must be at least 9 to 18 digits")
    .optional(),
  branchName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional()
    .matches(/^[A-z ]*$/, "Must contain only letters"),
  ifsc: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .length(11, "IFSC code must be of length 11")
    .matches(
      /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/,
      "First 4 characters must be alphabets and last 7 characters must be numbers"
    )
    .optional(),
});

export const userOfficeDetailsSchema = Yup.object().shape({
  doj: Yup.date().required("Required"),
  department: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  designation: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  salaryType: Yup.string().required("Required"),
  salary: Yup.number()
    .min(0)
    .positive("Enter only positive number")
    .required("Required"),
});

export const userDocDetailsSchema = Yup.object().shape({});

export const userAttendanceDetailsSchema = Yup.object().shape({
  startTime: Yup.string().nullable(),
  halfTime: Yup.string().nullable(),
  endTime: Yup.string()
    .nullable()
    .test(
      "End time must be greater than start time",
      "End time must be greater than start time",
      (value, context) => {
        return (
          +moment(context.parent.startTime, "HH:mm").format("HH") -
            +moment(value, "HH:mm").format("HH") <
          0
        );
      }
    ),
  maxHours: Yup.number()
    .min(0, "Max hours must be greater than or equal to 0")
    .max(24, "Max hours must be less than or equal to 24"),
  pLHours: Yup.number()
    .min(0, "Paid hours must be greater than or equal to 0")
    .max(24, "Paid hours must be less than or equal to 24"),
  numberOfPL: Yup.number(),
  pLCarryForward: Yup.string(),
});

export const userSalarySlipDetailsSchema = Yup.object().shape({
  slipLabels: Yup.array().min(1, "Please add at least one label"),
});

export const userRolesDetailsSchema = Yup.object().shape({
  customUserId: Yup.string().trim("Must not include white spaces").strict(true),
  name: Yup.string()
    .when("isEdit", {
      is: false,
      then: Yup.string()
        .required("Required")
        .trim("Must not include white spaces")
        .strict(true)
        .matches(/^[A-z ]*$/, "Must contain only letters"),
    })
    .trim("Must not include white spaces")
    .strict(true)
    .matches(/^[A-z ]*$/, "Must contain only letters"),
  employeeId: Yup.string()
    .when("isEdit", {
      is: false,
      then: Yup.string()
        .required("Required")
        .trim("Must not include white spaces")
        .strict(true),
    })
    .trim("Must not include white spaces")
    .strict(true),
  email: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    // .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email")
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/, "Invalid email")
    .when("isEdit", {
      is: false,
      then: Yup.string()
        .required("Required")
        .trim("Must not include white spaces")
        .strict(true)
        // .matches(
        //   /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        //   "Invalid email"
        // ),
        .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/, "Invalid email"),
    }),
  mobile: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .matches(/^[0-9 ]*$/, "Must contain only digits")
    .when("isEdit", {
      is: false,
      then: Yup.string()
        .required("Required")
        .trim("Must not include white spaces")
        .strict(true)
        .matches(/^[0-9 ]*$/, "Must contain only digits"),
    }),
  password: Yup.string().when("isEdit", {
    is: false,
    then: Yup.string()
      .trim("Must not include white spaces")
      .strict(true)
      .min(8, "Minimum characters is 8 & maximum characters is 16")
      .max(16, "Minimum characters is 8 & maximum characters is 16")
      .required("Required"),
  }),
  role: Yup.string().when("isEdit", {
    is: false,
    then: Yup.string().required("Required"),
  }),
});

export const passwordChangeForUserSchema = Yup.object().shape({
  password: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .min(8, "Minimum characters is 8 & maximum characters is 16")
    .max(16, "Minimum characters is 8 & maximum characters is 16")
    .required("Required"),
  confirmPassword: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .min(8, "Minimum characters is 8 & maximum characters is 16")
    .max(16, "Minimum characters is 8 & maximum characters is 16")
    .required("Required")
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    // )
    .oneOf([Yup.ref("password"), null], "Password not matched"),
});

export const roleSchema = Yup.object().shape({
  name: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
});
export const departmentSchema = Yup.object().shape({
  name: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
});

export const taxSchema = Yup.object().shape({
  name: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  percent: Yup.number().required("Required"),
});

export const gstSchema = Yup.object().shape({
  percent: Yup.number().required("Required"),
});

export const companySchema = Yup.object().shape({
  name: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  address: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional(),
  country: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  state: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  city: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  pincode: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional()
    .matches(/^[0-9]{4,6}$/, "Must contain 4 to 6 digits"),
  website: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .url("Invalid website url")
    .optional(),
  contactName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  emailAddress: Yup.string()
    .required("Required")
    .trim("Must not include white spaces")
    .strict(true)
    // .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email"),
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/, "Invalid email"),
  phoneNumber: Yup.string()
    .required("Required")
    .trim("Must not include white spaces")
    .strict(true)
    .matches(/^[0-9 ]*$/, "Must contain only digits"),
  phoneCode: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Phone code is required"),
  bankName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional()
    .matches(/^[A-z ]*$/, "Must contain only letters"),
  accountNumber: Yup.string()
    .trim("Must not include white spaces")
    .strict(true) // .matches(/^\d{9,18}$/, "Enter valid account number")
    .min(9, "Account number must be at least 9 to 18 digits")
    .max(18, "Account number must be at least 9 to 18 digits")
    .optional(),
  branchName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional(),
  ifscCode: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .length(11, "IFSC code must be of length 11")
    .matches(
      /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/,
      "First 4 characters must be alphabets and last 7 characters must be numbers"
    )
    .optional(),
  swiftCode: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    // .min(9, "SWIFT code must be at least 8 to 11 digits")
    // .max(18, "SWIFT code must be at least 8 to 11 digits")
    // .matches(
    //   /^[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}[a-zA-Z0-9]{3}$/,
    //   "Format: AAAA-BB-CC/12-DDD/123"
    // )
    .optional(),
  adCode: Yup.string()
    .matches(/^[0-9]*$/, "Must contain only numbers")
    .length(14, "AD code must be of length 14")
    .optional(),
  taxationType: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional(),
  gstin: Yup.string()
    .when("taxationType", {
      is: "GST",
      then: Yup.string()
        .trim("Must not include white spaces")
        .strict(true)
        .required("Required")
        .length(15, "GSTIN must be of length 15")
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          "Invalid GSTIN"
        ),
    })
    .when("taxationType", {
      is: "Composition Scheme",
      then: Yup.string()
        .trim("Must not include white spaces")
        .strict(true)
        .required("Required")
        .length(15, "GSTIN must be of length 15")
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          "Invalid GSTIN"
        ),
    }),
  serviceTaxNumber: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .length(15, "Service tax number must be of length 15")
    .optional(),
  pan: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional()
    .length(10, "PAN number must be of length 15")
    .matches(
      /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
      "Invalid PAN number. Format: ABCDE1234Z"
    ),
  vat: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional(),
  tan: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .length(10, "TAN number must be of length 10")
    .matches(
      /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/,
      "First 4 characters must be capital letter, 5th to the 9th digit is numerical and last digit is capital letter"
    )
    .optional(),
});

export const generalSettingSchema = Yup.object().shape({
  primarySignature: Yup.array()
    .optional()
    .nullable()
    .max(1, "Maximum limit is 1")
    .test(
      "is-correct-file-size",
      "File size must be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is PNG, JPEG",
      checkFileFormat
    ),
  secondarySignature: Yup.array()
    .optional()
    .nullable()
    .max(1, "Maximum limit is 1")
    .test(
      "is-correct-file-size",
      "File size must be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is PNG, JPEG",
      checkFileFormat
    ),
});

export const changePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    // .min(8, "Minimum characters is 8 & maximum characters is 16")
    // .max(16, "Minimum characters is 8 & maximum characters is 16")
    .required("Required"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  // ),
  newPassword: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .min(8, "Minimum characters is 8 & maximum characters is 16")
    .max(16, "Minimum characters is 8 & maximum characters is 16")
    .required("Required"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  // )
  confirmPassword: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .min(8, "Minimum characters is 8 & maximum characters is 16")
    .max(16, "Minimum characters is 8 & maximum characters is 16")
    .required("Required")
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    // )
    .oneOf([Yup.ref("newPassword"), null], "Password not matched"),
});

export const expenseSchema = Yup.object().shape({
  vendorName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  clientName: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional(),
  number: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  date: Yup.date().required("Required"),
  dueDate: Yup.date()
    .required("Required")
    .test(
      "Due date must be greater than expense date",
      "Due date must be greater than expense date",
      (value, context) => {
        return moment(value, "YYYY-MM-DD").isSameOrAfter(
          moment(context.parent.date, "YYYY-MM-DD")
        );
      }
    ),
  invoiceNumber: Yup.string()
    .trim("Must not include white spaces")
    .strict(true),
  // .required("Required"),
  placeOfSupply: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  items: Yup.array().min(1, "Please add at least one item"),
});

export const credentialSchema = Yup.object().shape({
  title: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  userId: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  loginLink: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  password: Yup.string().required("Required"),
  type: Yup.number().required("Required"),
});

export const salaryConstraintSchema = Yup.object().shape({
  requiredHours: Yup.number()
    .required("Required")
    .positive("Enter only positive number"),
  requiredDays: Yup.number()
    .required("Required")
    .positive("Enter only positive number"),
  holidays: Yup.number()
    .required("Required")
    .positive("Enter only positive number")
    .min(0),
});

export const employeeSchema = Yup.object().shape({
  maxHours: Yup.number()
    .required("Required")
    .min(0, "Max hours must be greater than or equal to 0")
    .max(24, "Max hours must be less than or equal to 24"),
  pLHours: Yup.number()
    .required("Required")
    .min(0, "Paid hours must be greater than or equal to 0")
    .max(24, "Paid hours must be less than or equal to 24"),
  numberOfPL: Yup.number().required("Required"),
  pLCarryForward: Yup.string().required("Required"),
});

export const applyLeaveSchema = Yup.object().shape({
  dates: Yup.array().min(1, "Please add at least one date"),
  type: Yup.string().required("Required"),
  reason: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  statusName: Yup.string().when("isEdit", {
    is: true,
    then: Yup.string()
      .trim("Must not include white spaces")
      .strict(true)
      .required("Required"),
  }),
  rejectionReason: Yup.string().when("status", {
    is: 2,
    then: Yup.string()
      .trim("Must not include white spaces")
      .strict(true)
      .required("Required"),
  }),
});

export const requestInvoiceSchema = Yup.object().shape({
  title: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  number: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .required("Required"),
  description: Yup.string()
    .trim("Must not include white spaces")
    .strict(true)
    .optional(),
});

export const salarySlipSchema = Yup.object().shape({
  slipLabels: Yup.array().min(1, "Please add at least one label"),
});

export const locationSchema = Yup.object().shape({
  lat: Yup.number().required("Required"),
  lng: Yup.number().required("Required"),
  validDistance: Yup.number()
    .required("Required")
    .positive("Enter only positive number"),
});

export const createAttendanceSchema = Yup.object().shape({
  date: Yup.date().required("Required"),
  attendanceType: Yup.string().required("Required"),
});

export const stockSchema = Yup.object().shape({
  count: Yup.number()
    .required("Required")
    .positive("Enter only positive number"),
});
