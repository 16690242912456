import React, { useState } from "react";
import useLoading from "../../../../hooks/useLoading";
import { commonType } from "../../../../pages/main/lead";
import SpinLoader from "../../../../shared components/Loader/SpinLoader";
import InputField from "../../../../shared components/TextInput";
import UpdationDetail from "../../../Data Update Detail";
import { CgClose } from "react-icons/cg";
import { gstSchema } from "../../../../utils/validationSchema";
import { useFormik } from "formik";
import InputError from "../../../../shared components/InputError/InputError";
import { showToast } from "../../../../lib/toast/ShowToast";
import {
  createTax,
  updateTax,
} from "../../../../lib/api functions/setting/tax";
import { GstType } from "../../../../pages/main/setting/sub pages/gst/type";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall?: (val: any) => void;
  params: any;
};

const Form: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params } = props;
  const [loading, showLoader, hideLoader] = useLoading();
  const [updatedValues, setUpdatedValues] = useState<any>({
    isObjChange: false,
  });

  const fn = {
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
  };

  const initialValues: GstType = {
    percent: state?.data?.tax?.percent ?? 0,
  };

  const formik = useFormik<GstType>({
    initialValues,
    validationSchema: gstSchema,
    onSubmit: async (values) => {
      let finalObj = {};
      delete updatedValues.isObjChange;
      if (state?.data) {
        finalObj = {
          ...updatedValues,
          type: 1,
        };
      } else {
        finalObj = {
          ...updatedValues,
          type: 1,
        };
      }
      showLoader();
      if (state.data) {
        const res = await updateTax(finalObj, state.data.tax._id, hideLoader);
        if (res) {
          showToast(`GST tax updated`, "success");
          fn.setPositionToBody();
          apiCall?.(params);
          hideLoader();
        }
      } else {
        const res = await createTax(finalObj, hideLoader);
        if (res) {
          showToast(`GST tax created`, "success");
          fn.setPositionToBody();
          apiCall?.(params);
          hideLoader();
        }
      }
    },
  });

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <h1 className="font16x600 mb-0">
              {state.data ? "Edit Details" : "Add New Tax"}
            </h1>
            <span onClick={fn.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="form_content_section">
            <form onSubmit={formik.handleSubmit}>
              <div className="form_fields_wrapper">
                <div className="mb-3">
                  <InputField
                    id="percent"
                    label="Percent (%)"
                    type="text"
                    state={formik.values.percent}
                    setState={(e) => {
                      formik.setFieldValue("percent", e.target.value);
                      setUpdatedValues({
                        ...updatedValues,
                        percent: e.target.value,
                        isObjChange: true,
                      });
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.percent && formik.errors.percent ? (
                    <InputError error={formik.errors.percent} />
                  ) : null}
                </div>
              </div>
              <div className="form_button_wrapper d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  className="btn font12x500 shadow-none modalBtnOutline"
                  onClick={fn.setPositionToBody}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn font12x500 shadow-none custom_btn ms-3"
                  disabled={updatedValues?.isObjChange ? false : true}
                >
                  {state.data ? (
                    loading ? (
                      <SpinLoader height="20" width="20" color="#fff" />
                    ) : (
                      "Update"
                    )
                  ) : loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>

            {state.data && (
              <UpdationDetail
                lastUpdate={state?.data?.tax?.updatedAt}
                updatedBy={undefined}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
