import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import useLoading from "../../hooks/useLoading";
import { updateTask } from "../../lib/api functions/task";
import { showToast } from "../../lib/toast/ShowToast";

type PropType = {
  data?: any;
  color?: string;
  label?: string;
  id?: string;
  check?: string;
  apiCall: (val: any) => void;
  params: any;
  status: number;
};

const UpdateData: React.FC<PropType> = (props) => {
  const { data, color, label, id, check, apiCall, params, status } = props;
  const [loading, showLoader, hideLoader] = useLoading();
  const [isOpen, setIsOpen] = useState(false);

  const fn = {
    openDropdown: () => {
      setIsOpen(!isOpen);
    },
    closeDropdown: () => {
      setIsOpen(false);
    },
    callingApi: async (label: string, value: number) => {
      // setPostData({
      //   value,
      //   label,
      // });

      const formData = new FormData();
      if (check === "Status") {
        formData.append("status", value + "");
      } else {
        formData.append("priority", value + "");
      }

      const res = await updateTask(formData, id ?? "", hideLoader);
      if (res) {
        showToast(`Task updated`, "success");
        apiCall(params);
      }
    },
  };

  return (
    <>
      <OutsideClickHandler onOutsideClick={fn.closeDropdown}>
        <div
          className="color_dropdown_wrapper"
          onClick={fn.openDropdown}
          style={{ border: `1px solid ${color}` }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <p
              className="mb-0 me-2 font10x400 textColor3"
              style={{ color: `${color}` }}
            >
              {label}
            </p>
            <img
              src="/Assets/Svg/dropdownIcon.svg"
              alt="icon"
              width={8}
              height={8}
            />
          </div>
          {isOpen && (
            <div className="color_dropdown_menus_section">
              {data?.map((item: any, i: number) => {
                return (
                  <div
                    key={i}
                    className="color_dropdown_menus"
                    onClick={() => fn.callingApi(item?.label, item?.value)}
                  >
                    <span
                      className="font10x400"
                      style={{
                        color: status === item?.value ? color : "",
                      }}
                    >
                      {item?.label}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default UpdateData;
