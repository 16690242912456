import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

type proptype = {
  path: string;
  label: string;
  isLeadApprove?: boolean;
};

const Menu: React.FC<proptype> = (props) => {
  const { path, label, isLeadApprove } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <div
        className={
          pathname.includes(path)
            ? "details_single_menu_active"
            : "details_single_menu"
        }
        style={{
          pointerEvents: isLeadApprove ? "none" : "unset",
        }}
        onClick={() => navigate(path)}
      >
        <div className="details_menu">
          <span
            className={
              pathname.includes(path)
                ? "font14x500 textColor2"
                : "font14x500 textColor1"
            }
            style={{
              color: isLeadApprove ? "var(--bg_color6)" : "",
            }}
          >
            {label}
          </span>
        </div>
        {pathname.includes(path) && (
          <img src={`/Assets/Svg/menuActiveColor.svg`} alt="menu_active" />
        )}
      </div>
    </>
  );
};

export default Menu;
