import React from "react";
import { useNavigate } from "react-router-dom";

type propType = {
  url: string;
  title: string;
};

const DeleteFilter: React.FC<propType> = (props) => {
  const { url, title } = props;
  const navigate = useNavigate();

  return (
    <>
      <div
        title={title}
        className="filter_section_wrapper"
        onClick={() => navigate(url)}
        style={{
          backgroundColor: "var(--error)",
        }}
      >
        <img src="/Assets/Svg/delete.svg" alt="filter" />
      </div>
    </>
  );
};

export default DeleteFilter;
