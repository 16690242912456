import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataNotFound from "../../../components/Data Not Found";
import Pagination from "../../../components/Pagination";
import CalendarFilter from "../../../Filters/CalendarFilter";
import useLoading from "../../../hooks/useLoading";
import { getPayment } from "../../../lib/api functions/payment";
import SkeletonLoader from "../../../shared components/Loader/SkeletonLoader";
import Search from "../../../shared components/Search";
import {
  DELETE_PAYMENT,
  modesMap,
  paymentTypeMap,
} from "../../../utils/constants";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import { commonType } from "../lead";
import { ParamType } from "./type";
import OutsideClickHandler from "react-outside-click-handler";
import ReceivedForm from "../../../components/Payment Component/ReceivedForm";
import MadeForm from "../../../components/Payment Component/MadeForm";
import ShowSelectedDate from "../../../shared components/DateInput/ShowSelectedDate";
import DeleteModal from "../../../components/Trashed Modal/DeleteModal";

const Payments = () => {
  const { userId, companyId, roles, setting } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const navigate = useNavigate();
  const [loading, showLoader, hideLoader] = useLoading();
  const [payments, setPayments] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);
  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    company: companyId,
  });

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    getPayments: async (params?: ParamType) => {
      showLoader();
      const res = await getPayment(params);
      if (res) {
        setPayments(res.data.payments);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
    addParam: (value: string) => {
      if (value === "resetFilter") {
        delete params?.type;
        fn.getPayments?.({ ...params, page: 1 });
      } else {
        fn.getPayments?.({ ...params, page: 1, type: value });
      }
    },
  };

  useEffect(() => {
    if (roles?.length !== 0) {
      if (!roles[0]?.payments.complete) {
        params.user = userId;
        fn.getPayments(params);
      } else {
        fn.getPayments(params);
      }
    }
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="data_render_wrapper">
          {/* filters */}
          <div className="filter_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-12 col-lg-4">
                  <Search
                    placeholder="Search by payment number"
                    state={params}
                    setState={setParams}
                    apiCall={fn.getPayments}
                    paramValue="number"
                  />
                </div>
                <div className="col-12 col-lg-8 pt-3 pt-lg-0">
                  <div className="d-flex align-items-center gap-3 justify-content-start justify-content-lg-end">
                    {params?.dF && (
                      <>
                        <ShowSelectedDate
                          label="From:"
                          value={moment(params?.dF?.split("_")[1]).format(
                            setting?.dateFormat
                          )}
                        />
                        <ShowSelectedDate
                          label="To:"
                          value={moment(params?.dF?.split("_")[2]).format(
                            setting?.dateFormat
                          )}
                        />
                      </>
                    )}

                    <div className="d-flex gap-2">
                      <CalendarFilter
                        state={params}
                        apiCall={fn.getPayments}
                        setState={setParams}
                        fieldName="date"
                        label="Issue date"
                        isFutureDateRequired={true}
                      />

                      <OutsideClickHandler
                        onOutsideClick={() => setIsOpen(false)}
                      >
                        <div
                          className="new_btn_wrapper"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          <div>
                            <button className="btn font12x500 buttonOne shadow-none">
                              <img
                                src="/Assets/Svg/plusIcon.svg"
                                alt="icon"
                                className="me-2"
                              />
                              Add New Payment
                            </button>
                          </div>
                          {isOpen && (
                            <div className="new_btn_menus">
                              <div
                                className="menus cursor_pointer"
                                onClick={() =>
                                  fn.openModal(undefined, "receive_payment")
                                }
                              >
                                <span className="font12x500">
                                  Payment Received
                                </span>
                              </div>
                              <div
                                className="menus cursor_pointer"
                                onClick={() =>
                                  fn.openModal(undefined, "made_payment")
                                }
                              >
                                <span className="font12x500">Payment Made</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </OutsideClickHandler>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="list_view_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center pb-3">
                <div className="col-12 col-lg-3">
                  <span className="font16x600 textColor3">
                    List of Payments
                  </span>
                </div>
                <div className="col-12 col-lg-9 pt-3 pt-lg-0">
                  <div className="d-flex justify-content-start justify-content-lg-end">
                    <div className="d-flex align-items-center">
                      <span className="font12x500 textColor3">Sort:</span>
                      <div className="ms-2">
                        <select
                          className="form-select font12x500 textColor3 shadow-none dropdown_filter"
                          onChange={(e) => {
                            fn.addParam(e.target.value);
                          }}
                        >
                          <option value="resetFilter">---Type---</option>
                          <option value="1">Received</option>
                          <option value="2">Made</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* table */}
            {payments?.length !== 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="font12x500 textColor3">
                        <th scope="col">#</th>
                        <th scope="col">Issue Date</th>
                        <th scope="col">Payment no</th>
                        <th scope="col">Account Name</th>
                        <th scope="col">Document No</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Mode</th>
                        <th scope="col">Type</th>
                        {/* <th scope="col">Available Credit</th> */}
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments?.map((payment: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <th>
                              {(params.page - 1) * params.per_page + (i + 1)}
                            </th>
                            <td>
                              {moment(payment?.date).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td>{payment?.number}</td>
                            <td>
                              {payment?.type === 1
                                ? `${payment?.invoice?.client?.name}`
                                : `${payment?.expense?.vendor?.name}`}
                            </td>
                            <td>{payment?.documentNumber}</td>
                            <td>{`₹ ${payment?.amount?.toLocaleString()}`}</td>
                            <td className="font12x500">
                              {modesMap.get(payment?.mode)}
                            </td>
                            <td className="font12x500">
                              {paymentTypeMap.get(payment?.type)}
                            </td>
                            {/* <td>{`₹ ${payment?.availableCredit}`}</td> */}
                            <td>
                              <div className="d-flex align-items-center gap-3">
                                <button
                                  className="btn font12x500 buttonTwo shadow-none"
                                  onClick={() =>
                                    navigate(`/payment/view/${payment?._id}`)
                                  }
                                >
                                  View Details
                                </button>
                                <div
                                  className="cursor_pointer"
                                  onClick={() =>
                                    fn.openModal(
                                      { paymentIds: [payment?._id] },
                                      "delete"
                                    )
                                  }
                                >
                                  <img
                                    src="/Assets/Svg/deleteColor.svg"
                                    alt="close"
                                    className="img-fluid"
                                    draggable={false}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : loading ? (
              <SkeletonLoader />
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>

        {/* pagination */}
        {payments?.length !== 0 && (
          <div className="pagination_wrapper pt-3 px-3">
            <Pagination
              currentPage={params.page}
              onPageChange={(e) => {
                fn.getPayments({ ...params, page: e });
                setParams({
                  ...params,
                  page: e,
                });
              }}
              pageSize={params.per_page}
              totalCount={Math.ceil(totalResult / params.per_page)}
              onChangePerPage={(e) => {
                fn.getPayments({ ...params, per_page: e });
                setParams({
                  ...params,
                  per_page: e,
                });
              }}
            />
          </div>
        )}
      </div>

      {/* form modal */}
      {commonData?.modal && commonData?.check === "receive_payment" && (
        <ReceivedForm
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getPayments}
          params={params}
        />
      )}

      {/* form modal */}
      {commonData?.modal && commonData?.check === "made_payment" && (
        <MadeForm
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getPayments}
          params={params}
        />
      )}

      {/* delete modal */}
      {commonData?.modal && commonData?.check === "delete" && (
        <DeleteModal
          label="Payment"
          dataIds={commonData?.data}
          setState={setCommonData}
          url={DELETE_PAYMENT}
          variableName="paymentIds"
          apiCall={fn.getPayments}
          params={params}
        />
      )}
    </>
  );
};

export default Payments;
