import React from "react";
import DropdownTwo from "../../shared components/Dropdown/DropdownTwo";
import InputError from "../../shared components/InputError/InputError";

type PropType = {
  users: any;
  formik: any;
};

const AssignUser: React.FC<PropType> = (props) => {
  const { users, formik } = props;

  const fn = {
    assignUserInState: (label?: string, value?: string | number) => {
      formik.setFieldValue("assignTo", [...formik.values.assignTo, value]);
      formik.setFieldValue("assignToName", [
        ...formik.values.assignToName,
        {
          _id: value,
          name: label,
        },
      ]);
    },
    deleteAssignedUser: (value?: string) => {
      const newData = formik.values.assignToName.filter((ele: any) => {
        return ele._id !== value;
      });
      formik.setFieldValue("assignToName", newData);
      const newDataId = formik.values.assignTo.filter((ele: any) => {
        return ele !== value;
      });
      formik.setFieldValue("assignTo", newDataId);
    },
  };

  return (
    <>
      <div className="mb-3">
        <div className="mb-3">
          <DropdownTwo
            label="Assign to*"
            placeholder="Select User"
            name="assignTo"
            data={users}
            state={""}
            setState={(e?: string, x?: string | number) => {
              fn.assignUserInState(e, x);
            }}
            labelField="name"
            onBlur={() => formik.setFieldTouched("assignTo", true)}
          />
        </div>
        {formik.values.assignToName.length !== 0 && (
          <div className="multi_data_wrapper">
            {formik.values.assignToName?.map((item: any, i: number) => {
              return (
                <div key={i} className="indi_multi_data">
                  <p className="mb-0 font12x400 textColor3">{item?.name}</p>
                  <div
                    className="remove_data cursor_pointer"
                    onClick={() => fn.deleteAssignedUser(item?._id)}
                  >
                    <img
                      src="/Assets/Svg/closeIcon.svg"
                      width={12}
                      height={12}
                      alt="remove"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {formik.touched.assignTo && formik.errors.assignTo ? (
          <InputError error={formik.errors.assignTo} />
        ) : null}
      </div>
    </>
  );
};

export default AssignUser;
