import { useContext } from "react";
import { UserContext } from "../../../pages/main/user/add update user";
import {
  USER_ATTENDANCE,
  USER_BANK_DETAILS,
  USER_DETAILS,
  USER_DOCUMENTS,
  USER_OFFICE_DETAILS,
  USER_ROLES,
  USER_SALARY_SLIP,
} from "../../../utils/constants";
import UserAttendance from "./attendance";
import BankDetails from "./bank details";
import Documents from "./documents";
import OfficeDetails from "./office details";
import UserSalarySlip from "./salary slip";
import UserDetails from "./user details";
import UserRoles from "./user roles";

const MenuData = () => {
  const { routeName } = useContext(UserContext);
  switch (routeName) {
    case USER_ROLES:
      return (
        <>
          <UserRoles />
        </>
      );
    case USER_DETAILS:
      return (
        <>
          <UserDetails />
        </>
      );
    case USER_BANK_DETAILS:
      return (
        <>
          <BankDetails />
        </>
      );
    case USER_OFFICE_DETAILS:
      return (
        <>
          <OfficeDetails />
        </>
      );
    case USER_DOCUMENTS:
      return (
        <>
          <Documents />
        </>
      );
    case USER_ATTENDANCE:
      return (
        <>
          <UserAttendance />
        </>
      );
    case USER_SALARY_SLIP:
      return (
        <>
          <UserSalarySlip />
        </>
      );
    default:
      return null;
  }
};

export default MenuData;
