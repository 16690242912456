import Api from "../../../config/api";
import {
  CREATE_ROLE,
  SEARCH_ROLE,
  SEARCH_ROLE_BY_ID,
  UPDATE_ROLE,
} from "../../../utils/constants";
import { showToast } from "../../toast/ShowToast";

export const getRole = async (params?: any) => {
  try {
    const res = await Api(false).get(SEARCH_ROLE, { params });
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const getRoleById = async (id?: string) => {
  try {
    const res = await Api(false).get(`${SEARCH_ROLE_BY_ID}/${id}`);
    return res;
  } catch (error: any) {
    if (error?.response?.status >= 400 && error?.response?.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
    } else {
      showToast(`${error?.response?.data?.message}`, "error");
    }
  }
};

export const createRole = async (body: any, hideLoader: () => void) => {
  try {
    const res = await Api(false).post(CREATE_ROLE, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};

export const updateRole = async (
  body: any,
  id: string,
  hideLoader: () => void
) => {
  try {
    const res = await Api(false).put(`${UPDATE_ROLE}/${id}`, body);
    return res;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      showToast(`${error.response.statusText}`, "error");
      hideLoader();
    } else {
      showToast(`${error.response.data.message}`, "error");
      hideLoader();
    }
  }
};
