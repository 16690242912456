import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import DataNotFound from "../../../../components/Data Not Found";
import Pagination from "../../../../components/Pagination";
import CalendarFilter2 from "../../../../Filters/CalendarFilter2";
import useLoading from "../../../../hooks/useLoading";
import { getClient, updateClient } from "../../../../lib/api functions/client";
import { showToast } from "../../../../lib/toast/ShowToast";
import ShowSelectedDate from "../../../../shared components/DateInput/ShowSelectedDate";
import SkeletonLoader from "../../../../shared components/Loader/SkeletonLoader";
import Search from "../../../../shared components/Search";
import { MainContext } from "../../../../utils/private_routes/PrivateRoutes";
import { ParamType } from "../type";

const TrashedVendor = () => {
  const { setPageTitle, userId, companyId, roles, setting } =
    useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [recoverLoading, showLoader2, hideLoader2] = useLoading();
  const [clients, setClients] = useState<any>([]);
  const [totalResult, setTotalResult] = useState<number>(0);
  const [params, setParams] = useState<ParamType>({
    page: 1,
    per_page: 10,
    type: JSON.stringify([2, 3]),
    company: companyId,
    // status: 2,
  });

  const fn = {
    getVendors: async (params: ParamType) => {
      showLoader();
      const res = await getClient(params);
      if (res) {
        setClients(res.data.clients);
        setTotalResult(res.data.total);
        hideLoader();
      }
    },
    recoverVendor: async (clientId: string) => {
      let finalObj = {
        status: 1,
      };
      showLoader2();
      const res = await updateClient(finalObj, clientId, hideLoader);
      if (res) {
        showToast(`Vendor recovered`, "success");
        fn.getVendors(params);
        hideLoader2();
      }
    },
  };

  useEffect(() => {
    setPageTitle("Trashed Vendors");
  }, []);

  useEffect(() => {
    if (roles?.length !== 0) {
      if (!roles[0]?.clients.complete) {
        params.user = userId;
        fn.getVendors(params);
      } else {
        fn.getVendors(params);
      }
    }
  }, []);

  return (
    <>
      <div className="single_page">
        <div className="data_render_wrapper">
          {/* filters */}
          <div className="filter_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-12 col-lg-4">
                  <Search
                    placeholder="Search clients"
                    state={params}
                    setState={setParams}
                    apiCall={fn.getVendors}
                    paramValue="name"
                  />
                </div>
                <div className="col-12 col-lg-8 pt-3 pt-lg-0">
                  <div className="d-flex align-items-center gap-3 justify-content-start justify-content-lg-end">
                    {params?.start_date && params?.end_date && (
                      <>
                        <ShowSelectedDate
                          label="From:"
                          value={moment(params?.start_date).format(
                            setting?.dateFormat
                          )}
                        />
                        <ShowSelectedDate
                          label="To:"
                          value={moment(params?.end_date).format(
                            setting?.dateFormat
                          )}
                        />
                      </>
                    )}

                    <div className="d-flex gap-2">
                      <CalendarFilter2
                        state={params}
                        apiCall={fn.getVendors}
                        setState={setParams}
                        isFutureDateRequired={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="list_view_wrapper">
            <div className="container-fluid p-0">
              <div className="row align-items-center pb-3">
                <div className="col-12 col-lg-3">
                  <span className="font16x600 textColor3">
                    List of Trashed Vendors
                  </span>
                </div>
              </div>
            </div>

            {/* table */}
            {clients?.length !== 0 ? (
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="font12x500 textColor3">
                        <th scope="col">
                          <div className="customCheck form-check d-flex align-items-center p-0">
                            <input
                              type="checkbox"
                              className="me-2"
                              id="checkbox"
                              name="rememberMe"
                              // checked={formik.values.tandcCheck}
                              // onChange={(e) => {
                              //   formik.setFieldValue("tandcCheck", e.target.checked);
                              // }}
                            />
                          </div>
                        </th>
                        <th scope="col">#</th>
                        <th scope="col">Entry Date</th>
                        <th scope="col">Vendor name</th>
                        <th scope="col">Contact</th>
                        <th scope="col">Last Updated</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clients?.map((client: any, i: number) => {
                        return (
                          <tr key={i} className="font12x400">
                            <th>
                              <div className="customCheck form-check d-flex align-items-center p-0">
                                <input
                                  type="checkbox"
                                  className="me-2"
                                  id="checkbox"
                                  name="rememberMe"
                                  // checked={formik.values.tandcCheck}
                                  // onChange={(e) => {
                                  //   formik.setFieldValue("tandcCheck", e.target.checked);
                                  // }}
                                />
                              </div>
                            </th>
                            <th>
                              {(params.page - 1) * params.per_page + (i + 1)}
                            </th>
                            <td>
                              {moment(client?.createdAt).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td>
                              <span className="font12x500 mb-1">
                                {client?.name}
                              </span>
                              <br />
                              <span>{client?.completeAddress?.city}</span>
                            </td>
                            <td>
                              <span className="mb-1">{client?.email}</span>
                              <br />
                              <span>{client?.contactNumber}</span>
                            </td>
                            <td>
                              {moment(client?.updatedAt).format(
                                setting?.dateFormat
                              )}
                            </td>
                            <td>
                              <button
                                className="btn font12x500 buttonTwo shadow-none"
                                onClick={() => fn.recoverVendor(client?._id)}
                              >
                                Recover
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : loading ? (
              <SkeletonLoader />
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>

        {/* pagination */}
        {clients?.length !== 0 && (
          <div className="pagination_wrapper pt-3 px-3">
            <Pagination
              currentPage={params.page}
              onPageChange={(e) => {
                fn.getVendors({ ...params, page: e });
                setParams({
                  ...params,
                  page: e,
                });
              }}
              pageSize={params.per_page}
              totalCount={Math.ceil(totalResult / params.per_page)}
              onChangePerPage={(e) => {
                fn.getVendors({ ...params, per_page: e });
                setParams({
                  ...params,
                  per_page: e,
                });
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TrashedVendor;
