import React, { useContext } from "react";
import { ViewLeadContext } from "../../../pages/main/lead/view lead";
import Menu from "./Menu";
import Skeleton from "react-loading-skeleton";

const MenuBar = () => {
  const { id, personName, loading, lead } = useContext(ViewLeadContext);
  return (
    <>
      <div>
        {loading ? (
          <Skeleton height="46px" borderRadius="8px" />
        ) : (
          <div className="person_name_wrapper viewlead_content_header">
            <h3 className="m-0 font14x600 textColor3">{personName}</h3>
          </div>
        )}

        <div className="details_menu_wrapper">
          <Menu
            path={`/lead/view/${id}/profile`}
            label="Lead Profile"
            isLeadApprove={false}
          />
          <Menu
            path={`/lead/view/${id}/update`}
            label="Add Updates"
            isLeadApprove={
              lead?.confirmation === 0 || lead?.confirmation === 2
                ? true
                : false
            }
          />
          <Menu
            path={`/lead/view/${id}/proposal`}
            label="Proposal"
            isLeadApprove={
              lead?.confirmation === 0 || lead?.confirmation === 2
                ? true
                : false
            }
          />
          <Menu
            path={`/lead/view/${id}/quotation`}
            label="Attach Mail of Quotation"
            isLeadApprove={
              lead?.confirmation === 0 || lead?.confirmation === 2
                ? true
                : false
            }
          />
          <Menu
            path={`/lead/view/${id}/po`}
            label="Attach P.O"
            isLeadApprove={
              lead?.confirmation === 0 || lead?.confirmation === 2
                ? true
                : false
            }
          />
          <Menu
            path={`/lead/view/${id}/invoice`}
            label="Invoice"
            isLeadApprove={
              lead?.confirmation === 0 || lead?.confirmation === 2
                ? true
                : false
            }
          />
          <Menu
            path={`/lead/view/${id}/request-invoice`}
            label="Request Invoice"
            isLeadApprove={
              lead?.confirmation === 0 || lead?.confirmation === 2
                ? true
                : false
            }
          />
          <Menu
            path={`/lead/view/${id}/payment`}
            label="Payments"
            isLeadApprove={
              lead?.confirmation === 0 || lead?.confirmation === 2
                ? true
                : false
            }
          />
          <Menu
            path={`/lead/view/${id}/credential`}
            label="Attach Credentials"
            isLeadApprove={
              lead?.confirmation === 0 || lead?.confirmation === 2
                ? true
                : false
            }
          />
          <Menu
            path={`/lead/view/${id}/request-demo`}
            label="Request Demo"
            isLeadApprove={
              lead?.confirmation === 0 || lead?.confirmation === 2
                ? true
                : false
            }
          />
        </div>
      </div>
    </>
  );
};

export default MenuBar;
