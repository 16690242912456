import React, { useContext, useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { commonType } from "../../../pages/main/lead";
import { BiSave } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import useLoading from "../../../hooks/useLoading";
import {
  getDailyAttendance,
  updateDailyAttendance,
} from "../../../lib/api functions/attendance/attendance";
import moment from "moment";
import { MainContext } from "../../../utils/private_routes/PrivateRoutes";
import Skeleton from "react-loading-skeleton";
import { showToast } from "../../../lib/toast/ShowToast";
import _ from "lodash";
import {
  calculateDayDevoted,
  calculateTotalTime,
  changeSignOutTimeStamps,
  changeTimeStamps,
} from "../../../function/other";
import {
  dayDevotedMap,
  DELETE_DAILY_ATTENDANCE,
} from "../../../utils/constants";
import DropdownOne from "../../../shared components/Dropdown/DropdownOne";
import CreateDailyAttendance from "./CreateDailyAttendance";
import DeleteModalSingle from "../../Trashed Modal/DeleteModalSingle";

type propType = {
  state: commonType;
  setState: (val: commonType) => void;
  apiCall: (val: any) => void;
  params: any;
};

type TimeStampType = {
  signIn: string[];
  signOut: string[];
  totalSignInLength: number;
  totalSignOutLength: number;
  dayDevoted?: string | number;
  dayDevotedLabel: string;
};

const ViewAttendance: React.FC<propType> = (props) => {
  const { state, setState, apiCall, params } = props;
  const { setting, roles } = useContext(MainContext);
  const [commonData, setCommonData] = useState<commonType>({
    data: undefined,
    modal: false,
    check: "",
  });
  const [loading, showLoader, hideLoader] = useLoading();
  const [attendances, setAttendances] = useState<any>([]);
  const [singleData, setSingleData] = useState<TimeStampType>({
    signIn: [],
    signOut: [],
    totalSignInLength: 0,
    totalSignOutLength: 0,
    dayDevoted: "",
    dayDevotedLabel: "",
  });
  const [error, setError] = useState<string>("");

  const fn = {
    openModal: (data?: any, check?: string) => {
      setCommonData({
        data: data,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    setPositionToBody: () => {
      setState({
        data: undefined,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
    getDailyAttendance: async () => {
      const param = {
        attendance: state?.data?._id,
        sort_by: "date",
        sort_order: "asc",
      };
      showLoader();
      const res = await getDailyAttendance(param);
      if (res) {
        setAttendances([]);
        res.data.dailyAttendances?.map((item: any) => {
          setAttendances((prev: any) => [
            ...prev,
            {
              ...item,
              signIn: changeTimeStamps(item.signIn, "timeToString"),
              signOut: changeSignOutTimeStamps(
                item.signOut,
                item.signIn?.length,
                item.signOut?.length,
                "timeToString"
              ),
              totalSignInLength: item.signIn?.length,
              totalSignOutLength: item.signOut?.length,
              dayDevoted: item.dayDevoted,
              dayDevotedLabel: dayDevotedMap.get(item.dayDevoted),
              isEditable: false,
            },
          ]);
        });
        hideLoader();
      }
    },
    changeEditStatus: (
      signIn?: string[],
      signOut?: string[],
      value?: boolean,
      dayDevotedValue?: string,
      index?: number
    ) => {
      let deepAttendances = _.cloneDeep(attendances);
      let tempSingleData = { ...singleData };

      if (value) {
        tempSingleData.signIn = signIn ?? [];
        tempSingleData.signOut = signOut ?? [];
        tempSingleData.totalSignInLength = signIn?.length ?? 0;
        tempSingleData.totalSignOutLength = signOut?.length ?? 0;
        tempSingleData.dayDevoted = dayDevotedValue ?? "";
        tempSingleData.dayDevotedLabel =
          dayDevotedMap.get(dayDevotedValue) ?? "";
        setSingleData(tempSingleData);
        deepAttendances.map((item: any, i: number) => {
          if (index === i) {
            item.isEditable = value;
          } else {
            item.isEditable = !value;
          }
        });
        setAttendances(deepAttendances);
      } else {
        tempSingleData.signIn = [];
        tempSingleData.signOut = [];
        tempSingleData.totalSignInLength = 0;
        tempSingleData.totalSignOutLength = 0;
        tempSingleData.dayDevoted = "";
        tempSingleData.dayDevotedLabel = "";
        setSingleData(tempSingleData);
        deepAttendances.map((item: any, i: number) => {
          if (index === i) {
            item.isEditable = value;
          }
        });
        setAttendances(deepAttendances);
        setError("");
      }
    },
    updateDailyAttendance: async (id: string) => {
      let body = {};
      if (state?.data?.salaryType === "D") {
        body = {
          dayDevoted: singleData.dayDevoted,
        };
      } else {
        body = {
          signIn: changeTimeStamps(singleData?.signIn, "stringToTime"),
          signOut: changeSignOutTimeStamps(
            singleData.signOut,
            singleData.totalSignInLength,
            singleData.totalSignOutLength,
            "stringToTime"
          ).filter((item) => item?.length),
        };
      }

      const res = await updateDailyAttendance(body, id);
      if (res) {
        showToast(`Attendance updated`, "success");
        fn.getDailyAttendance();
        apiCall(params);
      }
    },
    validateTimings: (value: any, index: number, check: string) => {
      let tempObj = { ...singleData };

      if (singleData.totalSignInLength === 1) {
        if (check === "signIn") {
          tempObj.signIn[index] = value;
          const checkDate = moment(
            tempObj.signOut[index],
            "HH:mm"
          ).isSameOrAfter(moment(tempObj.signIn[index], "HH:mm"));
          if (!checkDate) {
            setError(
              "Sign In time must be less than or equal to Sign Out time"
            );
          } else {
            setError("");
          }
        } else {
          tempObj.signOut[index] = value;
          if (tempObj.signOut[index] === "") {
            setError("");
          } else {
            const checkDate = moment(
              tempObj.signOut[index],
              "HH:mm"
            ).isSameOrAfter(moment(tempObj.signIn[index], "HH:mm"));
            if (!checkDate) {
              setError("Sign Out time must be greater than Sign In time");
            } else {
              setError("");
            }
          }
        }
        setSingleData(tempObj);
      } else if (singleData.totalSignInLength > 1) {
        if (check === "signIn") {
          tempObj.signIn[index] = value;
          if (index === 0) {
            //compare signin 0 with signout 0
            const checkDate = moment(
              tempObj.signOut[index],
              "HH:mm"
            ).isSameOrAfter(moment(tempObj.signIn[index], "HH:mm"));
            if (!checkDate) {
              setError(
                `Row: ${index + 1} Sign In(${
                  tempObj.signIn[index]
                }) time must be less than or equal to Row: ${
                  index + 1
                } Sign Out(${tempObj.signOut[index]}) time`
              );
            } else {
              setError("");
            }
          } else {
            let msg = "";
            //signin index <= signout index && signin index >= signout index-1
            const checkDate1 = moment(
              tempObj.signIn[index],
              "HH:mm"
            ).isSameOrAfter(moment(tempObj.signOut[index - 1], "HH:mm"));
            if (!checkDate1) {
              // setError(
              //   `Row: ${index} Sign Out(${
              //     tempObj.signOut[index - 1]
              //   }) time must be less than or equal to Row:${
              //     index + 1
              //   } Sign In(${tempObj.signIn[index]}) time`
              // );
              msg = `Row: ${index} Sign Out(${
                tempObj.signOut[index - 1]
              }) time must be less than or equal to Row:${index + 1} Sign In(${
                tempObj.signIn[index]
              }) time`;
            }

            const checkDate2 = moment(
              tempObj.signOut[index],
              "HH:mm"
            ).isSameOrAfter(moment(tempObj.signIn[index], "HH:mm"));
            if (!checkDate2) {
              // setError(
              //   `Row: ${index + 1} Sign In(${
              //     tempObj.signIn[index]
              //   }) time must be less than or equal to Row:${
              //     index + 1
              //   } Sign Out(${tempObj.signOut[index]}) time`
              // );

              msg = `Row: ${index + 1} Sign In(${
                tempObj.signIn[index]
              }) time must be less than or equal to Row:${index + 1} Sign Out(${
                tempObj.signOut[index]
              }) time`;
            }
            setError(msg);
          }
        } else {
          tempObj.signOut[index] = value;
          if (index === singleData.totalSignInLength - 1) {
            //signout index >= signin index
            if (tempObj.signOut[index] === "") {
              setError("");
            } else {
              const checkDate = moment(
                tempObj.signOut[index],
                "HH:mm"
              ).isSameOrAfter(moment(tempObj.signIn[index], "HH:mm"));
              if (!checkDate) {
                setError(
                  `Row: ${index + 1} Sign Out(${
                    tempObj.signOut[index]
                  }) time must be greater than Row: ${index + 1} Sign In(${
                    tempObj.signIn[index]
                  }) time`
                );
              } else {
                setError("");
              }
            }
          } else {
            let msg = "";
            //signout index >= signin index && signout index <= signin index+1
            const checkDate1 = moment(
              tempObj.signOut[index],
              "HH:mm"
            ).isSameOrAfter(moment(tempObj.signIn[index], "HH:mm"));
            if (!checkDate1) {
              // setError(
              //   `Row: ${index + 1} Sign Out(${
              //     tempObj.signOut[index]
              //   }) time must be greater than Row: ${index + 1} Sign In(${
              //     tempObj.signIn[index]
              //   }) time`
              // );
              msg = `Row: ${index + 1} Sign Out(${
                tempObj.signOut[index]
              }) time must be less than or equal to Row: ${index + 1} Sign In(${
                tempObj.signIn[index]
              }) time`;
            }

            const checkDate2 = moment(
              tempObj.signIn[index + 1],
              "HH:mm"
            ).isSameOrAfter(moment(tempObj.signOut[index], "HH:mm"));
            if (!checkDate2) {
              // setError(
              //   `Row: ${index + 2} Sign In(${
              //     tempObj.signIn[index + 1]
              //   }) time must be less than or equal to Row: ${index + 1} Sign Out(${
              //     tempObj.signOut[index]
              //   }) time`
              // );

              msg = `Row: ${index + 2} Sign In(${
                tempObj.signIn[index + 1]
              }) time must be greater than or equal to Row: ${
                index + 1
              } Sign Out(${tempObj.signOut[index]}) time`;
            }
            setError(msg);
          }
        }
        setSingleData(tempObj);
      } else {
        //not to be handelled
      }
    },
  };

  useEffect(() => {
    fn.getDailyAttendance();
  }, []);

  return (
    <>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="form_heading_section">
            <div>
              <h1 className="font16x600 mb-0">View Attendance</h1>
              <p className="mb-0 font12x400">{state?.data?.name}</p>
              <p className="mb-0 font12x400">{state?.data?.employeeId}</p>
            </div>
            <div className="d-flex align-items-center gap-3">
              {params?.month === moment().month().toString() && (
                <p className="mb-0 mt-1 font12x400">
                  Available PL: {state?.data?.availablePL}
                </p>
              )}
              {roles[0]?.attendance.complete && (
                <button
                  className="btn font12x500 buttonOne shadow-none"
                  onClick={() => {
                    fn.openModal(
                      { attendances, salaryType: state?.data?.salaryType },
                      "create_attendance"
                    );
                  }}
                >
                  Create
                </button>
              )}

              <span onClick={fn.setPositionToBody}>
                <CgClose />
              </span>
            </div>
          </div>
          <div
            className="form_content_section"
            style={{
              height: "calc(100% - 80px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="form_fields_wrapper pb-0">
              {attendances?.length !== 0 ? (
                <div className="entry_table_wrapper">
                  <div className="table_wrapper">
                    <table className="table">
                      <thead className="table_head">
                        <tr className="font12x500 textColor3">
                          <th scope="col">Date</th>
                          <th scope="col">Day</th>
                          <th scope="col">Attendance Type</th>
                          <th scope="col">Sign In</th>
                          <th scope="col">Sign Out</th>
                          <th scope="col">Time Devoted</th>
                          <th scope="col">Day Devoted</th>
                          {(roles[0]?.name === "super-admin" ||
                            roles[0]?.name === "admin") && (
                            <th scope="col">Action</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {attendances.map(
                          (item: any, attendanceIndex: number) => {
                            return item?.isEditable ? (
                              <>
                                <tr
                                  key={attendanceIndex}
                                  className="font12x400"
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <td>
                                    {moment(item.date).format(
                                      setting?.dateFormat
                                    )}
                                  </td>
                                  <td>{moment(item.date).format("dddd")}</td>
                                  <td>{item?.attendanceType}</td>
                                  {state?.data?.salaryType === "D" ? (
                                    <td>
                                      <div className="d-flex flex-column gap-2">
                                        {item?.signIn?.map(
                                          (item2: any, i: number) => {
                                            return <span key={i}>{item2}</span>;
                                          }
                                        )}
                                      </div>
                                    </td>
                                  ) : (
                                    <td>
                                      <div className="d-flex flex-column gap-2">
                                        {singleData["signIn"].map(
                                          (item2: any, i: number) => {
                                            return (
                                              <div
                                                key={i}
                                                className="input_wrapper d-flex align-items-center"
                                                style={{
                                                  width: 110,
                                                }}
                                              >
                                                <input
                                                  type="time"
                                                  className="form-control shadow-none input_field"
                                                  id="signin"
                                                  autoComplete="off"
                                                  value={singleData.signIn[i]}
                                                  onChange={(e) => {
                                                    fn.validateTimings(
                                                      e.target.value,
                                                      i,
                                                      "signIn"
                                                    );
                                                  }}
                                                />
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </td>
                                  )}

                                  {state?.data?.salaryType === "D" ? (
                                    <td>
                                      <div className="d-flex flex-column gap-2">
                                        {item.signOut?.length !== 0 &&
                                          item?.signOut?.map(
                                            (item3: any, i: number) => {
                                              return (
                                                <span key={i}>
                                                  {item3 !== ""
                                                    ? item3
                                                    : "--:--"}
                                                </span>
                                              );
                                            }
                                          )}
                                      </div>
                                    </td>
                                  ) : (
                                    <td>
                                      <div className="d-flex flex-column gap-2">
                                        {singleData["signOut"]?.map(
                                          (item3: any, i: number) => {
                                            return (
                                              <div
                                                key={i}
                                                className="input_wrapper d-flex align-items-center"
                                                style={{
                                                  width: 110,
                                                }}
                                              >
                                                <input
                                                  type="time"
                                                  className="form-control shadow-none input_field"
                                                  id="signin"
                                                  autoComplete="off"
                                                  value={singleData.signOut[i]}
                                                  onChange={(e) => {
                                                    fn.validateTimings(
                                                      e.target.value,
                                                      i,
                                                      "signOut"
                                                    );
                                                  }}
                                                />
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </td>
                                  )}
                                  <td>{item.timeDevoted}</td>
                                  {state?.data?.salaryType === "D" ? (
                                    <td>
                                      <DropdownOne
                                        placeholder="Select day devoted"
                                        name="dayDevoted"
                                        data={dayDevotedMap}
                                        state={singleData.dayDevotedLabel}
                                        setState={(
                                          e?: string,
                                          x?: string | number
                                        ) => {
                                          let tempObj = { ...singleData };
                                          // @ts-ignore
                                          tempObj.dayDevoted = x;
                                          tempObj.dayDevotedLabel =
                                            dayDevotedMap.get(x);
                                          setSingleData(tempObj);
                                        }}
                                      />
                                    </td>
                                  ) : (
                                    <td>{item.dayDevoted}</td>
                                  )}

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <div className="d-flex gap-2">
                                      {error === "" && (
                                        <div
                                          className="d-flex align-items-center cursor_pointer"
                                          onClick={() =>
                                            fn.updateDailyAttendance(item?._id)
                                          }
                                        >
                                          <BiSave
                                            style={{
                                              fontSize: 15,
                                              color: "var(--text2)",
                                            }}
                                          />
                                        </div>
                                      )}
                                      <div
                                        className="d-flex align-items-center cursor_pointer"
                                        onClick={() =>
                                          fn.changeEditStatus(
                                            undefined,
                                            undefined,
                                            false,
                                            undefined,
                                            attendanceIndex
                                          )
                                        }
                                      >
                                        <MdCancel
                                          style={{
                                            fontSize: 15,
                                            color: "var(--error)",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {error !== "" && (
                                  <tr
                                    key={attendanceIndex}
                                    className="errorDanger"
                                  >
                                    <td colSpan={5}>{error}</td>
                                  </tr>
                                )}
                              </>
                            ) : (
                              <tr key={attendanceIndex} className="font12x400">
                                <td>
                                  {moment(item.date).format(
                                    setting?.dateFormat
                                  )}
                                </td>
                                <td>{moment(item.date).format("dddd")}</td>
                                <td>{item?.attendanceType}</td>
                                <td>
                                  <div className="d-flex flex-column gap-2">
                                    {item?.signIn?.map(
                                      (item2: any, i: number) => {
                                        return <span key={i}>{item2}</span>;
                                      }
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex flex-column gap-2">
                                    {item.signOut?.length !== 0 &&
                                      item?.signOut?.map(
                                        (item3: any, i: number) => {
                                          return (
                                            <span key={i}>
                                              {item3 !== "" ? item3 : "--:--"}
                                            </span>
                                          );
                                        }
                                      )}
                                  </div>
                                </td>
                                <td>{item.timeDevoted}</td>
                                <td>{dayDevotedMap.get(item.dayDevoted)}</td>
                                {(roles[0]?.name === "super-admin" ||
                                  roles[0]?.name === "admin") && (
                                  <td>
                                    <div className="d-flex gap-2">
                                      <div
                                        className="d-flex align-items-center cursor_pointer"
                                        onClick={() =>
                                          fn.changeEditStatus(
                                            item?.signIn,
                                            item?.signOut,
                                            true,
                                            item?.dayDevoted,
                                            attendanceIndex
                                          )
                                        }
                                      >
                                        <img
                                          src="/Assets/Svg/editIcon.svg"
                                          alt="edit"
                                        />
                                      </div>

                                      {item?.attendanceType !== "PL" && (
                                        <div
                                          className="cursor_pointer"
                                          onClick={() =>
                                            fn.openModal(item?._id, "delete")
                                          }
                                        >
                                          <img
                                            src="/Assets/Svg/deleteColor.svg"
                                            alt="close"
                                            className="img-fluid"
                                            draggable={false}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                )}
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : loading ? (
                <div className="d-flex justify-content-between mb-3 gap-3">
                  <Skeleton width="50px" height="20px" borderRadius={4} />
                  <Skeleton width="50px" height="20px" borderRadius={4} />
                  <Skeleton width="100px" height="20px" borderRadius={4} />
                  <Skeleton width="40px" height="20px" borderRadius={4} />
                  <Skeleton width="50px" height="20px" borderRadius={4} />
                  <Skeleton width="50px" height="20px" borderRadius={4} />
                  <Skeleton width="50px" height="20px" borderRadius={4} />
                  <Skeleton width="50px" height="20px" borderRadius={4} />
                </div>
              ) : (
                <p className="mb-0 font14x400 textColor3">No data found</p>
              )}
            </div>

            <div className="form_bottom_section">
              <div>
                <p className="mb-0 font14x500 textColor3">
                  Total time:{" "}
                  {loading ? (
                    <Skeleton width="50px" height="10px" borderRadius={4} />
                  ) : (
                    <span className="font12x400">
                      {calculateTotalTime(attendances)}
                    </span>
                  )}
                </p>
                <p className="mb-0 font14x500 textColor3">
                  Total Day Devoted:{" "}
                  {loading ? (
                    <Skeleton width="30px" height="10px" borderRadius={4} />
                  ) : (
                    <span className="font12x400">
                      {calculateDayDevoted(attendances)}
                    </span>
                  )}
                </p>
              </div>
              <div>
                <p className="mb-0 font14x500 textColor3">
                  Total UPL used:{" "}
                  {loading ? (
                    <Skeleton width="30px" height="10px" borderRadius={4} />
                  ) : (
                    <span className="font12x400">
                      {
                        attendances?.filter((item: any) => {
                          return item.attendanceType === "UPL";
                        }).length
                      }
                    </span>
                  )}
                </p>
                <p className="mb-0 font14x500 textColor3">
                  Total PL used:{" "}
                  {loading ? (
                    <Skeleton width="30px" height="10px" borderRadius={4} />
                  ) : (
                    <span className="font12x400">
                      {
                        attendances?.filter((item: any) => {
                          return item.attendanceType === "PL";
                        }).length
                      }
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* create attendance modal */}
      {commonData?.modal && commonData?.check === "create_attendance" && (
        <CreateDailyAttendance
          userId={state?.data?.userId}
          month={state?.data?.month}
          year={state?.data?.year}
          state={commonData}
          setState={setCommonData}
          apiCall={fn.getDailyAttendance}
        />
      )}

      {/* delete modal */}
      {commonData?.modal && commonData?.check === "delete" && (
        <DeleteModalSingle
          label="Attendance"
          id={commonData?.data}
          setState={setCommonData}
          url={DELETE_DAILY_ATTENDANCE}
          apiCall={fn.getDailyAttendance}
          params={params}
        />
      )}
    </>
  );
};

export default ViewAttendance;
