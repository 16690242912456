import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import Box from "../../../../../components/Action Component/Box";
import useLoading from "../../../../../hooks/useLoading";
import {
  getDocumentSettingById,
  updateDocumentSetting,
} from "../../../../../lib/api functions/setting/document";
import { showToast } from "../../../../../lib/toast/ShowToast";
import DropdownOne from "../../../../../shared components/Dropdown/DropdownOne";
import InputError from "../../../../../shared components/InputError/InputError";
import SkeletonLoader from "../../../../../shared components/Loader/SkeletonLoader";
import SpinLoader from "../../../../../shared components/Loader/SpinLoader";
import InputField from "../../../../../shared components/TextInput";
import {
  salaryTypeMap,
  salaryValueTypeMap,
} from "../../../../../utils/constants";
import { MainContext } from "../../../../../utils/private_routes/PrivateRoutes";
import { salarySlipSchema } from "../../../../../utils/validationSchema";
import { SalarySlipLabelsType, SalarySlipType } from "./type";

const SalarySlipLabel = () => {
  const { companyId, roles, setDocumentSetting } = useContext(MainContext);
  const [loading, showLoader, hideLoader] = useLoading();
  const [salarySlipLoading, showLoader2, hideLoader2] = useLoading();
  const initialValues: SalarySlipType = {
    _id: "",
    slipLabels: [],
  };
  const [singleItem, setSingleItem] = useState<SalarySlipLabelsType>({
    type: "",
    typeName: "",
    label: "",
    value: "",
    valueType: "",
    valueTypeName: "",
    isEditable: false,
  });
  const fn = {
    getSalarySlipSetting: async (params?: any) => {
      showLoader2();
      const res = await getDocumentSettingById(companyId, params);
      if (res) {
        formik.setFieldValue("_id", res.data.documentSetting._id);
        let slipLabels: any = [];
        res.data.documentSetting.slipLabels?.map((item: any) => {
          slipLabels.push({
            type: item.type,
            typeName: salaryTypeMap.get(item.type),
            label: item.label,
            value: item.value,
            valueType: item.valueType,
            valueTypeName: salaryValueTypeMap.get(item.valueType),
            isEditable: false,
          });
        });
        formik.setFieldValue("slipLabels", slipLabels);
        hideLoader2();
      }
    },
    addDataIntoState: (singleItem: SalarySlipLabelsType) => {
      formik.setFieldValue("slipLabels", [
        ...formik.values.slipLabels,
        singleItem,
      ]);
      setSingleItem({
        type: "",
        typeName: "",
        label: "",
        value: "",
        valueType: "",
        valueTypeName: "",
        isEditable: false,
      });
    },
    updateDataIntoState: (index: number) => {
      let _state = formik.values;
      formik.setFieldValue(
        `slipLabels[${index}.isEditable]`,
        !_state.slipLabels[index].isEditable
      );
    },
    saveDataIntoState: (index: number) => {
      let _state = formik.values;
      formik.setFieldValue(
        `slipLabels[${index}.label]`,
        _state.slipLabels[index].label
      );
      formik.setFieldValue(
        `slipLabels[${index}.type]`,
        _state.slipLabels[index].type
      );
      formik.setFieldValue(
        `slipLabels[${index}.typeName]`,
        _state.slipLabels[index].typeName
      );
      formik.setFieldValue(
        `slipLabels[${index}.value]`,
        _state.slipLabels[index].value
      );
      formik.setFieldValue(
        `slipLabels[${index}.valueType]`,
        _state.slipLabels[index].valueType
      );
      formik.setFieldValue(
        `slipLabels[${index}.valueTypeName]`,
        _state.slipLabels[index].valueTypeName
      );
      formik.setFieldValue(`slipLabels[${index}.isEditable]`, false);
    },
    deleteDataIntoState: (index: number) => {
      const singleList = [...formik.values.slipLabels];
      const finalList = singleList.filter((item: any, i: number) => {
        return i !== index;
      });
      formik.setFieldValue("slipLabels", finalList);
    },
  };

  const formik = useFormik<SalarySlipType>({
    initialValues,
    validationSchema: salarySlipSchema,
    onSubmit: async (values) => {
      let formObj: any = {
        slipLabels: [],
      };
      values?.slipLabels?.forEach((item) => {
        formObj.slipLabels.push({
          type: item?.type,
          label: item?.label,
          value: item?.value,
          valueType: item?.valueType,
        });
      });
      showLoader();
      const res = await updateDocumentSetting(formObj, values._id, hideLoader);
      if (res) {
        setDocumentSetting(res.data.documentSetting);
        showToast(`Salary slip setting updated`, "success");
        hideLoader();
      }
    },
  });

  useEffect(() => {
    if (roles?.length !== 0) {
      fn.getSalarySlipSetting({
        f: "slipLabels",
        company: companyId,
      });
    }
  }, []);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font14x600 textColor3">Salary Slip Labels</p>
        </div>

        {salarySlipLoading ? (
          <SkeletonLoader />
        ) : (
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className="entry_table_wrapper">
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="font12x500 textColor3">
                        <th scope="col">Label</th>
                        <th scope="col">Section</th>
                        <th scope="col">Value</th>
                        <th scope="col">Value Type</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formik.values.slipLabels?.map(
                        (slipLabel: any, i: number) => {
                          return slipLabel?.isEditable ? (
                            <tr key={i} className="font12x400">
                              <td>
                                <InputField
                                  id="label"
                                  type="text"
                                  placeholder="enter label"
                                  state={slipLabel?.label}
                                  setState={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    formik.setFieldValue(
                                      `slipLabels[${i}].label`,
                                      e.target.value
                                    );
                                  }}
                                  width="200px"
                                />
                              </td>
                              <td>
                                <DropdownOne
                                  placeholder="type"
                                  name="typeName"
                                  data={salaryTypeMap}
                                  state={slipLabel?.typeName}
                                  setState={(
                                    e?: string,
                                    x?: string | number
                                  ) => {
                                    formik.setFieldValue(
                                      `slipLabels[${i}].typeName`,
                                      e
                                    );
                                    formik.setFieldValue(
                                      `slipLabels[${i}].type`,
                                      x
                                    );
                                  }}
                                  width="150px"
                                />
                              </td>
                              <td>
                                <InputField
                                  id="value"
                                  type="tel"
                                  placeholder="0"
                                  state={slipLabel?.value}
                                  setState={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    formik.setFieldValue(
                                      `slipLabels[${i}].value`,
                                      e.target.value
                                    );
                                  }}
                                  width="150px"
                                />
                              </td>
                              <td>
                                <DropdownOne
                                  placeholder="value type"
                                  name="valueTypeName"
                                  data={salaryValueTypeMap}
                                  state={slipLabel?.valueTypeName}
                                  setState={(
                                    e?: string,
                                    x?: string | number
                                  ) => {
                                    formik.setFieldValue(
                                      `slipLabels[${i}].valueTypeName`,
                                      e
                                    );
                                    formik.setFieldValue(
                                      `slipLabels[${i}].valueType`,
                                      x
                                    );
                                  }}
                                  width="150px"
                                />
                              </td>
                              <td>
                                <div
                                  className="d-flex align-items-center cursor_pointer"
                                  onClick={() => fn.saveDataIntoState(i)}
                                >
                                  <BiSave
                                    style={{
                                      fontSize: 18,
                                      color: "var(--text2)",
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr key={i} className="font12x400">
                              <td>{slipLabel?.label}</td>
                              <td>{slipLabel?.typeName}</td>
                              <td>{slipLabel?.value}</td>
                              <td>{slipLabel?.valueTypeName}</td>
                              <td>
                                <div className="d-flex gap-2">
                                  <Box
                                    bgColor="var(--btn_color1)"
                                    image="/Assets/Svg/edit.svg"
                                    onClickFn={() => {
                                      fn.updateDataIntoState(i);
                                    }}
                                  />
                                  <Box
                                    bgColor="var(--btn_color2)"
                                    image="/Assets/Svg/delete.svg"
                                    onClickFn={() => {
                                      fn.deleteDataIntoState(i);
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                      <tr className="font12x400">
                        <td>
                          <InputField
                            id="label"
                            type="text"
                            placeholder="Enter label"
                            state={singleItem?.label}
                            setState={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setSingleItem({
                                ...singleItem,
                                label: e.target.value,
                              });
                            }}
                            width="200px"
                          />
                        </td>
                        <td>
                          <DropdownOne
                            placeholder="Type"
                            name="typeName"
                            data={salaryTypeMap}
                            state={singleItem?.typeName}
                            setState={(e?: string, x?: string | number) => {
                              setSingleItem({
                                ...singleItem,
                                typeName: e as string,
                                type: x as string,
                              });
                            }}
                            width="150px"
                          />
                        </td>
                        <td>
                          <InputField
                            id="Value"
                            type="tel"
                            placeholder="0"
                            state={singleItem?.value}
                            setState={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setSingleItem({
                                ...singleItem,
                                value: e.target.value,
                              });
                            }}
                            width="150px"
                          />
                        </td>
                        <td>
                          <DropdownOne
                            placeholder="Value type"
                            name="valueTypeName"
                            data={salaryValueTypeMap}
                            state={singleItem?.valueTypeName}
                            setState={(e?: string, x?: string | number) => {
                              setSingleItem({
                                ...singleItem,
                                valueTypeName: e as string,
                                valueType: x as string,
                              });
                            }}
                            width="150px"
                          />
                        </td>
                        <td>
                          {singleItem?.label !== "" &&
                          singleItem?.value !== "" &&
                          singleItem?.valueType !== "" &&
                          singleItem?.type !== "" ? (
                            <div
                              className="btn"
                              style={{
                                width: 40,
                                height: "100%",
                                backgroundColor: "var(--bg_color2)",
                              }}
                              onClick={() => fn.addDataIntoState(singleItem)}
                            >
                              <img
                                src="/Assets/Svg/plusIcon.svg"
                                alt="icon"
                                width={15}
                                height={15}
                              />
                            </div>
                          ) : (
                            <div
                              className="btn"
                              style={{
                                width: 40,
                                height: "100%",
                                backgroundColor: "var(--bg_color2)",
                                cursor: "no-drop",
                                opacity: 0.5,
                              }}
                            >
                              <img
                                src="/Assets/Svg/plusIcon.svg"
                                alt="icon"
                                width={15}
                                height={15}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {formik.errors.slipLabels ? (
                    <InputError error={formik.errors.slipLabels} />
                  ) : null}
                </div>
              </div>

              <div className="d-flex justify-content-end mt-5">
                <button className="btn font12x500 shadow-none custom_btn ms-3">
                  {loading ? (
                    <SpinLoader height="20" width="20" color="#fff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default SalarySlipLabel;
