import { useContext } from "react";
import { UserProfileContext } from "../../../../../pages/main/user/User Profile";
import InputField from "../../../../../shared components/TextInput";
import {
  salaryTypeMap,
  salaryValueTypeMap,
} from "../../../../../utils/constants";

const UserSalarySlip = () => {
  const { userObj } = useContext(UserProfileContext);

  return (
    <>
      <div
        className="menu_content_wrapper px-3"
        style={{
          minHeight: "calc(100vh - 102px)",
          height: "calc(100vh - 102px)",
        }}
      >
        <div className="d-flex justify-content-between py-3">
          <p className="m-0 font16x600 textColor3">Salary Slip</p>
        </div>

        <div className="details_form_wrapper">
          <form>
            <div className="entry_table_wrapper">
              <div className="table_wrapper">
                <table className="table">
                  <thead className="table_head">
                    <tr className="font12x500 textColor3">
                      <th scope="col">Label</th>
                      <th scope="col">Type</th>
                      <th scope="col">Value</th>
                      <th scope="col">Value Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userObj?.slipLabels &&
                      userObj?.slipLabels?.map((slipLabel: any, i: number) => {
                        return (
                          <tr className="font12x400">
                            <td>
                              <InputField
                                id="label"
                                type="text"
                                placeholder="Enter label"
                                state={slipLabel?.label}
                                isDisabled={true}
                                width="200px"
                              />
                            </td>
                            <td>
                              <InputField
                                id="type"
                                type="text"
                                placeholder="Type"
                                state={salaryTypeMap.get(slipLabel?.type)}
                                isDisabled={true}
                                width="150px"
                              />
                            </td>
                            <td>
                              <InputField
                                id="value"
                                type="tel"
                                placeholder="0"
                                state={slipLabel?.value}
                                isDisabled={true}
                                width="150px"
                              />
                            </td>
                            <td>
                              <InputField
                                id="valueType"
                                type="text"
                                placeholder="Value type"
                                state={salaryValueTypeMap.get(
                                  slipLabel?.valueType
                                )}
                                isDisabled={true}
                                width="150px"
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UserSalarySlip;
