import { useEffect, useRef, useState } from "react";
import {
  MailOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputError from "../../shared components/InputError/InputError";
import { useFormik } from "formik";
import { emailLoginSchema } from "../../utils/validationSchema";
import useLoading from "../../hooks/useLoading";
import {
  captureUserDevice,
  getloggedInCompanyId,
  getloggedInUserId,
  passwordLogin,
} from "../../lib/api functions/common";
import SpinLoader from "../../shared components/Loader/SpinLoader";
import { showToast } from "../../lib/toast/ShowToast";
import { PushNotificationToken } from "../../firebase";

export type emailLogin = {
  email: string;
  password: string;
};

const EmailLogin = () => {
  const [loading, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const [tokenFound, setTokenFound] = useState("");
  const [isPasswordShow, setIsPasswordShow] = useState<boolean>(false);
  const passRef = useRef<any>(null);

  useEffect(() => {
    PushNotificationToken(setTokenFound);
  }, []);

  useEffect(() => {
    if (token && role) {
      navigate(location.pathname === "/" ? "/dashboard" : location.pathname);
    }
  }, []);

  const fn = {
    getLoggedInUserId: async (params: any) => {
      const res = await getloggedInUserId(params);
      if (res) {
        window.localStorage.setItem("userId", res.data.user);
        return true;
      } else {
        return false;
      }
    },
    getLoggedInCompanyId: async (params: any) => {
      const res = await getloggedInCompanyId(params);
      if (res) {
        window.localStorage.setItem("companyId", res.data.company);
        return true;
      } else {
        return false;
      }
    },
    registerUserDevice: async () => {
      const params = {
        deviceId: tokenFound,
        platform: "web",
      };
      const res = await captureUserDevice(params);
      if (res) {
        window.localStorage.setItem("userDeviceId", res.data.userDevice._id);
        showToast("Device registered", "success");
      }
    },
    passwordToggle: () => {
      if (passRef?.current?.type === "password") {
        passRef.current.type = "text";
        setIsPasswordShow(true);
      } else {
        passRef.current.type = "password";
        setIsPasswordShow(false);
      }
    },
  };

  const initialValues: emailLogin = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: emailLoginSchema,
    onSubmit: async (values) => {
      let finalObj = {
        email: values.email.toLowerCase(),
        password: values.password,
      };
      showLoader();
      const res = await passwordLogin(finalObj, hideLoader);
      if (res) {
        window.localStorage.setItem("role", res.data.role);
        window.localStorage.setItem("token", res.data.token);
        const user = await fn.getLoggedInUserId({});
        const company = await fn.getLoggedInCompanyId({});
        if (user && company) {
          fn.registerUserDevice();
          navigate(
            location.pathname === "/" ? "/dashboard" : location.pathname,
            { replace: true }
          );
        } else {
          navigate("/", { replace: true });
        }
        hideLoader();
      }
    },
  });

  return (
    <>
      <div className="container-fluid m-0 loginSection">
        <div className="row">
          <div className="col-12 col-sm-7 authLeft d-flex justify-content-center align-items-center">
            <div>
              <h1 className="mb-0 textColor3">Welcome to our CRM</h1>
              <h1 className="m-0 textColor3">Sign In to start.</h1>
              <p className="font14x500 textColor1 mt-1">
                Enter your details to proceed further
              </p>
              <div className="mt-5">
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-2">
                    <label
                      htmlFor="email"
                      className="font14x500 textColor1 form-label mb-1"
                    >
                      Email*
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control shadow-none font14x500 border-0"
                        id="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="input-group-text border-0">
                        <MailOutlined className="textColor1" />
                      </div>
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <InputError error={formik.errors.email} />
                    ) : null}
                  </div>

                  <div className="mb-2">
                    <label
                      htmlFor="password"
                      className="font14x500 textColor1 form-label mb-1"
                    >
                      Password*
                    </label>
                    <div className="input-group position-relative">
                      <input
                        ref={passRef}
                        type="password"
                        className="form-control shadow-none font14x500 pe-3 border-0"
                        id="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div
                        className="input-group-text cursor_pointer border-0"
                        onClick={fn.passwordToggle}
                      >
                        {isPasswordShow ? (
                          <EyeOutlined className="textColor1" />
                        ) : (
                          <EyeInvisibleOutlined className="textColor1" />
                        )}
                      </div>
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <InputError error={formik.errors.password} />
                    ) : null}
                  </div>

                  <button
                    type="submit"
                    className="btn font14x500 shadow-none fullWidthButton py-2 mt-3"
                  >
                    {loading ? (
                      <SpinLoader height="22" width="22" color="#fff" />
                    ) : (
                      "Sign In"
                    )}
                  </button>
                </form>

                <div className="font14x500 text-center pt-2">
                  <Link to="/otp" className="anchorTag">
                    Sign in using OTP
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-5 d-flex justify-content-center align-items-center">
            <img
              src="/Assets/Svg/login.svg"
              alt="loginDefault"
              draggable={false}
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      {/* desktop alert */}
      <div className="desktopAlert">
        <img
          src="/Assets/Svg/desktopAlert.svg"
          className="img-fluid"
          alt="alert"
          draggable={false}
        />
        <p className="mb-0 my-3 text-center">
          Please use <b>web view</b> (Desktop/Laptop) to access the portal.
        </p>
      </div>
    </>
  );
};

export default EmailLogin;
