import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import {
  captureUserDevice,
  getloggedInCompanyId,
  getloggedInUserId,
  otpLoginMethod,
  verifyOtpMethod,
} from "../../lib/api functions/common";
import { showToast } from "../../lib/toast/ShowToast";
import SpinLoader from "../../shared components/Loader/SpinLoader";
import { PushNotificationToken } from "../../firebase";
import { handleTime } from "../../function/other";

const inputs = Array(4).fill("");

const VerifyOtp = () => {
  const { state } = useLocation();
  const [loading, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();
  const [inputOtp, setInputOtp] = useState<string>("");
  const [tokenFound, setTokenFound] = useState("");
  //otp expireTime in seconds
  const [expireTime, setExpireTime] = useState<number>(300);
  const [time, setTime] = useState<string>("00:00");
  useEffect(() => {
    if (expireTime !== 0) {
      setTime(handleTime(expireTime));
      setTimeout(() => {
        setExpireTime(expireTime - 1);
      }, 1000);
    }
  }, [expireTime]);

  useEffect(() => {
    PushNotificationToken(setTokenFound);
  }, []);

  const fn = {
    handleKeyBoardFocus: (e: any) => {
      if ((e.keyCode === 8 || e.keyCode === 46) && e.target.value === "") {
        if (
          e.target &&
          e.target.parentElement &&
          e.target.parentElement.previousElementSibling &&
          e.target.parentElement.previousElementSibling.children[0]
        ) {
          e.target.parentElement.previousElementSibling.children[0].focus();
        }
      }
    },
    handleOTP: (e: any) => {
      let isNumber = e.target.value.match(/[A-z]/g);
      if (!isNumber) {
        if (e.target.value.length === 1) {
          if (
            e.target &&
            e.target.parentElement &&
            e.target.parentElement.nextElementSibling &&
            e.target.parentElement.nextElementSibling.children[0]
          ) {
            e.target.parentElement.nextElementSibling.children[0].focus();
          }
        }

        let otpNumber = "";
        const inputsValue = document.getElementsByClassName("otpField");
        for (const element of inputsValue) {
          const e = element as HTMLInputElement;
          otpNumber += e.value;
        }
        setInputOtp(otpNumber);
      } else {
        showToast("Only number is allowed", "error");
      }
    },
    verifyOtp: async (e: any) => {
      e.preventDefault();
      let values = {
        mobile: state.mobile,
        phoneCode: state.phoneCode,
        hash: state.hash,
        otp: inputOtp,
        role: "admin",
      };
      showLoader();
      const res = await verifyOtpMethod(values, hideLoader);
      if (res) {
        window.sessionStorage.removeItem("mobile");
        window.localStorage.setItem("role", res.data.role);
        window.localStorage.setItem("token", res.data.token);
        const user = await fn.getLoggedInUserId({});
        const company = await fn.getLoggedInCompanyId({});
        if (user && company) {
          fn.registerUserDevice();
          navigate("/dashboard", { replace: true });
        } else {
          navigate("/", { replace: true });
        }
      }
    },
    getLoggedInUserId: async (params: any) => {
      const res = await getloggedInUserId(params);
      if (res) {
        window.localStorage.setItem("userId", res.data.user);
        return true;
      } else {
        return false;
      }
    },
    getLoggedInCompanyId: async (params: any) => {
      const res = await getloggedInCompanyId(params);
      if (res) {
        window.localStorage.setItem("companyId", res.data.company);
        return true;
      } else {
        return false;
      }
    },
    registerUserDevice: async () => {
      const params = {
        deviceId: tokenFound,
        platform: "web",
      };
      const res = await captureUserDevice(params);
      if (res) {
        window.localStorage.setItem("userDeviceId", res.data.userDevice._id);
        showToast("Device registered", "success");
      }
    },
    resendOTP: async () => {
      const res = await otpLoginMethod({ mobile: state.mobile });
      if (res) {
        navigate("/verify-otp", { state: { ...res.data } });
      }
    },
  };

  useEffect(() => {
    if (!state) {
      navigate(-1);
    }
  }, []);

  return (
    <>
      <div className="container-fluid m-0 loginSection">
        <div className="row">
          <div className="col-12 col-sm-7 authLeft">
            <div
              className="d-inline-block mt-3 ms-3 cursor_pointer"
              onClick={() => navigate("/otp", { replace: true })}
            >
              <img src="/Assets/Svg/backBtn.svg" alt="close" />
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                height: "calc(100% - 40px)",
              }}
            >
              <div>
                <h1 className="mb-0 text-center textColor3">
                  Welcome to our CRM
                </h1>
                <h1 className="m-0 text-center textColor3">
                  Please Verify OTP
                </h1>
                <div className="mt-5">
                  <form onSubmit={fn.verifyOtp}>
                    <div className="mb-4 d-flex justify-content-center">
                      {inputs.map((item, index) => {
                        return (
                          <div className="otpInputs me-3" key={index}>
                            <input
                              type="tel"
                              className="otpField form-control shadow-none"
                              maxLength={1}
                              onKeyDown={fn.handleKeyBoardFocus}
                              onChange={fn.handleOTP}
                            />
                          </div>
                        );
                      })}
                    </div>

                    <button
                      type="submit"
                      className="btn font14x500 shadow-none fullWidthButton py-2"
                      disabled={inputOtp === "" ? true : false}
                    >
                      {loading ? (
                        <SpinLoader height="22" width="22" color="#fff" />
                      ) : (
                        "Verify"
                      )}
                    </button>
                  </form>

                  <div className="font14x500 text-center pt-2">
                    {expireTime === 0 ? (
                      <p
                        className="anchorTag mt-1 cursor_pointer d-inline-block"
                        onClick={fn.resendOTP}
                      >
                        Resend OTP
                      </p>
                    ) : (
                      <p className="font14x500 textColor1 mt-1">
                        Resend OTP in {time}
                      </p>
                    )}
                    <p className="font14x500 textColor1">
                      {`The OTP has been sent to ${state.mobile}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-5 d-flex justify-content-center align-items-center">
            <img
              src="/Assets/Svg/login.svg"
              alt="loginDefault"
              draggable={false}
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      {/* desktop alert */}
      <div className="desktopAlert">
        <img
          src="/Assets/Svg/desktopAlert.svg"
          className="img-fluid"
          alt="alert"
          draggable={false}
        />
        <p className="mb-0 my-3 text-center">
          Please use <b>web view</b> (Desktop/Laptop) to access the portal.
        </p>
      </div>
    </>
  );
};

export default VerifyOtp;
