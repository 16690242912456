import React from "react";
import { getDefaultInitial } from "../../../../../function/other";

type PropType = {
  state?: any;
  name?: string;
};

const ProfilePhoto: React.FC<PropType> = (props) => {
  const { state, name } = props;

  return (
    <>
      <div className="d-flex justify-content-center align-items-center flex-column mb-4 mb-lg-0">
        <div className="mb-4">
          <h3 className="font14x600 textColor3 mb-0">Profile Photo</h3>
        </div>

        <div
          style={{
            border: "2px dotted var(--border_color4)",
            padding: 20,
            textAlign: "center",
            borderRadius: 8,
          }}
        >
          {state ? (
            <div className="has_profile_photo font96x600">
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}${state?.url}`}
                alt="profile_image"
                draggable={false}
                width={150}
                height={150}
              />
            </div>
          ) : (
            <div
              className="has_profile_photo font96x600"
              style={{
                backgroundColor: "var(--bg_color2)",
              }}
            >
              {getDefaultInitial(name ?? "")}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfilePhoto;
